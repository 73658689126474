import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { entrarAction, sairAction } from '../../actions/loginAction'
import { TextField } from '../../_atoms'

import logo from '../../assets/img/logo-emoutech.png'

const styles = function (theme) {
  //  console.log( theme.palette.primary )

  return {
    root: {
      marginTop: 50,
      display: 'flex',
      justifyContent: 'center',
    },
    logoLogin: {
      height: 130,
      backgroundSize: '65%',
      // backgroundColor: theme.palette.primary['A100']
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 300,
    },
    title: {
      marginBottom: 16,
      fontSize: 30,
      color: theme.palette.text.secondary,
    },
    CardActions: {
      display: 'flex',
      justifyContent: 'center',
    },
    pos: {
      marginBottom: 12,
      color: theme.palette.text.secondary,
    },
  }
}

class TrocarSenha extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Login: '',
      NovaSenha: '',
      ReNovaSenha: '',
      TrocarDisabled: true,
    }
  }

    onChange = (key, event) => {
      let TrocarDisabled = true
      if (key === 'NovaSenha' && this.state.ReNovaSenha === event.target.value) {
        TrocarDisabled = false
      }

      if (key === 'ReNovaSenha' && this.state.NovaSenha === event.target.value) {
        TrocarDisabled = false
      }

      this.setState({ [key]: event.target.value, TrocarDisabled })
    }

    handlerClickEnter = () => {
      const url = 'api/Login/TrocarSenhaComToken'

      const model = {
        Login: this.state.Login,
        Senha: this.state.NovaSenha,
        Token: this.props.Token,
      }

      axios.post(url, model).then(response => {
        this.props.entrarAction(response)
      })
    }

    render() {
      const { classes } = this.props
      return (
        <div className={classes.root}>
          <Card className={classes.card}>
            <CardMedia className={classes.logoLogin} image={logo} />
            <CardContent>
              <Typography variant="body1" className={classes.title}>
                            Trocar Senha
              </Typography>
              <Typography variant="body1" className={classes.pos}>
                            Digite uma nova senha
              </Typography>
              <Grid>
                <Grid item>
                  <TextField
                    label="Login"
                    className={classes.textField}
                    onChange={event => this.onChange('Login', event)}
                    value={this.state.Login}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Nova Senha"
                    className={classes.textField}
                    type="password"
                    onChange={event => this.onChange('NovaSenha', event)}
                    value={this.state.NovaSenha}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Repita Nova Senha"
                    className={classes.textField}
                    type="password"
                    onChange={event => this.onChange('ReNovaSenha', event)}
                    value={this.state.ReNovaSenha}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.CardActions}>
              <Button
                color="primary"
                onClick={() => this.handlerClickEnter()}
                disabled={this.state.TrocarDisabled}
              >
                            Trocar
              </Button>
            </CardActions>
          </Card>
        </div>
      )
    }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      entrarAction,
      sairAction,
    },
    dispatch,
  )
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TrocarSenha))
