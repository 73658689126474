import { SHOWMENSAGEM, HIDEMENSAGEM } from '../contstants/actionsContants'

export const showMensagemConfirmacaoAction = objMensagem => ({
  type: SHOWMENSAGEM,
  payload: objMensagem,
})

export const hideMensagemConfirmacaoAction = () => ({
  type: HIDEMENSAGEM,
})
