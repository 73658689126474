import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core'
import React, { useState } from 'react'
import CUPONS_PACKAGES from './cuponsPackages'

function ContentCupons ({
  onClick
}) {
  const theme = useTheme()
  const borderColor = theme?.palette?.secondary?.main
  const [selected, setSelected] = useState({})
  return (
    <Grid container spacing={1} justify={'center'}>
      {CUPONS_PACKAGES.map(pct => {
        return (
          <Grid item sm={4} xs={12} key={pct.nome}>
            <Card
              style={{ border: selected?.nome === pct.nome && `1px solid ${borderColor}` }}
            >
              <CardActionArea
                onClick={() => {
                  onClick(pct)
                  setSelected(pct)
                }}
              >
                <CardMedia
                  image={require('./../../../../assets/img/cupom_logo.png')}
                  style={{ height: 100 }}
                />
                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Typography color="primary">{pct.nome}</Typography>
                  <Typography
                    style={{
                      fontSize: 20
                    }}
                    color="secondary"
                  >
                    R$ {pct.valor.toFixed(2)}
                  </Typography>
                  <Typography color="primary">Quantidade: {pct.quantidade}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ContentCupons
