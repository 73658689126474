import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from '@material-ui/core'
import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'

export default function AddOrEditModal ({
  onHide,
  data,
  addOrEditEndereco
}) {
  const [form, setForm] = useState({
    name: '',
    parentId: null,
    ...data
  })

  function save () {
    addOrEditEndereco(form)
  }

  let titulo = (data.id ? 'Editar ' : 'Novo ') + 'Endereço'
  if (data.name) {
    titulo += ` ➜ ${data.name}`
  } else if (data.parentName) {
    titulo += ` em ${data.parentName}`
  }
  return (
    <Dialog fullWidth maxWidth={'sm'} open>
      <Title
        title={titulo}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent style={{ paddingTop: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Name do Endereço"
              value={form.name}
              onChange={e => setForm({ ...form, name: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={save}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
