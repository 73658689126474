import React, { useEffect, useRef, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { show } from 'react-notification-system-redux'
import {
  Grid,
  useMediaQuery,
  IconButton,
  Button,
  InputAdornment,
  Icon,
  FormControlLabel,
  Checkbox,
  Badge,
  Tabs,
  Tab,
  Paper,
  Fab,
  Tooltip,
  Avatar,
  CircularProgress
} from '@material-ui/core'
import * as signalR from '@microsoft/signalr'
import Title from '@em/lib/src/util/Title'

import { axiosEMUtility } from '../../../myAxios'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import envConstants from '../../../contstants/envConstants.json'
import statusEntregaConstants from '../../../contstants/statusEntrega'
import BarraPedidos from './components/barraPedidos'
import PedidoItens from './components/pedidoItens'
import PedidoPagamento from './components/pedidoPagamento'
import MesasDialog from './components/mesasDialog'
import FecharCaixaDialog from './components/fecharCaixaDialog'
import SearchItemDialog2 from '../../sortimento/components/searchItemDialog2'
import PedidoInfo from './components/pedidoInfo'
import { IndicadorImpressoras } from '../../dispositivos/components'
import ListaDeAdmsDialog from './components/listaDeAdmsDialog'
import AutenticaAdmDialog from './components/autenticaAdmDialog'
import moment from 'moment'
import { layoutDefault, layoutFenix, layoutPedidaoFenix } from '../impressaoLayouts'
import layoutMDogao from '../impressaoLayouts/layoutMDogao'
import layoutFoguete from '../impressaoLayouts/layoutFoguete'
import canaisContstants from '../../../contstants/canais'
import { TextField } from '../../../_atoms'
import CloseIfoodMerchantModal from './components/closeIfoodMerchantModal'
import { getCenter } from '@em/lib/src/Printers/helpers'
import FiltersModal from './components/filtersModal'

let connection = {}

const Caixa3 = ({ userReducer, match, showMensagemConfirmacaoAction, show, funcoes }) => {
  const [filter, setFilter] = useState({
    open: false,
    canais: [],
    status: [],
    view: 'me',
    clientes: []
  })
  const [loading, setLoading] = useState(true)
  const [wsConnected, setWsConnected] = useState(true)
  const [mesasDialog, setMesasDialog] = useState({ open: false })
  const [key, setKey] = useState(null)
  const [searchItemDialog, setSearchItemDialog] = useState({ open: false, search: '' })
  const [fechamentoDialog, setFechamentoDialog] = useState({ open: false })
  const [mesas, setMesas] = useState([])
  const [tags, setTags] = useState([])
  const [pedidos, setPedidos] = useState([])
  const [impressoras, setImpressoras] = useState([])
  const [todosStatusDePedidos, setTodosStatusDePedidos] = useState([])
  const [pedido, setPedido] = useState({})
  const [pedidoAtualizar, setPedidoAtualizar] = useState({})
  const [emUtility, setEMUtility] = useState({})
  const [caixa, setCaixa] = useState({})
  const [modelosDeImpressao, setModelosDeImpressao] = useState([1, 2, 3])
  const [admsModal, setAdmsModal] = useState({ open: false })
  const [autenticaAdmModal, setAutenticaAdmModal] = useState({ open: false, data: null })
  const [allowEditByAdm, setAllowEditByAdm] = useState(false)
  const [sistema, setSistema] = useState(null)
  // const [changedPedido, setChangedPedido] = useState(false)
  const [formasDePagamento, setFormasdePagamento] = useState([])
  const [tab, setTab] = useState(0)
  const [statusLojaIfood, setStatusLojaIfood] = useState({
    available: false,
    loading: true
  })
  const [closeIfoodMerchantModal, setCloseIfoodMerchantModal] = useState({ open: false })
  const [vendedores, setVendedores] = useState([])
  const refSearch = useRef('')

  const isMobile = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    loadCaixa()
    loadOrderStatus()
    connection = new signalR.HubConnectionBuilder()
      .withUrl(`${envConstants[window.location.host].api}/pedidoHub`)
      .withAutomaticReconnect()
      .build()

    const addOnGroup = () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.invoke('AddToGroup', userReducer.sistema.sistemaBancoId.toString())
        connection.on('atualizarPedido', p => setPedidoAtualizar(p))
        setWsConnected(true)
      }
    }

    connection.start().then(addOnGroup)
    connection.onreconnected(addOnGroup)

    connection.onclose(() => {
      setWsConnected(false)
    })

    connection.onreconnecting(() => {
      setWsConnected(false)
    })

    if (userReducer?.sistema?.sistemaBancoId == 102) {
      loadStatusIfood()
    }
    loadVendedores()
  }, [])

  useEffect(() => {
    if (pedidoAtualizar?.id) {
      pedidoChegando()
    }
  }, [pedidoAtualizar])

  useEffect(() => {
    if (todosStatusDePedidos && pedido.statusEntregaId) {
      const st = todosStatusDePedidos.find(x => x.id === pedido.statusEntregaId)
      setPedido({ ...pedido, statusEntregaJoin: st })
    }
    if (!pedido.statusEntregaId) {
      setAllowEditByAdm(true)
    }
  }, [pedido.statusEntregaId, pedido.id])

  useEffect(() => {
    setAllowEditByAdm(false)
  }, [pedido.id])

  const loadVendedores = () => {
    axios.get('api/Pessoa/ByPagina?paginaId=618035c008f5796946c176f0&funcao=vendedor')
      .then(resp => {
        setVendedores(resp)
      })
  }

  const loadStatusIfood = () => {
    axios.get('api/ifoodV2/merchants/status').then(response => {
      const [data] = response
      setStatusLojaIfood({
        ...data,
        loading: false
      })
    }).finally(() => {
      setTimeout(() => {
        loadStatusIfood()
      }, 30000)
    })
  }

  const onRefreshPedido = pedidoAtualizado => {
    axios.post('api/pedido', pedidoAtualizado).then(response => {
      setPedidoAtualizar(response?.dados)
    })
  }

  const loadCaixa = () => {
    axios.get(`api/caixa/${match.params.caixaId}`).then(caixa => {
      if (!caixa.dataAbertura) {
        window.location.hash = 'pdv/caixa'
        return
      }
      setCaixa(caixa)
    })
  }

  useEffect(() => {
    if (caixa.id) {
      Promise.all([
        axiosGetPedidos(),
        axios.get('/api/mesa/full'),
        axios.get('/api/item/tags?componente=false'),
        axios.get('/api/Connect/Print'),
        axios.get('/api/Impressora/Full'),
        axios.get('/api/FormaPagamento/full'),
        axios.get('api/sistema/me')

      ]).then(responses => {
        setLoading(false)
        setPedidos(pedidosOrder(responses[0]))
        setMesas(responses[1])
        setTags(responses[2])
        setEMUtility(responses[3])
        setImpressoras(responses[4])
        setFormasdePagamento(responses[5])
        setSistema(responses[6])
      })
    }
  }, [caixa])

  const loadOrderStatus = () => {
    axios.get('/api/pedidostatus/full')
      .then(resp => {
        const list = resp?.map(s => {
          const statusTypeObj = statusEntregaConstants.find(se => se.id === s.statusType)
          if (statusTypeObj?.id) {
            return { ...s, statusTypeObj, statusTypeName: statusTypeObj?.nome }
          }
          return s
        })
        setTodosStatusDePedidos(list)
      })
  }

  const pedidoChegando = () => {
    if (pedidoAtualizar.statusCaixa === 2) {
      setPedidos(pedidos.filter(x => x.id !== pedidoAtualizar.id))
      if (pedido?.id === pedidoAtualizar?.id) {
        setPedido({})
      }
      return
    }

    const _pedido = pedidos.find(pdd => pdd.id === pedidoAtualizar?.id)

    if (!_pedido) {
      if ([2, 3].includes(pedidoAtualizar.canal) && pedidoAtualizar.statusEntrega <= 1) {
        new Audio(require('./../../../assets/audios/novoPedido.mp3')).play()
      }
      setPedidos([pedidoAtualizar, ...pedidos])
    } else {
      setPedidos(pedidos.map(p => {
        if (pedidoAtualizar.id == p.id) {
          return { ...p, ...pedidoAtualizar }
        }
        return p
      }))
    }

    if (pedidoAtualizar.selected) {
      setPedido(pedidoAtualizar)
    } else if (pedido.id === pedidoAtualizar.id) {
      setPedido({ ...pedido, ...pedidoAtualizar })
    }

    setKey(new Date().getTime())
  }

  const onAddPedido = canal => {
    axios.post('api/pedido', { canal }).then(response => {
      if (response.dados) {
        setPedidoAtualizar({ ...response.dados, selected: true })
        setTab(1)
      }
    })
  }

  const axiosGetPedidos = () => {
    return axios.get('api/pedido/full', {
      params: {
        statusCaixa: '3,1'
        // financeiroId: caixa.financeiroId // não sei se é certo
      }
    })
  }

  const pedidosOrder = (ppds) => {
    return ppds?.sort((a, b) => {
      if (a.id < b.id) { return 1 }
      if (a.id > b.id) { return -1 }
      return 0
    })
  }

  const loadPedidos = () => {
    axiosGetPedidos().then(response => {
      setPedidos(pedidosOrder(response))
    })
  }

  const onSetMesas = mesas => {
    onRefreshPedido({ ...pedido, mesas })
    setMesasDialog({ ...mesasDialog, open: false })
  }

  const onAddItem = item => {
    let itens = []
    if (pedido.itens) itens = [...pedido.itens]
    const valorAux = parseFloat(((item.valor * (pedido?.cliente?.entidade?.discount || 0) / 100)).toFixed(2)) * -1
    const valorAux2 = ((item.valor + valorAux) < (item.minAmount || 0) ? (item.valor - (item.minAmount || 0)) * -1 : valorAux)
    const newItem = {
      ...item,
      qtde: item?.multiplo || 1,
      foto: item.fotos && item.fotos[0],
      showComposicao: (item.montagens || []).length > 0,
      valorAux: valorAux2
    }

    // setPedido({ ...pedido, itens: [...itens, newItem] })
    onRefreshPedido({ ...pedido, itens: [...itens, newItem] })
    setSearchItemDialog({ ...searchItemDialog, open: false, search: '' })
    // setChangedPedido(true)
  }

  const loadPedido = (pedido, force) => {
    // if (changedPedido && !force) {
    //   confirmExitPedido(pedido)
    // } else {
    axios.get(`api/pedido/${pedido.id}`).then(response => {
      const st = todosStatusDePedidos.find(x => x.id === pedido.statusEntregaId)
      // setChangedPedido(false)
      setPedido({ ...response, statusEntregaJoin: st })
    })
    // }
    setTab(1)
  }

  const onSave = (nextPedido) => {
    axios.post('api/pedido', pedido).then(response => {
      // setChangedPedido(false)
      loadPedidos()
      setTimeout(() => {
        if (nextPedido?.id) {
          loadPedido(nextPedido, true)
        } else {
          const pedidoAtualizado = {
            ...pedido,
            ...response?.dados
          }
          loadPedido(pedidoAtualizado, true)
        }
      }, 500)
    })
  }

  const validateAdm = (userId, senha) => {
    axios.post(`api/Login/VerificarLogin/?id=${userId}&senha=${senha}`)
      .then(val => {
        if (val) {
          setAutenticaAdmModal({ open: false })
          setAllowEditByAdm(true)
        }
      })
  }

  const onFinalizar = () => {
    const data = {
      ...pedido,
      financeiroId: caixa.financeiroId,
      dataCompetencia: caixa.dataAbertura
    }

    axios.post(`api/pedido/finalizar?modelosDeImpressao=${modelosDeImpressao.join(',')}`, data).then(response => {
      setPedido({})
      loadPedidos()
      // setChangedPedido(false)
    })
  }

  const onCancelar = () => {
    axios.post(`api/pedido/cancelar/${pedido.id}`).then(response => {
      setPedido({})
      loadPedidos()
    })
  }

  const onImprimirCfe = () => {
    const imp = impressoras.find(x => x.id === caixa.impressora.id)
    const { caminho, modelo } = imp

    axios
      .create({ baseURL: emUtility.host })
      .post(`api/Printer/PrintCfe/${caminho}/${modelo.id}?chave=${pedido.cFe}`)
      .then(response => {

      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  const onImprimirPedidao = () => {
    var lines = []
    lines.push(
      {
        value: getCenter('PEDIDO', 18),
        fontSize: 18
      },
      {
        value: getCenter(pedido.numero, 25),
        fontSize: 25
      },
      {
        value: getCenter('Data da impressão ', 12),
        fontSize: 12
      },
      {
        value: getCenter(moment().format('DD/MM/YYYY HH:mm'), 12),
        fontSize: 12
      }
    )

    let layout = lines
    if (userReducer?.sistema?.sistemaBancoId == 1963) {
      layout = layoutPedidaoFenix(pedido)
    }

    onImprimir(layout)
  }

  const onImprimirControle = () => {
    let layout = []
    if (userReducer?.sistema?.sistemaBancoId == 1963) {
      layout = layoutFenix(pedido)
    } else if (userReducer?.sistema?.sistemaBancoId == 3079) {
      layout = layoutMDogao(pedido)
    } else if (userReducer?.sistema?.sistemaBancoId == 3649) {
      layout = layoutFoguete(pedido)
    } else {
      layout = layoutDefault(pedido)
    }
    onImprimir(layout)
  }

  const onImprimirPracas = async (imprs) => {
    const itemIds = pedido.itens?.filter(i => i.id?.length === 24)?.map(i => i.id)?.join()

    const responseItens = await axios.get('/api/item/full', { params: { ids: itemIds } })
    const itemsWithImpressoras = responseItens?.filter(item => item.impressoras)
    let distinctImpressoras = []
    itemsWithImpressoras.forEach(item => {
      item?.impressoras.forEach(imp => {
        if (!distinctImpressoras.find(di => di.id === imp.id)) {
          distinctImpressoras = [...distinctImpressoras, imp]
        }
      })
    })

    distinctImpressoras.forEach(print => {
      const items = itemsWithImpressoras?.filter(item => !!item.impressoras.find(p => p.id === print.id))?.map(item => item.id)
      const pedidoItens = pedido.itens.filter(item => items.includes(item.id))

      var lines = [
        {
          value: getCenter(print.nome, 18),
          fontStyle: 1,
          fontSize: 18
        },
        {
          value: '-----------------------------------------------------------------'
        },
        {
          value: ' '
        },
        ...layoutMDogao({ ...pedido, itens: pedidoItens }, { hideTotal: true })
      ]

      const currentPrint = impressoras.find(imp => imp.id === print.id)

      axiosEMUtility
        .post(`api/Printer/PrintOut/${currentPrint.caminho}/${currentPrint.modelo?.id}`, lines)
        .then(response => {
          show({ message: 'Impressão enviada!', title: 'Sucesso' }, 'success')
        })
        .catch(error => {
          show({ message: error.message, title: 'Sem resposta' }, 'error')
        })
    })
  }

  const onImprimir = (linhas) => {
    const imp = impressoras.find(x => x.id === caixa.impressora.id)
    const { caminho, modelo } = imp

    axiosEMUtility
      .post(`api/Printer/PrintOut/${caminho}/${modelo.id}`, linhas)
      .then(response => {
        show({ message: 'Impressão enviada!', title: 'Sucesso' }, 'success')
      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  const onSistemaPagar = () => {
    axios.post(`api/PagSeguro/GerarPagamento/${pedido.id}/5ef55c52baf14b1c238b5143/1`).then(response => {
      window.location = 'https://pagseguro.uol.com.br/v2/checkout/payment.html?code=' + response.dados
    })
  }

  if (loading) {
    return <p>Carregando...</p>
  }

  if (!caixa.numero) {
    return <p>Caixa invalido</p>
  }

  const filterPedidos = () => pedidos.filter(p => {
    let showCanal = true
    let showStatus = true
    let showSearch = true
    let showOnlyMe = true
    let showClients = true
    const showMesas = true
    if (filter.canais.length > 0) {
      if (!filter.canais.includes(p.canal)) {
        showCanal = false
      }
    }

    if (filter.status.length > 0) {
      if (!filter.status.includes(p.statusEntregaId)) {
        showStatus = false
      }
    }

    if (filter.view === 'me') {
      if (p?.seller?.id != userReducer.pessoa.id) {
        showOnlyMe = false
      }
    }

    if (filter.clientes.length > 0) {
      if (!filter.clientes.includes(p.cliente?.id)) {
        showClients = false
      }
    }

    if (filter.search) {
      if (!p.numero.toString().includes(filter.search) &&
        !p?.mesas?.toString().includes(filter.search) &&
        !p?.cliente?.nome.toLowerCase()?.toString().includes(filter.search.toLowerCase())
      ) {
        showSearch = false
      }
    }

    return showCanal && showStatus && showSearch && (showOnlyMe || !p?.seller?.id) && showClients
  })

  const badgeContent = filter.canais.length + filter.status.length + filter.clientes.length + (filter.view === 'all' ? 1 : 0)
  return (
    <div>
      <Title
        title={
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <span style={{ fontSize: isMobile && 20 }}>
                {`${caixa.nome || 'Caixa'} ${caixa.numero}`}
              </span>
            </Grid>
            {!wsConnected && !isMobile &&
              <Grid item>
                <Button onClick={() => { window.location.reload() }}
                  color="error"
                  variant="outlined">
                  Atualizar a pagina
                </Button>
              </Grid>
            }

            {userReducer?.sistema?.sistemaBancoId == 102 && statusLojaIfood?.available != null && caixa?.canais?.includes(2) &&
              <Grid item>
                <Tooltip title={
                  <div>
                    <p style={{ fontSize: 16 }}><b>{statusLojaIfood?.message?.title}</b></p>
                    {statusLojaIfood?.validations?.map(v => {
                      return (
                        <div style={{ marginTop: 10 }} key={v?.id}>
                          <p style={{ fontSize: 16 }}>{v?.message?.title}</p>
                          {v?.message?.subtitle && <p style={{ fontSize: 14 }}>({v?.message?.subtitle})</p>}
                          {v?.message?.description && <p style={{ fontSize: 14 }}>{v?.message?.description}</p>}
                        </div>
                      )
                    })}
                  </div>
                }>
                  <div style={{ position: 'relative' }}>
                    <IconButton onClick={() => setCloseIfoodMerchantModal({ open: true })} disabled={!statusLojaIfood?.available}>
                      <Avatar src={
                        statusLojaIfood?.available ? require('./../../../assets/img/pdv/ifood.png')
                          : require('./../../../assets/img/pdv/ifood_black.png')
                      } />
                    </IconButton>

                    {statusLojaIfood?.loading && <CircularProgress
                      size={40}
                      style={{
                        color: '#AAA',
                        position: 'absolute',
                        top: 10,
                        left: 11,
                        zIndex: 1
                      }}
                    />
                    }
                  </div>
                </Tooltip>
              </Grid>
            }
          </Grid>
        }
        component={emUtility.host && (
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}>
            <IndicadorImpressoras connectURL={emUtility.host} impressoras={impressoras} />
          </div>
        )}
        buttons={[
          {
            type: 'menu',
            icon: 'more_vert',
            buttons: [
              {
                onClick: () => setFechamentoDialog({ ...fechamentoDialog, open: true }),
                label: 'Fechar o caixa'
              }
            ]
          }
        ]}
      />

      <Grid container spacing={1}>
        {((isMobile && tab === 0) || !isMobile) &&
          <>
            <Grid item>
              <div style={{ width: !isMobile ? 250 : '100%' }}>
                <Grid container spacing={1} alignItems="center" justify="center">
                  {!isMobile &&
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained" color="primary"
                        onClick={() => onAddPedido(0)}>
                        Novo Pedido ({pedidos.length})
                      </Button>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <TextField
                          inputRef={refSearch}
                          onKeyPress={(e => {
                            if (e.key == 'Enter') { setFilter({ ...filter, search: refSearch.current.value }) }
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    setFilter({ ...filter, search: refSearch.current.value })
                                  }}
                                >
                                  <Icon>search</Icon>
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          label="Busca de Pedidos"
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => setFilter({ ...filter, open: true })}>
                          <Badge
                            badgeContent={badgeContent}
                            color="error">
                            <Icon>filter_list</Icon>
                          </Badge>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      {filter?.open &&
                        <FiltersModal
                          onHide={refresh => setFilter({ ...filter, open: false })}
                          canaisContstants={canaisContstants}
                          todosStatusDePedidos={todosStatusDePedidos}
                          // vendedores={vendedores}
                          onFilter={form => setFilter({ ...form, open: false })}
                          filter={filter}
                          userReducer={userReducer}
                          pedidos={pedidos}
                        />
                      }
                      <BarraPedidos
                        key={new Date().getTime()}
                        pedidos={filterPedidos()}
                        pedido={pedido}
                        todosStatusDePedidos={todosStatusDePedidos}
                        onSelectPedido={loadPedido}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </>
        }

        <Grid item xs={!isMobile}>
          {pedido?.id && (
            <Grid container spacing={1} >
              {((isMobile && tab === 1) || !isMobile) &&
                <>
                  <Grid item xs={12} md={7}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <PedidoItens
                          key={`${pedido.id}-${pedido.statusEntrega}`}
                          mesas={mesas}
                          pedido={pedido}
                          onRefreshPedido={onRefreshPedido}
                          search={searchItemDialog.search}
                          onOpenMesas={mesasSelecionadas => setMesasDialog({ open: true, mesasSelecionadas })}
                          onOpenSearchItens={search => setSearchItemDialog({ ...searchItemDialog, open: true, search })}
                          handlerChange={(e) => setSearchItemDialog({ ...searchItemDialog, [e.target.name]: e.target.value })}
                          openAdmsModal={() => setAdmsModal({ open: true })}
                          allowEditByAdm={allowEditByAdm}
                          onSave={onSave}
                          show={show}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PedidoInfo
                          pedido={pedido}
                          onRefreshPedido={onRefreshPedido}
                          allowEditByAdm={allowEditByAdm}
                          key={`${pedido.id}-${pedido.statusEntrega}`}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              {((isMobile && tab === 2) || !isMobile) &&
                <>
                  <Grid item xs={12} md={5}>
                    <div>
                      <PedidoPagamento
                        // key={`${pedido.id}-${pedido.statusEntrega}-${key}-${pedido.cFe}`} //Essa KEY esta dando problema quando chega um pedido via WS
                        pedido={pedido}
                        formasDePagamento={formasDePagamento}
                        onRefreshPedido={onRefreshPedido}
                        onFinalizar={onFinalizar}
                        onCancelar={onCancelar}
                        onImprimir={onImprimir}
                        onImprimirPedidao={onImprimirPedidao}
                        onImprimirCfe={onImprimirCfe}
                        onImprimirControle={onImprimirControle}
                        onImprimirPracas={onImprimirPracas}
                        onSave={onSave}
                        emUtility={emUtility}
                        sistema={sistema}
                        modelosDeImpressao={modelosDeImpressao}
                        setModelosDeImpressao={modelosDeImpressao => setModelosDeImpressao(modelosDeImpressao)}
                        todosStatusDePedidos={todosStatusDePedidos}
                        vendedores={vendedores}
                      // changedPedido={changedPedido}
                      />
                    </div>
                  </Grid>
                </>
              }
            </Grid>
          )}
        </Grid>
      </Grid>

      {mesasDialog.open &&
        <MesasDialog
          mesas={mesas}
          mesasSelecionadas={mesasDialog.mesasSelecionadas}
          onHide={() => setMesasDialog({ ...mesasDialog, open: false })}
          onSetMesas={onSetMesas}
        />
      }

      {searchItemDialog.open &&
        <SearchItemDialog2
          onAddItem={onAddItem}
          search={searchItemDialog.search}
          tags={tags}
          onHide={() => setSearchItemDialog({ ...searchItemDialog, open: false })}
        />
      }

      {fechamentoDialog.open &&
        <FecharCaixaDialog
          caixa={caixa}
          emUtility={emUtility}
          onHide={() => setFechamentoDialog({ ...fechamentoDialog, open: false })}
        />
      }

      {admsModal.open &&
        <ListaDeAdmsDialog
          onHide={() => setAdmsModal({ open: false })}
          confirmAdm={adm => {
            setAutenticaAdmModal({ open: true, data: adm })
            setAdmsModal({ open: false })
          }}
        />
      }

      {autenticaAdmModal.open &&
        <AutenticaAdmDialog
          onHide={() => setAutenticaAdmModal({ open: false })}
          adm={autenticaAdmModal.data}
          validateAdm={validateAdm}
        />
      }

      {closeIfoodMerchantModal?.open &&
        <CloseIfoodMerchantModal
          onHide={refresh => {
            setCloseIfoodMerchantModal({ open: false })
            if (refresh === true) {
              setStatusLojaIfood({
                ...statusLojaIfood,
                available: false,
                message: { title: 'Loja fechada' },
                validations: []
              })
            }
          }}
          data={statusLojaIfood}
        />
      }

      {isMobile &&
        <>
          {tab === 1 && <div style={{ height: 50 }} />}
          {tab === 0 &&
            <Fab
              style={{
                position: 'fixed',
                bottom: 100,
                right: 20
              }}
              color="primary"
              onClick={() => onAddPedido(0)}
            >
              <Icon>
                add
              </Icon>
            </Fab>
          }
          <Paper
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              left: 0
            }}
            fullWidth
            elevation={4}
          >
            <Tabs
              value={tab}
              onChange={(e, i) => setTab(i)}
              variant="fullWidth"
            >
              <Tab
                icon={
                  <Badge
                    badgeContent={pedidos?.length}
                    invisible={pedidos?.length === 0 || pedido?.id}
                    color="primary"
                  >
                    <Icon>list_alt</Icon>
                  </Badge>
                }
                label={`Pedidos ${pedido?.id ? `(Q: ${pedidos?.length})` : ''}`}
              />
              <Tab
                disabled={!pedido?.id}
                icon={<Icon>shopping_cart</Icon>}
                label={`Carrinho ${pedido?.numero ? `(P: ${pedido?.numero})` : ''}`}
              // label={'Carrinho'}
              />
              <Tab
                disabled={!pedido?.id}
                icon={<Icon>payments</Icon>}
                label={`Pagamento ${pedido?.numero ? `(R$ ${(pedido._valorTotal || pedido.valorTotal || 0)?.toFixed(2)})` : ''}`}
              // label={'Pagamento'}
              />
            </Tabs>
          </Paper>
        </>
      }
    </div >
  )
}

function mapStateToProps (state) {
  return {
    userReducer: state.userReducer,
    online: state.onlineReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      show,
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Caixa3)
