import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import Title from '@em/lib/src/util/Title'

import BuyAndAddCuponsModal from './buyAndAddCuponsModal'
import CupomConsumerModal from './cupomConsumerModal'
import List from './list'

const tableRef = React.createRef()

function Cupons () {
  const [buyAndAddCuponsModal, setBuyAndAddCuponsModal] = useState({
    open: false
  })
  const [cupomConsumerModal, setCupomConsumerModal] = useState({
    open: false
  })

  const loadCupons = () => tableRef.current && tableRef.current.onQueryChange({ page: 0 })

  useEffect(() => {
    loadCupons()
  }, [])

  return (
    <>
      <Title
        title="Lista de Cupons"
        component={(
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setCupomConsumerModal({ open: true })}
            >
              Consumir Cupom
            </Button>
            <Button
              color="primary"
              variant="outlined"
              style={{ marginLeft: 5 }}
              onClick={() => setBuyAndAddCuponsModal({ open: true })}
            >
              Comprar mais cupons
            </Button>
          </div>
        )}
      />
      <List
        tableRef={tableRef}
      />
      {buyAndAddCuponsModal.open &&
        <BuyAndAddCuponsModal
          onHide={refresh => {
            setBuyAndAddCuponsModal({ open: false })
            if (refresh) {
              loadCupons()
            }
          }}
        />
      }
      {cupomConsumerModal.open &&
        <CupomConsumerModal
          onHide={refresh => {
            setCupomConsumerModal({ open: false })
            if (refresh) {
              loadCupons()
            }
          }}
        />
      }
    </>
  )
}

export default Cupons
