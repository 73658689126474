import React, { useState } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import {
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List
} from '@material-ui/core'
import { BsInfoLg } from 'react-icons/bs'
import { IoReceiptSharp } from 'react-icons/io5'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import statusEntregaList from './../../../../contstants/statusEntrega'
import { axiosEMUtility } from '../../../../myAxios'
import { authorizationAdmin } from './authorizationAdmin'

const RangeSimplesNacional = {
  1: 4,
  2: 7.3,
  3: 9.5,
  4: 10.70,
  5: 14.30,
  6: 19.00
}

function WorkflowStatusButtons ({
  pedido,
  sistema,
  onRefreshPedido,
  todosStatusDePedidos,
  usuario
}) {
  const [changeStatus, setChangeStatus] = useState({ open: false })
  const [statusEntregaClicked, setStatusEntregaClicked] = useState(null)

  const atualizaPedido = (statusEntrega) => {
    onRefreshPedido({
      ...pedido,
      statusEntrega: statusEntregaClicked?.statusType || statusEntrega?.statusType,
      statusEntregaId: statusEntregaClicked?.id || statusEntrega?.id
    })
  }

  const setTaxDoItem = async (order) => {
    const itensId = order?.itens?.map(x => x.id).join(',')

    if (itensId) {
      const itensResponse = await Axios.get(`api/item/${itensId}?withNCM=true`)
      order.itens.map(it => {
        it.tax = { ...itensResponse.find(x => x.id === it.id)?.tax }
        it.ean = itensResponse.find(x => x.id === it.id)?.ean
        return it
      })
    }
  }

  const emitirTesteFimAFim = async () => {
    await setTaxDoItem(pedido)
    const pICMS = RangeSimplesNacional[sistema.tax.range]
    const response = await axiosEMUtility.post('/api/Sat/TesteFimAFim', { ...pedido, pICMS })
    pedido.cFe = response.data.chaveConsulta
    onRefreshPedido(pedido)
  }

  const emitirCupomFiscal = async () => {
    await setTaxDoItem(pedido)
    const pICMS = RangeSimplesNacional[sistema.tax.range]
    const response = await axiosEMUtility.post('/api/Sat/EnviarDadosVenda', { ...pedido, pICMS })
    pedido.cFe = response.data.chaveConsulta
    if (response?.data?.dados?.chaveConsulta) {
      pedido.cFe = response.data.dados.chaveConsulta
      Axios.post('api/pedido', pedido)
      onRefreshPedido(pedido)
    }
  }

  let buttons = []
  if (pedido?.statusEntrega === 0) {
    buttons = todosStatusDePedidos.filter(s => !s.parentId)
  } else {
    buttons = todosStatusDePedidos.filter(s => s.parentId === pedido?.statusEntregaId || s.id === pedido?.statusEntregaId)
  }

  const sEntrega = statusEntregaList.find(x => x.id === pedido.statusEntrega)

  buttons = buttons?.filter(b => b?.id !== pedido?.statusEntregaId)

  const activeTax = !!(todosStatusDePedidos.find(s => s.id === pedido?.statusEntregaId)?.activeTax)

  const requestAuthorizationCancel = authorizationAdmin((status) => {
    if (status === 'success') {
      atualizaPedido()
    }
  })

  return (
    <>
      {requestAuthorizationCancel?.renderElement()}
      <ListItem
        button
        onClick={() => null}
      >
        <ListItemIcon>
          <Avatar>
            <BsInfoLg size="32px" />
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={sEntrega?.nome} secondary="Status" />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => setChangeStatus({ ...changeStatus, open: !changeStatus.open })}
          >
            {changeStatus.open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={changeStatus.open} timeout="auto" unmountOnExit>
        <List style={{ marginLeft: 15, padding: 0 }}>
          {buttons?.map(b => {
            return (
              <ListItem
                key={b?.id}
                button
                onClick={() => {
                  setStatusEntregaClicked(b)
                  if (b?.statusType == 3) {
                    requestAuthorizationCancel.open()
                  } else {
                    atualizaPedido(b)
                  }
                }}
              >
                <ListItemIcon>
                  <Avatar>
                    <BsInfoLg size="32px" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={b?.statusTypeName} secondary={b?.name} />
              </ListItem>
            )
          })}
          {!pedido.cFe && pedido?.statusEntregaJoin?.activeTax && (
            <>
              {usuario?.sistemaAnterior?.id === '5b6384392707c66b5d232a11' &&
                <ListItem
                  button
                  onClick={emitirTesteFimAFim}
                >
                  <ListItemIcon>
                    <Avatar>
                      <IoReceiptSharp size="32px" />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={'Teste Fim a Fim'} secondary={'Apenas Emoutech'} />
                </ListItem>
              }
              {activeTax &&
                <ListItem
                  button
                  onClick={emitirCupomFiscal}
                >
                  <ListItemIcon>
                    <Avatar>
                      <IoReceiptSharp size="32px" />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={'Emitir Cupom fiscal'} secondary={'S@T Fiscal'} />
                </ListItem>
              }
            </>
          )}
        </List>
      </Collapse>
    </>
  )

  // return (
  //   <Grid container spacing={1}>
  //     <Grid item xs={12}>
  //       <ComboBoxData
  //         selected={pedido?.statusEntregaId}
  //         list={buttons}
  //         name="statusEntregaId"
  //         label="Status do Pedido"
  //         onChange={b => atualizaPedido(b)}
  //         nameText='statusTypeName'
  //         nameValue='id'
  //       />
  //     </Grid>
  //     {!pedido.cFe && pedido?.statusEntregaJoin?.activeTax && (
  //       <>
  //         {usuario?.sistemaAnterior?.id === '5b6384392707c66b5d232a11' &&
  //           <Grid item xs={6}>
  //             <div>
  //               <Button
  //                 variant="outlined"
  //                 color="secondary"
  //                 fullWidth
  //                 onClick={emitirTesteFimAFim}
  //               >
  //                 teste fim a fim
  //               </Button>
  //             </div>
  //           </Grid>
  //         }
  //         {activeTax &&
  //           <Grid item xs={6}>
  //             <div>
  //               <Button
  //                 variant="outlined"
  //                 color="secondary"
  //                 fullWidth
  //                 onClick={emitirCupomFiscal}
  //               >
  //                 Emitir Cupom fiscal
  //               </Button>
  //             </div>
  //           </Grid>
  //         }

  //       </>
  //     )}

  //     {buttons.map((b, idx) => {
  //       return (
  //         <Grid key={idx} item xs={6}>
  //           <Button
  //             variant="outlined"
  //             color="secondary"
  //             fullWidth
  //             onClick={() => atualizaPedido(b)}
  //           >
  //             {b.name}
  //           </Button>
  //         </Grid>
  //       )
  //     })}
  //   </Grid >
  // )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(WorkflowStatusButtons)
