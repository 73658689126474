import React, { Component } from 'react'
import axios from 'axios'
import LancamentoDialog from '../../../_organisms/financeiro/lancamentos/lancamentoDialog'
import ListExtrato from '../../../_organisms/financeiro/extrato/listExtrato'
import Title from '@em/lib/src/util/Title'

const tipo = 2

class ReceitaContainer extends Component {
    state = {
      modalReceitas: { open: false },
      dataList: []
    }

    componentDidMount () {
      this.loadReceitas()
    }

    loadReceitas = () => axios.get(`api/financeiro/full?meusLancamentos=true&lstStatus=0&tipo=${tipo}`).then(dataList => this.setState({ dataList }))

    render () {
      const { dataList, modalReceitas } = this.state
      return (
        <div>
          <Title
            title="Minhas de receitas pendentes de aprovação"
            buttons={[
              { icon: 'add', onClick: () => this.setState({ modalReceitas: { open: true } }) }
            ]}
          />

          <ListExtrato list={dataList} />

          {modalReceitas.open &&
                    (
                      <LancamentoDialog
                        tipo={tipo}
                        onHide={refresh => this.setState({ modalReceitas: { open: false } }, () => {
                          if (refresh === true) {
                            this.loadReceitas()
                          }
                        })}
                        title="Lançamento de Receitas"
                      />
                    )}
        </div>
      )
    }
}

export default ReceitaContainer
