import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import TextField from '../TextField'
import { Autocomplete } from '@material-ui/lab'

class ComboBoxData extends Component {
  render () {
    const { onChange, selected, nameValue, nameText, label, list, noneText, disabled, error, fullWidth } = this.props
    const value = list.find(x => x[nameValue] == selected) || ''
    return (
      <Autocomplete
        options={list || []}
        disabled={disabled}
        size="small"
        getOptionLabel={option => option[nameText]}
        onChange={(e, item, t) => {
          if (item) {
            onChange(item)
          } else {
            onChange({ [nameValue]: '' })
          }
        }}
        style={{ minWidth: !fullWidth ? 200 : '100%' }}
        value={value}
        renderInput={(params) =>
          <TextField
            {...params}
            label={label}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment
            }}
          />}
      />
    )
  }
}

ComboBoxData.propTypes = {
  showNoneText: PropTypes.bool,
  noneText: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  nameValue: PropTypes.string,
  nameText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool
}

// Specifies the default values for props:
ComboBoxData.defaultProps = {
  nameValue: 'id',
  nameText: 'nome',
  showNoneText: true,
  noneText: 'Selecione'
}

export default ComboBoxData
