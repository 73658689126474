import React, { useState } from 'react'
import {
  Dialog, DialogContent, DialogActions, Button,
} from '@material-ui/core'

import { TextField } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

export default props => {
  const [colecao, setColecao] = useState('')

  return (
    <Dialog open onClose={props.onHide} fullWidth maxWidth="sm">
      <Title
        title="Criar Coleção"
        buttons={[
          {
            icon: 'close',
            onClick: props.onHide,
          },
        ]}
      />
      <DialogContent style={{ paddingTop: 10 }}>
        <TextField
          label="Coleção"
          value={colecao}
          onChange={e => setColecao(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => props.onCreateCollection(colecao)}>
                    Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
