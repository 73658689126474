import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Paper,
  Typography,
  IconButton,
  Icon,
  Divider,
  Collapse,
  useMediaQuery,
  Slide,
  Fade
} from '@material-ui/core'
import TextFielddMoneyBR from '@em/lib/src/util/InputCustom/TextFieldMoneyBR'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import Title from '@em/lib/src/util/Title'

import { ComboBoxData } from '../../../../_atoms/combobox'

const Transition = React.forwardRef(function Transition (props, ref) {
  const isMobile = useMediaQuery('(max-width:900px)')
  if (isMobile) {
    return <Slide direction="up" ref={ref} {...props} />
  }
  return <Fade ref={ref} {...props} />
})

const FormasPagamentoDialog = ({
  onHide,
  formasDePagamento,
  addPay,
  initialValue,
  pedido
}) => {
  const isMobile = useMediaQuery('(max-width:900px)')
  const [form, setForm] = useState({ valor: initialValue, personQty: 0 })
  const [showError, setShowError] = useState(false)
  const firstFormPgto = formasDePagamento.find(x => true)
  const [formPgto, setFormPgto] = useState({ ...firstFormPgto })
  console.log({ formPgto })
  const [splitPayment, setSplitPayment] = useState({ open: true })

  const validToPay = () => {
    if (!form.valor || !formPgto.id) {
      setShowError(true)
      return
    }
    let payments = []
    if (form.personQty) {
      let valorTotal = 0
      for (let index = 0; index < form?.personQty; index++) {
        valorTotal += form.valor
        if (valorTotal > initialValue) {
          form.valor = form.valor - (valorTotal - initialValue)
        } else if (valorTotal < initialValue && index === (form?.personQty - 1)) {
          form.valor = form.valor + (initialValue - valorTotal)
        }
        payments = [...payments, { ...formPgto, ...form }]
      }
    } else {
      payments = [...payments, { ...formPgto, ...form }]
    }

    addPay(payments, (pedido?.discountAmount || 0))
  }
  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      TransitionComponent={Transition}
    >
      <Title
        title={<span>Aplicar Pagamento - Falta <NumberFormatMoneyBR value={(initialValue || 0)} /></span>}
        buttons={[
          { icon: 'close', color: 'secondary', onClick: onHide }
        ]}
      />
      <DialogContent>

        <Paper style={{ marginTop: 10 }}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>Dividir a conta</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setSplitPayment({ open: !splitPayment.open })}>
                <Icon>{splitPayment.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={splitPayment.open}>
                <Divider />
                <div style={{ padding: 10 }}>
                  <Grid container spacing={2} alignItems="center" justify="center">
                    {[1, 2, 3, 4].map((personQty, idx) => {
                      const valorCalc = Number((initialValue / personQty).toFixed(2))
                      const selected = valorCalc == form.valor
                      return (
                        <Grid item key={idx}>
                          <Button
                            key={idx}
                            variant="contained"
                            style={{
                              backgroundColor: selected ? '#228B22' : '#FFFFFF',
                              color: selected && '#FFFFFF'
                            }}
                            onClick={e => setForm({ ...form, valor: valorCalc, personQty })}
                          >
                            <span>
                              {`${(initialValue || 0).toFixed(2).replace(',', '.')} / ${personQty}`}
                              <br />
                              <NumberFormatMoneyBR value={valorCalc} />
                            </span>
                          </Button>
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              </Collapse>
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <ComboBoxData
              label="Escolha a forma de pagamento"
              error={showError && !formPgto.id}
              onChange={e => setFormPgto(e)}
              selected={formPgto.id}
              style={{ width: 400 }}
              list={formasDePagamento || []}
            />
          </Grid>
          <Grid item xs>
            <TextFielddMoneyBR
              fullWidth
              label="Valor"
              name="valor"
              error={showError && !form.valor}
              value={form.valor}
              onValueChange={e => setForm({ ...form, valor: e.target.value, personQty: 0 })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {form?.personQty > 1 &&
          <div style={{ color: '#aaa' }}>
            O valor de <NumberFormatMoneyBR value={initialValue} /> será dividido em {form?.personQty} pagamentos.
          </div>
        }
        <div style={{ flex: 1 }} />
        <Button variant="contained" color="primary" onClick={validToPay}>Adicionar Pagamento</Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormasPagamentoDialog
