import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardContent, CardActions, Button, CardMedia, Typography,
} from '@material-ui/core'

const styles = {
  card: { width: '100%' },
  cardActions: { justifyContent: 'flex-end' },
  cardContent: { position: 'relative' },
  cardMedia: { height: 100, width: '100%' },
}

class CardImagemTitulo extends PureComponent {
  hasButtons() {
    const { buttons } = this.props
    return Boolean(buttons) && buttons.length > 0
  }

  render() {
    const { src, text, buttons } = this.props
    return (
      <Card style={styles.card}>
        <CardMedia
          style={styles.cardMedia}
          image={src}
          title={text}
        />
        <CardContent style={styles.cardContent}>
          <Typography component="p">{text}</Typography>
        </CardContent>
        {
                    this.hasButtons()
                    && (
                    <CardActions style={styles.cardActions}>
                      {
                            buttons.map((b, index) => (
                              <Button
                                key={index}
                                color="primary"
                                size="small"
                                onClick={b.onClick}
                              >
                                {b.text}
                              </Button>
                            ))
                        }
                    </CardActions>
                    )
                }
      </Card>
    )
  }
}

CardImagemTitulo.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttons: PropTypes.array,
}

export default CardImagemTitulo
