import React, { Component } from 'react'
import axios from 'axios'
import {
  TextField, Dialog, DialogContent, DialogActions, Button,
} from '@material-ui/core'

import Title from '@em/lib/src/util/Title'

class criaContaDialog extends Component {
  state = {
    nome: '',
  };

  onHandleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  };

  handleSalvar = () => {
    const { nome } = this.state
    axios.post('api/conta', { nome }).then(conta => {
      this.props.onHideSalvar(conta.dados)
    })
  };

  render() {
    const { nome } = this.state
    return (
      <div>
        <Dialog open>
          <Title title="Cadastro de Contas" />
          <DialogContent style={{ paddingTop: 10 }}>
            <TextField
              label="Nome da Conta"
              value={nome}
              name="nome"
              onChange={this.onHandleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.props.onHide}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSalvar}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default criaContaDialog
