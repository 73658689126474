import { Grid, Icon, IconButton, Paper, Tooltip } from '@material-ui/core'
import React, { PureComponent } from 'react'
import { ReactTable } from '../../../_atoms'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import TabEnderecoCriarEditarDialog from '../../pessoa/criarEditar/tabEnderecoCriarEditarDialog'

class DetalheModalPagamentos extends PureComponent {
  state = {
    editEnderecoModal: { open: false }
  }

  render () {
    const { pagamentos, pedido } = this.props
    const { endereco } = pedido
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ReactTable
            data={pagamentos || []}
            columns={[
              {
                accessor: 'nome'
              },
              {
                maxWidth: 200,
                accessor: 'valor',
                Cell: props => <NumberFormatMoneyBR value={props.value} />
              }
            ]}
          />
        </Grid>
        {endereco &&
          <Grid item xs={12}>
            <Paper style={{ padding: 5 }}>
              <h3>Endereço do Pedido</h3>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <div>
                    {`${endereco?.rua || 'não informado'}, ${endereco?.numero || 'não informado'} - ${endereco?.cidade || 'não informado'}/${endereco?.estado || 'não informado'} - ${endereco?.cep || 'não informado'}`}
                  </div>
                </Grid>
                <Grid item>
                  <Tooltip title="Editar endereço do pedido" placement="top">
                    <IconButton disabled={pedido.cFe} onClick={() => this.setState({ editEnderecoModal: { open: true } })}>
                      <Icon>
                        edit
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        }
        {this.state.editEnderecoModal.open &&
          <TabEnderecoCriarEditarDialog
            data={{ ...endereco, endereco: endereco?.rua }}
            onHide={() => this.setState({ editEnderecoModal: { open: false } })}
            onAdd={end => {
              pedido.endereco = end
              this.setState({ editEnderecoModal: { open: false } }, () => {
                this.props.onRefreshPedido(pedido)
              })
            }}
          />
        }
      </Grid>
    )
  }
}

export default DetalheModalPagamentos
