import React, { Component, useEffect, useState } from 'react'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent, DialogActions, Button, Grid, Typography
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import Mesa from './../../../../_atoms/Mesa'

const MesasDialog = (props) => {
  const [mesasSelecionadas, setMesasSelecionadas] = useState(props.mesasSelecionadas ? props.mesasSelecionadas.split(', ') : [])
  const [isViagem, setIsViagem] = useState(!(props.mesasSelecionadas?.length > 0))

  useEffect(() => {
    if (isViagem) {
      setMesasSelecionadas([])
    }
  }, [isViagem])

  return (
    <Dialog open fullWidth>
      <Title title="Escolha do cliente" buttons={[{ icon: 'close', color: 'secondary', onClick: props.onHide }]} />
      <DialogContent style={{ paddingTop: 10 }}>

        <Grid container spacing={1} justify='center'>
          <Grid item>
            <Button
              color={isViagem ? 'primary' : 'default'}
              variant={isViagem ? 'contained' : 'outlined'}
              onClick={() => setIsViagem(true)}
            >
              Para viagem
            </Button>
          </Grid>
          <Grid item>
            <Button
              color={!isViagem ? 'primary' : 'default'}
              variant={!isViagem ? 'contained' : 'outlined'}
              onClick={() => setIsViagem(false)}
            >
              Para consumir aqui
            </Button>
          </Grid>
        </Grid>

        {!isViagem &&
          <Grid container spacing={1} justify='center' style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography variant='h5' style={{ textAlign: 'center' }}>
                Escolha uma mesa
              </Typography>
            </Grid>
            {props.mesas?.map((mesa, idx) => {
              const selected = _.some(mesasSelecionadas, x => x === mesa.numero)
              return (
                <Grid item key={idx}>
                  <Mesa
                    onClick={() => setMesasSelecionadas([mesa.numero])}
                    selected={selected}
                    numero={mesa.numero}
                    lugares={mesa.lugares}
                  />
                </Grid>
              )
            })}
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isViagem && mesasSelecionadas.length === 0}
          onClick={() => props.onSetMesas(mesasSelecionadas.join(', '))} color="primary" variant="contained">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MesasDialog
