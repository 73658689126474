import React, { useState } from 'react'
import { Grid, Icon } from '@material-ui/core'

import AdvancedSearch from '../../../../_atoms/search/AdvancedSearch'
import { DatePicker } from '../../../../_atoms'
import { ComboBoxAPI, ComboBoxData } from '../../../../_atoms/combobox'
import { isMoment } from 'moment'

function Top ({
  onSearch,
  filter
}) {
  const [hasFiltered, setHasFiltered] = useState(false)
  const [form, setForm] = useState(filter)
  const [showError, setShowError] = useState({ error: false, msg: '' })

  const handleChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  useState(() => {
    setHasFiltered(true)
  }, [filter])

  const onBuscar = () => {
    const { error, msg } = verifyDateErrors(form.dataCadastroInicial, form.dataCadastroFinal)

    setShowError({ error, msg })

    if (error) {
      return
    }

    onSearch(form)
  }

  const verifyDateErrors = (data1, data2) => {
    let error = false
    let msg = ''
    if (!data1 || !data2) {
      error = true
    }

    // if (!isMoment(form.dataCadastroInicial) || !isMoment(!form.dataCadastroFinal)) {
    //   error = true
    // }

    const diffDays = form.dataCadastroInicial.diff(form.dataCadastroFinal, 'days')

    if (diffDays < -31) {
      error = true
      msg = 'Filtro superior à 31 dias'
    }

    return { error, msg }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>

        <AdvancedSearch
          // onDownload={onDownload}
          onBuscar={onBuscar}
          hasSearch={hasFiltered}
          searchSimple={
            <>
              <Grid item>
                <DatePicker
                  error={showError.error}
                  label="Data inicial"
                  value={form.dataCadastroInicial}
                  onChange={e => handleChangeForm('dataCadastroInicial', e)}
                />
                {showError.msg &&
                  <div style={{ fontSize: 12, color: '#c00000' }}>
                    <Icon style={{ fontSize: 10 }}>error</Icon>
                    {showError.msg}
                  </div>
                }
              </Grid>
              <Grid item>
                <DatePicker
                  error={showError.error}
                  label="Data final"
                  value={form.dataCadastroFinal}
                  onChange={e => handleChangeForm('dataCadastroFinal', e)}
                />
                {showError.msg &&
                  <div style={{ fontSize: 12, color: '#c00000' }}>
                    <Icon style={{ fontSize: 10 }}>error</Icon>
                    {showError.msg}
                  </div>
                }
              </Grid>
            </>
          }
        >
          <Grid container style={{ width: '100%' }} spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <ComboBoxData
                label="Origem"
                onChange={e => handleChangeForm('telas', e.id)}
                selected={form.telas}
                list={[
                  { id: '2,4', nome: 'Todos' },
                  { id: '2', nome: 'Caixa' },
                  { id: '4', nome: 'Entregador' }
                ]}
                onKeyPress={e => {
                  if (e.key === 'Enter') { onBuscar() }
                }}
              />
            </Grid>
            {form?.telas === '4' &&
              <Grid item xs={12} sm={6} md={4}>
                <ComboBoxAPI
                  label="Selecione o entregador"
                  onChange={e => handleChangeForm('telaId', e?.id)}
                  value={form?.telaId}
                  api="api/entregador/full"
                  onKeyPress={e => {
                    if (e.key === 'Enter') { onBuscar() }
                  }}
                />
              </Grid>
            }
            {form?.telas === '2' &&
              <Grid item xs={12} sm={6} md={4}>
                <ComboBoxAPI
                  label="Selecione o caixa"
                  onChange={obj => handleChangeForm('telaId', obj?.id)}
                  value={form?.telaId || ''}
                  api="api/caixa"
                  onKeyPress={e => {
                    if (e.key === 'Enter') { onBuscar() }
                  }}
                />
              </Grid>
            }
          </Grid>
        </AdvancedSearch>
      </Grid>
    </Grid>
  )
}

export default Top
