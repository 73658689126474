import React from 'react'
import {
  Dialog, DialogContent, Grid, TextField, Button, DialogActions,
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'

const MesaTitle = ({ onHide, criando }) => (
  <Title
    title={`${criando ? 'Criar' : 'Editar'} mesa`}
    buttons={[
      {
        icon: 'close',
        color: 'secondary',
        onClick: onHide,
      },
    ]}
  />
)

const Actions = ({ salvar }) => (
  <DialogActions>
    <div style={{ flex: 1 }} />
    <Button color="primary" variant="contained" onClick={salvar}>
            Salvar
    </Button>
  </DialogActions>
)

const Content = ({ numero, lugares, handlerChange }) => (
  <DialogContent style={{ paddingTop: 10 }}>
    <Grid container spacing={1}>
      <Grid item xs={12} />
    </Grid>
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Numero"
          name="numero"
          required
          type="text"
          value={numero || ''}
          onChange={handlerChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Lugares"
          name="lugares"
          required
          type="text"
          value={lugares || ''}
          onChange={handlerChange}
        />
      </Grid>
    </Grid>
  </DialogContent>
)


export default ({
  id, numero, lugares, onHide, salvar, handlerChange,
}) => (
  <Dialog maxWidth="sm" open>

    <MesaTitle onHide={onHide} criando={!id} />

    <Content numero={numero} lugares={lugares} handlerChange={handlerChange} />

    <Actions salvar={salvar} />

  </Dialog>
)
