import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Icon,
  Avatar
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import paginasDoSistema from './routes/paginasDoSistema'

import emoutechLOGO from '../assets/img/logo-emoutech.png'
import { EMOUTECH_ID } from '../contstants/systemConstants'

const styles = theme => ({
  link: {
    textDecoration: 'none'
  },
  nested: {
    paddingLeft: 40,
    backgroundColor: '#fafafa'
  },
  Selected: {
    backgroundColor: '#BBBBBB'
  }
})

class Modulos extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modulos:
        props.usuario.sistema.id == EMOUTECH_ID
          ? paginasDoSistema
          : this.getPaginasDoUsuario()
    }
  }

  getPaginasDoUsuario = () => {
    const { paginasPermitidas } = this.props.usuario.sistema
    const _paginasDoSistema = paginasDoSistema.map(modulo => {
      const paginas = []
      modulo.paginas.forEach(pagina => {
        const paginaPermitida = paginasPermitidas.find(f => f.id == pagina.id)
        if (paginaPermitida) {
          paginas.push(pagina)
        }
      })

      return { ...modulo, paginas }
    })

    return _paginasDoSistema
  };

  handleClickModulo (Modulo) {
    const modulos = _.map(this.state.modulos, modulo => {
      if (modulo.id === Modulo.id) {
        return { ...modulo, open: !modulo.open }
      }
      return { ...modulo, open: false }
    })
    this.setState({ modulos })
  }

  handleClickPagina () {
    const modulos = _.map(this.state.modulos, modulo => ({ ...modulo, open: false }))

    this.props.onClose()
    this.setState({ modulos })
  }

  getModulo (modulo) {
    const { classes } = this.props
    const paginas = modulo.paginas.filter(x => x.path && x.menu)
    if (!paginas || paginas.length === 0) {
      return null
    }

    return (
      <div key={modulo.id}>
        <Divider />

        <ListItem
          button
          onClick={() => this.handleClickModulo(modulo)}
          className={classNames({
            [classes.Selected]: modulo.open
          })}
        >
          <ListItemIcon>
            <Icon>{modulo.icone}</Icon>
          </ListItemIcon>
          <ListItemText primary={modulo.nome} />
          {modulo.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={modulo.open} timeout="auto" unmountOnExit>
          {_.map(paginas, pagina => (
            <Link
              style={{ backgroundColor: '#9a9a9a' }}
              to={pagina.path}
              className={classes.link}
              onClick={() => this.handleClickPagina(pagina)}
              key={pagina.id}
            >
              <Divider />
              <ListItem
                button
                className={classNames(classes.nested, {
                  [classes.Selected]: pagina.open
                })}
              >
                <ListItemIcon>
                  <Icon>{pagina.icone}</Icon>
                </ListItemIcon>
                <ListItemText primary={pagina.nome} />
              </ListItem>
            </Link>
          ))}
        </Collapse>
      </div>
    )
  }

  render () {
    const { modulos } = this.state
    return (
      <div style={{ width: 300 }}>
        <div
          style={{
            backgroundColor: '#444',
            height: 64,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            style={{ maxHeight: 40, maxWidth: 300 }}
            alt="emoutech"
            src={emoutechLOGO}
          />
        </div>

        <List>{modulos.map(modulo => this.getModulo(modulo))}</List>
      </div>
    )
  }
}

Modulos.prototypes = {
  classes: PropTypes.object.isRequerid,
  usuario: PropTypes.object.isRequerid,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles)(Modulos)
