import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Icon
} from '@material-ui/core'
import { Add as AddIcon, WhatsApp as WhatsAppIcon } from '@material-ui/icons'

import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import TabWhatsAppCriarEditarDialog from './tabWhatsAppCriarEditarDialog'
import { ReactTable } from '../../../_atoms'
import * as fnHelper from '@em/lib/src/util/fnHelper'

const styles = () => ({})

class TabWhatsApp extends React.Component {
  state = {
    modal: { open: false }
  };

  deleteConfirmed = whats => {
    this.props.onRemoveWhatsAppConfigs(whats.index)
  };

  add = () => {
    this.setState({ modal: { open: true } })
  };

  edit = data => {
    this.setState({ modal: { open: true, data } })
  };

  render() {
    const { whatsAppConfigs } = this.props
    return (
      <div>
        <Card>
          <CardHeader
            avatar={<WhatsAppIcon />}
            action={(
              <IconButton onClick={this.add}>
                <AddIcon />
              </IconButton>
            )}
            title="Lista de WhatsApps"
          />
          <CardContent style={{ padding: 0 }}>
            <ReactTable
              data={whatsAppConfigs || []}
              columns={[
                {
                  accessor: 'name'
                },
                {
                  accessor: 'whatsAppId'
                },
                {
                  accessor: 'status.name',
                  Cell: props => {
                    return (
                      <div>
                        {props.original.status?.name}
                        <br />
                        {fnHelper.toDateTimeBR(props.original.status?.updateAt)}
                      </div>
                    )
                  }
                },
                {
                  accessor: 'battery.percent',
                  Cell: props => {
                    return (
                      <div>
                        {props.original.battery?.percent}
                        <br />
                        {fnHelper.toDateTimeBR(props.original.battery?.updateAt)}
                      </div>
                    )
                  }
                },
                {
                  width: 110,
                  Cell: props => (
                    <div>
                      <IconButton
                        // disabled={!this.podeEditar()}
                        onClick={() => this.edit({ ...props.original, index: props.index })}
                        color="primary"
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton
                        // disabled={!this.podeDeletar()}
                        onClick={() => {
                          this.deleteConfirmed({
                            ...props.original,
                            index: props.index
                          })
                        }}
                        color="secondary"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  )
                }
              ]}
            />
          </CardContent>
        </Card>

        {this.state.modal.open && (
          <TabWhatsAppCriarEditarDialog
            data={this.state.modal.data}
            onHide={() => this.setState({ modal: { open: false } })}
            onAdd={em => {
              this.props.onAddWhatsAppConfigs(em)
              this.setState({ modal: { open: false } })
            }}
          />
        )}
      </div>
    )
  }
}

TabWhatsApp.prototypes = {
  showMensagemConfirmacaoAction: PropTypes.func.isRequired
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps
  )(TabWhatsApp)
)
