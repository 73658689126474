import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import Highcharts from 'highcharts'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import Chart from '../../_atoms/Chart'
import NumberFormatMoneyBR from '../../_atoms/numberFormat/NumberFormatMoneyBR'
import { DatePicker } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

export default class GraficoVendas extends Component {
  state = {
    serie: [],
    categories: [],
    faturamentoAtual: 0,
    dataAtual: null,
  }

  getOptions = (serie, categories) => ({
    chart: {
      defaultSeriesType: 'column',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          // rotation: -90,
          // y: 10, // 10 pixels down from the top
          format: 'R$ {point.y:.2f}', // one decimal
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
    },
    title: {
      text: 'Venda por hora',
    },

    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        categories,
        title: {
          text: '',
        },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Faturamento',
        },
      },
    ],
    tooltip: {
      formatter() {
        const valor = Highcharts.numberFormat(this.y, 2)
        const serieName = this.x
        return `Vendas - ${serieName}:<br/>`
          + `<b> R$${valor}</b><br/><br/>`
          + `<br/>`
      },
    },
    series: [
      {
        data: serie,
        // events: {
        //     click: mostrarFechamento
        // },
        name: 'Faturamento',
        color: '#2b908f',
      },
    ],
  });

  getData = () => {
    const { dataAtual } = this.state
    axios
      .get(`/api/Pedido/Dia/${dataAtual.format('YYYY-MM-DD')}`)
      .then(data => this.atualizaGraficos(data))
  }

  atualizaGraficos = data => {
    const categories = []; const
      serie = []

    _.forEach(data, d => {
      const property = _.head(_.keys(d))
      const hora = moment.utc(property, 'DD/MM/YYYY H').local().format('HH')
      categories.push(`${hora}hs`)
      serie.push(d[property])
    })

    this.setState({
      serie, categories,
    }, () => {
      this.atualizaLabels()
    })
  }

  atualizaLabels() {
    const { serie } = this.state
    const faturamentoAtual = _.sum(serie)
    this.setState({ faturamentoAtual })
  }

  componentDidMount() {
    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
    })
    this.setDataAtual(moment())
  }

  setDataAtual = value => {
    this.setState({
      dataAtual: value,
    }, () => {
      this.getData()
    })
  }

  render() {
    const { serie, categories } = this.state
    const options = this.getOptions(serie, categories)


    return (
      <>
        <Title title="Gráfico de vendas diário" />
        <br />
        <Grid container justify="space-between">
          <Grid item>
            {this.state.dataAtual && (
              <DatePicker
                label="Data"
                value={this.state.dataAtual}
                onChange={value => this.setDataAtual(value)}
              />
            )
            }
          </Grid>
          <Grid item>
            <span style={{ color: '#ccc', fontSize: '.8em' }}>
              Faturamento atual:&nbsp;
              <span style={{
                fontSize: '2em',
              }}
              >
                <NumberFormatMoneyBR value={this.state.faturamentoAtual} />
              </span>
            </span>
          </Grid>
        </Grid>
        <br />
        <Chart options={options} />
      </>
    )
  }
}
