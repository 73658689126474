import React, { useState } from 'react'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid
} from '@material-ui/core'
import { TextField } from './../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { TextFieldMoneyBR, TextFieldCEP } from './../../../../_atoms/numberFormat'

const AddOrEditModal = props => {
  const [form, setForm] = useState({ ...(props.action == 'add' ? {} : props.data) })

  const handlerChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={props.action != 'add' ? 'Edição faixa de CEP' : 'Nova Faixa De CEP'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name="nome"
                label="Nome"
                onChange={handlerChange}
                value={form.nome}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldCEP
                name="inicial"
                label="Inicial"
                onChange={handlerChange}
                value={form.inicial}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldCEP
                name="final"
                label="Final"
                onChange={handlerChange}
                value={form.final}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldMoneyBR
                name="valor"
                label="Valor"
                onValueChange={handlerChange}
                value={form.valor}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldMoneyBR
                name="valorFreteRepasse"
                label="Valor de Repasse"
                onValueChange={handlerChange}
                value={form.valorFreteRepasse}
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => props.onChange({
            nome: form.nome,
            inicial: form.inicial,
            final: form.final,
            valor: form.valor,
            valorFreteRepasse: form.valorFreteRepasse,
            filter: true
          })}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default AddOrEditModal
