import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
// import localForage from 'localforage'
// import session from 'redux-persist/lib/storage/session'
import { reducer as notificationsReducer } from 'react-notification-system-redux'

import { LOGIN } from '../contstants/actionsContants'
import userReducer from './userReducer'
// import pageSizeReducer from './pageSizeReducer'
import mensagemReducer from './mensagemReducer'
// import tagReducer from './tagReducer'
import onlineReducer from './onlineReducer'

const rootReducer = combineReducers({
  userReducer: persistReducer({ key: LOGIN, storage }, userReducer),
  // pageSizeReducer: persistReducer({ key: PAGESIZE, storage: storage }, pageSizeReducer),
  mensagemReducer,
  // tagReducer,
  onlineReducer,
  notificationsReducer,
})

export default rootReducer
