import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { TextField } from '../..'
import Title from '@em/lib/src/util/Title'
import TextFieldIcon from '../../TextFieldIcon'

const AddOrEditModal = props => {
  const [form, setForm] = useState({ ...(props.action == 'add' ? {} : props.data) })
  const handlerChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={props.action != 'add' ? 'Edição do Menu' : 'Novo Item Do Menu' + ' Em: ' + props.data.nome}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextFieldIcon
                name="icon"
                label="Icon"
                onChange={handlerChange}
                value={form.icon}
              />

            </Grid>
            <Grid item xs={4}>
              <TextField
                name="nome"
                label="Nome"
                onChange={handlerChange}
                value={form.nome}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="hash"
                label="Url"
                onChange={handlerChange}
                value={form.hash}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                name="offset"
                label="Distância do topo"
                onChange={handlerChange}
                value={form.offset}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={form.redirect}
                    onChange={(e) => handlerChange({ target: { name: 'redirect', value: e.target.checked } })}
                  />
                )}
                label="Redirecionar"
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => props.onChange(props.data, {
            icon: form.icon,
            hash: form.hash,
            offset: form.offset,
            redirect: form.redirect,
            nome: form.nome
          })}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default AddOrEditModal
