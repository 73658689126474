import React from 'react'
import {
  ListaPessoa, ListaSistemas, Perfil, Entidades, ListaEntregadores, Ponto, ListaFornecedores
} from '../../_pages/pessoa'
import {
  Pendente, Extrato, Contas, ReceitaLancamento, DespesaLancamento, DizimoContainer
} from '../../_pages/financeiro'
import { ModelosDocumentoss, LayoutsDeImpressao } from '../../_pages/documentos'
import { GrupoFiscal } from '../../_pages/fiscal'
import { GerarObjectId, Criptografia } from '../../_pages/ferramentas'
import {
  Caixas,
  PDVConfig,
  Pedidos,
  GraficoVendas,
  GraficoVendasMes,
  CaixaV2,
  CaixaV3,
  RelatorioItens,
  Cupons,
  FechamentosCaixa
} from '../../_pages/pdv'
import { ListStatus } from '../../_pages/pdv/pedidoStatus'
import { Itens, ItemCategorias, Recompra, ItemApplication } from '../../_pages/sortimento'
import { Sat, ImpressorasV2 } from '../../_pages/dispositivos'
import { ListaMusicas } from '../../_pages/musica'
import { ListaColecoes } from '../../_pages/colecoes'
import { ListaSegmentos } from '../../_pages/segmentos'
import { ListSites } from '../../_pages/site'
import SiteComponentes from '../../_pages/siteComponentes'
import ScheduleContainer from '../../_pages/site/schedule/scheduleContainer'
import { ListConectores } from '../../_pages/conector'
import { ListGaleria } from '../../_pages/galeria'
import ListConectoresDePedido from '../../_pages/conectorPedido/ListConectores'
import ListConectoresDeEnvio from '../../_pages/conectorEnvio/gatewayEnvio'
import ListConectoresDePagamento from '../../_pages/conectorPagamento/listGateway'
import { EntradaList, EstoqueEnderecos } from '../../_pages/estoque'
import { Atendimento, Auditoria, DashBoard } from '../../_pages/crm'

export default [
  {
    id: 1,
    nome: 'Exclusivo emoutech',
    icone: 'vpn_key',
    menu: true,
    paginas: [
      {
        id: '5b8579a0e904fa6ae3386857',
        component: ListaSistemas,
        nome: 'Sistemas',
        icone: 'laptop_windows',
        menu: true,
        path: '/emoutech/sistemas',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5cf12d3f8f32164628fbd08a',
        component: ListaColecoes,
        nome: 'Coleções',
        icone: 'laptop_windows',
        menu: true,
        path: '/emoutech/colecoes',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5ff88f833a918424c46edb3c',
        component: ListaSegmentos,
        nome: 'Segmentos',
        icone: 'view_list',
        menu: true,
        path: '/emoutech/segmentos',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 2,
    nome: 'Pessoa',
    icone: 'person',
    menu: true,
    paginas: [
      {
        id: '5b6a4ff8e57143c0660ba7f7',
        component: props => <ListaPessoa contexto={0} title="Lista de colaboradores" {...props} />,
        nome: 'Colaboradores',
        icone: 'list',
        menu: true,
        path: '/pessoa/colaboradores',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5b884c0a39bb9a05746bc451',
        component: props => <ListaPessoa contexto={1} title="Lista de clientes" {...props} />,
        nome: 'Clientes',
        icone: 'list',
        menu: true,
        path: '/pessoa/clientes',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5f62b37c250bc422ead6c6d4',
        component: ListaFornecedores,
        nome: 'Fornecedores',
        icone: 'list',
        menu: true,
        path: '/pessoa/fornecedores',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5d141186e2e58a0e1c322749',
        component: ListaEntregadores,
        nome: 'Entregadores',
        icone: 'motorcycle',
        menu: true,
        path: '/pessoa/entregadores',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5b6a505ee57143c0660ba7f9',
        component: Perfil,
        nome: 'Perfil',
        icone: 'person',
        menu: true,
        path: '/pessoa/perfil',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5ba4f850fd2f2b23e3e98064',
        component: Entidades,
        nome: 'Grupos de Pessoas',
        icone: 'group_work',
        menu: true,
        path: '/pessoa/entidades',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '60de03fe269a1c1de20db49d',
        component: Ponto,
        nome: 'Ponto',
        icone: 'group_work',
        menu: true,
        path: '/pessoa/ponto',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 3,
    nome: 'PDV',
    icone: 'dvr',
    menu: true,
    paginas: [
      {
        id: '5c635fec6da6c539a0e6d867',
        component: (props) => <Caixas {...props} caixaVersion="-v2"/>,
        nome: 'Caixas V2',
        icone: 'attach_money',
        menu: true,
        path: '/pdv/caixa-v2',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5c635fec6da6c539a0e6d867',
        component: CaixaV2,
        nome: 'Caixa V2',
        icone: 'attach_money',
        menu: false,
        path: '/pdv/caixa-v2/:caixaId',
        funcoes: ['admin', 'vendedor']
      },
      {
        id: '618035c008f5796946c176f0',
        component: (props) => <Caixas {...props} caixaVersion="-v3"/>,
        nome: 'Caixas V3',
        icone: 'attach_money',
        menu: true,
        path: '/pdv/caixa-v3',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '618035c008f5796946c176f0',
        component: CaixaV3,
        nome: 'Caixa V3',
        icone: 'attach_money',
        menu: false,
        path: '/pdv/caixa-v3/:caixaId',
        funcoes: ['admin', 'vendedor']
      },
      {
        id: '5be0cc8b77cc973c5a7698c3',
        component: PDVConfig,
        nome: 'Configurações',
        icone: 'settings',
        menu: true,
        path: '/pdv/config',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5c2e440c15506206b737c851',
        component: Pedidos,
        nome: 'Pedidos',
        icone: 'border_color',
        menu: true,
        path: '/pdv/pedidos',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5bff14ec05264c3a99aaaccb',
        component: GraficoVendas,
        nome: 'Gráfico de Venda Diária',
        icone: 'insert_chart_outlined',
        menu: true,
        path: '/pdv/relatorio/diario'
      },
      {
        id: '5bff41c295b7c925800d1a25',
        component: GraficoVendasMes,
        nome: 'Gráfico de Venda Mensal (D-1)',
        icone: 'insert_chart_outlined',
        menu: true,
        path: '/pdv/relatorio/mensal'
      },
      {
        id: '5cae9c967a31181eb1d2dae8',
        component: RelatorioItens,
        nome: 'Relatório de itens',
        icone: 'layers',
        menu: true,
        path: '/pdv/relatorio/itens'
      },
      {
        id: '5f6e98af8d98a1174efb5d1e',
        component: ListStatus,
        nome: 'Status',
        icone: 'border_color',
        menu: true,
        path: '/pdv/status',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5fff19554bd5810588115622',
        component: Cupons,
        nome: 'Cupons',
        icone: 'local_atm',
        menu: true,
        path: '/pdv/cupons',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '61e2389c621bf11c90521d62',
        component: FechamentosCaixa,
        nome: 'Fechamentos de Caixa',
        icone: 'layers',
        menu: true,
        path: '/pdv/fechamentos',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 4,
    nome: 'Financeiro',
    icone: 'monetization_on',
    menu: true,
    paginas: [
      {
        id: '5b6a505fe57143c0660ba7fb',
        component: DizimoContainer,
        nome: 'Lançar Dizimo',
        icone: 'monetization_on',
        menu: true,
        path: '/financeiro/dizimo',
        funcoes: ['criar', 'editar', 'deletar', 'Conferente']
      },
      {
        id: '5b6a505fe57143c0660ba7fc',
        component: ReceitaLancamento,
        nome: 'Lançar Receita',
        icone: 'monetization_on',
        menu: true,
        path: '/financeiro/receita',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5b6a505fe57143c0660ba7fd',
        component: DespesaLancamento,
        nome: 'Lançar Despesa',
        icone: 'monetization_on',
        menu: true,
        path: '/financeiro/despesa',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5b6a505fe57143c0660ba7fe',
        component: Extrato,
        nome: 'Extrato',
        icone: 'monetization_on',
        menu: true,
        path: '/financeiro/extrato',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5b6a5060e57143c0660ba7ff',
        component: Contas,
        nome: 'Contas',
        icone: 'exposure',
        menu: true,
        path: '/financeiro/contas',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5b6a5060e57143c0660ba800',
        component: Pendente,
        nome: 'Pendente de Aprovação',
        icone: 'exposure',
        menu: true,
        path: '/financeiro/pendente',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 5,
    nome: 'Documentos',
    icone: 'attachment',
    menu: true,
    paginas: [
      {
        id: '5b8ab3165d6bd4200cea204c',
        component: ModelosDocumentoss,
        nome: 'Modelos de Documento',
        menu: true,
        path: '/documento/modelos',
        icone: 'attachment',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '61060ed8d9a5cc3bf075905b',
        component: LayoutsDeImpressao,
        nome: 'Layouts de Impressão',
        menu: true,
        path: '/documento/printsLayouts',
        icone: 'print',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 6,
    nome: 'Produtos',
    icone: 'layers',
    menu: true,
    paginas: [
      {
        id: '5b8b507472122d93981642cc',
        component: Itens,
        nome: 'Itens',
        menu: true,
        path: '/produtos/itens',
        icone: 'list',
        funcoes: ['criar', 'editar', 'deletar']
      },
      // {
      //   id: '5ca3722ced645751a5d5d438',
      //   component: Componentes,
      //   nome: 'Componentes',
      //   menu: true,
      //   path: '/produtos/componentes',
      //   icone: 'list',
      //   funcoes: ['criar', 'editar', 'deletar']
      // },
      {
        id: '5f35b300ccba9e0d5843dcc9',
        component: ItemCategorias,
        nome: 'Categorias',
        menu: true,
        path: '/produtos/categorias',
        icone: 'category',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '6091a7aaf32b7e53842a1329',
        component: ItemApplication,
        nome: 'Aplicações',
        menu: true,
        path: '/produtos/aplicacoes',
        icone: 'category',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '60661cb1aa2f844938abc1dd',
        component: Recompra,
        nome: 'Recompra',
        menu: true,
        path: '/produtos/recompra',
        icone: 'shopping_cart',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 7,
    nome: 'Dispositivos',
    icone: 'devices_other',
    menu: true,
    paginas: [
      // {
      //   id: '5bdf8b150986052b10555d8a',
      //   component: Impressoras,
      //   nome: 'Impressoras',
      //   menu: true,
      //   path: '/dispositivos/impressoras',
      //   icone: 'print',
      //   funcoes: ['criar', 'editar', 'deletar']
      // },
      {
        id: '6072fe8f73cc6852dc4cd8fd',
        component: ImpressorasV2,
        nome: 'Impressoras',
        menu: true,
        path: '/dispositivos/impressoras-v2',
        icone: 'print',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '603aec4d8aa74200016474d9',
        component: Sat,
        nome: 'Sat Fiscal',
        menu: true,
        path: '/dispositivos/sat',
        icone: 'print',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 8,
    nome: 'Repertório',
    icone: 'music_note',
    menu: true,
    paginas: [
      {
        id: '5be60a2dd4c1d526145f6a0f',
        component: ListaMusicas,
        nome: 'Musicas',
        menu: true,
        path: '/repertorio/musicas',
        icone: 'queue_music',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 9,
    nome: 'Para o Desenvolvedor',
    icone: 'build',
    menu: true,
    paginas: [
      {
        id: '5b843af88a6fcf73411b5391',
        component: GerarObjectId,
        nome: 'Gerar ObjectId',
        menu: true,
        path: '/emoutech/objectId',
        icone: 'vpn_key'
      },
      {
        id: '5bcf57e22ab62e43ab7180c0',
        component: Criptografia,
        nome: 'Criptografia',
        menu: true,
        path: '/emoutech/criptografia',
        icone: 'security'
      }
    ]
  },
  {
    id: 10,
    nome: 'Fiscal',
    icone: 'attachment',
    menu: true,
    paginas: [
      {
        id: '5d65ab05a242d26f0c6c6869',
        component: GrupoFiscal,
        nome: 'NCM',
        menu: true,
        path: '/fiscal/ncm',
        icone: 'attachment',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 11,
    nome: 'Meu Site',
    icone: 'web',
    menu: true,
    paginas: [
      {
        id: '5e113a24d1564a2624c4d98a',
        component: ListSites,
        nome: 'Paginas',
        menu: true,
        path: '/site/paginas',
        icone: 'http',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '601c306d5951890001d7b25e',
        component: SiteComponentes,
        nome: 'Componentes',
        menu: true,
        path: '/site/componentes',
        icone: 'http',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '608b2d19c31e7000012663ec',
        component: ScheduleContainer,
        nome: 'Boletins',
        menu: true,
        path: '/site/boletins',
        icone: 'http',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 12,
    nome: 'Configurações',
    icone: 'attachment',
    menu: true,
    paginas: [
      {
        id: '5e4c836f5f92534f945dc556',
        component: props => <ListGaleria tipos={'0'} title="Lista de Imagens" {...props}/>,
        nome: 'Galeria',
        menu: true,
        path: '/config/galeria',
        icone: 'collections',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '6022eaecfe09cc04d83992ff',
        component: props => <ListGaleria tipos={'1,2,3,4,5,6'} title="Lista de Importações" {...props}/>,
        nome: 'Importações',
        menu: true,
        path: '/config/importacoes',
        icone: 'cloud_upload',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 13,
    nome: 'Conectores',
    icone: 'attachment',
    menu: true,
    paginas: [
      {
        id: '5e404cf54d5a9c0001e92157',
        component: ListConectores,
        nome: 'Mensagem',
        menu: true,
        path: '/config/conectores/mensagens',
        icone: 'send',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5edc0ae019ca564a5b37018f',
        component: ListConectoresDePagamento,
        nome: 'Pagamentos',
        menu: true,
        path: '/config/conectores/pagamentos',
        icone: 'money',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5ef7a2b18f00d86770ba207e',
        component: ListConectoresDeEnvio,
        nome: 'Delivery',
        menu: true,
        path: '/config/conectores/delivery',
        icone: 'local_shipping',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5f3b48c2ae782100014fa924',
        component: ListConectoresDePedido,
        nome: 'Pedido',
        menu: true,
        path: '/config/conectores/pedido',
        icone: 'link',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 14,
    nome: 'CRM',
    icone: 'person',
    menu: true,
    paginas: [
      {
        id: '60500bbb2bbe583dd5183a84',
        component: DashBoard,
        nome: 'DashBoard',
        icone: 'dashBoard',
        menu: true,
        path: '/crm/dashBoard',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '601c30e05951890001d7b25f',
        component: Atendimento,
        nome: 'Atendimento',
        icone: 'laptop_windows',
        menu: true,
        path: '/crm/atendimento',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '601cdc5b26369811848eff72',
        component: Auditoria,
        nome: 'Auditoria',
        icone: 'laptop_windows',
        menu: true,
        path: '/crm/auditoria',
        funcoes: ['criar', 'editar', 'deletar']
      }
    ]
  },
  {
    id: 15,
    nome: 'Estoque',
    icone: 'widgets',
    menu: true,
    paginas: [
      {
        id: '6021f641e0fc61282c031c00',
        component: EstoqueEnderecos,
        nome: 'Endereços',
        icone: 'view_quilt',
        menu: true,
        path: '/estoque/enderecos',
        funcoes: ['criar', 'editar', 'deletar']
      },
      {
        id: '5f62b384250bc422ead6c6d5',
        component: EntradaList,
        nome: 'Entrada/Saída de Itens',
        menu: true,
        path: '/estoque/entradas',
        icone: 'category',
        funcoes: ['criar']
      }
    ]
  }
]
