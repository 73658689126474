import {
  getCenter,
  getBreakLines,
  getSpaceBetween
} from '@em/lib/src/Printers/helpers'
import moment from 'moment'
import CANAIS from '../../../contstants/canais'

const lineDefaultLucida = {
  fontSize: 12,
  fontFamily: 'Lucida Console',
  type: 0
}

// const lineDefaultLucida = {
//   ...lineDefaultLucida,
//   fontFamily: 'Arial'
// }

const getMoneyFormat = (valor) => {
  return Number(valor).toFixed(2).replace('.', ',')
}

const getLinesByWords = (phrase, maxLength) => {
  const words = phrase.split(' ')
  const listLines = ['']
  words.forEach(word => {
    const lastLineIndex = listLines.length - 1
    const lastLine = listLines[lastLineIndex]
    if ((lastLine.length + word.length) < maxLength) {
      listLines[lastLineIndex] += `${word} `
    } else {
      listLines.push(word)
    }
  })

  return listLines
}

const layoutFoguete = pedido => {
  const lines = getBreakLines(
    {
      ...lineDefaultLucida,
      value: pedido?.cliente?.nome || '',
      fontStyle: 1
    }, 12)

  lines.push({ value: ' ', type: 0 })
  lines.push({
    ...lineDefaultLucida,
    value: `${CANAIS.find(c => c?.id === pedido?.canal)?.nome} #${pedido?.numero}`,
    fontStyle: 1
  })
  lines.push({
    ...lineDefaultLucida,
    value: '-----------------------------------------------------------------'
  })
  lines.push({ value: ' ', type: 0 })
  lines.push({
    ...lineDefaultLucida,
    value: `Tel: ${pedido?.cliente?.telefone || 'N/A'}`,
    fontSize: 10,
    fontStyle: 1
  })
  lines.push({
    ...lineDefaultLucida,
    value: `CPF: ${pedido?.cliente?.cpf || 'N/A'}`,
    fontSize: 10,
    fontStyle: 1
  })
  getLinesByWords(`Endereço: ${pedido?.endereco?.rua || 'N/A'}, ${pedido?.endereco?.numero || 'S/N'}`
    , 33)
    .forEach(line => {
      lines.push({
        ...lineDefaultLucida,
        value: line,
        fontSize: 10,
        fontStyle: 1
      })
    })
  getLinesByWords(`Bairro: ${pedido?.endereco?.bairro || 'N/A'}`, 33)
    .forEach(line => {
      lines.push({
        ...lineDefaultLucida,
        value: line,
        fontSize: 10,
        fontStyle: 1
      })
    })
  getLinesByWords(`Cidade: ${pedido?.endereco?.cidade || 'N/A'} - ${pedido?.endereco?.estado || 'N/A'}`
    , 33)
    .forEach(line => {
      lines.push({
        ...lineDefaultLucida,
        value: line,
        fontSize: 10,
        fontStyle: 1
      })
    })
  lines.push({
    ...lineDefaultLucida,
    value: `CEP: ${pedido?.endereco?.cep || 'N/A'}`,
    fontSize: 10,
    fontStyle: 1
  })
  getLinesByWords(`Complemento: ${pedido?.endereco?.complemento || 'N/A'}`, 33)
    .forEach(line => {
      lines.push({
        ...lineDefaultLucida,
        value: line,
        fontSize: 10,
        fontStyle: 1
      })
    })
  getLinesByWords(`Ref: ${pedido?.endereco?.referencia || 'N/A'}`, 33)
    .forEach(line => {
      lines.push({
        ...lineDefaultLucida,
        value: line,
        fontSize: 10,
        fontStyle: 1
      })
    })
  lines.push({
    value: ' ',
    type: 0
  })
  lines.push({
    ...lineDefaultLucida,
    value: '-----------------------------------------------------------------'
  })
  lines.push({
    value: ' ',
    type: 0
  })
  lines.push({
    ...lineDefaultLucida,
    value: `${pedido?.itens?.length} itens`,
    fontSize: 10
  })
  lines.push({
    value: ' ',
    type: 0
  })
  lines.push({
    ...lineDefaultLucida,
    value: '-- Observação do Pedido --',
    fontStyle: 1
  })

  lines.push({
    value: ' ',
    type: 0
  })

  getLinesByWords(`${pedido?.observacao || 'N/A'}`, 33)
    .forEach(text => {
      lines.push({
        ...lineDefaultLucida,
        value: text,
        fontSize: 10,
        fontStyle: 1
      })
    })

  lines.push({
    value: ' ',
    type: 0
  })
  lines.push({
    value: '---------------------------------------------------',
    ...lineDefaultLucida
  })
  lines.push({
    value: ' ',
    type: 0
  })
  lines.push({
    value: ' ',
    type: 0
  })
  lines.push()
  lines.push()
  lines.push()

  const itens = pedido?.itens?.map(item => {
    const newMontagens = item?.montagens?.filter(m => !!(m.opcoes?.find(opt => opt?.qtde && opt?.qtde !== 0)))
    return { ...item, montagens: newMontagens }
  })

  if (itens?.length > 0) {
    itens?.forEach(item => {
      const itemNome = item.nome.trim()
      const itemnomeWords = itemNome.split(' ')
      let line1 = ''
      let line2 = ''
      itemnomeWords.forEach(word => {
        if ((line1.length + word.length) < 18) {
          line1 += `${word} `
        } else {
          line2 += `${word} `
        }
      })
      lines.push(
        {
          ...lineDefaultLucida,
          value: getSpaceBetween(`${item?.qtde}x ${line1}`, `${getMoneyFormat(item?._valorTotal || item?.valorTotal || 0)}`, 12),
          fontStyle: 1
        })

      if (line2) {
        lines.push(
          {
            ...lineDefaultLucida,
            value: line2,
            fontStyle: 1
          }
        )
      }

      item?.montagens?.forEach(m => {
        if (m?.opcoes?.length > 0) {
          lines.push(
            {
              value: ' ',
              type: 0
            },
            {
              ...lineDefaultLucida,
              value: ` *${m.titulo}:`,
              fontStyle: 1
            }
          )
          m?.opcoes?.forEach(opt => {
            const optNome = opt.nome.trim()
            if (opt?.qtde < 0) {
              lines.push(
                {
                  ...lineDefaultLucida,
                  value: `  ${opt?.qtde}x ${optNome}`,
                  fontSize: 11
                }
              )
            } else if (opt?.qtde > 0) {
              lines.push(
                {
                  ...lineDefaultLucida,
                  value: `   ${opt.qtde + opt?.incluso}x ${opt.nome}`,
                  fontSize: 11
                }
              )
            }
          })
        }
      })
      lines.push(
        {
          value: ' ',
          type: 0
        },
        {
          value: ' ',
          type: 0
        }
      )
    })
  }

  lines.push(
    {
      ...lineDefaultLucida,
      value: '-----------------------------------------------------------------'
    },
    {
      value: ' ',
      type: 0
    },
    // {
    //   value: getSpaceBetween('Subtotal: ', `${Number(pedido?._valor || pedido?.valor || 0).toFixed(2).replace('.', ',')}`, 12),
    //   fontSize: 12,
    //   fontFamily: 'Lucida Console',
    //   type: 0
    // },
    // {
    //   value: getSpaceBetween('Desconto: ', `${Number(pedido?.discount || 0).toFixed(2).replace('.', ',')}`, 12),
    //   fontSize: 12,
    //   fontFamily: 'Lucida Console',
    //   type: 0
    // },
    // {
    //   value: getSpaceBetween('Taxa de Entrega: ', `${Number(pedido?.valorFrete || 0).toFixed(2).replace('.', ',')}`, 12),
    //   fontSize: 12,
    //   fontFamily: 'Lucida Console',
    //   type: 0
    // },
    {
      ...lineDefaultLucida,
      value: getSpaceBetween('Total: ', `${getMoneyFormat(pedido?._valorTotal || pedido?.valorTotal || 0)}`, 12),
      fontStyle: 1
    },
    {
      value: ' ',
      type: 0
    },
    {
      ...lineDefaultLucida,
      value: '-----------------------------------------------------------------'
    },
    {
      value: ' ',
      type: 0
    }
  )

  let valorTotalPago = 0

  pedido?.pagamentos?.forEach(item => {
    valorTotalPago += Number(item.valor || 0)
    lines.push(
      {
        ...lineDefaultLucida,
        value: getSpaceBetween(`${item.nome}:`, `${getMoneyFormat(item.valor || 0)}`, 12),
        fontStyle: 1
      }
    )
  })

  const troco = valorTotalPago - Number(pedido?._valorTotal || pedido?.valorTotal || 0)

  lines.push(
    {
      ...lineDefaultLucida,
      value: getSpaceBetween(`${troco < 0 ? 'Falta Pagar' : 'Troco'}: `, `${getMoneyFormat(troco < 0 ? (troco * -1) : troco)}`, 12),
      fontStyle: 1
    },
    {
      value: ' ',
      type: 0
    },
    {
      value: '-----------------------------------------------------------------',
      type: 0
    },
    {
      value: ' ',
      type: 0
    },
    {
      ...lineDefaultLucida,
      value: getCenter('Obrigado, esperamos que aproveite', 9),
      fontSize: 9
    },
    {
      ...lineDefaultLucida,
      value: getCenter('a sua refeição.', 9),
      fontSize: 9
    },
    {
      value: ' ',
      type: 0
    },
    {
      value: '-----------------------------------------------------------------',
      type: 0
    },
    {
      value: ' ',
      type: 0
    },
    {
      ...lineDefaultLucida,
      value: `Recebido: ${moment().format('llll')}`,
      type: 0
    },
    {
      value: ' ',
      type: 0
    },
    {
      value: '-----------------------------------------------------------------',
      type: 0
    }
  )

  return lines
}

export default layoutFoguete
