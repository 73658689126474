import React, { useRef, useState } from 'react'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import { EMTable } from '@em/lib/src'
import { Icon, IconButton } from '@material-ui/core'
import { BsUpload } from 'react-icons/bs'
import { MdImportExport } from 'react-icons/md'
import { VscFileCode, VscSearch } from 'react-icons/vsc'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'

import Title from '@em/lib/src/util/Title'
import { AddOrEditModal, AddOrEditModalManual } from './components'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import DanfeModal from './components/danfeModal'
import ResumeModal from './components/resumeModal'

const typesEntry = {
  0: 'Desmembramento',
  1: 'Entrada',
  2: 'Saída',
  3: 'Inventário'
}

const ListEstoqueEntrada = (props) => {
  const tableRef = useRef()
  const inputFileRef = useRef(null)

  const [modalAddOrEditManual, setModalAddOrEditManual] = useState({ open: false })
  const [modalEntradaXML, setModalEntradaXML] = useState({ open: false, data: null })
  const [modalDanfe, setModalDanfe] = useState({ open: false })
  const [modalResume, setModalResume] = useState({ open: false })
  const [inputKey, setInputKey] = useState(new Date().getTime())

  const loadItens = () => tableRef.current.onQueryChange({ page: 0 })

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (!file) {
      return null
    }
    var reader = new FileReader()
    reader.onload = (function (file) {
      return function (e) {
        setModalEntradaXML({ open: true, data: { xml: e.target.result } })
        setInputKey(new Date().getTime())
      }
    })(file)

    reader.readAsText(file)
  }

  return (
    <div>
      <Title
        title="Movimentações de Estoque"
        buttons={[
          {
            type: 'menu',
            icon: 'add',
            buttons: [
              {
                icon: <BsUpload />,
                label: 'Entrada de nota fiscal via XML',
                onClick: () => inputFileRef.current.click()
              },
              {
                icon: <MdImportExport />,
                label: 'Entrada e Sáida Manual',
                onClick: () => setModalAddOrEditManual({ open: true })
              }
            ]
          }
        ]}
      />

      <EMTable
        tableRef={tableRef}
        data={query => {
          const { page, pageSize } = query
          return axios.get('/api/estoqueentrada', { params: { offset: page, limit: pageSize } }).then(response => {
            return {
              data: response.lista,
              page,
              totalCount: response.paginacao.qtdeRows || 0
            }
          })
        }}
        columns={[
          {
            title: 'Fornecedor',
            field: 'fornecedor.nome'
          },
          {
            width: 100,
            title: 'Nota',
            field: 'nNF'
          },
          {
            width: 100,
            title: 'Tipo',
            field: 'tipo',
            render: dataRow => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {dataRow?.tipo === 1 ? <FaArrowCircleUp color="#64b5f6" size="18px" />
                  : dataRow?.tipo === 2 ? <FaArrowCircleDown color="#f00" size="18px" /> : ''
                }
                &nbsp;
                <div>{typesEntry[dataRow?.tipo]}</div>
              </div>
            )
          },
          {
            title: 'Observação',
            field: 'observacao'
          },
          {
            width: 150,
            title: 'Data da Entrada',
            field: 'dataCadastro',
            render: rowData => fnHelper.toDateTimeBR(rowData.dataCadastro)
          },
          {
            width: 150,
            title: 'Qtde de Produtos',
            field: 'itens.length'
          },
          {
            width: 100,
            render: dataRow => (
              <div>
                {dataRow?.xml &&
                  <IconButton
                    onClick={() => setModalDanfe({ open: true, data: dataRow })}
                    color="primary"
                  >
                    <VscFileCode />
                  </IconButton>
                }
                {!dataRow?.xml &&
                  <IconButton
                    onClick={() => setModalResume({ open: true, data: dataRow })}
                    color="primary"

                  >
                    <Icon>search</Icon>
                  </IconButton>
                }
              </div>
            )
          }
        ]}
      />

      {modalEntradaXML.open && (
        <AddOrEditModal
          data={modalEntradaXML.data}
          onHide={refresh => {
            setModalEntradaXML({ open: false })
            if (refresh === true) {
              loadItens()
            }
          }}
        />
      )}

      {modalDanfe.open &&
        <DanfeModal
          data={modalDanfe.data}
          onHide={refresh => {
            setModalDanfe({ open: false })
            if (refresh === true) {
              loadItens()
            }
          }}
        />
      }

      {modalResume.open &&
        <ResumeModal
          data={modalResume.data}
          onHide={refresh => {
            setModalResume({ open: false })
            if (refresh === true) {
              loadItens()
            }
          }}
        />
      }

      {modalAddOrEditManual.open &&
        <AddOrEditModalManual
          onHide={refresh => {
            setModalAddOrEditManual({ open: false })
            if (refresh === true) {
              loadItens()
            }
          }}
        />
      }

      <input
        ref={inputFileRef}
        key={inputKey}
        type="file"
        accept=".xml"
        onChange={handleChangeFile}
        style={{ display: 'none' }}
      />

    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  showMensagemConfirmacaoAction
}, dispatch)

export default connect(null, mapDispatchToProps)(ListEstoqueEntrada)
