import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import _ from 'lodash'
import ReactGA from 'react-ga'
import Home from '../../_pages/home'
import paginasDoSistema from './paginasDoSistema'
import { EMOUTECH_ID } from '../../contstants/systemConstants'

class Routes extends Component {
  getPaginasDoUsuario = () => {
    const { paginasPermitidas } = this.props.usuario.sistema
    const _paginasDoSistema = paginasDoSistema.map(modulo => {
      const paginas = []
      modulo.paginas.forEach(pagina => {
        const paginaPermitida = paginasPermitidas.find(f => f.id == pagina.id)
        if (paginaPermitida) {
          const funcoes = paginaPermitida.funcoes.find(x => x == 'All')
            ? pagina.funcoes || []
            : paginaPermitida.funcoes || []
          paginas.push({ ...pagina, funcoes })
        }
      })

      return { ...modulo, paginas }
    })

    return _paginasDoSistema
  };

  componentDidMount () {
    this.loadGA()
  }

  componentDidUpdate () {
    this.loadGA()
  }

  loadGA = () => {
    const page = window.location.hash
    ReactGA.set({ page })
    // ReactGA.pageview(page)
  }

  render () {
    const modulos = this.props.usuario.sistema.id === EMOUTECH_ID ? paginasDoSistema : this.getPaginasDoUsuario()
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        {modulos.map((modulo, idx) => modulo.paginas.map(pagina => (
          <Route
            key={idx}
            exact
            path={pagina.path}
            render={props => <pagina.component {...props} funcoes={pagina.funcoes} />}
          />
        )))}
        <Redirect to="/" />
      </Switch>
    )
  }
}

export default Routes
