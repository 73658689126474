import React, { Component } from 'react'
import {
  Typography,
  Icon,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  Grid
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OptionSelectOne from './optionSelectOne'
import OptionMultiple from './optionMultiple'
import { FormattedMessage } from 'react-intl'

class Componentizacao extends Component {
  onAddOrLess = (opt, qtde) => {
    opt.qtde = (opt.qtde || 0) + qtde
    const { item, setItem } = this.props
    setItem(item)
  }

  onSelectOne = (opcoes, opc) => {
    opcoes.forEach(o => {
      if (opc.nome == o.nome) {
        if (o._qtde > 0) o.qtde = 0
        else o.qtde = 1
      } else {
        o.qtde = 0
      }
      o.incluso = 0
    })
    const { item, setItem } = this.props
    setItem(item)
  }

  getValor (montagem) {
    let valor = 0
    let qtde = 0
    montagem.opcoes.forEach(opc => {
      const qtde2 = ((opc.qtde || 0) + (opc.incluso || 0))
      valor += opc.valor * qtde2
      qtde += qtde2
    })
    return { valor, qtde }
  }

  render () {
    const { item, expandedPanel, setExpandedPanel, model } = this.props
    return (
      <div>
        {item.montagens?.map((montagem, idx) => {
          const expanded = expandedPanel === idx || expandedPanel === undefined
          return (
            <ExpansionPanel
              key={idx}
              expanded={expanded}
              onChange={(a, b) => setExpandedPanel(b ? idx : null)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={1} justify="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      {/* <Grid item>
                        <Icon
                          style={{ marginRight: 10 }}
                          fontSize="large"
                          color={!montagem._valid ? 'error' : 'primary'}>
                          {!montagem._valid ? 'list' : 'playlist_add_check'}
                        </Icon>
                      </Grid> */}
                      <Grid item>
                        <strong style={{ color: montagem._valid ? 'black' : 'red' }}>{montagem.titulo}</strong>
                        <span>
                          <br />
                          <FormattedMessage
                            id="selected"
                            values={{ qtde: (montagem._qtde || 0) }}
                          />
                        </span>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {montagem.maximo > 0 &&
                      <span>
                        <FormattedMessage
                          id="optionMax"
                          values={{ qtde: montagem.maximo }}
                        />
                      </span>
                    }

                    {(montagem.minimo > 0 && (montagem._qtde < montagem.minimo)) &&
                      <span style={{ backgroundColor: 'white', color: '#d81b60' }}>
                        <br />
                        <FormattedMessage
                          id="optionRequired"
                          values={{ qtde: montagem.minimo }}
                        />
                      </span>
                    }
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails style={{ padding: 0 }}>
                {(montagem.maximo === 1)
                  ? <OptionSelectOne model={model} opcoes={montagem.opcoes} onSelectOne={this.onSelectOne} requered={montagem.maximo == 1} />
                  : <OptionMultiple
                    disabledAdd={(montagem._qtde || 0) >= (montagem.maximo || 0)}
                    model={model}
                    opcoes={montagem.opcoes}
                    onAddOrLess={this.onAddOrLess}
                  />
                }
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </div>
    )
  }
}

export default Componentizacao
