
const layouts = [
  { id: 'home', nome: 'Home' },
  { id: 'image_text', nome: 'Imagem e Texto' },
  { id: 'products', nome: 'Produtos' },
  { id: 'gallery', nome: 'Galeria' },
  { id: 'menu', nome: 'Menu' },
  { id: 'e-commerce', nome: 'E-commerce' },
  { id: 'form', nome: 'Formulário' },
  { id: 'filter', nome: 'Filtros' },
  { id: 'systems', nome: 'Sistemas' },
  { id: 'landing_pages', nome: 'Landing Pages' },
  { id: 'my_account', nome: 'Minha Conta' },
  { id: 'order', nome: 'Pedidos' },
  { id: 'contacts', nome: 'Contato' },
  { id: 'where', nome: 'Onde estamos' },
  { id: 'banner', nome: 'Banner' },
  { id: 'video', nome: 'Video' },
  { id: 'g_calendar', nome: 'Google Agenda' },
  { id: 'youtube', nome: 'Youtube' }
]

const layoutsModels = [
  {
    layout: 'menu',
    model: 1,
    modelName: 'Menu Hamburguer',
    fields: [
      {
        type: 'image',
        name: 'image',
        label: 'imagem',
        xs: 12
      },
      {
        type: 'json',
        name: 'style',
        label: 'Estilo (JSON)'
      },
      {
        type: 'json',
        name: 'styleActive',
        label: 'Estilo do item selecionado (JSON)'
      },
      {
        type: 'menu',
        name: 'menuTree',
        xs: 12
      }
    ]
  },
  {
    layout: 'menu',
    model: 2,
    modelName: 'Menu Tabs',
    fields: [
      {
        type: 'image',
        name: 'image',
        label: 'imagem',
        xs: 12
      },
      {
        type: 'json',
        name: 'style',
        label: 'Estilo (JSON)'
      },
      {
        type: 'json',
        name: 'styleActive',
        label: 'Estilo do item selecionado (JSON)'
      },
      {
        type: 'menu',
        name: 'menuTree',
        xs: 12
      }
    ]
  },
  {
    layout: 'form',
    model: 1,
    modelName: 'Default',
    fields: [
      {
        type: 'comboboxApi',
        name: 'conectorId',
        label: 'Selecione o Conector',
        api: 'api/conector/full'
      },
      {
        type: 'text',
        name: 'btn-send',
        label: 'Rótulo do botão Enviar'
      },
      {
        type: 'text',
        name: 'btnTks',
        label: 'Rótulo do botão Obrigado'
      },
      {
        type: 'text',
        name: 'redirectTks',
        label: 'Caminho da página após click no botão Obrigado'
      },
      {
        type: 'text',
        name: 'assunto',
        label: 'Assunto'
      },
      {
        type: 'textarea',
        name: 'textFormHTML',
        label: 'Texto do Formulário (HTML)'
      },
      {
        type: 'json',
        name: 'styleForm',
        label: 'Estilo do Formulário (JSON)'
      },
      {
        type: 'json',
        name: 'styleInput',
        label: 'Estilo do input (JSON)'
      },
      {
        type: 'json',
        name: 'styleBtn',
        label: 'Estilo do Botão Enviar e Obrigado (JSON)'
      },
      {
        type: 'json',
        name: 'gridContainer',
        label: 'Estilo do GridContainer (JSON)'
      },
      {
        type: 'json',
        name: 'styleInputLabel',
        label: 'Estilo da label do input  (JSON)'
      },
      {
        type: 'textarea',
        name: 'msgThankHtml',
        label: 'Mensagem de agradecimento (HTML)',
        xs: 12
      },
      {
        type: 'form',
        name: 'form',
        xs: 12
      }
    ]
  },
  {
    layout: 'home',
    model: 1,
    modelName: 'Default',
    fields: [
      {
        type: 'textarea',
        name: 'text',
        label: 'Texto dentro da imagem',
        xs: 12
      }
    ]
  },
  {
    layout: 'image_text',
    model: 1,
    modelName: 'Padrão',
    fields: [
      {
        type: 'textarea',
        name: 'text',
        label: 'Texto',
        xs: 12
      },
      { type: 'image', name: 'image', label: 'Imagem' },
      { type: 'text', name: 'image_width', label: 'Largura da Imagem' },
      { type: 'text', name: 'image_height', label: 'Altura da Imagem' }

    ]
  },
  {
    layout: 'products',
    model: 1,
    modelName: 'Galeria Grid',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 },
      {
        type: 'json',
        name: 'gridItem',
        label: 'Grid Item (JSON)',
        xs: 12,
        defaultJSON: {
          xs: 12,
          md: 6,
          lg: 4,
          xl: 3
        }
      },
      {
        type: 'combobox',
        name: 'view',
        label: 'Ação após o clicar no produto',
        options: [
          { id: 'nothing', nome: 'Nada' },
          { id: 'show', nome: 'Mostrar' },
          { id: 'sale', nome: 'Vender' },
          { id: 'redirect', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'text', name: 'redirectCheckout', label: 'Caminho da página de Checkout', xs: 6
      },
      { type: 'textarea', name: 'redirectTo', label: 'Redirecionar para' },
      { type: 'bool', name: 'allowsFilter', label: 'Permite Filtro' }
    ]
  },
  {
    layout: 'products',
    model: 2,
    modelName: 'Carrocel Simples',
    fields: [
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'number', name: 'timer', label: 'Tempo do slider' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 },
      { type: 'json', name: 'gridItem', label: 'Grid Item (JSON)', xs: 12 },
      {
        type: 'combobox',
        name: 'view',
        label: 'Visualização dos produtos',
        options: [
          { id: 'nothing', nome: 'Nada' },
          { id: 'show', nome: 'Mostrar' },
          { id: 'sale', nome: 'Vender' },
          { id: 'redirect', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'text', name: 'redirectCheckout', label: 'Caminho da página de Checkout', xs: 6
      },
      { type: 'textarea', name: 'redirectTo', label: 'Redirecionar para' },
      { type: 'bool', name: 'autoPlay', label: 'Slider Automático' }
    ]
  },
  {
    layout: 'products',
    model: 3,
    modelName: 'Carrocel Multi-Slide',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'number', name: 'slidesVisible', label: 'Qde de Imagens visíveis' },
      { type: 'number', name: 'timer', label: 'Tempo do slider' },
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 },
      {
        type: 'combobox',
        name: 'view',
        label: 'Visualização dos produtos',
        options: [
          { id: 'nothing', nome: 'Nada' },
          { id: 'show', nome: 'Mostrar' },
          { id: 'sale', nome: 'Vender' },
          { id: 'redirect', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'text', name: 'redirectCheckout', label: 'Caminho da página de Checkout', xs: 6
      },
      { type: 'textarea', name: 'redirectTo', label: 'Redirecionar para' },
      { type: 'bool', name: 'autoPlay', label: 'Slider Automático' }
    ]
  },
  {
    layout: 'products',
    model: 4,
    modelName: 'Carrocel Galeria',
    fields: [
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 },
      {
        type: 'combobox',
        name: 'view',
        label: 'Visualização dos produtos',
        options: [
          { id: 'nothing', nome: 'Nada' },
          { id: 'show', nome: 'Mostrar' },
          { id: 'sale', nome: 'Vender' },
          { id: 'redirect', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'text', name: 'redirectCheckout', label: 'Caminho da página de Checkout', xs: 6
      },
      { type: 'textarea', name: 'redirectTo', label: 'Redirecionar para' },
      {
        type: 'combobox',
        name: 'thumbnailPosition',
        label: 'Posição da thumbnail',
        options: [
          { id: 'top', nome: 'Topo' },
          { id: 'right', nome: 'Direita' },
          { id: 'bottom', nome: 'Baixo' },
          { id: 'left', nome: 'Esquerda' }
        ]
      },
      { type: 'number', name: 'timer', label: 'Tempo do slider' },
      { type: 'bool', name: 'autoPlay', label: 'Slider Automático' },
      { type: 'bool', name: 'showThumbnails', label: 'Aparecer Thumbnails' },
      { type: 'bool', name: 'showBullets', label: 'Aparecer Bullets' },
      { type: 'bool', name: 'showNav', label: 'Aparecer Botões de Navegação' }
    ]
  },
  {
    layout: 'products',
    model: 5,
    modelName: 'Lista',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 },
      { type: 'json', name: 'gridItem', label: 'Grid Item (JSON)', xs: 12 },
      {
        type: 'combobox',
        name: 'view',
        label: 'Visualização dos produtos',
        options: [
          { id: 'nothing', nome: 'Nada' },
          { id: 'show', nome: 'Mostrar' },
          { id: 'sale', nome: 'Vender' },
          { id: 'redirect', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'text', name: 'redirectCheckout', label: 'Caminho da página de Checkout', xs: 6
      },
      { type: 'textarea', name: 'redirectTo', label: 'Redirecionar para' }
    ]
  },
  {
    layout: 'products',
    model: 6,
    modelName: 'Card',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 },
      { type: 'json', name: 'gridItem', label: 'Grid Item (JSON)', xs: 12 },
      {
        type: 'json',
        name: 'styleCard',
        label: 'Estilização do Card (JSON)',
        xs: 12,
        placeholder: '{ "style": { "padding": 0 }, "elevation": 8 }'
      },
      {
        type: 'json',
        name: 'styleCardContent',
        label: 'Estilização do Espaço do Liquid (JSON)',
        xs: 12,
        placeholder: '{ "style": { "padding": 0 }}'
      },
      {
        type: 'combobox',
        name: 'view',
        label: 'Ação após o clicar no produto',
        options: [
          { id: 'nothing', nome: 'Nada' },
          { id: 'show', nome: 'Mostrar' },
          { id: 'sale', nome: 'Vender' },
          { id: 'redirect', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'text', name: 'redirectCheckout', label: 'Caminho da página de Checkout', xs: 6
      },
      { type: 'textarea', name: 'redirectTo', label: 'Redirecionar para', xs: 6 },
      {
        type: 'number', name: 'timeout', label: 'Temporizador para o Carousel', xs: 6
      },
      { type: 'bool', name: 'allowsFilter', label: 'Permite Filtro' },
      { type: 'bool', name: 'useCardMedia', label: 'Usar Card Media do Material' },
      { type: 'bool', name: 'useCarousel', label: 'Usar Carousel na Imagens' },
      { type: 'bool', name: 'autoPlay', label: 'Passar imagens automaticamente' },
      { type: 'bool', name: 'showBullets', label: 'Mostrar Bullets' }
    ]
  },
  {
    layout: 'g_calendar',
    model: 1,
    modelName: 'Padrão',
    fields: [
      { type: 'text', name: 'id', label: 'Id Agenda' },
      { type: 'number', name: 'limit', label: 'Limit' }
    ]
  },
  {
    layout: 'youtube',
    model: 1,
    modelName: 'Playlist',
    fields: [
      { type: 'text', name: 'id', label: 'Id da Playlist' },
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      {
        type: 'textarea', name: 'legendHTML', label: 'Tag Html Legenda do Video', xs: 12
      }
    ]
  },
  {
    layout: 'contacts',
    model: 1,
    modelName: 'Padrão',
    fields: [
      {
        type: 'comboboxApi', name: 'conectorId', label: 'Selecione o Conector', api: 'api/conector/full'
      }
    ]
  },
  {
    layout: 'gallery',
    model: 1,
    modelName: 'Galeria Grid',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'textarea', name: 'liquid', label: 'Liquid (HTML)', xs: 12 }

    ]
  },
  {
    layout: 'gallery',
    model: 2,
    modelName: 'Carrocel Simples',
    fields: [
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'number', name: 'timer', label: 'Tempo do slider' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'bool', name: 'autoPlay', label: 'Slider Automático' }
    ]
  },
  {
    layout: 'gallery',
    model: 3,
    modelName: 'Carrocel Multi-Slide',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'number', name: 'slidesVisible', label: 'Qde de Imagens visíveis' },
      { type: 'number', name: 'timer', label: 'Tempo do slider' },
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      { type: 'bool', name: 'autoPlay', label: 'Slider Automático' }
    ]
  },
  {
    layout: 'gallery',
    model: 4,
    modelName: 'Carrocel Galeria',
    fields: [
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      {
        type: 'combobox',
        name: 'thumbnailPosition',
        label: 'Posição da thumbnail',
        options: [
          { id: 'top', nome: 'Topo' },
          { id: 'right', nome: 'Direita' },
          { id: 'bottom', nome: 'Baixo' },
          { id: 'left', nome: 'Esquerda' }
        ]
      },
      { type: 'number', name: 'timer', label: 'Tempo do slider' },
      { type: 'bool', name: 'autoPlay', label: 'Slider Automático' },
      { type: 'bool', name: 'showThumbnails', label: 'Aparecer Thumbnails' },
      { type: 'bool', name: 'showBullets', label: 'Aparecer Bullets' },
      { type: 'bool', name: 'showNav', label: 'Aparecer Botões de Navegação' }
    ]
  },
  {
    layout: 'video',
    model: 1,
    modelName: '1 Video',
    fields: [
      {
        type: 'text', name: 'video', label: 'Link do video', xs: 12
      },
      { type: 'text', name: 'width', label: 'Largura do video' },
      { type: 'text', name: 'height', label: 'Altura do video' }
    ]
  },
  {
    layout: 'order',
    model: 1,
    modelName: 'Listar meus pedidos',
    fields: [
      { type: 'number', name: 'limit', label: 'Limit' },
      { type: 'text', name: 'redirectOrder', label: 'Caminho Pedido' }
    ]
  },
  {
    layout: 'order',
    model: 2,
    modelName: 'Acompanhar pedido',
    fields: [

    ]
  },
  {
    layout: 'e-commerce',
    model: 1,
    modelName: 'Carrinho de Compras',
    fields: [
      {
        type: 'textarea', name: 'liquidLogo', label: 'Logo - Liquid (HTML)', xs: 12
      },
      {
        type: 'icon', name: 'icon', label: 'Ícone do Carrinho', xs: 6
      },
      {
        type: 'text', name: 'redirect', label: 'Caminho da página de Checkout', xs: 6
      },
      {
        type: 'color', name: 'background_button', label: 'Cor de Fundo dos Botões', xs: 6
      },
      {
        type: 'color', name: 'color_button', label: 'Cor das Letras dos Botões', xs: 6
      },
      {
        type: 'color', name: 'color_icon', label: 'Cor dos ícones', xs: 6
      },
      {
        type: 'color', name: 'background_badge', label: 'Cor de Fundo da Badge', xs: 6
      },
      {
        type: 'color', name: 'color_badge', label: 'Cor do Conteúdo da Badge', xs: 6
      },
      {
        type: 'text', name: 'redirectRegister', label: 'Caminho da página de Cadastro de Usuário', xs: 6
      },
      {
        type: 'textarea', name: 'liquidEndereco', label: 'Mostrar o Endereço - Liquid (HTML)', xs: 12
      },

      {
        type: 'bool', name: 'login_with_facebook', label: 'Permitir login com o Facebook', xs: 6
      },
      {
        type: 'bool', name: 'showLogin', label: 'Permitir Login'
      },
      {
        type: 'bool', name: 'showSearch', label: 'Mostrar o Busca'
      },
      {
        type: 'bool', name: 'showShoppingCart', label: 'Mostrar o Carrinho'
      },
      {
        type: 'menu',
        name: 'menuTree',
        xs: 12
      }
    ]
  },
  {
    layout: 'e-commerce',
    model: 2,
    modelName: 'Checkout Padrão',
    fields: [
      {
        type: 'text', name: 'redirect', label: 'Caminho da página de Finalizar Pedido', xs: 6
      },
      {
        type: 'text', name: 'redirectProducts', label: 'Caminho da página de Produtos', xs: 6
      },
      {
        type: 'color', name: 'background_button', label: 'Cor de Fundo do Botão', xs: 6
      },
      {
        type: 'color', name: 'color_button', label: 'Cor das Letras do Botão', xs: 6
      }
    ]
  },
  {
    layout: 'e-commerce',
    model: 3,
    modelName: 'Formulário de Pagamento',
    fields: [
      {
        type: 'text', name: 'redirectRegister', label: 'Caminho da página de Cadastro de Usuário', xs: 6
      },
      {
        type: 'text', name: 'redirectProducts', label: 'Caminho da página de Produtos', xs: 6
      },
      {
        type: 'text', name: 'redirectHome', label: 'Caminho da página de Início', xs: 6
      },
      {
        type: 'text', name: 'contactPhone', label: 'Telefone para Contato', xs: 6
      },
      {
        type: 'text', name: 'contactEmail', label: 'Email para Contato', xs: 6
      },
      {
        type: 'color', name: 'background_button', label: 'Cor de Fundo dos Botões', xs: 6
      },
      {
        type: 'color', name: 'color_button', label: 'Cor das Letras dos Botões', xs: 6
      },
      {
        type: 'json',
        name: 'styleLabelDefault',
        label: 'Estilo da Label Padrão (JSON)'
      },
      {
        type: 'json',
        name: 'styleLabelActive',
        label: 'Estilo da Label Ativa (JSON)'
      },
      {
        type: 'json',
        name: 'styleLabelCompleted',
        label: 'Estilo da Label Completa (JSON)'
      },
      {
        type: 'json',
        name: 'styleConnectorDefault',
        label: 'Estilo do Progresso Padrão (JSON)'
      },
      {
        type: 'json',
        name: 'styleConnectorActive',
        label: 'Estilo do Progresso Ativo (JSON)'
      },
      {
        type: 'json',
        name: 'styleConnectorCompleted',
        label: 'Estilo do Progresso Completo (JSON)'
      },
      {
        type: 'json',
        name: 'styleCircleDefault',
        label: 'Estilo do Numerador Padrão (JSON)'
      },
      {
        type: 'json',
        name: 'styleCircleActive',
        label: 'Estilo do Numerador Ativo (JSON)'
      },
      {
        type: 'json',
        name: 'styleCircleCompleted',
        label: 'Estilo do Numerador Completo (JSON)'
      },
      {
        type: 'json',
        name: 'styleStepper',
        label: 'Estilo do Stepper (JSON)'
      },
      {
        type: 'json',
        name: 'styleTitle',
        label: 'Estilo da Barra de Título (JSON)'
      },
      {
        type: 'color', name: 'colorIcons', label: 'Cor dos Icones', xs: 6
      },
      {
        type: 'comboboxApi',
        name: 'financeiraId',
        label: 'Selecione o Gateway',
        api: '/api/ConectorFinanceira/full'
      },
      {
        type: 'combobox',
        name: 'checkout_type',
        label: 'Tipo de Checkout',
        options: [
          { id: 'lightBox', nome: 'Abrir LightBox (modal)' },
          { id: 'location', nome: 'Redirecionar' }
        ]
      },
      {
        type: 'bool', name: 'login_with_facebook', label: 'Permitir login com o Facebook', xs: 6
      },
      {
        type: 'bool', name: 'prod', label: 'Produção', xs: 6
      }
    ]
  },
  {
    layout: 'e-commerce',
    model: 4,
    modelName: 'Formulário de Cadastro',
    fields: [
      {
        type: 'text', name: 'redirectLogin', label: 'Caminho da página de Login', xs: 12
      },
      {
        type: 'color', name: 'background_button', label: 'Cor de Fundo dos Botões', xs: 6
      },
      {
        type: 'color', name: 'color_button', label: 'Cor das Letras dos Botões', xs: 6
      }
    ]
  },
  {
    layout: 'e-commerce',
    model: 5,
    modelName: 'Formulário de Login',
    fields: [
      {
        type: 'text', name: 'redirectRegister', label: 'Caminho da página de Cadastro de Usuário', xs: 6
      },
      {
        type: 'text', name: 'redirectTo', label: 'Caminho da página depois que Logar', xs: 6
      },
      {
        type: 'color', name: 'background_button', label: 'Cor de Fundo dos Botões', xs: 6
      },
      {
        type: 'color', name: 'color_button', label: 'Cor das Letras dos Botões', xs: 6
      },
      {
        type: 'bool', name: 'login_with_facebook', label: 'Permitir login com o Facebook', xs: 6
      }
    ]
  },
  {
    layout: 'e-commerce',
    model: 6,
    modelName: 'Filtros',
    fields: [
      {
        type: 'json',
        name: 'json_drawer',
        label: 'Estilo do Drawer'
      },
      {
        type: 'json',
        name: 'json_button_apply',
        label: 'Estilo do Botão Aplicar'
      },
      {
        type: 'json',
        name: 'json_button_clear',
        label: 'Estilo do Botão Limpar Filtros'
      },
      {
        type: 'json',
        name: 'json_items',
        label: 'Estilo da Linhas de Filtros'
      },
      {
        type: 'json',
        name: 'json_app_bar',
        label: 'Estilo da Faixa'
      },
      {
        type: 'json',
        name: 'json_chip',
        label: 'Estilo dos Chips com os Filtros'
      },
      {
        type: 'json',
        name: 'json_badge',
        label: 'Estilo do Badge (mobile)'
      },
      {
        type: 'icon', name: 'icon', label: 'Ícone do Menu de Filtros', xs: 6
      },
      {
        type: 'color', name: 'color_icon', label: 'Cor do Ícone', xs: 6
      },
      {
        type: 'image',
        name: 'image',
        label: 'Logo para o menu de Filtros',
        xs: 12
      }
    ]
  },
  {
    layout: 'systems',
    model: 1,
    modelName: 'Lista de Sistemas em Cards',
    fields: [
      { type: 'text', name: 'tags', label: 'Tags Filtro' },
      {
        type: 'textarea',
        name: 'redirectTo',
        label: 'Redirecionar para',
        xs: 6,
        placeholder: 'página/{id}'
      },
      { type: 'number', name: 'limit', label: 'Limit de Registros por vez' },
      { type: 'text', name: 'width', label: 'Largura das imagens' },
      { type: 'text', name: 'height', label: 'Altura das imagens' },
      {
        type: 'color',
        name: 'colorStars',
        label: 'Cor das Estrelas',
        xs: 12,
        placeholder: '#fcaf17'
      },
      { type: 'json', name: 'gridItem', label: 'Grid Item (JSON)', xs: 12 },
      {
        type: 'json',
        name: 'styleCardEmpresa',
        label: 'Estilização do Card Empresa(JSON)',
        xs: 12,
        placeholder: '{ "style": { "padding": 0 }, "elevation": 8 }'
      },
      { type: 'bool', name: 'allowsFilter', label: 'Permite Filtro' }
    ]
  },
  {
    layout: 'filter',
    model: 1,
    modelName: 'Filtro de Busca e Localidade',
    fields: [
      {
        type: 'textarea',
        name: 'redirectTo',
        label: 'Redirecionar para',
        xs: 6,
        placeholder: 'página para aplicar os filtros'
      },
      {
        type: 'icon',
        name: 'icon',
        label: 'Ícone do Campo de Texto',
        xs: 6,
        placeholder: 'página para aplicar os filtros'
      },
      {
        type: 'json',
        name: 'configInput',
        label: 'Estilização do Campo de Texto(JSON)',
        xs: 12,
        placeholder: ''
      },
      {
        type: 'json',
        name: 'configAutoComplete',
        label: 'Estilização dos Campos de Sugestão(JSON)',
        xs: 12,
        placeholder: ''
      },
      {
        type: 'json',
        name: 'configButton',
        label: 'Estilização do Botão(JSON)',
        xs: 12,
        placeholder: `{
          style: {
            backgroundColor: '#f00',
            color: '#fff',
            borderRadius: 4,
            width: 150,
            height: 38
          }
        }`
      },
      {
        type: 'bool',
        name: 'getLocalidades',
        label: 'Requisitar api de Localidades',
        xs: 6
      }
    ]
  },
  {
    layout: 'filter',
    model: 2,
    modelName: 'Filtro com Avatares/Imagens',
    fields: [
      {
        type: 'color',
        name: 'color',
        label: 'Cor dos Títulos',
        xs: 6
      },
      {
        type: 'textarea',
        name: 'redirectTo',
        label: 'Redirecionar para',
        xs: 6,
        placeholder: 'página para aplicar os filtros'
      },
      {
        type: 'json',
        name: 'configAvatar',
        label: 'Estilização dos Avatores(JSON)',
        defaultJSON: {
          style: {
            height: '50px',
            width: '50px'
          }
        },
        xs: 12
      },
      {
        type: 'bool',
        name: 'getSegmentos',
        label: 'Requisitar api de Segmentos',
        xs: 6
      },
      {
        type: 'bool',
        name: 'getCategorias',
        label: 'Requisitar api de Categorias',
        xs: 6
      }
    ]
  },
  {
    layout: 'filter',
    model: 3,
    modelName: 'Filtro Menu Lateral',
    fields: [
      {
        type: 'textarea',
        name: 'redirectTo',
        label: 'Redirecionar para',
        xs: 6,
        placeholder: 'página para aplicar os filtros'
      },
      {
        type: 'color',
        name: 'tituloColor',
        label: 'Cor dos Títulos Fixos',
        xs: 6
      },
      {
        type: 'color',
        name: 'textColor',
        label: 'Cor dos textos e ícones',
        xs: 6
      },
      {
        type: 'color',
        name: 'borderColor',
        label: 'Cor da borda(Marcador)',
        xs: 6
      },
      {
        type: 'bool',
        name: 'getSegmentos',
        label: 'Requisitar api de Segmentos',
        xs: 6
      },
      {
        type: 'bool',
        name: 'getCategorias',
        label: 'Requisitar api de Categorias',
        xs: 6
      }
    ]
  },
  {
    layout: 'filter',
    model: 4,
    modelName: 'Filtro BreadCrumb',
    fields: [
      {
        type: 'textarea',
        name: 'redirectTo',
        label: 'Redirecionar para',
        xs: 6
      },
      {
        type: 'textarea',
        name: 'redirectHome',
        label: 'Redericionar para Início',
        xs: 6
      },
      {
        type: 'json',
        name: 'configBreadCrumb',
        label: 'Estilização do BreadCrumb(JSON)',
        defaultJSON: {
          style: {
            color: '',
            background: ''
          },
          maxItems: 5
        },
        xs: 12
      },
      {
        type: 'json',
        name: 'configLink',
        label: 'Estilização dos Links/Labels(JSON)',
        defaultJSON: {
          style: {
            color: '',
            background: ''
          },
          color: 'inherit'
        },
        xs: 12
      },
      {
        type: 'bool',
        name: 'getSegmentos',
        label: 'Requisitar api de Segmentos',
        xs: 6
      },
      {
        type: 'bool',
        name: 'getCategorias',
        label: 'Requisitar api de Categorias',
        xs: 6
      }
    ]
  },
  {
    layout: 'landing_pages',
    model: 1,
    modelName: 'Landing Page Flechei',
    fields: [
      {
        type: 'textarea',
        name: 'redirectLogin',
        label: 'Rota da página de Login',
        xs: 6
      }
    ]
  },
  {
    layout: 'my_account',
    model: 1,
    modelName: 'Minha conta Tabs',
    fields: [
      {
        type: 'textarea',
        name: 'redirectToLogin',
        label: 'Rota da página de Login',
        xs: 6
      },
      {
        type: 'textarea',
        name: 'redirectToPolicyPrivate',
        label: 'Rota da página de Política de Privacidade',
        xs: 6
      },
      {
        type: 'bool',
        name: 'showTabEmail',
        label: 'Mostrar tab de alteração de Email',
        xs: 6
      },
      {
        type: 'bool',
        name: 'showTabSenha',
        label: 'Mostrar tab de alteração de Senha',
        xs: 6
      },
      {
        type: 'bool',
        name: 'showTabEnderecos',
        label: 'Mostrar tab de Endereços',
        xs: 6
      },
      {
        type: 'bool',
        name: 'showTabCupons',
        label: 'Mostrar tab de Cupons',
        xs: 6
      }
    ]
  }
]

export { layoutsModels, layouts }
