import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga'
import { show } from 'react-notification-system-redux'
import envConstants from './contstants/envConstants.json'
import axios from 'axios'
import { sairAction } from './actions/loginAction'
import App from './App'
import { getCookie } from '@em/lib/src/util/fnHelper'
import { axiosLambda } from './myAxios'

const InterceptorAxios = (props) => {
  axiosLambda.interceptors.request.use(
    request => {
      const token = getCookie('token')
      if (token) {
        request.headers.Authorization = token
      }

      request.headers['Content-Type'] = 'application/json'

      ReactGA.event({
        category: window.location.hash,
        action: request.method + ': ' + request.url.toLowerCase().replace('/api', 'api')
      })

      return request
    },
    error => {
      notifiqueErro(error, 'Erro na requisição')

      ReactGA.exception({
        description: JSON.stringify(error),
        fatal: true
      })

      return Promise.reject(error)
    }
  )

  axios.interceptors.request.use(
    request => {
      const token = getCookie('token')
      if (token) {
        request.headers.Authorization = token
      }

      request.headers['Content-Type'] = 'application/json'
      request.baseURL = envConstants[window.location.host].api

      ReactGA.event({
        category: window.location.hash,
        action: request.method + ': ' + request.url.toLowerCase().replace('/api', 'api')
      })

      return request
    },
    error => {
      notifiqueErro(error, 'Erro na requisição')

      ReactGA.exception({
        description: JSON.stringify(error),
        fatal: true
      })

      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    response => {
      configurarNotificacoes(response)
      if (response && response.data) {
        return response.data
      }
      return response
    },
    error => {
      if (error.response && error.response.status === 401) {
        props.sairAction()
        notifiqueErro(null, 'Sessão expirada')
      } else {
        notifiqueErro(error, 'Sem resposta')
      }
      return Promise.reject(error)
    }
  )

  const notifiqueErro = (error, title) => {
    if (error?.response) {
      configurarNotificacoes(error.response)
    } else if (error?.message) {
      props.show({ message: error.message, title: 'Erro não indentificado 1' }, 'error')
    } else {
      props.show({ message: 'Falha de comunicação com o servidor.', title: 'Erro não indentificado 2' }, 'error')
    }
  }

  const configurarNotificacoes = response => {
    if (response && response.data) {
      const mensagens = response.data.mensagens || []
      mensagens.forEach(mensagem => {
        switch (mensagem.tipo) {
          case 0:
            props.show({ message: mensagem.texto, title: 'Sucesso' }, 'success')
            break
          case 1:
            props.show({ message: mensagem.texto, title: 'Erro' }, 'error')
            break
          case 2:
            props.show({ message: mensagem.texto, title: 'Informação' }, 'info')
            break
          case 3:
            props.show({ message: mensagem.texto, title: 'Atenção' }, 'warning')
            break
          default:
            break
        }
      })
    }
  }

  return <App />
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    show,
    sairAction
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(InterceptorAxios)
