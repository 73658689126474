import { Button, Dialog, DialogActions, DialogContent, Grid, Icon, IconButton, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import Title from '@em/lib/src/util/Title'
import { NumberFormatMoneyBR, TextFieldPercent } from '../../../../_atoms/numberFormat'
import TextFielddMoneyBR from '../../../../_atoms/numberFormat/TextFieldMoneyBR'

function ApplyDiscountDialog ({
  onHide,
  pedido,
  onRefreshPedido
}) {
  const [discountAmount, setDiscountAmount] = useState(pedido?.discountAmount || 0)
  const [percent, setPercent] = useState(Number(((pedido?.discountAmount * 100) / pedido._valor).toFixed(2)) || 0)

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title title="Aplicar Desconto" buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            {[5, 10, 20, 30, 40, 50].map((v, idx) => {
              const selected = Number(((100 * discountAmount) / pedido._valor).toFixed(2)) === Number(v?.toFixed(2))
              return (
                <Button
                  key={idx}
                  variant="contained"
                  style={{
                    backgroundColor: selected ? '#228B22' : '#32CD32',
                    color: '#fff'
                  }}
                  onClick={() => {
                    const newDiscountAmount = Number(((pedido._valor * v) / 100).toFixed(2))
                    setDiscountAmount(newDiscountAmount)
                    setPercent(v)
                  }}
                >
                  {`${v}%`}
                </Button>
              )
            })}

          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
            <div style={{ color: '#ccc', margin: '0px 10px' }} >OU</div>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <TextFieldPercent
              label="Alterar Percentual de Desconto"
              size="small"
              value={percent}
              onValueChange={e => setPercent(e.target.value)}
            />
            <Tooltip placement="top" title="Calcular">
              <IconButton
                style={{ marginLeft: 5 }}
                onClick={() => {
                  const newDiscountAmount = Number(((pedido._valor * percent) / 100).toFixed(2))
                  setDiscountAmount(newDiscountAmount)
                }}
              >
                <Icon>exposure</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
            <div style={{ color: '#ccc', margin: '0px 10px' }} >OU</div>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <TextFielddMoneyBR
              label="Alterar Valor de Desconto"
              size="small"
              value={discountAmount}
              onValueChange={e => setDiscountAmount(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div style={{ color: '#aaa' }}>
          Base de cálculo: <NumberFormatMoneyBR value={pedido._valor} />
          <br />
          Valor de desconto à ser aplicado: <NumberFormatMoneyBR value={discountAmount} />
        </div>
        <div style={{ flex: 1 }} />
        <Button onClick={onHide} color="secondary">
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            pedido.discountAmount = discountAmount
            onRefreshPedido(pedido)
          }}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApplyDiscountDialog
