import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'

export default ({
  selected, onClick, numero, lugares,
}) => {
  const backgroundColor = selected ? '#333' : 'white'
  const color = selected ? 'white' : '#333'

  if (!onClick) { onClick = () => { } }

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      style={{ backgroundColor }}
    >
      <Grid item>
        <Typography style={{ color }} variant="h3">
          {' '}
          {numero}
        </Typography>
        <Typography style={{ color }} variant="subtitle1">{lugares && `${lugares} lugares`}</Typography>
      </Grid>
    </Button>
  )
}
