import React from 'react'
import { IconButton, Icon } from '@material-ui/core'
import _ from 'lodash'

export default props => {
  const { funcoes, onClick } = props
  return (
    <IconButton
      disabled={!_.includes(funcoes, 'editar')}
      onClick={onClick}
      color="primary"
    >
      <Icon>edit</Icon>
    </IconButton>
  )
}
