import React from 'react'
import CKEditor from 'ckeditor4-react'


export default ({ value, handlerChange, editorRef }) => {
  return (
    <CKEditor
      ref={editorRef}
      value={value}
      onChange={handlerChange}
      config={{
        language: 'pt',
        image_previewText: 'Imagem',
        removePlugins: 'image',
        extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
        extraPlugins: 'print,format,font,colorbutton,justify,image2,uploadimage',
      }}
    />
  )
}

