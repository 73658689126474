import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  Grid, Button, Dialog, DialogContent, DialogActions
} from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { TextFieldNumber, TextFieldPercent } from '../../../_atoms/numberFormat'

class CriarEditarDialog extends React.Component {
  state = {
    form: {
      nome: ''
    }
  }

  constructor (props) {
    super(props)
    if (props.data) {
      this.state = { form: props.data }
    }
  }

  handlerChange = e => {
    this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
  }

  onSave = () => {
    const { form } = this.state
    axios.post('api/entidade', form).then(response => {
      this.props.onReload()
    })
  }

  render () {
    const { form } = this.state
    const { onHide } = this.props
    return (
      <Dialog open maxWidth="sm" fullWidth>
        <Title
          title={`${form.id ? 'Editar' : 'Nova'} Entidade`}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: onHide }
          ]}
        />

        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Nome"
                name="nome"
                required
                value={form.nome || ''}
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldPercent
                fullWidth
                label="Desconto(%)"
                name="discount"
                value={form.discount || ''}
                onValueChange={this.handlerChange}
                decimalScale={2}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSave} color="primary" variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

CriarEditarDialog.propTypes = {
  data: PropTypes.object
}

export default CriarEditarDialog
