import React from 'react'

import {
  ExpansionPanel as ExpansionPanelMaterial,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const ExpansionPanel = props => (
  <ExpansionPanelMaterial defaultExpanded>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="subtitle1" color="textSecondary">
        {props.titulo}
      </Typography>
    </ExpansionPanelSummary>

    <ExpansionPanelDetails>
      <Grid container spacing={1}>
        {props.children}
      </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanelMaterial>
)

export default ExpansionPanel
