import moment from 'moment'
import {
  getCenter,
  getSpaceBetween
} from './helpers'

const layoutPedidaoFenix = pedido => {
  var lines = [
    {
      value: '================================================================'
    },
    {
      value: ' '
    },
    {
      value: getSpaceBetween(`Pedido: ${pedido.numero}`, `Data: ${moment(pedido.dataCadastro).format('DD/MM/YYYY HH:mm')}`),
      fontStyle: 1
    },
    {
      value: ' '
    },
    {
      value: `Cliente: ${pedido?.cliente?.nome || ''}`,
      fontStyle: 1
    },
    {
      value: `CPF/CNPJ: ${pedido?.cliente?.cpf || ''}`,
      fontStyle: 1
    },
    {
      value: `Valor: R$ ${Number(pedido?._valorTotal || pedido?.valorTotal || 0).toFixed(2).replace('.', ',')}`,
      fontStyle: 1
    },
    {
      value: `Pagamento: ${pedido?.pagamentos?.map(p => p.nome).join() || ''}`,
      fontStyle: 1
    },
    {
      value: ' '
    },
    {
      value: ' '
    },
    {
      value: getCenter('_______________________________')
    },
    {
      value: getCenter('Assinatura', 8)
    },
    {
      value: '.',
      top: 20
    }
  ]

  return lines
}

export default layoutPedidaoFenix
