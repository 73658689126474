import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Webcam from 'react-webcam'
import DialogActions from '@material-ui/core/DialogActions'

const styles = {}

class DialogWebCam extends React.Component {
    setRef = webcam => {
      this.webcam = webcam
    }

    capture = () => {
      const imageSrc = this.webcam.getScreenshot()
      this.props.onCapture(imageSrc)
    }

    render() {
      return (
        <div>
          <Webcam
            audio={false}
            height={350}
            ref={this.setRef}
            screenshotFormat="image/png"
            width={350}
          />
          <DialogActions>
            <Button onClick={this.capture} color="primary">
              Tirar foto
            </Button>
          </DialogActions>
        </div>
      )
    }
}

DialogWebCam.propTypes = {
  classes: PropTypes.object.isRequired,
  onCapture: PropTypes.func.isRequired,
}

export default withStyles(styles)(DialogWebCam)
