import { SHOWMENSAGEM, HIDEMENSAGEM } from '../contstants/actionsContants'

const INITIAL_STATE = {
  titulo: '',
  mensagem: '',
  open: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOWMENSAGEM:
      return { ...state, ...action.payload, open: true }
    case HIDEMENSAGEM:
      return { ...INITIAL_STATE, open: false }
    default:
      break
  }

  return state
}
