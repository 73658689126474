import somasCaixa2 from '@em/lib/src/Produtos/somasCaixa2'
import { isNullOrUndefined } from '@em/lib/src/util/fnHelper'
import {
  Avatar,
  Card,
  Chip,
  Collapse,
  Divider,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { FaPlane } from 'react-icons/fa'
import React, { useState } from 'react'
import { NumberFormatMoneyBR } from '../../../../_atoms/numberFormat'

function PedidoItensCard ({
  pedido,
  edicaoBloqueada,
  handleChangeItem,
  setValorPopover,
  setOpcoesMenu,
  setItemAtual,
  setIdxAtual
}) {
  const [openCompentes, setOpenComponentes] = useState({ open: false, idx: null })
  const itens = pedido.itens || []

  somasCaixa2(pedido)

  itens.forEach(item => {
    item._valor = (item.valor || 0) + (item.valorAux || 0)
    item.valor = parseFloat((item.valor || 0)?.toFixed(2))
  })

  const isMobile = useMediaQuery('(max-width:900px)')
  const isMicroMobile = useMediaQuery('(max-width:320px)')

  const renderCard = (pedidoItem, idx) => {
    const valorUnitarioSemDesconto = parseFloat((pedidoItem._valorTotalItemSemDesconto / pedidoItem.qtde).toFixed(2))
    const valorItem = parseFloat(pedidoItem.valor.toFixed(2))
    const valorComponentes = parseFloat((valorUnitarioSemDesconto - valorItem).toFixed(2))

    return (
      <Card
        elevation={0}
        style={{
          marginBottom: 5,
          padding: 8
        }}
      >
        <Grid container spacing={1} alignItems={!isMobile && 'center'}>
          <Grid item xs container spacing={1} alignItems={!isMobile && 'center'}>
            {/* {pedidoItem?.montagens?.length > 0 &&
              <Grid item>
                <IconButton size="small" onClick={() => setOpenComponentes({ open: openCompentes?.idx === idx ? !openCompentes.open : true, idx })}>
                  <Icon>{openCompentes?.idx === idx && openCompentes?.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                </IconButton>
              </Grid>
            } */}
            <Grid item>
              <Avatar variant="square"
                src={(pedidoItem?.foto || '').startsWith('http') ? pedidoItem?.foto : (global.bucket + pedidoItem?.foto)}
              >
                <Icon>add_a_photo</Icon>
              </Avatar>
            </Grid>
            <Grid item xs container alignItems={!isMobile && 'center'} spacing={2}>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography>{pedidoItem?.codigo ? `${pedidoItem?.codigo} - ` : ''}{pedidoItem?.nome}</Typography>
                  {pedidoItem?.viagem &&
                    <Tooltip title="Para viagem">
                      <div>
                        <FaPlane
                          style={{ height: 24, width: 24, color: 'green', float: 'left' }}
                        />
                      </div>
                    </Tooltip>
                  }
                  {pedidoItem.valorAux != 0 && !isNullOrUndefined(pedidoItem.valorAux) &&
                    <Chip size="small" style={{ color: pedidoItem.valorAux < 0 ? 'red' : 'green' }} label={
                      <NumberFormatMoneyBR value={pedidoItem.valorAux * pedidoItem.qtde} />
                    } />
                  }
                  {pedidoItem?.montagens?.length > 0 &&
                    <Chip
                      onClick={() => setOpenComponentes({ open: openCompentes?.idx === idx ? !openCompentes.open : true, idx })}
                      size="small"
                      style={{ color: 'blue' }}
                      label={
                        <NumberFormatMoneyBR value={(valorComponentes || 0) * pedidoItem.qtde} />
                      }
                      onDelete={() => setOpenComponentes({ open: openCompentes?.idx === idx ? !openCompentes.open : true, idx })}
                      deleteIcon={<Icon>{openCompentes?.idx === idx && openCompentes?.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>}
                    />
                  }
                </div>
              </Grid>
              <Grid item xs={12} sm={6} container alignItems={!isMobile && 'center'} spacing={2}>
                <Grid item xs={isMicroMobile ? 12 : 6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      size="small"
                      disabled={pedidoItem.qtde <= (pedidoItem?.multiplo || 1) || pedido.canal == 2 || (edicaoBloqueada)}
                      onClick={() => pedidoItem.qtde > 1 && handleChangeItem(pedidoItem, 'qtde', pedidoItem.qtde - (pedidoItem?.multiplo || 1))}
                    >
                      <Icon>remove</Icon>
                    </IconButton>
                    {pedidoItem.qtde}
                    <IconButton
                      size="small"
                      disabled={pedido.canal == 2 || (edicaoBloqueada)}
                      onClick={() => handleChangeItem(pedidoItem, 'qtde', pedidoItem.qtde + (pedidoItem?.multiplo || 1))}>
                      <Icon>add</Icon>
                    </IconButton>

                  </div>
                </Grid>
                <Grid item xs={isMicroMobile ? 12 : 6}>
                  <Chip
                    size="small"
                    disabled={(edicaoBloqueada) || pedido.canal === 2}
                    onClick={(e) => setValorPopover({
                      open: true,
                      valor: pedidoItem._valor + valorComponentes,
                      valorComponentes,
                      item: { ...pedidoItem, idx },
                      anchorEl: e.currentTarget
                    })}
                    label={
                      <>
                        <NumberFormatMoneyBR value={pedidoItem._valor + valorComponentes} /> por {pedidoItem.unidadeMedida || 'un'}
                      </>
                    }
                  />
                  <br />
                  <span style={{ fontSize: 14, fontWeight: 900, marginLeft: 20, whiteSpace: 'nowrap' }}>
                    <NumberFormatMoneyBR value={pedidoItem._valorTotal} />
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isMicroMobile ? 2 : 1}>
            <IconButton
              size="small"
              onClick={e => {
                setOpcoesMenu({ anchorEl: e.currentTarget })
                setItemAtual(pedidoItem)
                setIdxAtual(idx)
              }}
              disabled={pedido.canal == 2 || (edicaoBloqueada)}
            >
              <Icon>more_vert</Icon>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={openCompentes?.idx === idx && openCompentes?.open}>
              <Divider />
              {pedidoItem?.montagens?.map((monta, idx) => {
                if (monta._qtde > 0) {
                  return (
                    <div
                      key={idx}
                      style={{
                        padding: 20,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <strong>{monta.titulo}</strong>
                      <div style={{ marginLeft: 10 }}>
                        {monta.opcoes.map((opc, idx2) => {
                          if (opc._qtde > 0) {
                            return (
                              <Chip
                                size="small"
                                key={`${idx}-${idx2}`}
                                variant="outlined"
                                label={`${opc._qtde}x ${opc.nome}: R$ ${Number(opc.valor).toFixed(2)}`}
                                style={{ borderColor: 'blue', color: 'blue' }}
                              />
                            )
                          }
                          return null
                        })}
                      </div>
                    </div>
                  )
                }
              })}
              <Divider />
              {pedidoItem?.observacao && <p><b>Observação: </b>{pedidoItem?.observacao}</p>}
            </Collapse>
          </Grid>
        </Grid>
      </Card>
    )
  }

  return (
    <>
      {itens?.map((pi, idx) => renderCard(pi, idx))}
    </>
  )
}

export default PedidoItensCard
