import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Icon, Avatar } from '@material-ui/core'
import ManagementFilesModal from './managementFilesModal'

function UploadMultipleFiles ({
  savedImages,
  onSave
}) {
  const [managementFilesModal, setManegementFileModal] = useState({
    open: false
  })

  return (
    <>
      <IconButton
        onClick={() => setManegementFileModal({ open: true })}>
        {(savedImages || []).length == 0
          ? <div
            style={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              backgroundColor: '#eee',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontSize: 12
            }}
          >
            <Icon>
              add
            </Icon>
            <p>Adicione uma ou mais imagens</p>
          </div>
          : <Avatar
            src={`${global.bucket}${savedImages[0]}`}
            alt="Imagem do item"
            style={{ width: 100, height: 100 }}
          />
        }
      </IconButton>
      {managementFilesModal.open &&
        <ManagementFilesModal
          savedImages={savedImages ?? []}
          onHide={() => setManegementFileModal({ open: false })}
          onSave={s3Keys => {
            onSave(s3Keys)
            setManegementFileModal({ open: false })
          }}
        />
      }
    </>
  )
}

UploadMultipleFiles.propTypes = {
  savedImages: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired
}

export default UploadMultipleFiles
