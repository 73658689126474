import React, { PureComponent } from 'react'
import { Menu, Button, MenuItem } from '@material-ui/core'

class ButtonMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

    handleOpenMenu = event => this.setState({ anchorEl: event.currentTarget })

    handleClose = () => {
      this.setState({ anchorEl: null })
    }

    handleClickItem = callback => () => {
      this.setState({ anchorEl: null })
      if (callback) { callback() }
    }

    render() {
      const { anchorEl } = this.state
      const { children, items, component } = this.props
      return (
        <>

          {
                    component
                    && (
                    <div onClick={this.handleOpenMenu}>
                      {component}
                    </div>
                    )
                }

          {
                    !component
                    && (
                    <Button aria-haspopup="true" onClick={this.handleOpenMenu}>
                      {children}
                    </Button>
                    )
                }

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {
                        items && items.map((item, index) => <MenuItem key={index} onClick={this.handleClickItem(item.onClick)}>{item.text}</MenuItem>)
                    }
          </Menu>
        </>
      )
    }
}

export default ButtonMenu
