import React from 'react'
import { Tab, Tabs, Paper } from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import Mesas from './config/mesas'
import FormasDePagamento from './config/formasDePagamento'
import CaixasConfig from './config/caixasConfig'
import PdvPlugins from './config/pdvPlugins'

class PDVConfig extends React.Component {
  state = {
    tabIndex: 0
  };

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex })
  };

  render () {
    const { tabIndex } = this.state
    return (
      <div>

        <Title title="Configurações" />

        <Paper>
          <Tabs
            value={this.state.tabIndex}
            onChange={this.handleChange}
            variant="scrollable"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="Caixas" />
            <Tab label="Mesas" />
            <Tab label="Formas de pagamento" />
            <Tab label="Plugins" />
          </Tabs>
        </Paper>

        {tabIndex === 0 && <CaixasConfig />}
        {tabIndex === 1 && <Mesas />}
        {tabIndex === 2 && <FormasDePagamento />}
        {tabIndex === 3 && <PdvPlugins />}
      </div>
    )
  }
}

export default PDVConfig
