import React from 'react'
import PropTypes from 'prop-types'
import { ComboBoxData } from '.'
import { Grid } from '@material-ui/core'

function ComboBoxDataTree ({ handlerChangeData, parentId, nameText, nameValue, listSelecteds, listFull, label }) {
  if (!listSelecteds) {
    listSelecteds = []
  }

  if (!listFull) {
    listFull = []
  }

  const handlerDataItem = (item, idx) => {
    if (!item[nameValue]) {
      idx--
    } else {
      listSelecteds[idx] = item
    }
    handlerChangeData(listSelecteds.filter((x, _idx) => _idx <= idx))
  }

  const handlerDataItemADD = (item) => {
    if (item[nameValue]) {
      handlerChangeData([...listSelecteds, item])
    }
  }

  if (listSelecteds.length == 0) {
    const listBro = listFull.filter(x => !x[parentId])
    return (
      <Grid container spacing={1}>
        <Grid item>
          <ComboBoxData
            label={label}
            onChange={handlerDataItemADD}
            nameText={nameText}
            list={listBro}
          />
        </Grid>
      </Grid>
    )
  }

  const lastSelected = listSelecteds[listSelecteds.length - 1]
  const listChild = listFull.filter(x => !!x[parentId] && x[parentId] == lastSelected[nameValue])

  return (
    <Grid container spacing={1}>
      {listSelecteds && listSelecteds.map((item, idx) => {
        const listBro = listFull.filter(x => x[parentId] == item[parentId])
        return (
          <>
            <Grid item>
              <ComboBoxData
                key={idx}
                label={`${label} N${idx + 1}`}
                onChange={e => handlerDataItem(e, idx)}
                selected={item[nameValue]}
                nameText={nameText}
                noneText="Remover"
                list={listBro}
              />
            </Grid>
          </>
        )
      })}

      {(listChild && listChild.length > 0) &&
        <Grid item>
          <ComboBoxData
            key={`${listSelecteds.length}`}
            label={label}
            onChange={handlerDataItemADD}
            nameText={nameText}
            list={listChild}
          />
        </Grid>
      }

    </Grid>
  )
}

ComboBoxDataTree.propTypes = {
  parentId: PropTypes.string.isRequired,
  nameText: PropTypes.string.isRequired
}

ComboBoxDataTree.defaultProps = {
  parentId: 'parentId',
  nameText: 'name',
  nameValue: 'id',
  label: 'Categoria'
}

export default ComboBoxDataTree
