import React, { Component } from 'react'
import axios from 'axios'

import Title from '@em/lib/src/util/Title'
import GridContas from '../../_organisms/financeiro/contas/gridContas'
import CriaContaDialog from '../../_organisms/financeiro/contas/criaContaDialog'

class ContasContainer extends Component {
  state = {
    modal: false,
    contas: [],
  };

  componentDidMount() {
    this.loadContas()
  }

  loadContas = () => {
    axios.get('api/conta?exibeContaIndefinida=true').then(contas => {
      this.setState({ contas })
    })
  }

  render() {
    const { modal, contas } = this.state
    return (
      <div>
        <Title
          title="Contas"
          buttons={[
            {
              icon: 'add',
              onClick: () => this.setState({
                modal: true,
              }),
            },
          ]}
        />

        <GridContas ListaContas={contas} onReload={this.loadContas} />

        {modal && (
          <CriaContaDialog
            onHide={() => {
              this.setState({ modal: false })
            }}
            onHideSalvar={conta => {
              this.setState({ modal: false, contas: [...contas, conta] })
            }}
          />
        )}
      </div>
    )
  }
}

export default ContasContainer
