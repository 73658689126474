import _ from 'lodash'
import moment from 'moment'

const formatoDateTimeEn = 'YYYY-MM-DDTHH:mm:ss'
const formatoDateTimeBR = 'DD/MM/YYYY HH:mm'
const formatoDateBR = 'DD/MM/YYYY'

export function splitTextFirstAndLast (text) {
  if (text === undefined || text === null || text === '') {
    return ''
  }

  const textArray = text.split(' ')
  let returnText = text
  if (textArray.length > 1) {
    returnText = `${textArray[0]} ${textArray[textArray.length - 1]}`
  }
  return returnText
}

export function toDateBR (text) {
  if (!text) {
    return ''
  }
  const dtMomen = moment.utc(text, formatoDateTimeEn).local()
  if (!dtMomen.isValid()) {
    return text
  }

  return dtMomen.format(formatoDateBR)
  // const dateArray = text.split("T")[0].split("-");

  // if (!dateArray) return text

  // return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
}

export function toDateTimeBR (text) {
  if (!text) {
    return ''
  }
  const dtMomen = moment.utc(text, formatoDateTimeEn).local()
  if (!dtMomen.isValid()) {
    return text
  }

  return dtMomen.format(formatoDateTimeBR)
}

export function addOrEditByIndex (list, item) {
  if (!list) {
    return [item]
  }
  if (_.isNumber(item.index)) {
    return list.map((listItem, i) => {
      if (item.index === i) {
        return item
      }
      return listItem
    })
  }
  return [...list, item]
}

export function isNullOrUndefined (value) {
  return _.isNull(value) || _.isUndefined(value)
}

export function tryJsonParse (value) {
  try {
    return JSON.parse(value)
  } catch (error) {
    return {}
  }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cpfOrCnpjMask = value => {
  if (!value) {
    return ''
  }

  if (value && value.length <= 14) {
    return cpfMask(value)
  }

  return cnpjMask(value)
}

export const orderBy = (list = [], key = 'name', order = 'asc') => {
  let newList = []

  if (order === 'asc' && Array.isArray(list)) {
    newList = _.cloneDeep(list).sort((a, b) => {
      var nameA = a[key].toUpperCase()
      var nameB = b[key].toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  }

  if (order === 'desc' && Array.isArray(list)) {
    newList = _.cloneDeep(list).sort((a, b) => {
      var nameA = a[key].toUpperCase()
      var nameB = b[key].toUpperCase()
      if (nameA > nameB) {
        return -1
      }
      if (nameA < nameB) {
        return 1
      }
      return 0
    })
  }

  return newList
}


export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires;
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
