import React, { useState } from 'react'
import {
  Icon,
  IconButton,
  Avatar
} from '@material-ui/core';

import ShowImage from './showImage';

export default function ImageAvatar({
  data,
  showDelete,
  showSelect,
  onSelect,
  onDelete
}) {
  const [showImage, setShowImage] = useState({ open: false })
  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        onClick={() => setShowImage({ open: true, src: `${global.bucket}${data.key}` })}
      >
        <Avatar
          src={`${global.bucket}${data.key}`}
          alt="Imagem do item"
          style={{ width: 100, height: 100 }}
        />
      </IconButton>
      {showDelete === true &&
        <div style={{
          position: 'absolute',
          bottom: 10,
          right: 10
        }}>
          <IconButton
            size="small"
            style={{ backgroundColor: '#f00' }}
            onClick={() => onDelete(data)}
          >
            <Icon style={{ fontSize: 12, color: '#fff' }}>
              delete
            </Icon>
          </IconButton>
        </div>
      }
      {showSelect === true &&
        <div style={{
          position: 'absolute',
          top: 10,
          right: 10
        }}>
          <IconButton
            size="small"
            onClick={() => onSelect(data)}
          >
            <Icon style={{ fontSize: 18, color: data.selected && '#89bf04' }}>
              check_circle
            </Icon>
          </IconButton>
        </div>
      }
      {showImage.open &&
        <ShowImage src={showImage.src} onHide={() => setShowImage({ open: false })} />
      }
    </div>
  )
}
