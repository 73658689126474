import {
  Card,
  CardActionArea,
  Collapse,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
  Paper
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'

function CustomTree ({
  lista,
  actions,
  columns,
  keyParent,
  openId
}) {
  const key = keyParent || 'paiId'
  const [listaTree, setListaTree] = useState([])

  const findParents = showStart => {
    const item = lista.find(l => l.id === openId)

    let parents = []
    if (item && showStart) {
      parents = [item]
    }
    if (item && item[key]) {
      const addParent = (item) => {
        const parent = lista.find(p => p.id === item[key])
        parents = [...parents, parent]
        if (parent && parent[key]) {
          addParent(parent)
        }
      }
      addParent(item)
    }
    return parents
  }

  const parents = findParents()

  const addChildren = (paiId, list) => {
    return list.filter(l => l[key] === paiId).map(x => {
      return {
        ...x,
        children: addChildren(x.id, list),
        open: !!parents.map(p => p.id).includes(x[key]),
        border: x.id === openId
      }
    })
  }

  const loadTree = () => {
    const itemsPai = lista.filter(x => !x[key])
      .map(x => ({
        ...x,
        open: !!parents.map(p => p.id).includes(x.id)
      }))
    const newListaTree = itemsPai.map(s => {
      return {
        ...s,
        children: addChildren(s.id, lista)
      }
    })
    setListaTree(newListaTree)
  }

  const openChildren = id => {
    const mapChildren = (list, id2) => {
      return list.map(l => {
        if (l.id === id2) {
          return { ...l, open: !l.open }
        }
        return { ...l, children: mapChildren(l.children, id2) }
      })
    }
    const newListaTree = listaTree.map(s => {
      if (s.id === id) {
        return { ...s, open: !s.open }
      }
      return { ...s, children: mapChildren(s.children, id) }
    })
    setListaTree(newListaTree)
  }

  const renderTree = (list, marginLeft) => {
    return (list || []).map(i => {
      return (
        <Grid
          item
          xs={12}
          key={i.id}
        >
          <Card elevation={0}>
            <CardActionArea>
              <Grid container>
                <Grid item style={{ width: 54 }}>
                  {i.children?.length > 0 &&
                    <IconButton onClick={() => openChildren(i.id)}>
                      {i.open && <Icon style={{ color: '' }}>keyboard_arrow_down</Icon>}
                      {!i.open && <Icon style={{ color: '' }}>keyboard_arrow_right</Icon>}
                    </IconButton>
                  }
                </Grid>
                {(columns || []).map((col, idx) => {
                  return (
                    <Grid
                      item
                      xs={!col.width}
                      style={{ width: col.width }}
                      key={col.field + idx}
                    >
                      <ListItem
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          padding: '10px 0px'
                        }}
                      >
                        <div
                          style={{
                            marginLeft,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {i[key] && idx === 0 && <Icon>subdirectory_arrow_right</Icon>}
                          <ListItemText>
                            <span style={{ color: '' }}>{i[col.field]}</span>
                          </ListItemText>
                        </div>
                      </ListItem>
                    </Grid>
                  )
                })}
                <Grid item style={{ width: 180 }}>
                  {(actions || [])?.map(action => action.render(i))}
                </Grid>
              </Grid>
              <Divider />
            </CardActionArea>
            {i.children?.length > 0 &&
              <Collapse in={i.open}>
                {renderTree(i.children, (marginLeft + 15))}
              </Collapse>
            }
          </Card>
        </Grid>
      )
    })
  }

  useEffect(() => {
    loadTree()
  }, [lista])

  return (
    <Grid container spacing={0}>
      <Grid item style={{ width: 54 }} />
      {(columns || []).map((col, idx) => (
        <Grid item
          key={col.title + idx}
          xs={!col.width}
          style={{
            width: col.width,
            // borderRight: '1px solid #ccc',
            fontWeight: 'bold'
          }}
        >
          {col.title}
        </Grid>
      ))}
      {actions?.length > 0 && <Grid item style={{ width: 180 }} />}
      {listaTree.length > 0
        ? renderTree(listaTree, 5)
        : <Grid item xs={12}>
          <Paper style={{ padding: 10, textAlign: 'center' }}>
            Lista vazia
          </Paper>
        </Grid>
      }
    </Grid>
  )
}

export default CustomTree
