import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import { Icon, IconButton } from '@material-ui/core'

import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import ReactTable from '../../_atoms/ReactTable'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import CriaDocDialog from './addOrEditModal'

class ListaDocs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      listaDocs: [],
    }
  }

  componentDidMount () {
    this.loadDocs()
  }

    loadDocs = () => {
      axios.get('api/DocumentoModelo').then(res => {
        this.setState({
          listaDocs: res.lista
        })
      })
    }

    handleDeleteConfirmation = itemLista => {
      const { id } = itemLista

      axios.delete(`api/DocumentoModelo/${id}`).then(resp => {
        this.loadDocs()
      })
    }

    handleDelete = itemLista => {
      const model = {
        title: 'Excluir',
        mensagem: (
          <span>
                    Você realmente deseja excluir
            {' '}
            <strong>
              {' '}
              {itemLista.nome}
            </strong>
?
          </span>
        ),
        buttoes: [
          { text: 'EXCLUIR', onClick: () => this.handleDeleteConfirmation(itemLista) }
        ]
      }
      this.props.showMensagemConfirmacaoAction(model)
    };

    downloadPDF = doc => {
      const arr = doc.html.split('{{')
      const keys = []
      if (arr.length > 1) {
        arr.forEach(element => {
          if (_.includes(element, '}}')) { keys.push(element.split('}}')[0]) }
        })
      }

      const win = window.open()
      win.document.write('<html><head>')
      win.document.write(`<title>${doc.nome}</title>`)
      win.document.write('</head>')
      win.document.write('<body>')
      win.document.write(doc.html)
      win.document.write('</body></html>')
      win.print()
      win.document.close()
      win.close()
    }

    render () {
      const { listaDocs } = this.state
      const { openModal } = this.props
      return (
        <div>
          <ReactTable
            data={listaDocs}
            columns={[
              {
                accessor: 'nome',
                Header: 'Nome do Documento'
              },
              {
                accessor: 'ultimaAlteracao.data',
                Header: 'Data de Alteração',
                Cell: props => fnHelper.toDateTimeBR(props.value)
              },
              {
                accessor: 'ultimaAlteracao.pessoa.nome',
                Header: 'Alterado por'
              },
              {
                maxWidth: 165,
                Cell: props => (
                  <div>
                    <IconButton onClick={() => this.downloadPDF(props.original)}>
                      <Icon>print</Icon>
                    </IconButton>
                    <IconButton color="primary" onClick={() => this.props.openModal(props.original)}>
                      <Icon>edit_icon</Icon>
                    </IconButton>
                    <IconButton color="secondary" onClick={() => this.handleDelete(props.original)}>
                      <Icon>delete_icon</Icon>
                    </IconButton>
                  </div>
                )
              }]}
          />


        </div>
      )
    }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(ListaDocs)
