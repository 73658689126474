import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Title from '@em/lib/src/util/Title'
import List from './components/List'
import AddOrEditModal from './components/AddOrEditModal'

export default ({ funcoes }) => {
  const [enderecos, setEnderecos] = useState([])
  const [addOrEditModal, setAddOrEditModal] = useState({ open: false, data: null })
  const [openId, setOpenId] = useState(null)

  async function loadEnderecos () {
    axios.get('api/StockAddressing/full?parentId=all').then(response => {
      setEnderecos(response)
    })
  }

  async function addOrEditEndereco (endereco) {
    axios.post('api/StockAddressing', endereco).then(response => {
      loadEnderecos()
      setAddOrEditModal({ open: false })
      setOpenId(response.dados?.id)
    })
  }

  async function deleteEndereco (endereco) {
    axios.delete(`api/StockAddressing/${endereco.id}`).then(response => {
      loadEnderecos()
      setOpenId(endereco.parentId)
    })
  }

  useEffect(() => {
    loadEnderecos()
  }, [])

  return (
    <div>
      <Title
        title="Lista de Endereços do Estoque"
        buttons={[
          {
            icon: 'add',
            onClick: () => setAddOrEditModal({ open: true })
          }
        ]}
      />
      <List
        enderecos={enderecos}
        openModal={data => setAddOrEditModal({ open: true, data })}
        deleteEndereco={deleteEndereco}
        funcoes={funcoes}
        openId={openId}
      />
      {addOrEditModal.open &&
        <AddOrEditModal
          onHide={() => setAddOrEditModal({ open: false })}
          data={addOrEditModal?.data ?? {}}
          addOrEditEndereco={addOrEditEndereco}
        />
      }
    </div>
  )
}
