import React from 'react'
import { Dialog } from '@material-ui/core'

export default function ShowImage({ src, onHide }) {
  return (
    <Dialog open onClose={onHide}>
      <img src={src} alt='Item' />
    </Dialog>
  )
}
