import React, { Component } from 'react'
import { Icon, Tooltip } from '@material-ui/core'
import ReactTable from '../../../_atoms/ReactTable'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import * as fnHelper from '@em/lib/src/util/fnHelper'

class ListExtrato extends Component {
  render () {
    const { list } = this.props
    return (
      <ReactTable
        data={list}
        columns={[
          {
            accessor: 'dataCadastro',
            Header: 'Data de Lançamento',
            Cell: props => fnHelper.toDateTimeBR(props.value)
          },
          {
            accessor: 'dataPagamento',
            Header: 'Data de Pagamento',
            Cell: props => fnHelper.toDateBR(props.value)
          },
          {
            accessor: 'conta.nome',
            Header: 'Conta'
          },
          {
            accessor: 'observacao',
            Header: 'Observação'
          },
          {
            width: 50,
            accessor: 'status',
            Cell: props => props.value === 0 && (
              <Tooltip title="Pendente de aprovação">
                <Icon style={{ color: 'yellow' }}>warning</Icon>
              </Tooltip>
            )
          },
          {
            width: 50,
            accessor: 'tipo',
            Cell: props => (props.value === 1 ? (
              <Tooltip title="Despesa">
                <Icon style={{ color: 'red' }}>arrow_downward</Icon>
              </Tooltip>
            ) : props.value === 2 ? (
              <Tooltip title="Receita">
                <Icon style={{ color: 'green' }}>arrow_upward</Icon>
              </Tooltip>
            ) : (<div />))
          },
          {
            width: 120,
            accessor: 'valorLiquido',
            Header: 'Valor Líquido',
            Cell: props => <NumberFormatMoneyBR value={props.value} />
          }
        ]}
      />
    )
  }
}

export default ListExtrato
