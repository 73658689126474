export const getColumnsByFont = (fontSize = 8) => {
  let ttCols = 0
  switch (fontSize) {
    case 6:
      ttCols = 55
      break
    case 7:
      ttCols = 46
      break
    case 8:
      ttCols = 41
      break
    case 9:
      ttCols = 36
      break
    case 10:
      ttCols = 32
      break
    case 11:
      ttCols = 30
      break
    case 12:
      ttCols = 26
      break
    case 15:
      ttCols = 20
      break
    default:
      break
  }
  return ttCols
}

export const getCenter = (text, fontSize) => {
  const ttCols = getColumnsByFont(fontSize)

  const ttText = `${text}`.length
  const ttWhite = ttCols - ttText
  const ttWhiteLeft = ttWhite / 2
  let ret = ''

  for (let i = 0; i < ttWhiteLeft; i++) {
    ret += ' '
  }

  ret += text

  return ret
}

export const getAddSpacesRightSide = (text, ttCols, align = 'left') => {
  // adiciona espaços em branco à direita
  text = text.substr(0, ttCols)

  const ttText = text.length
  const ttWhite = ttCols - ttText
  let ret = ''

  if (align == 'left') {
    ret += text
    for (let i = 0; i < ttWhite; i++) {
      ret += ' '
    }
  } else {
    for (let i = 0; i < ttWhite; i++) {
      ret += ' '
    }
    ret += text
  }

  return ret
}

export const getSpaceBetween = (text1, text2, fontSize = 8) => {
  // coloca espaço entre dois textos

  const ttCols = getColumnsByFont(fontSize)
  var ttText = text1.length + text2.length
  var ttWhite = ttCols - ttText
  let ret = text1
  for (let i = 0; i < ttWhite; i++) {
    ret += ' '
  }
  ret += text2
  return ret
}

export const getAlignTextPerPosition = (arrayTexts) => {
  // alinha textos
  // const partlines = [
  //   {
  //     value: getAddSpacesRightSide('PRODUTO 123', 15)
  //   },
  //   {
  //     value: getAddSpacesRightSide('2', 4)
  //   },
  //   {
  //     value: getAddSpacesRightSide('R$ 10,50', 8)
  //   },
  //   {
  //     value: getAddSpacesRightSide('R$ 21,00', 8)
  //   }
  // ]

  let newLine = ''
  arrayTexts.forEach(partLine => {
    newLine += partLine.value
  })

  return newLine
}

export const getBreakLines = (line, fontSize) => {
  // retorna uma string com a quantidade de posições mesmo que em branco e quebra em linhas
  const length = getColumnsByFont(fontSize)
  if (line.value.length <= length) {
    return [line]
  } else {
    const lines = line.value.match(new RegExp('.{1,' + length + '}', 'g'))
    return lines.map(l => {
      const newLine = l.trim()
      return { ...line, value: newLine }
    })
  }
}
