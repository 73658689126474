import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button
} from '@material-ui/core'

import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { showMensagemConfirmacaoAction } from '../../../../actions/mensagemAction'
import Axios from 'axios'

function SplitItemModal ({
  data,
  onHide,
  ...props
}) {
  const [qdeSplit, setQdeSplit] = useState(0)

  const onSplit = () => {
    const calcSplit = Number(qdeSplit || 0) * Number(data?.transformTo?.qtdeUnity || 0)
    const model = {
      title: `Demembrar item ➜ ${data?.codigo} - ${data?.nome}`,
      mensagem: `Você confirma o demembramento de ${qdeSplit} unidade(s) ${data?.codigo} - ${data?.nome} em ${calcSplit} unidade(s) de ${data?.transformTo?.code} - ${data?.transformTo?.name}`,
      buttoes: [
        { text: 'Confirmar', onClick: () => splitItemConfirmed() }
      ]
    }

    props.showMensagemConfirmacaoAction(model)
  }

  const splitItemConfirmed = () => {
    Axios.post(`/api/EstoqueEntrada/dismember/${data?.id}/${qdeSplit}`)
      .then(resp => {
        onHide(true)
      })
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={'Demembrar Item ➜ ' + data?.codigo}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <p>
              Cada unidade deste item pode ser transformada em {data?.transformTo?.qtdeUnity} unidades do item {data?.transformTo?.code} - {data?.transformTo?.name}
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              value={qdeSplit}
              onChange={e => setQdeSplit(e.target.value)}
              label={'Quantas unidades deseja desmembrar?'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!qdeSplit}
          onClick={onSplit}
        >
          Desmembrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    showMensagemConfirmacaoAction
  },
  dispatch
)

export default connect(null, mapDispatchToProps)(SplitItemModal)
