import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'
import { TextFieldTelefone } from '../../../_atoms/numberFormat'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const styles = () => ({})

class TabTelefoneCriarEditarDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        tipo: 'phone_android',
        confianca: 10,
        whatsAppId: 'y',
        ...props.data
      }
    }
  }

  handlerChange = e => {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    })
  };

  render () {
    const { form } = this.state
    const { data } = this.props
    const BUTTON_TEXT = (data && 'Editar') || 'Criar'
    return (
      <Dialog open>
        <Title title={`${(data && 'Editar') || 'Criar'} telefone`} />
        <DialogContent style={{ padding: 30 }}>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={12} md={4}>
              <ComboBoxData
                label="Tipo"
                onChange={e => this.handlerChange({ target: { name: 'tipo', value: e.id } })}
                selected={form.tipo}
                list={[
                  { id: 'phone_android', nome: 'Celular' },
                  { id: 'home', nome: 'Residencial' },
                  { id: 'business', nome: 'Comercial' }
                ]}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextFieldTelefone
                value={form.numero}
                name="numero"
                label="Telefone"
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={6} md={4}>

              <FormControlLabel
                control={
                  <Checkbox
                    onChange={e => {
                      this.handlerChange({ target: { name: 'whatsAppId', value: e.target.checked ? 'y' : '' } })
                    }}
                    checked={!!form.whatsAppId}
                  />
                }
                label={'Whatsapp'}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                name="observacao"
                fullWidth
                label="Observação"
                multiline
                value={(form && form.observacao) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onHide} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => this.props.onAdd(form)} color="primary" variant="contained">
            {BUTTON_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TabTelefoneCriarEditarDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default withStyles(styles)(TabTelefoneCriarEditarDialog)
