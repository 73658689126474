import Axios from 'axios'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React from 'react'
import { EMTable } from '@em/lib/src'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import { Chip, Icon, IconButton, Tooltip } from '@material-ui/core'

import CanaisList from '../../../contstants/canais'
import StatusCaixaList from '../../../contstants/statusCaixa'
import StatusEntregaList from '../../../contstants/statusEntrega'
import { axiosEMUtility } from './../../../myAxios'
import { showMensagemConfirmacaoAction } from './../../../actions/mensagemAction'

const RangeSimplesNacional = {
  1: 4,
  2: 7.3,
  3: 9.5,
  4: 10.70,
  5: 14.30,
  6: 19.00
}

function List ({
  tableRef,
  filter,
  openModal,
  sistema,
  refresh,
  ...othersProps
}) {
  const setTaxDoItem = async (order) => {
    const itensId = order?.itens?.map(x => x.id).join(',')

    if (itensId) {
      const itensResponse = await Axios.get(`api/item/${itensId}?withNCM=true`)
      order.itens.map(it => {
        it.tax = { ...itensResponse.find(x => x.id == it.id)?.tax }
        it.ean = itensResponse.find(x => x.id == it.id)?.ean
        return it
      })
    }
  }

  const emitirCupomFiscal = async (pedido) => {
    await setTaxDoItem(pedido)
    const pICMS = RangeSimplesNacional[sistema?.tax?.range]
    const response = await axiosEMUtility.post('/api/Sat/EnviarDadosVenda', { ...pedido, pICMS })
    pedido.cFe = response.data.dados.chaveConsulta

    Axios.post('api/pedido', pedido).then(() => refresh())
  }

  const handleCopy = text => {
    const copyText = document.getElementsByTagName('html')[0]
    const input = document.createElement('input')
    input.id = 'inp'
    input.value = text
    copyText.appendChild(input)

    const copy = document.getElementById('inp')
    copy.select()
    document.execCommand('Copy')

    copyText.removeChild(input)
  }

  const confirmEmissao = (pedido) => {
    const model = {
      title: 'Emitir CFe',
      mensagem: 'Você realmente deseja emitir um cupom fiscal pra esse pedido?',
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => {
            emitirCupomFiscal(pedido)
          }
        }
      ]
    }
    othersProps.showMensagemConfirmacaoAction(model)
  }

  const confirmCancel = (pedido) => {
    const model = {
      title: 'Cancelar CFe',
      mensagem: 'Você realmente deseja cancelar o cupom fiscal desse pedido?',
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => {
            cancelarCupomFiscal(pedido)
          }
        }
      ]
    }
    othersProps.showMensagemConfirmacaoAction(model)
  }

  const cancelarCupomFiscal = (pedido) => {
    if (pedido.cFe) {
      axiosEMUtility.post(`/api/Sat/CancelarUltimaVenda?chave=${pedido.cFe}`)
        .then(() => {
          pedido.cFe = 'CANCELADO - ' + pedido.cFe
          Axios.post('api/pedido', pedido).then(() => refresh())
        })
    }
  }

  return (
    <EMTable
      tableRef={tableRef}
      data={async query => {
        const { page, pageSize } = query
        const params = {
          ...filter,
          canais: (filter.canais || []).join(','),
          offset: page,
          limit: pageSize
        }

        const response = await Axios.get('api/pedido', { params })

        return {
          data: response.lista,
          page,
          totalCount: response?.paginacao?.qtdeRows || 0
        }
      }}
      columns={[
        {
          title: 'Pedido',
          field: 'numero',
          width: 100
        },
        {
          title: () => <div style={{ textAlign: 'center' }}>Canal</div>,
          field: 'canal',
          width: 100,
          render: props => {
            const canal = CanaisList.find(x => x.id == props.canal)
            return (
              <div style={{ textAlign: 'center' }}>
                <Chip variant="outlined" label={canal?.nome} />
              </div>
            )
          }
        },
        {
          title: () => <div style={{ textAlign: 'center' }}>Status</div>,
          render: props => {
            const sCaixa = StatusCaixaList.find(x => x.id == props.statusCaixa)
            const sEntrega = StatusEntregaList.find(x => x.id == props.statusEntrega)
            let st = sCaixa?.nome
            if (sEntrega?.id > 0) {
              st = (
                <center>
                  {st}
                  <br />
                  {sEntrega?.nome}
                </center>
              )
            }
            return (
              <div style={{ textAlign: 'center' }}>
                <Chip variant="outlined" label={st} />
              </div>
            )
          }
        },
        {
          title: 'Criação',
          field: '',
          render: props => (
            <div>
              <span>{props.operador && props.operador?.nome}</span>
              <br />
              <span>{fnHelper.toDateTimeBR(props.dataCadastro)}</span>
            </div>
          )
        },
        {
          title: 'Faturamento',
          field: 'faturamento',
          render: props => (
            props.faturamento && (
              <div>
                <span>{props.faturamento?.operadorNome}</span>
                <br />
                <span>{fnHelper.toDateTimeBR(props.faturamento?.data)}</span>
              </div>
            )
          )
        },
        {
          title: 'Valor Total',
          field: 'valorTotal',
          render: props => <NumberFormatMoneyBR value={props.valorTotal} />
        },
        {
          title: 'Cliente',
          render: props => (
            <div>
              {props.cliente && props.cliente?.nome && (
                <span>
                  {props.cliente?.nome}
                </span>
              )}
            </div>
          )
        },
        {
          title: 'Observação',
          render: props => (
            <div>
              {props.cliente && props.cliente?.cpf && (
                <span>
                  CPF NA NOTA:
                  {props.cliente?.cpf}
                </span>
              )}
            </div>
          )
        },
        // {
        //   title: 'Chave da Nota',
        //   field: 'cFe',
        //   width: 200
        // },
        {
          width: 170,
          render: props => {
            const cfeCancelada = props?.cFe?.startsWith('CANCELADO')
            return (
              <div style={{ display: 'flex' }}>
                <Tooltip
                  title="Imprimir"
                >
                  <IconButton
                    onClick={() => openModal('imprimirModal', props)}
                    color="primary"
                  >
                    <Icon>print</Icon>
                  </IconButton>
                </Tooltip>
                {!props?.cFe &&
                  <Tooltip
                    title="Emitir Cupom Fiscal"
                  >
                    <IconButton
                      onClick={() => confirmEmissao(props)}
                      color="primary"
                      disabled={props.statusCaixa === 4}
                    >
                      <Icon>gavel</Icon>
                    </IconButton>
                  </Tooltip>
                }
                {props?.cFe &&
                  <Tooltip
                    title={`CFe ${cfeCancelada ? 'Cancelada' : 'Gerada'}! Clique para copiar.`}
                  >
                    <IconButton
                      onClick={() => handleCopy(props.cFe)}
                      color="primary"
                    >
                      <Icon style={{ color: cfeCancelada ? '#f00' : '#03c303' }}>receipt_long</Icon>
                    </IconButton>
                  </Tooltip>
                }
                <Tooltip
                  title="Cancelar Cupom Fiscal"
                >
                  <IconButton
                    onClick={() => confirmCancel(props)}
                    color="primary"
                    disabled={(props.statusCaixa === 4 || !props.cFe || cfeCancelada)}
                  >
                    <Icon>cancel</Icon>
                  </IconButton>
                </Tooltip>
                <IconButton onClick={() => openModal('modalPedidoDetalhe', props)} color="primary">
                  <Icon>search</Icon>
                </IconButton>
              </div>
            )
          }
        }
      ]}
    />
  )
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showMensagemConfirmacaoAction
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(List)
