import React, { PureComponent } from 'react'
import _ from 'lodash'
import { Grid, FormControlLabel, Checkbox, Chip, Icon, Tooltip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { UploadMultipleFiles } from '@em/lib/src'
import * as fnHelper from '@em/lib/src/util/fnHelper'

import { ComboBoxData, ComboBoxAPI, ComboBoxDataTree } from '../../../../_atoms/combobox'
import listaUnidadeDeMedida from '../../../../contstants/unidadeDeMedida'
import { TextFieldMoneyBR, TextFieldEAN } from '../../../../_atoms/numberFormat'
import { DatePicker, TextField } from '../../../../_atoms'
import UploadImageDialog from '../../../../_atoms/uploadImageDialog'

class TabGeral extends PureComponent {
  state = {
    modalTag: { open: false },
    modalUpload: { open: false }
  };

  render () {
    const { modalUpload } = this.state
    const { handlerChange, allTags, allCategorias, form, showError, handleCopy, copied } = this.props
    const {
      nome,
      unidadeMedida,
      codigo,
      categorias,
      ean,
      valorDe,
      valor,
      ativo,
      controlaEstoque,
      tags,
      descricao,
      foto,
      fotos,
      descricaoHtml,
      codes,
      garantia,
      marca,
      ultimaCompra,
      ultimaVenda,
      externalCode,
      costPrice,
      minAmount,
      provider,
      componente,
      multiplo
    } = form

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <center>
              <UploadMultipleFiles
                savedImages={fotos}
                onSave={s3Keys => handlerChange({ target: { name: 'fotos', value: s3Keys } })}
              />
              {/* <IconButton
                color="primary"
                onClick={() => this.setState({ modalUpload: { open: true } })}
              >
                <Avatar
                  variant="rounded"
                  style={{ width: 97, height: 97 }}
                  alt="Logo"
                  src={`${global.bucket}${foto}`}
                />
              </IconButton> */}
            </center>
            {form?.id &&
              <center>
                <Tooltip
                  title={copied === form?.id ? 'Copiado!' : ''}
                  arrow
                  placement='right'
                  open={!!(copied === form?.id)}
                >
                  <Chip
                    label={form?.id}
                    onDelete={() => handleCopy(form?.id)}
                    variant="outlined"
                    color="primary"
                    deleteIcon={<Icon>{copied === form?.id ? 'check_circle' : 'content_copy'}</Icon>}
                  />
                </Tooltip>
              </center>
            }
            <div style={{ height: 10 }} />
            <Autocomplete
              fullWidth
              multiple
              id="tags"
              options={allTags || []}
              defaultValue={tags || []}
              freeSolo
              onChange={(a, b) => handlerChange({ target: { name: 'tags', value: b } })}
              renderTags={(value, getTagProps) => value.map((option, index) => (
                <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
              ))}
              renderInput={params => (
                <TextField
                  {...params}
                  multiple
                  variant="filled"
                  label="Tags"
                  name="tag"
                  onChange={handlerChange}
                  // value={this.props.tag}
                  placeholder="Nova tag"
                  fullWidth
                />
              )}
            />

            <div>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={ativo}
                    onChange={e => handlerChange({
                      target: { name: 'ativo', value: e.target.checked }
                    })}
                  />
                )}
                label="Ativo"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={controlaEstoque}
                    onChange={e => handlerChange({
                      target: { name: 'controlaEstoque', value: e.target.checked }
                    })}
                  />
                )}
                label="Controla Estoque"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={componente}
                    onChange={e => handlerChange({
                      target: { name: 'componente', value: e.target.checked }
                    })}
                  />
                )}
                label="Componente"
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={multiplo > 1}
                    onChange={e => handlerChange({
                      target: { name: 'multiplo', value: e.target.checked ? 2 : 1 }
                    })}
                  />
                )}
                label="Venda Múltipla"
              />
              {multiplo > 1 &&
                <TextField
                  label="Qtde"
                  name="multiplo"
                  type="number"
                  value={multiplo || ''}
                  style={{ width: 100 }}
                  onChange={e => {
                    if (e.target.value >= 2) {
                      handlerChange(e)
                    }
                  }}
                // onChange={handlerChange}
                />
              }
            </div>
          </Grid>
          <Grid item xs={12} md={9} container alignItems="center">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <ComboBoxDataTree
                  handlerChangeData={e => handlerChange({
                    target: { name: 'categorias', value: e }
                  })}
                  listSelecteds={categorias}
                  listFull={fnHelper.orderBy(allCategorias)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  error={showError && !codigo}
                  fullWidth
                  label="Codigo do item"
                  name="codigo"
                  type="text"
                  value={codigo || ''}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  label="Codigo do item(Terceiro)"
                  name="externalCode"
                  type="text"
                  value={externalCode || ''}
                  onChange={handlerChange}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <TextFieldEAN name="ean" value={ean || ''} label="EAN" onValueChange={handlerChange} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={showError && !nome}
                  fullWidth
                  label="Nome do item*"
                  name="nome"
                  required
                  type="text"
                  value={nome || ''}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextFieldMoneyBR
                  fullWidth
                  label="Custo Atual"
                  name="costPrice"
                  type="text"
                  value={costPrice || ''}
                  onValueChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextFieldMoneyBR
                  fullWidth
                  label="Valor Mínimo de Venda"
                  name="minAmount"
                  type="text"
                  value={minAmount || ''}
                  onValueChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextFieldMoneyBR
                  fullWidth
                  label="Valor De (Promocional)"
                  name="valorDe"
                  type="text"
                  value={valorDe || ''}
                  onValueChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextFieldMoneyBR
                  fullWidth
                  label="Valor de Venda"
                  name="valor"
                  type="text"
                  value={valor}
                  onValueChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <ComboBoxData
                  error={showError && !unidadeMedida}
                  label="Unidade de medida"
                  onChange={e => handlerChange({ target: { name: 'unidadeMedida', value: e.id } })}
                  selected={unidadeMedida}
                  nameValue="id"
                  nameText="nome"
                  list={listaUnidadeDeMedida}
                />
              </Grid>

              <Grid item xs={6} md={4}>
                <DatePicker
                  fullWidth
                  label="Última compra"
                  name="ultimaCompra"
                  type="text"
                  value={ultimaCompra || ''}
                  onChange={e => handlerChange({
                    target: {
                      name: 'ultimaCompra',
                      value: e
                    }
                  })}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <DatePicker
                  fullWidth
                  label="Última venda"
                  name="ultimaVenda"
                  type="text"
                  value={ultimaVenda || ''}
                  onChange={e => handlerChange({
                    target: {
                      name: 'ultimaVenda',
                      value: e
                    }
                  })}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  label="Garantia"
                  name="garantia"
                  type="text"
                  value={garantia || ''}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  fullWidth
                  label="Marca"
                  name="marca"
                  type="text"
                  value={marca || ''}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={4}>
                <ComboBoxAPI
                  label="Fornecedor"
                  onChange={e => {
                    handlerChange({ target: { name: 'provider', value: { id: e.id, name: e.nome } } })
                  }}
                  default={{ ...provider, nome: provider?.name }}
                  value={provider?.id}
                  api="api/Pessoa/fornecedor/full"
                  applyOrderBy
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  label="Descrição do item"
                  name="descricao"
                  type="text"
                  value={descricao || ''}
                  onChange={handlerChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição do item (HTML)"
                  name="descricaoHtml"
                  type="text"
                  value={descricaoHtml || ''}
                  onChange={handlerChange}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Outros Códigos do produto"
                  name="codes"
                  value={codes || ''}
                  onChange={handlerChange}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {modalUpload.open && (
          <UploadImageDialog
            title={foto ? 'Adicionar uma foto' : 'Alterar fofo'}
            tag="Item"
            onHide={() => this.setState({ modalUpload: { open: false } })}
            onCapture={img => {
              handlerChange({ target: { name: 'foto', value: img } })
              this.setState({ modalUpload: { open: false } })
            }}
          />
        )}
      </>
    )
  }
}

export default TabGeral
