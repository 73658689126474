import moment from 'moment'
import {
  getCenter,
  getBreakLines,
  getSpaceBetween,
  getAlignTextPerPosition,
  getAddSpacesRightSide
} from './helpers'

const layoutFenix = pedido => {
  var lines = [
    {
      value: '================================================================'
    },
    {
      value: getCenter('Fênix Auto Peças', 8),
      fontStyle: 1
    },
    {
      value: 'WhatsApp:(11)97043-3795/(11)5199-2730'
    },
    ...getBreakLines({
      value: 'R. Gonçalves Dias, 873 – Jd. Tupã –      Barueri – SP'
    }, 8),
    {
      value: 'Cep: 06435-030'
    },
    {
      value: '================================================================'
    }
  ]

  lines.push(
    {
      value: `Cliente: ${pedido?.cliente?.nome}`
    }
  )

  lines.push(
    {
      value: `CPF/CNPJ: ${pedido?.cliente?.cpf}`
    }
  )

  if (pedido?.endereco?.rua ||
    pedido?.endereco?.cidade ||
    pedido?.endereco?.numero ||
    pedido?.endereco?.estado
  ) {
    lines = [
      ...lines,
      ...getBreakLines(
        { value: `Endereço: ${pedido?.endereco?.rua || ''}, ${(pedido?.endereco?.numero || '')} - ${pedido?.endereco?.cidade || ''} - ${pedido?.endereco?.uf || ''}` },
        8
      )
    ]
  }

  lines.push({ value: ' ' })

  lines.push(
    {
      value: getSpaceBetween(`Pedido: ${pedido?.numero}`, `Data: ${moment(pedido?.dataCadastro).format('DD/MM/YYYY HH:mm')}`),
      fontStyle: 1
    }
  )

  lines.push(
    {
      value: `Vendedor: ${pedido?.seller?.name}`,
      fontStyle: 1
    }
  )

  lines.push({ value: ' ' })

  lines.push(
    {
      value: getAlignTextPerPosition([
        {
          value: getAddSpacesRightSide('Código', 13)
        },
        {
          value: getAddSpacesRightSide('Qtde', 7)
        },
        {
          value: getAddSpacesRightSide('Valor unit.', 11)
        },
        {
          value: getAddSpacesRightSide('     Total', 10)
        }
      ]),
      fontStyle: 1
    }
  )

  pedido?.itens?.forEach(item => {
    lines.push(
      {
        value: getAlignTextPerPosition([
          {
            value: getAddSpacesRightSide(`${item?.codigo}`, 13)
          },
          {
            value: getAddSpacesRightSide(`${item?.qtde}`, 7)
          },
          {
            value: getAddSpacesRightSide((`R$ ${Number((item?._valorTotal || item?.valorTotal || 0) / item?.qtde).toFixed(2).replace('.', ',')}`), 11)
          },
          {
            value: getAddSpacesRightSide((`R$ ${Number(item?._valorTotal || item?.valorTotal || 0).toFixed(2).replace('.', ',')}`), 10, 'right')
          }
        ])
      },
      {
        value: item.nome
      },
      {
        value: ' '
      }
    )
  })

  lines.push(
    {
      value: 'FRETE'
    },
    {
      value: `R$ ${Number(pedido?.valorFrete || 0).toFixed(2).replace('.', ',')}`
    },
    {
      value: '================================================================'
    },
    {
      value: getSpaceBetween('TOTAL DO PEDIDO', `R$ ${Number(pedido?._valorTotal || pedido?.valorTotal || 0).toFixed(2).replace('.', ',')}`),
      fontStyle: 1
    },
    {
      value: '****************************************************************'
    }
  )

  if (pedido?.observacao) {
    lines.push(
      {
        value: `Obs: ${pedido?.observacao}`,
        fontStyle: 1
      },
      {
        value: '****************************************************************'
      }
    )
  }

  lines = [
    ...lines,
    {
      value: ' '
    },
    ...getBreakLines(
      {
        value: 'Peças novas ou recondicionadas possuem a garantia de 3 meses.'
      }, 8),
    ...getBreakLines(
      {
        value: 'Só efetuamos trocas com apresentação da  nota ou cupom Não trocamos peças usadas!'
      }, 8),
    {
      value: ' '
    },
    {
      value: 'Garantia',
      fontStyle: 1
    },
    ...getBreakLines(
      {
        value: 'O prazo de garantia legal e obrigatório  para todos os produtos e estabelecido    pelo Código de Defesa do Consumidor Lei      8.087/90 é de 30 dias para reclamar  de vício ou defeito de produto não       durável e 90 dias para reclamar de vício ou defeito de produto durável.'
      }, 8),
    {
      value: ' '
    },
    {
      value: '================================================================'
    },
    {
      value: ' '
    },
    {
      value: getSpaceBetween(`Pedido: ${pedido?.numero}`, `Data: ${moment(pedido?.dataCadastro).format('DD/MM/YYYY HH:mm')}`),
      fontStyle: 1
    },
    {
      value: ' '
    },
    {
      value: `Cliente: ${pedido?.cliente?.nome || ''}`,
      fontStyle: 1
    },
    {
      value: `CPF/CNPJ: ${pedido?.cliente?.cpf || ''}`,
      fontStyle: 1
    },
    {
      value: `Valor: R$ ${Number(pedido?._valorTotal || pedido?.valorTotal || 0).toFixed(2).replace('.', ',')}`,
      fontStyle: 1
    },
    {
      value: `Pagamento: ${pedido?.pagamentos?.map(p => p.nome).join() || ''}`,
      fontStyle: 1
    },
    {
      value: ' '
    },
    {
      value: ' '
    },
    {
      value: getCenter('_______________________________')
    },
    {
      value: getCenter('Assinatura', 8)
    },
    {
      value: '.',
      top: 20
    }
  ]

  return lines
}

export default layoutFenix
