import React, { useState } from 'react'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import { ComboBoxAPI } from '../../_atoms/combobox'
import { Avatar, Button, Paper } from '@material-ui/core'
import { EMTable } from '@em/lib/src'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'

const tableRef = React.createRef()

export default ({ funcoes }) => {
  const [filter, setFilter] = useState({
    providerId: '',
    componente: false,
    compareMin: true
  })

  const loadItens = () => tableRef.current && tableRef.current.onQueryChange({ page: 0 })

  return (
    <div>
      <Title
        title="Lista de Produtos(Baixo Estoque)"
      />
      <Paper
        style={{
          padding: 10,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ComboBoxAPI
          label="Fornecedor"
          onChange={e => setFilter({ ...filter, providerId: e.id })}
          value={filter?.providerId}
          api="api/Pessoa/fornecedor/full"
          style={{ width: 400 }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 10 }}
          onClick={() => loadItens()}
        >
          Buscar
        </Button>
      </Paper>
      <EMTable
        tableRef={tableRef}
        data={query => {
          const { page, pageSize } = query
          return axios.get('api/item',
            {
              params: {
                offset: page,
                limit: pageSize,
                ...filter
              }
            }).then(response => {
            return {
              data: response.lista,
              page,
              totalCount: response.paginacao.qtdeRows || 0
            }
          })
        }}
        columns={[
          {
            type: 'image',
            width: 70,
            render: row => (
              <Avatar
                variant="rounded"
                alt="foto"
                src={`${global.bucket}${row?.fotos ? row?.fotos[0] : null}`}
              />
            )
          },
          {
            width: 150,
            title: 'Código',
            field: 'codigo',
            align: 'center'
          },
          {
            title: 'Nome do item',
            field: 'nome'
          },
          {
            title: 'Valor',
            field: 'valor',
            align: 'center',
            render: row => (
              <div>
                <NumberFormatMoneyBR value={row.valor} />
                {row.valorApartir !== row.valor &&
                  <span>
                    <br />
                    apartir de:
                    <NumberFormatMoneyBR value={row.valorApartir} />
                  </span>
                }
              </div>
            )
          },
          {
            title: 'Qtde Mínima',
            align: 'center',
            field: 'qtdeMinEstoque'
          },
          {
            title: 'Disponivel',
            align: 'center',
            field: 'qtdeDisponivel'
          }
        ]}
      />
    </div>
  )
}
