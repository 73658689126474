import React, { Component } from 'react'
import axios from 'axios'
import {
  Dialog, DialogContent, DialogActions, Button, Grid
} from '@material-ui/core'
import { ComboBoxAPI } from '../../../_atoms/combobox'
import { NumberFormatMoneyBR, TextFieldMoneyBR } from '../../../_atoms/numberFormat'

import Title from '@em/lib/src/util/Title'

class TransferenciaDialog extends Component {
  state = {
    contaDestino: {},
    valor: this.props.data.saldo,
    contaOrigem: this.props.data
  };

  onHandleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  onSave = () => {
    const { contaOrigem, contaDestino, valor } = this.state
    axios.post(`api/financeiro/transferencia/${contaOrigem.id}/${contaDestino.id}/${valor}`).then(conta => {
      this.props.onReload()
    })
  }

  handlerChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { data, onHide } = this.props
    const { contaDestino, valor } = this.state
    return (
      <div>
        <Dialog open>
          <Title title="Transferência entre contas" />
          <DialogContent style={{ paddingTop: 10 }}>

            <p>
Conta origem:
              <b>{data.nome}</b>
            </p>
            <p>
Saldo:
              <b>
                <NumberFormatMoneyBR value={data.saldo} />
              </b>
            </p>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ComboBoxAPI
                  loadStart
                  label="Seleciona a Conta Destino"
                  onChange={e => this.handlerChange({ target: { name: 'contaDestino', value: e } })}
                  value={contaDestino.id}
                  api="api/conta"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldMoneyBR
                  value={valor}
                  name="valor"
                  label="Valor"
                  onValueChange={this.handlerChange}
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={onHide}>
              Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={this.onSave}>
              Transferir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default TransferenciaDialog
