import React from 'react'
import axios from 'axios'
import { Chip, Icon } from '@material-ui/core'

import Title from '@em/lib/src/util/Title'

class GerarObjectId extends React.Component {
  state = {
    list: [],
  }

  handleCopy = text => {
    const copyText = document.getElementsByTagName('html')[0]
    const input = document.createElement('input')
    input.id = 'inp'
    input.value = text
    copyText.appendChild(input)

    const copy = document.getElementById('inp')
    copy.select()
    document.execCommand('Copy')

    copyText.removeChild(input)
  }

  gerar = () => {
    axios.post('api/ferramentas/gerarobjectid').then(obj => {
      this.setState({ list: [...this.state.list, obj] })
    })
  }

  render() {
    const { list } = this.state

    return (
      <div>
        <Title
          title="Gerador de ObjectId"
          buttons={[
            {
              icon: 'add',
              color: 'primary',
              onClick: this.gerar,
            },
          ]}
        />

        {list.map(obj => (
          <Chip
            label={obj}
            color="primary"
            onDelete={() => this.handleCopy(obj)}
            deleteIcon={<Icon>file_copy</Icon>}
          />
        ))}
      </div>
    )
  }
}

export default GerarObjectId
