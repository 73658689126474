import React, { Component } from 'react'
import axios from 'axios'
import { IconButton, Icon, Avatar, Button, Chip, Grid } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Title from '@em/lib/src/util/Title'
import { AddOrEditModal } from './components'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import moment from 'moment'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import EMTable from '@em/lib/src/EMTable'

const tableRef = React.createRef(null)

class ListSites extends Component {
  state = {
    modalAddOrEdit: { open: false },
    lista: []
  };

  // componentDidMount () {
  //   this.loadSites()
  // }

  // loadSites = () => {
  //   axios.get('api/site').then(res => {
  //     this.setState({ lista: res.lista })
  //   })
  // }

  loadSites = () => tableRef.current && tableRef.current.onQueryChange({ page: 0 })

  handleDelete = site => {
    const model = {
      title: 'Excluir',
      mensagem: `Você realmente deseja excluir ${site.dominio}`,
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => this.handleDeleteConfirmation(site)
        }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  handleDeleteConfirmation = site => {
    const { id } = site

    axios.delete(`api/site/${id}`).then(resp => {
      this.loadSites()
    })
  }

  onPublicar = site => {
    const model = {
      title: 'Publicar',
      icon: <Icon>web</Icon>,
      mensagem: `Você realmente deseja publicar ${site.dominio}`,
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => this.handlePublicarConfirmation(site)
        }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  handlePublicarConfirmation = site => {
    const { id } = site
    axios.delete(`api/site/publicar/${id}`).then(resp => {
      this.loadSites()
    })
  }

  render () {
    const { lista, modalAddOrEdit } = this.state
    return (
      <div>
        <Title
          title="Lista de Paginas"
          buttons={[
            {
              icon: 'add',
              onClick: () => this.setState({ modalAddOrEdit: { open: true, data: {} } })
            }
          ]}
        />

        <EMTable
          tableRef={tableRef}
          data={query => {
            const { page, pageSize } = query
            return axios.get('api/site', {
              params: { offset: page, limit: pageSize }
            }).then(response => {
              return {
                data: response.lista,
                page,
                totalCount: response?.paginacao?.qtdeRows || 0
              }
            })
          }}
          columns={[
            {
              width: 60,
              title: 'Logo',
              field: 'logo',
              render: row => (
                <Avatar variant="rounded" alt="Logo" src={`${global.bucket}${row.logo}`} />
              )
            },
            {
              title: 'Paginas',
              field: 'dominio',
              render: row => {
                const urlProd = `https://${row.dominio}`
                return urlProd
              }
            },
            {
              field: 'dominio',
              render: row => {
                const urlProd = `https://${row.dominio}`
                let urlQA = urlProd.replace('#', '?env=qa#')
                if (!urlQA.includes('?env=qa#')) {
                  urlQA += '?env=qa'
                }
                return (
                  <Grid container justify="space-between">
                    <Grid item>
                      <Chip
                        onClick={() => window.open(urlQA)}
                        label="QA"
                      />
                      <Chip
                        onClick={() => window.open(urlProd)}
                        label="PROD"
                      />
                    </Grid>
                  </Grid>
                )
              }
            },
            {
              title: 'Ultima Atualização',
              field: 'dataAtualizacao',
              render: row => fnHelper.toDateTimeBR(row.dataAtualizacao)
            },
            {
              title: 'Ultima Publicação',
              field: 'dataPublicacao',
              render: row => fnHelper.toDateTimeBR(row.dataPublicacao)
            },
            {
              width: 300,
              render: row => (
                <div style={{ textAlign: 'right' }}>
                  {moment(row.dataAtualizacao) > moment(row.dataPublicacao) &&
                    <Button variant="outlined" style={{ borderColor: 'green', color: 'green' }}
                      onClick={() => this.onPublicar(row)}>
                      Publicar
                    </Button>
                  }
                  <IconButton color="primary" onClick={() => this.setState({ modalAddOrEdit: { open: true, data: row, duplicate: true } })}>
                    <Icon>control_point_duplicate</Icon>
                  </IconButton>
                  <IconButton color="primary" onClick={() => this.setState({ modalAddOrEdit: { open: true, data: row } })}>
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton color="secondary" onClick={() => this.handleDelete(row)}>
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              )
            }
          ]}
        />

        {modalAddOrEdit.open && (
          <AddOrEditModal
            data={modalAddOrEdit.data}
            duplicate={modalAddOrEdit.duplicate}
            onHide={refresh => {
              this.setState({ modalAddOrEdit: { open: false } })
              if (refresh) {
                this.loadSites()
              }
            }}
          />
        )}

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  showMensagemConfirmacaoAction
},
dispatch)

export default connect(null, mapDispatchToProps)(ListSites)
