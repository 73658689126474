import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  Button, Dialog, DialogTitle, DialogActions, DialogContent, Zoom
} from '@material-ui/core'

import { hideMensagemConfirmacaoAction } from '../actions/mensagemAction'

class MsgConfirmacao extends Component {
  render () {
    const { mensagem, hideMensagemConfirmacaoAction } = this.props
    return (
      <Dialog open={mensagem.open}>
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {mensagem.icon &&
              <Zoom in timeout={1000}>
                {mensagem.icon && mensagem.icon}
              </Zoom>
            }
            <span style={{ marginLeft: 5 }}>{mensagem.title}</span>
          </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10 }}>{mensagem.mensagem}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              if (mensagem?.onHideFunc) {
                mensagem?.onHideFunc()
              }
              hideMensagemConfirmacaoAction()
            }}
            color="secondary">
            Cancelar
          </Button>

          {_.map(mensagem.buttoes, (button, idx) => (
            <Button
              key={idx}
              variant={button.variant || 'contained'}
              onClick={() => {
                button.onClick && button.onClick()
                hideMensagemConfirmacaoAction()
              }}
              color={button.color || 'primary'}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    )
  }
}

MsgConfirmacao.propTypes = {
  mensagem: PropTypes.object
}

function mapStateToProps (state) {
  return {
    mensagem: state.mensagemReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      hideMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MsgConfirmacao)
