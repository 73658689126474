import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Button, Grid } from '@material-ui/core'

export default ({ onRefreshPedido, pedido, hostIfood, onImprimir }) => {
  if (pedido.statusEntrega === 3 || pedido.statusEntrega === null) return null

  const atualizaIfood = (acao, reference, idPedido) => {
    axios
      .put(`api/Ifood/Integracao/${acao}/${reference}/${idPedido}`)
      .then(result => {
        if (result) {
          onRefreshPedido({ ...pedido, trancadoPara: { nome: 'Ifood' } })
        }
        if (acao === 'confirmation') {
          onImprimir()
        }
      })
  }

  // const atualizaIfood = (acao, reference, idPedido) => {
  //   axios
  //     .create({ baseURL: hostIfood })
  //     .put(`api/Ifood/Integration/${acao}/${reference}/${idPedido}`)
  //     .then(result => {
  //       if (result) onRefreshPedido({ ...pedido, trancadoPara: { nome: 'Ifood' } })
  //       if (acao == 'confirmation') {
  //         onImprimir()
  //       }
  //     })
  // }

  return (
    <Grid container>
      <Grid item xs={12}>
        {pedido.statusEntrega === 0 && (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            // onClick={() => atualizaIfood("integration", pedido.referencia, pedido.id)}
            disabled
          >
            Integrando...
          </Button>
        )}

        {pedido.statusEntrega === 1 && (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              color="secondary"
              style={{ width: '50%' }}
              onClick={() => atualizaIfood('rejection', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Recusar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              style={{ width: '50%', marginLeft: 5 }}
              onClick={() => atualizaIfood('confirmation', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Aceitar
            </Button>
          </div>
        )}

        {pedido.statusEntrega === 2 && (
          <Button
            variant="outlined"
            color="secondary"
            style={{ width: '100%', marginLeft: 5 }}
            onClick={() => atualizaIfood('dispatch', pedido.referencia, pedido.id)}
            disabled={!!pedido.trancadoPara}
          >
            Despachar
          </Button>
        )}

        {pedido.statusEntrega === 4 && (
          <Button variant="outlined" color="secondary" style={{ width: '100%', marginLeft: 5 }} disabled>
            Pedido ja saiu para entrega
          </Button>
        )}

        {pedido.statusEntrega === 3 && (
          <Button variant="outlined" color="secondary" style={{ width: '100%', marginLeft: 5 }} disabled>
            Pedido foi cancelado
          </Button>
        )}

        {pedido.statusEntrega === 5 && (
          <Button variant="outlined" color="secondary" style={{ width: '100%', marginLeft: 5 }} disabled>
            Pedido foi entregue
          </Button>
        )}

        {pedido.statusEntrega === 6 && (
          <Button variant="outlined" color="secondary" style={{ width: '100%', marginLeft: 5 }} disabled>
            Pedido Concluido
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
