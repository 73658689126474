import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import Title from '@em/lib/src/util/Title'
import { loadApiEMTable } from '@em/lib/src/EMTable/materialTableHelper'
import moment from 'moment'

import Top from './components/top'
import List from './components/list'

function FechamentosCaixa () {
  const [filter, setFilter] = useState({
    telas: '2,4',
    entregadorNome: '',
    telaId: '',
    dataCadastroInicial: moment(),
    dataCadastroFinal: moment(),
    loadApi: false
  })
  const tableRef = useRef(null)

  const loadFechamentosCaixa = (data) => loadApiEMTable(tableRef, data)

  useEffect(() => {
    if (filter?.loadApi) {
      loadFechamentosCaixa({ page: 0 })
    }
  }, [filter])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Title
          title="Lista de Fechamentos de Caixa"
          // buttons={[
          //   {
          //     icon: 'cloud_upload',
          //     color: 'primary',
          //     onClick: () => this.setState({ uploadFileModal: { open: true } })
          //   },
          //   {
          //     icon: 'add',
          //     onClick: () => this.setState({
          //       modalAddOrEdit: { open: true }
          //     })
          //   }
          // ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Top
          onSearch={form => setFilter({ ...form, loadApi: true })}
          filter={filter}
        />
      </Grid>
      <Grid item xs={12}>
        <List
          tableRef={tableRef}
          filter={filter}
          parent="fechamentoCaixa"
        />
      </Grid>
    </Grid>
  )
}

export default FechamentosCaixa
