import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Icon,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Tabs,
  Tab
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as fnHelper from '@em/lib/src/util/fnHelper'

import TabPessoa from './../tabPessoa'
import TabTelefone from './../tabTelefone'
import TabEmail from './../tabEmail'
import TabContato from './../tabContato'
import TabWhatsApp from './../tabWhatsApp'
import TabEndereco from './../tabEndereco'
import { Title, TextField, DatePicker } from '../../../../_atoms'
import UploadImageDialog from '../../../../_atoms/uploadImageDialog'
import paginasDoSistema from '../../../../_templates/routes/paginasDoSistema'
import { ComboBoxData } from '../../../../_atoms/combobox'
import CustomBreadCrumbCRUD from '../../../../_atoms/customBreadCrumbCRUD'
import { TextFieldPercent } from '../../../../_atoms/numberFormat'

const TabsOfPerson = forwardRef(({ data, onHide, forceXS, segmentosFull, contexto }, ref) => {
  // state = {
  //   showErros: false,
  //   segmento: '',
  // };
  const [indexTab, setIndexTab] = useState(0)
  const [site, setSite] = useState("")
  const [trocarFotoDialog, setTrocarFotoDialog] = useState({ open: false })
  const [form, setForm] = useState({
    tipo: 'PJ',
    hosts: [],
    confianca: 10,
    ...data
  })

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    onSave() {
      const newForm = { ...form }
      if (site) {
        if (newForm.hosts && _.isArray(newForm.hosts)) {
          newForm.hosts.push(site)
        } else {
          newForm.hosts = [site]
        }
      }

      const data = { ...newForm, contexto }
      axios.post('api/sistema', data).then(response => {
        onHide(true, response.dados)
      })
    }

  }));

  const handlerChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handlerChangePaginasAddRemove = (e, paginaId) => {
    const paginas = form.paginas || []
    if (e.target.checked) {
      paginas.push({ id: paginaId })
    } else {
      _.remove(paginas, x => x.id === paginaId)
    }

    setForm({ ...form, paginas })
  }

  const handlerChangePaginasLimit = (paginaId, limite) => {
    const paginas = form?.paginas?.map(pg => {
      if (pg.id == paginaId) {
        return { ...pg, limite }
      }
      return pg
    })


    setForm({ ...form, paginas })
  }

  const onAddItem = (name) => value => {
    setForm({ ...form, [name]: fnHelper.addOrEditByIndex(form[name], value) })
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        value={indexTab}
        onChange={(e, i) => setIndexTab(i)}
      >
        <Tab label="Geral" />
        <Tab label="Sistema" />
        <Tab label="Endereços" />
        <Tab label="Telefones" />
        <Tab label="Emails" />
        <Tab label="Contatos" />
        <Tab label="WhatsApp" />
        <Tab label="Fiscal" />
      </Tabs>

      <div style={{ padding: 10 }}>

        {indexTab === 0 &&
          <TabPessoa
            handlerChange={handlerChange}
            form={form}
            forceXS={forceXS}
            contexto={contexto}
            onOpenTrocarFotoDialog={(name) => setTrocarFotoDialog({ open: true, name })}
          />
        }

        {indexTab === 1 &&
          <Grid container spacing={1}>
            {/* <Grid item xs={6} md={forceXS ? 6 : 2}>
              <TextField name="host" label="Host" onChange={handlerChange} value={form.host} />
            </Grid> */}
            <Grid item xs={12}>
              <div style={{ color: '#8f8f8f', marginBottom: 5, fontSize: 12 }}>Segmentos</div>
              <CustomBreadCrumbCRUD
                referenceList={segmentosFull || []}
                currentList={form.segmentos || []}
                onChange={newSegmentos => {
                  handlerChange({
                    target: {
                      name: 'segmentos',
                      value: newSegmentos
                    }
                  })
                }}
                label={'Segmentos'}
              />
            </Grid>
            <Grid item xs={12} md={forceXS ? 12 : 4}>
              <ComboBoxData
                label="Verificado"
                onChange={e => handlerChange({ target: { name: 'confianca', value: e.id } })}
                selected={form.confianca}
                list={[
                  { id: 10, nome: 'Não Verificado' },
                  { id: 75, nome: 'Dados Confirmado' },
                  { id: 100, nome: 'Verificado' }
                ]}
              />
            </Grid>
            <Grid item xs={6} md={forceXS ? 6 : 2}>
              <TextField name="googleTag" label="Google TagManager" onChange={handlerChange}
                value={form.googleTag} />
            </Grid>
            {/* <Grid item xs={2}>
                  <TextField
                    disabled
                    name="scoreFlechei"
                    label="Score Flechei"
                    value={form.scoreFlechei}
                  />
                </Grid> */}
            <Grid item xs={6} md={forceXS ? 6 : 3}>
              <TextField
                disabled
                name="estrelas"
                label="Média de Avaliações (de 1 à 5)"
                value={form.estrelas}
              />
            </Grid>
            <Grid item xs={6} md={forceXS ? 6 : 3}>
              <DatePicker
                label="Data de Expiração"
                onChange={value => handlerChange({ target: { name: 'dataExpiracao', value } })}
                value={form.dataExpiracao}
              />
            </Grid>
            <Grid item xs={12} md={forceXS ? 12 : 3}>
              <Autocomplete
                multiple
                options={[]}
                id="hosts"
                defaultValue={form.hosts || []}
                freeSolo
                onChange={(a, b) => handlerChange({ target: { name: 'hosts', value: b } })}
                renderTags={(value, getTagProps) => value.map((option, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Sites"
                    placeholder="Novo site"
                    onChange={(e) => setSite(e.target.value)}
                    value={site}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={forceXS ? 12 : 4}>
              <ComboBoxData
                label="Server"
                noneText="Muro"
                onChange={e => handlerChange({ target: { name: 'server', value: e.id } })}
                selected={form.server || ''}
                list={[
                  { id: 'HML', nome: 'Server HML' },
                  { id: 'A', nome: 'Server A' }
                ]}
              />
            </Grid>
            {/* <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    options={[]}
                    id="segmentos"
                    defaultValue={form.segmentos || []}
                    freeSolo
                    onChange={(a, b) => handlerChange({ target: { name: 'segmentos', value: b } })}
                    renderTags={(value, getTagProps) => value.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Segmentos"
                        placeholder="Novo Segmento"
                        onChange={(e) => setState({ site: e.target.value })}
                        value={state.segmento}
                        fullWidth
                      />
                    )}
                  />
                </Grid> */}

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {paginasDoSistema.map((modulo, idx) => (
                  <Grid key={idx} item xs={3}>
                    <Card>
                      <CardHeader avatar={<Icon>{modulo.icone}</Icon>} title={modulo.nome} />
                      <CardContent>
                        <FormGroup>
                          <Grid container alignItems="center">

                            {modulo?.paginas?.map(pg => {
                              const pagina = form?.paginas?.find((x) => x.id == pg.id)
                              return (
                                <>
                                  <Grid item xs={10}>
                                    <FormControlLabel
                                      key={pg.id}
                                      control={
                                        <Checkbox
                                          checked={!!pagina}
                                          onChange={e => handlerChangePaginasAddRemove(e, pg.id)}
                                        />
                                      }
                                      label={pg.nome}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      style={{ minWidth: 40 }}
                                      disabled={!pagina}
                                      onChange={(e) => handlerChangePaginasLimit(pg.id, e.target.value)}
                                      value={pagina?.limite}
                                      placeholder="Limite"
                                    />
                                  </Grid>
                                </>
                              )
                            })}
                          </Grid>
                        </FormGroup>

                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                multiline
                rows={4}
                rowsMax={20}
                label="Tema JSON"
                name="tema"
                value={form.tema || ''}
                onChange={handlerChange}
              />
            </Grid>
          </Grid>
        }

        {indexTab === 2 &&
          <TabEndereco
            enderecos={form.enderecos}
            onAdd={onAddItem('enderecos')}
            onRemove={index => {
              const enderecos = [...form.enderecos]
              _.pullAt(enderecos, index)
              setForm({ ...form, enderecos })
            }}
          />
        }

        {indexTab === 3 &&
          <TabTelefone
            telefones={form.telefones}
            onAdd={onAddItem('telefones')}
            onRemove={index => {
              const telefones = [...form.telefones]
              _.pullAt(telefones, index)
              setForm({ ...form, telefones })
            }}
          />
        }

        {indexTab === 4 &&
          <TabEmail
            emails={form.emails}
            onAddEmail={onAddItem('emails')}
            onRemoveEmail={index => {
              const emails = [...form.emails]
              _.pullAt(emails, index)
              setForm({ ...form, emails })
            }}
          />
        }

        {indexTab === 5 &&
          <TabContato
            contatos={form.contatos}
            onAddEmail={onAddItem('contatos')}
            onRemoveEmail={index => {
              const contatos = [...form.contatos]
              _.pullAt(contatos, index)
              setForm({ ...form, contatos })
            }}
          />
        }

        {indexTab === 6 &&
          <TabWhatsApp
            whatsAppConfigs={form.whatsAppConfigs}
            onAddWhatsAppConfigs={onAddItem('whatsAppConfigs')}
            onRemoveWhatsAppConfigs={index => {
              const whatsAppConfigs = [...form.whatsAppConfigs]
              _.pullAt(whatsAppConfigs, index)
              setForm({ ...form, whatsAppConfigs })
            }}
          />
        }
        {indexTab === 7 &&
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <ComboBoxData
                label="Faixa"
                onChange={(e) => setForm({ ...form, tax: { ...form.tax, range: e.id } })}
                selected={form.tax?.range || ""}
                list={[
                  { id: 1, nome: '1ª Faixa Até 180.000,00' },
                  { id: 2, nome: '2ª Faixa De 180.000,01 a 360.000,00' },
                  { id: 3, nome: '3ª Faixa De 360.000,01 a 720.000,00' },
                  { id: 4, nome: '4ª Faixa De 720.000,01 a 1.800.000,00' },
                  { id: 5, nome: '5ª Faixa De 1.800.000,01 a 3.600.000,00' },
                  { id: 6, nome: '6ª Faixa De 3.600.000,01 a 4.800.000,00' }
                ]}
              />
            </Grid>
            <Grid item xs={6} md={forceXS ? 6 : 2}>
              <TextFieldPercent
                label="Despesa FIXA"
                onValueChange={(e) => setForm({ ...form, tax: { ...form.tax, fixedExpense: e.target.value } })}
                value={form.tax?.fixedExpense || ""}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxData
                label="Formula"
                onChange={(e) => setForm({ ...form, tax: { ...form.tax, formula: e.id } })}
                selected={form.tax?.formula || ""}
                list={[
                  { id: "margem", nome: 'Margem' },
                  { id: "marckup", nome: 'Marckup' }
                ]}
              />
            </Grid>
            <Grid item xs={6} md={forceXS ? 6 : 2}>
              <TextFieldPercent
                label="Margem padrão"
                onValueChange={(e) => setForm({ ...form, tax: { ...form.tax, marginDefault: e.target.value } })}
                value={form.tax?.marginDefault || ""}
                decimalScale={2}
              />
            </Grid>
          </Grid>
        }
      </div>

      {trocarFotoDialog.open && (
        <UploadImageDialog
          title="Trocar foto de perfil"
          onHide={() => setTrocarFotoDialog({ open: false })}
          onCapture={foto => {
            setForm({ ...form, [trocarFotoDialog.name]: foto })
            setTrocarFotoDialog({ open: false })
          }}
        />
      )}
    </>
  )
})


export default TabsOfPerson
