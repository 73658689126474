import React from 'react'
import axios from 'axios'
import { Button, Grid } from '@material-ui/core'

export default ({ onRefreshPedido, pedido, hostIfood, onImprimir }) => {
  if (pedido.statusEntrega === 3 || pedido.statusEntrega === null) return null

  const atualizaIfood = (acao, reference, idPedido) => {
    axios
      .put(`api/IfoodV2/Integracao/${acao}/${reference}/${idPedido}`)
      .then(result => {
        if (result) {
          onRefreshPedido({ ...pedido, trancadoPara: { nome: 'Ifood' } })
        }
        if (acao === 'confirmation') {
          onImprimir()
        }
      })
  }

  const cancelaPedidoIfood = (reference, idPedido) => {
    axios
      .put(`api/IfoodV2/IntegracaoCancela/${reference}/${idPedido}`, {
        reason: 'Pedido recusado pela loja',
        cancellationCode: '501'
      })
      .then(result => {
        if (result) {
          onRefreshPedido({ ...pedido, trancadoPara: { nome: 'Ifood' } })
        }
      })
  }

  const startPreparationIfood = (reference, idPedido) => {
    axios
      .put(`api/IfoodV2/IntegracaoIniciaPreparacao/${reference}/${idPedido}`)
      .then(result => {
        if (result) {
          onRefreshPedido({ ...pedido, trancadoPara: { nome: 'Ifood' } })
        }
      })
  }

  return (
    <Grid container spacing={1}>
      {pedido.statusEntrega === 0 && (
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          disabled
        >
          Integrando...
        </Button>
      )}

      {pedido.statusEntrega === 1 && (
        <>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => cancelaPedidoIfood(pedido.referencia, pedido.id)}
              fullWidth
              disabled={!!pedido.trancadoPara}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => atualizaIfood('confirm', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Aceitar
            </Button>
          </Grid>
        </>
      )}

      {(pedido.statusEntrega === 2) && (
        <>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"

              fullWidth
              onClick={() => startPreparationIfood(pedido.referencia, pedido.id)}
            >
              Iniciar preaparação
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth

              onClick={() => atualizaIfood('dispatch', pedido.referencia, pedido.id)}
            >
              Despachar
            </Button>
          </Grid>
        </>
      )}

      {pedido.statusEntrega === 2 && (pedido.tipo === 'TAKEOUT' || pedido.tipo === 'INDOOR') && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth

            onClick={() => atualizaIfood('readyToPickup', pedido.referencia, pedido.id)}
            disabled={!!pedido.trancadoPara}
          >
            Pronto para retirada
          </Button>
        </Grid>
      )}

      {pedido.statusEntrega === 34 && pedido.tipo === 'DELIVERY' && (
        <>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth

              onClick={() => atualizaIfood('dispatch', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Despachar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => atualizaIfood('requestDriver', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Solicitar entregador Ifood
            </Button>
          </Grid>
        </>
      )}

      {pedido.statusEntrega === 21 && (
        <>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => atualizaIfood('acceptCancellation', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Aceitar cancelamento do cliente
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => atualizaIfood('denyCancellation', pedido.referencia, pedido.id)}
              disabled={!!pedido.trancadoPara}
            >
              Recusar cancelamento do cliente
            </Button>
          </Grid>
        </>
      )}

      {pedido.statusEntrega === 4 && (
        <Grid item xs={12}>
          <Button variant="outlined" color="secondary" disabled fullWidth>
            Pedido ja saiu para entrega
          </Button>
        </Grid>
      )}

      {pedido.statusEntrega === 3 && (
        <Grid item xs={12}>
          <Button variant="outlined" color="secondary" disabled fullWidth>
            Pedido foi cancelado
          </Button>
        </Grid>
      )}

      {pedido.statusEntrega === 5 && (
        <Grid item xs={12}>
          <Button variant="outlined" color="secondary" disabled fullWidth>
            Pedido foi entregue
          </Button>
        </Grid>
      )}

      {pedido.statusEntrega === 6 && (
        <Grid item xs={12}>
          <Button variant="outlined" color="secondary" disabled fullWidth>
            Pedido Concluido
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
