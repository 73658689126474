import React, { Component } from 'react'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showMensagemConfirmacaoAction } from '../../../../actions/mensagemAction'
import { EMTable } from '@em/lib/src'
import { IconButtonAdd, IconButtonEdit, IconButtonDelete } from '../../../../_atoms/button'
import STATUS_ENTREGA from './../../../../contstants/statusEntrega'
import CustomTree from '../../../../_atoms/customTree'

class ListStatus extends Component {
  state = {
    modalAddOrEdit: { open: false },
    // lista: [],
    openId: null
  };

  handleDelete = status => {
    const model = {
      title: 'Excluir',
      mensagem: `Você realmente deseja excluir ${status.name}`,
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => this.handleDeleteConfirmation(status)
        }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  handleDeleteConfirmation = status => {
    const { id } = status

    axios.delete(`api/pedidostatus/${id}`).then(resp => {
      this.setState({ openId: resp.parentId })
    })
  }

  render () {
    const { funcoes, deleteStatus, openModal, pedidoStatuses } = this.props
    return (
      <CustomTree
        lista={pedidoStatuses}
        keyParent={'parentId'}
        // openId={openId}
        columns={[
          {
            title: 'Nome do status',
            field: 'name'
          }
        ]}
        actions={[
          {
            render: row => {
              const status = STATUS_ENTREGA.find(s => s.id === row.statusType)
              return (
                <div style={{ width: 180, textAlign: 'right' }}>
                  {status.activeIds && status.activeIds.length > 0 &&
                    <IconButtonAdd
                      onClick={() => openModal({
                        parentId: row.id,
                        removeStatus: row.statusType,
                        parentName: row.name,
                        activeIds: status?.activeIds
                      })}
                      funcoes={funcoes}
                    />}
                  <IconButtonEdit onClick={() => openModal(row)} funcoes={funcoes} />
                  <IconButtonDelete
                    onClick={() => deleteStatus(row)} funcoes={funcoes}
                    perguntar
                    mensagem={
                      <div>
                        <span style={{ fontSize: 18 }}>
                          Tem certeza que deseja deletar este status?
                        </span><br />
                        Isso impactará diretamente ao caixa!
                      </div>
                    }
                  />
                </div>
              )
            }
          }
        ]}
      />
    )
    return (
      <EMTable
        tableRef={this.props.tableRef}
        data={query => {
          const { page, pageSize } = query
          return axios.get('/api/pedidostatus/full', { params: { offset: page, limit: pageSize } }).then(response => {
            return {
              data: response
            }
          })
        }}
        columns={[
          {
            title: 'Nome do Status',
            field: 'name'
          },
          {
            title: 'Tipo de Status',
            field: 'statusType',
            render: row => (<div>{STATUS_ENTREGA.find(x => x.id == row.statusType)?.nome}</div>)
          },
          {
            align: 'right',
            width: 180,
            render: row => {
              const status = STATUS_ENTREGA.find(s => s.id === row.statusType)
              return (
                <div style={{ width: 180 }}>
                  {status.activeIds && status.activeIds.length > 0 &&
                    <IconButtonAdd
                      onClick={() => openModal({
                        parentId: row.id,
                        removeStatus: row.statusType,
                        parentName: row.name,
                        activeIds: status?.activeIds
                      })}
                      funcoes={funcoes}
                    />}
                  <IconButtonEdit onClick={() => openModal(row)} funcoes={funcoes} />
                  <IconButtonDelete
                    onClick={() => deleteStatus(row)} funcoes={funcoes}
                    perguntar
                    mensagem={
                      <div>
                        <span style={{ fontSize: 18 }}>
                          Tem certeza que deseja deletar este status?
                        </span><br />
                        Isso impactará diretamente ao caixa!
                      </div>
                    }
                  />
                </div>
              )
            }
          }
        ]}
        options={{
          paging: false
        }}
        parentChildData={
          (row, rows) => {
            return rows.find((status) => status.id === row?.parentId)
          }
        }
      />
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  showMensagemConfirmacaoAction
},
dispatch)

export default connect(null, mapDispatchToProps)(ListStatus)
