import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const styles = function (theme) {
  return {
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 300,
    },
    DialogActions: {
      display: 'flex',
      justifyContent: 'center',
    },
  }
}

class EsqueciMinhaSenhaDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Email: '',
      Mensagem: '',
    }
  }

    onChange = (key, event) => {
      this.setState({ [key]: event.target.value })
    }

    handlerResetSenha = () => {
      this.setState({ Mensagem: 'Enviando email...' })
      const url = 'api/Login/EsqueciMinhaSenha'
      axios
        .post(`${url}?email=${this.state.Email}&url=${window.location.origin}`)
        .then(response => {
          this.setState({ Mensagem: 'Confira seu e-mail!' })
          setTimeout(() => this.props.onHide(), 3000)
        })
    }

    render() {
      const { classes } = this.props
      return (
        <Dialog open style={{ textAlign: 'center' }}>
          <DialogTitle>Esqueci minha senha</DialogTitle>
          <DialogContent style={{ paddingTop: 10 }}>
            <Typography variant="body1" className={classes.pos}>
                        Coloque seu email no campo abaixo,
              {' '}
              <br />
                        que enviaremos um link
              <br />
                        para resetar sua senha!
            </Typography>
            <TextField
              id="Email"
              label="Email Login"
              value={this.state.Email}
              className={classes.textFieldt}
              type="email"
              onChange={event => this.onChange('Email', event)}
              autoComplete="current-Email"
              margin="normal"
            />

            <Typography variant="body1" color="primary" className={classes.pos}>
              {this.state.Mensagem}
            </Typography>
          </DialogContent>
          {!this.state.Mensagem && (
            <DialogActions className={classes.DialogActions}>
              <Button onClick={this.props.onHide} color="secondary">
                            Cancelar
              </Button>
              <Button onClick={() => this.handlerResetSenha()} color="primary">
                            Reset Senha
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )
    }
}

EsqueciMinhaSenhaDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EsqueciMinhaSenhaDialog),
)
