import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Button,
  Grid,
  Chip
} from '@material-ui/core'
import { Cropper } from 'react-image-cropper'
import axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DialogWebCam from './dialogWebCam'
import DialogComputador from './dialogComputador'
import GaleriaDialog from './dialogGaleria'
import Title from '@em/lib/src/util/Title'
import TextField from '../TextField'
import envConstants from '../../contstants/envConstants.json'

const styles = {}

class EditDialog extends Component {
  state = {
    tag: '',
    form: { ...this.props.data }
  };

  handlerChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  };

  handlerChangeForm = e => {
    this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
  };

  onSave = () => {
    const { form, tag } = this.state
    const newTags = [...form.tags]
    if (tag) {
      newTags.push(tag)
    }
    axios.put('api/arquivo', { ...form, tags: newTags }).then(response => {
      this.props.onHide(true)
    })
  }

  render () {
    const { onHide } = this.props
    const { form, tag } = this.state
    return (
      <Dialog open onClose={onHide}>
        <Title
          title={'Editar Tags'}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide
            }
          ]}
        />

        <DialogContent style={{ textAlign: 'center' }}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <TextField
                name="titulo"
                label="Titulo"
                onChange={this.handlerChangeForm}
                value={form.titulo}
              />
            </Grid>
            <Grid item xs={7}>
              <Autocomplete
                multiple
                id="tags"
                options={[]}
                defaultValue={form.tags || []}
                freeSolo
                onChange={(a, b) => this.handlerChangeForm({ target: { name: 'tags', value: b } })}
                renderTags={(value, getTagProps) => value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))}
                renderInput={params => (
                  <TextField
                    {...params}
                    // variant="filled"
                    label="Tags"
                    name="tag"
                    onChange={this.handlerChange}
                    value={this.state.tag}
                    placeholder="Nova tag"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                name="descricao"
                label="Descricao"
                onChange={this.handlerChangeForm}
                value={form.descricao}
              />
            </Grid>
            <Grid item xs={12}>
              <center>
                <img style={{ maxHeight: 200 }} src={`${envConstants[window.location.host].bucket}${form.key}`}/>
              </center>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSave} color="primary" variant="contained">
              Concluir
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditDialog.defaultProps = {
  title: 'Carregar imagem',
  fileTypes: ['image']
}

EditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fileTypes: PropTypes.array,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onCapture: PropTypes.func.isRequired
}

export default withStyles(styles)(EditDialog)
