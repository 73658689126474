import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid
} from '@material-ui/core'
import { JSONEditor } from 'react-json-editor-viewer'

import { TextField } from './../../_atoms'
import Title from '@em/lib/src/util/Title'

const styles = {
  label: {
    alignItems: 'center',
    height: 15,
    margin: 5
  },
  value: {
    marginLeft: 10
  },
  row: {
    display: 'flex'
  },
  withChildrenLabel: {
    color: 'DeepPink'
  },
  select: {
    borderRadius: 3,
    borderColor: 'grey',
    backgroundColor: 'DimGray',
    color: 'khaki'
  },
  input: {
    borderRadius: 3,
    border: '1px solid #272822',
    padding: 10
  },
  addButton: {
    cursor: 'pointer',
    color: 'blue',
    margin: '0 0 0 15px',
    fontSize: '1.5rem'
  },
  removeButton: {
    cursor: 'pointer',
    color: 'red',
    margin: '0 0 0 15px',
    fontSize: '1.5rem'
  },
  saveButton: {
    cursor: 'pointer',
    color: 'green',
    margin: '0 0 0 15px',
    fontSize: '1.5rem'
  },
  builtin: {
    color: 'green'
  },
  text: {
    color: 'khaki'
  },
  number: {
    color: 'purple'
  },
  property: {
    color: 'DeepPink'
  },
  collapseIcon: {
    cursor: 'pointer',
    color: 'teal'
  }
}

const AddOrEditModal = props => {
  const [json, setJson] = useState(props.data)
  const refContainer = useRef(null)

  const onSave = () => {
    if (refContainer?.current?.state?.data?.root?.raiz) {
      const st = JSON.stringify(refContainer.current.state.data.root.raiz)
      props.onChange(st)
      props.onHide()
    } else {
      props.onHide()
    }
  }

  const onMerge = () => {
    const st = _.merge((_.cloneDeep(props.defaultJSON) || { raiz: {} }),
      refContainer.current.state.data.root)
    setJson(st)
  }

  const onSetDefault = () => {
    setJson(_.cloneDeep(props.defaultJSON || { raiz: {} }))
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={'Edição de JSON: ' + props.label}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>
          <JSONEditor
            ref={refContainer}
            data={json}
            collapsible
            addButton
            styles={styles}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!props.defaultJSON.raiz} color="secondary" onClick={onSetDefault}>
            Default
          </Button>
          <Button disabled={!props.defaultJSON.raiz} color="secondary" onClick={onMerge}>
            Merge
          </Button>
          <Button color="primary" onClick={onSave}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(AddOrEditModal)
