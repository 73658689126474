export default pedido => {
  let valorTotal = 0
  pedido._valid = true
  pedido.itens && pedido.itens.forEach(item => {
    const _item = somarItem(item)
    valorTotal += _item._valorTotal
    if (!_item._valid) pedido._valid = false
  })
  pedido._valorTotal = (valorTotal || 0) + (pedido.valorFrete || 0) + (pedido.valorTaxa || 0)
  pedido._valor = (valorTotal || 0)
  pedido._valorTotal = pedido._valorTotal - (pedido.discountAmount || 0)
}

const somarItem = (item) => {
  let valorTotalItem = 0
  item._valid = true
  item.montagens && item.montagens.forEach(montagem => {
    let qtdeMontagem = 0
    let valorTotalMontagem = 0
    montagem._valid = true
    montagem.opcoes && montagem.opcoes.forEach(opc => {
      const qtdeOpcoes = ((opc.qtde || 0) + (opc.incluso || 0))
      opc._qtde = qtdeOpcoes
      qtdeMontagem += qtdeOpcoes
      valorTotalMontagem += (opc.valor || 0) * qtdeOpcoes
      opc._valid = qtdeOpcoes >= opc.minimo && qtdeOpcoes <= opc.maximo
      if (!opc._valid) {
        montagem._valid = false
        item._valid = false
      }
    })
    valorTotalItem += valorTotalMontagem
    montagem._qtde = qtdeMontagem
    montagem._valorTotal = valorTotalMontagem
    if (montagem._valid) {
      montagem._valid = qtdeMontagem >= montagem.minimo && qtdeMontagem <= montagem.maximo
    }
    if (!montagem._valid) {
      item._valid = false
    }
  })

  item._valorTotalItemSemDesconto = parseFloat((((item.valor || 0) + valorTotalItem) * item.qtde).toFixed(2))
  item._valorTotalDesconto = parseFloat(((item.valorAux || 0) * item.qtde).toFixed(2))
  item._valorTotal = parseFloat(((item._valorTotalItemSemDesconto || 0) + item._valorTotalDesconto).toFixed(2))

  return {
    _valid: item._valid,
    _valorTotal: item._valorTotal,
    _valorTotalItemSemDesconto: item._valorTotalItemSemDesconto
  }
}

const changeValorAuxItem = (pedidoItens = [], discount) => {
  const newPedidoItens = pedidoItens?.map(item => {
    const valorAux = parseFloat(((item.valor * discount) / 100).toFixed(2)) * -1
    return {
      ...item,
      valorAux: ((item.valor + valorAux) < (item.minAmount || 0) ? (item.valor - (item.minAmount || 0)) * -1 : valorAux)
    }
  })

  return newPedidoItens
}

export { somarItem, changeValorAuxItem }
