import React, { Component } from 'react'
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import { Grid, Button, Paper } from '@material-ui/core'

import { ReactTable, DatePicker, EmptyList, DateTimePicker } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import NumberFormatMoneyBR from '../../_atoms/numberFormat/NumberFormatMoneyBR'

class RelatorioItens extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      dataCompetenciaInicial: moment(),
      dataCompetenciaFinal: moment(),
      lista: [],
    }
  }

  getRelatorio = () => {
    const { dataCompetenciaInicial, dataCompetenciaFinal } = this.state

    const d1 = dataCompetenciaInicial.format('YYYY-MM-DD HH:mm')
    const d2 = dataCompetenciaFinal.format('YYYY-MM-DD')

    const url = `/api/Pedido/Item/Group?dataCompetenciaInicial=${d1}&dataCompetenciaFinal=${d2}`

    this.setState({ loading: true, }, () => {
      axios.get(url)
        .then(result => {
          this.setState({
            lista: result, loading: false,
          })
        })
        .catch(error => {
          this.setState({
            lista: [], loading: false,
          })
        })
    })
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value })
  }

  render() {
    const { loading, lista = [] } = this.state
    return (
      <>
        <Title title="Relatório de itens" />
        <br />
        <Paper style={{ marginBottom: 10, padding: 10 }}>
          <Grid
            container
            direction="row"
            // justify="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <DateTimePicker
                label="Competência inicial"
                value={this.state.dataCompetenciaInicial}
                onChange={value => this.handleChange('dataCompetenciaInicial', value)}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                label="Competência final"
                value={this.state.dataCompetenciaFinal}
                onChange={value => this.handleChange('dataCompetenciaFinal', value)}
              />
            </Grid>
            <Grid item>
              <Grid item>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={this.getRelatorio}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <br />

        {
          lista.length === 0
          && <EmptyList text="Não há itens neste período" />
        }

        {
          lista.length > 0
          && (
            <ReactTable
              loading={loading}
              data={lista}
              columns={[
                {
                  Header: 'Codigo',
                  accessor: 'codigo',
                },
                {
                  Header: 'Nome do item',
                  accessor: 'nome',
                },
                {
                  Header: () => (
                    <div style={{ textAlign: 'right' }}>Quantidade</div>
                  ),
                  Cell: props => (
                    <div style={{ textAlign: 'right' }}>
                      {props.original.qtde}
                    </div>
                  ),
                },
                {
                  Header: () => (
                    <div style={{ textAlign: 'right' }}>Valor</div>
                  ),
                  Cell: props => (
                    <div style={{ textAlign: 'right' }}>
                      <NumberFormatMoneyBR value={props.original.valor} />
                    </div>
                  ),
                },
              ]}
            />
          )
        }
      </>

    )
  }
}

export default RelatorioItens
