import React from 'react'
import { Typography } from '@material-ui/core'

const style = {
  listaVazia: {
    color: '#bbb',
    fontStyle: 'italic',
    fontSize: '1.2em',
    padding: 15,
  },
}

export default props => (
  <Typography style={style.listaVazia}>
    {props.text}
  </Typography>
)
