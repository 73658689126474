import React, { Component } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Title from '@em/lib/src/util/Title'
import PendenteAprovacaoDialog from '../../_organisms/financeiro/pendente/pendenteAprovacaoDialog'
import ListPendentes from '../../_organisms/financeiro/pendente/listPendentes'

class PendenteContainer extends Component {
  state = {
    modalPendente: { open: false },
    dataList: []
  };

  componentDidMount () {
    this.loadPendentes()
  }

  loadPendentes () {
    const url = 'api/financeiro/full?lstStatus=0'
    axios.get(url).then(dataList => this.setState({ dataList }))
  }

  onConfirm = financeiro => {
    this.setState({
      modalPendente: { open: false }
    }, this.loadPendentes)
  }

  render () {
    const { dataList, modalPendente } = this.state
    return (
      <div>
        <Title title="Lançamentos pendentes de aprovação" />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ListPendentes
              list={dataList}
              onShowModalPendentes={data => this.setState({ modalPendente: { open: true, data } })}
            />
          </Grid>
        </Grid>

        {modalPendente.open && (
          <PendenteAprovacaoDialog
            data={modalPendente.data}
            onHide={() => this.setState({ modalPendente: { open: false } })}
            onConfirm={this.onConfirm}
          />
        )}
      </div>
    )
  }
}

PendenteContainer.propTypes = {}

export default PendenteContainer
