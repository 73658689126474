import React from 'react'
import {
  Dialog, DialogContent, Grid, DialogActions, Button, DialogTitle,
} from '@material-ui/core'
import { TextField } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

const ConnectModal = ({
  onSave, onHide, handleChange, connect,
}) => (
  <Dialog open maxWidth="md" fullWidth>

    <Title
      title="Editar EMUtility"
      buttons={[
        {
          icon: 'close',
          color: 'secondary',
          onClick: onHide,
        },
      ]}
    />

    <DialogContent style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            fullWidth
            label="Link connect"
            name="host"
            required
            type="text"
            value={connect.host2}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color="secondary">Procurar</Button>
      <Button
        color="primary"
        variant="contained"
        onClick={onSave}
      >
                Salvar
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConnectModal
