import React, { Component } from 'react'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import ListExtrato from './../pdv/fechamentosCaixa/components/list'
import { loadApiEMTable } from '@em/lib/src/EMTable/materialTableHelper'

class ExtratoContainer extends Component {
  state = {
    dataList: [],
    somas: []
  };

  tableRef = React.createRef(null)

  loadExtrato = (data) => loadApiEMTable(this.tableRef, data)

  render () {
    return (
      <div>
        <Title title="Extrato Financeiro" />
        <ListExtrato tableRef={this.tableRef} parent="extrato" />
      </div>
    )
  }
}

ExtratoContainer.propTypes = {}

export default ExtratoContainer
