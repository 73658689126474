import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Dialog, DialogContent, Icon, IconButton, Grid, Chip, DialogActions, Button, Tooltip, Avatar
} from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import { EMTable } from '@em/lib/src'
import { ComboBoxAPI, ComboBoxData, ComboBoxDataTree } from '../../../_atoms/combobox'
import * as fnHelper from '@em/lib/src/util/fnHelper'

const SearchItemDialog = ({ onHide, title, multi, itens, onAddItem, ...props }) => {
  const [tagsFilter, setTagsFilter] = useState([])
  const [categoriesSelecteds, setCategoriesSelecteds] = useState([])
  const [applicationsSelecteds, setApplicationsSelecteds] = useState([])
  const [tags, setTags] = useState([])
  const [provider, setProvider] = useState(null)
  const [search, setSearch] = useState(props.search)
  const [sort, setSort] = useState('codigo')
  const [addItemWhenOne, setAddItemWhenOne] = useState(true)
  const [substituteFilter, setSubstituteFilter] = useState({})
  const [selecteds, setSelecteds] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)
  const [categories, setCategories] = useState([])
  const [applications, setApplications] = useState([])

  const itemsFiltered = itens?.filter(item => {
    if (substituteFilter?.id) {
      return item.id == substituteFilter.id
    }

    let showByTag = false
    let showByCategory = true
    let showByApplication = true
    let showByProvider = true

    if (tagsFilter.length == 0) {
      showByTag = true
    } else {
      tagsFilter.forEach(tag => {
        if (_.some(item.tags, t => t == tag)) showByTag = true
      })
    }

    if (categoriesSelecteds?.length > 0) {
      const lastCategorySelected = categoriesSelecteds[categoriesSelecteds.length - 1]
      if (!_.some(item.categorias, ic => ic.id == lastCategorySelected.id)) {
        showByCategory = false
      }
    }

    if (applicationsSelecteds?.length > 0) {
      const lastApplicationsSelected = applicationsSelecteds[applicationsSelecteds.length - 1]
      showByApplication = false
      item?.applications?.forEach(apps => {
        if (_.some(apps, ic => ic.id == lastApplicationsSelected.id)) {
          showByApplication = true
        }
      })
    }

    if (provider?.id) {
      if (item.provider?.id !== provider?.id) {
        showByProvider = false
      }
    }

    const showBySearch = _.includes(_.toUpper(item.nome), _.toUpper(search)) ||
      _.includes(_.toUpper(item.ean), _.toUpper(search)) ||
      _.includes(_.toUpper(item.codigo), _.toUpper(search)) ||
      _.includes(_.toUpper(item.codes), _.toUpper(search)) ||
      _.includes(_.toUpper(item.externalCode), _.toUpper(search))

    return showByTag && showBySearch && showByCategory && showByProvider && showByApplication
  }).sort((a, b) => {
    if (a[sort] > b[sort]) {
      return 1
    }
    if (a[sort] < b[sort]) {
      return -1
    }
    return 0
  })

  if (addItemWhenOne && itemsFiltered?.length == 1) {
    const item = itemsFiltered[0]
    onAddItem(_.cloneDeep(item))
  }

  useEffect(() => {
    const tags = []

    itemsFiltered?.map(x => x.tags)?.forEach(ts => {
      ts?.forEach(tag => {
        if (!tags?.includes(tag)) {
          tags.push(tag)
        }
      })
    })
    setAddItemWhenOne(false)
    setTags(tags)
  }, [itemsFiltered.length])

  useEffect(() => {
    const categories = []
    itens?.map(x => x.categorias)?.forEach(cats => {
      cats?.forEach(c => {
        if (!categories?.map(x => x.id)?.includes(c.id)) {
          categories.push(c)
        }
      })
    })
    setCategories(fnHelper.orderBy(categories))
  }, [])

  useEffect(() => {
    const applications = []
    itens?.map(x => x.applications)?.forEach(appls => {
      appls?.forEach(apps => {
        apps.forEach(a => {
          if (!applications?.map(x => x.id)?.includes(a.id)) {
            applications.push(a)
          }
        })
      })
    })
    setApplications(fnHelper.orderBy(applications))
  }, [])

  const onSelect = item => {
    if (selecteds.find(x => x.id == item.id)) {
      setSelecteds(selecteds.filter(x => x.id != item.id))
    } else {
      setSelecteds([...selecteds, item])
    }
  }

  const onSelectAll = () => {
    if (selectedAll) {
      setSelectedAll(false)
      setSelecteds([])
    } else {
      setSelectedAll(true)
      setSelecteds(itemsFiltered)
    }
  }

  const addTag = tag => {
    setSubstituteFilter({})
    setTagsFilter([tag])
  }

  const removeTag = tag => {
    setSubstituteFilter({})
    setTagsFilter(tagsFilter.filter(x => x != tag))
  }

  // let lastCategory = {}
  // if (categoriesSelecteds) {
  //   lastCategory = categoriesSelecteds[categoriesSelecteds.length - 1]
  // }

  const hasSubstituteItems = itemsFiltered?.map(x => x.substituteItems?.length || 0).reduce((a, b) => a + b, 0) > 0

  return (
    <Dialog open fullWidth maxWidth={false}>
      <Title title={title || 'Lista de produtos'} buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
      <DialogContent style={{ paddingTop: 10 }}>
        <Grid container spacing={1}>

          <Grid item xs={12} md={4}>
            <Grid container spacing={2} style={{ position: 'sticky', top: 0 }}>
              <Grid item xs={12}>
                <ComboBoxAPI
                  label="Fornecedor"
                  onChange={obj => setProvider(obj)}
                  value={provider?.id}
                  api="api/Pessoa/fornecedor/full"
                  applyOrderBy
                />
              </Grid>
              {categories?.length > 0 &&
                <Grid item xs={12}>
                  <h3>Filtrar por Categorias</h3>
                  <ComboBoxDataTree
                    handlerChangeData={e => {
                      // setCategoriesSelecteds(categoriesSelecteds.filter((x, _idx) => _idx < idx))
                      setCategoriesSelecteds(e)
                      setSubstituteFilter({})
                    }}
                    listSelecteds={categoriesSelecteds}
                    listFull={categories}
                  />
                </Grid>
              }

              {applications?.length > 0 &&
                <Grid item xs={12}>
                  <h3>Filtrar por Aplicação</h3>
                  <ComboBoxDataTree
                    handlerChangeData={e => {
                      setApplicationsSelecteds(e)
                      setSubstituteFilter({})
                    }}
                    listSelecteds={applicationsSelecteds}
                    listFull={applications}
                  />
                </Grid>
              }

              {tags?.length > 0 &&
                <Grid item xs={12}>
                  <h3>Filtrar por tags</h3>
                  {tags.map((tag, idx) => {
                    const filtered = _.some(tagsFilter, x => x === tag)
                    const backgroundColor = filtered ? '#333' : 'white'
                    const color = filtered ? 'white' : '#333'
                    return (
                      <Chip
                        onClick={() => (filtered ? removeTag(tag) : addTag(tag))}
                        key={idx}
                        label={tag}
                        variant="outlined"
                        color="primary"
                        style={{ backgroundColor, color, margin: 5 }}
                      />
                    )
                  })}
                </Grid>
              }

            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <TextField
                  value={search} name="search"
                  label="Código | Código Terceiro | EAN | Nome do item"
                  onChange={(e) => {
                    setSearch(e.target.value)
                    setSubstituteFilter({})
                  }} />
              </Grid>
              <Grid item xs={3}>
                <ComboBoxData
                  label="Ordenação"
                  onChange={e => setSort(e.id)}
                  selected={sort}
                  list={[
                    { id: 'codigo', nome: 'Código (A-Z)' },
                    { id: 'nome', nome: 'Nome (A-Z)' },
                    { id: 'valorApartir', nome: 'Valor (0-9)' }
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ position: 'relative' }}>
                  <EMTable
                    data={itemsFiltered || []}
                    columns={[
                      {
                        width: 50,
                        render: row => row.fotos && <Avatar src={`${global.bucket}${row.fotos[0]}`} alt="I" />
                      },
                      {
                        title: 'Produto',
                        field: 'nome',
                        render: row => (row.codigo || '#') + ' - ' + row.nome
                      },
                      {
                        title: 'Fornecedor',
                        field: 'provider.name',
                        render: row => row.provider?.name
                      },
                      {
                        width: 100,
                        title: 'Valor',
                        render: row => <NumberFormatMoneyBR value={row.valor} />
                      },
                      {
                        width: 100,
                        title: 'Estoque',
                        field: 'qtdeDisponivel'
                      },
                      {
                        title: 'Substituto',
                        width: 100,
                        hidden: !hasSubstituteItems,
                        render: row => (row.substituteItems?.map(item => {
                          var substitute = itens?.find(x => x.id == item)
                          return (
                            <Tooltip placement="top" title={
                              <div>
                                {`${(substitute?.codigo || '#') + ' - ' + substitute?.nome} - `}
                                <NumberFormatMoneyBR value={substitute?.valor} />
                              </div>
                            }
                            key={item.id}
                            >
                              <IconButton
                                style={{}}
                                onClick={() => setSubstituteFilter(substitute)}
                              >
                                <Avatar src={`${global.bucket}${substitute?.fotos && substitute?.fotos[0]}`} alt="S" />
                              </IconButton>
                            </Tooltip>
                          )
                        }))
                      },
                      {
                        width: 70,
                        render: row => {
                          if (multi) {
                            const isSelected = !!selecteds.find(x => x.id == row.id)
                            return (
                              <IconButton
                                style={isSelected ? { backgroundColor: 'blue', color: 'white' } : {}}
                                onClick={() => onSelect(row)}
                              >
                                <Icon>check</Icon>
                              </IconButton>
                            )
                          }
                          return (
                            <IconButton onClick={() => onAddItem(_.cloneDeep(row))}>
                              <Icon>check</Icon>
                            </IconButton>
                          )
                        }
                      }
                    ]}
                  />
                  {multi && (
                    <Tooltip
                      title={`Isto selecionará todos os ${itemsFiltered.length} itens!`}
                    >
                      <IconButton
                        onClick={onSelectAll}
                        style={{
                          position: 'absolute',
                          top: 0,
                          backgroundColor: selectedAll && 'blue',
                          color: selectedAll && 'white',
                          zIndex: 10,
                          right: 15
                        }}
                      >
                        <Icon>check</Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
      {multi && (
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => onAddItem(selecteds)}>
            Finalizar Seleção
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default SearchItemDialog
