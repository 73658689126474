import React, { Component, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import { show } from 'react-notification-system-redux'
import { Grid, Avatar, IconButton, Tooltip, CircularProgress, Button, Grow } from '@material-ui/core'
import * as signalR from '@microsoft/signalr'
import { axiosEMUtility, axiosLambda } from '../../../myAxios'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'

import Title from '@em/lib/src/util/Title'
import envConstants from '../../../contstants/envConstants.json'
import BarraPedidos from './components/barraPedidos'
import PedidoItens from './components/pedidoItens'
import PedidoCliente from './components/pedidoCliente'
import PedidoPagamento from './components/pedidoPagamento'
import MesasDialog from './components/mesasDialog'
// import FormasPagamentoDialog from './components/formasPagamentoDialog'
import FecharCaixaDialog from './components/fecharCaixaDialog'
import SearchItemDialog from '../../sortimento/components/searchItemDialog'
import { Alert } from '@material-ui/lab'
import PedidoEntregador from './components/pedidoEntregador'
import PedidoInfo from './components/pedidoInfo'
import { IndicadorImpressoras } from '../../dispositivos/components'
import ListaDeAdmsDialog from './components/listaDeAdmsDialog'
import AutenticaAdmDialog from './components/autenticaAdmDialog'
import moment from 'moment'
import { layoutDefault, layoutFenix, layoutPedidaoFenix } from '../impressaoLayouts'
import layoutMDogao from '../impressaoLayouts/layoutMDogao'
import layoutFoguete from '../impressaoLayouts/layoutFoguete'

let connection = {}

const Caixa2 = ({ userReducer, match, showMensagemConfirmacaoAction, show, funcoes }) => {
  const [loading, setLoading] = useState(true)
  const [wsConnected, setWsConnected] = useState(true)
  const [mesasDialog, setMesasDialog] = useState({ open: false })
  const [lojaEmoutech, setLojaEmoutech] = useState({})
  const [key, setKey] = useState(null)
  const [cronIfood, setCronIfood] = useState({ disabled: false })
  const [searchItemDialog, setSearchItemDialog] = useState({ open: false, search: '' })
  const [fechamentoDialog, setFechamentoDialog] = useState({ open: false })
  const [mesas, setMesas] = useState([])
  const [tags, setTags] = useState([])
  const [pedidos, setPedidos] = useState([])
  const [impressoras, setImpressoras] = useState([])
  const [todosStatusDePedidos, setTodosStatusDePedidos] = useState([])
  const [pedido, setPedido] = useState({})
  const [pedidoWS, setPedidoWS] = useState({})
  const [emUtility, setEMUtility] = useState({})
  const [caixa, setCaixa] = useState({})
  const [hostIfood, setHostIfood] = useState('')
  const [modelosDeImpressao, setModelosDeImpressao] = useState([1, 2, 3])
  const [admsModal, setAdmsModal] = useState({ open: false })
  const [autenticaAdmModal, setAutenticaAdmModal] = useState({ open: false, data: null })
  const [allowEditByAdm, setAllowEditByAdm] = useState(false)
  const [sistema, setSistema] = useState(null)
  const [changedPedido, setChangedPedido] = useState(false)
  const [formasDePagamento, setFormasdePagamento] = useState([])

  useEffect(() => {
    loadCaixa()
    loadOrderStatus()
    connection = new signalR.HubConnectionBuilder()
      .withUrl(`${envConstants[window.location.host].api}/pedidoHub`)
      .withAutomaticReconnect()
      .build()

    const addOnGroup = () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.invoke('AddToGroup', userReducer.sistema.sistemaBancoId.toString())
        connection.on('atualizarPedido', p => setPedidoWS(p))
        connection.on('pingIfood', console.log)
        setWsConnected(true)
      }
    }

    connection
      .start()
      .then(addOnGroup)

    connection.onreconnected(addOnGroup)

    connection.onclose(() => {
      setWsConnected(false)
    })

    connection.onreconnecting(() => {
      setWsConnected(false)
    })
  }, [])

  useEffect(() => {
    if (pedidoWS?.id) {
      atualizarPedido(pedidoWS)
    }
  }, [pedidoWS])

  const loadConnectIfood = () => {
    axios.get('/api/Connect/Ifood')
      .then(resp => {
        setHostIfood(resp?.host)
      })
  }

  useEffect(() => {
    if (caixa?.canais?.includes(2)) {
      loadIfoodCronStatus()
      loadConnectIfood()
    }
  }, [caixa])

  useEffect(() => {
    if (todosStatusDePedidos && pedido.statusEntregaId) {
      const st = todosStatusDePedidos.find(x => x.id === pedido.statusEntregaId)
      setPedido({ ...pedido, statusEntregaJoin: st })
    }
  }, [pedido.statusEntregaId, pedido.id])

  useEffect(() => {
    setAllowEditByAdm(false)
  }, [pedido.id])

  const loadCaixa = () => {
    axios.get(`api/caixa/${match.params.caixaId}`).then(caixa => {
      if (!caixa.dataAbertura) {
        window.location.hash = 'pdv/caixa'
        return
      }

      Promise.all([
        axiosGetPedidos(),
        axios.get('/api/mesa/full'),
        axios.get('/api/item/tags?componente=false'),
        axios.get('/api/Connect/Print'),
        axios.get('/api/Impressora/Full'),
        axios.get('/api/FormaPagamento/full'),
        // axios.get('/api/Connect/Ifood'),
        axios.get('api/sistema/me')

      ]).then(responses => {
        setCaixa(caixa)
        setLoading(false)
        setPedidos(responses[0])
        setMesas(responses[1])
        setTags(responses[2])
        setEMUtility(responses[3])
        setImpressoras(responses[4])
        setFormasdePagamento(responses[5])
        setSistema(responses[6])
      })
    })
  }

  const loadOrderStatus = () => {
    axios.get('/api/pedidostatus/full')
      .then(resp => {
        setTodosStatusDePedidos(resp)
      })
  }

  const loadIfoodCronStatus = () => {
    axiosLambda.get('cron/list').then(response => {
      const cronIfood = response.data[0]
      if (cronIfood) {
        cronIfood.open = cronIfood.State === 'ENABLED'
        setCronIfood(cronIfood)
      }
    })

    setTimeout(loadIfoodCronStatus, 1000 * 60 * 0.5)
  }

  // const lojaIfood = li => {
  //   setLojaIfood({ ...li, time: 0 })
  // };

  const abrirFecharLojaIfood = () => {
    const { open } = cronIfood

    const model = {
      title: 'Confirmar',
      mensagem: (
        <div>
          <p>Você realmente deseja <b>{open ? 'FECHAR' : 'ABRIR'}</b> sua loja no IFOOD?</p>
          <Alert title="Informação" color="info">
            Esta ação pode demorar até 5 minutos para ser processada no IFOOD.
          </Alert>
          {open &&
            <Alert title="Atenção" color="warning">
              Ao fechar a loja, o sistema deixará de receber notificaçães do IFOOD
            </Alert>
          }

        </div>
      ),
      buttoes: [
        { text: 'Confirmar', onClick: abrirFecharLojaIfoodConfirm }
      ]
    }

    showMensagemConfirmacaoAction(model)
  }

  const confirmExitPedido = pedido => {
    const model = {
      title: 'Confirmar',
      mensagem: (
        <div>
          <p>Existem alterações que ainda não foram salvas nesse pedido!</p>
        </div>
      ),
      buttoes: [
        {
          variant: 'outlined',
          text: 'Descartar Alterações',
          onClick: () => {
            loadPedido(pedido, true)
          }
        },
        {
          text: 'Salvar Alterações',
          onClick: () => {
            onSave(pedido)
          }
        }
      ]
    }

    showMensagemConfirmacaoAction(model)
  }

  const abrirFecharLojaIfoodConfirm = () => {
    const { open, Name } = cronIfood

    axiosLambda.post('cron/enable', {
      enable: !open,
      name: Name
    }).then(response => {
      setCronIfood({
        ...cronIfood,
        open: !open,
        disabled: true
      })

      setTimeout(() => setCronIfood({ ...cronIfood, open, disabled: false }), 60000)
    })
  }

  const abrirFecharLojaEmoutech = () => {
    let url = 'api/Ifood/AbrirLoja'
    if (lojaEmoutech.open) {
      url = 'api/Ifood/FecharLoja'
    }

    axios.post(`${url}`)
      .then(result => {
        setLojaEmoutech({ lojaEmoutech, open: result })
      })
  }

  const pedidoChegando = pedido => {
    setPedidos(oldList => {
      const _pedido = oldList.find(pdd => pdd.id === pedido?.id)

      if (!_pedido) {
        if ([2, 3].includes(pedido.canal) && pedido.statusEntrega <= 1) {
          new Audio(require('./../../../assets/audios/novoPedido.mp3')).play()
        }
        return [...oldList, pedido]
      }

      return oldList.map(p => {
        if (pedido.id === p.id) {
          return pedido
        }
        return p
      })
    })
  }

  const atualizarPedido = (pdd, alteracaoCompleta) => {
    if (pdd.statusCaixa === 2) {
      setPedidos(_.filter(pedidos, f => f.id !== pdd.id))
      if (pedido?.id === pdd?.id) {
        setPedido({})
      }
      return
    }
    pedidoChegando(pdd)

    if (alteracaoCompleta) {
      setPedido(pdd)
      setChangedPedido(true)
    } else if (pedido.id === pdd.id) {
      setPedido({
        ...pedido,
        statusEntrega: pdd.statusEntrega,
        statusCaixa: pdd.statusCaixa,
        trancadoPara: pdd.trancadoPara,
        pagamentos: pdd.pagamentos
      })
    }
    setKey(new Date().getTime())
  }

  const axiosGetPedidos = () => axios.get('api/pedido/full', { params: { statusCaixa: '3,1' } })

  const loadPedidos = () => {
    axiosGetPedidos().then(response => {
      setPedidos(response)
    })
  }

  const onSetMesas = mesas => {
    setPedido({ ...pedido, mesas })
    setMesasDialog({ ...mesasDialog, open: false })
  }

  const onOpenSearchItens = search => {
    if (!searchItemDialog.loadItens) {
      axios
        .get('api/item/full', {
          params: {
            componente: false,
            ativo: true
          }
        })
        .then(itens => {
          setSearchItemDialog({
            open: true, loadItens: true, itens, search
          })
        })
    } else {
      setSearchItemDialog({ ...searchItemDialog, open: true, search })
    }
  }

  const handlerChangeSearchItens = e => {
    setSearchItemDialog({ ...searchItemDialog, [e.target.name]: e.target.value })
  }

  const onAddItem = item => {
    let itens = []
    if (pedido.itens) itens = [...pedido.itens]
    const valorAux = parseFloat(((item.valor * (pedido?.cliente?.entidade?.discount || 0) / 100)).toFixed(2)) * -1
    const valorAux2 = ((item.valor + valorAux) < (item.minAmount || 0) ? (item.valor - (item.minAmount || 0)) * -1 : valorAux)
    const newItem = {
      ...item,
      qtde: 1,
      foto: item.fotos && item.fotos[0],
      showComposicao: (item.montagens || []).length > 0,
      valorAux: valorAux2
    }

    setPedido({ ...pedido, itens: [...itens, newItem] })
    setSearchItemDialog({ ...searchItemDialog, open: false, search: '' })
    setChangedPedido(true)
  }

  const loadPedido = (pedido, force) => {
    if (changedPedido && !force) {
      confirmExitPedido(pedido)
    } else {
      axios.get(`api/pedido/${pedido.id}`).then(response => {
        const st = todosStatusDePedidos.find(x => x.id === pedido.statusEntregaId)
        setChangedPedido(false)
        setPedido({ ...response, statusEntregaJoin: st })
      })
    }
  }

  const onSave = (nextPedido) => {
    axios.post('api/pedido', pedido).then(response => {
      setChangedPedido(false)
      loadPedidos()
      setTimeout(() => {
        if (nextPedido?.id) {
          loadPedido(nextPedido, true)
        } else {
          const pedidoAtualizado = {
            ...pedido,
            ...response?.dados
          }
          loadPedido(pedidoAtualizado, true)
        }
      }, 500)
    })
  }

  const print = retornoImpressao => {
    retornoImpressao.dados.forEach(impressaoDTO => {
      if (!impressaoDTO.ip && caixa?.impressora?.caminho) {
        impressaoDTO.ip = caixa?.impressora?.caminho
      }
      if (!impressaoDTO.modeloId && caixa?.impressora?.modeloId) {
        impressaoDTO.modeloId = caixa.impressora.modeloId
      }

      axios
        .create({ baseURL: emUtility.host })
        .post(`api/Imprimir/${impressaoDTO.ip}/${impressaoDTO.modeloId}`, impressaoDTO.linhas)
        .then(response => response.data.mensagens || [])
        .then(mensagens => {
          mensagens.forEach(mensagem => {
            show({ message: mensagem.texto, title: 'Informação' }, 'info')
          })
        })
        .catch(error => {
          show({ message: error.message, title: 'Sem resposta' }, 'error')
        })
    })
  }

  const validateAdm = (userId, senha) => {
    axios.post(`api/Login/VerificarLogin/?id=${userId}&senha=${senha}`)
      .then(val => {
        if (val) {
          setAutenticaAdmModal({ open: false })
          setAllowEditByAdm(true)
        }
      })
  }

  const onFinalizar = () => {
    const data = { ...pedido, financeiroId: caixa.financeiroId, dataCompetencia: caixa.dataAbertura }
    if (pedido.canal == 0) {
      axios.post(`api/pedido/imprimir?modelosDeImpressao=${modelosDeImpressao.join(',')}`, data).then(response => {
        const onImprimir = () => print(response)
        onFinalizarConfirmar(onImprimir)
      })
    } else {
      onFinalizarConfirmar()
    }
  }

  const onFinalizarConfirmar = fnCallBack => {
    const data = { ...pedido, financeiroId: caixa.financeiroId, dataCompetencia: caixa.dataAbertura }

    axios.post(`api/pedido/finalizar?modelosDeImpressao=${modelosDeImpressao.join(',')}`, data).then(response => {
      fnCallBack && fnCallBack()
      setPedido({})
      loadPedidos()
      setChangedPedido(false)
    })
  }

  const onCancelar = () => {
    axios.post(`api/pedido/cancelar/${pedido.id}`).then(response => {
      setPedido({})
      loadPedidos()
    })
  }

  const onImprimirCfe = () => {
    axios
      .create({ baseURL: emUtility.host })
      .post(`api/Printer/PrintCfe/${caixa.impressora.caminho}/${caixa.impressora.modeloId}?chave=${pedido.cFe}`)
      .then(response => {

      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  const onImprimirPedidao = () => {
    var lines = []

    lines.push(
      {
        value: 'Obrigado',
        align: 'center'
      },
      {
        value: `${pedido.numero}`,
        fontSize: 30,
        align: 'center'
      },
      {
        value: 'Data da impressão ' + moment().format('DD/MM/YYYY HH:mm'),
        align: 'center'
      }
    )

    let layout = lines
    if (userReducer?.sistema?.sistemaBancoId == 1963) {
      layout = layoutPedidaoFenix(pedido)
    }

    onImprimir(layout)
  }

  const onImprimirControle = () => {
    let layout = layoutDefault(pedido)
    if (userReducer?.sistema?.sistemaBancoId == 1963) {
      layout = layoutFenix(pedido)
    } else if (userReducer?.sistema?.sistemaBancoId == 3079) {
      layout = layoutMDogao(pedido)
    } else if (userReducer?.sistema?.sistemaBancoId == 3649) {
      layout = layoutFoguete(pedido)
    }
    onImprimir(layout)
  }

  const onImprimirPracas = () => {
    var lines = []

    lines.push(
      {
        value: `${pedido.numero}`,
        fontSize: 30,
        align: 'center'
      },
      {
        value: `${pedido.numero}`,
        fontSize: 30,
        align: 'center'
      }
    )

    onImprimir(lines)
  }

  const onImprimir = (linhas) => {
    axiosEMUtility
      .post(`api/Printer/PrintOut/${caixa.impressora.caminho}/${caixa.impressora.modeloId}`, linhas)
      .then(response => {
        show({ message: 'Impressão enviada!', title: 'Sucesso' }, 'success')
      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  const onSistemaPagar = () => {
    axios.post(`api/PagSeguro/GerarPagamento/${pedido.id}/5ef55c52baf14b1c238b5143/1`).then(response => {
      window.location = 'https://pagseguro.uol.com.br/v2/checkout/payment.html?code=' + response.dados
    })
  }

  if (loading) {
    return <p>Carregando...</p>
  }

  if (!caixa.numero) {
    return <p>Caixa invalido</p>
  }
  return (
    <div>
      <Title
        title={
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <span>{`Caixa Nº ${caixa.numero}`}</span>
            </Grid>
            {!wsConnected &&
              <Grid item>
                <Button onClick={() => { window.location.reload() }}
                  color="error"
                  variant="outlined">
                  Atualizar a pagina
                </Button>
              </Grid>
            }
            {cronIfood?.open != null &&
              <Grid item>
                <Tooltip title={`${cronIfood.open ? 'Loja aberta' : 'Loja Fechada'}`}>
                  <div style={{ position: 'relative' }}>
                    <IconButton disabled={cronIfood.disabled} onClick={abrirFecharLojaIfood}>
                      <Avatar src={
                        cronIfood?.open ? require('./../../../assets/img/pdv/ifood.png')
                          : require('./../../../assets/img/pdv/ifood_black.png')
                      } />
                    </IconButton>

                    {cronIfood.disabled && <CircularProgress
                      size={40}
                      style={{
                        color: '#AAA',
                        position: 'absolute',
                        top: 10,
                        left: 11,
                        zIndex: 1
                      }}
                    />
                    }
                  </div>
                </Tooltip>
              </Grid>
            }
          </Grid>
        }
        component={(
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}>
            <IndicadorImpressoras connectURL={emUtility.host} impressoras={impressoras} />
          </div>
        )}
        buttons={[
          {
            type: 'menu',
            icon: 'more_vert',
            buttons: [
              {
                onClick: () => setFechamentoDialog({ ...fechamentoDialog, open: true }),
                label: 'Fechar o caixa'
              }
            ]
          }
        ]}
      />

      <BarraPedidos
        key={new Date().getTime()}
        pedidos={pedidos}
        pedido={pedido}
        onAddPedido={atualizarPedido}
        todosStatusDePedidos={todosStatusDePedidos}
        onSelectPedido={loadPedido}
      />

      {pedido && pedido.id && (
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PedidoItens
                  key={`${pedido.id}-${pedido.statusEntrega}`}
                  mesas={mesas}
                  pedido={pedido}
                  onRefreshPedido={(p) => atualizarPedido(p, true)}
                  search={searchItemDialog.search}
                  onOpenMesas={mesasSelecionadas => setMesasDialog({ open: true, mesasSelecionadas })}
                  onOpenSearchItens={onOpenSearchItens}
                  handlerChange={handlerChangeSearchItens}
                  openAdmsModal={() => setAdmsModal({ open: true })}
                  allowEditByAdm={allowEditByAdm}
                  onSave={onSave}
                  show={show}
                />
              </Grid>
              <Grow
                timeout={500}
                in={pedido?.itens?.length > 0}>
                <Grid item xs={12}>
                  <PedidoInfo
                    pedido={pedido}
                    onRefreshPedido={(p) => atualizarPedido(p, true)}
                    allowEditByAdm={allowEditByAdm}
                  />
                </Grid>
              </Grow>
              <Grow
                timeout={1000}
                in={pedido?.itens?.length > 0}>
                <Grid item xs={12} sm={6}>
                  <PedidoCliente
                    pedido={pedido}
                    onRefreshPedido={(p) => atualizarPedido(p, true)}
                    allowEditByAdm={allowEditByAdm}
                  />
                </Grid>
              </Grow>
              <Grow
                timeout={1500}
                in={pedido?.itens?.length > 0}>
                <Grid item xs={12} sm={6}>
                  <PedidoEntregador pedido={pedido} onRefreshPedido={(p) => atualizarPedido(p, true)} />
                </Grid>
              </Grow>
            </Grid>
          </Grid>
          <Grow
            timeout={2000}
            in={pedido?.itens?.length > 0}>
            <Grid item xs={12} md={4}>
              <div style={{ position: 'sticky', top: 60 }}>
                <PedidoPagamento
                  key={`${pedido.id}-${pedido.statusEntrega}-${key}-${pedido.cFe}`}
                  pedido={pedido}
                  formasDePagamento={formasDePagamento}
                  onRefreshPedido={(p) => atualizarPedido(p, true)}
                  onFinalizar={onFinalizar}
                  onCancelar={onCancelar}
                  onImprimir={onImprimir}
                  onImprimirPedidao={onImprimirPedidao}
                  onImprimirCfe={onImprimirCfe}
                  onImprimirControle={onImprimirControle}
                  onImprimirPracas={onImprimirPracas}
                  onSave={onSave}
                  hostIfood={hostIfood}
                  emUtility={emUtility}
                  sistema={sistema}
                  modelosDeImpressao={modelosDeImpressao}
                  setModelosDeImpressao={modelosDeImpressao => setModelosDeImpressao(modelosDeImpressao)}
                  todosStatusDePedidos={todosStatusDePedidos}
                  changedPedido={changedPedido}
                />
              </div>

              {/* <Button color="secondary" onClick={onSistemaPagar}>
               Pagar PageSeguro
                </Button> */
              }

            </Grid>
          </Grow>

        </Grid>
      )}

      {mesasDialog.open && (
        <MesasDialog
          mesas={mesas}
          mesasSelecionadas={mesasDialog.mesasSelecionadas}
          onHide={() => setMesasDialog({ ...mesasDialog, open: false })}
          onSetMesas={onSetMesas}
        />
      )}

      {searchItemDialog.open && (
        <SearchItemDialog
          onAddItem={onAddItem}
          itens={searchItemDialog.itens}
          search={searchItemDialog.search}
          tags={tags}
          onHide={() => setSearchItemDialog({ ...searchItemDialog, open: false })}
        />
      )}

      {fechamentoDialog.open && (
        <FecharCaixaDialog
          caixa={caixa}
          emUtility={emUtility}
          onHide={() => setFechamentoDialog({ ...fechamentoDialog, open: false })}
        />
      )}

      {admsModal.open &&
        <ListaDeAdmsDialog
          onHide={() => setAdmsModal({ open: false })}
          confirmAdm={adm => {
            setAutenticaAdmModal({ open: true, data: adm })
            setAdmsModal({ open: false })
          }}
        />
      }

      {autenticaAdmModal.open &&
        <AutenticaAdmDialog
          onHide={() => setAutenticaAdmModal({ open: false })}
          adm={autenticaAdmModal.data}
          validateAdm={validateAdm}
        />
      }
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userReducer: state.userReducer,
    online: state.onlineReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      show,
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Caixa2)
