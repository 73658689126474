import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import AdvancedSearch from '../../_atoms/search/AdvancedSearch'
import List from './components/list'
import AddOrEditModal from './components/CreateEdit/AddOrEditModal'
import SplitItemModal from './components/CreateEdit/splitItemModal'
import { ComboBoxAPI, ComboBoxData } from '../../_atoms/combobox'
import { TextField } from '../../_atoms'
import UploadFileModalWithFeedback from './components/uploadFileModalWithFeedback'
import { loadApiEMTable } from '@em/lib/src/EMTable/materialTableHelper'

const FileDownload = require('js-file-download')
const { Parser } = require('json2csv')

class Itens extends Component {
  tableRef = React.createRef();

  state = {
    allTags: [],
    modalAddOrEdit: { open: false },
    hasFiltered: false,
    filter: {
      nome: '',
      disponivel: '',
      ativo: null,
      tags: '',
      codigo: '',
      sort: 'Nome-asc',
      componente: false,
      categoriaId: '',
      stockAddressingId: ''
    },
    uploadFileModal: { open: false },
    allCategorias: [],
    allApplications: [],
    providers: [],
    allStockAddresses: [],
    allItems: [],
    loadItemAll: false,
    splitItemModal: { open: false, data: null },
    copied: ''
  }

  componentDidMount = () => {
    this.getTags()
    this.loadAllCategorias()
    this.loadAllApplications()
    this.loadProviders()
    this.loadStockAddress()
  };

  loadItens = (data) => loadApiEMTable(this.tableRef, data)

  loadItensFull = () => {
    if (!this.state.loadItemAll) {
      axios.get('/api/Item/Full?componente=false')
        .then(itens => {
          this.setState({ allItems: itens, loadItemAll: true })
        })
    }
  }

  loadAllCategorias = () => axios.get('api/itemcategory/full').then(resp => this.setState({ allCategorias: resp }))

  loadAllApplications = () => axios.get('api/itemApp/full').then(resp => this.setState({ allApplications: resp }))

  loadStockAddress () {
    axios.get('api/StockAddressing/full?parentId=all').then(response => {
      this.setState({ allStockAddresses: response })
    })
  }

  loadProviders = () => {
    axios.get('api/Pessoa/fornecedor/full')
      .then(resp => {
        this.setState({
          providers: resp
        })
      })
  }

  getTags () {
    axios.get('api/item/tags?componente=false').then(allTags => {
      this.setState({ allTags })
    })
  }

  addTags = (tags) => {
    if (tags && tags.length > 0) {
      this.setState({ allTags: [...this.state.allTags, ...tags] })
    }
  }

  handleChangeForm = (name, value) => {
    this.setState({
      hasFiltered: true,
      filter: {
        ...this.state.filter,
        [name]: value,
        offset: 0
      }
    })
  };

  onCheckTag (tag, checked) {
    const { filter } = this.state
    const tags = filter.tags.split(',')

    if (checked) {
      tags.push(tag)
    } else {
      _.remove(tags, x => x === tag)
    }

    this.setState({
      hasFiltered: true,
      filter: {
        ...filter,
        tags: tags.join(',')
      }
    })
  }

  onDownload = () => {
    const { filter } = this.state
    this.setState({ hasFiltered: true }, () => {
      axios.get('api/item/full', { params: filter }).then(response => {
        const fields = [
          {
            label: 'Codigo',
            value: 'codigo',
            default: ''
          },
          {
            label: 'Descricao',
            value: 'nome',
            default: ''
          },
          {
            label: 'Valor de Venda',
            value: 'valor',
            default: ''
          },
          {
            label: 'Custo',
            value: 'costPrice',
            default: ''
          },
          {
            label: 'EAN',
            value: 'ean',
            default: ''
          },
          {
            label: 'Codigo Terceiro',
            value: 'externalCode',
            default: ''
          },
          {
            label: 'Unidade de Medida',
            value: 'unidadeMedida',
            default: ''
          },
          {
            label: 'Quantidade Mínima de Estoque',
            value: 'qtdeMinEstoque',
            default: ''
          },
          {
            label: 'Quantidade Máxima de Estoque',
            value: 'qtdeMaxEstoque',
            default: ''
          },
          {
            label: 'NCM',
            value: 'tax.ncm',
            default: ''
          }]
        const opts = { fields, delimiter: ';' }
        const parser = new Parser(opts)
        const csv = parser.parse(response)
        FileDownload(csv, 'produtos.csv')
      })
    }
    )
  }

  onBuscar = () => {
    this.setState({ hasFiltered: false }, () => this.loadItens({ page: 0 }))
  }

  onDelete = async (data) => {
    await axios.delete(`/api/Item/${data.id}`)
    this.setState({ loadItemAll: false }, () => this.loadItens({ isDelete: true }))
  }

  onEdit = (data) => {
    this.setState({
      modalAddOrEdit: { open: true, data }
    })
  };

  handleCopy = text => {
    const copyText = document.querySelector('div[class="MuiDialogContent-root"]')
    const input = document.createElement('input')
    input.id = 'inp'
    input.value = text
    copyText.appendChild(input)

    const copy = document.getElementById('inp')
    copy.select()
    document.execCommand('Copy')

    copyText.removeChild(input)
    this.setState({
      copied: text
    }, () => {
      setTimeout(() => {
        this.setState({
          copied: ''
        })
      }, 3000)
    })
  }

  render () {
    const {
      modalAddOrEdit,
      allTags,
      filter,
      uploadFileModal,
      allCategorias,
      allApplications,
      providers,
      allStockAddresses,
      allItems,
      splitItemModal,
      hasFiltered,
      copied
    } = this.state
    const { funcoes } = this.props
    return (
      <div>
        <Title
          title="Lista de produtos"
          buttons={[
            {
              icon: 'cloud_upload',
              color: 'primary',
              onClick: () => this.setState({ uploadFileModal: { open: true } })
            },
            {
              icon: 'add',
              onClick: () => this.setState({
                modalAddOrEdit: { open: true }
              })
            }
          ]}
        />

        <AdvancedSearch
          onDownload={this.onDownload}
          onBuscar={this.onBuscar}
          hasSearch={hasFiltered}
          searchSimple={
            <>
              <Grid item>
                <TextField
                  label="Codigo"
                  value={filter.codigo}
                  onChange={e => this.handleChangeForm('codigo', e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') { this.onBuscar() }
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Nome do item"
                  value={filter.nome}
                  onChange={e => this.handleChangeForm('nome', e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') { this.onBuscar() }
                  }}
                />
              </Grid>
            </>
          }
        >
          <Grid container style={{ width: '100%' }} spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Codigo (Terceiro)"
                value={filter?.externalCode}
                onChange={e => this.handleChangeForm('externalCode', e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Marca"
                value={filter?.marca}
                onChange={e => this.handleChangeForm('marca', e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ComboBoxAPI
                label="Fornecedor"
                onChange={obj => this.handleChangeForm('providerId', obj?.id)}
                value={filter?.providerId || ''}
                api="api/Pessoa/fornecedor/full"
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
                applyOrderBy
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ComboBoxAPI
                label="Categoria"
                onChange={obj => this.handleChangeForm('categoriaId', obj?.id)}
                value={filter?.categoriaId}
                api="api/itemcategory/full"
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
                nameText="name"
                applyOrderBy
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="ID"
                value={filter?.ids}
                onChange={e => this.handleChangeForm('ids', e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ComboBoxAPI
                label="Endereço do Estoque"
                onChange={obj => this.handleChangeForm('stockAddressingId', obj?.id)}
                value={filter?.stockAddressingId}
                api="api/StockAddressing/full?parentId=all"
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
                nameText="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ComboBoxData
                label="Ativo"
                onChange={e => this.handleChangeForm('ativo', e.id)}
                selected={filter.ativo}
                list={[{ id: true, nome: 'Sim' }, { id: false, nome: 'Não' }]}
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ComboBoxData
                label="Ordenação"
                onChange={e => this.handleChangeForm('sort', e.id)}
                selected={filter.sort}
                list={[
                  { id: 'Codigo-asc', nome: 'Código (A-Z)' },
                  { id: 'Nome-asc', nome: 'Nome (A-Z)' },
                  { id: 'ValorApartir-asc', nome: 'Valor (0-9)' }
                ]}
                onKeyPress={e => {
                  if (e.key === 'Enter') { this.onBuscar() }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                label="Componentes"
                control={
                  <Checkbox
                    color="secondary"
                    onChange={e => {
                      this.handleChangeForm('componente', e.target.checked)
                    }}
                    checked={filter?.componente}
                  />
                }
                style={{ marginLeft: 5 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ width: '100%' }} spacing={1} justify="flex-start">
                {_.map(allTags, (tag, index) => {
                  const checked = _.includes(filter.tags.split(','), tag)
                  return (
                    <Grid item key={index}>
                      <FormControlLabel
                        control={<Checkbox checked={checked} onChange={e => this.onCheckTag(tag, e.target.checked)} />}
                        label={tag}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>

        </AdvancedSearch>

        <List
          tableRef={this.tableRef}
          filter={filter}
          funcoes={funcoes}
          onDelete={this.onDelete}
          onEdit={this.onEdit}
          openSplitModal={data => this.setState({ splitItemModal: { open: true, data } })}
          title="Itens"
        />

        {modalAddOrEdit.open && (
          <AddOrEditModal
            onHide={refresh => this.setState({ modalAddOrEdit: { open: false }, loadItemAll: !refresh }, () => {
              if (refresh === true) {
                this.loadItens()
              }
            })}
            data={modalAddOrEdit.data}
            title="Item"
            componente={false}
            allTags={allTags}
            addTags={this.addTags}
            allCategorias={allCategorias}
            allApplications={allApplications}
            providers={providers}
            allStockAddresses={allStockAddresses}
            loadItensFull={this.loadItensFull}
            allItems={allItems}
            copied={copied}
            handleCopy={this.handleCopy}
          />
        )}
        {uploadFileModal.open && (
          <UploadFileModalWithFeedback
            onHide={refresh => this.setState({ uploadFileModal: { open: false } }, () => refresh === true && this.loadItens())}
            title={'Importação de Itens'}
            tipo={6}
          />
        )}
        {splitItemModal.open &&
          <SplitItemModal
            onHide={refresh => this.setState({ splitItemModal: { open: false } }, () => {
              if (refresh === true) {
                this.loadItens()
              }
            })}
            data={splitItemModal.data}
          />
        }
      </div>
    )
  }
}

export default Itens
