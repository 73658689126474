import React, { Component } from 'react'
import { List } from '@material-ui/core'
import ExpansionPanel from '../../../_atoms/ExpansionPanel'
import PaginaCheck from './PaginaCheck'

export default class ModuloCard extends Component {
  render () {
    const {
      perfil, modulo, onCheckPage, onCheckFunction
    } = this.props
    const { paginas } = modulo
    return (
      <ExpansionPanel titulo={modulo.nome}>
        <List style={{ width: '100%' }}>
          {
            paginas.map((pagina, idx) => (
              <PaginaCheck onCheckPage={onCheckPage} onCheckFunction={onCheckFunction} pagina={pagina} perfil={perfil} key={idx} />
            ))
          }
        </List>
      </ExpansionPanel>

    )
  }
}
