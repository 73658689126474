import React, { Component } from 'react'
import {
  Button, TextField, Dialog, DialogContent, DialogActions
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'

class AutenticaConferenteDialog extends Component {
  state = {
    senha: ''
  };

  onHandleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  handlerValConf = () => {
    this.props.ValConf(this.props.conferente.id, this.state.senha)
  };

  render () {
    const { senha } = this.state
    const { onHideSenha, conferente } = this.props
    return (
      <Dialog open maxWidth="sm">
        <Title
          title="Senha"
          buttons={[
            { icon: 'close', color: 'secondary', onClick: onHideSenha }
          ]}
        />
        <DialogContent style={{ paddingTop: 10 }}>
          <p>
            Ola&nbsp;
            <strong>{conferente.nome}</strong>
, por favor digite sua senha!
          </p>
          <TextField
            label="Senha"
            type="password"
            name="senha"
            style={{ marginTop: 10 }}
            value={senha}
            onChange={this.onHandleChange}
            fullWidth
            autocomplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={this.handlerValConf}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AutenticaConferenteDialog
