import React from 'react'
import MaskedInput from 'react-text-mask'
import TextField from './../TextField'

function TextMaskCustom (props) {
  const { ...other } = props
  return (
    <MaskedInput
      {...other}
      mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      guide={false}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

export default props => (
  <TextField
    InputProps={{
      inputComponent: TextMaskCustom
    }}
    {...props}
  />
)
