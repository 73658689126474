import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class Chart extends Component {
  render() {
    const { options, key } = this.props
    const opt = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      legend: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
        column: {
          maxPointWidth: 100,
        },
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [],
        title: {
          text: null,
        },
      },
      series: [],
    }

    const newOption = { ...opt, ...options }
    return (
      <HighchartsReact
        key={key}
        highcharts={Highcharts}
        options={newOption}
      />
    )
  }
}

export default Chart
