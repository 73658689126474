import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import {
  Button, Avatar, Icon, IconButton, Grid
} from '@material-ui/core'
import CriarEditarSistemaDialog from '../../_organisms/pessoa/criarEditar/criarEditarSistemaDialog'

import ReactTable from '../../_atoms/ReactTable'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import { entrarAction } from '../../actions/loginAction'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import ComboBoxData from '../../_atoms/combobox/ComboBoxData'
import TextField from '../../_atoms/TextField'
import UploadFileModal from '../../_atoms/uploadFileModal'
import { EMTable } from '@em/lib/src'

const tableRef = React.createRef()

const ListaSistemas = ({ showMensagemConfirmacaoAction, entrarAction }) => {
  const [filter, setFilter] = useState({ busca: '' })
  const [modalEditarSistema, setModalEditarSistema] = useState({ open: false })
  const [uploadFileModal, setUploadFileModal] = useState({ open: false })
  const [segmentosFull, setSegmentosFull] = useState([])

  const loadSistemas = () => tableRef.current && tableRef.current.onQueryChange({ page: 0 })

  useEffect(() => {
    axios.get('/public/api/sistemaSegmento/full')
      .then(response => {
        setSegmentosFull(response)
      })
  }, [])

  const onDelete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {deleted.nome}
          </strong>
?
        </span>
      ),
      buttoes: [{ text: 'EXCLUIR', onClick: () => deleteConfirmed(deleted) }]
    }
    showMensagemConfirmacaoAction(model)
  }

  const deleteConfirmed = deleted => {
    axios.delete(`api/Sistema/${deleted.id}`).then(() => {
      loadSistemas()
    })
  }

  const onTrocarSistemaConfirmed = sistema => {
    axios.post(`api/login/${sistema.id}`).then(result => {
      entrarAction(result)
      // window.location = '/'
    })
  }

  const onTrocarSistema = sistema => {
    const model = {
      icon: <Icon>compare_arrows</Icon>,
      title: 'Trocar',
      mensagem: (
        <span>
          Você realmente deseja trocar para o sistema:
          {' '}
          <strong>
            {' '}
            {sistema.nome}
          </strong>
?
        </span>
      ),
      buttoes: [{ text: 'Sim', onClick: () => onTrocarSistemaConfirmed(sistema) }]
    }
    showMensagemConfirmacaoAction(model)
  }

  return (
    <div>
      <Title
        title="Lista de Sistemas"
        buttons={[
          {
            icon: 'cloud_upload',
            color: 'primary',
            onClick: () => setUploadFileModal({ open: true })
          },
          {
            icon: 'add',
            color: 'primary',
            onClick: data => setModalEditarSistema({ open: true, data })
          }
        ]}
      />

      <Grid container spacing={1} justify="space-between" >
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <TextField
                label="Nome da Empresa"
                type="text"
                onChange={e => setFilter({ ...filter, busca: e.target.value })}
                value={filter.busca}
              />
            </Grid>
            <Grid item>
              <ComboBoxData
                label="Verificação"
                onChange={e => setFilter({ ...filter, confianca: e.id })}
                selected={filter.confianca}
                list={[
                  { id: 10, nome: 'Não Verificado' },
                  { id: 75, nome: 'Confirmado' },
                  { id: 100, nome: 'Verificado' }
                ]}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={loadSistemas}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => window.location = '/download/InstaladorEmutility.exe'}
          >
            Baixar EMUtility
          </Button>
        </Grid>
      </Grid>

      <EMTable
        tableRef={tableRef}
        options={{
          sorting: true
        }}
        data={query => {
          const { page, pageSize, orderDirection, orderBy } = query
          let sort = 'sistemaId-desc'
          console.log({ orderBy, orderDirection })
          if (orderBy?.field) {
            sort = orderBy.field + '-' + orderDirection
          }

          return axios.get('/api/Sistema', {
            params: {
              ...filter,
              orderBy: sort,
              offset: page,
              limit: pageSize
            }
          }).then(response => {
            return {
              data: response.lista,
              page,
              totalCount: response?.paginacao?.qtdeRows || 0
            }
          })
        }}
        columns={[
          {
            width: 60,
            field: 'foto',
            render: dataRow => <Avatar
              variant="rounded" style={{ textAlign: 'center' }}
              src={global.bucket + dataRow.foto}
            />
          },
          {
            width: 100,
            title: 'Database',
            field: 'sistemaId'
          },
          {
            title: 'Codigo',
            field: 'codigo'
          },
          {
            title: 'Nome da Empresa',
            field: 'nome'
          },
          {
            title: 'Server',
            field: 'server'
          },
          // {
          //   title: 'Paginas',
          //   field: 'paginasIds',
          //   render: dataRow => dataRow.paginasIDs?.length
          // },
          // {
          //   title: 'Endereços',
          //   field: 'enderecos',
          //   render: dataRow => dataRow.enderecos?.length
          // },
          // {
          //   title: 'Contatos',
          //   field: 'contatos',
          //   render: dataRow => dataRow.contatos?.length
          // },
          // {
          //   title: 'Impressões',
          //   field: 'impressoesFlechei'
          // },
          {
            title: 'Score',
            field: 'scoreFlechei'
          },
          {
            width: 150,
            title: 'Verificação',
            field: 'confianca',
            render: dataRow => <progress value={dataRow.confianca || 0} max="100" />
          },
          {
            width: 200,
            render: dataRow => (
              <div>
                <IconButton onClick={() => onTrocarSistema(dataRow)} color="default">
                  <Icon>link</Icon>
                </IconButton>
                <IconButton
                  onClick={() => setModalEditarSistema({ open: true, data: dataRow })}
                  color="primary"
                >
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => onDelete(dataRow)} color="secondary">
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )
          }
        ]}
      />

      {modalEditarSistema.open && (
        <CriarEditarSistemaDialog
          onHide={refresh => {
            setModalEditarSistema({ open: false })
            if (refresh === true) loadSistemas()
          }}
          data={modalEditarSistema.data}
          segmentosFull={segmentosFull || []}
        />
      )}
      {uploadFileModal.open && (
        <UploadFileModal
          onHide={refresh => {
            setUploadFileModal({ open: false })
            if (refresh === true) loadSistemas()
          }}
          title="Importação de Sitemas"
          tipo={1}
        />
      )}
    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction,
      entrarAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaSistemas)
