import React, { PureComponent } from 'react'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Title from '@em/lib/src/util/Title'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import Lista from '../../../_organisms/pdv/formasDePagamento/lista'
import CreateFormaPagamentoModal from '../../../_organisms/pdv/formasDePagamento/createFormaPagamentoModal'
import { IoMdCash } from "react-icons/io"

class FormasDePagamento extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalFormaPagamento: { open: false },
      formas: [],
    }
  }

  componentDidMount() {
    this.loadFormasDePagamento()
  }

  loadFormasDePagamento = () => {
    axios.get('api/FormaPagamento/full').then(formas => {
      this.setState({ formas })
    })
  }

  render() {
    const { formas, modalFormaPagamento } = this.state

    return (
      <>

        <Title
          icon={<IoMdCash style={{ fontSize: 24 }} />}
          title="Formas de pagamento ativas"
          buttons={[
            {
              icon: 'add',
              onClick: () => this.setState({ modalFormaPagamento: { open: true } }),
            },
          ]}
        />

        <Lista data={formas} onReload={this.loadFormasDePagamento} />

        {modalFormaPagamento.open &&
          <CreateFormaPagamentoModal
            formasDePagamentoSelecionadas={formas}
            onHide={() => this.setState({ modalFormaPagamento: { open: false } })}
            onReload={() => this.setState({ modalFormaPagamento: { open: false } }, this.loadFormasDePagamento)}
          />
        }

      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction,
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(FormasDePagamento)
