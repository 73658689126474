export default [
  { id: 0, nome: 'Novo pedido', nome2: 'Aguardando Integração', workflow: false },
  { id: 1, nome: 'Pedido recebido', nome2: 'Aguardando Confirmação', workflow: false },
  { id: 2, nome: 'Pedido Confirmado', nome2: 'Aguardando Preparo', workflow: true, activeIds: [3, 4, 5, 11, 34] },
  { id: 3, nome: 'Pedido Cancelado', nome2: 'Cancelado', workflow: true, activeIds: [] },
  { id: 4, nome: 'Pedido saiu para entrega', nome2: 'Saiu para entrega', workflow: true, activeIds: [3, 5] },
  { id: 5, nome: 'Pedido Entregue', nome2: 'Entregue', workflow: true, activeIds: [34] },
  { id: 6, nome: 'Pedido Concluído', nome2: 'Concluido', workflow: false, activeIds: [34] },
  { id: 7, nome: 'Solicitação de cancelamento do pedido', workflow: false },
  { id: 8, nome: 'Solicitação de cancelamento negada', workflow: false },
  { id: 9, nome: 'Entregador se deslocando para retirar pedido', workflow: false },
  { id: 10, nome: 'Entregador chegou para retirar o pedido', workflow: false },
  { id: 11, nome: 'Pedido pronto para ser retirado', workflow: true, activeIds: [3, 12] },
  { id: 12, nome: 'Pedido Coletado', workflow: true, activeIds: [] },
  { id: 13, nome: 'Cliente chegou na vaga para retirar pedido', workflow: false },
  { id: 14, nome: 'Notificação de atraso', workflow: false },
  { id: 15, nome: 'Alteração do Tempo de Preparo do Pedido', workflow: false },
  { id: 16, nome: 'Verificar disponibilidade de entregador iFood', workflow: false },
  { id: 17, nome: 'Requisitar entregador iFood', workflow: false },
  { id: 18, nome: 'Requisição de entregador iFood aprovada', workflow: false },
  { id: 19, nome: 'Requisição de entregador iFood negada', workflow: false },
  { id: 20, nome: 'Entregador alocado', workflow: false },
  { id: 21, nome: 'Solicitação de cancelamento do pedido pelo cliente', workflow: false },
  { id: 22, nome: 'Solicitação de cancelamento do pedido pelo cliente aceita', workflow: false },
  { id: 23, nome: 'Solicitação de cancelamento do pedido pelo cliente negada', workflow: false },
  { id: 24, nome: 'Agrupamento de Pedidos - Uso exclusivo iFood 1', workflow: false },
  { id: 25, nome: 'Agrupamento de Pedidos - Uso exclusivo iFood 2', workflow: false },
  { id: 26, nome: 'Agrupamento de Pedidos - Uso exclusivo iFood 3', workflow: false },
  { id: 27, nome: 'Agrupamento de Pedidos - Uso exclusivo iFood 4', workflow: false },
  { id: 28, nome: 'Agrupamento de Pedidos - Uso exclusivo iFood 5', workflow: false },
  { id: 29, nome: 'Requisição de alteração de informação do pedido', workflow: false },
  { id: 30, nome: 'Requisição de alteração de informação do pedido negada', workflow: false },
  { id: 31, nome: 'Pedido alterado', workflow: false },
  { id: 32, nome: 'Pedido pode ser colocado no Box', workflow: false },
  { id: 33, nome: 'Recomendação para iniciar o preparo do pedido', workflow: false },
  { id: 34, nome: 'Indica que o pedido está pronto para ser retirado pra retirar ou na mesa', workflow: true }
]
