import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'

class TabEmailCriarEditarDialog extends Component {
  state = {
    form: { ...this.props.data },
    showErros: false
  }

  handlerChange = e => {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    })
  };

  render () {
    const { form } = this.state
    const { data } = this.props
    return (
      <Dialog open fullWidth maxWidth="sm">
        <Title title={`${(data && 'Editar') || 'Criar'} email`} />
        <DialogContent style={{ padding: 30 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                value={(form && form.email) || ''}
                onChange={this.handlerChange}
                autocomplete="off"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onHide} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => this.props.onAdd(form)} color="primary" variant="contained">
            {(data && 'Editar') || 'Criar'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TabEmailCriarEditarDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default TabEmailCriarEditarDialog
