import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { CircularProgress, MenuItem } from '@material-ui/core'
import { TextField } from '..'
import { Autocomplete } from '@material-ui/lab'
import * as fnHelper from '@em/lib/src/util/fnHelper'

const ComboBoxAPI = (props) => {
  const { nameValue, nameText, loadStart, onChange, noneText, fnText, label, error, value, ...others } = props
  const [list, setList] = useState((others.default && others.default[nameValue]) ? [others.default] : [])
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loadStart) {
      load()
    }
  }, [])

  useEffect(() => {
    if (others?.default && others?.default[nameValue]) {
      const listSemDefault = list.filter(x => x[nameValue] != others?.default[nameValue])
      setList(
        [
          others.default,
          ...listSemDefault
        ])
    }
  }, [others.default])

  const load = async () => {
    setLoading(true)
    if (!loaded) {
      const list = await axios.get(props.api)

      if (others?.default && others.default[nameValue]) {
        const listSemDefault = list.filter(x => x[nameValue] != others.default[nameValue])
        setList(
          [
            others.default,
            ...(props.applyOrderBy ? fnHelper.orderBy(listSemDefault, nameText) : listSemDefault)
          ])
      } else {
        setList(props.applyOrderBy ? fnHelper.orderBy(list, nameText) : list)
      }
      setLoaded(true)
    }

    setLoading(false)
  }

  const newList = (list || []).map(item => {
    return { ...item, [nameText]: item[nameText] || 'Não identificado' }
  })

  return (
    <Autocomplete
      options={newList}
      size="small"
      onFocus={load}
      getOptionLabel={option => option[nameText]}
      onChange={(e, item, t) => {
        if (item) {
          onChange(item)
        } else {
          onChange({ [nameValue]: '' })
        }
      }}
      // style={others?.style}
      // onKeyPress={others?.onKeyPress}
      value={newList.find(x => x[nameValue] == value) || ''}
      renderInput={(params) =>
        <TextField
          {...params}
          label={label}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />}
      {...others}
    />
  )
}

ComboBoxAPI.propTypes = {
  loadStart: PropTypes.bool,
  default: PropTypes.object,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  api: PropTypes.string.isRequired,
  nameValue: PropTypes.string,
  nameText: PropTypes.string,
  fnText: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.bool,
  noneText: PropTypes.string
}

ComboBoxAPI.defaultProps = {
  loadStart: false,
  fnText: null,
  nameValue: 'id',
  nameText: 'nome',
  error: false
}

export default ComboBoxAPI
