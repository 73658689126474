import React, { Component, useRef, useState } from 'react'
import axios from 'axios'
import {
  Dialog, DialogActions, DialogContent, Button, Grid,
} from '@material-ui/core'
import { TextField } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import { DialogTitle } from '@material-ui/core'
import { TinyEditor } from '@em/lib/src'

const AddOrEditModal = (props) => {
  const editorRef = useRef(null)

  const [form, setForm] = useState(props.data)


  const onSave = () => {
    const html = editorRef.current.getContent()

    const newFoem = _.omit(form, ["ultimaAlteracao"])
    axios.post('api/DocumentoModelo', { ...newFoem, html }).then(resp => {
      props.onHide(true)
    })
  }

  const handlerChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  };

  return (
    <div>
      <Title
        title={form && form.id ? 'Edição de Documento' : 'Novo Documento'}
        buttons={[{ icon: 'close', color: 'secondary', onClick: props.onHide }]}
      />

      <DialogContent>
        <Grid container spacing={1} >
          <Grid item xs={12}>
            <TextField
              label="Nome do Documento"
              name="nome"
              value={form && form.nome}
              onChange={handlerChange}
            />
          </Grid>
          <Grid item xs={12}>

            <TinyEditor
              editorRef={editorRef}
              initialValue={form?.html}
            />

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onSave}>
          Salvar
        </Button>
      </DialogActions>
    </div>
  )
}

export default AddOrEditModal
