import React from 'react'
import { Grid, Button, Typography, List, ListItem, Checkbox, ListItemSecondaryAction, ListItemText, ListItemAvatar, Radio, ListItemIcon } from '@material-ui/core'
import { NumberFormatMoneyBR } from '../../util/InputCustom'

const ModeloChips = ({ opcoes, onSelectOne }) => (
  <Grid container spacing={1}>
    {opcoes && opcoes.map((opc, idx) => {
      const qtde2 = (opc.qtde || 0) + (opc.incluso || 0)
      let borderColor = '#ccc'
      if (qtde2 > 0) borderColor = '#66bb6a'

      return (
        <Grid item key={idx}>
          <Button
            style={{ borderRadius: 15, border: `2px solid ${borderColor}` }}
            onClick={() => onSelectOne(opcoes, opc)}
          >
            <div>
              <Typography variant="subtitle1">{opc.nome}</Typography>
              <Typography variant="caption">
                <NumberFormatMoneyBR value={opc.valor} />
              </Typography>
            </div>
          </Button>
        </Grid>
      )
    })}
  </Grid>
)

const ModeloRadio = ({ opcoes, onSelectOne }) => (
  <List dense
    style={{ width: '100%' }}
  >
    {opcoes && opcoes.map((opc, idx) => {
      const qtde2 = (opc.qtde || 0) + (opc.incluso || 0)
      let active = false
      if (qtde2 > 0) active = true

      return (
        <ListItem key={idx}
          button
          onClick={() => onSelectOne(opcoes, opc)}
        >
          <ListItemIcon>
            <Radio
              edge="end"
              checked={active}
            />
          </ListItemIcon>
          <ListItemText primary={opc.nome} />

          <ListItemSecondaryAction>
            <NumberFormatMoneyBR value={opc.valor} />
          </ListItemSecondaryAction>
        </ListItem>
      )
    })}
  </List>
)

const OptionSelectOne = ({ opcoes, onSelectOne, model }) => {
  if (model === 1) {
    return <ModeloChips opcoes={opcoes} onSelectOne={onSelectOne}/>
  } else {
    return <ModeloRadio opcoes={opcoes} onSelectOne={onSelectOne}/>
  }
}

export default OptionSelectOne
