import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import Title from '@em/lib/src/util/Title'
import CriaPerfilDialog from '../../_organisms/pessoa/perfil/criaPerfilDialog'
import PerfilDialig from '../../_organisms/pessoa/perfil/PerfilDialig'
import TabelaPerfil from '../../_organisms/pessoa/perfil/TabelaPerfil'

class Perfil extends Component {
  state = {
    saving: false,
    modal: false,
    perfis: [],
    idxPerfilSelecionado: null,
  };

  componentDidMount() {
    axios.get('api/perfil').then(perfis => {
      this.setState({ perfis })
    })
  }

  addNewPerfisToState = newPerfil => {
    const { perfis } = this.state

    const index = perfis.findIndex(x => x.id === newPerfil.id)
    perfis[index] = newPerfil

    this.setState({
      perfis,
    })
  };

  onCheckPage = (pagina, perfil, checked) => {
    const paginas = [...(perfil.paginas || [])]
    if (checked) {
      paginas.push({
        id: pagina.id,
        funcoes: [],
      })
    } else _.remove(paginas, x => x.id === pagina.id)

    this.addNewPerfisToState({ ...perfil, paginas })
  };

  onCheckFunction = (pagina, perfil, funcao, checked) => {
    const indexPagina = _.findIndex(perfil.paginas, p => p.id === pagina.id)

    const funcoes = [...perfil.paginas[indexPagina].funcoes]

    if (checked) {
      funcoes.push(funcao)
    } else {
      _.remove(funcoes, x => _.toLower(x) === _.toLower(funcao))
    }

    const paginas = [...perfil.paginas]

    paginas[indexPagina] = { ...perfil.paginas[indexPagina], funcoes }

    this.addNewPerfisToState({ ...perfil, paginas })
  };

  onAddPerfil = model => {
    axios.post('api/Perfil', model).then(result => {
      this.setState({ perfis: [...this.state.perfis, result.dados], modal: false })
    })
  };

  editPerfil = () => {
    const { perfis, idxPerfilSelecionado } = this.state
    const perfil = perfis[idxPerfilSelecionado]

    axios.post('api/Perfil', perfil).then(() => {
      this.setState({ idxPerfilSelecionado: null })
    })
  };

  render() {
    const { modal, perfis, idxPerfilSelecionado } = this.state
    const perfil = perfis[idxPerfilSelecionado]

    return (
      <div>
        <Title title="Perfis de acesso" buttons={[{ icon: 'add', onClick: () => this.setState({ modal: true }) }]} />

        <TabelaPerfil perfis={perfis} onEdit={x => this.setState({ idxPerfilSelecionado: x.index })} />

        {perfil && (
          <PerfilDialig
            onCheckPage={this.onCheckPage}
            onCheckFunction={this.onCheckFunction}
            perfil={perfil}
            onSave={this.editPerfil}
            onHide={() => this.setState({ idxPerfilSelecionado: null })}
          />
        )}

        {modal && <CriaPerfilDialog onSave={this.onAddPerfil} onHide={() => this.setState({ modal: false })} />}
      </div>
    )
  }
}

export default Perfil
