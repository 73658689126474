import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Avatar, Grid, IconButton, Tooltip, Chip, Button } from '@material-ui/core'

export default props => {
  const [files, setFiles] = useState([])
  const [tags, setTags] = useState([])

  const loadFiles = () => {
    axios.get('api/arquivo?limit=1000').then(response => {
      const tags = [{ name: 'Sem tag', selected: true }]
      response.lista.forEach(item => {
        item.tags.forEach(tag => {
          if (!tags.map(x => x.name).includes(tag)) {
            tags.push({ name: tag, selected: false })
          }
        })
      })
      setFiles(response.lista)
      setTags(tags)
    })
  }

  useEffect(() => {
    loadFiles()
  }, [])

  const onSelectTag = (tag) => {
    setTags(tags.map(t => {
      if (tag.name == t.name) {
        return { name: t.name, selected: true }
      }
      return { name: t.name, selected: false }
    }))
  }

  return (
    <>
      <div style={{ margin: 10 }}>
        {tags.map(tag => {
          return (
            <Chip color={tag.selected ? 'secondary' : 'primary'} label={tag.name} onClick={() => onSelectTag(tag)} />
          )
        })}
      </div>

      <Grid container spacing={2}>
        {files.filter(f => {
          const tagSelected = tags.find(x => x.selected)

          if (tagSelected && tagSelected.name == 'Sem tag' && (!f.tags || f.tags.length == 0)) {
            return true
          }

          let contem = false
          f.tags.forEach(t => {
            if (tagSelected && tagSelected.name == t) {
              contem = true
            }
          })
          return contem
        }).map((file, idx) => (
          <Grid item xs={12} sm={6} md={4} lg={2} style={{ cursor: 'pointer' }}>
            <Tooltip title={file.descricao} arrow>
              <Grid container style={{ height: 150 }} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <img
                    onClick={() => props.onCapture(file.key)}
                    variant="rounded"
                    style={{ maxWidth: 150 }}
                    alt="G"
                    src={`${global.bucket}${file.key}`}
                  />
                </Grid>
                <Grid item xs={12}>{file.titulo} <br /><i>{((file.size || 0) * 1e-6).toFixed(2)} MB</i></Grid>
              </Grid>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
