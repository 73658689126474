import Axios from 'axios'
import moment from 'moment'
import {
  getCenter,
  getSpaceBetween
} from './helpers'

const layoutReciboMotoboy = async financeiro => {
  const { financeiroId, valorLiquido, telaId } = financeiro
  if (!financeiroId || !telaId) {
    return
  }
  const settings = JSON.parse(financeiro.settings)
  const pedidos = await Axios('api/pedido/full', { params: { financeiroId, entregadorId: telaId } })
  let dateStart = ''
  let dateEnd = ''
  pedidos.forEach(pedido => {
    if (!dateStart || dateStart > pedido.dataCadastro) {
      dateStart = pedido.dataCadastro
    }
    if (!dateEnd || dateEnd < pedido.dataCadastro) {
      dateEnd = pedido.dataCadastro
    }
  })

  var lines = [
    {
      value: 'RELATÓRIO DE ENTREGAS',
      fontStyle: 1,
      fontSize: 14
    },
    {
      value: `     ${moment().format('DD/MM/YYYY HH:mm')}`,
      fontSize: 12
    },
    {
      value: '------------------------------------------------------'
    },
    {
      value: 'Nome: ' + settings.nome
    },
    {
      value: 'Quantidade de viagens: ' + pedidos.length
    },
    {
      value: '------------------------------------------------------'
    },
    {
      value: 'PEDIDO BAIRRO CEP   VALOR',
      fontStyle: 1,
      fontSize: 13,
      top: 20
    }
  ]

  pedidos.forEach(pedido => {
    let line1 = ''; let line2 = '';

    (`${pedido.numero} ${pedido?.endereco?.bairro}`).split(' ').forEach(word => {
      if ((line1.length + word.length) < 28) {
        line1 += `${word} `
      } else {
        line2 += `${word} `
      }
    })

    lines.push({
      value: line1,
      fontSize: 12
    })
    lines.push({
      value: getSpaceBetween(line2, `${pedido?.endereco?.cep} ${(pedido.valorFreteRepasse || 0).toFixed(2).replace('.', ',')}`, 12),
      fontSize: 12
    })
  })

  lines.push({
    value: getCenter('VALOR REPASSE', 12),
    fontStyle: 1,
    fontSize: 12,
    top: 20
  })

  lines.push({
    value: getCenter((valorLiquido || 0).toFixed(2).replace('.', ','), 15),
    fontStyle: 1,
    fontSize: 15
  })

  lines.push({
    value: '    __________________________________',
    top: 40
  })

  lines.push({
    value: getCenter('Assinatura', 10),
    fontSize: 10,
    top: 12
  })

  lines.push({
    value: getCenter('Obrigado pelo excelente serviço', 10),
    fontSize: 10,
    top: 20
  })

  return lines
}

export default layoutReciboMotoboy
