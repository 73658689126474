import TextFielddMoneyBR from '@em/lib/src/util/InputCustom/TextFieldMoneyBR'
import Title from '@em/lib/src/util/Title'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  Tooltip
} from '@material-ui/core'
import React, { useState } from 'react'
import { TextFieldPercent } from '../../../../_atoms/numberFormat'

function DiscountDialog ({
  onHide,
  pedido,
  onRefreshPedido
}) {
  const [discountAmount, setDiscountAmount] = useState(pedido?.discountAmount || 0)
  const [percent, setPercent] = useState(Number(((pedido?.discountAmount * 100) / pedido._valor).toFixed(2)) || 0)
  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
    >
      <Title
        title="Aplicar Desconto" buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
        subTitle={`Apenas é considerado a soma dos itens! (R$ ${pedido._valor.toFixed(2).replace('.', ',')})`}
      />
      <DialogContent>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Divider />
            <div style={{ padding: 10 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  {[5, 10, 20, 30, 40, 50].map((v, idx) => {
                    const selected = Number(((100 * discountAmount) / pedido._valor).toFixed(2)) === Number(v?.toFixed(2))
                    return (
                      <Button
                        key={idx}
                        variant="contained"
                        style={{
                          backgroundColor: selected ? '#228B22' : '#32CD32',
                          color: '#fff'
                        }}
                        onClick={() => {
                          const newDiscountAmount = Number(((pedido._valor * v) / 100).toFixed(2))
                          setDiscountAmount(newDiscountAmount)
                          setPercent(v)
                        }}
                      >
                        {`${v}%`}
                      </Button>
                    )
                  })}

                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
                  <div style={{ color: '#ccc', margin: '0px 10px' }} >OU</div>
                  <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextFieldPercent
                    label="Alterar Percentual de Desconto"
                    size="small"
                    value={percent}
                    onValueChange={e => setPercent(e.target.value)}
                  />
                  <Tooltip placement="top" title="Calcular">
                    <IconButton
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        const newDiscountAmount = Number(((pedido._valor * percent) / 100).toFixed(2))
                        setDiscountAmount(newDiscountAmount)
                      }}
                    >
                      <Icon>exposure</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
                  <div style={{ color: '#ccc', margin: '0px 10px' }} >OU</div>
                  <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextFielddMoneyBR
                    label="Alterar Valor de Desconto"
                    size="small"
                    value={discountAmount}
                    onValueChange={e => setDiscountAmount(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onRefreshPedido({ ...pedido, discountAmount })
            onHide()
          }}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DiscountDialog
