import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Button } from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import ModuloCard from './ModuloCard'
import paginasDoSistema from '../../../_templates/routes/paginasDoSistema'
import { PERFIL_ADMIN_ID } from '../../../contstants/systemConstants'

export default class PerfilDialig extends Component {
  render() {
    const {
      perfil, onCheckPage, onCheckFunction, onSave, onHide,
    } = this.props
    return (
      <Dialog fullWidth maxWidth={false} open onClose={onHide}>
        <Title
          title={perfil.nome}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide,
            },
          ]}
        />

        <DialogContent style={{ paddingTop: 10 }}>
          {paginasDoSistema.map((modulo, idx2) => {
            if (!modulo.paginas || modulo.paginas.length === 0) return null
            return (
              <Grid item xs={12} key={`${idx2}`}>
                <ModuloCard onCheckPage={onCheckPage} onCheckFunction={onCheckFunction} perfil={perfil} modulo={modulo} />
              </Grid>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onSave} color="primary" variant="contained" disabled={perfil.id === PERFIL_ADMIN_ID}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
