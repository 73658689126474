import React from 'react'
import { } from './helpers'

function PrintSimulator ({
  layout
}) {
  return (
    <div style={{ backgroundColor: '#fdfd96', width: 278.25, minHeight: 400 }}>
      {layout?.map((line, idx) => {
        if (line?.type === '1') {
          return (
            <img
              style={{
                marginTop: Number(line?.top),
                marginLeft: Number(line?.left),
                whiteSpace: 'pre',
                overflowX: 'hidden'
              }}
              src={'data:image/png;base64,' + line?.value}
              alt=""
            />
          )
        }
        return (
          <div key={line?.type + idx}
            style={{
              fontStyle: line?.fontStyle === '2' ? 'italic' : 'normal',
              fontWeight: line?.fontStyle === '1' ? 'bold' : '100',
              textDecoration: line?.fontStyle === '4' ? 'underline' : line?.fontStyle === '8' ? 'line-through' : 'none',
              marginTop: Number(line?.top),
              marginLeft: Number(line?.left),
              fontSize: `${Number(line?.fontSize)}pt`,
              fontFamily: line?.fontFamily,
              overflowX: 'hidden',
              whiteSpace: 'pre'
            }}
          >
            <div>{line?.value}</div>
          </div>
        )
      })}
    </div>
  )
}

export default PrintSimulator
