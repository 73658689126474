import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Menu, MenuItem, Avatar, Icon, Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { sairAction, entrarAction } from '../actions/loginAction'
import { showMensagemConfirmacaoAction } from '../actions/mensagemAction'

class MenuPaginaConfig extends React.Component {
  state = {
    anchorEl: null
  }

  renderAvatar() {
    const { usuario } = this.props
    const { pessoa } = usuario
    const { foto, nome } = pessoa
    const eventAvatar = event => this.setState({ anchorEl: event.currentTarget })

    const propsComun = {
      style: { cursor: 'pointer' },
      onClick: eventAvatar
    }

    if (foto) {
      return (
        <Avatar
          variant="rounded"
          src={usuario.pessoa.foto}
          {...propsComun}
        />
      )
    }

    if (nome) {
      const array = nome.split(' ')
      let letras = ''
      if (array.length > 0) {
        letras = nome.split('')[0]
      }
      if (array.length > 1) {
        letras += array[array.length - 1].split('')[0]
      }

      return (
        <Avatar
          variant="rounded" {...propsComun}>
          {letras}
        </Avatar>
      )
    }

    return <Avatar
      variant="rounded" {...propsComun} />
  }

  goToAnterior = ({ id, nome }) => {
    const model = {
      icon: <Icon>compare_arrows</Icon>,
      title: 'Confirmação',
      mensagem: (
        <span>
          Você realmente deseja volter para o sistema:
          {' '}
          <strong>
            {nome}
          </strong>
?
        </span>
      ),
      buttoes: [{ text: 'Sim', onClick: () => this.onTrocarSistemaConfirmed(id) }]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  onTrocarSistemaConfirmed = id => {
    axios.post(`api/login/${id}`).then(result => {
      this.props.entrarAction(result)
      this.props.history.push('/')
    })
  };

  render() {
    const { anchorEl } = this.state
    const { usuario } = this.props
    const { sistemaAnterior } = usuario

    return (
      <div>
        {this.renderAvatar()}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          {sistemaAnterior.id != null &&
            <MenuItem onClick={() => {
              this.setState({ anchorEl: null }, () =>
                this.goToAnterior(sistemaAnterior)
              )
            }}>
              Voltar para {sistemaAnterior.nome}
            </MenuItem>
          }
          <MenuItem onClick={() => window.location.reload()}>Atualizar o sistema</MenuItem>
          <MenuItem onClick={() => this.props.sairAction()}>Sair</MenuItem>
        </Menu>
      </div>
    )
  }
}

MenuPaginaConfig.propTypes = {
  sairAction: PropTypes.func.isRequired,
  usuario: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sairAction,
      entrarAction,
      showMensagemConfirmacaoAction

    },
    dispatch
  )
}

export default withRouter(connect(null, mapDispatchToProps)(MenuPaginaConfig))
