import { EMTable } from '@em/lib/src'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import Title from '@em/lib/src/util/Title'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import xmltojson from 'xmltojson'
import { TextField } from '../../../../_atoms'
import { TextFieldMoneyBR } from '../../../../_atoms/numberFormat'

export default ({ data, onHide }) => {
  const renderNFe = () => {
    var myOptions = { mergeCDATA: false, xmlns: false, attrsAsObject: true }
    const result = xmltojson.parseString(data.xml, myOptions)
    const itens = []
    const NFe = result.nfeProc[0].NFe[0]
    const infNFe = NFe.infNFe[0]
    const det = infNFe.det
    const ide = infNFe.ide[0]
    const emit = infNFe.emit[0]
    const nNF = ide.nNF[0]._text
    const dhEmi = ide.dhEmi[0]._text
    const natOp = ide.natOp[0]._text
    const total = infNFe.total[0]
    const ICMSTot = total.ICMSTot[0]
    const vNF = ICMSTot.vNF[0]._text
    const vFrete = ICMSTot.vFrete[0]._text
    const fornecedor = {
      cnpj: emit.CNPJ[0]._text,
      nome: emit.xNome[0]._text,
      apelido: emit?.xFant && emit?.xFant[0]._text || emit.xNome[0]._text
    }

    det.forEach(itemXML => {
      const prod = itemXML.prod[0]
      const tax = {
        NCM: prod.NCM[0]._text,
        CFOPEntrada: prod.CFOP[0]._text
      }

      itens.push({
        nome: prod.xProd[0]._text,
        qtde: prod.qCom[0]._text,
        ean: prod.cEAN[0]._text,
        externalCode: prod.cProd[0]._text,
        costPrice: prod.vUnCom[0]._text,
        costPriceTotal: prod.vProd[0]._text,
        tax
      })
    })

    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              disabled
              label="Fornecedor"
              value={fornecedor?.cnpj}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              disabled
              label="Razão Social"
              value={fornecedor?.nome}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled
              label="Nome Fantasia"
              value={fornecedor?.apelido}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              readOnly
              label="Natureza da operação"
              value={natOp}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled
              label="Data Emissão"
              value={moment(dhEmi).format('DD/MM/YYYY HH:mm')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled
              label="Nota"
              value={`${nNF}`}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldMoneyBR
              disabled
              label="Valor do Frete"
              value={vFrete}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldMoneyBR
              disabled
              label="Valor da Nota"
              value={vNF}
            />
          </Grid>
        </Grid>
        <EMTable
          options={{
            toolbar: false,
            paging: false
          }}
          data={itens}
          columns={[
            {
              title: '#',
              render: row => {
                return row.tableData.id + 1
              }
            },
            {
              title: 'Codigo',
              field: 'externalCode'
            },
            {
              title: 'Descição',
              field: 'nome'
            },
            {
              title: 'Ean',
              field: 'ean'
            },
            {
              title: 'NCM',
              field: 'tax.NCM'
            },
            {
              title: 'CFOP',
              field: 'tax.CFOPEntrada'
            },
            {
              title: 'Qtde',
              field: 'qtde'
            },
            {
              title: 'Custo Un',
              field: 'costPrice',
              render: dataRow => (<NumberFormatMoneyBR value={dataRow.costPrice} />)
            },
            {
              title: 'Custo Total',
              field: 'costPriceTotal',
              render: dataRow => (<NumberFormatMoneyBR value={dataRow.costPriceTotal} />)
            }
          ]}
        />
      </div>
    )
  }

  return (
    <div>

      <Dialog maxWidth="lg" fullWidth open onClose={onHide}>
        <Title
          title={'Espelho da nota'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: onHide }
          ]}
        />
        <DialogContent>
          {data.xml ? renderNFe() : <span>XML vazio</span>}
        </DialogContent>
      </Dialog>
    </div>
  )
}
