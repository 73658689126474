import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Axios from 'axios'

import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from './../combobox'
import TextField from './../TextField'

export default ({
  onHide,
  apiGet,
  apiPost,
  queryPost,
  title,
  label,
  buttonLabel,
  nameDefault,
  inputLabel
}) => {
  const [list, setList] = useState([])
  const [form, setForm] = useState({
    conectorId: '',
    name: nameDefault || ''
  })
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  const loadApi = () => {
    if (!apiGet) {
      return null
    }
    Axios.get(apiGet)
      .then(resp => {
        setList(resp)
      })
  }

  const post = () => {
    if (!apiPost) {
      return null
    }
    setLoading(true)

    if (!form?.conectorId) {
      showError(true)
      return
    }

    Axios.post(`${apiPost}/${form?.conectorId}?${queryPost || ''}&name=${form?.name}`)
      .then(resp => {
        onHide(true)
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    loadApi()
  }, [])

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
    >
      <Title
        title={title || "Escolha o Conector"}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Grid container spacing={1}>
          {label &&
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                {label}
              </Typography>
            </Grid>
          }
          <Grid item xs={6}>
            <ComboBoxData
              label="Conector"
              onChange={e => setForm({...form, conectorId: e?.id })}
              selected={form?.conectorId}
              list={list}
              error={showError && !form?.conectorId}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={inputLabel || 'Assunto'}
              fullWidth
              value={form?.name}
              onChange={e => setForm({ ...form, name: e.target.value })}
              error={showError && !form?.name}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={post}
          disabled={loading}
          endIcon={loading ? <CircularProgress size="20px" /> : null}
        >
          {buttonLabel || 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
