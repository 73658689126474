import { Button, Dialog, DialogActions, DialogContent, Grid, Icon, IconButton } from '@material-ui/core'
import Axios from 'axios'
import React, { useState } from 'react'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'

const inputFileRef = React.createRef(null)

function UploadFileModal ({
  title,
  onHide,
  tipo
}) {
  const [file, setFile] = useState(null)
  const [key, setKey] = useState(new Date().getTime())
  const [showError, setShowError] = useState(false)

  const handleChange = e => {
    setFile(e.target.files[0])
    setKey(new Date().getTime())
  }

  const importar = async () => {
    if (!file) {
      setShowError(true)
      return null
    }
    const base64 = await fnHelper.toBase64(file)
    const data = {
      fileName: file.name,
      tipo,
      base64,
      titulo: file.name,
      descricao: '',
      tags: []
    }
    Axios.post('/api/Arquivo/UploadFileToS3', data)
      .then(() => {
        onHide(true)
      })
  }

  return (
    <Dialog open maxWidth={'sm'} fullWidth>
      <Title
        title={title || 'Importação'}
        buttons={[
          {
            icon: 'close',
            color: 'secondary',
            onClick: onHide
          }
        ]}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div
              style={{
                width: '100%',
                height: '100%',
                border: '1px solid #ccc',
                borderRadius: 4,
                backgroundColor: '#eee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={() => window.open('https://s3.amazonaws.com/emoutech.com.br/modelos/modelo_importacao_sistemas.csv', '_target')}
            >
              <Icon style={{ fontSize: 50 }}>
                cloud_download
              </Icon>
              <center style={{ heigth: 40 }}>{'Clique aqui para fazer download do arquivo modelo'}</center>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                width: '100%',
                height: '100%',
                border: `1px solid ${!file && showError ? '#c00000' : '#ccc'}`,
                borderRadius: 4,
                backgroundColor: !file && '#eee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: !file && '#ccc',
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={() => !file && inputFileRef.current.click()}
            >
              {file
                ? <img
                  src={require('./../assets/img/csv.png')}
                  height="50px"
                  width="50px"
                />
                : <Icon style={{ fontSize: 50 }}>
                  cloud_upload
                </Icon>
              }
              <center style={{ height: 40 }}>{file ? file.name : 'Clique aqui para anexar um arquivo'}</center>
              {file &&
                <IconButton
                  onClick={() => setFile(null)}
                  size="small"
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              }
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <input
          ref={inputFileRef}
          key={key}
          type="file"
          accept=".csv"
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={importar}
        >
          Importar
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default UploadFileModal
