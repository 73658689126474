import React from 'react'
import ReactTable from 'react-table-v6'

export default props => {
  const newProps = { ...props, data: props.data || [] }
  let hideHeader = true

  newProps.columns.forEach(col => {
    if (col.Header) {
      hideHeader = false
    }
  })

  if (hideHeader) {
    newProps.columns.forEach(col => {
      col.headerStyle = { ...col.headerStyle, display: 'none' }
    })
  }

  const showPagination = props.pages && props.pages > 1

  return (
    <ReactTable
      manual
      previousText="Anterior"
      nextText="Próximo"
      loadingText="Carregando..."
      noDataText={false}
      pageText="Pagina"
      ofText="de"
      rowsText="registros"
      showPageSizeOptions={false}
      showPagination={!!showPagination}
      defaultPageSize={0}
      sortable={false}
      resizable={false}
      multiSort={false}
      collapseOnSortingChange={false}
      collapseOnPageChange={false}
      collapseOnDataChange={false}
      className="-striped -highlight"
      {...newProps}
    />
  )
}
