import React from 'react'

export default props => (
  <fieldset style={{ border: '1px solid rgba(0, 0, 0, 0.23)', margin: 0, ...props.style }}>
    <legend style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>
      {props.label}
    </legend>
    {props.children}
  </fieldset>
)
