import React from 'react'
import _ from 'lodash'
import {
  Paper,
  Icon,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { NumberFormatMoneyBR } from './../../../../_atoms/numberFormat'
import ClienteDialog from './clienteDialog'
import axios from 'axios'
import { changeValorAuxItem } from '@em/lib/src/Produtos/somasCaixa2'

class PedidoItens extends React.Component {
  state = {
    clienteDialog: { open: false },
    anchorEl: null
  };

  changeDiscount = async (e, entidade) => {
    const { pedido, onRefreshPedido } = this.props

    if (e.target.checked) {
      const url = 'api/Entidade/' + entidade.id
      await axios.get(url)
        .then(resp => {
          pedido.cliente.entidade = {
            ...pedido.cliente.entidade,
            ...resp
          }
        })
    } else {
      pedido.cliente.entidade = {
        ...pedido.cliente.entidade,
        discount: 0
      }
    }
    pedido.itens = changeValorAuxItem(pedido.itens, pedido.cliente?.entidade?.discount)
    onRefreshPedido(pedido)
  }

  render() {
    const { clienteDialog } = this.state
    const { pedido, onRefreshPedido, allowEditByAdm } = this.props
    const { cliente, endereco } = pedido

    return (
      <>
        <div style={{ padding: 10, backgroundColor: '#EEE', border: "1px solid #BABABA" }}>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ flex: 1 }}>
              <strong>Dados do Cliente</strong>

              {cliente && cliente.nome && (
                <p style={{ margin: 0 }}>
                  <strong>Nome: </strong>
                  {' '}
                  {cliente.nome}
                </p>
              )}
              {cliente && cliente.cpf && (
                <p style={{ margin: 0 }}>
                  {' '}
                  <strong>CPF na nota: </strong>
                  {' '}
                  {cliente && cliente.cpf}
                </p>
              )}
              {endereco && (
                <>
                  {endereco.rua && endereco.numero && (
                    <p style={{ margin: 0 }}>

                      {' '}
                      <strong>Endereço: </strong>
                      {' '}
                      {endereco.rua}
,
                      {' '}
                      {endereco.numero}
                      {' '}
                    </p>
                  )}
                  {endereco.bairro && (
                    <p style={{ margin: 0 }}>

                      {' '}
                      <strong>Bairro: </strong>
                      {endereco.bairro}
                      {' '}
                    </p>
                  )}
                  {endereco.cidade && (
                    <p style={{ margin: 0 }}>

                      {' '}
                      <strong>Cidade: </strong>
                      {endereco.cidade}
                      {' '}
                    </p>
                  )}
                  {endereco.referencia && (
                    <p style={{ margin: 0 }}>

                      {' '}
                      <strong>Ponto de referencia: </strong>
                      {endereco.referencia}
                      {' '}
                    </p>
                  )}
                  {endereco.cep && (
                    <p style={{ margin: 0 }}>

                      <strong>CEP: </strong>
                      {' '}
                      {endereco.cep}
                      {' '}
                    </p>
                  )}
                </>
              )}
              {cliente && cliente.telefone && (
                <p style={{ margin: 0 }}>

                  {' '}
                  <strong>Telefone: </strong>
                  {' '}
                  {cliente && cliente.telefone}
                </p>
              )}
              {cliente && cliente.email && (
                <p style={{ margin: 0 }}>

                  {' '}
                  <strong>Email: </strong>
                  {' '}
                  {cliente.email}
                </p>
              )}
              {cliente?.entidade?.id && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    Desconto de {cliente?.entidade?.discount}% referente ao grupo {cliente?.entidade?.nome}
                  </div>
                  <Checkbox
                    checked={cliente?.entidade?.discount > 0}
                    color="primary"
                    onClick={e => this.changeDiscount(e, cliente?.entidade)}
                    disabled={(pedido?.statusEntregaJoin?.blockEdit && !allowEditByAdm)}
                  />
                </div>
              )}
            </div>
            <div>
              <IconButton
                onClick={() => this.setState({ clienteDialog: { open: true } })}
                disabled={(pedido?.statusEntregaJoin?.blockEdit && !allowEditByAdm)}
              >
                <Icon>{cliente ? 'person' : 'person_add'}</Icon>
              </IconButton>
            </div>
          </div>
        </div>

        {clienteDialog.open && (
          <ClienteDialog
            onRefreshPedido={onRefreshPedido}
            pedido={pedido}
            onHide={() => this.setState({ clienteDialog: { open: false } })}
          />
        )}
      </>
    )
  }
}

export default PedidoItens
