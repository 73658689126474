import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog, DialogContent, Grid, Button, DialogActions, Typography,
} from '@material-ui/core'
import { EmptyList } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import formasDePagamentoJson from '../../../contstants/formasDePagamento.json'

class CreateFormaPagamentoModal extends Component {
  constructor(props) {
    super(props)
    const idsFormasDePagamento = props.formasDePagamentoSelecionadas.map(f => f.id)
    const formasDePagamento = formasDePagamentoJson.filter(f => !_.includes(idsFormasDePagamento, f.id))
    this.state = { formasDePagamento }
  }

    onSave = () => {
      const { formasDePagamento } = this.state
      const model = formasDePagamento.filter(x => x.selected)
      axios.post('api/FormaPagamento', model).then(response => {
        this.props.onReload()
      })
    }

    onSelect = forma => {
      const formasDePagamento = this.state.formasDePagamento.map(fp => (forma.id == fp.id ? { ...fp, selected: !fp.selected } : fp))
      this.setState({ formasDePagamento })
    }

    render() {
      const { onHide } = this.props
      const { formasDePagamento } = this.state
      let disabled = true
      return (
        <Dialog open>

          <Title
            title="Formas de Pagamento"
            buttons={[
              { icon: 'close', color: 'secondary', onClick: this.props.onHide },
            ]}
          />

          <DialogContent style={{ paddingTop: 10 }}>
            <Typography variant="title">Selecione as formas de pagamento que você deseja adicionar.</Typography>
            {formasDePagamento.length == 0
                        && <EmptyList text="Não existem mais formas de pagamento para adicionar" />}

            <Grid container spacing={1}>
              {formasDePagamento && formasDePagamento.map(f => {
                if (f.selected) {
                  disabled = false
                }
                const backgroundColor = f.selected ? '#333' : 'white'
                const color = f.selected ? 'white' : '#333'

                return (
                  <Grid item>
                    <Button
                      onClick={() => this.onSelect(f)}
                      variant="outlined"
                      style={{
                          backgroundColor, color, width: 200, height: 100,
                        }}
                    >
                      {f.nome}
                    </Button>
                  </Grid>

                )
              })}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button disabled={disabled} color="primary" variant="contained" onClick={this.onSave}>
                        Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
}

export default CreateFormaPagamentoModal
