import React from 'react'
import {
  Grid, Dialog, DialogContent, Button, IconButton, Icon, Avatar, Chip, Checkbox, FormControlLabel
} from '@material-ui/core'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { TextFieldTelefone } from './../../../../_atoms/numberFormat'
import { TextField, ReactTable } from './../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import TextFieldCPFCNPJ from '../../../../_atoms/numberFormat/TextFieldCPFCNPJ'

class ClienteSearchDialog extends React.PureComponent {
  state = {
    clientes: [],
    paginacao: {},
    filter: {}
  };

  componentDidMount () {
    this.loadClientes()
  }

  loadClientes = () => {
    this.setState({ loading: true })
    const { filter, offset, pageSize } = this.state
    const { nome, telefone, cpfCnpj } = filter
    axios
      .get('api/pessoa', {
        params: {
          nome, telefone, contexto: '1', offset, pageSize, cpfCnpj
        }
      })
      .then(response => {
        const lista = response.lista.map(c => {
          let telefones = []; let enderecos = []
          if (c.telefones) {
            telefones = c.telefones.map((t, i) => {
              return { ...t, selected: i == 0 }
            })
          }

          if (c.enderecos) {
            enderecos = c.enderecos.map((e, i) => {
              return { ...e, selected: i == 0 }
            })
          }
          return { ...c, telefones, enderecos }
        })
        this.setState({ clientes: lista, loading: false, paginacao: response.paginacao })
      })
  };

  fetchData = (state, instance) => {
    this.setState({ loading: true, offset: state.page, pageSize: state.pageSize }, this.loadClientes)
  };

  handleChangeFilter = e => {
    this.setState({ filter: { ...this.state.filter, [e.target.name]: e.target.value } })
  };

  handleChangeIdx = (idx, prop, idCliente) => {
    const clientes = this.state.clientes.map(c => {
      if (idCliente == c.id) {
        const propMap = c[prop].map((t, i) => {
          return { ...t, selected: idx == i }
        })

        return { ...c, [prop]: propMap }
      }
      return c
    })
    this.setState({ clientes })
  }

  render () {
    const { clientes, loading, filter, paginacao } = this.state
    const { onHide, onSelect } = this.props
    return (
      <>
        <Dialog open maxWidth="lg" fullWidth>
          <Title title="Busca de Clientes" buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />

          <Grid container fullWidth style={{ margin: '0 20px' }} spacing={1} justify="space-between" alignItems="center">
            <Grid item xs={3}>
              <TextField value={filter.nome} name="nome" label="Nome do Cliente" onChange={this.handleChangeFilter} />
            </Grid>
            <Grid item xs={3}>
              <TextFieldCPFCNPJ
                label="CPF do Cliente"
                name="cpfCnpj"
                value={filter?.cpfCnpj}
                onChange={this.handleChangeFilter}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldTelefone value={filter.telefone} name="telefone" label="Telefone" onChange={this.handleChangeFilter} />
            </Grid>
            <Grid item xs={3}>
              <Button variant="outlined" onClick={this.loadClientes}>
                Buscar
              </Button>
            </Grid>
          </Grid>

          <DialogContent style={{ paddingTop: 10, minHeight: 300 }}>
            <ReactTable
              loading={loading}
              onFetchData={this.fetchData}
              pages={paginacao && paginacao.qtdePaginas}
              data={clientes}
              columns={[
                {
                  maxWidth: 50,
                  accessor: 'foto',
                  Cell: props => <Avatar
                    variant="rounded" style={{ textAlign: 'center' }}
                    src={props.value}
                  />
                },
                {
                  Header: 'Cliente',
                  accessor: 'nome'
                },
                {
                  Header: 'Telefones',
                  accessor: 'telefones',
                  Cell: props => (
                    <div style={{ padding: 5, border: '1px solid #CCCCCC' }}>
                      {props.value && props.value.map((tel, idx) => (
                        <>
                          <FormControlLabel
                            control={<Checkbox checked={tel.selected} onChange={() => this.handleChangeIdx(idx, 'telefones', props.original.id)} />}
                            label={tel.numero}
                          />
                          <br />
                        </>
                      ))}
                    </div>
                  )
                },
                {
                  accessor: 'enderecos',
                  Cell: props => (
                    <div style={{ padding: 5, border: '1px solid #CCCCCC', display: 'flex', alignItems: 'center' }}>
                      {props.value && props.value.filter(end => !!end.endereco).map((end, idx) => (
                        <FormControlLabel
                          control={<Checkbox checked={end.selected} onChange={() => this.handleChangeIdx(idx, 'enderecos', props.original.id)} />}
                          label={(
                            <div>
                              {`${end.endereco}, ${end.numero} ${end.bairro}`}
                              {end.cidade && <><br />{end.cidade} {end.cep}</>}
                              {end.referencia && <><br />Ref: {end.referencia}</>}
                            </div>
                          )}
                        />
                      ))}
                    </div>
                  )
                },
                {
                  width: 50,
                  Cell: props => {
                    return (
                      <div>
                        <IconButton
                          variant="contained"
                          onClick={() => onSelect(
                            props.original,
                            props.original.enderecos && props.original.enderecos.find(x => x.selected),
                            props.original.telefones && props.original.telefones.find(x => x.selected)
                          )}
                          color="secondary"
                        >
                          <Icon>check</Icon>
                        </IconButton>
                      </div>
                    )
                  }
                }
              ]}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default withRouter(ClienteSearchDialog)
