import React, { PureComponent } from 'react'
import _ from 'lodash'
import {
  Grid
} from '@material-ui/core'

import { TextField } from '../../../../_atoms'

class TabSEO extends PureComponent {
  render () {
    const { form, handlerChangeSEO } = this.props
    const { seo } = form
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="URL"
              name="hash"
              value={seo?.hash || ''}
              onChange={handlerChangeSEO}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Meta Tag Description"
              name="description"
              value={seo?.description || ''}
              onChange={handlerChangeSEO}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Meta Tag Keywords (Separe as palavras por vírgula)"
              name="keywords"
              value={seo?.keywords || ''}
              onChange={handlerChangeSEO}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default TabSEO
