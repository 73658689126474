import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { Avatar, Icon, IconButton } from '@material-ui/core'
import CriarEditarPessoaDialog from '../../_organisms/pessoa/criarEditar/criarEditarPessoaDialog'
import LoginDialog from '../../_organisms/pessoa/criarEditar/loginDialog'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import { ReactTable } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import { AdvancedSearch } from '../../_atoms/search'
import UploadFileModal from '../../_atoms/uploadFileModal'
import UploadFileModalWithFeedback from './components/uploadFileModalWithFeedback'
import { EMTable } from '@em/lib/src'

const tiposPorContexto = {
  0: {
    tipo: 3,
    nome: 'Colaboradores'
  },
  1: {
    tipo: 2,
    nome: 'Clientes'
  },
  2: {
    tipo: 4,
    nome: 'Fornecedores'
  }
}

const tableRef = React.createRef()


const ListarPessoas = (props) => {
  const { title, contexto } = props
  const [modalEditarPessoa, setModalEditarPessoa] = useState({ open: false })
  const [modalEditarUsuario, setModalEditarUsuario] = useState({ open: false })
  const [uploadFileModal, setUploadFileModal] = useState({ open: false })
  const [filter, setFilter] = useState({ busca: '', firstLoad: true })

  const onDelete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {deleted.nome}
          </strong>
          ?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => deleteConfirmed(deleted) }
      ]
    }
    props.showMensagemConfirmacaoAction(model)
  };

  const deleteConfirmed = deleted => {
    axios.delete(`api/Pessoa/${deleted.id}`).then(() => {
      loadPessoas()
    })
  };

  const loadPessoas = (data) => tableRef.current && tableRef.current.onQueryChange(data)

  useEffect(() => {
    if (!filter.firstLoad) {
      loadPessoas({ page: 0 })
    }
  }, [filter])

  return (
    <div>
      <Title
        title={title}
        buttons={[
          {
            icon: 'cloud_upload',
            color: 'primary',
            onClick: () => setUploadFileModal({ open: true })
          },
          {
            icon: 'add',
            color: 'primary',
            onClick: () => setModalEditarPessoa({ open: true })
          }
        ]}
      />

      <AdvancedSearch
        onBuscar={(nome) => {
          setFilter({ ...filter, nome, firstLoad: false })
        }}
        labelPrincipal="Nome"
      />

      <EMTable
        tableRef={tableRef}
        data={query => {
          const { page, pageSize } = query

          return axios.get('/api/pessoa', {
            params: {
              ...filter,
              offset: page,
              limit: pageSize,
              contexto
            }
          }).then(response => {
            return {
              data: response.lista,
              page,
              totalCount: response?.paginacao?.qtdeRows || 0
            }
          })
        }}
        columns={[
          {
            width: 60,
            field: 'foto',
            render: dataRow => <Avatar
              variant="rounded" style={{ textAlign: 'center' }}
              src={global.bucket + dataRow.foto}
            />
          },
          {
            title: 'Nome',
            field: 'nome'
          },
          {
            title: 'CPF/CNPJ',
            field: 'cpfCnpj'
          },
          {
            title: 'Grupo',
            field: 'entidade.nome'
          },
          {
            title: 'Codigo de Acesso',
            field: 'codeAccess'
          },
          {
            title: 'Status',
            field: 'status'
          },
          {
            width: 200,
            render: dataRow => (
              <div>
                <IconButton onClick={() => setModalEditarUsuario({ open: true, data: dataRow })} color="primary">
                  <Icon>person</Icon>
                </IconButton>
                <IconButton
                  onClick={() => setModalEditarPessoa({ open: true, data: dataRow })}
                  color="primary"
                >
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => onDelete(dataRow)} color="secondary">
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )
          }
        ]}
      />

      {modalEditarPessoa.open && (
        <CriarEditarPessoaDialog
          contexto={contexto}
          onSave={() => {
            loadPessoas()
            setModalEditarPessoa({ open: false })
          }}
          onHide={() => setModalEditarPessoa({ open: false })}
          data={modalEditarPessoa.data}
        />
      )}

      {modalEditarUsuario.open && (
        <LoginDialog
          onSave={() => {
            loadPessoas()
            setModalEditarUsuario({ open: false })
          }}
          onHide={() => setModalEditarUsuario({ open: false })}
          data={modalEditarUsuario.data}
        />
      )}

      {uploadFileModal.open && (
        <UploadFileModalWithFeedback
          title="Importação de clientes"
          onHide={refresh => {
            setUploadFileModal({ open: false })
            refresh === true && loadPessoas()
          }}
        />
      )}
    </div>
  )
}

// class Listar extends React.Component {


//   render() {
//     const { title, contexto } = props
//     const {
//       pessoas, modalEditarPessoa, modalEditarUsuario,
//       uploadFileModal
//     } = state

//     return (

//     )
//   }
// }

ListarPessoas.propTypes = {
  history: PropTypes.object.isRequired,
  showMensagemConfirmacaoAction: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListarPessoas)
