import React, { Component } from 'react'
import _ from 'lodash'
import {
  Card, CardHeader, IconButton, Collapse, CardContent, Typography, Icon, Grid, Menu, MenuItem
} from '@material-ui/core'
import { isNullOrUndefined } from 'util'
import Title from '@em/lib/src/util/Title'
import { NumberFormatMoneyBR } from '../../../../_atoms/numberFormat'
import TabComposicaoTituloModal from './TabComposicaoTituloModal'
import TabComposicaoOpcaoModal from './TabComposicaoOpcaoModal'

class TabMontagem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      montagens: this.props.montagens,
      anchorElN1: { currentTarget: null },
      anchorElN2: { currentTarget: null },
      tituloModal: { open: false },
      opcaoModal: { open: false },
      selecteds: { idxM: null, idxO: null }
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.montagens != this.state.montagens) {
      this.setState({ montagens: nextProps.montagens })
    }
  }

  onCollapseMontagem = index => {
    const montagens = this.state.montagens.map((montagem, idx) => {
      if (index == idx) {
        return { ...montagem, open: !montagem.open }
      }
      return { ...montagem, open: false }
    })

    this.setState({ montagens, anchorElN1: { currentTarget: null } })
  };

  onOpenMenuN1 () {
    const { anchorElN1 } = this.state
    return (
      <Menu
        open
        id="titulo"
        onClose={() => this.setState({ anchorElN1: { currentTarget: null } })}
        anchorEl={anchorElN1.currentTarget}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          onClick={() => {
            this.setState({
              anchorElN1: { currentTarget: null },
              tituloModal: { open: true }
            })
          }}
        >
          Editar Composição
        </MenuItem>
        <MenuItem onClick={() => this.onDeleteMontagem()}>Remover Composição</MenuItem>
        <MenuItem
          onClick={() => {
            this.setState({
              anchorElN1: { currentTarget: null },
              opcaoModal: { open: true, multi: true }
            })
          }}
        >
          Adicionar Opção
        </MenuItem>
      </Menu>
    )
  }

  onOpenMenuN2 () {
    const { anchorElN2 } = this.state
    return (
      <Menu
        open
        id="menu"
        onClose={() => this.setState({ anchorElN2: { currentTarget: null } })}
        anchorEl={anchorElN2.currentTarget}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          onClick={() => {
            this.setState({
              anchorElN2: { currentTarget: null },
              opcaoModal: { open: true, multi: false }
            })
          }}
        >
          Editar Opção
        </MenuItem>
        <MenuItem onClick={() => this.onDeleteOpcao()}>Remover Opção</MenuItem>
      </Menu>
    )
  }

  handlerChangeMontagem = montagem => {
    const { montagens, selecteds } = this.state
    let newMontagens = _.cloneDeep(montagens)
    if (!isNullOrUndefined(selecteds.idxM)) {
      newMontagens[selecteds.idxM] = { ...montagem }
    } else {
      newMontagens = [...(newMontagens || []), { ...montagem }]
    }
    this.props.handlerChange({ target: { name: 'montagens', value: newMontagens } })
    this.setState({ tituloModal: { open: false } })
  };

  handlerChangeOpcao = (opc, selectedsItem) => {
    const { montagens, selecteds } = this.state
    const newMontagens = _.cloneDeep(montagens)
    if (!isNullOrUndefined(selecteds.idxO)) {
      newMontagens[selecteds.idxM].opcoes[selecteds.idxO] = opc
    } else {
      const opts = selectedsItem.map(o => ({ ...opc, nome: o.nome, id: o.id }))
      newMontagens[selecteds.idxM].opcoes = [...(newMontagens[selecteds.idxM].opcoes || []), ...opts]
    }

    this.props.handlerChange({ target: { name: 'montagens', value: newMontagens } })
    this.setState({ opcaoModal: { open: false } })
  };

  onDeleteMontagem = () => {
    this.setState({ anchorElN1: { currentTarget: null } }, () => {
      const { montagens, selecteds } = this.state
      const newMontagens = _.cloneDeep(montagens)
      _.pullAt(newMontagens, [selecteds.idxM])
      this.props.handlerChange({ target: { name: 'montagens', value: newMontagens } })
    })
  };

  onDeleteOpcao = () => {
    this.setState({ anchorElN2: { currentTarget: null } }, () => {
      const { montagens, selecteds } = this.state
      const newMontagens = _.cloneDeep(montagens)
      _.pullAt(newMontagens[selecteds.idxM].opcoes, selecteds.idxO)
      this.props.handlerChange({ target: { name: 'montagens', value: newMontagens } })
    })
  };

  render () {
    const {
      montagens, anchorElN1, anchorElN2, tituloModal, opcaoModal, selecteds
    } = this.state
    const { allTags } = this.props
    return (
      <div>
        <Title
          title="Grupos de Composição"
          buttons={[
            {
              icon: 'add',
              color: 'primary',
              onClick: () => this.setState({ tituloModal: { open: true }, selecteds: {} })
            }
          ]}
        />

        <Grid container spacing={1}>
          {montagens &&
            montagens.map((montagem, idxM) => (
              <Grid item xs={12} key={idxM}>
                <Card>
                  <CardHeader
                    style={{ alignItems: 'center', padding: 5 }}
                    avatar={<Icon onClick={() => this.onCollapseMontagem(idxM)}>list</Icon>}
                    action={(
                      <IconButton
                        onClick={e => this.setState({
                          anchorElN1: { currentTarget: e.currentTarget },
                          selecteds: { idxM }
                        })}
                      >
                        <Icon>more_vert</Icon>
                      </IconButton>
                    )}
                    title={<div onClick={() => this.onCollapseMontagem(idxM)}>{montagem.titulo}</div>}
                    subheader={(
                      <div onClick={() => this.onCollapseMontagem(idxM)}>
                        {montagem.maximo > 0 && `Escolha até ${montagem.maximo} opções`}
                        {montagem.minimo > 0 && ` (${montagem.minimo} opções são obrigatórias)`}
                      </div>
                    )}
                  />
                  <Collapse in={montagem.open} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Grid container spacing={1}>
                        {montagem.opcoes &&
                            montagem.opcoes.map((opcao, idxO) => (
                              <Grid item key={`${idxM}-${idxO}`}>
                                <table style={{ borderRadius: 20, border: '2px solid #ccc' }}>
                                  <tbody>
                                    <tr>
                                      <td style={{ paddingLeft: 10 }}>
                                        <Typography variant="caption">{opcao.nome}</Typography>
                                      </td>
                                      <td>
                                        <Typography variant="caption">
                                          <NumberFormatMoneyBR value={opcao.valor} />
                                        </Typography>
                                      </td>
                                      <td>
                                        <IconButton
                                          onClick={e => this.setState({
                                            anchorElN2: { currentTarget: e.currentTarget },
                                            selecteds: { idxM, idxO }
                                          })}
                                        >
                                          <Icon>more_vert</Icon>
                                        </IconButton>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Grid>
                            ))}
                      </Grid>
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            ))}
        </Grid>

        {anchorElN1 && anchorElN1.currentTarget && this.onOpenMenuN1()}
        {anchorElN2 && anchorElN2.currentTarget && this.onOpenMenuN2()}

        {tituloModal.open && (
          <TabComposicaoTituloModal
            handlerChange={this.handlerChangeMontagem}
            onHide={() => this.setState({ tituloModal: { open: false } })}
            data={!isNullOrUndefined(selecteds.idxM) && montagens[selecteds.idxM]}
          />
        )}

        {opcaoModal.open && (
          <TabComposicaoOpcaoModal
            multi={opcaoModal.multi}
            tags={allTags}
            handlerChange={this.handlerChangeOpcao}
            onHide={() => this.setState({ opcaoModal: { open: false } })}
            montagem={montagens[selecteds.idxM]}
            data={!isNullOrUndefined(selecteds.idxO) && montagens[selecteds.idxM].opcoes[selecteds.idxO]}
          />
        )}
      </div>
    )
  }
}

export default TabMontagem
