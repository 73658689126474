import React from 'react'
import NumberFormat from 'react-number-format'
import TextField from './../TextField'

export default props => (
  <NumberFormat
    customInput={TextField}
    decimalSeparator=","
    fixedDecimalScale
    decimalScale={2}
    displayType="text"
    thousandSeparator="."
    prefix="R$ "
    {...props}
    value={props.value || 0}
  />
)
