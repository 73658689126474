import React, { useState } from 'react'
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import GaleryModal from './galeryModal';

export default function ButtonAddFiles({
  onChangeInputFile,
  key,
  onAdd,
  loading
}) {
  const inputFileRef = React.createRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [galeryModal, setGaleryModal] = useState({ open: false })
  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <div
          style={{
            width: 100,
            height: 100,
            borderRadius: '50%',
            backgroundColor: '#eee',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 12,
            position: 'relative'
          }}
        >
          {loading ?
            <>
              <CircularProgress color="primary" size={100} />
              <div
                style={{
                  position: 'absolute',
                }}>
                carregando...
              </div>
            </>
            :
            <>
              <Icon>
                add
              </Icon>
              <p>Adicione uma imagem</p>
            </>
          }
        </div>
      </IconButton>
      <input
        key={key}
        type="file"
        multiple
        ref={inputFileRef}
        style={{
          display: 'none'
        }}
        onChange={onChangeInputFile}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setGaleryModal({ open: true })
            setAnchorEl(null)
          }}
        >
          <Icon>add_photo_alternate</Icon>&nbsp;Minha Galeria
        </MenuItem>
        <MenuItem
          onClick={() => {
            inputFileRef.current.click()
            setAnchorEl(null)
          }}
        >
          <Icon>computer</Icon>&nbsp;Meu Computador
        </MenuItem>
      </Menu>
      {galeryModal.open &&
        <GaleryModal
          onHide={() => setGaleryModal({ open: false })}
          onAdd={onAdd}
        />
      }
    </>
  )
}
