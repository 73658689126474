import React, { PureComponent } from 'react'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Mesa from '../../../_atoms/Mesa'
import Title from '@em/lib/src/util/Title'
import CreateMesaModal from '../../../_organisms/pdv/mesas/CreateMesaModal'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import ButtonMenu from '../../../_atoms/button/ButtonMenu'
import { SiAirtable } from "react-icons/si"

const initialForm = {
  id: null,
  numero: null,
  lugares: null
}

class Mesas extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      mesas: [],
      form: initialForm
    }
  }

  getMesas = async () => {
    const mesasResultado = await axios.get('/api/Mesa/Full')
    this.setState({
      mesas: [...mesasResultado]
    })
  }

  salvar = async () => {
    await axios.post('/api/Mesa', this.state.form)
    this.getMesas()
    this.onHideModal()
  }

  deleteConfirmed = async ({ id }) => {
    const boolResult = await axios.delete(`/api/Mesa/${id}`)
    if (boolResult) {
      this.getMesas()
    }
  }

  delete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir a mesa
          {' '}
          <strong>
            {' '}
            {deleted.numero}
          </strong>
          ?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => this.deleteConfirmed(deleted) }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  }

  handlerChange = event => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value
      }
    })
  }

  openModal = () => {
    this.setState({
      form: { ...initialForm },
      modal: true
    })
  }

  openModalEdit = mesa => {
    this.setState({
      form: { ...mesa },
      modal: true
    })
  }

  onHideModal = () => {
    this.setState({ modal: false })
  }

  componentDidMount() {
    this.getMesas()
  }

  render() {
    const { mesas, modal, form } = this.state
    const { id, numero, lugares } = form

    return (
      <>

        <Title
          icon={<SiAirtable style={{fontSize: 24}} />}
          title="Cadastro de Mesas"
          buttons={[
            {
              icon: 'add',
              onClick: this.openModal
            }
          ]}
        />

        <Grid container spacing={1}>
          <Grid item xl={12} />
        </Grid>

        <Grid container spacing={1}>
          {mesas && mesas.map((mesa, idx) => (
            <Grid item key={idx}>
              <ButtonMenu
                items={[
                  {
                    text: 'Editar',
                    onClick: () => this.openModalEdit(mesa)
                  },
                  {
                    text: 'Deletar',
                    onClick: () => this.delete(mesa)
                  }
                ]}
                component={(
                  <Mesa
                    numero={mesa.numero}
                    lugares={mesa.lugares}
                  />
                )}
              />

            </Grid>
          ))}

        </Grid>

        {modal &&
          <CreateMesaModal
            id={id}
            numero={numero}
            lugares={lugares}
            onHide={this.onHideModal}
            handlerChange={this.handlerChange}
            salvar={this.salvar}
          />
        }
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(Mesas)
