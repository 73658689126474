import Title from '@em/lib/src/util/Title'
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { ComboBoxAPI } from '../../../../_atoms/combobox'

function AddOrEditEntregadorDialog ({
  entregador,
  onHide,
  saveEntregador
}) {
  const [entregadorSelected, setEntregadorSelected] = useState({ ...entregador })
  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Title
        title={`${entregador ? 'Editar' : 'Adicionar'} Entregador`}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ComboBoxAPI
              label="Selecione o entregador"
              onChange={e => setEntregadorSelected({
                id: e.id,
                nome: e.nome,
                tipo: 'Motocicleta',
                foto: e.foto,
                telefone: e.telefones?.map(t => t.numero)?.join()
              })}
              default={entregadorSelected}
              value={entregadorSelected?.id}
              api="api/entregador/full"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="secondary">
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => saveEntregador(entregadorSelected)}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default AddOrEditEntregadorDialog
