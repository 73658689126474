import React, { Component } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { Tooltip, CircularProgress, Icon, IconButton } from '@material-ui/core'

const ConnectIcone = ({ loading, status, isConnect, mensagem, connectURL }) => {
  let color = ''
  if (!isConnect) {
    switch (status) {
      case 1:
        color = '#6ecd6e'
        break
      case 2:
        color = '#decb0b'
        break
      case 3:
        color = '#f50057'
        break
      default:
        break
    }
  }

  const icone = (
    <div style={{ margin: '0px 0px 0px 10px' }}>
      {loading && <CircularProgress />}
      {!loading && isConnect && (
        <IconButton size="small" href={connectURL} target="__blank">
          {status ? <Icon style={{ color: '#6ecd6e' }}>signal_wifi_4_bar</Icon>
            : <Icon style={{ color: '#f50057' }}>signal_wifi_off</Icon>
          }
        </IconButton>
      )}
      {!loading && !isConnect && (
        <IconButton size="small">
          <Icon style={{ color }}>print</Icon>
        </IconButton>
      )}
    </div>
  )

  if (mensagem) {
    return (
      <Tooltip title={mensagem}>
        {icone}
      </Tooltip>
    )
  }
  return icone
}

class IndicadorImpressoras extends Component {
  state = {
    connect: {
      loading: false,
      status: null
    },
    impressoras: []
  }

  intervalTime = 300000;

  // 5min
  interval;

  createAxios = () => axios.create({ baseURL: this.props.connectURL })

  getStatusConnect = async () => {
    let status

    await this.setStateConnect({
      ...this.state.connect,
      loading: true
    })

    try {
      const result = await this.createAxios().get('api/Versao')
      status = !!result.data.versao
    } catch (error) {
      status = null
    }

    await this.setStateConnect({ status, loading: false })
  }

  setStateConnect (connect) {
    return new Promise(resolve => this.setState({ connect }, resolve))
  }

  setStateImpressora (impressora, index) {
    const { impressoras } = this.state
    return new Promise(resolve => this.setState({
      impressoras: [
        ...impressoras.slice(0, index),
        impressora,
        ...impressoras.slice(index + 1)
      ]
    }, resolve))
  }

  getStatusImpressoras = () => {
    const { impressoras, connect } = this.state
    if (!connect.status) { return }

    _.forEach(impressoras, (impressora, index) => {
      /* await */ this.getStatusImpressora(impressora, index)
    })
  }

  getStatusImpressora = async (impressora, index) => {
    let status; let
      mensagem

    await this.setStateImpressora({
      ...impressora,
      loading: true
    }, index)

    try {
      const result = await this.createAxios().post(`api/Imprimir/${impressora.ip}/${impressora.modeloId}/Teste/Comunicacao`)

      status = result?.data?.status
      mensagem = result?.data?.mensagem
    } catch (error) {
      status = 3
      mensagem = error?.message
    }

    /* await */ this.setStateImpressora({
      ...impressora,
      status,
      mensagem,
      loading: false
    }, index)
  }

  getStatusGeral = async () => {
    await this.getStatusConnect()
    this.getStatusImpressoras()
  }

  init = () => {
    const { impressoras } = this.props
    this.setState({
      impressoras: impressoras.map(i => ({
        loading: false,
        status: null,
        mensagem: null,
        ip: i.caminho,
        modeloId: i.modelo.id
      }))
    }, () => {
      this.getStatusGeral()
      this.interval = setInterval(() => {
        this.getStatusGeral()
      }, this.intervalTime)
    })
  }

  componentDidMount = () => {
    // if (this.props.connectURL !== undefined) {
    this.init()
    // }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.connectURL !== prevProps.connectURL) {
      this.init()
    }
    if (this.props.impressoras.length !== prevProps.impressoras.length) {
      this.init()
    }
  }

  render () {
    const { connect, impressoras } = this.state
    const { connectURL } = this.props

    return (
      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <ConnectIcone
          connectURL={connectURL}
          loading={connect.loading}
          status={connect.status}
          isConnect
        />
        {_.map(impressoras, (impressora, index) => (
          <ConnectIcone
            key={index}
            connectURL={connectURL}
            loading={impressora.loading}
            status={impressora.status}
            mensagem={impressora.mensagem}
            isConnect={false}
          />
        ))
        }
      </div>
    )
  }
}

export default IndicadorImpressoras
