import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactGA from 'react-ga'
import { show } from 'react-notification-system-redux'
import { axiosEMUtility } from './myAxios';
import axios from 'axios'

const InterceptorEMUtility = (props) => {
  if (axiosEMUtility) {
    axiosEMUtility?.interceptors?.request?.use(
      async request => {
        ReactGA.event({
          category: window.location.hash,
          action: request.method + ': ' + request.url.toLowerCase().replace('/api', 'api')
        })
        const response = await axios.get('/api/Connect/Print')
        request.baseURL = response.host
        return request
      },
      error => {
        ReactGA.exception({ description: JSON.stringify(error), fatal: true })
        return Promise.reject(error)
      }
    )

    axiosEMUtility?.interceptors?.response?.use(
      response => {
        if (response && response.data) {
          configurarNotificacoes(response?.data?.mensagens)
        }
        return response
      },
      (error, b) => {
        configurarNotificacoes(error?.response?.data?.mensagens)
        return Promise.reject(error)
      }
    )

    const configurarNotificacoes = mensagens => {
        mensagens?.forEach(mensagem => {
          switch (mensagem.tipo) {
            case 0:
              props.show({ message: mensagem.texto, title: 'Sucesso' }, 'success')
              break
            case 1:
              props.show({ message: mensagem.texto, title: 'Erro' }, 'error')
              break
            case 2:
              props.show({ message: mensagem.texto, title: 'Informação' }, 'info')
              break
            case 3:
              props.show({ message: mensagem.texto, title: 'Atenção' }, 'warning')
              break
            default:
              break
          }
        })
    };
  }

  return null
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    show
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(InterceptorEMUtility);