import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core'

import { TextField } from './../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxAPI } from '../../../../_atoms/combobox'

function ObservacaoDialog ({
  pedido,
  onHide,
  save
}) {
  const [order, setOrder] = useState(pedido)
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={`Editar observação do pedido ➜ ${pedido.numero}`}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ComboBoxAPI
              label="Vendedor"
              onChange={e => setOrder({ ...order, seller: { id: e?.id, name: e?.nome } })}
              default={{ ...order?.seller, nome: order?.seller?.name }}
              value={order?.seller?.id}
              api={'api/Pessoa/ByPagina?paginaId=5c635fec6da6c539a0e6d867&funcao=vendedor'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="observacao"
              label="Observação do Pedido"
              value={order?.observacao || ''}
              onChange={e => setOrder({ ...order, observacao: e.target.value })}
              multiline
              rows={3}
              maxRows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            save(order)
            onHide()
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ObservacaoDialog
