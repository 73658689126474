import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Paper, Icon, Button, IconButton, Grid,
  Avatar, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Collapse, Checkbox, ButtonGroup, Grow, ClickAwayListener, MenuList, MenuItem, Popper
} from '@material-ui/core'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { BsListCheck, BsPrinter, BsReceipt } from 'react-icons/bs'
import { MdSportsMotorsports, MdCallSplit } from 'react-icons/md'

import statusPagamentoJSON from './../../../../contstants/statusPagamento'
import PedidoPagamentoWorkFlowIfood from './pedidoPagamentoWorkFlowIfood'
import WorkflowStatusButtons from './workflowStatusButtons'
import FormasPagamentoDialog from './formasPagamentoDialog'
import ClienteDialog from './clienteDialog'
import AddOrEditEntregadorDialog from './addOrEditEntregadorDialog'
import VendedorDialog from './vendedorDialog'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import SellersCollapse from './sellersCollapse'
import DiscountDialog from './discountDialog'
import { authorizationAdmin } from './authorizationAdmin'

const DICTIONARY_TIPOS = {
  1: 'Pedidão',
  2: 'Controle',
  3: 'Praças',
  4: 'CFe'
}

const PedidoPagamento = ({
  onFinalizar, onCancelar, onImprimir, onImprimirPedidao,
  onImprimirControle, onImprimirPracas, onImprimirCfe, onSave, pedido,
  onRefreshPedido, hostIfood, formasDePagamento, todosStatusDePedidos, emUtility, sistema,
  vendedores
  // changedPedido
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [valor, setValor] = useState('')
  const [autoValor, setAutoValor] = useState(true)
  const [payDialog, setPayDialog] = useState({ open: false })
  const [discountDialog, setDiscountDialog] = useState({ open: false })
  const [clienteDialog, setClienteDialog] = useState({ open: false })
  const [entregadorDialog, setEntregadorDialog] = useState({ open: false })
  const [vendedorDialog, setVendedorDialog] = useState({ open: false, data: null })
  const [imprimirCollapse, setImprimirCollapse] = useState({ open: false, tipos: [1, 2, 3, ...(pedido.nfe ? [4] : [])] })

  useEffect(() => {
    setImprimirCollapse({ ...imprimirCollapse, open: false })
  }, [pedido.id])

  const getValorPagamento = () => {
    let valorPago = 0
    pedido.pagamentos &&
      pedido.pagamentos.forEach(item => {
        valorPago += Number(item.valor || 0)
      })
    return valorPago
  }

  const addPagamento = (pgto, discount) => {
    let pagamentos = []
    if (pedido.pagamentos) { pagamentos = [...pedido.pagamentos] }
    onRefreshPedido({
      ...pedido,
      discountAmount: discount,
      pagamentos: [...pagamentos, ...pgto]
    })
    setValor('')
    setAutoValor(true)
    setPayDialog(false)
  }

  const removePagamento = index => {
    _.pullAt(pedido.pagamentos, index)
    onRefreshPedido(pedido)
  }

  const onImprimirTipos = () => {
    if (imprimirCollapse.tipos.includes(1)) {
      onImprimirPedidao()
    }
    if (imprimirCollapse.tipos.includes(2)) {
      onImprimirControle()
    }
    if (imprimirCollapse.tipos.includes(3)) {
      onImprimirPracas()
    }
    if (pedido.cFe && imprimirCollapse.tipos.includes(4)) {
      onImprimirCfe()
    }
  }

  const valorPago = getValorPagamento()

  let valorField = ''
  if (!autoValor) {
    valorField = valor
  } else if (pedido._valorTotal > valorPago) {
    valorField = pedido._valorTotal - valorPago
  }

  const canalInterno = [0, 1, 3].includes(pedido.canal)
  const requestAuthorizationDiscount = authorizationAdmin((status) => {
    if (status === 'success') {
      setDiscountDialog({ open: true })
    }
  })

  return (
    <div>
      {requestAuthorizationDiscount.renderElement()}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ padding: 10, backgroundColor: '#EEE', border: '1px solid #BABABA' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingBottom: 10 }}>
              <div>Valor dos produtos</div>
              <div><NumberFormatMoneyBR value={(pedido._valor || 0)} /></div>
            </div>
            {pedido.valorTaxa > 0 &&
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <div>Taxa de serviço do site</div>
                  <div><NumberFormatMoneyBR value={(pedido.valorTaxa || 0) - (pedido.valorFrete || 0)} /></div>
                </div>
              </>
            }
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={8}>
                <b style={{ fontSize: 20 }}>
                  Valor total
                </b>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <b style={{ fontSize: 20 }}>
                  <NumberFormatMoneyBR value={pedido._valorTotal} />
                </b>
              </Grid>
              <Grid item xs={8}>
                <b style={{ fontSize: 20 }}>
                  Valor pago
                </b>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <b style={{ fontSize: 20 }}>
                  <NumberFormatMoneyBR value={valorPago} />
                </b>
              </Grid>
              <Grid item xs={8}>
                <b style={{ fontSize: 20 }}>
                  {valorPago >= pedido._valorTotal ? 'Troco ' : 'Falta '}
                </b>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <b style={{ fontSize: 20, color: valorPago < pedido._valorTotal && 'red' }}>
                  <NumberFormatMoneyBR value={valorPago.toFixed(2) - pedido._valorTotal?.toFixed(2)} />
                </b>
              </Grid>
            </Grid>
            {pedido.discountAmount > 0 &&
              <Grid item xs={12}>
                <Paper style={{ padding: 10, marginTop: 5, backgroundColor: 'darkseagreen' }}>
                  <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item>
                      <b>Desconto aplicado</b>
                    </Grid>
                    <Grid item style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                      <NumberFormatMoneyBR value={pedido.discountAmount} />

                      {(canalInterno) && (
                        <IconButton size="small" color="secondary" onClick={() => onRefreshPedido({ ...pedido, discountAmount: 0 })}>
                          <Icon>delete</Icon>
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            }
            {pedido.valorFrete > 0 &&
              <Grid item xs={12}>
                <Paper style={{ padding: 10, marginTop: 5, backgroundColor: 'darkkhaki' }}>
                  <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item>
                      <b>Taxa de entrega</b>
                    </Grid>
                    <Grid item style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                      <NumberFormatMoneyBR value={pedido.valorFrete} />

                      {(canalInterno) && (
                        <IconButton size="small" color="secondary" onClick={() => onRefreshPedido({ ...pedido, valorFrete: 0 })}>
                          <Icon>delete</Icon>
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            }
            {canalInterno &&
              <div style={{ textAlign: 'center' }}>
                <ButtonGroup variant="outlined" aria-label="split button" color="primary" style={{ marginTop: 10, marginBottom: 5 }}>
                  <Button onClick={() => setPayDialog({ open: true, initialValue: valorField })}>Adicionar pagamento</Button>
                  <Button
                    size="small"
                    aria-controls={anchorEl ? 'split-button-menu' : undefined}
                    aria-expanded={anchorEl ? 'true' : undefined}
                    aria-haspopup="menu"
                    onClick={e => setAnchorEl(e.currentTarget)}
                  >
                    <ExpandMore />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  transition
                  disablePortal
                  placement='bottom-end'
                  style={{ zIndex: 9 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                    >
                      <Paper style={{ minWidth: 250, zIndex: 10 }}>
                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                          <MenuList id="split-button-menu">
                            {['Adicionar Desconto'].map((option, index) => (
                              <MenuItem
                                key={option}
                                onClick={() => requestAuthorizationDiscount.open()}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>

              </div>
            }
            {pedido?.pagamentos?.length > 0 && (
              <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  {pedido.pagamentos.map((pag, idx) => (
                    <Paper key={idx} style={{ padding: 10, marginTop: 5, backgroundColor: '#CCC' }}>
                      <Grid container justify="space-between" alignItems="center" spacing={1}>
                        <Grid item>
                          <b>Pagamento: </b>{pag.nome}
                        </Grid>
                        <Grid item>
                          <span>
                            {statusPagamentoJSON[pag.status]}
                          </span>
                        </Grid>
                        <Grid item style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                          <NumberFormatMoneyBR value={pag.valor} />

                          {(canalInterno && pag.financeira !== 1) && (
                            <IconButton size="small" color="secondary" onClick={() => removePagamento(idx)}>
                              <Icon>delete</Icon>
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Grid>
              </Grid>
            )}

          </div>
        </Grid>

        <Grid item xs={12}>
          <div style={{ backgroundColor: '#EEE', border: '1px solid #BABABA' }}>

            <List style={{ padding: 0 }}>
              <SellersCollapse
                pedido={pedido}
                vendedores={vendedores}
                onRefreshPedido={onRefreshPedido}
              />
              <ListItem
                button
                onClick={() => setClienteDialog({ open: true })}
              >
                <ListItemIcon>
                  <Avatar alt={pedido?.cliente?.nome} src={global.bucket + pedido?.cliente?.foto} >
                    <Icon style={{ fontSize: 32 }}>face</Icon>
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={pedido?.cliente?.nome} secondary="Cliente" />
              </ListItem>
              <ListItem
                button
                onClick={() => setEntregadorDialog({ open: true })}
              >
                <ListItemIcon>
                  <Avatar src={global.bucket + pedido?.entregador?.foto} >
                    <MdSportsMotorsports size="32px" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={pedido?.entregador?.nome} secondary="Entregador" />
              </ListItem>
              {[0, 1, 3].includes(pedido.canal) && (
                <WorkflowStatusButtons
                  key={`${pedido.id}-${pedido.statusEntrega}-${pedido.cFe}`}
                  onRefreshPedido={onRefreshPedido}
                  pedido={pedido}
                  emUtility={emUtility}
                  sistema={sistema}
                  todosStatusDePedidos={todosStatusDePedidos}
                />
              )}
              <ListItem
                button
                disabled={imprimirCollapse.tipos.length === 0}
                onClick={() => onImprimirTipos()}
              >
                <ListItemIcon>
                  <Avatar>
                    <BsPrinter size="32px" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary="Imprimir" secondary={imprimirCollapse.tipos.map(t => DICTIONARY_TIPOS[t]).join('/')} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => setImprimirCollapse({ ...imprimirCollapse, open: !imprimirCollapse.open })}>
                    {imprimirCollapse.open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <Collapse in={imprimirCollapse.open} timeout="auto" unmountOnExit>
              <List style={{ marginLeft: 15, padding: 0 }}>
                {pedido.cFe &&
                  <ListItem
                    button
                    onClick={onImprimirCfe}
                  >
                    <ListItemIcon>
                      <Avatar>
                        <BsReceipt size="32px" />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText primary="Cupom fiscal" secondary="Impressão" />
                    <ListItemSecondaryAction>
                      <Checkbox
                        onChange={({ target: { checked } }) => setImprimirCollapse({ ...imprimirCollapse, tipos: checked ? [...imprimirCollapse.tipos, 4] : imprimirCollapse.tipos.filter(x => x !== 4) })}
                        checked={imprimirCollapse.tipos.includes(4)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                }
                <ListItem
                  button
                  onClick={onImprimirPracas}
                >
                  <ListItemIcon>
                    <Avatar>
                      <MdCallSplit size="32px" />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary="Praças" secondary="Impressão" />
                  <ListItemSecondaryAction>
                    <Checkbox
                      onChange={({ target: { checked } }) => setImprimirCollapse({ ...imprimirCollapse, tipos: checked ? [...imprimirCollapse.tipos, 3] : imprimirCollapse.tipos.filter(x => x !== 3) })}
                      checked={imprimirCollapse.tipos.includes(3)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem
                  button
                  onClick={onImprimirControle}
                >
                  <ListItemIcon>
                    <Avatar>
                      <BsListCheck size="32px" />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary="Controle" secondary="Impressão" />
                  <ListItemSecondaryAction>
                    <Checkbox
                      onChange={({ target: { checked } }) => setImprimirCollapse({ ...imprimirCollapse, tipos: checked ? [...imprimirCollapse.tipos, 2] : imprimirCollapse.tipos.filter(x => x !== 2) })}
                      checked={imprimirCollapse.tipos.includes(2)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem
                  button
                  onClick={onImprimirPedidao}
                >
                  <ListItemIcon>
                    <Avatar>
                      <AiOutlineFieldNumber size="32px" />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary="Pedidão" secondary="Impressão" />
                  <ListItemSecondaryAction>
                    <Checkbox
                      onChange={({ target: { checked } }) => setImprimirCollapse({ ...imprimirCollapse, tipos: checked ? [...imprimirCollapse.tipos, 1] : imprimirCollapse.tipos.filter(x => x !== 1) })}
                      checked={imprimirCollapse.tipos.includes(1)} />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Collapse>
          </div>

        </Grid>

        <Grid item xs={12}>
          {pedido.canal != 0 && (
            <>
              {pedido.canal == 2 &&
                (pedido.statusEntrega !== 3 && pedido.statusEntrega !== null && (
                  <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                      <PedidoPagamentoWorkFlowIfood
                        onImprimir={onImprimir}
                        onRefreshPedido={onRefreshPedido}
                        pedido={pedido}
                        hostIfood={hostIfood}
                      />
                    </Grid>
                  </Grid>
                ))}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          {((pedido.statusEntrega === 3) && (
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <Button fullWidth style={{ backgroundColor: 'red', color: 'white' }} onClick={onCancelar}>
                  Cancelar Faturamento
                </Button>
              </Grid>
            </Grid>
          )) || (
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                {[0, 1, 3].includes(pedido.canal) && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onFinalizar}
                    disabled={!pedido.itens || pedido.itens?.length <= 0 || pedido._valorTotal > valorPago || !pedido._valid}
                  >
                      Faturar
                  </Button>
                )}

                {([2].includes(pedido.canal)) && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onFinalizar}
                    disabled={![4, 5, 6].includes(pedido.statusEntrega)}
                  >
                      Faturar
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

      </Grid>

      {clienteDialog.open &&
        <ClienteDialog
          onHide={() => setClienteDialog({ open: false })}
          pedido={pedido}
          onRefreshPedido={onRefreshPedido}
        />
      }

      {discountDialog?.open &&
        <DiscountDialog
          onHide={refresh => setDiscountDialog({ open: false })}
          pedido={pedido}
          onRefreshPedido={onRefreshPedido}
        />
      }

      {entregadorDialog.open &&
        <AddOrEditEntregadorDialog
          onHide={() => setEntregadorDialog({ open: false })}
          entregador={pedido.entregador}
          saveEntregador={data => {
            setEntregadorDialog({ open: false })
            onRefreshPedido({ ...pedido, entregador: data })
          }}
        />
      }

      {payDialog.open && (
        <FormasPagamentoDialog
          addPay={addPagamento}
          fPgto={payDialog.fPgto}
          initialValue={payDialog.initialValue}
          formasDePagamento={formasDePagamento}
          pedido={pedido}
          onHide={() => setPayDialog({ ...payDialog, open: false })}
        />
      )}

      {vendedorDialog?.open &&
        <VendedorDialog
          onHide={() => setVendedorDialog({ open: false })}
          pedido={pedido}
          save={p => onRefreshPedido(p)}
        />
      }

    </div>
  )
}

export default PedidoPagamento
