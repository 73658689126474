import React, { Component } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { PERFIL_ADMIN_ID } from '../../../contstants/systemConstants'

const styles = {
  paper: {
    margin: 10,
    padding: 5
  },
  chip: {
    margin: 5
  }
}

const CheckboxPagina = props => {
  const {
    isPageChecked, onChange, nome, perfilAdmin
  } = props
  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={isPageChecked}
          tabIndex={-1}
          disabled={perfilAdmin}
          disableRipple
          onChange={onChange}
        />
      )}
      label={nome}
    />
  )
}

class PaginaCheck extends Component {
  constructor (props) {
    super(props)
    this.state = { saving: false }
  }

  componentDidMount () {

  }

    isFunctionChecked = (funcaoFromPage, funcoesFromPerfil) => {
      const { perfil } = this.props
      if (perfil.id === PERFIL_ADMIN_ID) {
        return true
      }
      return _.some(funcoesFromPerfil, f => _.toLower(f) === _.toLower(funcaoFromPage))
    }

    render () {
      const {
        pagina, perfil, onCheckPage, onCheckFunction
      } = this.props
      const perfilAdmin = perfil.id === PERFIL_ADMIN_ID

      const pageFromPerfil = _.find(perfil.paginas, p => p.id === pagina.id)
      const isPageChecked = !!pageFromPerfil || perfilAdmin

      return (
        <div>

          <ListItem
            dense
            button
            onClick={() => { }}
            style={{ width: '100%' }}
          >

            <ListItemText
              primary={(
                <CheckboxPagina
                  perfilAdmin={perfilAdmin}
                  isPageChecked={isPageChecked}
                  onChange={event => onCheckPage(pagina, perfil, event.target.checked)}
                  nome={pagina.nome}
                />
              )}
            />

            {isPageChecked && (pagina?.funcoes ?? []).map((funcao, idx) => (
              <FormControlLabel
                key={idx}
                control={(
                  <Checkbox
                    disabled={perfilAdmin}
                    checked={this.isFunctionChecked(funcao, (pageFromPerfil || {}).funcoes)}
                    onChange={event => onCheckFunction(pagina, perfil, funcao, event.target.checked)}
                  />
                )}
                label={funcao}
              />
            ))
            }

          </ListItem>

        </div>
      )
    }
}

export default withStyles(styles)(PaginaCheck)
