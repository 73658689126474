import React, { useState } from 'react'
import _ from 'lodash'
import { Typography, CardContent, Collapse, IconButton, Icon, List, ListItem, ListSubheader, ListItemText, ListItemIcon, ListItemSecondaryAction } from '@material-ui/core'
import AddOrEditModal from './addOrEditModal'

export default ({ value, onChange }) => {
  const [modal, setModal] = useState({ open: false })

  const handlerChange = (data, item) => {
    if (modal.action == 'add') {
      data.pages = [...(data.pages || []), { ...item }]
    } else {
      _.keys(item).forEach(key => {
        data[key] = item[key]
      })
    }
    onChange(value)
    setModal({ open: false })
  }

  const getItem = (page, idx, pages) => {
    return (
      <>
        <ListItem button>
          <ListItemIcon>
            <Icon>{page.icon}</Icon>
          </ListItemIcon>
          <ListItemText primary={page.nome} />
          <ListItemSecondaryAction>
            <Typography style={{ marginRight: 10 }} variant="caption">
              {page.hash}
            </Typography>
            <IconButton edge="end" aria-label="add">
              <Icon onClick={() => setModal({ open: true, dataValue: page, action: 'add' })}>add</Icon>
            </IconButton>
            <IconButton onClick={() => setModal({ open: true, dataValue: page })} edge="end" aria-label="edit">
              <Icon>edit</Icon>
            </IconButton>
            <IconButton onClick={() => {
              pages.splice(idx, 1)
              onChange(value || [])
            }} edge="end" aria-label="delete">
              <Icon>delete</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {page.pages &&
       <Collapse in={true} timeout="auto" unmountOnExit>
         <List component="div" disablePadding style={{ paddingLeft: 30 }}>
           {page.pages.map((p, idx) => getItem(p, idx, page.pages))}
         </List>
       </Collapse>
        }
      </>
    )
  }

  return (
    <div>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem button>
          <ListItemText primary="Paginas do Menu" />
          <ListItemSecondaryAction>
            <IconButton onClick={() => setModal({ open: true, dataValue: value, action: 'add' })} edge="end" aria-label="add">
              <Icon>add</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {value && value.pages && value.pages.map((p, idx) => getItem(p, idx, value.pages))}
      </List>

      {modal.open &&
        <AddOrEditModal
          action={modal.action}
          onChange={handlerChange}
          data={modal.dataValue}
          onHide={() => setModal({ open: false })}
        />
      }
    </div>
  )
}
