import React, { useEffect, useState } from 'react'
import { EMTable } from '@em/lib/src'
import Axios from 'axios'
import moment from 'moment'
import { Grid, Paper, Button, Dialog, DialogContent } from '@material-ui/core'
import { Chart, DatePicker } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import ChatRoom from './../atendimento/components/ChatRoom'

const GraficoCountReceiver = ({ list, onClick }) => {
  const getOptions = (series, categories) => ({
    chart: {
      defaultSeriesType: 'bar',
      height: "100%"
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        events: {
          click: onClick
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
    },
    title: {
      text: 'Quantidade de mensagens por cliente ' + categories.length,
    },

    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        categories,
        title: {
          text: '',
        },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Atendimento',
        },
      },
    ],
    tooltip: {
      formatter() {
        const valor = this.y
        const serieName = this.x
        return `Cliente: ${serieName}:<br/>`
          + `<b>Mensagens: ${valor}</b><br/><br/>`
          + `<br/>`
      },
    },
    series: series,
  })

  const countReceiver = _.chain(list.filter(x => x?.receiver?.name))
    .groupBy("receiver.name")
    .map((value, key) => {
      return (
        {
          name: key,
          y: value.length,
          id: value[0]?.receiver?.id
        }
      )
    })
    .value()
    .sort((a, b) => b.y - a.y)

  const options = getOptions(
    [{
      data: countReceiver,
      name: 'Cliente',
      color: '#2b908f',
    }],
    countReceiver.map(item => item.name),
  )

  return (
    <Chart options={options} />
  )
}

const GraficoCountMedia = ({ list }) => {
  const getOptions = (series, categories) => ({
    chart: {
      defaultSeriesType: 'pie',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    title: {
      text: 'Quantidade de mensagens por canal',
    },

    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        categories,
        title: {
          text: '',
        },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Atendimento',
        },
      },
    ],
    tooltip: {
      formatter() {
        const valor = this.y
        const serieName = this.point.name
        return `Canal: ${serieName}:<br/>`
          + `<b>Mensagens: ${valor}</b><br/><br/>`
          + `<br/>`
      },
    },
    series: series,
  })

  const countMedia = _.chain(list)
    .groupBy("channel.type")
    .map((value, key) => {
      return (
        {
          name: {
            1: "Telefone",
            2: "Whatsapp",
            "undefined": "Sem canal"
          }[`${key}`], y: value.length
        }
      )
    })
    .value()

  const options = getOptions(
    [{
      data: countMedia,
      name: 'Canal',
      color: '#2b908f',
    }],
    countMedia.map(item => item.name))

  return (
    <Chart options={options} />
  )
}

const GraficoCountCreated = ({ list }) => {
  const getOptions = (series, categories) => ({
    chart: {
      defaultSeriesType: 'column',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
    },
    title: {
      text: 'Quantidade de mensagens por hora',
    },

    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        categories,
        title: {
          text: '',
        },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Atendimento',
        },
      },
    ],
    tooltip: {
      formatter() {
        const valor = this.y
        const serieName = this.x
        return `Data: ${serieName}:<br/>`
          + `<b>Mensagens: ${valor}</b><br/><br/>`
          + `<br/>`
      },
    },
    series: series,
  })

  const countReceiver = _.chain(list)
    .sortBy("createAt")
    .groupBy((x) => {
      return moment(x.createAt).format("DD/MM/YYYY HH") + "h"
    })
    .map((value, key) => {
      return (
        [key, value.length]
      )
    })
    .value()

  const options = getOptions(
    [
      {
        data: countReceiver,
        name: 'Cliente',
        color: '#2b908f',
      }
    ],
    countReceiver.map(item => item[0]))

  return (
    <Chart options={options} />
  )
}

export default () => {
  const [createAtStart, setCreateAtStart] = useState(moment().format("YYYY-MM-DD"))
  const [createAtEnd, setCreateAtEnd] = useState(null)
  const [list, setList] = useState([])
  const [key, setKey] = useState(null)
  const [openChat, setOpenChat] = useState({ open: false })

  useEffect(() => {
    loadMessages()
  }, [])

  const loadMessages = () => Axios.get('api/message', {
    params: {
      offset: 0,
      limit: 1000,
      createAtStart: moment(createAtStart).format('YYYY-MM-DD'),
      createAtEnd: moment(createAtEnd).format('YYYY-MM-DD')
    }
  }).then(response => {
    setList(response.lista)
    setKey(new Date())
  })

  const onClick = ({ point }) => {
    Axios.get("api/sistema/" + point.id).then(response => {
      setOpenChat({ open: true, cardSelected: response })
    })
  }

  return (
    <div>
      <Paper style={{ margin: 10 }}>
        <Title title="Dashboard CRM" />
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <DatePicker
              label="Data de inicio"
              maxDate={createAtEnd || ''}
              value={createAtStart}
              onChange={value => setCreateAtStart(value)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Data de fim"
              minDate={createAtStart || ''}
              value={createAtEnd}
              onChange={value => setCreateAtEnd(value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={loadMessages}
            >
              Busca
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GraficoCountMedia list={list} key={key} />
            </Grid>
            <Grid item xs={12}>
              <GraficoCountCreated list={list} key={key} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <GraficoCountReceiver list={list} onClick={onClick} key={key} />
        </Grid>
      </Grid>

      <Dialog
        maxWidth="md"
        fullWidth
        open={openChat.open}
        onClose={() => setOpenChat({ open: false })}>
        <DialogContent style={{ overflowY: "hidden", padding: 0 }}>
          <ChatRoom
            // whatsSelected={whatsSelected}
            // setOpenPerson={(p) => setOpenPerson(p?.id == openPerson?.id ? null : p)}
            updateCard={() => setOpenChat({ open: false })}
            cardSelected={openChat.cardSelected}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
