import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Grid, Dialog, DialogActions, DialogContent,
} from '@material-ui/core'
import { CardImagemTitulo } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

class EscolherSistemaDialog extends Component {
  renderizarEmpresas() {
    const { sistemas, onSelect } = this.props
    return (
      <Grid container spacing={1}>
        {sistemas.map(empresa => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            key={empresa.id}
          >

            <CardImagemTitulo
              src={empresa.foto}
              text={empresa.nome}
              buttons={[{
                text: 'ENTRAR',
                onClick: () => {
                  onSelect(empresa)
                },
              }]}
            />

          </Grid>
        ))}
      </Grid>
    )
  }

  render() {
    const { onHide } = this.props

    return (
      <Dialog open maxWidth="lg" fullWidth>
        <Title
          title="SELECIONE UMA EMPRESA"
          buttons={[
            { icon: 'close', color: 'secondary', onClick: onHide },
          ]}
        />
        <DialogContent style={{ paddingTop: 10 }}>
          <div style={{ marginTop: '20px' }}>
            {
                            this.renderizarEmpresas()
                        }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onHide(null)} color="secondary">
                        CANCELAR
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EscolherSistemaDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  sistemas: PropTypes.array.isRequired,
}

export default EscolherSistemaDialog
