export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SETSISTEMAS = 'SETSISTEMAS'
// export const PAGESIZE = 'PAGESIZE'
export const SHOWMENSAGEM = 'SHOWMENSAGEM'
export const HIDEMENSAGEM = 'HIDEMENSAGEM'
export const SETTITULO = 'SETTITULO'
// export const LISTTAG = 'LISTTAG'
// export const ADDTAG = 'ADDTAG'
// export const DELETETAG = 'DELETETAG'
export const ONLINE = 'ONLINE'
