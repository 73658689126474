import { createStore } from 'redux'
import { persistStore } from 'redux-persist'
// import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import reducers from '../reducers' // where reducers is an object of reducers

function configureStore() {
  const store = createStore(reducers)
  const persistor = persistStore(store)

  return { persistor, store }
}

export default configureStore
