import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  IconButton,
  Icon,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Slide,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'


import { TextField, ReactTable } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'
import TextFieldColor from '../../../_atoms/TextFieldColor'
import UploadImageDialog from '../../../_atoms/uploadImageDialog'
import { layoutsModels, layouts } from './json'
import InputGenerator from './../../../_atoms/InputGenerator'
import TextFieldJSON from '../../../_atoms/TextFieldJSON'

const useStyles = makeStyles((theme) => ({
  card: {
    border: '1px solid #ccc',
  },
  cardHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }
}))

const AddOrEditPageModal = props => {
  const [modalAddOrEdit, setModalAddOrEdit] = useState({ open: false })
  const [form, setForm] = useState({
    ativo: true,
    style: '',
    image: '',
    name: '',
    title: '',
    subTitle: '',
    cols: '{ "xs": 12, "md": 6 }',
    grid: '',
    layout: '',
    hash: '',
    model: '',
    menu: '',
    altura: '',
    data: '',
    ...props.data
  })

  const [style, setStyle] = useState(
    props.data && props.data.style ? JSON.parse(props.data.style) : {}
  )
  const [dataValue, setDataValue] = useState(
    props.data && props.data.data ? JSON.parse(props.data.data) : {}
  )
  const [image, setImage] = useState(
    props.data && props.data.image ? JSON.parse(props.data.image) : {}
  )

  const [grid, setGrid] = useState(
    props.data && props.data.grid ? JSON.parse(props.data.grid) : {}
  )
  const [fields, setFields] = useState([])
  const [models, setModels] = useState([])
  const [imageDialog, setImageDialog] = useState({ open: false })

  const handlerChange = e => {
    if (e.target.name == 'layout' || e.target.name == 'model') {
      setDataValue({})
    }
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (form.layout && form.model) {
      const lyt = layoutsModels.find(
        x => x.layout == form.layout && x.model == form.model
      )
      setFields(lyt && lyt.fields || [])
    } else {
      setFields([])
    }
  }, [form.layout, form.model])

  useEffect(() => {
    setModels((layoutsModels.filter(x => x.layout == form.layout) || []).map(md => ({ id: md.model, nome: md.modelName })))
  }, [form.layout])

  const handlerChangeStyle = e => {
    setStyle({ ...style, [e.target.name]: e.target.value })
  }

  const handlerChangeGrid = e => {
    setGrid({ ...grid, [e.target.name]: e.target.value })
  }

  const handlerChangeData = e => {
    setDataValue({ ...dataValue, [e.target.name]: e.target.value })
  }

  const handlerChangeDataImage = (name, value) => {
    setDataValue({ ...dataValue, image: { ...dataValue.image, [name]: value } })
  }

  const handlerChangeDataOBJ = name => e => {
    setDataValue({ ...dataValue, [name]: { ...dataValue[name], [e.target.name]: e.target.value } })
  }

  const onHideSave = (page, idx) => {
    if (page) {
      if (idx == -1) {
        setDataValue({ ...dataValue, pages: [...(dataValue.pages || []), page] })
      } else {
        dataValue.pages[idx] = page
        setDataValue({ ...dataValue, pages: dataValue.pages })
      }
    }
    setModalAddOrEdit({ open: false })
  }

  const classes = useStyles()


  return (
    <div>
      <Dialog
        maxWidth={false}
        fullWidth
        open={props.open}
        onClose={() => props.onHide(false)}
      >
        <Title
          title={(form && form.id ? "Editar " : 'Criar ') + props.title}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: () => props.onHide(false)
            }
          ]}
        />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader title="Configuração Geral" className={classes.cardHeader} />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            label="Nome"
                            name="name"
                            value={form && form.name}
                            onChange={handlerChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Hash"
                            name="hash"
                            value={form && form.hash}
                            onChange={handlerChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                          <TextField
                            label="Altura da Pagina FIXA"
                            name="height"
                            value={style && style.height}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <ComboBoxData
                            label="Dispositivos"
                            onChange={e => handlerChange({ target: { name: 'device', value: e.id } })}
                            selected={form && form.device || null}
                            noneText="Todos os Dispositivos"
                            list={[
                              { id: 'mobile', nome: 'Mobile' },
                              { id: 'desktop', nome: 'Desktop' }
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rowsMax={5}
                            label="Titulo da Pagina"
                            name="title"
                            value={form && form.title}
                            onChange={handlerChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            multiline
                            rowsMax={5}
                            label="Sub Titulo da Pagina"
                            name="subTitle"
                            value={form && form.subTitle}
                            onChange={handlerChange}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <center>
                            <IconButton
                              style={{ padding: 8 }}
                              color="primary"
                              onClick={() => setImageDialog({
                                open: true,
                                handlerChange: (name, value) => {
                                  setImage({ ...image, src: value })
                                }
                              })}
                            >
                              <Avatar
                                variant="rounded"
                                alt="Logo"
                                src={`${global.bucket}${image.src}`}
                              />
                            </IconButton>
                          </center>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type="number"
                            name="blur"
                            label="Blur"
                            onChange={e => setImage({ ...image, blur: e.target.value })}
                            value={image.blur}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type="number"
                            name="strength"
                            label="Intencidade do efeito"
                            onChange={e => setImage({ ...image, strength: e.target.value })}
                            value={image.strength}
                          />
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader title="Configuração do Grid" className={classes.cardHeader} />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                          <ComboBoxData
                            label="Grid Direção"
                            onChange={e => handlerChangeGrid({ target: { name: 'direction', value: e.id } })}
                            selected={grid && grid.direction}
                            list={[
                              { id: 'row', nome: 'row' },
                              { id: 'row-reverse', nome: 'row-reverse' },
                              { id: 'column', nome: 'column' },
                              { id: 'column-reverse', nome: 'column-reverse' }
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <ComboBoxData
                            label="Grid Vertical"
                            onChange={e => handlerChangeGrid({ target: { name: 'alignItems', value: e.id } })}
                            selected={grid && grid.alignItems}
                            list={[
                              { id: 'flex-start', nome: 'flex-start' },
                              { id: 'center', nome: 'center' },
                              { id: 'flex-end', nome: 'flex-end' },
                              { id: 'stretch', nome: 'stretch' },
                              { id: 'baseline', nome: 'baseline' }
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <ComboBoxData
                            label="Grid Horizontal"
                            onChange={e => handlerChangeGrid({ target: { name: 'justify', value: e.id } })}
                            selected={grid && grid.justify}
                            list={[
                              { id: 'flex-start', nome: 'flex-start' },
                              { id: 'center', nome: 'center' },
                              { id: 'flex-end', nome: 'flex-end' },
                              { id: 'space-between', nome: 'space-between' },
                              { id: 'space-around', nome: 'space-around' },
                              { id: 'space-evenly', nome: 'space-evenly' }
                            ]}
                          />

                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <ComboBoxData
                            label="Grid spacing"
                            onChange={e => handlerChangeGrid({ target: { name: 'spacing', value: e.id } })}
                            selected={grid && grid.spacing}
                            list={[
                              { id: 0, nome: '0' },
                              { id: 1, nome: '1' },
                              { id: 2, nome: '2' },
                              { id: 3, nome: '3' },
                              { id: 4, nome: '4' },
                              { id: 5, nome: '5' },
                              { id: 6, nome: '6' },
                              { id: 7, nome: '7' },
                              { id: 8, nome: '8' },
                              { id: 9, nome: '9' },
                              { id: 10, nome: '10' },
                              { id: 11, nome: '11' },
                              { id: 12, nome: '12' }
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldJSON
                            label="Grid item Tamanho de cada Item session (JSON)"
                            name="cols"
                            placeholder={`{"xs" : 12}`}
                            value={form && form.cols}
                            onChange={handlerChange}
                            defaultJSON={{
                              xs: 12,
                              sm: 6,
                              md: 4,
                              style: {
                                padding: 0,
                                margin: 0,
                                backgroundColor: '#fff'
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldJSON
                            label="Grid container Filho JSON"
                            name="grid"
                            value={form && form.grid}
                            onChange={handlerChange}
                            defaultJSON={{
                              style: {
                                padding: 0,
                                margin: 0,
                                backgroundColor: '#fff'
                              },
                              spacing: 1,
                              justify: 'center',
                              direction: 'row',
                              alignItems: 'center'
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader title="Configuração do Container" className={classes.cardHeader} />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={2}>
                          <center>
                            <IconButton
                              style={{ padding: 8 }}
                              color="primary"
                              onClick={() => setImageDialog({
                                open: true,
                                handlerChange: (name, value) => {
                                  handlerChange({
                                    target: {
                                      name: 'imageContainer',
                                      value
                                    }
                                  })
                                }
                              })}
                            >
                              <Avatar
                                variant="rounded"
                                alt="Logo"
                                src={`${global.bucket}${form.imageContainer}`}
                              />
                            </IconButton>
                          </center>
                        </Grid>
                        <Grid item xs={10}>
                          <TextFieldJSON
                            label="Configuração JSON"
                            name="containerJSON"
                            value={form && form.containerJSON}
                            onChange={handlerChange}
                            defaultJSON={{
                              maxWidth: 'md',
                              style: {
                                backgroundColor: '#00ff00',
                                borderRadius: 20
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ComboBoxData
                            label="Estilo do Cursor"
                            onChange={e => handlerChange({ target: { name: 'cursor', value: e.id } })}
                            selected={form && form.cursor}
                            list={[
                              { id: 'flechei', nome: 'Flechei' }
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                </Grid>
                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <CardHeader title="Configuração do Estilo" className={classes.cardHeader} />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Fonte Familia"
                            name="fontFamily"
                            value={style && style.fontFamily}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextFieldColor
                            label="Cor de fundo"
                            name="backgroundColor"
                            value={style && style.backgroundColor}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextFieldColor
                            label="Cor de texto"
                            name="color"
                            value={style && style.color}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <ComboBoxData
                            label="Position"
                            onChange={e => handlerChangeStyle({ target: { name: 'position', value: e.id } })}
                            selected={style && style.position}
                            list={[
                              { id: 'relative', nome: 'Relative' },
                              { id: 'fixed', nome: 'Fixed' },
                              { id: 'absolute', nome: 'Absolute' },
                              { id: 'sticky', nome: 'Sticky' }
                            ]}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Z-Index"
                            name="zIndex"
                            value={style && style.zIndex}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Top"
                            name="top"
                            value={style && style.top}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Margin"
                            name="margin"
                            value={style && style.margin}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <TextField
                            label="Padding"
                            name="padding"
                            value={style && style.padding}
                            onChange={handlerChangeStyle}
                          />
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardHeader title="Configuração Dinâmica" className={classes.cardHeader} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <ComboBoxData
                            label="Layout"
                            onChange={e => handlerChange({
                              target: { name: 'layout', value: e.id }
                            })}
                            selected={form.layout}
                            list={layouts}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ComboBoxData
                            label="Modelo"
                            onChange={e => handlerChange({
                              target: { name: 'model', value: e.id }
                            })}
                            selected={form.model}
                            list={models}
                          />
                        </Grid>

                        {fields && fields.map(f => (
                          <Grid key={f} item xs={f.xs || 6}>
                            <InputGenerator
                              f={f}
                              handlerChangeDataImage={handlerChangeDataImage}
                              handlerChangeData={handlerChangeData}
                              handlerChangeDataOBJ={handlerChangeDataOBJ}
                              data={dataValue}
                            />
                          </Grid>
                        ))}

                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* )} */}
        </DialogContent>
        <DialogActions>

          <FormControlLabel
            control={<Checkbox
              color="primary"
              checked={form?.ativo || false}
              onChange={e => handlerChange({ target: { name: 'ativo', value: e.target.checked } })}
            />}
            label="Ativo"
            labelPlacement="left"
          />

          <Button
            color="primary"
            onClick={() => props.onHide({
              ...form,
              data: JSON.stringify(dataValue),
              style: JSON.stringify(style),
              image: JSON.stringify(image),
              grid: JSON.stringify(grid)
            })}
          >
            {props.okText}
          </Button>
        </DialogActions>
      </Dialog>

      {imageDialog.open && (
        <UploadImageDialog
          title="Selecione uma imagem"
          onHide={() => setImageDialog({ open: false })}
          tag="Site"
          src={imageDialog.src}
          onCapture={foto => {
            imageDialog.handlerChange('src', foto)
            setImageDialog({ open: false })
          }}
        />
      )}

      {modalAddOrEdit.open && (
        <AddOrEditPageModal data={modalAddOrEdit.data} onHide={p => onHideSave(p, modalAddOrEdit.idx)} />
      )}
    </div>
  )
}

export default AddOrEditPageModal
