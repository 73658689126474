import { Button, Dialog, DialogActions, DialogContent, Grid, CircularProgress, Tooltip, IconButton, Icon } from '@material-ui/core'
import Axios from 'axios'
import React, { useState } from 'react'
import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { NumberFormatMoneyBR, TextFieldCEP } from '../../../../_atoms/numberFormat'
import TextFielddMoneyBR from '../../../../_atoms/numberFormat/TextFieldMoneyBR'

function ApplyShippingDialog ({
  onHide,
  pedido,
  onRefreshPedido
}) {
  const [shippingAmount, setShippingAmount] = useState(pedido.valorFrete || 0)
  const [calcKM, setCalcKM] = useState({
    kms: '',
    amountByKms: ''
  })
  const [cep, setCep] = useState(pedido?.endereco?.cep || '')
  const [loading, setLoading] = useState(false)

  const onCalcShipping = () => {
    const newShippingAmount = (calcKM?.kms || 0) * (calcKM?.amountByKms || 0)
    setShippingAmount(Number(newShippingAmount?.toFixed(2)))
  }

  const searchCEP = () => {
    setLoading(true)
    Axios.get(`/api/ConectorEnvio/cep/${cep}`)
      .then(resp => {
        const [shipping] = resp
        const newShippingAmount = (shipping?.valor || 0)
        pedido.valorFreteRepasse = shipping?.valorFreteRepasse
        setTimeout(() => {
          setLoading(false)
          setShippingAmount(newShippingAmount)
        }, 1000)
      })
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title title="Aplicar Frete" buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <TextFieldCEP
              label="Buscar pelo CEP"
              size="small"
              onChange={e => setCep(e.target.value)}
              value={cep || ''}
              disabled={loading}
            />
            {!loading
              ? <Tooltip placement="top" title="Buscar">
                <IconButton
                  style={{ marginLeft: 5 }}
                  onClick={searchCEP}
                  disabled={loading}
                >
                  <Icon>search</Icon>
                </IconButton>
              </Tooltip>
              : <CircularProgress size={40} />}
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
            <div style={{ color: '#ccc', margin: '0px 10px' }} >OU</div>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Números de KMs"
              type="number"
              size="small"
              onChange={e => setCalcKM({ ...calcKM, kms: e.target.value })}
              value={calcKM.kms}
              disabled={loading}
            />
            <div style={{ margin: '0px 10px' }}>X</div>
            <TextFielddMoneyBR
              label="Valor por KM"
              size="small"
              onValueChange={e => setCalcKM({ ...calcKM, amountByKms: e.target.value })}
              value={calcKM.amountByKms}
              disabled={loading}
            />
            <Tooltip placement="top" title="Calcular">
              <IconButton
                style={{ marginLeft: 5 }}
                onClick={onCalcShipping}
                disabled={loading}
              >
                <Icon>exposure</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
            <div style={{ color: '#ccc', margin: '0px 10px' }} >OU</div>
            <div style={{ height: 1, width: '100%', backgroundColor: '#ccc' }} />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <TextFielddMoneyBR
              label="Alterar Valor de Frete"
              size="small"
              onValueChange={e => setShippingAmount(e.target.value)}
              value={shippingAmount}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div style={{ color: '#aaa' }}>
          Valor de frete à ser aplicado: <NumberFormatMoneyBR value={shippingAmount} />
        </div>
        <div style={{ flex: 1 }} />
        <Button onClick={onHide} color="secondary">
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            pedido.valorFrete = shippingAmount
            onRefreshPedido(pedido)
          }}
          disabled={loading}
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApplyShippingDialog
