import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { IconButton, Tooltip } from '@material-ui/core'
import { SwapVert, Delete } from '@material-ui/icons'

import Connect from '../../_organisms/colecoes/Connect'
import CreateCollectionModal from '../../_organisms/colecoes/createCollectionModal'
import { ReactTable } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'

export default props => {
  const { funcoes } = props

  const [collections, setCollections] = useState([])
  const [connect, setConnect] = useState('')
  const [addCollections, setAddCollections] = useState({ open: false })

  const loadCollections = () => {
    axios.get('api/Integracao').then(resp => {
      setCollections(resp)
    })
  }

  const onCreateCollection = colecao => {
    axios.post('api/Integracao', { colecao, dataIntegracao: moment() }).then(resp => {
      setAddCollections({ open: false })
      loadCollections()
    })
  }

  const handleDelete = original => {
    axios.delete(`api/Integracao/${original.id}`).then(resp => {
      loadCollections()
    })
  }

  useEffect(() => {
    loadCollections()
  }, [])

  return (
    <div>
      <Title
        title="Lista de Coleções"
        buttons={[
          {
            icon: 'add',
            onClick: () => setAddCollections({ open: true })
          }
        ]}
      />
      <Connect funcoes={funcoes} setConnect={con => setConnect(con)} />
      <ReactTable
        data={collections}
        columns={[
          {
            accessor: 'colecao',
            Header: 'Coleção'
          },
          {
            accessor: 'statusIntegracao',
            Header: 'Último status de integração'
          },
          {
            accessor: 'dataIntegracao',
            Header: 'Data da última integração',
            Cell: props => <div>{fnHelper.toDateTimeBR(props.original.dataIntegracao)}</div>
          },
          {
            width: 110,
            Cell: props => (
              <div>
                <Tooltip title="Iniciar Integração" placement="top">
                  <IconButton>
                    <SwapVert color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar" placement="top">
                  <IconButton onClick={() => handleDelete(props.original)}>
                    <Delete color="secondary" />
                  </IconButton>
                </Tooltip>
              </div>
            )
          }
        ]}
      />
      {addCollections.open && (
        <CreateCollectionModal onHide={() => setAddCollections({ open: false })} onCreateCollection={onCreateCollection} />
      )}
    </div>
  )
}
