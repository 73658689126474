import React from 'react'
import { IconButton, Icon } from '@material-ui/core'
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'

const showPrompt = (mensagem, showMensagemConfirmacaoAction, deleteConfirmed) => {
  const model = {
    title: 'Excluir',
    mensagem,
    buttoes: [
      { text: 'EXCLUIR', onClick: deleteConfirmed },
    ],
  }

  showMensagemConfirmacaoAction(model)
}


const IconButtonDelete = props => {
  const {
    funcoes, onClick, perguntar, mensagem, showMensagemConfirmacaoAction,
  } = props

  if (perguntar) {
    return (
      <IconButton
        disabled={!_.includes(funcoes, 'deletar')}
        onClick={() => {
          showPrompt(mensagem, showMensagemConfirmacaoAction, onClick)
        }}
        color="secondary"
      >
        <Icon>delete</Icon>
      </IconButton>
    )
  }

  return (
    <IconButton
      disabled={!_.includes(funcoes, 'deletar')}
      onClick={onClick}
      color="secondary"
    >
      <Icon>delete</Icon>
    </IconButton>
  )
}


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    showMensagemConfirmacaoAction,
  },
  dispatch,
)

export default connect(null, mapDispatchToProps)(IconButtonDelete)
