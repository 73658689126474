import React, { PureComponent, useState } from 'react'
import { connect } from 'react-redux'
import { show } from 'react-notification-system-redux'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import { Connect } from './components'
import { EMTable } from '@em/lib/src'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, Tabs, Tab, Grid } from '@material-ui/core'
import { SettingsInputAntenna, SettingsInputComponent } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const SAT_FUNCTIONS = [
  { display: "Consultar Status Operacional", router: "ConsultarStatusOperacional" },
  { display: "Consultar SAT", router: "ConsultarSAT" },
  { display: "Consultar Numero Sessao", router: "ConsultarNumeroSessao" },
  { display: "Extrair Logs", router: "ExtrairLogs" },
  { display: "Associar Assinatura", router: "AssociarAssinatura" },
  { display: "Bloquear SAT", router: "BloquearSAT" },
  { display: "Desbloquear SAT", router: "DesbloquearSAT" },
  { display: "Trocar Codigo De Ativacao", router: "TrocarCodigoDeAtivacao" },
  { display: "Ativar SAT", router: "AtivarSAT" },
  { display: "Atualizar Software SAT", router: "AtualizarSoftwareSAT" },
  { display: "Comunicar Certificado ICPBRASIL", router: "ComunicarCertificadoICPBRASIL" },
  { display: "Configurar Interface De Rede", router: "ConfigurarInterfaceDeRede" },
  { display: "Enviar Dados Venda", router: "EnviarDadosVenda" },
  { display: "Teste Fim A Fim", router: "TesteFimAFim" },
  { display: "Cancelar Ultima Venda", router: "CancelarUltimaVenda" },
]

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: "100%"
  },
}));

const SatFiscal = ({ funcoes }) => {
  const classes = useStyles();

  const [connect, setConnect] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)
  const [responseSat, setResponseSat] = useState({})

  const execute = (cmd) => {
    axios.post(`${connect.host}/api/sat/${cmd.router}`).then(response => {
      setResponseSat({
        severity: "success",
        data: JSON.stringify(response, null, 2)
      })
    }).catch(err => {
      setResponseSat({
        severity: "error",
        data: JSON.stringify(err, null, 2)
      })
    })
  }

  return (
    <>
      <Title title="Comandos Sat" />
      <Connect setConnect={setConnect} funcoes={funcoes} />

      <Grid container spacing={1}>
        <Grid item>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={(a, i) => setTabIndex(i)}
            aria-label="Vertical tabs example"
            className={classes.tabs}

          >
            {SAT_FUNCTIONS.map(fn => {
              return (
                <Tab
                  onClick={() => execute(fn)}
                  label={fn.display}
                />
              )
            })}
          </Tabs>
        </Grid>
        <Grid item>
          {responseSat?.severity &&
            <Alert
              severity={responseSat?.severity}
              style={{
                whiteSpace: "pre-wrap",
                maxWidth: "70vw",
                wordWrap: "break-word"
              }}>
              <AlertTitle>
                {`Comando executado com ${responseSat?.severity == "success" ? "Sucesso" : "Erro"}`}
              </AlertTitle>
              <pre>
                {responseSat?.data}
              </pre>
            </Alert>
          }
        </Grid>
      </Grid>
    </>
  )

}

const mapDisptchToProps = {
  show,
}

export default connect(
  null,
  mapDisptchToProps,


)(SatFiscal)
