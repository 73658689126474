import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'
import { TextFieldCEP, TextFieldNumber } from '../../../_atoms/numberFormat'
import Axios from 'axios'
import ufs from '../../../contstants/ufs'

const styles = () => ({})

class TabEnderecoCriarEditarDialog extends Component {
  state = {
    form: { tipo: 'phone_android', confianca: 10, ...this.props.data },
    showError: false
  }

  handlerChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
        rua: e.target.name === 'endereco' ? e.target.value : this.state.form?.rua
      }
    })
  };

  handlerChangeCEP = e => {
    this.setState({
      form: {
        ...this.state.endereco,
        cep: e.target.value,
        rua: '',
        bairro: '',
        cidade: '',
        complemento: ''
      }
    }, () => {
      if (this.state.form?.cep?.length == 9) {
        this.onSearchCEP()
      }
    })
  };

  onSearchCEP = () => {
    Axios.get('/api/Localidade/' + this.state.form.cep).then(response => {
      this.setState({
        form: {
          ...this.state.form,
          ...response,
          endereco: response?.rua,
          complemento: response?.complemento,
          estado: response?.uf
        }
      })
    })
  }

  onOK = () => {
    const { form } = this.state
    const required = [
      form?.cep,
      form?.endereco,
      form?.numero,
      form?.bairro,
      form?.cidade,
      form?.estado
    ]
    let error = false

    required.forEach(r => {
      if (!r) {
        error = true
      }
    })

    if (error) {
      this.setState({ showError: true })
      return
    }

    this.props.onAdd(form)
  }

  render () {
    const { form, showError } = this.state
    const { data } = this.props
    const BUTTON_TEXT = (data && 'Editar') || 'Criar'
    return (
      <Dialog open>
        <Title title={`${(data && 'Editar') || 'Criar'} endereço`} />
        <DialogContent style={{ padding: 30 }}>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={12} md={6}>
              <TextFieldCEP
                value={form.cep}
                name="cep"
                label="CEP"
                onChange={this.handlerChangeCEP}
                error={showError && !form.cep}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={form.endereco}
                name="endereco"
                label="Rua"
                onChange={this.handlerChange}
                error={showError && !form.endereco}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={form.numero}
                name="numero"
                label="Numero"
                onChange={this.handlerChange}
                error={showError && !form.numero}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={form.bairro}
                name="bairro"
                label="Bairro"
                onChange={this.handlerChange}
                error={showError && !form.bairro}
              />

            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={form.cidade}
                name="cidade"
                label="Cidade"
                onChange={this.handlerChange}
                error={showError && !form.cidade}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ComboBoxData
                selected={form?.estado}
                list={ufs}
                name="estado"
                label="Estado"
                onChange={e => this.handlerChange({
                  target: {
                    name: 'estado',
                    value: e?.id
                  }
                })}
                nameText={'name'}
                nameValue={'id'}
                error={showError && !form.estado}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={form.complemento}
                name="complemento"
                label="Complemento"
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={form.referencia}
                name="referencia"
                label="Ponto de Referencia"
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="observacao"
                fullWidth
                label="Observação"
                multiline
                value={(form && form.observacao) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldNumber
                value={(form?.latitude) || ''}
                name="latitude"
                label="Latitude"
                onValueChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldNumber
                value={(form?.longitude) || ''}
                name="longitude"
                label="Longitude"
                onValueChange={this.handlerChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onHide} color="secondary">
            Cancelar
          </Button>
          <Button onClick={this.onOK} color="primary" variant="contained">
            {BUTTON_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TabEnderecoCriarEditarDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default withStyles(styles)(TabEnderecoCriarEditarDialog)
