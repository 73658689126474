import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Avatar, Icon, Chip } from '@material-ui/core'
import { DatePicker, TextField } from '../../../_atoms'
import { ComboBoxAPI, ComboBoxData } from '../../../_atoms/combobox'
import CustomBreadCrumbCRUD from '../../../_atoms/customBreadCrumbCRUD'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import UploadImageDialog from '../../../_atoms/uploadImageDialog'

const useStyles = makeStyles((theme) => ({
  divFotos: { padding: 7.2, border: '1px solid #e2e2e1', borderRadius: 4 }
}))

const TabPessoa = ({ form, handlerChange, forceXS }) => {
  const [tag, setTag] = useState('')
  const [trocarFotoDialog, setTrocarFotoDialog] = useState({ open: false })
  const foto = global.bucket + form.foto
  const fotoCapa = global.bucket + form.fotoCapa
  const classes = useStyles()

  let fieldsName = {
    nome: 'Nome',
    apelido: 'Apelido',
    dataNascimento: 'Data de nascimento',
    cpfCnpj: 'CPF',
    iE: 'IE'
  }

  if (form?.tipo === 'PJ') {
    fieldsName = {
      nome: 'Razão social',
      apelido: 'Nome fantasia',
      dataNascimento: 'Data de criação',
      cpfCnpj: 'CNPJ',
      iE: 'IE'
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} md={forceXS ? 6 : 3}>
          <div className={classes.divFotos}>
            <div style={{ color: 'rgb(143, 143, 143)', marginBottom: 5, fontSize: 12 }}>Logo</div>
            <Avatar
              variant="rounded"
              onClick={() => setTrocarFotoDialog({ open: true, name: 'foto' })}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: 100,
                maxHeight: 100,
                cursor: 'pointer'
              }}
              alt="Logo"
              src={foto}
            />
          </div>
        </Grid>
        <Grid item xs={6} md={forceXS ? 6 : 3}>
          <div className={classes.divFotos} >
            <div style={{ color: 'rgb(143, 143, 143)', marginBottom: 5, fontSize: 12 }}>
              Capa
            </div>
            <Avatar
              variant="rounded"
              onClick={() => setTrocarFotoDialog({ open: true, name: 'fotoCapa' })}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: 100,
                maxHeight: 100,
                cursor: 'pointer'
              }}
              alt="Capa"
              src={fotoCapa}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={forceXS ? 12 : 3}>
          <ComboBoxData
            label="Tipo de pessoa"
            onChange={e => handlerChange({ target: { name: 'tipo', value: e.id } })}
            selected={form.tipo}
            list={[{ id: 'PF', nome: 'Pessoa Fisica' }, { id: 'PJ', nome: 'Pessoa Juridica' }]}
          />
        </Grid>
        <Grid item xs={12} md={forceXS ? 12 : 3}>
          <TextField label={fieldsName.cpfCnpj} name="cpfCnpj" value={form.cpfCnpj || ''} onChange={handlerChange} />
        </Grid>
        <Grid item xs={12} md={forceXS ? 12 : 4}>
          <TextField label={fieldsName.nome} name="nome" required value={form.nome || ''} onChange={handlerChange} />
        </Grid>
        <Grid item xs={12} md={forceXS ? 12 : 4}>
          <TextField label={fieldsName.apelido} name="apelido" value={form.apelido || ''} onChange={handlerChange} />
        </Grid>

        <Grid item xs={12} md={forceXS ? 12 : 4}>
          <TextField label={fieldsName.iE}
            name="iE" value={form.iE || ''}
            onChange={handlerChange}
          />
        </Grid>
        <Grid item xs={12} md={forceXS ? 12 : 4}>
          <DatePicker
            label={fieldsName.dataNascimento}
            value={form.dataNascimento}
            onChange={value => handlerChange({ target: { name: 'dataNascimento', value } })}
          />
        </Grid>

        <Grid item xs={12} md={forceXS ? 12 : 4}>
          <ComboBoxAPI
            label="Grupo"
            onChange={e => handlerChange({ target: { name: 'entidade', value: e } })}
            default={form.entidade}
            value={form.entidade && form.entidade.id}
            api="api/entidade"
          />
        </Grid>
        <Grid item xs={12} md={forceXS ? 12 : 4}>
          <Autocomplete
            multiple
            options={[]}
            id="tags"
            defaultValue={form.tags || []}
            freeSolo
            onChange={(a, b) => handlerChange({ target: { name: 'tags', value: b } })}
            renderTags={(value, getTagProps) => value.map((option, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={option}
                {...getTagProps({ index })} />
            ))}
            renderInput={params => (
              <TextField
                {...params}
                label="Tags"
                placeholder="Nova tag"
                onChange={(e) => setTag(e.target.value)}
                value={tag}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descrição"
            name="descricao"
            onChange={handlerChange}
            value={form.descricao}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>

      {trocarFotoDialog.open && (
        <UploadImageDialog
          title="Trocar foto"
          onHide={() => setTrocarFotoDialog({ open: false })}
          onCapture={f => {
            handlerChange({ target: { name: trocarFotoDialog.name, value: f } })
            setTrocarFotoDialog({ open: false })
          }}
        />
      )}
    </>
  )
}

TabPessoa.propTypes = {
  form: PropTypes.object
}

export default TabPessoa
