import React from 'react'
import {
  Dialog, DialogContent, Grid, Button, DialogActions,
} from '@material-ui/core'
import { TextField } from './../../../_atoms'
import Title from '@em/lib/src/util/Title'
import ComboBoxData from './../../../_atoms/combobox/ComboBoxData'

const ImpressoraTitle = ({ onHide, criando }) => (
  <Title
    title={`${criando ? 'Criar' : 'Editar'} impressora`}
    buttons={[
      {
        icon: 'close',
        color: 'secondary',
        onClick: onHide,
      },
    ]}
  />
)

const Actions = ({ salvar }) => (
  <DialogActions>

    <div style={{ flex: 1 }} />

    <Button color="primary" variant="contained" onClick={salvar}>
            Salvar
    </Button>
  </DialogActions>
)


export default ({
  id, nome, caminho, modelo, listaModelo, onHide, handlerChange, salvar,
}) => (
  <>

    <Dialog maxWidth="sm" open>
      <ImpressoraTitle onHide={onHide} criando={!id} />
      <DialogContent style={{ paddingTop: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} />
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome"
              name="nome"
              required
              type="text"
              value={nome || ''}
              onChange={handlerChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Local|IP|Porta"
              name="caminho"
              required
              type="text"
              value={caminho || ''}
              onChange={handlerChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ComboBoxData
              label="Modelo"
              onChange={e => handlerChange({ target: { name: 'modelo', value: { ...e } } })}
              selected={(modelo || {}).id}
              nameValue="id"
              nameText="nome"
              list={listaModelo}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <Actions salvar={salvar} />

    </Dialog>
  </>
)
