export default [
  {
    id: 1,
    nome: 'Na Fila Do Caixa'
  },
  {
    id: 2,
    nome: 'Finalizado'
  },
  {
    id: 3,
    nome: 'Impresso'
  },
  {
    id: 4,
    nome: 'Cancelado'
  },
  {
    id: 5,
    nome: 'E-commerce Pendente'
  }
]
