import React, { useState, useEffect } from 'react'
import { Avatar, Badge, Button, Grid, IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import InfiniteScroll from 'react-infinite-scroll-component'

import moment from 'moment'
import ContactCard from './ContactCard'
import { TextField } from '../../../../_atoms'
import './contacts.css'
import { AccountCircle, MobileOff, SearchOutlined, WhatsApp } from '@material-ui/icons'
import * as fnHelper from '@em/lib/src/util/fnHelper'

const Contacts = ({ whatsSelected, setWhatsSelected, cardSelected, cards, whatsAppConfigs, filter, setFilter, setCardSelected, loadSistemas, pagination }) => {
  const [search, setSearch] = useState("")
  const hasMore = cards.length < pagination.qtdeRows

  return (
    <div className="sidebar" style={{ width: 360 }}>
      <Grid container justify="space-between" alignItems="center"
        style={{
          padding: 10,
          backgroundColor: "#00bfa5"
        }}
      >
        <Grid item>
          <Avatar src={'foto'} />
        </Grid>
        <Grid item style={{ paddingRight: 5 }}>
          {whatsAppConfigs?.map(whats => {
            let color = ""
            let percentBattery = whats?.battery?.percent
            const statusName = whats?.status?.name
            const styleWhatsSelected = {}
            const disconnected = statusName !== "CONNECTED"
            if (disconnected) {
              color = "red"
            }

            if (whats?.whatsAppId == whatsSelected?.whatsAppId) {
              styleWhatsSelected.boxShadow = "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
            }

            return (
              <Tooltip title={
                <div>
                  <h3>{whats.name}</h3>
                  <table>
                    <tr>
                      <td>
                        {moment(whats?.status?.updateAt).format("DD/MM/YYYY HH:mm")}
                      </td>
                      <td>
                        Status
                      </td>
                      <td>
                        {statusName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {moment(whats?.battery?.updateAt).format("DD/MM/YYYY HH:mm")}
                      </td>
                      <td>
                        Bateria
                      </td>
                      <td>
                        {percentBattery}%
                      </td>
                    </tr>
                  </table>
                </div>
              }>
                <IconButton onClick={() => setWhatsSelected(whats)}
                  style={{
                    position: "relative",
                    ...styleWhatsSelected
                  }}>
                  <div
                    style={{
                      fontSize: 8,
                      position: "absolute",
                      top: 0,
                      backgroundColor: "#FFFd",
                      padding: 5,
                      borderRadius: 10,
                      right: 0,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {disconnected &&
                      <MobileOff style={{ fontSize: 8, color: "red", marginRight: 2 }} />
                    }
                    {percentBattery}%
                  </div>
                  <WhatsApp style={{ color }} />
                </IconButton>
              </Tooltip>
            )
          })}
        </Grid>
      </Grid>
      <div className="">
        <div className="">
          <TextField
            onChange={e => setSearch(e.target.value)}
            onKeyPress={(press => {
              console.log({ press })
              press.key == "Enter" && setFilter(search)
            })}
            placeholder="Busque pelo nome do contato"
            label={filter ? "Busque por: " + filter : "Busque"}
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setFilter(search)}>
                    <SearchOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div id="scrollableDiv" style={{ overflowY: 'auto', height: 'calc(100vh - 210px)', backgroundColor: "gray" }} >
        <InfiniteScroll
          dataLength={cards.length} // This is important field to render the next data
          next={loadSistemas}
          scrollableTarget="scrollableDiv"
          hasMore={hasMore}
          loader={<h4>Carregando...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Vocẽ já tem todas conversas</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={() => alert('atualizado')}
          // pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
        >
          {cards && cards.map((r) => (
            <div
              key={r.id}
              id={r.id}
              onClick={() => setCardSelected(r)}
            >
              <ContactCard
                dateChecked={r.dateChecked}
                foto={r.foto}
                unread={r.unread}
                comment={r.comment}
                status={r.status}
                id={r.id}
                selected={r.id === cardSelected?.id}
                name={r.nome}
              />
            </div>
          ))}
        </InfiniteScroll>

      </div>
    </div >
  )
}

export default Contacts
