import React from 'react'
import { Avatar } from '@material-ui/core'
import ReactImageZoom from 'react-image-zoom'

export default ({ src, width, height, scale, zoomPosition }) => {
  if (!src) {
    return (
      <Avatar
        variant="rounded"
        style={{ width, height }}
      />
    )
  }

  return (
    <ReactImageZoom
      width={width}
      height={height}
      scale={scale}
      zoomPosition={zoomPosition}
      img={src}
    />
  )
}
