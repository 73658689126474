import React from 'react'
import EMTable from '@em/lib/src/EMTable'
import { Icon, IconButton } from '@material-ui/core'

export default ({ form, setForm, setModalAddOrEdit, onDelete }) => {
  return (
    <div>
      <EMTable
        title="Sessions da pagina"
        data={form.pages}
        columns={[
          {
            title: 'device',
            field: 'device',
            width: 80,
            render: (row) => (
              <div>
                <Icon>
                  {!row.device
                    ? 'devices'
                    : row.device == 'mobile'
                      ? 'smartphone'
                      : row.device == 'desktop'
                        ? 'desktop_mac'
                        : 'error'}
                </Icon>
              </div>
            )
          },
          {
            title: 'Nome',
            field: 'name'
          },
          {
            title: 'Layout',
            field: 'layout',
            render: (row) => (
              <span>{`${row.layout} (${row.model})`}</span>
            )
          },
          {
            width: 230,
            render: (row) => {
              return (
                <div>
                  <IconButton
                    color="primary"
                    disabled={row.tableData.id == 0}
                    onClick={() => {
                      form.pages.splice(
                        row.tableData.id - 1,
                        0,
                        _.cloneDeep(row)
                      )
                      form.pages.splice(row.tableData.id + 1, 1)
                      setForm({ ...form, pages: form.pages })
                    }}
                  >
                    <Icon>arrow_drop_up</Icon>
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={row.tableData.id + 1 == form.pages.length}
                    onClick={() => {
                      form.pages.splice(
                        row.tableData.id + 2,
                        0,
                        _.cloneDeep(row)
                      )
                      form.pages.splice(row.tableData.id, 1)
                      setForm({ ...form, pages: form.pages })
                    }}
                  >
                    <Icon>arrow_drop_down</Icon>
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setModalAddOrEdit({
                        open: true,
                        data: row,
                        idx: row.tableData.id
                      })
                    }
                  >
                    <Icon>edit_icon</Icon>
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      onDelete(row)
                    }}
                  >
                    <Icon>delete_icon</Icon>
                  </IconButton>
                </div>
              )
            }
          }
        ]}
        options={
          {
            paging: false,
            rowStyle: (rowData) => {
              if (!rowData.ativo) {
                return { backgroundColor: '#ff7961' }
              }
              if (rowData.tableData.id % 2 === 0) {
                return { backgroundColor: '#f1f1f1' }
              }
            }
          }
        }
      />
    </div>
  )
}
