import React, { Component } from 'react'
import {
  Grid, Button, Icon, Typography,
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import { SearchImpressoraDialog } from './../../../dispositivos/components'

class TabImpressoras extends Component {
  state = {
    modalSearchImpressoras: { open: false },
  };

  handlerSelectedImpressora = impressorasSelecionadas => {
    const { handlerChange } = this.props
    this.setState({ modalSearchImpressoras: { open: false } }, () => {
      handlerChange({ target: { name: 'impressoras', value: impressorasSelecionadas } })
    })
  };

  render() {
    const { modalSearchImpressoras } = this.state
    const { impressoras } = this.props

    return (
      <div>
        <Title
          title="Impressoras"
          buttons={[
            {
              icon: 'settings',
              color: 'primary',
              onClick: () => this.setState({ modalSearchImpressoras: { open: true } }),
            },
          ]}
        />

        <Grid container spacing={1}>
          {impressoras
            && impressoras.map((imp, idx) => (
              <Grid item key={idx}>
                <Button onClick={() => { }} variant="outlined" style={{ minWidth: 120 }}>
                  <Grid item>
                    <Icon fontSize="large">print</Icon>
                    <Typography variant="subtitle1">{imp.nome}</Typography>
                  </Grid>
                </Button>
              </Grid>
            ))}
        </Grid>

        {modalSearchImpressoras.open && (
          <SearchImpressoraDialog
            title="Selecione as impressoras do item"
            impressorasSelecionadas={impressoras || []}
            onHide={() => this.setState({ modalSearchImpressoras: { open: false } })}
            handlerSelectedImpressora={this.handlerSelectedImpressora}
          />
        )}
      </div>
    )
  }
}

export default TabImpressoras
