import React, { Component } from 'react'

import Title from '@em/lib/src/util/Title'
import AddOrEditModal from '../../_organisms/documentos/addOrEditModal'
import ListaDocs from '../../_organisms/documentos/listaDocs'

class ModelosDocumentos extends Component {
  state = {
    addOrEditModal: { open: false }
  };

  render() {
    const { addOrEditModal } = this.state
    return (
      <div>
        {!addOrEditModal.open &&

          <div>
            <Title
              title="Lista de Modelos de documentos"
              buttons={[
                {
                  icon: 'add',
                  onClick: () => this.setState({
                    addOrEditModal: { open: true }
                  })
                }
              ]}
            />

            <ListaDocs
              openModal={(data) => this.setState({ addOrEditModal: { open: true, data } })}
            />

          </div>
        }


        {addOrEditModal.open && (
          <AddOrEditModal
            onHide={() => {
              this.setState({ addOrEditModal: { open: false } })
            }}
            data={addOrEditModal.data}
          />
        )}

      </div>


    )
  }
}

export default ModelosDocumentos
