import React from 'react'
import { Grid, IconButton, Icon, Typography, List, ListItem, ListItemAvatar, Checkbox, ListItemText, ListItemSecondaryAction, Divider } from '@material-ui/core'
import { NumberFormatMoneyBR } from '../../util/InputCustom'

const ModeloChips = ({ opcoes, onAddOrLess }) => (
  <Grid container spacing={1}>
    {opcoes && opcoes.map((opc, idx) => {
      let borderColor = '#ccc'
      if (opc.minimo > (opc._qtde)) {
        borderColor = '#ef5350'
      } else if (opc.maximo < (opc._qtde)) {
        borderColor = '#ef5350'
      } else if (opc.qtde < 0) {
        borderColor = '#ef5350'
      } else if (opc.qtde > 0) {
        borderColor = '#66bb6a'
      }

      return (
        <Grid item key={idx}>
          <table style={{ borderRadius: 20, border: `2px solid ${borderColor}` }}>
            <tbody>
              <tr>
                <td style={{ position: 'relative' }}>
                  <span style={{
                    fontSize: 10, position: 'absolute', top: 5, left: 5
                  }}
                  >
                    min:
                    {opc.minimo}
                  </span>
                  <IconButton
                    disabled={(opc._qtde || 0) <= (opc.minimo || 0)}
                    onClick={() => onAddOrLess(opc, -1)}
                  >
                    <Icon>remove</Icon>
                  </IconButton>
                </td>
                <td>
                  <center>
                    <Typography variant="subtitle1">{opc.nome}</Typography>

                    <Typography variant="caption">
                      {(opc._qtde || 0)}
x
                      <NumberFormatMoneyBR value={opc.valor} />
                    </Typography>
                  </center>
                </td>
                <td style={{ position: 'relative' }}>
                  <span style={{
                    fontSize: 10, position: 'absolute', top: 5, right: 5
                  }}
                  >
                    max:
                    {opc.maximo}
                  </span>
                  <IconButton
                    disabled={(opc._qtde || 0) >= (opc.maximo || 0)}
                    onClick={() => onAddOrLess(opc, 1)}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
    })}
  </Grid>
)

const ModeloCheckbox = ({ opcoes, onAddOrLess, disabledAdd }) => (
  <List dense style={{ width: '100%', padding: 0 }}>
    {opcoes && opcoes.map((opc, idx) => {
      return (
        <ListItem button style={{ padding: 0, borderTop: '1px solid #ccc' }} key={idx}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs>
              <p style={{ margin: 0 }}>{opc.nome}</p>
              <NumberFormatMoneyBR value={opc.valor} />
            </Grid>
            <Grid item>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!!opc._qtde &&
                  <React.Fragment>
                    <IconButton
                      color="primary"
                      disabled={(opc._qtde || 0) <= (opc.minimo || 0)}
                      onClick={() => onAddOrLess(opc, -1)}
                    >
                      <Icon>remove</Icon>
                    </IconButton>
                    <div>
                      {(opc._qtde || 0)}
                    </div>
                  </React.Fragment>
                }
                <IconButton
                  color="primary"
                  disabled={disabledAdd || (opc._qtde || 0) >= (opc.maximo || 0)}
                  onClick={() => onAddOrLess(opc, 1)}
                >
                  <Icon>add</Icon>
                </IconButton>
              </div>
            </Grid>

            <Grid item>
              {(opc.incluso > 0 && opc.incluso > opc._qtde) &&
                <span style={{ color: 'red', margin: '0 10px' }}>
                  remover {(opc._qtde !== 0) &&
                    `${opc.incluso - opc._qtde} de ${opc.incluso}`}
                </span>
              }

            </Grid>
          </Grid>
        </ListItem>
      )
    })}
  </List >
)

const OptionMultiple = ({ opcoes, onAddOrLess, model, disabledAdd }) => {
  if (model === 1) {
    return <ModeloChips disabledAdd={disabledAdd} opcoes={opcoes} onAddOrLess={onAddOrLess} />
  } else {
    return <ModeloCheckbox disabledAdd={disabledAdd} opcoes={opcoes} onAddOrLess={onAddOrLess} />
  }
}

export default OptionMultiple
