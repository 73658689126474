import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import MomentUtils from '@date-io/moment'
import 'react-table-v6/react-table.css'
import './assets/css/reactTable.css'
import InterceptorApp from './interceptorApp'
import * as serviceWorker from './serviceWorker'
import configureStore from './store/configureStore'
import envConstants from './contstants/envConstants.json'
import './index.css'
import 'moment/locale/pt-br'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0
  })
}

global.bucket = envConstants[window.location.host].bucket

const { persistor, store } = configureStore()

const onBeforeLift = () => {

}

const Root = () => (
  <Sentry.ErrorBoundary fallback={'An error has occured'} showDialog>
    <Provider store={store}>
      <PersistGate
        loading={<div>Carregando...</div>}
        onBeforeLift={onBeforeLift}
        persistor={persistor}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <InterceptorApp />
        </MuiPickersUtilsProvider>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>

)

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorker.register()
