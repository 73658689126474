import React, { useState } from 'react'
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core'

import Title from '@em/lib/src/util/Title'
import QrReader from 'react-qr-reader'

import { TextField } from '../../../../_atoms'
import Axios from 'axios'

export default function CupomConsumerModal ({
  onHide
}) {
  const [readQrCode, setReadQrCode] = useState(false)
  const [data, setData] = useState({
    qrcode: '',
    codigo: ''
  })

  const readCupom = codigo => {
    Axios.put(`/api/coupon/Baixar/${codigo}`).then(() => {
      onHide(false)
    })
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={'Consumir Cupom'}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Grid container spacing={1} justify="flex-end">
          {!readQrCode &&
            <>
              <Grid item xs={12}>
                <TextField
                  label="Digite o código do cupom"
                  value={data.codigo || ''}
                  onChange={e => setData({ ...data, codigo: e.target.value })}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setReadQrCode(true)}
                >
                  Ler QRCODE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!data.codigo}
                  onClick={() => readCupom(data.codigo)}
                >
                  Consumir
                </Button>
              </Grid>
            </>
          }
          {readQrCode &&
            <>
              <QrReader
                delay={300}
                onError={error => console.log({ error })}
                onScan={data => {
                  setData({ ...data, qrcode: data })
                  if (data) readCupom(data)
                }}
                style={{ width: '100%' }}
              />
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setReadQrCode(false)}
                >
                  Digitar Código
                </Button>
              </Grid>
            </>
          }
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
