import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Chip } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'

class FormasDePagamentoLista extends PureComponent {
    onDelete = forma => {
      const model = {
        title: 'Excluir',
        mensagem: (
          <span>
                    Você realmente deseja excluir
            {' '}
            <strong>
              {' '}
              {forma.nome}
            </strong>
?
          </span>
        ),
        buttoes: [
          { text: 'EXCLUIR', onClick: () => this.deleteConfirmed(forma) }
        ]
      }
      this.props.showMensagemConfirmacaoAction(model)
    }

    deleteConfirmed = forma => {
      axios.delete(`api/FormaPagamento/${forma.id}`).then(response => {
        this.props.onReload()
      })
    }

    render () {
      const { data } = this.props
      return (
        <div>
          {data && data.map((forma, idx) => (
            <Chip key={idx} variant="outlined" label={forma.nome} onDelete={() => this.onDelete(forma)} />
          ))}

        </div>
      )
    }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(FormasDePagamentoLista)
