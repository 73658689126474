export default {
  0: 'Não Existe Transação',
  1: 'Aguardando Pagamento',
  2: 'Em Analise',
  3: 'Pago',
  4: 'Disponivel',
  5: 'Em Disputa',
  6: 'Devolvida',
  7: 'Cancelada',
  8: 'Debitado',
  9: 'Retenção Temporaria'
}
