import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { show } from 'react-notification-system-redux'
import axios from 'axios'
import { Icon, IconButton, Tooltip } from '@material-ui/core'

import { ReactTable } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import IconButtonEdit from '../../_atoms/button/IconButtonEdit'
import IconButtonDelete from '../../_atoms/button/IconButtonDelete'
import { CreateImpressoraModal, Connect } from './components'
import moment from 'moment'

const initialForm = {
  id: null,
  nome: null,
  caminho: null,
  modelo: null,
}

class Impressoras extends PureComponent {
  state = {
    data: {
      lista: [],
    },
    connect: null,
    modal: false,
    loading: false,
    offset: 0,
    limit: 10,
    form: initialForm,
    listaModelo: [],
  };

  constructor(props) {
    super(props)
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true, offset: state.page, limit: state.limit }, this.getImpressoras)
  };

  onHideModal = () => {
    this.setState({ modal: false })
  };

  getImpressoras() {
    const { offset, limit } = this.state

    this.setState({ loading: true }, () => {
      axios
        .get('/api/Impressora', {
          params: {
            offset,
            limit,
          },
        })
        .then(result => {
          this.setState({
            data: result,
            loading: false,
          })
        })
    })
  }

  onEdit = ({ original }) => {
    this.setState({
      form: { ...original },
      modal: true,
    })
  };

  onDelete = ({ original }) => {
    axios.delete(`/api/Impressora/${original.id}`).then(() => this.getImpressoras())
  };

  handlerChangeForm = event => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value,
      },
    })
  };

  getModelos = () => axios.get('api/modelo/').then(result => this.setState({ listaModelo: result }));

  salvar = () => {
    const { form } = this.state

    axios.post('/api/Impressora', form).then(() => {
      this.onHideModal()
      this.getImpressoras()
    })
  };

  openModal = () => {
    this.setState({
      form: { ...initialForm },
      modal: true,
    })
  };

  componentDidMount() {
    this.getModelos()
  }

  handlerTestOLD = original => {
    const { connect } = this.state
    axios
      .create({ baseURL: connect.host })
      .post(`api/Imprimir/${original.caminho}/${original.modelo.id}/Teste/Impressao`)
      .then(result => {
        const { data } = result
        if (data) {
          this.props.show({ message: data.message, title: data.title }, data.type)
        }
      })
      .catch(err => {
        this.props.show({ message: err.message, title: 'Erro' }, 'error')
      })
  };

  getLinesToPrint = (line, length) => {
    if (line.value.length <= length) {
      return [line]
    } else {

      const lines = line.value.match(new RegExp('.{1,' + length + '}', 'g'))
      return lines.map(l => {
        const newLine = l.trim()
        return { ...line, value: newLine }
      })
    }
  }

  handlerTest = original => {
    const { connect } = this.state
    const lines = [
      {
        value: "Sistema Emoutech",
        align:"center"
      },
      {
        value: "Data: " + moment().format("DD/MM/YYYY HH:mm"),
        align:"center"
      },
      {
        value: "MMMWWWMMM",
        align:"center"
      },
      {
        value: "iiiiiiiii",
        align:"center"
      },
      { value: " " },
      ...this.getLinesToPrint({
        value: "Impressora configurada com sucesso!"
      }, 26),
      { value: " "},
      ...this.getLinesToPrint({
        value: `Tipo: ${original.modelo.nome}`
      }, 26),
      ...this.getLinesToPrint({
        value: `IP/USB/NOME: ${original.caminho}`,
      }, 26),
    ]

    axios
      .create({ baseURL: connect.host })
      .post(`api/Printer/PrintOut/${original.caminho}/${original.modelo.id}`, lines)
      .then(result => {
        const { data } = result
        if (data) {
          this.props.show({ message: data.message, title: data.title }, data.type)
        }
      })
      .catch(err => {
        this.props.show({ message: err.message, title: 'Erro' }, 'error')
      })
  };

  setConnect = connect => this.setState({ connect });

  render() {
    const { funcoes } = this.props
    const {
      data, loading, modal, listaModelo, form,
    } = this.state
    return (
      <>
        <Title
          title="Lista de impressoras"
          buttons={[
            {
              icon: 'add',
              onClick: this.openModal,
            },
          ]}
        />

        <Connect setConnect={this.setConnect} funcoes={funcoes} />

        <ReactTable
          loading={loading}
          onFetchData={this.fetchData}
          pages={data.paginacao && data.paginacao.qtdePaginas}
          data={data.lista || []}
          columns={[
            {
              Header: 'Nome',
              accessor: 'nome',
            },
            {
              Header: 'Local|IP|Porta',
              accessor: 'caminho',
            },
            {
              Header: 'Modelo',
              accessor: 'modelo.nome',
            },
            {
              width: 165,
              Cell: props => (
                <>
                  <Tooltip title="Teste de Impressão">
                    <IconButton disabled={!(this.state.connect || {}).host} onClick={() => this.handlerTest(props.original)}>
                      <Icon>wifi</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <IconButtonEdit funcoes={funcoes} onClick={() => this.onEdit(props)} />
                  </Tooltip>
                  <Tooltip title="Deletar">
                    <IconButtonDelete
                      funcoes={funcoes}
                      onClick={() => this.onDelete(props)}
                      perguntar
                      mensagem={(
                        <span>
                          Você realmente deseja excluir a impressora
                          {' '}
                          <strong>
                            {' '}
                            {props.original.nome}
                          </strong>
?
                        </span>
                      )}
                    />
                  </Tooltip>
                </>
              ),
            },
          ]}
        />

        {modal && (
          <CreateImpressoraModal
            onHide={this.onHideModal}
            handlerChange={this.handlerChangeForm}
            listaModelo={listaModelo}
            salvar={this.salvar}
            id={form.id}
            nome={form.nome}
            caminho={form.caminho}
            modelo={form.modelo}
          />
        )}
      </>
    )
  }
}

const mapDisptchToProps = {
  show,
}

export default connect(
  null,
  mapDisptchToProps,
)(Impressoras)
