import axios from 'axios'

const axiosEMUtility = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json'
  }
})

const axiosLambda = axios.create({
  baseURL: 'https://api.emoutech.com.br/prod/',
  headers: {
    'Content-Type': 'application/json'
  }
})

export { axiosLambda, axiosEMUtility }
