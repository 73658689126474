import React, { Component } from 'react'
import axios from 'axios'
import {
  List, ListItem, ListItemText, Avatar, Dialog, DialogContent
} from '@material-ui/core'

import Title from '@em/lib/src/util/Title'

class ListaDeConferentesDialog extends Component {
  constructor (props) {
    super(props)
    this.state = {
      listaConferente: [],
      loading: true
    }
  }

  componentDidMount () {
    const paginaLancarDizimo = '5b6a505fe57143c0660ba7fb'
    axios.get(`api/Pessoa/ByPagina?paginaId=${paginaLancarDizimo}&funcao=Conferente`)
      .then(listaConferente => {
        this.setState({ listaConferente, loading: false })
      })
  }

  render () {
    const { listaConferente, loading } = this.state
    return (
      <Dialog open>
        <Title
          title="Conferentes"
          buttons={[
            { icon: 'close', color: 'secondary', onClick: this.props.onHide }
          ]}
        />
        <DialogContent style={{ paddingTop: 10 }}>
          {loading && <p>Carregando...</p>}

          <List>
            {listaConferente.map((conferente, idx) => (
              <ListItem key={idx} button onClick={() => this.props.addConferente(conferente)}>
                <Avatar
                  variant="rounded" src={conferente.foto} />
                <ListItemText
                  primary={conferente.nome}
                  secondary={conferente.entidade && conferente.entidade.nome}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    )
  }
}

export default ListaDeConferentesDialog
