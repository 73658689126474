import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Title from '@em/lib/src/util/Title'
import List from './listaSegmentos'
import AddOrEditModal from './AddOrEditModal'

export default ({ funcoes }) => {
  const [segmentos, setSegmentos] = useState([])
  const [openId, setOpenId] = useState(null)
  const [addOrEditModal, setAddOrEditModal] = useState({
    open: false,
    data: null
  })

  async function loadSegmentos () {
    axios.get('/public/api/sistemaSegmento/full').then(response => {
      setSegmentos(response)
    })
  }

  async function addOrEditSegmento (segmento) {
    axios.post('/api/SistemaSegmento', segmento).then((response) => {
      loadSegmentos()
      setAddOrEditModal({ open: false })
      setOpenId(response.dados.id)
    })
  }

  async function deleteSegmento (segmento) {
    axios.delete(`/api/SistemaSegmento/${segmento.id}`).then((response) => {
      setOpenId(segmento.paiId)
      loadSegmentos()
    })
  }

  useEffect(() => {
    loadSegmentos()
  }, [])

  return (
    <div>
      <Title
        title="Lista de segmentos"
        buttons={[
          {
            icon: 'add',
            onClick: () => setAddOrEditModal({ open: true })
          }
        ]}
      />
      <List
        segmentos={segmentos}
        openId={openId}
        openModal={data => setAddOrEditModal({ open: true, data })}
        deleteSegmento={deleteSegmento}
        funcoes={funcoes}
      />
      {addOrEditModal.open &&
        <AddOrEditModal
          onHide={() => setAddOrEditModal({ open: false })}
          data={addOrEditModal?.data ?? {}}
          addOrEditSegmento={addOrEditSegmento}
        />
      }

    </div>
  )
}
