import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core'

import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxAPI } from '../../../../_atoms/combobox'

function VendedorDialog ({
  pedido,
  onHide,
  save
}) {
  const [order, setOrder] = useState(pedido)
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={`Editar vendedor do pedido ➜ ${pedido.numero}`}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ComboBoxAPI
              label="Vendedor"
              onChange={e => setOrder({ ...order, seller: { id: e?.id, name: e?.nome } })}
              default={{ ...order?.seller, nome: order?.seller?.name }}
              value={order?.seller?.id}
              api={'api/Pessoa/ByPagina?paginaId=618035c008f5796946c176f0&funcao=vendedor'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            save(order)
            onHide()
          }}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VendedorDialog
