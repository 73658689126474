import React, { PureComponent } from 'react'
import { Chip, Grid } from '@material-ui/core'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import { EMImageZoom, EMTable } from '@em/lib/src'
import { isNullOrUndefined } from '@em/lib/src/util/fnHelper'

class DetalheModalItens extends PureComponent {
  render () {
    const { itens } = this.props
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EMTable
            data={itens}
            columns={[
              {
                cellStyle: { width: 50 },
                render: row => (
                  <EMImageZoom
                    width={50}
                    height={50}
                    scale={5}
                    zoomPosition="right"
                    src={row.foto && (global.bucket + row.foto)}
                  />
                )
              },
              {
                maxWidth: '100%',
                width: '100%',
                title: 'Código',
                render: row => {
                  const valorUnitarioSemDesconto = parseFloat((row._valorTotalItemSemDesconto / row.qtde).toFixed(2))
                  const valorItem = parseFloat(row.valor.toFixed(2))
                  const valorComponentes = parseFloat((valorUnitarioSemDesconto - valorItem).toFixed(2))
                  return (
                    <div>
                      {`${row.codigo} - ${row.nome}`}
                      <br />
                      {row.valorAux != 0 && !isNullOrUndefined(row.valorAux) &&
                        <Chip size="small" style={{ color: row.valorAux < 0 ? 'red' : 'green' }} label={
                          <NumberFormatMoneyBR value={row.valorAux * row.qtde} />
                        } />
                      }
                      {valorComponentes > 0 &&
                        <Chip size="small" style={{ color: 'blue' }} label={
                          <NumberFormatMoneyBR value={valorComponentes * row.qtde} />
                        } />
                      }
                    </div>
                  )
                }
              },
              {
                maxWidth: 150,
                title: 'Qtde',
                align: 'center',
                render: row => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {row.qtde}
                  </div>
                )
              },
              {
                title: 'Total',
                maxWidth: 150,
                render: row => {
                  const valorUnitarioSemDesconto = parseFloat((row.valorTotal / row.qtde).toFixed(2))
                  const valorItem = parseFloat(row.valor.toFixed(2))
                  const valorComponentes = parseFloat((valorUnitarioSemDesconto - valorItem).toFixed(2))

                  return (
                    <div style={{ textAlign: 'center' }}>
                      <Chip
                        size="small"
                        label={
                          <>
                            <NumberFormatMoneyBR value={row.valor + valorComponentes} /> por {row.unidadeMedida || 'un'}
                          </>
                        }
                      />
                      <br />
                      <span style={{ fontSize: 16, fontWeight: 900 }}>
                        <NumberFormatMoneyBR value={row.valorTotal} />
                      </span>
                    </div>
                  )
                }
              }
            ]}
            detailPanel={[
              (item) => {
                let qdeOpcoes = 0
                item?.montagens?.forEach(monta => {
                  qdeOpcoes += monta?.opcoes?.length
                })
                return {
                  tooltip: `${qdeOpcoes} Componentes`,
                  icon: (item?.montagens?.length > 0) ? 'chevron_right' : '0',
                  disabled: !(item?.montagens?.length > 0),
                  render: row => {
                    if (row.montagens) {
                      return (
                        <>
                          {row.montagens.map((monta, idx) => {
                            if (monta._qtde > 0) {
                              return (
                                <div
                                  key={idx}
                                  style={{
                                    padding: 20,
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <strong>{monta.titulo}</strong>
                                  <div style={{ marginLeft: 10 }}>
                                    {monta.opcoes.map((opc, idx2) => {
                                      if (opc._qtde > 0) {
                                        return (
                                          <Chip
                                            size="small"
                                            key={`${idx}-${idx2}`}
                                            variant="outlined"
                                            label={`${opc._qtde}x ${opc.nome}: R$ ${Number(opc.valor).toFixed(2)}`}
                                            style={{ borderColor: 'blue', color: 'blue' }}
                                          />
                                        )
                                      }
                                      return null
                                    })}
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </>
                      )
                    }
                    return null
                  }
                }
              }
            ]}
            options={{
              pageSize: 1000,
              paging: false,
              header: false
            }}
          />
        </Grid>
      </Grid>
    )
  }
}

export default DetalheModalItens
