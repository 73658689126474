import React, { Component } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { Avatar, Chip, IconButton, Icon } from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import { ReactTable } from '../../_atoms'
import UploadImageDialog from '../../_atoms/uploadImageDialog'
import EditDialog from '../../_atoms/uploadImageDialog/editDialog'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import envConstants from '../../contstants/envConstants.json'

const ICONS_STATUS = {
  0: <Icon>success</Icon>,
  1: <Icon>progress</Icon>,
  2: <Icon>fail</Icon>
}

class Lista extends Component {
  state = {
    offset: 0,
    pageSize: 10,
    loading: false,
    allTags: [],
    data: {
      lista: []
    },
    modalUpload: { open: false },
    editModal: { open: false },
    hasFiltered: false,
    form: {
      tags: []
    }
  };

  loadLista () {
    const { offset, pageSize, form } = this.state
    const { tipos } = this.props
    const { tags } = form

    this.setState({ loading: true }, () => {
      axios
        .get('api/arquivo', {
          params: {
            tags: tags.join(','),
            tipo: tipos,
            offset,
            pageSize
          }
        })
        .then(result => {
          this.setState({
            data: result,
            loading: false
          })
        })
    })
  }

  onCheckTag (tag, checked) {
    const { form } = this.state
    const tags = [...form.tags]
    if (checked) {
      tags.push(tag)
    } else {
      _.remove(tags, x => x === tag)
    }

    this.setState({
      hasFiltered: true,
      form: {
        ...form,
        tags
      }
    })
  }

  fetchData = (state, instance) => {
    this.setState(
      { loading: true, offset: state.page, pageSize: state.pageSize },
      this.loadLista
    )
  };

  delete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {deleted.titulo}
          </strong>
?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => this.deleteConfirmed(deleted) }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  deleteConfirmed = deleted => {
    axios.delete(`api/arquivo/${deleted.id}`).then(() => {
      this.loadLista()
    })
  };

  render () {
    const { modalUpload, editModal } = this.state
    return (
      <div>
        <Title
          title={this.props.title}
          buttons={[
            {
              icon: 'add',
              onClick: () => this.setState({
                modalUpload: { open: true }
              })
            }
          ]}
        />

        <ReactTable
          // loading={loading}
          onFetchData={this.fetchData}
          pages={this.state.data.paginacao && this.state.data.paginacao.qtdePaginas}
          data={this.state.data.lista}
          columns={[
            {
              maxWidth: 60,
              accessor: 'key',
              Cell: props => (
                <Avatar
                  variant="rounded"
                  style={{ textAlign: 'center' }}
                  src={`${envConstants[window.location.host].bucket}${props.value}`}
                />
              )
            },
            {
              Header: 'Descrição',
              Cell: row => (
                <div>
                  <strong>{row.original.titulo}</strong>
                  <br />
                  {' '}
                  <span>{row.original.descricao}</span>
                </div>
              )
            },
            {
              accessor: 'tags',
              Header: 'Tags',
              Cell: props => _.map(props.value, (tag, idx) => <Chip key={idx} label={tag} variant="outlined" />)
            },
            {
              accessor: 'size',
              Header: 'Tamanho',
              Cell: row => (`${((row.value || 0) * 1e-6).toFixed(2)} MB`)
            },
            {
              accessor: 'status',
              Header: 'Status',
              Cell: row => ICONS_STATUS[row.value]
            },
            {
              accessor: 'messageError',
              Header: 'Mensagem de erro'
            },
            {
              width: 110,
              Cell: props => (
                <div>
                  <IconButton
                    onClick={() => this.setState({ editModal: { open: true, data: props.original } })}
                    color="primary"
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      this.delete(props.original)
                    }}
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              )
            }
          ]}
        />
        {modalUpload.open && (
          <UploadImageDialog
            noShowGaleria
            title="Adicionar uma imagem"
            onHide={() => this.setState({ modalUpload: { open: false } })}
            onCapture={foto => this.setState({ modalUpload: { open: false } }, this.loadLista)}
          />
        )}

        {editModal.open && (
          <EditDialog
            data={editModal.data}
            onHide={() => this.setState({ editModal: { open: false } }, this.loadLista)}
          />
        )}
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(Lista)
