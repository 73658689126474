import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid
} from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import { ComboBoxData } from '../../../_atoms/combobox'
import canaisJSON from './../../../contstants/canais'
import InputGenerator from '../../../_atoms/InputGenerator'

const inputs = {
  2: [
    { type: 'text', name: 'username', label: 'Login - USERNAME DO IFOOD' },
    { type: 'text', name: 'password', label: 'Senha - PASSWORD DO IFOOD' }
  ]
}

const AddOrEditModal = props => {
  const [form, setForm] = useState({
    canalId: 0,
    nome: '',
    ...props.data,
    data: fnHelper.tryJsonParse(props.data && props.data.data)
  })

  const [showError, setShowError] = useState(false)

  const onSave = () => {
    if (!!form.canalId || form.nome) {
      setShowError(true)
    }

    axios
      .post('api/conectorPedido', { ...form, data: JSON.stringify(form.data) })
      .then(resp => {
        props.onHide(true)
      })
  }

  const handlerChange = e => {
    if (e.target.name == 'canalId') {
      setForm({ ...form, [e.target.name]: e.target.value, data: '' })
    } else {
      setForm({ ...form, [e.target.name]: e.target.value })
    }
  }

  const handlerChangeData = e => {
    setForm({ ...form, data: { ...form.data, [e.target.name]: e.target.value } })
  }

  const handlerChangeDataImage = (name, value) => {
  }

  const handlerChangeDataOBJ = name => e => {
  }

  let ipts = []
  if (form.canalId > 0) {
    ipts = inputs[form.canalId]
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={form && form.id ? 'Edição do Conector de Pedido' : 'Novo Conector de Pedido'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Nome"
                error={showError && !form.nome}
                name="nome"
                value={form && form.nome}
                onChange={handlerChange}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxData
                label="Nome do Conector de Pedido"
                onChange={e => handlerChange({ target: { name: 'canalId', value: e.id } })}
                selected={form.canalId}
                list={canaisJSON}
              />
            </Grid>

            {ipts && ipts.map((f, idx) => (
              <Grid key={idx} item xs={f.xs || 6}>
                <InputGenerator
                  f={f}
                  handlerChangeDataImage={handlerChangeDataImage}
                  handlerChangeData={handlerChangeData}
                  handlerChangeDataOBJ={handlerChangeDataOBJ}
                  data={form && form.data}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(AddOrEditModal)
