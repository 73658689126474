import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  IconButton,
  CardHeader,
  CircularProgress,
  Icon,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import TabEmailCriarEditarDialog from './tabEmailCriarEditarDialog'
import { ReactTable } from '../../../_atoms'

const styles = () => ({})

class TabEmail extends React.Component {
  state = {
    modal: { open: false },
  };

  deleteConfirmed = email => {
    this.props.onRemoveEmail(email.index)
  };

  add = () => {
    this.setState({ modal: { open: true } })
  };

  edit = data => {
    this.setState({ modal: { open: true, data } })
  };

  render() {
    const { emails } = this.props
    return (
      <div>
        <Card>
          <CardHeader
            avatar={<Icon>emails</Icon>}
            action={(
              <IconButton onClick={this.add}>
                <AddIcon />
              </IconButton>
            )}
            title="Lista de emails"
          />
          <CardContent style={{ padding: 0 }}>
            <ReactTable
              data={emails || []}
              columns={[
                {
                  accessor: 'confianca',
                  width: 30,
                  Cell: props => (
                    <CircularProgress
                      thickness={7}
                      size={25}
                      variant="determinate"
                      value={props.value}
                    />
                  ),
                },
                {
                  accessor: 'email',
                },
                {
                  width: 110,
                  Cell: props => (
                    <div>
                      <IconButton
                          // disabled={!this.podeEditar()}
                        onClick={() => this.edit({ ...props.original, index: props.index })}
                        color="primary"
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton
                          // disabled={!this.podeDeletar()}
                        onClick={() => {
                          this.deleteConfirmed({
                            ...props.original,
                            index: props.index,
                          })
                        }}
                        color="secondary"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  ),
                },
              ]}
            />
          </CardContent>
        </Card>

        {this.state.modal.open && (
          <TabEmailCriarEditarDialog
            data={this.state.modal.data}
            onHide={() => this.setState({ modal: { open: false } })}
            onAdd={em => {
              this.props.onAddEmail(em)
              this.setState({ modal: { open: false } })
            }}
          />
        )}
      </div>
    )
  }
}

TabEmail.prototypes = {
  showMensagemConfirmacaoAction: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction,
    },
    dispatch,
  )
}

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(TabEmail),
)
