import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import {
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  Grid,
  Chip,
  Card,
  Slide,
  Collapse,
  useMediaQuery,
  Fade,
  Avatar,
  Typography,
  Tooltip,
  CardActionArea,
  Badge,
  Button,
  Fab,
  Menu,
  MenuItem
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import Axios from 'axios'

import { TextField } from '../../../_atoms'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import { ComboBoxAPI, ComboBoxData, ComboBoxDataTree } from '../../../_atoms/combobox'
import AddOrEditModal from './CreateEdit/AddOrEditModal'

const Transition = React.forwardRef(function Transition (props, ref) {
  const isMobile = useMediaQuery('(max-width:1024px)')
  if (isMobile) {
    return <Slide direction="up" ref={ref} {...props} />
  }
  return <Fade ref={ref} {...props} />
})

const SearchItemDialog2 = ({ onHide, title, itens, onAddItem, ...props }) => {
  const [tagsFilter, setTagsFilter] = useState([])
  const [categoriesSelecteds, setCategoriesSelecteds] = useState([])
  const [applicationsSelecteds, setApplicationsSelecteds] = useState([])
  const [tags, setTags] = useState([])
  const [search, setSearch] = useState(props.search)
  const [categories, setCategories] = useState([])
  const [applications, setApplications] = useState([])
  const [openMoreFilters, setOpenMoreFilters] = useState(false)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    limit: 24,
    offset: 0
  })
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({
    nome: '',
    disponivel: '',
    ativo: null,
    tags: '',
    codigo: '',
    sort: 'Nome-asc',
    componente: 'false',
    categoriaId: '',
    stockAddressingId: ''
  })
  const [needApplyFilters, setNeedApplyFilters] = useState(false)
  const [menu, setMenu] = useState({ anchorEl: null, item: null })
  const [detailItem, setDetailItem] = useState({ open: false, data: null })
  const [searchItemsSubstitute, setSearchItemsSubstitute] = useState({ open: false, data: null })

  const isMobile = useMediaQuery('(max-width:1024px)')

  const addTag = tag => {
    setTagsFilter([tag])
  }

  const removeTag = tag => {
    setTagsFilter(tagsFilter.filter(x => x != tag))
  }

  const loadItens = (offset, limit, query) => {
    let params = { ...query }
    _.keys(filter).forEach(key => {
      if (!fnHelper.isNullOrUndefined(filter[key]) && !_.isEmpty(filter[key])) {
        params = { ...params, [key]: filter[key] }
      }
    })

    if (props?.itemPai) {
      params = { ...params, ids: props?.itemPai?.substituteItems?.join() }
    }

    Axios.get('api/item', { params: { ...params, offset, limit, search } })
      .then(response => {
        if (offset == 0) {
          setItems(response?.lista)
        } else {
          setItems(state => [...state, ...response?.lista])
        }
        setTotal(response?.paginacao?.qtdeRows)
        setPagination({ ...pagination, offset: pagination?.offset + 1 })
        setNeedApplyFilters(false)
      })
  }

  const loadAllCategorias = () => Axios.get('api/itemcategory/full').then(resp => setCategories(resp))

  const loadAllAplicacoes = () => Axios.get('api/ItemApp/full').then(resp => setApplications(resp))

  const loadAllTags = () => Axios.get('api/item/tags?componente=false').then(allTags => setTags(allTags))

  useEffect(() => {
    loadItens(pagination.offset, pagination.limit)
    loadAllCategorias()
    loadAllAplicacoes()
    loadAllTags()
  }, [])

  useEffect(() => {
    setFilter({ ...filter, categoriaId: categoriesSelecteds[categoriesSelecteds?.length - 1]?.id })
  }, [categoriesSelecteds])

  useEffect(() => {
    setFilter({ ...filter, applicationId: applicationsSelecteds[applicationsSelecteds?.length - 1]?.id })
  }, [applicationsSelecteds])

  useEffect(() => {
    setFilter({ ...filter, tags: tagsFilter?.join() })
  }, [tagsFilter])

  useEffect(() => {
    if (items?.length > 0) {
      setNeedApplyFilters(true)
    }
  }, [filter])

  const renderCard2 = (item, idx) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Card style={{ padding: 5, border: '1px solid' }}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item xs>
              <div
                style={{
                  fontSize: 16,
                  height: 42,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div className='twoLines'>{`${item?.codigo || ''}${item?.nome ? ` - ${item?.nome}` : ''}`}</div>
              </div>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={e => setMenu({ anchorEl: e.currentTarget, item })}>
                <Icon>more_vert</Icon>
              </IconButton>
            </Grid>
          </Grid>
          <CardActionArea style={{ padding: 8 }} onClick={() => onAddItem(_.cloneDeep(item))}>
            <Grid container>
              <Grid item>
                <div
                  style={{
                    width: 70,
                    height: 70,
                    border: '1px solid #eee',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Avatar
                    src={`${global.bucket}${item?.fotos && item?.fotos[0]}`}
                    alt={item?.nome}
                    variant="square"
                    style={{ maxWidth: '100%', maxHeight: '100%', backgroundColor: '#fff' }}
                  >
                    <Icon style={{ color: '#000' }}>add_a_photo</Icon>
                  </Avatar>
                </div>
              </Grid>
              <Grid item xs>
                <Grid item xs={12}>
                  <div style={{ paddingLeft: 5, height: 63 }}>
                    <Typography className='oneLine' component="div" style={{ color: '#bdbdbd', fontSize: 14 }}>{item?.provider?.name}</Typography>
                    <Typography component="p" style={{ color: '#bdbdbd', fontSize: 14 }}><b>Estoque: </b>{item?.qtdeDisponivel || 0}</Typography>
                    <Typography component="p" style={{ color: '#bdbdbd', fontSize: 14 }}><b>Substitutos: </b>{item?.substituteItems?.length || 0}</Typography>
                  </div>

                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {item?.valorDe > 0 &&
                      <Typography variant="h6" style={{ marginRight: 10, color: '#F00', textDecoration: 'line-through' }}>
                        <NumberFormatMoneyBR value={item?.valorDe} />
                      </Typography>
                    }

                    <Badge color="primary" badgeContent={item?.valorApartir > item?.valor ? 'Apartir de' : 0}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                    >
                      <Typography variant="h5" style={{ marginRight: 5 }}>
                        <NumberFormatMoneyBR value={item?.valorApartir} />
                      </Typography>
                    </Badge>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card >
      </Grid >
    )
  }

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth={false}
      open
      TransitionComponent={Transition}
    >
      <Title title={title || 'Lista de produtos'} buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
      <DialogContent style={{ padding: isMobile && 2, paddingTop: 10 }} id="scrollableDiv">
        <Grid container spacing={1}>
          <Grid item xs>
            <TextField
              value={search} name="search"
              label="Cód. | EAN | Nome"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  loadItens(0, pagination?.limit)
                }
              }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            {!isMobile &&
              <Badge badgeContent="!" color="error" invisible={!needApplyFilters}>
                <Tooltip title={needApplyFilters ? 'Os filtros precisam ser aplicados' : ''}>
                  <Button
                    onClick={() => loadItens(0, pagination?.limit)}
                    endIcon={<Icon>search</Icon>}
                    color="primary"
                    variant="contained"
                  >
                    Buscar
                  </Button>
                </Tooltip>
              </Badge>
            }
            {isMobile &&
              <Badge badgeContent="!" color="error" invisible={!needApplyFilters}>
                <Fab size="small" color="primary" onClick={() => loadItens(0, pagination?.limit)}>
                  <Icon>search</Icon>
                </Fab>
              </Badge>
            }
          </Grid>
          <Grid item>
            <IconButton onClick={() => setOpenMoreFilters(!openMoreFilters)}>
              <Icon>filter_list</Icon>
            </IconButton>
          </Grid>
        </Grid>
        <Collapse in={openMoreFilters}>
          <Grid container spacing={1} style={{ paddingTop: 10 }}>
            <Grid item xs={12} sm={8}>
              <ComboBoxAPI
                label="Fornecedor"
                onChange={obj => setFilter({ ...filter, providerId: obj?.id })}
                value={filter?.providerId}
                api="api/Pessoa/fornecedor/full"
                applyOrderBy
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ComboBoxData
                label="Ordenação"
                onChange={e => setFilter({ ...filter, sort: e?.id })}
                fullWidth={isMobile}
                selected={filter?.sort}
                list={[
                  { id: 'Codigo-asc', nome: 'Código (A-Z)' },
                  { id: 'Nome-asc', nome: 'Nome (A-Z)' },
                  { id: 'ValorApartir-asc', nome: 'Valor (0-9)' }
                ]}
              />
            </Grid>

            {categories?.length > 0 &&
              <Grid item xs={12}>
                <h3>Filtrar por Categorias</h3>
                <ComboBoxDataTree
                  handlerChangeData={e => {
                    setCategoriesSelecteds(e)
                  }}
                  listSelecteds={categoriesSelecteds}
                  listFull={categories}
                />
              </Grid>
            }

            {applications?.length > 0 &&
              <Grid item xs={12}>
                <h3>Filtrar por Aplicação</h3>
                <ComboBoxDataTree
                  handlerChangeData={e => {
                    setApplicationsSelecteds(e)
                  }}
                  listSelecteds={applicationsSelecteds}
                  listFull={applications}
                />
              </Grid>
            }

            {tags?.length > 0 &&
              <Grid item xs={12}>
                <h3>Filtrar por tags</h3>
                {tags.map((tag, idx) => {
                  const filtered = _.some(tagsFilter, x => x === tag)
                  const backgroundColor = filtered ? '#333' : 'white'
                  const color = filtered ? 'white' : '#333'
                  return (
                    <Chip
                      onClick={() => (filtered ? removeTag(tag) : addTag(tag))}
                      key={idx}
                      label={tag}
                      variant="outlined"
                      color="primary"
                      style={{ backgroundColor, color, margin: 5 }}
                    />
                  )
                })}
              </Grid>
            }
          </Grid>
        </Collapse>

        {items.length === 0 &&
          <p style={{ textAlign: 'center', marginTop: 50 }}>
            <b>Nenhum item encontrado</b>
          </p>
        }
        <Grid container spacing={1} style={{ paddingTop: 10 }}>
          {items?.map((item, idx) => renderCard2(item, idx))}
        </Grid>
        {items?.length < total &&
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: 5 }}>
            <Button
              onClick={() => loadItens(pagination.offset, pagination.limit)}
              color="primary"
              variant="contained"
            >
              Ver mais
            </Button>
          </div>
        }
        {/* </InfiniteScroll> */}
        <MenuSearchItem
          menu={menu}
          setMenu={setMenu}
          onAddItem={onAddItem}
          tags={tags}
          onHide={onHide}
        />
      </DialogContent >
    </Dialog >
  )
}

const MenuSearchItem = ({
  menu,
  setMenu,
  onAddItem,
  tags,
  onHide
}) => {
  const [detailItem, setDetailItem] = useState({ open: false, data: null })
  const [searchItemsSubstitute, setSearchItemsSubstitute] = useState({ open: false, data: null })

  return (
    <>
      <Menu
        anchorEl={menu?.anchorEl}
        open={Boolean(menu?.anchorEl)}
        onClose={() => setMenu({ anchorEl: null, item: null })}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={() => setDetailItem({ open: true, data: menu?.item })}>Detalhes</MenuItem>
        <MenuItem
          onClick={() => setSearchItemsSubstitute({ open: true, data: menu?.item })}
          disabled={!menu?.item?.substituteItems || menu?.item?.substituteItems?.length === 0}
        >
          Substitutos
        </MenuItem>
      </Menu>
      {detailItem?.open &&
        <AddOrEditModal
          onHide={() => setDetailItem({ open: false })}
          data={detailItem?.data}
          title="Item"
          disabledAll
          loadItensFull={() => null}
          allItems={[]}
        />
      }
      {searchItemsSubstitute?.open &&
        <SearchItemDialog2
          onAddItem={onAddItem}
          search={''}
          tags={tags}
          onHide={() => setSearchItemsSubstitute({ open: false, data: null })}
          itemPai={menu?.item}
          title={`Lista de Produtos ➜ Substitutos de ${menu?.item?.codigo} - ${menu?.item?.nome}`}
        />
      }
    </>
  )
}

export default SearchItemDialog2
