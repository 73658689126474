import { EMTable } from '@em/lib/src'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import { Chip, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Title from '@em/lib/src/util/Title'
import IconButtonDelete from '../../../../_atoms/button/IconButtonDelete'
import SearchItemDialog from '../searchItemDialog'

function TabSimilares ({
  loadItensFull,
  allItems,
  allTags,
  handlerChange,
  form
}) {
  const [addItems, setAddItems] = useState({ open: false })

  const onAddItem = (item) => {
    handlerChange({
      target: {
        name: 'similarItems',
        value: [...(form?.similarItems || []), item.id]
      }
    })
    setAddItems({ open: false })
  }

  const onDelete = item => {
    handlerChange({
      target: {
        name: 'similarItems',
        value: [...(form?.similarItems || [])].filter(x => x !== item.id)
      }
    })
  }
  useEffect(() => {
    loadItensFull()
  }, [])

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Title
            title="Similares"
            buttons={[
              {
                icon: 'add',
                color: 'primary',
                onClick: () => setAddItems({ open: true })
              }
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <EMTable
            title={'Similares'}
            data={allItems.filter(item => form?.similarItems?.includes(item.id))}
            columns={
              [
                {
                  title: 'Código',
                  field: 'codigo',
                  align: 'center'
                },
                {
                  title: 'Nome do item',
                  field: 'nome'
                },
                {
                  title: 'Valor',
                  field: 'valor',
                  align: 'center',
                  render: row => (
                    <div>
                      <NumberFormatMoneyBR value={row.valor} />
                    </div>
                  )
                },
                {
                  title: 'Disponivel',
                  align: 'center',
                  field: 'qtdeDisponivel'
                },
                {
                  title: 'Ativo',
                  field: 'ativo',
                  align: 'center',
                  lookup: {
                    true: <Chip size="small" variant="outlined" label='Sim' />,
                    false: <Chip size="small" variant="outlined" style={{ borderColor: 'red', color: 'red' }} label='Não' />
                  }
                },
                {
                  align: 'right',
                  width: 110,
                  render: row => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButtonDelete
                        onClick={() => {
                          onDelete(row)
                        }}
                        perguntar
                        funcoes={['deletar']}
                        mensagem={(
                          <span>
                            Você realmente deseja excluir o item
                            <strong>
                              {' '}
                              {row.nome}
                            </strong>
                      ?
                          </span>
                        )}
                      />
                    </div>
                  )
                }
              ]}
          />
        </Grid>
      </Grid>
      {addItems.open &&
        <SearchItemDialog
          onAddItem={onAddItem}
          itens={allItems.filter(item => !form?.similarItems?.includes(item.id))}
          search={''}
          tags={allTags}
          onHide={() => setAddItems({ ...addItems, open: false })}
        />
      }
    </>
  )
}

export default TabSimilares
