import React, { useRef } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Icon,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Tabs,
  Tab
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, DatePicker } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import TabsOfPerson from './tabsOfPerson'

const CriarEditarSistemaDialog = ({ segmentosFull, onHide, data }) => {
  const childRef = useRef();

  return (
    <Dialog open maxWidth={false} fullWidth>
      <Title
        title={data ? (
          <span style={{ position: 'relative' }}>{`Editar ${data.nome && data.nome}`}
            {/* <span style={{ fontSize: 12, top: -10, position: 'absolute' }}>{form.scoreFlechei || '0'}</span> */}
          </span>
        ) : 'Criar'}
        buttons={[
          {
            icon: 'close',
            color: 'secondary',
            onClick: onHide
          }
        ]}
      />

      <DialogContent style={{ minHeight: 300 }}>
        <TabsOfPerson
          ref={childRef}
          onHide={onHide}
          data={data}
          segmentosFull={segmentosFull}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" disabled={false}
          onClick={() => childRef.current.onSave()}>
          Salvar
        </Button>
      </DialogActions>

    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(CriarEditarSistemaDialog)
