import React from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardActionArea,
  CardContent,
  Grid
} from '@material-ui/core'

function ContentPagamento ({
  conectoresFinanceira,
  userReducer,
  onClick
}) {
  let lista = conectoresFinanceira?.filter(x => x?.financeira !== 2 && x?.financeira !== 3)
  if (userReducer?.sistemaAnterior?.id === '5b6384392707c66b5d232a11' ||
    userReducer?.sistema?.id === '5b6384392707c66b5d232a11'
  ) {
    lista = [
      ...lista,
      {
        nome: 'Grátis Emoutech',
        financeira: 0,
        id: '6001e4ea44d952093c4b1bfb'
      }
    ]
  }
  return (
    <Grid container spacing={1} justify={'center'}>
      {lista?.map(con => {
        let image = ''
        if (con.financeira === 1) {
          image = require('./../../../../assets/img/pdv/pagseguro.png')
        } else if (con.financeira === 0) {
          image = require('./../../../../assets/img/pdv/emoutech.png')
        }
        return (
          <Grid item xs={3} key={con.id}>
            <Card
            >
              <CardActionArea
                onClick={() => onClick(con)}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <img src={image} style={{ height: 60, maxWidth: '100%' }} />
                </div>
                <CardContent
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <b>{con.nome}</b>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

function mapStateToProps (state) {
  return {
    userReducer: state.userReducer
  }
}

export default connect(mapStateToProps, null)(ContentPagamento)
