import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

class Home extends React.Component {
  render () {
    const { usuario } = this.props
    const { sistema } = usuario

    return (
      <div style={{
        display: 'flex', alignItems: 'center', height: 500, justifyContent: 'center', flexDirection: 'column'
      }}
      >
        <h1>{`Ola ${usuario.pessoa.nome}`}</h1>
        <h3>Seja bem vindo ao sistema</h3>
        <h1>{sistema.nome}</h1>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.userReducer
})

export default connect(mapStateToProps)(Home)
