import React from 'react'
import { connect } from 'react-redux'
import { show } from 'react-notification-system-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { Dialog, DialogContent, Grid, Button } from '@material-ui/core'
import moment from 'moment'
import Title from '@em/lib/src/util/Title'
import { axiosEMUtility } from '../../../../myAxios'

const ImprimirFechamentoModal = props => {
  const { caixa, title, history, emUtility, show, paymentList, printer } = props

  const onPrint = () => {
    const lines = [
      { value: `Extrato: de Fechamento de caixa` },
      { value: `Caixa: ${caixa.numero}` },
      { value: `Abertura do caixa: ${moment(caixa.data).format("DD/MM/YYYY HH:mm")}` },
      { value: `Operador: ${caixa.operador.nome}` },
    ]

    lines.push({ value: ` ` })

    paymentList.forEach(e => {
      lines.push({
        value: `${e.nome} ${e.valor}`
      })
    });

    lines.push({
      value: `Valor Total: ${_.sumBy(paymentList, 'valor')}`
    })

    lines.push({ top: 20, value: `______________________________________________________________` })
    lines.push({ value: caixa.operador.nome })
    lines.push({ value: `Data da impressão: ${moment().format("DD/MM/YYYY HH:mm")}` })


    axiosEMUtility
      .post(`api/Printer/PrintOut/${printer.caminho}/${printer.modeloId}`, lines)
      .then(mensagens => {
        history.replace('/pdv/caixa-v2')
      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={title}
      />
      <DialogContent>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <center>
              <h2>Deseja imprimir um extrado?</h2>
            </center>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => history.replace('/pdv/caixa-v2')}
              color="secondary"
            >
              Não quero imprimir
            </Button>
          </Grid>
          {/* <Grid item xs={4}>
            <Button
              onClick={imprimirPorItem}
              color="primary"
              variant="contained"
            >
              Imprimir por item
            </Button>
          </Grid> */}
          <Grid item xs={4}>
            <Button
              onClick={onPrint}
              color="primary"
              variant="contained"
            >
              Imprimir extrato
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default connect(null, { show })(withRouter(ImprimirFechamentoModal))
