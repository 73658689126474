import { Grid } from '@material-ui/core'
import React from 'react'

import CustomBreadCrumbCRUD2 from '../../../../_atoms/customBreadCrumbCRUD2'
import { TextField } from '../../../../_atoms'

function TabEstoque ({
  form,
  handlerChange,
  allStockAddresses
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CustomBreadCrumbCRUD2
          referenceList={allStockAddresses || []}
          currentList={form.stockAddresses || []}
          label={'Endereço'}
          onChange={newList => {
            handlerChange({
              target: {
                name: 'stockAddresses',
                value: newList
              }
            })
          }}
          relationKey={'parentId'}
          labelKey={'name'}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="Qtde Mínima Estoque"
          name="qtdeMinEstoque"
          required
          type="number"
          value={form?.qtdeMinEstoque || 0}
          onChange={handlerChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="Qtde Máxima Estoque"
          name="qtdeMaxEstoque"
          required
          type="number"
          value={form?.qtdeMaxEstoque || 0}
          onChange={handlerChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          label="Qtde Disponível Estoque"
          name="qtdeDisponivel"
          type="number"
          value={form?.qtdeDisponivel || 0}
          disabled
        />
      </Grid>
    </Grid>
  )
}

export default TabEstoque
