import React from 'react'
import { TextField } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'

const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: props => (`1px solid ${props.error ? '#c00000' : '#e2e2e1'}`),
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff'
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: props => (`${props.error ? '#c00000' : fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`),
      borderColor: props => props.error ? '#c00000' : theme.palette.primary.main
    }
  },
  focused: {}
}))

export default props => {
  const classes = useStylesReddit(props)
  const { InputProps, value, ...outers } = props

  return (
    <TextField
      variant="filled"
      size="small"
      InputProps={{ classes, disableUnderline: true, ...InputProps }}
      fullWidth
      {...outers}
      value={value === undefined ? "" : value}
    />
  )
}
