import React, { useEffect, useRef, useState } from 'react'
import Axios from 'axios'
import moment from 'moment'

import ChatRoom from './components/ChatRoom'
import Contacts from './components/Contacts'
import './index.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Avatar, Button, DialogActions, Grid } from '@material-ui/core'

import TabsOfPerson from '../../../_organisms/pessoa/criarEditar/criarEditarSistemaDialog/tabsOfPerson'

let currentPage = 0
const limit = 10
export default () => {
  const childRef = useRef();

  const [me, setMe] = useState({})
  const [filter, setFilter] = useState('')
  const [cards, setCards] = useState([])
  const [cardSelected, setCardSelected] = useState(null)
  const [openPerson, setOpenPerson] = useState(null)
  const [pagination, setPagination] = useState({})
  const [whatsSelected, setWhatsSelected] = useState({})
  const [segmentosFull, setSegmentosFull] = useState([])


  useEffect(() => {
    currentPage = 0
    loadSistemas(true)
  }, [filter])

  useEffect(() => {
    Axios.get('/public/api/sistemaSegmento/full')
      .then(response => {
        setSegmentosFull(response)
      })

    loadMe()
  }, [])



  const loadMe = () => {
    Axios.get('api/Sistema/me', {
    }).then(result => {
      setMe(result)
      setTimeout(() => loadMe(), 10000);
    })
  }


  useEffect(() => {
    if (!whatsSelected.whatsAppId) {
      if (me?.whatsAppConfigs?.length > 0) {
        setWhatsSelected(me.whatsAppConfigs[0])
      }
    }
  }, [me?.whatsAppConfigs?.length])

  const loadSistemas = (restart) => {
    currentPage++
    Axios.get('api/Sistema', {
      params: {
        busca: filter,
        orderBy: "dateChecked-asc",
        offset: (currentPage - 1),
        limit
      }
    }).then(result => {
      if (restart) {
        setCards([...result.lista])
      } else {
        setCards([...cards, ...result.lista])
      }
      setPagination(result.paginacao)
    })
  }

  const updateCard = (c) => {
    const newCards = cards.map(_c => {
      if (c.id == _c.id) {
        return c
      }
      return _c
    })
      .sort((a, b) => moment(a.dateChecked) - moment(b.dateChecked))
    setCards(newCards)
  }

  return (
    <Grid container>
      <Grid item>
        <Contacts
          whatsSelected={whatsSelected}
          setWhatsSelected={setWhatsSelected}
          whatsAppConfigs={me.whatsAppConfigs}
          filter={filter}
          pagination={pagination}
          hasMore={cards.length < pagination.qtdeRows}
          cardSelected={cardSelected}
          cards={cards}
          setCards={setCards}
          loadSistemas={loadSistemas}
          setCardSelected={setCardSelected}
          setFilter={setFilter}
        />
      </Grid>
      <Grid item xs >

        <ChatRoom
          whatsSelected={whatsSelected}
          updateCard={updateCard}
          setOpenPerson={(p) => setOpenPerson(p?.id == openPerson?.id ? null : p)}
          cardSelected={cardSelected}
        />
      </Grid>
      {openPerson?.nome &&
        <Grid item xs={3}>
          <div style={{ display: "flex", padding: 10 }}>
            <Avatar src={global.bucket + openPerson.foto} />
            <div style={{ marginLeft: 10, textAlign: "left" }}>
              <h3>{openPerson.nome}</h3>
              <p>{openPerson.apelido}</p>
            </div>
          </div>
          <div style={{ overflowY: 'auto', height: 'calc(100vh - 190px)' }}>
            <TabsOfPerson
              segmentosFull={segmentosFull}
              ref={childRef}
              onHide={(success, data) => {
                if (success) {
                  const _cards = cards.map(c => {
                    if (data.id == c.id) {
                      return data
                    }
                    return c
                  })
                  setCards(_cards)
                  if (cardSelected?.id == data.id) {
                    setCardSelected(data)
                  }
                }
                setOpenPerson(null)
              }}
              key={openPerson.id}
              forceXS
              data={openPerson}
            />
          </div>
          <DialogActions>
            <Button variant="outlined" disabled={false}
              onClick={() => setOpenPerson(null)}>
              Fechar
            </Button>
            <Button color="primary" variant="contained" disabled={false}
              onClick={() => childRef.current.onSave()}>
              Salvar
            </Button>
          </DialogActions>
        </Grid>
      }
    </Grid>
  )
}
