import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'


import ButtonAddFiles from './buttonAddFiles'
import ImageAvatar from './ImageAvatar'
import Axios from 'axios'

function ManagementFilesModal({
  onHide,
  savedImages,
  onSave
}) {
  const [stageImages, setStageImages] = useState([...savedImages])
  const [key, setKey] = useState(new Date().getTime())
  const [loading, setLoading] = useState(false)

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const onChangeInputFile = async event => {
    setLoading(true)
    let count = 0;
    [...event.target.files].map(async file => {
      const base64 = await toBase64(file)
      file.base64 = base64
      postFile(file)
      count++
    })

    setKey(new Date().getTime())
    setLoading(false)
  }

  const postFile = async file => {
    setLoading(true)
    const data = {
      fileName: file.name,
      titulo: '',
      descricao: '',
      base64: file.base64,
      tags: []
    }

    const response = await Axios.post('api/arquivo/UploadFileToS3', data)
    setStageImages(currentList => ([...currentList, response.dados.key]))
    setLoading(false)
  }

  return (
    <Dialog open maxWidth="md" fullWidth>
      <Title
        title={'Gerenciar Imagens'}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        {(stageImages || []).map(key => {
          return (
            <ImageAvatar
              data={{ key }}
              showDelete={true}
              key={key}
              onDelete={dataDelete => {
                setStageImages(currentList => currentList.filter(x => x !== dataDelete.key))
              }}
            />
          )
        })}
        <ButtonAddFiles
          onChangeInputFile={onChangeInputFile}
          key={key}
          onAdd={newKeys => setStageImages(currentList => ([...currentList, ...newKeys]))}
          loading={loading}
        />

      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave(stageImages)}
        >Salvar</Button>
      </DialogActions>
    </Dialog>
  )
}

ManagementFilesModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  savedImages: PropTypes.array
}

export default ManagementFilesModal
