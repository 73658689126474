import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  IconButton,
  Icon,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Slide
} from '@material-ui/core'
import { ReactLiquid } from 'react-liquid'
import { TextField, TextFieldColor, TextFieldIcon } from '../'
import MenuTree from './menuItem'
import FormTree from './formItem'
import { ComboBoxAPI, ComboBoxData } from './../combobox'
import UploadImageDialog from './../uploadImageDialog'
import TextFieldJSON from '../TextFieldJSON'

export default (props) => {
  const [imageDialog, setImageDialog] = useState({ open: false })
  const {
    f,
    data,
    handlerChangeDataImage,
    handlerChangeDataOBJ,
    handlerChangeData,
    disabledReorder
  } = props

  switch (f.type) {
    case 'endereco':
      return (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              name="logradouro"
              label="Endereço"
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].logradouro}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="bairro"
              label="Bairro"
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].bairro}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cidade"
              label="Cidade"
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].cidade}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="uf"
              label="Estado"
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].uf}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pais"
              label="Pais"
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].pais}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cep"
              label="CEP"
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].cep}
            />
          </Grid>
        </Grid>
      )
    case 'image':
      return (
        <>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <center>
                <IconButton
                  style={{ padding: 8 }}
                  color="primary"
                  onClick={() =>
                    setImageDialog({
                      open: true,
                      src: `${global.bucket}${
                        data && data[f.name] && data[f.name].src
                        }`,
                      handlerChange: handlerChangeDataImage
                    })
                  }
                >
                  <Avatar
                    variant="rounded"
                    alt="Logo"
                    src={`${global.bucket}${
                      data && data[f.name] && data[f.name].src
                      }`}
                  />
                </IconButton>
              </center>
            </Grid>
            <Grid item xs={5}>
              <TextField
                type="number"
                name="blur"
                label="Blur"
                onChange={(e) => handlerChangeDataImage('blur', e.target.value)}
                value={data && data[f.name] && data[f.name].blur}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                type="number"
                name="strength"
                label="Intencidade do efeito"
                onChange={(e) =>
                  handlerChangeDataImage('strength', e.target.value)
                }
                value={data && data[f.name] && data[f.name].strength}
              />
            </Grid>
          </Grid>

          {imageDialog.open && (
            <UploadImageDialog
              title="Selecione uma imagem"
              onHide={() => setImageDialog({ open: false })}
              tag="Site"
              src={imageDialog.src}
              onCapture={(foto) => {
                imageDialog.handlerChange('src', foto)
                setImageDialog({ open: false })
              }}
            />
          )}
        </>
      )
    case 'menu':
      return (
        <MenuTree
          value={data && { ...data[f.name] }}
          onChange={(v) =>
            handlerChangeData({ target: { name: f.name, value: v } })
          }
        />
      )
    case 'form':
      return (
        <FormTree
          value={data && { ...data[f.name] }}
          onChange={(v) =>
            handlerChangeData({ target: { name: f.name, value: v } })
          }
        />
      )
    // case 'html':
    //   return (
    //     <CKEditor5
    //       value={data && data[f.name]}
    //       onChange={(v) =>
    //         handlerChangeData({ target: { name: f.name, value: v } })
    //       }
    //     />
    //   )
    case 'icon':
      return (
        <TextFieldIcon
          name={f.name}
          label={f.label}
          value={data && data[f.name]}
          onChange={handlerChangeData}
        />
      )
    case 'text':
    case 'number':
    case 'textarea':
      return (
        <TextField
          multiline={f.type == 'textarea'}
          type={f.type}
          name={f.name}
          label={f.label}
          onChange={handlerChangeData}
          value={data && data[f.name]}
        />
      )
    case 'double_text':
      return (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              name="key"
              label={`${f.label} - chave`}
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].key}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="value"
              label={`${f.label} - valor`}
              onChange={handlerChangeDataOBJ(f.name)}
              value={data && data[f.name] && data[f.name].value}
            />
          </Grid>
        </Grid>
      )
    case 'color':
      return (
        <TextFieldColor
          name={f.name}
          label={f.label}
          onChange={handlerChangeData}
          value={data && data[f.name]}
        />
      )
    case 'json':
      return (
        <TextFieldJSON
          name={f.name}
          label={f.label}
          onChange={handlerChangeData}
          value={data && data[f.name]}
          defaultJSON={f.defaultJSON}
        />
      )
    case 'bool':
      return (
        <FormControlLabel
          control={
            <Checkbox
              name={f.name}
              onChange={(e) =>
                handlerChangeData({
                  target: { name: f.name, value: e.target.checked }
                })
              }
              checked={data && data[f.name]}
            />
          }
          label={f.label}
        />
      )
    case 'comboboxApi':
      return (
        <ComboBoxAPI
          loadStart
          label={f.label}
          onChange={(e) =>
            handlerChangeData({
              target: { name: f.name, value: e.id }
            })
          }
          value={data && data[f.name] && data[f.name]}
          api={f.api}
        />
      )
    case 'outher':
      return <f.component
        name={f.name}
        label={f.label}
        onChange={(d) => handlerChangeData({ target: { name: f.name, value: d } })}
        data={data && data[f.name]}
        setForm={props.setForm}
        disabledReorder={disabledReorder}
      />
    case 'combobox':
      return (
        <ComboBoxData
          label={f.label}
          onChange={(e) =>
            handlerChangeData({
              target: { name: f.name, value: e.id }
            })
          }
          selected={data && data[f.name] && data[f.name]}
          list={f.options}
        />
      )
    default:
      return 'Type não encontrado'
  }
}
