import React, { Component } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { ComboBoxAPI } from '../../../_atoms/combobox'
import { TextFieldMoneyBR } from '../../../_atoms/numberFormat'
import { DatePicker, TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'

class LancamentoDialog extends Component {
    state = {
      form: {
        dataPagamento: '',
        dataVencimento: '',
        conta: {},
        valorLiquido: '',
        observacao: ''
      }
    }

    handleDate = (name, date) => {
      this.setState({ form: { ...this.state.form, [name]: date } })
    };

    handleChange = e => {
      this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
    };

    lancar = () => {
      const { tipo, onHide } = this.props
      const model = { ...this.state.form, tipo }
      axios.post('api/financeiro', model).then(response => {
        onHide(true)
      })
    }

    render () {
      const { form } = this.state
      const {
        dataPagamento, dataVencimento, valorLiquido, observacao, conta
      } = form
      const { title, onHide } = this.props
      return (
        <div>
          <Dialog open>
            <Title title={title} />
            <DialogContent style={{ paddingTop: 10 }}>
              <div style={{ padding: 10 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Selecione a data de Pagamento"
                      value={dataPagamento}
                      onChange={d => this.handleDate('dataPagamento', d)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label="Selecione a data de Vencimento"
                      value={dataVencimento}
                      onChange={d => this.handleDate('dataVencimento', d)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ComboBoxAPI
                      label="Selecione a conta"
                      onChange={e => this.handleChange({ target: { name: 'conta', value: e } })}
                      default={conta}
                      value={conta && conta.id}
                      api="api/conta"
                    />

                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldMoneyBR
                      name="valorLiquido"
                      value={valorLiquido}
                      label="Valor R$"
                      onValueChange={this.handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Observações"
                      value={observacao}
                      name="observacao"
                      onChange={this.handleChange}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={this.lancar}>Salvar</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default LancamentoDialog
