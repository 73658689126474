import { EMTable } from '@em/lib/src'
import { Button, Grid, Icon, IconButton, Paper } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import moment from 'moment'
import Title from '@em/lib/src/util/Title'
import { ComboBoxAPI, ComboBoxData } from '../../_atoms/combobox'

const Ponto = ({ usuario }) => {
  const anoAtual = Number(moment().format("YYYY"))
  const anos = [
    anoAtual - 2,
    anoAtual - 1,
    anoAtual,
  ]
  const [totais, setTotais] = useState({ horas: 0, datesDistincts: [] })
  const [pontoList, setPontoList] = useState([])
  const [filter, setFilter] = useState({
    ano: anoAtual,
    mes: Number(moment().format("MM")),
    usuarioId: ""
  })

  useEffect(() => {
    loadPontos()
  }, [])


  const loadPontos = () => {
    Axios.get('api/ponto/full', { params: filter }).then(response => {
      let minutesMonth = 0
      let datesDistincts = []
      response.forEach(dt => {
        let minutesDiff = 0
        if (dt.dataSaida) {
          minutesDiff = moment(dt.dataSaida).diff(dt.dataEntrada, "minutes")
          minutesMonth += minutesDiff
        }
        const dd = moment(dt.dataEntrada).format("DD")
        if (!datesDistincts.includes(dd)) {
          datesDistincts.push(dd)
        }


        dt.week = moment(dt.dataEntrada).format("ddd")
        dt.dataEntrada = moment(dt.dataEntrada).format("DD/MM/YYYY HH:mm")
        dt.dataSaida = moment(dt.dataSaida).format("DD/MM/YYYY HH:mm")

        var num = minutesDiff;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        dt.onlineTime = rhours + "h " + rminutes
      })


      var ttnum = minutesMonth
      var tthours = (ttnum / 60)
      var ttrhours = Math.floor(tthours)
      var ttminutes = (tthours - ttrhours) * 60
      var ttrminutes = Math.round(ttminutes)

      setTotais({ horas: ttrhours + "h " + ttrminutes, datesDistincts })

      setPontoList(response
        .sort((a, b) => {
          if (a.dataEntrada > b.dataEntrada) {
            return 1;
          }
          if (a.dataEntrada < b.dataEntrada) {
            return -1;
          }
          return 0;
        })
      )
    })
  }


  return (
    <div>
      <Title title="Controle de Tempo" />

      <Paper style={{ marginBottom: 10, padding: 10 }}>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Grid item>

            <ComboBoxAPI
              loadStart
              label="Usuario"
              onChange={e => setFilter({ ...filter, usuarioId: e.id })}
              value={filter.usuarioId}
              style={{ width: 300 }}
              api={"api/login/usuario/full"}
            />

          </Grid>

          <Grid item>

            <ComboBoxData
              label="Mês"
              onChange={e => setFilter({ ...filter, mes: e.id })}
              selected={filter.mes}
              style={{ width: 300 }}
              list={[
                { id: 1, nome: "Janeiro" },
                { id: 2, nome: "Fevereiro" },
                { id: 3, nome: "Março" },
                { id: 4, nome: "Abril" },
                { id: 5, nome: "Maio" },
                { id: 6, nome: "Junho" },
                { id: 7, nome: "Julho" },
                { id: 8, nome: "Agosto" },
                { id: 9, nome: "Setembro" },
                { id: 10, nome: "Outubro" },
                { id: 11, nome: "Novembro" },
                { id: 12, nome: "Dezembro" },
              ]}
            />

          </Grid>

          <Grid item>

            <ComboBoxData
              label="Ano"
              onChange={e => setFilter({ ...filter, ano: e.id })}
              selected={filter?.ano}
              style={{ width: 300 }}
              list={anos.map(x => {
                return {
                  id: `${x}`,
                  nome: `${x}`,
                }
              })}
            />
          </Grid>


          <Grid item xs>
            <Button variant="contained" color="primary" onClick={loadPontos}>
              Buscar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Paper style={{ padding: 10, backgroundColor: "#CCC", textAlign: "center", minWidth: 100 }}>
                  <h1>{totais.horas}</h1>
                  <p>Horas Online</p>
                </Paper>
              </Grid>
              <Grid item>
                <Paper style={{ padding: 10, backgroundColor: "#CCC", textAlign: "center", minWidth: 100 }}>
                  <h1>{pontoList.length}</h1>
                  <p>Marcações</p>
                </Paper>
              </Grid>
              <Grid item>
                <Paper style={{ padding: 10, backgroundColor: "#CCC", textAlign: "center", minWidth: 100 }}>
                  <h1>{totais.datesDistincts.length}</h1>
                  <p>Dias</p>
                </Paper>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Paper>

      <EMTable
        title="Marcações"
        data={pontoList}
        options={{ paging: false, toolbar: true }}
        columns={[
          {
            title: 'Semana',
            field: 'week',
            maxWidth: 100
          },
          {
            title: 'Entrada',
            field: 'dataEntrada',
          },
          {
            title: 'Saida',
            field: 'dataSaida',
            render: rowData => {
              if (!rowData.dataSaida) {
                return "Online"
              }
              return rowData.dataSaida
            }
          },
          {
            title: 'Tempo Online',
            field: "onlineTime",
          },
        ]}
      />
    </div>
  )
}


export default Ponto
