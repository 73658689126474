import React from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import { Button, Grid, Tooltip } from '@material-ui/core'
import { axiosEMUtility } from '../../../../myAxios'

const RangeSimplesNacional = {
  1: 4,
  2: 7.3,
  3: 9.5,
  4: 10.70,
  5: 14.30,
  6: 19.00
}

function WorkflowStatusButtons ({
  pedido,
  sistema,
  emUtility,
  onRefreshPedido,
  todosStatusDePedidos,
  usuario,
  changedPedido
}) {
  const atualizaPedido = (statusEntrega) => {
    Axios.put(`api/pedido/${pedido.id}/status/${statusEntrega.id}`).then(result => {
      if (result) {
        onRefreshPedido({
          ...pedido,
          statusEntrega: statusEntrega.statusType,
          statusEntregaId: statusEntrega.id
        })
      }
    })
  }

  const setTaxDoItem = async (order) => {
    const itensId = order?.itens?.map(x => x.id).join(',')

    if (itensId) {
      const itensResponse = await Axios.get(`api/item/${itensId}?withNCM=true`)
      order.itens.map(it => {
        it.tax = { ...itensResponse.find(x => x.id === it.id)?.tax }
        it.ean = itensResponse.find(x => x.id === it.id)?.ean
        return it
      })
    }
  }

  const emitirTesteFimAFim = async () => {
    await setTaxDoItem(pedido)
    const pICMS = RangeSimplesNacional[sistema.tax.range]
    const response = await axiosEMUtility.post('/api/Sat/TesteFimAFim', { ...pedido, pICMS })
    pedido.cFe = response.data.chaveConsulta
    onRefreshPedido(pedido)
  }

  const emitirCupomFiscal = async () => {
    await setTaxDoItem(pedido)
    const pICMS = RangeSimplesNacional[sistema.tax.range]
    const response = await axiosEMUtility.post('/api/Sat/EnviarDadosVenda', { ...pedido, pICMS })
    pedido.cFe = response.data.chaveConsulta
    if (response?.data?.dados?.chaveConsulta) {
      pedido.cFe = response.data.dados.chaveConsulta
      Axios.post('api/pedido', pedido)
      onRefreshPedido(pedido)
    }
  }

  let buttons = []
  if (pedido?.statusEntrega === 0) {
    buttons = todosStatusDePedidos.filter(s => !s.parentId)
  } else {
    buttons = todosStatusDePedidos.filter(s => s.parentId === pedido?.statusEntregaId)
  }

  return (
    <Grid container spacing={1}>
      {!pedido.cFe && pedido?.statusEntregaJoin?.activeTax && (
        <>
          {usuario?.sistemaAnterior?.id === '5b6384392707c66b5d232a11' &&
            <Grid item xs={6}>
              <Tooltip title={changedPedido ? 'É necessário salvar o pedido!' : ''} placement="top">
                <div>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    disabled={changedPedido}
                    onClick={emitirTesteFimAFim}
                  >
                    teste fim a fim
                  </Button>
                </div>
              </Tooltip>
            </Grid>
          }

          <Grid item xs={6}>
            <Tooltip title={changedPedido ? 'É necessário salvar o pedido!' : ''} placement="top">
              <div>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={changedPedido}
                  onClick={emitirCupomFiscal}
                >
                  Emitir Cupom fiscal
                </Button>
              </div>
            </Tooltip>
          </Grid>
        </>
      )
      }
      {
        buttons.map((b, idx) => {
          return (
            <Grid key={idx} item xs={6}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => atualizaPedido(b)}
              >
                {b.name}
              </Button>
            </Grid>
          )
        })
      }
    </Grid >
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(WorkflowStatusButtons)
