import React, { useEffect, useState } from 'react'

import { ComboBoxAPI, ComboBoxData } from '../../../../_atoms/combobox'
import { Button, Grid } from '@material-ui/core'
import { TextField } from '../../../../_atoms'
import Axios from 'axios'
import { SettingsInputCompositeTwoTone } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'

const pisCofinsGrupos = [
  {
    id: '01',
    nome: '01 – Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))'
  },
  { id: '02', nome: '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))' },
  { id: '03', nome: '03 - Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)' },
  { id: '04', nome: '04 - Operação Tributável (tributação monofásica (alíquota zero))' },
  { id: '06', nome: '06 - Operação Tributável (alíquota zero)' },
  { id: '07', nome: '07 - Operação Isenta da Contribuição' },
  { id: '08', nome: '08 - Operação Sem Incidência da Contribuição' },
  { id: '09', nome: '09 - Operação com Suspensão da Contribuição' },
  { id: '49', nome: '49 - Outras Operações de saída' },
  { id: '99', nome: '99 - Outras Operações' }
]

const icmsOrigs = [
  { id: '0', nome: '0 - Nacional, exceto as indicadas nos códigos 3 a 5' },
  { id: '1', nome: '1 - Estrangeira - Importação direta, exceto a indicada no código 6' },
  { id: '2', nome: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7' },
  { id: '3', nome: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%' },
  {
    id: '4',
    nome:
      '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes'
  },
  { id: '5', nome: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%' },
  { id: '6', nome: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX' },
  { id: '7', nome: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX' },
  { id: '8', nome: '8 - Nacional - mercadoria ou bem com Conteúdo de Importação superior a 70% (setenta por cento)' }
]

const icmsCST = [
  { id: '00', nome: '00 - Tributada integralmente', pICMS: true },
  { id: '20', nome: '20 - Com redução de base de cálculo', pICMS: true },
  { id: '90', nome: '90 - Outros', pICMS: true },
  { id: '40', nome: '40 - Isenta', pICMS: false },
  { id: '41', nome: '41 - Não tributada', pICMS: false },
  { id: '50', nome: '50 - Suspensão', pICMS: false },
  { id: '60', nome: '60 - ICMS cobrado anteriormente por substituição tributária', pICMS: false },
  { id: '102', nome: '102- Tributada pelo Simples Nacional sem permissão de crédito', pICMS: false },
  { id: '300', nome: '300 – Imune', pICMS: false },
  { id: '400', nome: '400 – Não tributada', pICMS: false },
  {
    id: '500',
    nome: '500 – ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
    pICMS: false
  },
  { id: '900', nome: '900 - Outros', pICMS: true }
]

const cfops = [
  {
    nome: '5101 - Venda de produção do estabelecimento',
    id: '5101'
  },
  {
    nome: '5102 - Venda de mercadoria adquirida ou recebida de terceiros',
    id: '5102'
  },
  {
    nome: '5103 - Venda de produção do estabelecimento, efetuada fora do estabelecimento',
    id: '5103'
  },
  {
    nome: '5104 - Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento',
    id: '5104'
  },
  {
    nome:
      '5405 - Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído',
    id: '5405'
  }
]

function TabFiscal({ tax, handlerChange }) {
  const [taxGroup, setTaxGroup] = useState(null)

  const renderSubTitle = title => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{
        height: 1, backgroundColor: '#ccc', width: '100%', marginRight: 5
      }}
      />
      <div>{title}</div>
      <div style={{
        height: 1, backgroundColor: '#ccc', width: '100%', marginLeft: 5
      }}
      />
    </div>
  )

  const findNCM = () => {
    if (tax?.ncm) {
      Axios.get(`api/grupofiscal/ncm/${tax.ncm}`).then(response => {
        if (response.status == 204) {
          setTaxGroup({ error: true })
        } else {
          setTaxGroup(response)
        }
      })
    }
  }

  useEffect(() => {
    if (tax?.ncm && tax?.ncm.length == 8) {
      findNCM()
    } else {
      setTaxGroup(null)
    }
  }, [tax?.ncm])

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={3}>
        <ComboBoxAPI
          label="NCM"
          onChange={e => handlerChange({ target: { name: 'tax', value: { ...tax, ncm: e.ncm } } })}
          value={tax?.ncm}
          default={tax}
          nameValue="ncm"
          nameText="ncm"
          api="api/grupofiscal/full"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          label="CFOP de Entrada"
          value={tax?.cfopEntrada}
        />
      </Grid>
      <Grid item xs={3}>
        <ComboBoxData
          label="CFOP de Venda"
          name="cfop"
          onChange={e => handlerChange({ target: { name: 'tax', value: { ...tax, cfop: e.id } } })}
          selected={tax?.cfop}
          list={cfops}
        />
      </Grid>
      {/* <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={findNCM}>
          Buscar NCM
        </Button>
      </Grid> */}
      {taxGroup?.error &&
        <Grid item xs={12}>
          <Alert
            severity="warning">
            <AlertTitle>
              NCM Não cadastrado no fiscal
            </AlertTitle>
            <pre>
              Cadastre o NCM {tax.ncm} ou solicite ao seu contador.
            </pre>
          </Alert>
        </Grid>
      }
      {(taxGroup && !taxGroup.error) &&
        <>
          <Grid item xs={8}>
            <TextField
              disabled
              label="Nome"
              value={taxGroup?.nome}
            />
          </Grid>
          <Grid iteu xs={4}>
            <TextField
              disabled
              label="CEST"
              value={taxGroup?.cest}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled
              label="Origem"
              value={icmsOrigs.find(x => x.id == [taxGroup?.icms?.orig]).nome || taxGroup?.icms?.orig}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              disabled
              label="ICMS CST"
              value={icmsCST.find(x => x.id == [taxGroup?.icms?.cst]).nome || taxGroup?.icms?.cst}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              label="Alíquota efetiva do imposto (pICMS)"
              name="pICMS"
              value={tax?.icms?.pICMS}
              onChange={e => handlerChange({ target: { name: 'tax', value: { ...tax, icms: { ...tax?.icms, pICMS: e.target.value } } } })}
            />
          </Grid> */}

          <Grid item xs={12} style={{ margin: 10 }}>
            {renderSubTitle('PIS')}
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label="PIS Código de Situação Tributária"
              value={pisCofinsGrupos.find(x => x.id == [taxGroup?.pis?.cst]).nome || taxGroup?.pis?.cst}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label="Alíquota do PIS (em percentual) (pPis)"
              value={taxGroup?.pis?.pPIS}
            />
          </Grid>
          <Grid item xs={12} style={{ margin: 10 }}>
            {renderSubTitle('COFINS')}
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label="COFINS Código de Situação Tributária"
              value={pisCofinsGrupos.find(x => x.id == [taxGroup?.cofins?.cst]).nome || taxGroup?.cofins?.cst}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              label="Alíquota do COFINS (em percentual) (pCOFINS)"
              value={taxGroup?.cofins?.pCOFINS}
            />
          </Grid>
        </>
      }

    </Grid>
  )
}

export default TabFiscal
