import React from 'react'
import ReactFileReader from 'react-file-reader'
import { Button, Grid, DialogActions } from '@material-ui/core'

export default props => {
  return (
    <center>
      <Grid contaienr spacing={6}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <img style={{maxWidth: 500, maxHeight: 500}} src={props.src} />
        </Grid>
      </Grid>

      <DialogActions>
          <Button variant="outlined" color="secondary" onClick={props.onRemove}>
            Remover Imagem
          </Button>
          <ReactFileReader
            fileTypes={props.fileTypes}
            base64
            multipleFiles={false}
            handleFiles={props.onCapture}
          >
            <Button variant="outlined" color="primary">
              Carregar Imagem
            </Button>
          </ReactFileReader>
        </DialogActions>
    </center>
  )
}
