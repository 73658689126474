import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { TextField, ReactTable } from '../..'
import Title from '@em/lib/src/util/Title'
import TextFieldIcon from '../../TextFieldIcon'
import { ComboBoxData } from '../../combobox'
import maskDictionary from './maskDictionary.json'

const AddOrEditModal = props => {
  const [form, setForm] = useState({...(props.action == "add" ? {} : props.data)})

  const handlerChange = e => {
    setForm({...form, [e.target.name]: e.target.value })
  };

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={props.action != "add" ? 'Edição do Formulário' : 'Novo Item Do Formalário'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide },
          ]}
        />
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={4}>
                <TextField
                  name="label"
                  label="Label"
                  onChange={handlerChange}
                  value={form.label}
                />
            </Grid>
            <Grid item xs={4}>
              <ComboBoxData
                label="Mascara"
                onChange={e => handlerChange({ target: { name: 'mask', value: e.id } })}
                selected={form && form.mask}
                list={[
                  { id: "text", nome: maskDictionary["text"] },
                  { id: "number", nome: maskDictionary["number"] },
                  { id: "phone", nome: maskDictionary["phone"] },
                  { id: "date", nome: maskDictionary["date"] },
                  { id: "email", nome: maskDictionary["email"] },
                ]}
              />

            </Grid>
            <Grid item xs={4}>
                <TextField
                  name="gridItem"
                  label="Grid (JSON)"
                  onChange={handlerChange}
                  value={form.gridItem}
                />
                      {/* <ComboBoxData
                        label="Grid"
                        onChange={e => handlerChange({ target: { name: 'grid', value: e.id } })}
                        selected={form && form.grid}
                        list={[
                          { id: 0, nome: '0' },
                          { id: 1, nome: '1' },
                          { id: 2, nome: '2' },
                          { id: 3, nome: '3' },
                          { id: 4, nome: '4' },
                          { id: 5, nome: '5' },
                          { id: 6, nome: '6' },
                          { id: 7, nome: '7' },
                          { id: 8, nome: '8' },
                          { id: 9, nome: '9' },
                          { id: 10, nome: '10' },
                          { id: 11, nome: '11' },
                          { id: 12, nome: '12' },
                        ]}
                      /> */}

            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!form.required}
                      onChange={e => handlerChange({target:{name:"required", value:e.target.checked}})}
                    />
                  )}
                  label="Requerido"
                />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={()=> props.onChange(form, props.action)}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default AddOrEditModal
