import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const TinyEditor = ({ editorRef, initialValue, title }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_TOKEN}
      onInit={(evt, editor) => editorRef.current = editor}
      initialValue={initialValue}
      init={{
        min_height: 400,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | undo redo',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  )
}
