import React, { useEffect, useState } from 'react'
import { Avatar, Badge, Checkbox } from '@material-ui/core'
import moment from 'moment'

import './contact.css'

function Chats({ name, foto, dateChecked, selected, comment, unread }) {
  var date = moment(dateChecked)
  var diff = date.diff(moment(), 'days') // 1
  var style = {
    position: 'relative',
    background: '#ffffff'
  }
  if (diff < 0) {
    style.background = '#ef5350'
    style.color = '#ffffff'
  } else if (diff < 1) {
    style.background = '#fff176'
  } else if (diff < 8) {
    style.background = '#e0e0e0'
  }

  if (selected) {
    style.borderRadius = `0 100px 0 0`
  }

  var dateFormat = date.format('DD/MM/YYYY HH:mm')
  return (
    <div className="chats" style={style}>
      <Badge badgeContent={unread} color="error">
        <Avatar src={foto ? (global.bucket + foto) : null} />
      </Badge>
      <div style={{ marginLeft: 15 }}>
        <p style={{ fontSize: 15, fontWeight: 'bold' }}>{name}</p>
        <p>{comment}</p>
      </div>
      {date &&
        <p style={{ fontSize: 10, position: 'absolute', bottom: 5, right: 5 }}>
          Agenda: {dateFormat}
        </p>
      }
    </div>
  )
}

export default Chats
