import React from 'react'
import { InlineDatePicker } from 'material-ui-pickers'

import TextField from './TextField'

export default props => {
  const newProps = { ...props, value: props.value || null }
  return (
    <InlineDatePicker
      variant="filled"
      label="Selecione a data"
      format="DD/MM/YYYY"
      mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : [])}
      invalidDateMessage="Data invalida"
      keyboard
      animateYearScrolling
      TextFieldComponent={TextField}
      fullWidth
      clearable
      {...newProps}
    />
  )
}
