import React, { PureComponent } from 'react'
import { Icon, Chip } from '@material-ui/core'
import axios from 'axios'
import ConnectModal from './ConnectModal'
import IconButtonEdit from '../../_atoms/button/IconButtonEdit'
import IconButtonAdd from '../../_atoms/button/IconButtonAdd'
import './connect.css'
import CircularProgress from '@material-ui/core/CircularProgress'

class Connect extends PureComponent {
    state = {
      versao: null,
      loading: false,
      modal: false,
      connect: {
        id: null,
        host: '',
        host2: '',
      },
    }

    openModal = () => {
      this.setState({ modal: true })
    }

    onHide = () => {
      const { connect } = this.state
      this.setState({
        modal: false,
        connect: { ...connect, host2: connect.host },
      })
    }

    handleChange = event => {
      const { connect } = this.state
      this.setState({
        connect: {
          ...connect,
          host2: event.target.value,
        },
      })
    }

    onSave = () => {
      const { connect } = this.state
      axios.post('/api/Connect/Integration', {
        id: connect.id,
        host: connect.host2,
        tipo: 0,
      }).then(result => {
        this.onHide()
        this.setConnect(result.dados || result)
      })
    }

    getConnect = () => {
      axios.get('/api/Connect/Integration').then(result => {
        this.setConnect(result.dados || result)
      })
    }

    setConnect = connect => {
      this.setState({ connect: { ...connect, host2: connect.host } })
      this.checkConnection(connect)
      this.props.setConnect && this.props.setConnect(connect)
    }

    checkConnection(connect) {
      this.setState({
        loading: true,
      })

      axios.create({ baseURL: connect.host }).get('api/Versao')
        .then(result => {
          this.setState({ versao: result.data.versao, loading: false })
        }).catch(err => {
          this.setState({ versao: null, loading: false })
        })
    }

    componentDidMount() {
      this.getConnect()
    }

    render() {
      const { funcoes } = this.props
      const { loading, versao, connect } = this.state
      return (
        <>
          {this.state.modal && <ConnectModal onHide={this.onHide} onSave={this.onSave} handleChange={this.handleChange} connect={this.state.connect} />}

          <div className="connect-component">
            <div className="container">
              <div className="item">
                            Connect
              </div>
              <div className="item">
                {
                                connect.host
                            }
              </div>
              <div className="item">
                {
                                loading
                                  ? <CircularProgress />
                                  : versao === null
                                    ? <Icon className="no-connection">signal_wifi_off</Icon>
                                    : <Icon className="connected">signal_wifi_4_bar</Icon>
                            }

              </div>
              <div className="item">
                <Chip label={loading ? 'Procurando...' : (versao === null) ? 'Sem conexão' : `Versão: ${versao}`} />
              </div>
              <div className="item button">
                {
                                connect.id
                                  ? <IconButtonEdit onClick={this.openModal} funcoes={funcoes} />
                                  : <IconButtonAdd onClick={this.openModal} funcoes={funcoes} />
                            }
              </div>
            </div>
          </div>
        </>
      )
    }
}

export default Connect
