import React, { useState } from 'react'
import { EMTable } from '@em/lib/src'
import Axios from 'axios'
import moment from 'moment'
import { Grid, Paper, Button } from '@material-ui/core'
import { Chart, DatePicker } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import CallEndIcon from '@material-ui/icons/CallEnd'
import PhoneIcon from '@material-ui/icons/Phone'
import Highcharts from 'highcharts'

export default () => {
  const tableRef = React.createRef()
  const [createAtStart, setCreateAtStart] = useState(null)
  const [createAtEnd, setCreateAtEnd] = useState(null)
  const [list, setList] = useState([])

  const loadMessages = () => tableRef.current && tableRef.current.onQueryChange({ page: 0 })

  return (
    <div>
      <Paper style={{ margin: 10 }}>
        <Title title="Auditoria de mensagens" />
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <DatePicker
              label="Data de inicio"
              maxDate={createAtEnd || ''}
              value={createAtStart}
              onChange={value => setCreateAtStart(value)}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="Data de fim"
              minDate={createAtStart || ''}
              value={createAtEnd}
              onChange={value => setCreateAtEnd(value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={loadMessages}
            >
              Busca
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <EMTable
        tableRef={tableRef}
        data={async query => {
          const { page, pageSize } = query
          const response = await Axios.get('api/message', {
            params: {
              offset: page,
              limit: pageSize,
              createAtStart: moment(createAtStart).format('YYYY-MM-DD'),
              createAtEnd: moment(createAtEnd).format('YYYY-MM-DD')
            }
          })

          setList(response.lista)

          return {
            data: response.lista,
            page,
            totalCount: response?.paginacao?.qtdeRows || 0
          }
        }}
        columns={[
          {
            title: 'Remetente',
            field: 'sender.name',
            customSort: console.log
          },
          {
            title: 'Destinatário',
            field: 'receiver.name'
          },
          {
            title: 'Data de envio/recebimento',
            field: 'createAt',
            render: rowData => moment(rowData.createAt).format('DD/MM/YYYY HH:mm')
          },
          {
            field: 'channel.type',
            width: 30,
            lookup: { 1: <PhoneIcon />, 2: <WhatsAppIcon /> },
            emptyValue: <CallEndIcon />
          },
          {
            title: 'Mensagem',
            field: 'content'
          }
        ]}
      />
    </div>
  )
}
