import { Icon, IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import React, { useState, useEffect } from 'react'

import SearchItemDialog from '../_pages/sortimento/components/searchItemDialog'
import TextField from './TextField'

function TextFieldWithSearchItems ({
  disabled,
  value,
  itens,
  tags,
  error,
  label,
  nameValue,
  nameText,
  onChange
}) {
  const [searchItemsDialog, setSearchItemsDialog] = useState({ open: false })
  const [inputValue, setInputValue] = useState('')
  const [search, setSearch] = useState('')

  useEffect(() => {
    const item = itens?.find(item => item[nameValue || 'id'] === value)
    setInputValue(item ? `${item?.codigo} - ${item?.nome}` : '')
    setSearch(item ? item?.codigo : '')
    setSearchItemsDialog({ open: false })
  }, [value])

  return (
    <>
      <TextField
        label={label}
        error={error}
        value={inputValue}
        InputProps={{
          endAdornment: (
            <InputAdornment variant="outlined" position="end">
              <Tooltip placement="top" title={value ? 'Trocar Item' : 'Selecionar Item'}>
                <IconButton
                  disabled={disabled}
                  onClick={() => setSearchItemsDialog({ open: true })}
                >
                  <Icon>{value ? 'autorenew' : 'add'}</Icon>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
      {searchItemsDialog.open &&
        <SearchItemDialog
          onAddItem={item => {
            onChange(null, item)
          }}
          itens={itens}
          search={search}
          tags={tags}
          onHide={() => setSearchItemsDialog({ open: false })}
        />
      }
    </>
  )
}

export default TextFieldWithSearchItems
