import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid, Checkbox, FormControlLabel, Paper, Chip, Tooltip, Badge
} from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { EMTable } from '@em/lib/src'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import Axios from 'axios'

import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { TextFieldPercent } from '../../../../_atoms/numberFormat'
import { showMensagemConfirmacaoAction } from '../../../../actions/mensagemAction'
import _ from 'lodash'

const xmlToJSON = require('xmltojson')
const colorDB = '#64b5f6'

const RangeSimplesNacional = {
  1: 4,
  2: 7.3,
  3: 9.5,
  4: 10.70,
  5: 14.30,
  6: 19.00
}

const EntradaXMLModal = props => {
  const [calcVenda, setCalcVenda] = useState({
    calc: false,
    changed: false,
    sumFaixa: true,
    sumFixedExpence: true
  })
  const [sistema, setSistema] = useState({})
  const [estoqueEntrada, setEstoqueEntrada] = useState({
    observacao: '',
    fornecedor: {},
    itens: [],
    tipo: 1,
    loading: true,
    ...props.data
  })

  const [showErrorSave, setShowErrorSave] = useState(false)
  const [fornecedorReposicao, setFornecedorReposicao] = useState(false)
  const [compareCodigoInterno, setCompareCodigoInterno] = useState(false)

  const onSave = () => {
    const { itens, fornecedor } = estoqueEntrada
    let error = false
    if (!itens || itens?.length == 0 || !fornecedor?.id) {
      error = true
    }

    if (error) {
      setShowErrorSave(true)
      return
    }

    axios.post(`api/estoqueentrada/${calcVenda.calc}`, estoqueEntrada)
      .then(resp => {
        props.onHide(true)
      })
  }

  const getProducts = async (fornecedor, itensNota) => {
    const externalCodes = itensNota.map(x => x.externalCode)
    if (fornecedor.id) {
      let stComprareCode = 'externalCode'
      let url = `api/item/full?externalCodes=${externalCodes.join(',')}`
      if (!fornecedorReposicao) {
        url += `&providerId=${fornecedor.id}`
      }
      if (compareCodigoInterno) {
        url = url.replace('externalCodes', 'codigos')
        stComprareCode = 'codigo'
      }

      const itensDB = await Axios.get(url)
      if (itensDB) {
        itensNota.forEach(item => {
          const itemFind = itensDB.find(x => x[stComprareCode] == item.externalCode && x.unidadeMedida === item.unidadeMedida)
          const itemsFilter = itensDB.filter(x => x[stComprareCode] == item.externalCode && (x?.id !== itemFind?.id || !itemFind))

          item.itemsDB = itemsFilter
          item.itemDB = itemFind
          item.id = itemFind?.id
        })
      }
    }
  }

  const confirmAction = e => {
    if (e.target.checked) {
      const model = {
        title: 'ATENÇÃO',
        mensagem: 'Esta opção poderá dar entrada em itens cujo o fornecedor é diferente desta nota. Você tem certeza que deseja fazer isto?',
        buttoes: [
          { text: 'Confirmar', onClick: () => setFornecedorReposicao(true) }
        ],
        onHideFunc: () => {
          setFornecedorReposicao(false)
        }
      }
      props.showMensagemConfirmacaoAction(model)
    } else {
      setFornecedorReposicao(false)
    }
  }

  const parseXML = async () => {
    var myOptions = { mergeCDATA: false, xmlns: false, attrsAsObject: true }

    const result = xmlToJSON.parseString(estoqueEntrada.xml, myOptions)
    const itens = []
    const NFe = result.nfeProc[0].NFe[0]
    const infNFe = NFe.infNFe[0]
    const ide = infNFe.ide[0]
    const chave = infNFe._attr.Id._value
    const emit = infNFe.emit[0]
    const cnpj = emit.CNPJ[0]._text

    const fornecedorDB = await Axios.get(`api/pessoa/provider/ByCpfCNPJ?CpfCnpj=${cnpj}`)
    const fornecedor = {
      id: fornecedorDB.id,
      cnpj: emit.CNPJ[0]._text,
      nome: emit.xNome[0]._text,
      apelido: emit?.xFant && emit?.xFant[0]._text || emit.xNome[0]._text
    }

    infNFe.det.forEach(itemXML => {
      const prod = itemXML.prod[0]
      const tax = {}
      if (prod.NCM) {
        tax.NCM = `${prod.NCM[0]._text}`
      }
      if (prod.CFOP) {
        tax.CFOPEntrada = `${prod.CFOP[0]._text}`
      }

      itens.push({
        nome: prod.xProd[0]._text,
        qtde: prod.qCom[0]._text,
        ean: `${prod.cEAN[0]._text}`,
        externalCode: `${prod.cProd[0]._text}`,
        costPrice: prod.vUnCom[0]._text,
        costPriceTotal: prod.vProd[0]._text,
        tax,
        unidadeMedida: prod.uCom[0]._text
      })
    })

    await getProducts(fornecedor, itens)

    setEstoqueEntrada({
      ...estoqueEntrada,
      itens,
      nNF: `${ide.nNF[0]._text}`,
      fornecedor,
      chave,
      loading: false
    })
  }

  useEffect(() => {
    if (estoqueEntrada.xml) {
      parseXML()
    }
  }, [estoqueEntrada.xml])

  useEffect(() => {
    const funcAsync = async () => {
      if (!sistema.id) {
        const responseSistema = await axios.get('api/sistema/me')
        setSistema(responseSistema)
        setCalcVenda({
          ...calcVenda,
          formula: responseSistema?.tax?.formula,
          margin: responseSistema?.tax?.marginDefault
        })
      }
    }
    funcAsync()
  }, [])

  useEffect(() => {
    if (calcVenda.calc) {
      onCalc()
    }
  }, [calcVenda.calc])

  const onCalc = async () => {
    if (calcVenda.calc && estoqueEntrada.itens.length > 0) {
      const itens = estoqueEntrada.itens.map(item => {
        let percents = calcVenda.margin
        if (calcVenda.sumFaixa && sistema?.tax?.range) {
          percents += RangeSimplesNacional[sistema.tax.range]
        }

        if (calcVenda.sumFixedExpence && sistema?.tax?.fixedExpense) {
          percents += sistema.tax.fixedExpense
        }

        let multiplicador = 1
        if (sistema.tax.formula == 'marckup') {
          multiplicador = 1 + (percents / 100)
        } else {
          multiplicador = 100 / (100 - percents)
        }
        const valor = item.costPrice * multiplicador

        return { ...item, valor }
      })
      setCalcVenda({ ...calcVenda, changed: false })
      setEstoqueEntrada({ ...estoqueEntrada, itens })
    }
  }

  useEffect(() => {
    const fn = async (e) => {
      const itens = estoqueEntrada.itens
      await getProducts(estoqueEntrada.fornecedor, itens)
      setEstoqueEntrada({ ...estoqueEntrada, itens })
    }
    fn()
  }, [fornecedorReposicao, compareCodigoInterno])

  const renderNota = () => {
    if (!estoqueEntrada?.fornecedor?.id) {
      return (
        <Grid item xs>
          <Alert severity="warning">
            <AlertTitle>Fornecedor não encontrado</AlertTitle>
            <pre>Cadastre-o em sua base para seguir coma importação</pre>
          </Alert>
        </Grid>
      )
    }
    return (
      <>
        <Grid item xs={3}>
          <TextField
            disabled
            label="Fornecedor"
            value={estoqueEntrada?.fornecedor?.cnpj}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            disabled
            label="Razão Social"
            value={estoqueEntrada?.fornecedor?.nome}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            label="Nome Fantasia"
            value={estoqueEntrada?.fornecedor?.apelido}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled
            label="Nota"
            value={`${estoqueEntrada?.nNF}`}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={confirmAction}
                checked={fornecedorReposicao}
              />
            }
            label={'Fornecedor de reposição'}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => setCompareCodigoInterno(e.target.checked)}
                checked={compareCodigoInterno}
              />
            }
            label={'Codigo interno'}
          />
        </Grid>

        {(estoqueEntrada?.itens?.length > 0) &&
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={calcVenda.calc}
                    onChange={e => setCalcVenda({ ...calcVenda, changed: true, calc: e.target.checked })}
                  />
                }
                label={'Quero atualizar o preço'}
              />
            </Grid>
            {(calcVenda.calc && sistema?.tax?.formula) &&
              <Grid item xs={12}>
                <Paper>
                  <Grid container alignItems="center" justify="space-between" spacing={1}>
                    <Grid item>
                      <TextFieldPercent
                        fullWidth
                        label={'Aplicar ' + (sistema.tax.formula || 'margem')}
                        onValueChange={e => setCalcVenda({ ...calcVenda, changed: true, margin: e.target.value })}
                        value={calcVenda.margin}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox color="primary"
                          checked={calcVenda.sumFaixa}
                          onChange={e => setCalcVenda({ ...calcVenda, changed: true, sumFaixa: e.target.checked })}
                        />}
                        label={`Adicionar DAS (${RangeSimplesNacional[sistema.tax.range]}%)`}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox color="primary"
                          checked={calcVenda.sumFixedExpence}
                          onChange={e => setCalcVenda({ ...calcVenda, changed: true, sumFixedExpence: e.target.checked })}
                        />}
                        label={`Adicionar Despesas fixas (${sistema.tax.fixedExpense}%)`}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        disabled={!calcVenda.changed || !estoqueEntrada.itens || estoqueEntrada.itens.length == 0}
                        variant={'contained'}
                        color="primary"
                        onClick={onCalc}>Calcular</Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            }

            <Grid item xs={12}>
              <EMTable
                options={{
                  toolbar: false,
                  paging: false
                }}
                data={estoqueEntrada.itens}
                columns={[
                  {
                    title: 'Codigo',
                    field: 'externalCode',
                    width: 100,
                    editable: 'never',
                    render: dataRow => {
                      return (
                        <>
                          <Badge badgeContent={dataRow.itemsDB?.length} color="secondary" invisible={!dataRow.itemsDB || dataRow.itemsDB?.length <= 0}>
                            {dataRow.externalCode}
                          </Badge>
                          <p style={{ color: colorDB }}>
                            {dataRow?.itemDB?.codigo}
                          </p>
                        </>
                      )
                    }
                  },
                  {
                    title: 'Nome',
                    field: 'nome',
                    editable: 'never',
                    render: dataRow => {
                      return (
                        <div>
                          {dataRow.nome}
                          {dataRow?.itemDB?.nome &&
                            <p style={{ color: colorDB }}>
                              {dataRow?.itemDB?.nome}
                            </p>
                          }
                        </div>
                      )
                    }
                  },
                  {
                    title: 'Unid. Medida',
                    field: 'unidadeMedida',
                    editable: 'never',
                    render: dataRow => {
                      return (
                        <div>
                          {dataRow.unidadeMedida}
                        </div>
                      )
                    }
                  },
                  {
                    title: 'Qtde',
                    field: 'qtde',
                    editable: 'never',
                    width: 30
                  },
                  {
                    title: <Tooltip title='Custo da nota fiscal'><span>Custo</span></Tooltip>,
                    field: 'costPrice',
                    editable: 'never',
                    width: 90,
                    render: dataRow => {
                      return (
                        <div>
                          <NumberFormatMoneyBR value={dataRow.costPrice} />
                          {dataRow.costPrice != dataRow.costPriceTotal &&
                            <>
                              <br />
                              <b>
                                <NumberFormatMoneyBR style={{ color: '#AAA' }} value={dataRow.costPriceTotal} />
                              </b>
                            </>
                          }
                        </div>
                      )
                    }
                  },
                  {
                    title: <Tooltip title='Preço atual de venda'><span>Preço Atual</span></Tooltip>,
                    editable: 'never',
                    width: 107,
                    field: 'itemDB.valor',
                    headerStyle: {
                      backgroundColor: colorDB,
                      color: '#FFF'
                    },
                    render: dataRow => (
                      <div style={{ color: colorDB }}>
                        {dataRow.id
                          ? <div>
                            <NumberFormatMoneyBR value={dataRow.itemDB?.valor} />
                          </div>
                          : <Chip label="Não encontrado" />
                        }
                      </div>
                    )
                  },
                  {
                    title: '',
                    editable: 'never',
                    hidden: !calcVenda.calc,
                    width: 25,
                    headerStyle: {
                      backgroundColor: '#fff176',
                      color: '#FFF'
                    },
                    render: dataRow => {
                      if (!dataRow?.itemDB || !dataRow?.valor) {
                        return null
                      } else if (dataRow?.itemDB?.valor > dataRow?.valor) {
                        return (<ArrowDownward style={{ color: '#FF0000' }} />)
                      }
                      return (<ArrowUpward style={{ color: '#00FF00' }} />)
                    }
                  },
                  {
                    title: <Tooltip title='Novo Preço de venda'><span>Novo Preço</span></Tooltip>,
                    editable: 'always',
                    headerStyle: {
                      backgroundColor: '#fff176'
                    },
                    width: 150,
                    hidden: !calcVenda.calc,
                    field: 'valor',
                    render: dataRow => dataRow.valor && <NumberFormatMoneyBR value={dataRow.valor} />
                  }
                ]}
                cellEditable={{
                  onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    return new Promise((resolve, reject) => {
                      const itens = estoqueEntrada.itens.map((item, idx) => {
                        if (idx === rowData?.tableData?.id) {
                          return {
                            ...item,
                            [columnDef.field]: newValue
                          }
                        }
                        return item
                      })
                      setEstoqueEntrada({ ...estoqueEntrada, itens })
                      resolve()
                    })
                  }
                }}
              />
            </Grid>
          </>
        }
      </>
    )
  }

  return (
    <div>
      <Dialog maxWidth="lg" fullWidth open>
        <Title
          title="Entrada de nota fiscal via XML"
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>
          <Grid container spacing={1} alignItems="center" justify="space-between">
            {estoqueEntrada.loading
              ? <Grid item xs><div style={{ margin: 200, textAlign: 'center' }}>Carregando Nota...</div></Grid>
              : renderNota()
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: '#212121',
                margin: '0 10px'
              }}></div>
              <span> Dados da NFe</span>
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: colorDB,
                margin: '0 10px'
              }}></div>
              <span> Dados do cadastrados</span>

            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: '#fff176',
                margin: '0 10px'
              }}></div>
              <span> Dados do editáveis</span>

            </Grid>
          </Grid>

          <Button
            color="primary"
            onClick={onSave}
            disabled={estoqueEntrada?.itens?.find(x => !x.id)}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    showMensagemConfirmacaoAction
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EntradaXMLModal)
