import React, { Component, useRef, useState } from 'react'
import axios from 'axios'
import { IconButton, Icon } from '@material-ui/core'
import { EMTable } from '@em/lib/src'
import { Parser } from 'json2csv'
import fileDownload from 'js-file-download'

import Title from '@em/lib/src/util/Title'
import AddOrEditModal from '../../_organisms/fiscal/grupoFiscal/addOrEditModal'
import AdvancedSearch from '../../_atoms/search/AdvancedSearch'
import UploadFileModalWithFeedback from './components/uploadFileModalWithFeedback'
import IconButtonDelete from '../../_atoms/button/IconButtonDelete'

const NCMContainer = ({ funcoes }) => {
  const tableRef = useRef()

  const [modalAddOrEdit, setModalAddOrEdit] = useState({ open: false })
  const [filter, setFilter] = useState({})
  const [uploadFile, setUploadFile] = useState({ open: false })

  const onBuscar = (ncm) => {
    setFilter({ ...filter, ncm })
    loadGrupoFiscal({})
  }

  const onDownload = (ncm) => {
    const newFilter = { ...filter, ncm }
    setFilter(newFilter)
    axios.get('api/grupofiscal/full', { params: newFilter }).then(response => {
      const fields = [
        {
          label: 'NCM',
          value: 'ncm',
          default: ''
        },
        {
          label: 'Descricao',
          value: 'nome',
          default: ''
        },
        {
          label: 'Cest',
          value: 'cest',
          default: ''
        },
        {
          label: 'Origem',
          value: 'icms.orig',
          default: ''
        },
        {
          label: 'ICMS CST',
          value: 'icms.cst',
          default: ''
        },
        {
          label: 'Cofins CST',
          value: 'cofins.cst',
          default: ''
        },
        {
          label: 'Cofins pCOFINS',
          value: 'cofins.pCOFINS',
          default: ''
        },
        {
          label: 'Pis CST',
          value: 'pis.cst',
          default: ''
        },
        {
          label: 'Pis pPis',
          value: 'pis.pPis',
          default: ''
        }
      ]
      const opts = { fields, delimiter: ';' }
      const parser = new Parser(opts)
      const csv = parser.parse(response)
      fileDownload(csv, 'ncm.csv')
    })
  }

  const loadGrupoFiscal = (p) => tableRef.current && tableRef.current.onQueryChange(p)

  const handleDelete = (n) => {
    axios.delete(`/api/GrupoFiscal/${n.id}`)
      .then(() => loadGrupoFiscal())
  }

  return (
    <div>
      <Title
        title="Lista de NCMs"
        buttons={[
          {
            icon: 'cloud_upload',
            color: 'primary',
            onClick: () => setUploadFile({ open: true })
          },
          {
            icon: 'add',
            onClick: () => setModalAddOrEdit({ open: true, data: {} })
          }
        ]}
      />

      <AdvancedSearch
        onDownload={onDownload}
        onBuscar={onBuscar}
        labelPrincipal="NCM"
      />

      <EMTable
        tableRef={tableRef}
        data={query => {
          const { page, pageSize } = query
          return axios.get('api/grupofiscal', {
            params: {
              ...filter,
              offset: page,
              limit: pageSize
            }
          }).then(response => {
            return {
              data: response.lista,
              page,
              totalCount: response.paginacao.qtdeRows || 0
            }
          })
        }}
        columns={[
          {
            title: 'NCM',
            field: 'ncm'
          },
          {
            title: 'Descrição',
            field: 'nome'
          },
          {
            title: 'Cest',
            field: 'cest'
          },
          {
            width: 165,
            render: dataRow => (
              <div>
                <IconButton color="primary" onClick={() => setModalAddOrEdit({ open: true, data: dataRow })}>
                  <Icon>edit_icon</Icon>
                </IconButton>
                <IconButtonDelete
                  funcoes={funcoes}
                  onClick={() => handleDelete(dataRow)}
                  perguntar
                  mensagem={(
                    <span>
                      Você realmente deseja excluir este NCM
                      <strong>
                        {' '}
                        {dataRow.ncm}
                      </strong>
                      ?
                    </span>
                  )}
                />
              </div>
            )
          }]}
      />

      {modalAddOrEdit.open && (
        <AddOrEditModal
          data={modalAddOrEdit.data}
          onHide={refresh => {
            setModalAddOrEdit({ open: false })
            if (refresh) {
              loadGrupoFiscal()
            }
          }}
        />
      )}

      {uploadFile.open &&
        <UploadFileModalWithFeedback
          onHide={refresh => {
            setUploadFile({ uploadFileModal: { open: false } })
            if (refresh === true) {
              loadGrupoFiscal()
            }
          }}
          title={'Importação de NCMs'}
          tipo={6}
        />
      }

    </div>
  )
}

export default NCMContainer
