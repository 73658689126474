import React, { Component } from 'react'
import axios from 'axios'
import LancamentoDialog from '../../../_organisms/financeiro/lancamentos/lancamentoDialog'
import ListExtrato from '../../../_organisms/financeiro/extrato/listExtrato'
import Title from '@em/lib/src/util/Title'

const tipo = 1

class DespesaContainer extends Component {
    state = {
      modalDespesas: { open: false },
      dataList: []
    }

    componentDidMount () {
      this.loadDespesas()
    }

    loadDespesas = () => axios.get(`api/financeiro/full?meusLancamentos=true&lstStatus=0&tipo=${tipo}`).then(dataList => this.setState({ dataList }))

    render () {
      const { dataList, modalDespesas } = this.state
      return (
        <div>
          <Title
            title="Minhas de despesas pendentes de aprovação"
            buttons={[
              { icon: 'add', onClick: () => this.setState({ modalDespesas: { open: true } }) }
            ]}
          />

          <ListExtrato list={dataList} />

          {modalDespesas.open &&
                    (
                      <LancamentoDialog
                        tipo={tipo}
                        onHide={refresh => this.setState({ modalDespesas: { open: false } }, () => {
                          if (refresh === true) {
                            this.loadDespesas()
                          }
                        })}
                        title="Lançamento de Despesas"
                      />
                    )}
        </div>
      )
    }
}

export default DespesaContainer
