import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { DatePicker, TextField } from '../../../_atoms'
import { TextFieldMoneyBR } from '../../../_atoms/numberFormat'

class TabLicenca extends Component {
  render () {
    const { form, handlerChange } = this.props
    return (
      <Grid container spacing={1} alignItems="center" style={{ marginTop: 20 }}>
        <Grid item xs={3}>
          <TextFieldMoneyBR
            label="Valor Mensal"
            name="valorMensal"
            value={form.valorMensal || ''}
            onValueChange={handlerChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="SistemaId"
            name="sistemaId"
            value={form.sistemaId || ''}
            onChange={handlerChange}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Data de Expiração"
            value={form.dataExpiracao}
            onChange={value => handlerChange({ target: { name: 'dataExpiracao', value } })}
          />
        </Grid>
      </Grid>
    )
  }
}

export default TabLicenca
