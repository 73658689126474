import React from 'react'
import {
  Dialog, DialogContent, CircularProgress, Grid, Typography,
} from '@material-ui/core'

const LoadingModal = () => (
  <Dialog open maxWidth="lg" fullScreen>
    <DialogContent style={{
      padding: 50, backgroundColor: 'rgb(10,10,99,0.9)', alignItems: 'center', display: 'flex',
    }}
    >
      <Grid container spacing={5} alignItems="center" direction="column" justify="center">
        <Grid item>
          <CircularProgress color="primary" size={100} />
        </Grid>
        <Grid item>
          <Typography color="primary">Carregando...</Typography>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
)

export default LoadingModal
