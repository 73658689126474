import React, { useState } from 'react'
import {
  InputAdornment, IconButton, Popover, Icon
} from '@material-ui/core'
import { isNullOrUndefined } from 'util'
import { ChromePicker } from 'react-color'
import TextField from './TextField'

const TextFieldColor = props => {
  const [anchorEl, setAnchorEl] = useState(false)
  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={e => setAnchorEl(e.currentTarget)}
                style={{ marginRight: -5, padding: 10, backgroundColor: isNullOrUndefined(props.value) ? 'transparent' : props.value }}
              ><Icon>colorize</Icon></IconButton>
            </InputAdornment>
          )
        }}
        variant="filled"
        fullWidth
        {...props}
        value={isNullOrUndefined(props.value) ? '' : props.value}
      />

      <Popover
        id={`popover-${props.id}`}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ChromePicker
          color={isNullOrUndefined(props.value) ? '' : props.value}
          onChangeComplete={e => props.onChange({ target: { name: props.name, value: e.hex } })}
        />
      </Popover>
    </>
  )
}

export default TextFieldColor
