import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Icon,
  IconButton
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import Title from '@em/lib/src/util/Title'
import FeedBackListModal from './feedbackListModal'
import Axios from 'axios'

const inputFileRef = React.createRef(null)

const csv = require('csvtojson')

const fieldItem = [
  {
    label: 'NCM',
    value: 'ncm'
  },
  {
    label: 'Descricao',
    value: 'nome'
  },
  {
    label: 'Cest',
    value: 'cest'
  },
  {
    label: 'Origem',
    value: 'icms.orig'
  },
  {
    label: 'ICMS CST',
    value: 'icms.cst'
  },
  {
    label: 'Cofins CST',
    value: 'cofins.cst'
  },
  {
    label: 'Cofins pCOFINS',
    value: 'cofins.pCOFINS'
  },
  {
    label: 'Pis CST',
    value: 'pis.cst'
  },
  {
    label: 'Pis pPis',
    value: 'pis.pPis'
  },
  {
    label: 'Feedback',
    value: 'feedback'
  }
]

function UploadFileModal ({
  title,
  onHide
}) {
  const [file, setFile] = useState(null)
  const [key, setKey] = useState(new Date().getTime())
  const [showError, setShowError] = useState(false)
  const [listItemsImport, setListItemsImport] = useState([])
  const [feedbackListModal, setFeedbackListModal] = useState({ open: false })
  const [validColums, setValidColumns] = useState([])
  const [invalidColums, setInvalidColumns] = useState([])
  const [updateItems, setUpdateItems] = useState(false)

  const handleChange = e => {
    setFile(e.target.files[0])
    setKey(new Date().getTime())
  }

  const parseCSV = (string, x, y) => {
    csv({
      delimiter: [';']
    })
      .fromString(string)
      .then((json) => {
        setValidColumns(_.keys(json.find(x => x)).filter(key => fieldItem.map(x => x?.label).includes(key)))
        setInvalidColumns(_.keys(json.find(x => x)).filter(key => !fieldItem.map(x => x?.label).includes(key)))
        const lista = json.map(item => {
          let newData = {}
          _.keys(item).forEach(key => {
            newData = {
              ...newData,
              [fieldItem?.find(x => x.label === key)?.value]: item[key]
            }
          })
          return newData
        })
        setListItemsImport(lista)
        setFeedbackListModal({ open: true })
      })
  }

  const importar = async () => {
    if (!file) {
      setShowError(true)
      return null
    }
    var reader = new FileReader()
    reader.onload = (function (file) {
      return function (e) {
        parseCSV(e.target.result, '\n', ';')
      }
    })(file)

    reader.readAsText(file)
  }

  const importConfirmed = () => {
    const data = listItemsImport.map(item => {
      return {
        icms: {
          orig: item['icms.orig'],
          cst: item['icms.cst']
        },
        cofins: {
          cst: item['cofins.cst'],
          pCOFINS: item['cofins.pCOFINS']
        },
        pis: {
          cst: item['pis.cst'],
          pPis: item['pis.pPis']
        },
        ..._.omit(
          item,
          [
            ...invalidColums,
            'tableData',
            'icms.orig',
            'icms.cst',
            'cofins.cst',
            'cofins.pCOFINS',
            'pis.cst',
            'pis.pPis'
          ]
        )
      }
    })

    Axios.post(`/api/GrupoFiscal/bulk?update=${updateItems}`, data)
      .then(resp => {
        setValidColumns([...validColums, 'Feedback'])
        const newListImport = listItemsImport.map((item, idx) => {
          const feedback = resp?.find(x => x.index === idx)
          let messageFeedback = ''
          if (feedback.feedback === 0) {
            messageFeedback = 'Erro no registro: \n' + feedback?.feedbackError
          } else if (feedback.feedback === 1) {
            messageFeedback = 'Registro criado com sucesso!'
          } else if (feedback.feedback === 2) {
            messageFeedback = 'Registro alterado com sucesso!'
          } else if (feedback.feedback === 3) {
            messageFeedback = 'Registro já existe. Não pode ser criado!'
          }
          return { ...item, feedback: { status: feedback.feedback, messageFeedback } }
        })

        setListItemsImport(newListImport)
        setFeedbackListModal({ open: true, feedbackApi: true })
      })
  }

  return (
    <Dialog open maxWidth={'md'} fullWidth>
      <Title
        title={title || 'Importação'}
        buttons={[
          {
            icon: 'close',
            color: 'secondary',
            onClick: onHide
          }
        ]}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={updateItems ? 6 : 12}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={updateItems} onClick={e => setUpdateItems(e.target.checked)} />}
              label={'Somente atualizar itens existentes na base'}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                width: '100%',
                height: '100%',
                border: `1px solid ${!file && showError ? '#c00000' : '#ccc'}`,
                borderRadius: 4,
                backgroundColor: !file && '#eee',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: !file && '#ccc',
                position: 'relative',
                cursor: 'pointer'
              }}
              onClick={() => !file && inputFileRef.current.click()}
            >
              {file
                ? <img
                  src={require('./../../../assets/img/csv.png')}
                  height="50px"
                  width="50px"
                />
                : <Icon style={{ fontSize: 50 }}>
                  cloud_upload
                </Icon>
              }
              <center style={{ height: 40 }}>{file ? file.name : 'Clique aqui para anexar um arquivo'}</center>
              {file &&
                <IconButton
                  onClick={() => setFile(null)}
                  size="small"
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              }
            </div>
          </Grid>
        </Grid>
        {feedbackListModal.open &&
          <FeedBackListModal
            onHide={() => {
              setFeedbackListModal({ open: false })
              if (feedbackListModal.feedbackApi) {
                onHide(true)
              }
            }}
            listItemsImport={listItemsImport}
            validColums={validColums}
            invalidColums={invalidColums}
            setListItemsImport={setListItemsImport}
            importConfirmed={importConfirmed}
            feedbackApi={feedbackListModal.feedbackApi}
            fieldItem={fieldItem}
          />
        }
      </DialogContent>
      <DialogActions>
        <input
          ref={inputFileRef}
          key={key}
          type="file"
          accept=".csv"
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={importar}
        >
          Importar
        </Button>
      </DialogActions>
    </Dialog >
  )
}

export default UploadFileModal
