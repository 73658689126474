import React, { useState } from 'react'
import Title from '@em/lib/src/util/Title'

import AddOrEditModal from './LayoutsDeImpressao/addOrEditModal'
import List from './LayoutsDeImpressao/list'
import Axios from 'axios'
import { loadApiEMTable } from '@em/lib/src/EMTable/materialTableHelper'

const tableRef = React.createRef()

function LayoutsDeImpressao ({
  funcoes
}) {
  const [addOrEditModal, setAddOrEditModal] = useState({
    open: false
  })

  const loadLayouts = (data) => loadApiEMTable(tableRef, data)

  const deleteLayout = layout => Axios.delete(`/api/PrintLayout/${layout?.id}`).then(() => loadLayouts({ isDelete: true }))

  return (
    <div>
      <Title
        title="Layouts de Impressão"
        buttons={[
          {
            icon: 'add',
            onClick: () => setAddOrEditModal({ open: true })
          }
        ]}
      />

      <List
        tableRef={tableRef}
        openModal={data => setAddOrEditModal({ open: true, data })}
        funcoes={funcoes}
        onDelete={deleteLayout}
      />

      {addOrEditModal?.open &&
        <AddOrEditModal
          onHide={refresh => {
            setAddOrEditModal({ open: false })
            if (refresh === true) {
              loadLayouts()
            }
          }}
          data={addOrEditModal?.data}
        />
      }
    </div>
  )
}

export default LayoutsDeImpressao
