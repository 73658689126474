import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { Avatar, Grid, Icon, IconButton } from '@material-ui/core'
import CriarEditarPessoaDialog from '../../_organisms/pessoa/criarEditar/criarEditarPessoaDialog'
import LoginDialog from '../../_organisms/pessoa/criarEditar/loginDialog'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import { ReactTable, TextField } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import { AdvancedSearch } from '../../_atoms/search'
import { EMTable } from '@em/lib/src'

const tableRef = React.createRef()


const ListarFornecedores = (props) => {
  const [modalEditarPessoa, setModalEditarPessoa] = useState({ open: false })
  const [modalEditarUsuario, setModalEditarUsuario] = useState({ open: false })
  const [filter, setFilter] = useState({ busca: '', reload: true })

  const onDelete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {deleted.nome}
          </strong>
?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => deleteConfirmed(deleted) }
      ]
    }
    props.showMensagemConfirmacaoAction(model)
  };

  const deleteConfirmed = deleted => {
    axios.delete(`api/Pessoa/${deleted.id}`).then(() => {
      loadFornecedores()
    })
  };

  const loadFornecedores = (data) => tableRef.current && tableRef.current.onQueryChange(data)

  useEffect(() => {
    if (filter.reload) {
      loadFornecedores({ page: 0 })
    }
  }, [filter])

  return (
    <div>
      <Title
        title="Lista de Fornecedores"
        buttons={[
          {
            icon: 'add',
            color: 'primary',
            onClick: () => setModalEditarPessoa({ open: true })
          }
        ]}
      />

      <AdvancedSearch
        onBuscar={(nome) => {
          setFilter({ ...filter, nome, reload: true })
        }}
        labelPrincipal="Nome"
      >
        <Grid container>
          <Grid item>
            <TextField
              label="IE"
              value={filter.ie}
              onChange={(e) => {
                setFilter({ ...filter, ie: e.target.value, reload: false })
              }}
            />
          </Grid>
        </Grid>
      </AdvancedSearch>

      <EMTable
        tableRef={tableRef}
        data={query => {
          const { page, pageSize } = query

          return axios.get('/api/pessoa', {
            params: {
              ...filter,
              offset: page,
              limit: pageSize,
              contexto: 2
            }
          }).then(response => {
            return {
              data: response.lista,
              page,
              totalCount: response?.paginacao?.qtdeRows || 0
            }
          })
        }}
        columns={[
          {
            width: 60,
            field: 'foto',
            render: dataRow => <Avatar
              variant="rounded" style={{ textAlign: 'center' }}
              src={global.bucket + dataRow.foto}
            />
          },
          {
            title: 'Nome',
            field: 'nome'
          },
          {
            title: 'CPF/CNPJ',
            field: 'cpfCnpj'
          },
          {
            title: 'IE',
            field: 'ie'
          },
          {
            width: 200,
            render: dataRow => (
              <div>
                <IconButton onClick={() => setModalEditarUsuario({ open: true, data: dataRow })} color="primary">
                  <Icon>person</Icon>
                </IconButton>
                <IconButton
                  onClick={() => setModalEditarPessoa({ open: true, data: dataRow })}
                  color="primary"
                >
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => onDelete(dataRow)} color="secondary">
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )
          }
        ]}
      />

      {modalEditarPessoa.open && (
        <CriarEditarPessoaDialog
          contexto={2}
          onSave={() => {
            loadFornecedores()
            setModalEditarPessoa({ open: false })
          }}
          onHide={() => setModalEditarPessoa({ open: false })}
          data={modalEditarPessoa.data}
        />
      )}

      {modalEditarUsuario.open && (
        <LoginDialog
          onSave={() => {
            loadFornecedores()
            setModalEditarUsuario({ open: false })
          }}
          onHide={() => setModalEditarUsuario({ open: false })}
          data={modalEditarUsuario.data}
        />
      )}

    </div>
  )
}

ListarFornecedores.propTypes = {
  history: PropTypes.object.isRequired,
  showMensagemConfirmacaoAction: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListarFornecedores)
