import React, { useEffect, useState } from 'react'
import Title from '@em/lib/src/util/Title'
import Sessions from '../site/components/sessions'
import AddOrEditPageModal from '../site/components/addOrEditPageModal'
import { INITIAL_FORM } from '../site/components/addOrEditModal'
import Axios from 'axios'

export default (props) => {
  const [form, setForm] = useState({ ...INITIAL_FORM })
  const [modalAddOrEdit, setModalAddOrEdit] = useState({})

  const loadComponentes = () => {
    Axios.get('api/sitecomponente').then(resp => {
      setForm({ ...form, pages: resp.lista })
    })
  }

  const onDelete = item => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {item.nome}
          </strong>
?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => deleteConfirmed(item) }
      ]
    }
    props.showMensagemConfirmacaoAction(model)
  }

  const deleteConfirmed = forma => {
    Axios.delete(`api/sitecomponente/${forma.id}`).then(response => {
      loadComponentes()
    })
  }

  useEffect(() => {
    loadComponentes()
  }, [])

  const onHideSave = (page, idx) => {
    if (page) {
      Axios.post('api/sitecomponente', page).then(resp => {
        loadComponentes()
      })
    }
    setModalAddOrEdit({ open: false })
  }

  // const onDelete = (row) =>{
  //   form.pages.splice(row.tableData.id, 1)
  //   setForm({ ...form, pages: form.pages })
  // }

  return (
    <div>

      <Title
        title="Cadastro de Componentes"
        buttons={[
          {
            icon: 'add',
            onClick: () => setModalAddOrEdit({ open: true, idx: -1 })
          }
        ]}
      />

      <Sessions
        form={form}
        setForm={setForm}
        setModalAddOrEdit={setModalAddOrEdit}
        onDelete={onDelete}
      />

      {modalAddOrEdit.open && (
        <AddOrEditPageModal
          open={modalAddOrEdit.open}
          data={modalAddOrEdit.data}
          onHide={(p) => onHideSave(p, modalAddOrEdit.idx)}
          title="Componente"
          okText={'Salvar'}
        />
      )}

    </div>
  )
}
