import _ from 'lodash'
import moment from 'moment'

const layoutFechamentoCaixa = (caixa, paymentList) => {
  const lines = [
    { value: 'Extrato: de Fechamento de caixa' },
    { value: `Caixa: ${caixa?.numero}` },
    { value: `Abertura do caixa: ${moment(caixa?.data).format('DD/MM/YYYY HH:mm')}` },
    { value: `Operador: ${caixa?.operador?.nome}` }
  ]

  lines.push({ value: ' ' })

  paymentList.forEach(e => {
    lines.push({
      value: `${e?.nome} ${e?.valor}`
    })
  })

  lines.push({
    value: `Valor Total: ${_.sumBy(paymentList, 'valor')}`
  })

  lines.push({ top: 20, value: '______________________________________________________________' })
  lines.push({ value: caixa?.operador?.nome })
  lines.push({ value: `Data da impressão: ${moment().format('DD/MM/YYYY HH:mm')}` })

  return lines
}

export default layoutFechamentoCaixa
