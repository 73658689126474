import {
  getCenter,
  getBreakLines,
  getSpaceBetween,
  getLinesByWords
} from '@em/lib/src/Printers/helpers'
import moment from 'moment'
import CANAIS from './../../../contstants/canais'

const lineDefaultLucida = {
  fontSize: 12,
  fontFamily: 'Lucida Console',
  type: 0
}

const getMoneyFormat = (valor) => {
  return Number(valor).toFixed(2).replace('.', ',')
}

const layoutMDogao = (pedido, config) => {
  const lines = []

  let _canal = CANAIS.find(c => c?.id === pedido?.canal)?.nome

  if (_canal === 'Loja') {
    if (pedido.mesas) {
      _canal = `Mesa: ${pedido.mesas}`
    } else {
      _canal = 'Viagem'
    }
  }

  lines.push({
    ...lineDefaultLucida,
    value: getCenter(_canal, 18),
    fontStyle: 1,
    fontSize: 18
  })
  lines.push({
    ...lineDefaultLucida,
    value: getCenter(`Pedido: ${pedido?.numero}`, 18),
    fontStyle: 1,
    fontSize: 18
  })

  lines.push(
    {
      value: getCenter(`OP: ${pedido?.seller?.name}`, 9),
      ...lineDefaultLucida,
      fontSize: 9
    }
  )

  lines.push({
    ...lineDefaultLucida,
    value: getCenter(`${moment(pedido.dataCadastro).format('DD/MM/YYYY HH:mm')}`, 9),
    fontSize: 9
  })

  if (pedido?.cliente) {
    lines.push({
      ...lineDefaultLucida,
      value: '-----------------------------------------------------------------'
    })
    lines.push.apply(lines, getBreakLines({
      ...lineDefaultLucida,
      value: `Cliente: ${pedido?.cliente?.nome || ''}`,
      fontStyle: 1
    }, 12))
    if (pedido?.cliente?.telefone) {
      lines.push({
        ...lineDefaultLucida,
        value: `Tel: ${pedido?.cliente?.telefone || ''}`,
        fontSize: 10,
        fontStyle: 1
      })
    }
    if (pedido?.cliente?.cpf) {
      lines.push({
        ...lineDefaultLucida,
        value: `CPF: ${pedido?.cliente?.cpf || ''}`,
        fontSize: 10,
        fontStyle: 1
      })
    }
  }

  if (pedido?.endereco && pedido?.canal) {
    getLinesByWords(`Endereço: ${pedido?.endereco?.rua || ''}, ${pedido?.endereco?.numero || ''}`, 33)
      .forEach(line => {
        lines.push({
          ...lineDefaultLucida,
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    getLinesByWords(`Bairro: ${pedido?.endereco?.bairro || ''}`, 33)
      .forEach(line => {
        lines.push({
          ...lineDefaultLucida,
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    getLinesByWords(`Cidade: ${pedido?.endereco?.cidade || ''} - ${pedido?.endereco?.estado || ''}`
      , 33)
      .forEach(line => {
        lines.push({
          ...lineDefaultLucida,
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    lines.push({
      ...lineDefaultLucida,
      value: `CEP: ${pedido?.endereco?.cep || ''}`,
      fontSize: 10,
      fontStyle: 1
    })
    getLinesByWords(`Complemento: ${pedido?.endereco?.complemento || ''}`, 33)
      .forEach(line => {
        lines.push({
          ...lineDefaultLucida,
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    getLinesByWords(`Referência: ${pedido?.endereco?.referencia || ''}`, 33)
      .forEach(line => {
        lines.push({
          ...lineDefaultLucida,
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
  }

  lines.push({
    value: ' ',
    type: 0
  })
  lines.push({
    ...lineDefaultLucida,
    value: '-----------------------------------------------------------------'
  })
  lines.push({
    value: ' ',
    type: 0
  })

  const itens = pedido?.itens?.map(item => {
    const newMontagens = item?.montagens?.filter(m => !!(m.opcoes?.find(opt => opt?.qtde && opt?.qtde !== 0)))
    return { ...item, montagens: newMontagens }
  })

  if (itens?.length > 0) {
    itens?.forEach(item => {
      const itemNome = item.nome.trim()
      const itemnomeWords = itemNome.split(' ')
      let line1 = ''
      let line2 = ''
      itemnomeWords.forEach(word => {
        if ((line1.length + word.length) < 18) {
          line1 += `${word} `
        } else {
          line2 += `${word} `
        }
      })
      lines.push(
        {
          ...lineDefaultLucida,
          value: getSpaceBetween(`${item?.qtde}x ${line1}`, `${getMoneyFormat(item?._valorTotal || item?.valorTotal || 0)}`, 12),
          fontStyle: 1
        })

      if (line2) {
        lines.push(
          {
            ...lineDefaultLucida,
            value: line2,
            fontStyle: 1
          }
        )
      }

      if (item.viagem || item.observacao) {
        lines.push(
          {
            value: ' ',
            type: 0
          },
          {
            value: ' *Observação:' + (item.viagem ? ' Para Viagem' : ''),
            fontStyle: 1,
            fontSize: 11,
            fontFamily: 'Lucida Console',
            type: 0
          }
        )
        if (item.observacao) {
          lines.push(
            ...getBreakLines(
              {
                value: `  ${item.observacao}`,
                fontSize: 10,
                fontFamily: 'Lucida Console',
                type: 0
              }, 10)
          )
        }
      }

      item?.montagens?.forEach(m => {
        if (m?.opcoes?.length > 0) {
          lines.push(
            {
              value: ' ',
              type: 0
            },
            {
              ...lineDefaultLucida,
              value: ` *${m.titulo}:`,
              fontStyle: 1
            }
          )
          m?.opcoes?.forEach(opt => {
            const optNome = opt.nome.trim()
            if (opt?.qtde < 0) {
              lines.push(
                {
                  ...lineDefaultLucida,
                  value: `  ${opt?.qtde}x ${optNome}`,
                  fontSize: 11
                }
              )
            } else if (opt?.qtde > 0) {
              lines.push(
                {
                  ...lineDefaultLucida,
                  value: `   ${opt.qtde + opt?.incluso}x ${opt.nome}`,
                  fontSize: 11
                }
              )
            }
          })
        }
      })
      lines.push(
        {
          value: ' ',
          type: 0
        },
        {
          value: ' ',
          type: 0
        }
      )
    })
  }

  if (pedido?.observacao) {
    lines.push({
      ...lineDefaultLucida,
      value: '-- Observação do Pedido --',
      fontStyle: 1
    })

    lines.push({
      value: ' ',
      type: 0
    })

    getLinesByWords(`${pedido?.observacao || ''}`)
      .forEach(text => {
        lines.push({
          ...lineDefaultLucida,
          value: text,
          fontSize: 10,
          fontStyle: 1
        })
      })

    lines.push({
      value: '---------------------------------------------------',
      ...lineDefaultLucida
    })
  }

  lines.push({
    value: ' ',
    type: 0
  })

  if (!config?.hideTotal) {
    lines.push(
      {
        ...lineDefaultLucida,
        value: '-----------------------------------------------------------------'
      },
      {
        value: ' ',
        type: 0
      },
      {
        ...lineDefaultLucida,
        value: getSpaceBetween('Descontos: ', `${getMoneyFormat(pedido?.discount || 0)}`, 12)
      },
      {
        value: ' ',
        type: 0
      },
      {
        ...lineDefaultLucida,
        value: getSpaceBetween('SubTotal: ', `${getMoneyFormat(pedido?._valor || pedido?.valor || 0)}`, 12)
      },
      {
        value: ' ',
        type: 0
      },

      {
        ...lineDefaultLucida,
        value: getSpaceBetween('Total: ', `${getMoneyFormat(pedido?._valorTotal || pedido?.valorTotal || 0)}`, 12),
        fontStyle: 1
      },
      {
        value: ' ',
        type: 0
      }
    )

    let valorTotalPago = 0

    pedido?.pagamentos?.forEach(item => {
      valorTotalPago += Number(item.valor || 0)
      lines.push(
        {
          ...lineDefaultLucida,
          value: getSpaceBetween(`${item.nome}:`, `${getMoneyFormat(item.valor || 0)}`, 12),
          fontStyle: 1
        }
      )
    })

    const troco = valorTotalPago - Number(pedido?._valorTotal || pedido?.valorTotal || 0)

    lines.push(
      {
        ...lineDefaultLucida,
        value: getSpaceBetween(`${troco < 0 ? 'Falta Pagar' : 'Troco'}: `, `${getMoneyFormat(troco < 0 ? (troco * -1) : troco)}`, 12),
        fontStyle: 1
      }
    )
  }

  lines.push(
    {
      value: ' ',
      type: 0
    },
    {
      value: '-----------------------------------------------------------------',
      type: 0
    },
    {
      value: ' ',
      type: 0
    },
    {
      ...lineDefaultLucida,
      value: getCenter('Obrigado, esperamos que aproveite', 9),
      fontSize: 9
    },
    {
      ...lineDefaultLucida,
      value: getCenter('a sua refeição.', 9),
      fontSize: 9
    },
    {
      value: ' ',
      type: 0
    }
  )

  return lines
}

export default layoutMDogao
