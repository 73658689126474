import React, { useState } from 'react'
import {
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List
} from '@material-ui/core'
import { GiReceiveMoney } from 'react-icons/gi'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

function SellersCollapse ({
  vendedores,
  pedido,
  onRefreshPedido
}) {
  const [changeVendedor, setChangeVendedor] = useState({ open: false })
  const sellersExceptMe = vendedores?.filter(v => v?.id !== pedido?.seller?.id)
  return (
    <>
      <ListItem
        button
        disabled={sellersExceptMe?.length === 0}
        onClick={() => null}
      >
        <ListItemIcon>
          <Avatar src={global.bucket + pedido?.seller?.foto}>
            <GiReceiveMoney size="32px" />
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={pedido?.seller?.name} secondary="Vendedor Atual" />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => setChangeVendedor({ ...changeVendedor, open: !changeVendedor.open })}
            disabled={sellersExceptMe?.length === 0}
          >
            {changeVendedor.open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={changeVendedor.open} timeout="auto" unmountOnExit>
        <List style={{ marginLeft: 15, padding: 0 }}>
          {sellersExceptMe?.map(v => {
            return (
              <ListItem
                key={v?.id}
                button
                onClick={() => onRefreshPedido({
                  ...pedido,
                  seller: {
                    id: v?.id,
                    name: v?.nome,
                    foto: v?.foto
                  }
                })}
              >
                <ListItemIcon>
                  <Avatar src={global.bucket + v.foto}>
                    <GiReceiveMoney size="32px" />
                  </Avatar>
                </ListItemIcon>
                <ListItemText primary={v?.nome} secondary={'Vendedor'} />
              </ListItem>
            )
          })}
        </List>
      </Collapse>
    </>
  )
}

export default SellersCollapse
