import Title from '@em/lib/src/util/Title'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'

function PayCheckModal ({
  onHide,
  cupom,
  buy
}) {
  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={'Confirme sua compra'}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Typography color="primary">
          <b>Quantidade</b>: {cupom?.qtde} cupons<br />
          <b>Prefixo do Cupom</b>: {cupom?.prefixo}<br />
          <b>Tipo de Desconto</b>: {!cupom?.percentualValor ? 'Percentual' : 'Valor Fixo'}<br />
          <b>Desconto</b>: {cupom?.desconto} {!cupom?.percentualValor ? '%' : 'reais'}<br />
          <b>Data de Expiração</b>: {moment(cupom?.dataExpiracao).format('DD/MM/YYYY')}<br />
          <b>Regras de Uso</b>: {cupom?.regras}<br />
          <b>Forma de Pagamento</b>: {cupom?.pagamento?.nome}<br />
        </Typography>
        <Typography color="secondary" style={{ fontSize: 20 }}>
          <b>Valor total da compra:</b> R$ {Number(cupom?.pagamento?.valor).toFixed(2)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => buy(true)}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PayCheckModal
