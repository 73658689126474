import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  Icon,
  IconButton
} from '@material-ui/core'
import React, { useState } from 'react'
import { PrintSimulator } from '@em/lib/src'
import { pullAt, cloneDeep } from 'lodash'

import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'
import Axios from 'axios'
import { formatLines } from '@em/lib/src/Printers/helpers'
import layoutDefault from '../../pdv/impressaoLayouts/layoutDefault'
import { useEffect } from 'react'

const typeLines = [
  { id: '0', name: 'Texto' },
  { id: 'products', name: 'Produtos' },
  { id: '1', name: 'Imagem base64' }
]

const fontSizes = [
  {
    id: 6,
    name: '6',
    cols: 55
  },
  {
    id: 7,
    name: '7',
    cols: 46
  },
  {
    id: 8,
    name: '8',
    cols: 41
  },
  {
    id: 9,
    name: '9',
    cols: 36
  },
  {
    id: 10,
    name: '10',
    cols: 32
  },
  {
    id: 11,
    name: '11',
    cols: 30
  },
  {
    id: 12,
    name: '12',
    cols: 26
  }
]

const aligns = [
  { id: 'left', name: 'Esquerda' },
  { id: 'center', name: 'Centralizado' },
  { id: 'right', name: 'Direita' }
]

const emptyFormLine = {
  type: '0',
  fontSize: '8',
  value: 'Texto Exemplo',
  fontStyle: '0',
  top: '0',
  left: '0',
  align: 'left',
  fontFamily: 'Lucida Console'
}

const fontStyles = [
  {
    id: '0',
    name: 'Regular'
  },
  {
    id: '1',
    name: 'Bold'
  },
  {
    id: '2',
    name: 'Itálica'
  },
  {
    id: '4',
    name: 'Underline'
  },
  {
    id: '8',
    name: 'Strikeout'
  }
]

const pedidoMock = {
  id: '6071d3000e066b00015665be',
  numero: 1085,
  canal: 0,
  statusCaixa: 1,
  statusEntrega: 0,
  statusEntregaId: null,
  statusEntregaLista: null,
  operador: {
    id: '5b6384392707c66b5d232a12',
    nome: 'Nome do Operador'
  },
  trancadoPara: null,
  faturamento: null,
  cliente: {
    id: '5d1ab0d60fb4260001ac8841',
    nome: 'PEDIDO DE TESTE',
    cpf: null,
    email: null,
    telefone: '0800 007 0110 ID: 38674622',
    entidade: null
  },
  endereco: {
    conectorEnvioId: null,
    rua: 'PEDIDO DE TESTE - NÃO ENTREGAR - R TESTE',
    numero: '124',
    bairro: 'OUTROS',
    cidade: 'BUJARI',
    estado: null,
    pais: null,
    coordenadas: null,
    cep: '12345678',
    referencia: 'Riozinho',
    complemento: null
  },
  entregador: null,
  dataCadastro: '2021-04-10T16:32:00.803Z',
  dataCompetencia: '2021-04-10T16:32:00.803Z',
  dataAtualizacao: '0001-01-01T00:00:00Z',
  valorFrete: null,
  valor: 74,
  discountAmount: 0,
  valorTaxa: null,
  valorTotal: 74,
  itens: [
    {
      id: '5ed6868551abf300015c2ebe',
      codigo: '03',
      ean: null,
      unidadeMedida: 'Un',
      nome: 'Item 1',
      foto: null,
      valor: 6,
      valorAux: 0,
      valorTotal: 6,
      discountAmount: null,
      discountAmountAux: null,
      minAmount: null,
      viagem: false,
      qtde: 1,
      peso: 0,
      observacao: null,
      tax: null,
      montagens: [
        {
          titulo: 'Montagem 1',
          minimo: 1,
          maximo: 3,
          opcoes: [
            {
              id: '5ee1266fe9f9f1000118ae2e',
              nome: 'opcao 1',
              incluso: 0,
              minimo: 0,
              maximo: 3,
              valor: 2,
              qtde: 1
            },
            {
              id: '5ee123e2e9f9f1000118ae2c',
              nome: 'opcao 2',
              incluso: 0,
              minimo: 0,
              maximo: 3,
              valor: 3,
              qtde: 1
            }
          ]
        }
      ],
      impressoras: null
    },
    {
      id: '5cc7c253ccb00f00011a5d15',
      codigo: '01',
      ean: '7898311766356',
      unidadeMedida: 'Un',
      nome: 'Item 2',
      foto: 'files/1/637207433230349177_light-bulb.png',
      valor: 22,
      valorAux: null,
      valorTotal: 22,
      discountAmount: null,
      discountAmountAux: null,
      minAmount: null,
      viagem: false,
      qtde: 1,
      peso: 0,
      observacao: null,
      tax: null,
      montagens: [
        {
          titulo: 'Montagem 2',
          minimo: 1,
          maximo: 3,
          opcoes: [
            {
              id: '5ee123e2e9f9f1000118ae2c',
              nome: 'Opcao 1',
              incluso: 1,
              minimo: 0,
              maximo: 1,
              valor: 2,
              qtde: 0
            },
            {
              id: '5ed6da2251abf300015c2efe',
              nome: 'Opcao 2',
              incluso: 0,
              minimo: 0,
              maximo: 1,
              valor: 0,
              qtde: 0
            }
          ]
        }
      ],
      impressoras: [
        {
          codigo: null,
          ip: null,
          modeloId: null,
          nome: 'Sala'
        }
      ]
    },
    {
      id: '5cc7c253ccb00f00011a5d15',
      codigo: '01',
      ean: '7898311766356',
      unidadeMedida: 'Un',
      nome: 'Item 3',
      foto: 'files/1/637207433230349177_light-bulb.png',
      valor: 20,
      valorAux: null,
      valorTotal: 20,
      discountAmount: null,
      discountAmountAux: null,
      minAmount: null,
      viagem: false,
      qtde: 1,
      peso: 0,
      observacao: null,
      tax: null,
      montagens: [
        {
          titulo: 'REMOVER',
          minimo: 1,
          maximo: 1,
          opcoes: [
            {
              id: '5ee123e2e9f9f1000118ae2c',
              nome: 'Opcao 1',
              incluso: 1,
              minimo: 0,
              maximo: 1,
              valor: 2,
              qtde: -1
            }
          ]
        }
      ],
      impressoras: [
        {
          codigo: null,
          ip: null,
          modeloId: null,
          nome: 'Sala'
        }
      ]
    }
  ],
  pagamentos: [
    {
      id: '5c07f390fe473a589c81240b',
      nome: 'Dinheiro',
      financeira: null,
      financeiraId: null,
      staus: null,
      status: null,
      valor: 48,
      metodo: 0,
      referencia: null
    }
  ],
  observacao: null,
  mesas: '12',
  financeiroId: null,
  devolucao: null,
  referencia: null,
  cFe: null,
  seller: {
    id: '5b6384392707c66b5d232a12',
    name: 'Vendedor'
  }
}

const fontsFamily = [
  { id: 'Lucida Console', name: 'Lucida Console' },
  { id: 'Courier New', name: 'Courier New' },
  { id: 'Courier', name: 'Courier' },
  { id: 'Courier New Bold', name: 'Courier New Bold' }
]

function AddOrEditModal({
  data,
  onHide
}) {
  const [form, setForm] = useState({ name: '', lines: [], ...data })
  const [layout, setLayout] = useState([])
  const [drawer, setDrawer] = useState({ open: false, data: null, idx: null })

  useEffect(() => {
    (async () => {
      const l = await formatLines(pedidoMock, form?.lines)
      setLayout(l)
    })()
  }, [form.lines])

  const handleChangeLine = (e, idx) => {
    const lines = form?.lines?.map((line, index) => {
      if (idx === index) {
        return {
          ...line,
          value: e.target.value === 'products' ? '' : line?.value,
          [e.target.name]: e.target.value?.toString()
        }
      }
      return line
    })
    setForm({ ...form, lines })
  }

  const handleDeleteLine = idx => {
    const lines = cloneDeep(form?.lines)
    pullAt(lines, idx)
    setForm({ ...form, lines })
  }

  const save = () => {
    Axios.post('/api/PrintLayout', form)
      .then(resp => {
        onHide(true)
      })
  }

  const formLine = (line, index) => {
    return (
      <Grid item xs={12} container spacing={1} alignItems="center">
        {/* <Grid item>
          <div>{index + 1}ª</div>
        </Grid> */}
        <Grid item>
          <ComboBoxData
            label="Tipo"
            onChange={e => handleChangeLine({ target: { name: 'type', value: e.id } }, index)}
            selected={line?.type}
            nameText={'name'}
            nameValue={'id'}
            list={typeLines}
          />
        </Grid>
        <Grid item>
          <ComboBoxData
            label="Tamanho da Fonte"
            onChange={e => handleChangeLine({ target: { name: 'fontSize', value: e.id } }, index)}
            selected={line?.fontSize}
            nameText={'name'}
            nameValue={'id'}
            list={fontSizes}
            disabled={line?.type === 'products' || line?.type === '1'}
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Texto/Valor"
            onChange={e => handleChangeLine({ target: { name: 'value', value: e.target.value } }, index)}
            value={line?.value}
            disabled={line?.type === 'products'}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => setDrawer({ open: true, idx: index })}
            disabled={line?.type === 'products'}
          >
            <Icon>settings</Icon>
          </IconButton>
          <IconButton onClick={() => handleDeleteLine(index)}>
            <Icon>delete</Icon>
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog fullWidth maxWidth={'lg'} open>
      <Title
        title={`${data?.id ? 'Editar' : 'Criar'} Layout de Impressão`}
        buttons={[
          {
            icon: 'close',
            color: 'secondary',
            onClick: onHide
          }
        ]}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8}>
            <Grid item xs={12}>
              <TextField
                label="Nome do Layout"
                onChange={e => setForm({ ...form, name: e.target?.value })}
                value={form?.name}
              />
            </Grid>
            <Title
              title={'Adicione Linhas ao Layout'}
              buttons={[
                {
                  icon: 'add',
                  color: 'primary',
                  onClick: () => setForm({ ...form, lines: [...form?.lines, emptyFormLine] })
                }
              ]}
            />

            {form?.lines?.map((line, idx) => formLine(line, idx))}
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <h2>Prévia do Layout</h2>
            <PrintSimulator
              // key={new Date()}
              layout={layout}
            />
          </Grid>
        </Grid>
        <Drawer
          anchor={'left'}
          open={drawer?.open}
          onClose={() => setDrawer({ open: false, idx: null })}
          PaperProps={{
            style: {
              maxWidth: 300
            }
          }}
        >
          <div style={{ padding: 10 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h2>Configuração</h2>
              </Grid>
              <Grid item xs={12}>
                <ComboBoxData
                  label="Família da Fonte"
                  onChange={e => handleChangeLine({ target: { name: 'fontFamily', value: e.id } }, drawer?.idx)}
                  selected={form?.lines[drawer?.idx]?.fontFamily}
                  nameText={'name'}
                  nameValue={'id'}
                  list={fontsFamily}
                  disabled={form?.lines[drawer?.idx]?.type === 'products' || form?.lines[drawer?.idx]?.type === '1'}
                />
              </Grid>
              <Grid item xs={12}>
                <ComboBoxData
                  label="Estilo da Fonte"
                  onChange={e => handleChangeLine({ target: { name: 'fontStyle', value: e.id } }, drawer?.idx)}
                  selected={form?.lines[drawer?.idx]?.fontStyle}
                  nameText={'name'}
                  nameValue={'id'}
                  list={fontStyles}
                  disabled={form?.lines[drawer?.idx]?.type === 'products' || form?.lines[drawer?.idx]?.type === '1'}
                />
              </Grid>
              <Grid item xs={12}>
                <ComboBoxData
                  label="Alinhamento"
                  onChange={e => handleChangeLine({ target: { name: 'align', value: e.id } }, drawer?.idx)}
                  selected={form?.lines[drawer?.idx]?.align}
                  nameText={'name'}
                  nameValue={'id'}
                  list={aligns}
                  disabled={form?.lines[drawer?.idx]?.type === 'products' || form?.lines[drawer?.idx]?.type === '1'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Margem Acima"
                  onChange={e => handleChangeLine({ target: { name: 'top', value: e.target.value } }, drawer?.idx)}
                  value={form?.lines[drawer?.idx]?.top}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Margem à Esquerda"
                  onChange={e => handleChangeLine({ target: { name: 'left', value: e.target.value } }, drawer?.idx)}
                  value={form?.lines[drawer?.idx]?.left}
                  type="number"
                />
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={save}>SALVAR</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddOrEditModal
