import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { MenuItem, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '.'

function renderInputComponent (inputProps) {
  const {
    classes, inputRef = () => { }, ref, ...other
  } = inputProps

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  )
}

function renderSuggestion (suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query)
  const parts = parse(suggestion, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (part.highlight ? (
          <span key={String(index)} style={{ fontWeight: 500 }}>
            {part.text}
          </span>
        ) : (
          <strong key={String(index)} style={{ fontWeight: 300 }}>
            {part.text}
          </strong>
        )))}
      </div>
    </MenuItem>
  )
}

function getSuggestionValue (suggestion) {
  return suggestion
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: 20
  }
})

class AutosuggestCustom extends React.Component {
    state = {
      popper: '',
      suggestions: []
    };

    getSuggestions = value => {
      const { suggestions } = this.props
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length
      let count = 0

      return inputLength === 0
        ? []
        : suggestions.filter(suggestion => {
          const keep = count < 3 && suggestion.slice(0, inputLength).toLowerCase() === inputValue

          if (keep) {
            count += 1
          }

          return keep
        })
    }

    handleSuggestionsFetchRequested = ({ value }) => {
      this.setState({ suggestions: this.getSuggestions(value) })
    };

    handleSuggestionsClearRequested = () => {
      this.setState({ suggestions: [] })
    };

    render () {
      const {
        classes, label, handleChange, value
      } = this.props

      const autosuggestProps = {
        renderInputComponent,
        suggestions: this.state.suggestions,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion
      }

      return (
        <div className={classes.root}>
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              classes,
              label,
              value,
              onChange: handleChange
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion
            }}
            renderSuggestionsContainer={options => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
        </div>
      )
    }
}

AutosuggestCustom.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AutosuggestCustom)
