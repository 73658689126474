export default [
  {
    nome: 'Pacote Promo',
    quantidade: 20,
    valor: 1.00,
    foto: ''
  },
  {
    nome: 'Pacote 1',
    quantidade: 50,
    valor: 5.00,
    foto: ''
  },
  {
    nome: 'Pacote 2',
    quantidade: 100,
    valor: 9.00,
    foto: ''
  },
  {
    nome: 'Pacote 3',
    quantidade: 250,
    valor: 20.00,
    foto: ''
  },
  {
    nome: 'Pacote 4',
    quantidade: 500,
    valor: 35.00,
    foto: ''
  },
  {
    nome: 'Pacote 5',
    quantidade: 1000,
    valor: 60.00,
    foto: ''
  }
]
