import React, { useState } from 'react'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import { Grid, Card, CardContent, CardHeader, CardActions, Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

export default () => {
  const [tokenIfood, setTokenIfood] = useState('')
  const [tokenSite, setTokenSite] = useState('')
  const [tokenConectorFinanceira, setTokenConectorFinanceira] = useState('')

  const gerarTokenIfood = () => {
    axios.get('api/login/ifood/getToken').then(response => {
      setTokenIfood(response)
    })
  }

  const gerarTokenSite = () => {
    axios.get('api/login/site/getToken').then(response => {
      setTokenSite(response)
    })
  }

  const gerarTokenConectorFinanceira = () => {
    axios.get('/api/Login/conectorfinanceira/getToken').then(response => {
      setTokenConectorFinanceira(response)
    })
  }

  const handleCopy = text => {
    const copyText = document.getElementsByTagName('html')[0]
    const input = document.createElement('input')
    input.id = 'inp'
    input.value = text
    copyText.appendChild(input)

    const copy = document.getElementById('inp')
    copy.select()
    document.execCommand('Copy')

    copyText.removeChild(input)
  }

  return (
    <div>
      <Title title="Tokens" />
      <Grid container spacing={2}>
        <Grid item>
          <Card style={{ width: 400 }}>
            <CardHeader title="Ifood" />
            <CardContent>
              Integre seu PDV com ifood.
              {tokenIfood &&
                <Alert variant="filled" style={{ margin: 20 }}>
                  <AlertTitle>
                    <Button variant="contained" color="primary" onClick={() => handleCopy(tokenIfood)}>
                      Clique aqui para copiar
                    </Button>
                  </AlertTitle>
                  <div
                    style={{
                      width: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                    }
                  >
                    {tokenIfood}
                  </div>
                </Alert>
              }
            </CardContent>
            {!tokenIfood &&
              <CardActions>
                <Button color="primary" onClick={gerarTokenIfood}>
                  Gerar um token
                </Button>
              </CardActions>
            }
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ width: 400 }}>
            <CardHeader title="Conector Financeira" />
            <CardContent>
              Disponibilizar o acesso as minhas formas de pagamento.
              {tokenConectorFinanceira &&
                <Alert variant="filled" style={{ margin: 20 }}>
                  <AlertTitle>
                    <Button variant="contained" color="primary" onClick={() => handleCopy(tokenConectorFinanceira)}>
                      Clique aqui para copiar
                    </Button>
                  </AlertTitle>
                  <div
                    style={{
                      width: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                    }
                  >
                    {tokenConectorFinanceira}
                  </div>
                </Alert>
              }
            </CardContent>
            {!tokenConectorFinanceira &&
              <CardActions>
                <Button color="primary" onClick={gerarTokenConectorFinanceira}>
                  Gerar um token
                </Button>
              </CardActions>
            }
          </Card>
        </Grid>
        <Grid item>
          <Card style={{ width: 400 }}>
            <CardHeader title="Site" />
            <CardContent>
              Disponibilizar o acesso as api dos meus sites.
              {tokenSite &&
                <Alert variant="filled" style={{ margin: 20 }}>
                  <AlertTitle>
                    <Button variant="contained" color="primary"
                    onClick={() => handleCopy(tokenSite)}>
                      Clique aqui para copiar
                    </Button>
                  </AlertTitle>
                  <div
                    style={{
                      width: 200,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                    }
                  >
                    {tokenSite}
                  </div>
                </Alert>
              }
            </CardContent>
            {!tokenSite &&
              <CardActions>
                <Button color="primary" onClick={gerarTokenSite}>
                  Gerar um token
                </Button>
              </CardActions>
            }
          </Card>
        </Grid>

      </Grid>
    </div>
  )
}
