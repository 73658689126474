import React, {useState} from 'react';
import _ from 'lodash';
import { Typography, CardContent, Collapse, IconButton, Icon, Card, CardHeader } from '@material-ui/core';
import AddOrEditModal from './addOrEditModal';
import maskDictionary from './maskDictionary.json';
import { ReactTable } from '../..';


export default ({value, onChange}) =>{
  const [modal, setModal] = useState({ open : false })

  const handlerChange = (data) =>{
    let newFields = {...value}
    if (modal.action == "add") {
      newFields = { fields : [...(newFields.fields || []), data] }
    }else{
      newFields.fields[modal.index] = data
    }
    onChange(newFields)
    setModal({open:false})
  }

  return(
    <div>
        <Card>
          <CardHeader
            avatar={<Icon>list</Icon>}
            action={(
              <IconButton onClick={()=> setModal({ open:true, action:"add" })}>
                <Icon>add</Icon>
              </IconButton>
            )}
            title="Lista de Campos do formulário"
          />
          <CardContent style={{ padding: 0 }}>
            <ReactTable
              data={value.fields}
              columns={[
                {
                  Header:"Label",
                  accessor: 'label',
                },
                {
                  Header:"Requerido",
                  accessor: 'required',
                  Cell: props => <Icon>{props.value && "check"}</Icon>
                },
                {
                  Header:"Grid",
                  accessor: 'grid',
                },
                {
                  Header:"Mascara",
                  accessor: 'mask',
                  Cell: row => maskDictionary[row.value]
                },
                {
                  width: 110,
                  Cell: props => (
                    <div>
                      <IconButton
                        onClick={() => setModal({open:true, data: props.original, index: props.index })}
                        color="primary"
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton
                          // disabled={!this.podeDeletar()}
                        onClick={() => {
                          this.deleteConfirmed({
                            ...props.original,
                            index: props.index,
                          })
                        }}
                        color="secondary"
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </div>
                  ),
                },
              ]}
            />
          </CardContent>
        </Card>

      {modal.open &&
        <AddOrEditModal
          action={modal.action}
          onChange={handlerChange}
          data={modal.data}
          onHide={()=> setModal({open:false})}
        />
      }
    </div>
  )
}
