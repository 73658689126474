import React, { Component } from 'react'
import {
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  DialogActions,
  Button,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Icon
} from '@material-ui/core'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import TabGeral from './TabGeral'
import TabComposicao from './TabComposicao'
import TabImpressoras from './TabImpressoras'
import TabEmbalagens from './TabEmbalagens'
import TabSEO from './TabSEO'
import TabFiscal from './TabFiscal'
import TabSimilares from './TabSimilares'
import TabSubstitutos from './TabSubstitutos'
import TabEstoque from './TabEstoque'
import TabApplication from './TabApplication'

class AddOrEditModal extends Component {
  state = {
    saving: false,
    update: false,
    indexTab: 0,
    form: {
      ativo: true,
      dimensoesEmbalagem: {
        altura: 0,
        largura: 0,
        profundidade: 0,
        peso: 0
      },
      categorias: [],
      unidadeMedida: 'UNID',
      multiplo: 1,
      ...this.props.data
    },
    allTagsComponentes: [],
    splitItemModal: { open: false },
    showError: false
  };

  componentDidMount () {
    this.loadTags()
  }

  loadTags () {
    const p1 = axios.get('api/item/tags?componente=true')

    Promise.all([p1]).then(responses => {
      this.setState({
        allTagsComponentes: responses[0]
      })
    })
  }

  handlerChange = (event) => {
    const { form } = this.state
    this.setState({
      form: { ...form, [event.target.name]: event.target.value },
      update: true
    })

    if (event.target.name == 'tags') {  // eslint-disable-line
      this.addTag(event.target.value)
    }
  };

  addTag = (tags) => {
    const novasTags = tags.filter((t) => {
      const incluso = this.props.allTags.find((x) => x == t)  // eslint-disable-line
      return !incluso
    })
    if (novasTags && novasTags.length > 0) {
      this.props.addTags(novasTags)
    }
  };

  handlerChangeDimensoes = (event) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        dimensoesEmbalagem: {
          ...(form.dimensoesEmbalagem || {}),
          [event.target.name]: event.target.value
        }
      },
      update: true
    })
  };

  handlerChangeSEO = (event) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        seo: { ...(form.seo || {}), [event.target.name]: event.target.value }
      },
      update: true
    })
  }

  setSaving = (bool) =>
    new Promise((resolve) => this.setState({ saving: bool }, () => resolve()));

  onSave = async () => {
    const { onHide } = this.props
    const { form } = this.state

    if (!form?.codigo || !form?.nome || !form?.unidadeMedida) {
      this.setState({ showError: true })
      return
    }

    await this.setSaving(true)
    await axios.post('/api/Item', { ...form, multiplo: form.multiplo <= 1 ? 1 : form.multiplo })
    onHide(true)
    this.setSaving(false)
  };

  render () {
    const { indexTab, form, saving, update, allTagsComponentes, showError } = this.state
    const {
      onHide,
      allTags,
      title,
      gruposFiscal,
      allCategorias,
      allApplications,
      providers,
      loadItensFull,
      allItems,
      allStockAddresses,
      disabledAll,
      handleCopy,
      copied
    } = this.props
    let titulo = (form.id ? 'Editar ' : 'Novo ') + title
    if (form.nome) {
      titulo += ` ➜ ${form.nome}`
    }

    return (
      <Dialog fullWidth maxWidth={false} open>

        <Title
          title={titulo}
          buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
        />
        <DialogContent style={{ paddingTop: 10, minHeight: 300 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Tabs
                value={indexTab}
                onChange={(event, indexTab) => this.setState({ indexTab })}
                variant="scrollable"
                orientation="vertical"
              >
                <Tab label="Dados do item" />
                <Tab label="Composição" disabled={form.componente} />
                <Tab label="Aplicação" disabled={form.componente} />
                <Tab label="Embalagem" disabled={form.componente} />
                <Tab label="SEO" disabled={form.componente} />
                <Tab label="Impressoras" disabled={form.componente} />
                <Tab label="Fiscal" disabled={form.componente} />
                <Tab label="Similares" disabled={form.componente} />
                <Tab label="Substitutos" disabled={form.componente} />
                <Tab label="Estoque" disabled={!form.controlaEstoque} />
              </Tabs>

            </Grid>
            <Grid item xs={10}>
              {indexTab === 0 && (
                <TabGeral
                  allTags={allTags}
                  allCategorias={allCategorias}
                  gruposFiscal={gruposFiscal}
                  providers={providers}
                  handlerChange={this.handlerChange}
                  form={form}
                  showError={showError}
                  handleCopy={handleCopy}
                  copied={copied}
                />
              )}
              {indexTab === 1 && !form?.componente && (
                <TabComposicao
                  allTags={allTagsComponentes}
                  montagens={form.montagens}
                  handlerChange={this.handlerChange}
                />
              )}
              {indexTab === 2 && !form?.componente && (
                <TabApplication
                  allApplications={allApplications}
                  form={form}
                  handlerChange={this.handlerChange}
                />
              )}

              {indexTab === 3 && !form?.componente && (
                <TabEmbalagens
                  form={form}
                  handlerChangeDimensoes={this.handlerChangeDimensoes}
                  handlerChange={this.handlerChange}
                  allItems={allItems}
                  loadItensFull={loadItensFull}
                />
              )}
              {indexTab === 4 && !form?.componente && (
                <TabSEO
                  form={form}
                  handlerChangeSEO={this.handlerChangeSEO}
                />
              )}
              {indexTab === 5 && !form?.componente && (
                <TabImpressoras
                  impressoras={form.impressoras}
                  handlerChange={this.handlerChange}
                />
              )}
              {indexTab === 6 && !form?.componente && (
                <TabFiscal
                  handlerChange={this.handlerChange}
                  tax={form?.tax}
                />
              )}
              {indexTab === 7 && !form?.componente && (
                <TabSimilares
                  handlerChange={this.handlerChange}
                  loadItensFull={loadItensFull}
                  allItems={allItems}
                  allTags={allTags}
                  form={form}
                />
              )}
              {indexTab === 8 && !form?.componente && (
                <TabSubstitutos
                  handlerChange={this.handlerChange}
                  loadItensFull={loadItensFull}
                  allItems={allItems}
                  allTags={allTags}
                  form={form}
                />
              )}
              {indexTab === 9 && form?.controlaEstoque && (
                <TabEstoque
                  handlerChange={this.handlerChange}
                  form={form}
                  allStockAddresses={allStockAddresses}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        {!disabledAll &&
          <DialogActions>
            <Typography color="error" variant="caption">
              {update && 'É necessario salvar as informações!'}
              {showError && <>{update && <br />}{'Código ou Nome do produto vazio!'}</>}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disabled={saving}
              onClick={this.onSave}
            >
              Salvar
            </Button>
          </DialogActions>
        }

      </Dialog>
    )
  }
}

export default AddOrEditModal
