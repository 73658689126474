import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Button, Icon, IconButton, Paper, Tooltip } from '@material-ui/core'
import Axios from 'axios'

import ObservacaoDialog from './observacaoDialog'
import statusEntregaList from './../../../../contstants/statusEntrega'

class PedidoInfo extends React.Component {
  state = {
    anchorEl: null,
    disabledBtnStatus: false,
    observacaoDialog: { open: false, data: null }
  }

  atualizarStatusEntregaIfood = () => {
    const { pedido } = this.props
    Axios.put(`api/ifood/FindStatusLogistica/${pedido.referencia}`)
    this.setState({ disabledBtnStatus: true }, () => {
      setTimeout(() => this.setState({ disabledBtnStatus: false }), 30000)
    })
  }

  render() {
    const { pedido, onRefreshPedido } = this.props
    const { observacaoDialog } = this.state
    const sEntrega = statusEntregaList.find(x => x.id === pedido.statusEntrega)

    return (
      <>
        <div style={{ padding: 10, backgroundColor: '#EEE', border:"1px solid #BABABA" }}>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ flex: 1 }}>

              <strong>Dados do Pedido: {pedido.numero}</strong>

              <p style={{ margin: 0 }}>
                <strong>Status: </strong>
                {' '}
                {sEntrega.nome}
              </p>

              <p style={{ margin: 0 }}>
                <strong>Data Criação: </strong>
                {' '}
                {moment(pedido.dataCadastro).format('DD/MM/YYYY HH:mm')}
              </p>

              <p style={{ margin: 0 }}>
                <strong>Observação: </strong>
                {' '}
                {pedido.observacao || 'Não há'}
              </p>

              <p style={{ margin: 0 }}>
                <strong>Vendedor: </strong>
                {' '}
                {pedido?.seller?.name || 'Não há'}
              </p>

            </div>
            {pedido.canal === 2 &&
              <div>
                <Button
                  color="primary"
                  disabled={this.state.disabledBtnStatus}
                  onClick={this.atualizarStatusEntregaIfood}>
                  Atualizar Status de entrega
                  </Button>
              </div>
            }
            {(pedido.canal === 0 || pedido.canal === 1) &&
              <div>
                <Tooltip title="Alterar Observação">
                  <IconButton onClick={() => this.setState({ observacaoDialog: { open: true, data: pedido } })}>
                    <Icon>edit</Icon>
                  </IconButton>
                </Tooltip>
                {observacaoDialog?.open &&
                  <ObservacaoDialog
                    onHide={() => this.setState({ observacaoDialog: { open: false } })}
                    pedido={observacaoDialog.data}
                    save={newOrder => onRefreshPedido(newOrder)}
                  />
                }
              </div>
            }
          </div>
        </div>
      </>
    )
  }
}

export default PedidoInfo
