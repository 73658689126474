import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions } from '@material-ui/core'

const CustomDialog = props => {
  const {
    title, actions, children, dialogProps
  } = props
  return (
    <Dialog {...dialogProps}>
      {title}
      <DialogContent style={{ paddingTop: 10 }}>
        {children}
      </DialogContent>
      <DialogActions>
        {actions}
      </DialogActions>
    </Dialog>
  )
}
CustomDialog.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  children: PropTypes.object,
  dialogProps: PropTypes.object
}
export default CustomDialog
