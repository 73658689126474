import React, { Component } from 'react'
import { Grid, Button } from '@material-ui/core'
import { ReactTable, Chart } from '../../../_atoms'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import TransferenciaDialog from './transferenciaDialog'

class GridContas extends Component {
  state = {
    modalTransferir: { open: false },
  };

  openModal = (name, data) => {
    this.setState({ [name]: { open: true, data } })
  }

  render() {
    const { modalTransferir } = this.state
    const { onReload, ListaContas } = this.props

    const categories = ListaContas.map(conta => conta.nome)

    const series = [{ name: 'Saldo', data: ListaContas.map(conta => conta.saldo) }]

    const xAxis = {
      categories,
      title: { text: 'Contas' },
    }

    const options = { xAxis, series }

    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <ReactTable
              data={this.props.ListaContas}
              columns={[
                {
                  accessor: 'nome',
                  Header: 'Nome da Conta',
                },
                {
                  accessor: 'saldo',
                  Header: 'Saldo',
                  Cell: props => <NumberFormatMoneyBR value={props.value} />,
                },
                {
                  Cell: props => <Button onClick={() => this.openModal('modalTransferir', props.original)} disabled={props.original.saldo <= 0} color="primary" variant="outlined">Transferir</Button>,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Chart options={options} />
          </Grid>
        </Grid>

        {modalTransferir.open
          && (
          <TransferenciaDialog
            data={modalTransferir.data}
            onReload={() => this.setState({ modalTransferir: { open: false } }, onReload)}
            onHide={() => this.setState({ modalTransferir: { open: false } })}
          />
          )}
      </div>
    )
  }
}

export default GridContas
