import React, { useEffect, useState } from 'react'
import {
  List, ListItem, ListItemText, Avatar, Dialog, DialogContent
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import Axios from 'axios'

function ListaDeAdmsDialog (props) {
  const [loading, setLoading] = useState(true)
  const [listaAdms, setListaAdms] = useState([])

  const loadAdms = () => {
    const paginaCaixa = '618035c008f5796946c176f0'
    Axios.get(`api/Pessoa/ByPagina?paginaId=${paginaCaixa}&funcao=admin`)
      .then(lista => {
        setListaAdms(lista)
        setLoading(false)
      })
  }

  useEffect(() => {
    loadAdms()
  }, [])

  return (
    <Dialog open>
      <Title
        title="Permissão de Administrador"
        buttons={[
          { icon: 'close', color: 'secondary', onClick: props.onHide }
        ]}
      />
      <DialogContent style={{ paddingTop: 10 }}>
        {loading && <p>Carregando...</p>}

        <List>
          {listaAdms.map((adm, idx) => (
            <ListItem key={idx} button onClick={() => props.confirmAdm(adm)}>
              <Avatar
                variant="rounded" src={adm?.foto} />
              <ListItemText
                primary={adm?.nome}
                secondary={adm?.entidade?.nome}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default ListaDeAdmsDialog
