import Axios from 'axios'
import React from 'react'
import { EMTable } from '@em/lib/src'
import IconButtonEdit from '../../../_atoms/button/IconButtonEdit'
import IconButtonDelete from '../../../_atoms/button/IconButtonDelete'

function List ({
  tableRef,
  funcoes,
  openModal,
  onDelete
}) {
  return (
    <div>
      <EMTable
        tableRef={tableRef}
        data={async query => {
          const { page, pageSize } = query
          const params = {
            offset: page,
            limit: pageSize
          }

          const response = await Axios.get('api/PrintLayout', { params })

          return {
            data: response,
            page,
            totalCount: response?.paginacao?.qtdeRows || 0
          }
        }}
        columns={[
          {
            title: 'Layout',
            field: 'name'
          },
          {
            maxWidth: 80,
            render: dataRow => {
              return (
                <div style={{ display: 'flex' }}>
                  <IconButtonEdit
                    funcoes={funcoes}
                    onClick={() => {
                      openModal(dataRow)
                    }}
                  />
                  <IconButtonDelete
                    funcoes={funcoes}
                    onClick={() => {
                      onDelete(dataRow)
                    }}
                    perguntar
                    mensagem={(
                      <span>
                        Você realmente deseja excluir o layout
                        <strong>
                          {' '}
                          {dataRow.name}
                        </strong>
                        ?
                      </span>
                    )}
                  />
                </div>
              )
            }
          }
        ]}
      />
    </div>
  )
}

export default List
