import {
  getCenter,
  tranformProductsToLines,
  getSpaceBetween,
  getLinesByWords
} from '@em/lib/src/Printers/helpers'
import moment from 'moment'
import CANAIS from './../../../contstants/canais'

const lineDefaultLucida = {
  fontSize: 12,
  fontFamily: 'Lucida Console',
  type: 0
}

const layoutDefault = pedido => {
  var lines = [
    {
      value: '-------------------------------------------------',
      ...lineDefaultLucida
    },
    {
      value: getCenter('DOCUMENTO PARA CONFERÊNCIA', 9),
      fontSize: 9,
      fontFamily: 'Lucida Console',
      type: 0
    },
    {
      value: getCenter('NÃO POSSUI VALOR FISCAL', 9),
      fontSize: 9,
      fontFamily: 'Lucida Console',
      type: 0
    },
    {
      value: '-------------------------------------------------',
      ...lineDefaultLucida
    },
    {
      value: ' '
    }
  ]

  let _canal = CANAIS.find(c => c?.id === pedido?.canal)?.nome

  if (_canal === 'Loja') {
    if (pedido.mesas) {
      _canal = `Mesa: ${pedido.mesas}`
    } else {
      _canal = 'Viagem'
    }
  }

  lines.push({
    ...lineDefaultLucida,
    value: getCenter(_canal, 12),
    fontStyle: 1,
    fontSize: 12
  })
  lines.push({
    ...lineDefaultLucida,
    value: getCenter(`Pedido: ${pedido?.numero}`, 12),
    fontStyle: 1,
    fontSize: 12
  })

  lines.push(
    {
      ...lineDefaultLucida,
      value: getCenter(`OP: ${pedido?.seller?.name}`, 9),
      fontSize: 9
    }
  )

  lines.push({
    ...lineDefaultLucida,
    value: getCenter(`${moment(pedido.dataCadastro).format('DD/MM/YYYY HH:mm')}`, 9),
    fontSize: 9
  })

  lines.push(
    {
      value: ' ',
      type: 0
    }
  )

  if (pedido?.cliente) {
    lines.push(...getLinesByWords(`Cliente: ${pedido?.cliente?.nome || ''}`).map(value => {
      return {
        value,
        fontSize: 10,
        fontStyle: 1
      }
    }))
    if (pedido?.cliente?.telefone) {
      lines.push({
        value: `Tel: ${pedido?.cliente?.telefone}`,
        fontSize: 10,
        fontStyle: 1
      })
    }
    if (pedido?.cliente?.cpf) {
      lines.push({
        value: `CPF: ${pedido?.cliente?.cpf}`,
        fontSize: 10,
        fontStyle: 1
      })
    }
  }

  if (pedido?.endereco && pedido?.canal) {
    getLinesByWords(`Endereço: ${pedido?.endereco?.rua || ''}, ${pedido?.endereco?.numero || ''}`)
      .forEach(line => {
        lines.push({
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    getLinesByWords(`Bairro: ${pedido?.endereco?.bairro || ''}`, 33)
      .forEach(line => {
        lines.push({
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    getLinesByWords(`Cidade: ${pedido?.endereco?.cidade || ''} - ${pedido?.endereco?.estado || ''}`
      , 33)
      .forEach(line => {
        lines.push({
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    lines.push({
      value: `CEP: ${pedido?.endereco?.cep || ''}`,
      fontSize: 10,
      fontStyle: 1
    })
    getLinesByWords(`Complemento: ${pedido?.endereco?.complemento || ''}`, 33)
      .forEach(line => {
        lines.push({
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
    getLinesByWords(`Referência: ${pedido?.endereco?.referencia || ''}`, 33)
      .forEach(line => {
        lines.push({
          value: line,
          fontSize: 10,
          fontStyle: 1
        })
      })
  }

  if (pedido?.observacao) {
    lines.push(
      {
        value: ' ',
        type: 0
      },
      {
        value: '-- Observação do Pedido --',
        fontStyle: 1,
        ...lineDefaultLucida
      },
      {
        value: ' ',
        type: 0
      },
      ...getLinesByWords(pedido?.observacao).map(value => {
        return {
          value: value,
          ...lineDefaultLucida
        }
      }),
      {
        value: ' ',
        type: 0
      }
    )
  }

  lines.push({ value: '---------------------------------------------------' },
    ...tranformProductsToLines(pedido)
  )

  lines.push(
    {
      value: ' ',
      type: 0
    },
    {
      value: getSpaceBetween('Frete: ', `${Number(pedido?.valorFrete || 0).toFixed(2).replace('.', ',')}`, 12),
      fontStyle: 1,
      ...lineDefaultLucida
    },
    {
      value: getSpaceBetween('Valor Total: ', `${Number(pedido?._valorTotal || pedido?.valorTotal || 0).toFixed(2).replace('.', ',')}`, 12),
      fontStyle: 1,
      ...lineDefaultLucida
    },
    {
      value: ' ',
      type: 0
    },
    {
      value: '-------- Pagamento --------',
      fontStyle: 1,
      ...lineDefaultLucida
    },
    {
      value: ' ',
      type: 0
    }
  )

  let valorTotalPago = 0

  pedido?.pagamentos?.forEach(item => {
    valorTotalPago += Number(item.valor || 0)
    lines.push(
      {
        value: getSpaceBetween(`${item.nome}:`, `${Number(item.valor || 0).toFixed(2).replace('.', ',')}`, 12),
        ...lineDefaultLucida
      }
    )
  })

  const troco = valorTotalPago - Number(pedido?._valorTotal || pedido?.valorTotal || 0)

  lines.push(
    {
      value: getSpaceBetween(`${troco < 0 ? 'Falta Pagar' : 'Troco'}: `, `${Number(troco < 0 ? (troco * -1) : troco).toFixed(2).replace('.', ',')}`, 12),
      ...lineDefaultLucida
    },
    {
      value: ' ',
      type: 0
    }
  )

  return lines
}

export default layoutDefault
