import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Avatar
} from '@material-ui/core'

import UploadImageDialog from '../../../_atoms/uploadImageDialog'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'

export default function AddOrEditModal ({
  onHide,
  data,
  addOrEditSegmento,
  onOpenTrocarFotoDialog
}) {
  const [form, setForm] = useState({
    nome: '',
    paiId: null,
    ...data
  })
  const [trocarFotoDialog, setTrocarFotoDialog] = useState({
    open: false,
    name: null
  })

  function save () {
    addOrEditSegmento(form)
  }

  let titulo = (data.id ? 'Editar ' : 'Nova ') + 'Segmento'
  if (data.nome) {
    titulo += ` ➜ ${data.nome}`
  } else if (data.paiNome) {
    titulo += ` em ${data.paiNome}`
  }
  return (
    <Dialog fullWidth maxWidth={'sm'} open>
      <Title
        title={titulo}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent style={{ paddingTop: 10 }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Avatar
              variant="rounded"
              onClick={() => setTrocarFotoDialog({ open: true, name: '' })}
              style={{ width: 100, cursor: 'pointer' }}
              alt="Logo"
              src={global.bucket + form.foto}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="nome"
              label="Nome do Segmento"
              value={form.nome}
              onChange={e => setForm({ ...form, nome: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <ComboBoxData
              list={[
                { id: 0, nome: 'Nenhum' },
                { id: 1, nome: 'Primário' },
                { id: 2, nome: 'Secundário' }
              ]}
              onChange={e => setForm({ ...form, destaque: e.id })}
              name="destaque"
              label="Destaque"
              value={form?.destaque || ''}
            />
          </Grid>
        </Grid>
        {trocarFotoDialog.open && (
          <UploadImageDialog
            title="Trocar foto do Segmento"
            onHide={() => setTrocarFotoDialog({ open: false })}
            onCapture={foto => {
              setTrocarFotoDialog({ open: false })
              setForm({
                ...form,
                foto
              })
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={save}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
