import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Grid,
  Tooltip,
  Chip,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  CircularProgress
} from '@material-ui/core'
import ImageAvatar from './ImageAvatar'
import { set } from 'react-ga'

export default function GaleryModal({
  onHide,
  onAdd
}) {
  const [files, setFiles] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)

  const loadFiles = () => {
    setLoading(true)
    axios.get(`api/arquivo?limit=1000`).then(response => {
      let tags = [{ name: "Sem tag", selected: true }]
      response.lista.forEach(item => {
        item.tags.forEach(tag => {
          if (!tags.map(x => x.name).includes(tag)) {
            tags.push({ name: tag, selected: false })
          }
        });
      });
      setFiles(response.lista)
      setTimeout(() => {
        setLoading(false)
      }, 1000);
      setTags(tags)
    })
  }

  useEffect(() => {
    loadFiles()
  }, [])

  const onSelectTag = (tag) => {
    setTags(tags.map(t => {
      if (tag.name == t.name) {
        return { name: t.name, selected: true }
      }
      return { name: t.name, selected: false }
    }))
  }

  if (loading) {

    return (
      <Dialog open maxWidth="xs" onClose={onHide}>
        <DialogContent style={{ minWidth: 150, minHeight: 150 }}>
          <CircularProgress color="primary" size={100} />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open maxWidth="md" onClose={onHide}>
      <DialogContent>
        <div>
          {tags.map(tag => {
            return (
              <Chip color={tag.selected ? "secondary" : "primary"} label={tag.name} onClick={() => onSelectTag(tag)} />
            )
          })}
        </div>
        <Grid container spacing={1}>
          {files.filter(f => {
            const tagSelected = tags.find(x => x.selected)

            if (tagSelected && tagSelected.name == "Sem tag" && (!f.tags || f.tags.length == 0)) {
              return true
            }

            let contem = false
            f.tags.forEach(t => {
              if (tagSelected && tagSelected.name == t) {
                contem = true
              }
            });
            return contem
          }).map((file, idx) => (
            <Grid item xs={12} sm={6} md={4} lg={2} style={{ cursor: "pointer" }}>
              <Tooltip title={file.descricao} arrow>
                <Grid container style={{ height: 200 }} justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <ImageAvatar
                      alt="G"
                      data={file}
                      showDelete={false}
                      showSelect={true}
                      onSelect={dataSelected => {
                        setFiles(
                          files.map(x => {
                            if (x.key === dataSelected.key) {
                              return { ...x, selected: !x.selected }
                            }
                            return x
                          })
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      wordBreak: 'break-word'
                    }}
                  >
                    {file.titulo} <br /><i>{((file.size || 0) * 1e-6).toFixed(2)} MB</i>
                  </Grid>
                </Grid>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onAdd(files.filter(x => x.selected).map(x => x.key))
            onHide()
          }}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
