import React, { useState, useEffect, useRef } from 'react'
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Menu, MenuItem, Popover, Popper } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import SendIcon from '@material-ui/icons/Send'
import moment from 'moment'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import MicIcon from '@material-ui/icons/Mic'
import { makeStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import SpeedDial from '@material-ui/lab/SpeedDial'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { Picker } from 'emoji-mart'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import PhoneIcon from '@material-ui/icons/Phone'
import Axios from 'axios'
import 'emoji-mart/css/emoji-mart.css'
import './ChatRoom.css'

import { DateTimePicker, TextField } from '../../../../_atoms'
import ComboBoxData from '../../../../_atoms/combobox/ComboBoxData'

let lastdate = null

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1
  },
  radioGroup: {
    margin: theme.spacing(1, 0)
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp': {
      bottom: 0,
      right: 0
    }
  }
}))

const statusList =
  [
    { id: 'Primeiro contato', nome: 'Primeiro contato' },
    { id: 'Aguardando retorno', nome: 'Aguardando retorno' },
    { id: 'Verificando dados cadastrados', nome: 'Verificando dados cadastrados' },
    { id: 'Serviços não aplicáveis', nome: 'Serviços não aplicáveis' },
    { id: 'Recado com Terceiros', nome: 'Recado com Terceiros' },
    { id: 'Não tem interesse', nome: 'Não tem interesse' },
    { id: 'Permanentemente Fechada', nome: 'Permanentemente Fechada' },
    { id: 'Dados incorretos/não encontrados', nome: 'Dados incorretos/não encontrados' },
    { id: 'Em Negociação', nome: 'Em Negociação' },
    { id: 'Proposta comercial enviada', nome: 'Proposta comercial enviada' },
    { id: 'Negociação ganha', nome: 'Negociação ganha' },
    { id: 'Negociação perdida', nome: 'Negociação perdida' }
  ]

function ChatRoom ({ cardSelected, updateCard, setOpenPerson, whatsSelected }) {
  const messagesEnd = useRef(null)
  const contentRef = useRef(null)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [open, setOpen] = React.useState(false)
  const [input, setInput] = useState('')
  const [modal, setModal] = useState({ open: false })
  const [messages, setmessages] = useState([])
  const classes = useStyles()

  useEffect(() => {
    const func = async () => {
      const response = await Axios.get('api/message', {
        params: {
          personId: cardSelected.id,
          limit: 20,
          offset: 0
        }
      })
      setmessages(response.lista.reverse())
    }

    cardSelected?.id && func()
  }, [cardSelected?.id])

  const sendMessage = async (channel) => {
    if (channel.type == 2) {
      if (!whatsSelected.whatsAppId) {
        console.log('whatsAppId is requerid')
        return
      }
    }

    const item = {
      content: input,
      direction: 'out',
      channel,
      sender: {
        whatsAppId: whatsSelected?.whatsAppId
      },
      receiver: {
        id: cardSelected.id,
        name: cardSelected.nome
      }
    }

    const response = await Axios.post('api/message', item)
    if (response.dados) {
      setmessages([...messages, response.dados])
      setInput('')
      setOpen(false)
    }
  }

  useEffect(() => {
    if (messages?.length > 0) {
      messagesEnd.current.scrollIntoView()
    }
  }, [messages.length])

  useEffect(() => {
    if (messagesEnd.current && messagesEnd.current.addScrollListener) {
      messagesEnd.current.addScrollListener((a) => {
        console.log(a)
      })
    }
  }, [messagesEnd.current])

  const addEmoji = (e) => {
    setInput(input + e.native)
    setAnchorEl(null)
    setTimeout(() => {
      contentRef.current?.focus()
    }, 200)
  }

  const onMoveTheEndConfirm = async (content, dateChecked, status) => {
    const model = {
      content,
      receiver: {
        id: cardSelected.id,
        name: cardSelected.nome
      }
    }

    const response = await Axios.post('api/message/toTheEnd?status=' + status, model)
    updateCard({
      ...cardSelected,
      dateChecked: null,
      unread: 0
    })
  }

  const onSheduledConfirm = async (content, dateChecked, status) => {
    const model = {
      content,
      receiver: {
        id: cardSelected.id,
        name: cardSelected.nome
      }
    }

    const response = await Axios.post(`api/message/toSchedule?status=${status}&dateSchedule=${dateChecked.format('YYYY/MM/DD HH:mm')}`, model)
    updateCard({
      ...cardSelected,
      dateChecked: dateChecked.format(),
      unread: 0
    })
  }

  const messagesIn = messages.filter(m => m.direction == 'in')
  const lastDateIn = messagesIn[messagesIn.length - 1]?.createAt

  if (!cardSelected) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Selecione um contato.
      </div>
    )
  }
  return (
    <div className="chatroom">
      <Grid container justify="space-between">
        <Grid item>
          <Button style={{ textAlign: 'left', flex: 1, justifyContent: 'left' }} onClick={() => setOpenPerson && setOpenPerson(cardSelected)}>
            <Avatar src={global.bucket + cardSelected.foto} />
            <div style={{ marginLeft: 10, textAlign: 'left' }}>
              <h3>{cardSelected.nome}</h3>
              <p>
                Ultima mensagem{' '}
                {lastDateIn && moment(lastDateIn).format('DD/MM/YYYY H:mm')}
              </p>
            </div>
          </Button>
        </Grid>
        <Grid item>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <IconButton>
            <AttachFileIcon />
          </IconButton>

          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <IconButton {...bindTrigger(popupState)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={() => {
                    popupState.close()
                    setModal({
                      open: true,
                      modal: 'toEnd',
                      status: cardSelected.status || 'Primeiro contato',
                      onClick: onMoveTheEndConfirm
                    })
                  }}>Enviar para o final da fila</MenuItem>
                  <MenuItem onClick={() => {
                    popupState.close()
                    setModal({
                      open: true,
                      modal: 'toSchedule',
                      status: cardSelected.status || 'Primeiro contato',
                      date: moment(),
                      onClick: onSheduledConfirm
                    })
                  }}>Agendar atendimento</MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Grid>
      </Grid>

      <div className="chatroom__body" id="scrollableDivMessage"
        style={{
          overflowY: 'auto',
          height: 'calc(100vh - 195px)',
          padding: 10
        }}>

        <InfiniteScroll
          dataLength={messages.length} // This is important field to render the next data
          next={() => alert('carrega mais')}
          scrollableTarget="scrollableDivMessage"
          hasMore={false}
          InfiniteScroll={200}
          loader={<h4>Carregando...</h4>}
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Vocẽ já tem todas conversas</b>
          //   </p>
          // }
          // below props only if you need pull down functionality
          refreshFunction={() => alert('atualizado')}
          // pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
        >

          {messages.map((message, idx) => {
            const currentdate = moment(message.createAt).format('DD/MM/YYYY')
            let printLastDateDate = false
            if (lastdate === null || lastdate < currentdate) {
              lastdate = currentdate
              printLastDateDate = true
            }
            return (
              <>
                {printLastDateDate &&
                  <p key={message.id}
                    style={{ textAlign: 'center', backgroundColor: '#222', color: '#FFF' }}
                    className={'chatroom__message chatroom__messagesystem'}
                  >
                    {lastdate}
                  </p>
                }
                <p
                  key={message.id}
                  style={{ maxWidth: '90%', wordBreak: 'break-all' }}
                  className={`chatroom__message ${message.direction === 'out' ? 'chatroom__messagerecierver'
                    : message.direction === 'unknown' ? 'chatroom__messagesystem' : ''
                    }`}
                >
                  <p className="chatroom__messagetimestamp_canal_icon">
                    {message.channel?.type === 1 && <PhoneIcon />}
                    {message.channel?.type === 2 && <WhatsAppIcon />}
                  </p>

                  <span className="chatroom__username">{message.sender?.name}</span>
                  {message.content}
                  <p className="chatroom__messagetimestamp ">
                    {moment(message.createAt).format('DD/MM/YYYY H:mm')}
                  </p>
                </p>
              </>
            )
          })}
        </InfiniteScroll>

        <div id="emptyDiv"
          style={{ float: 'left', clear: 'both' }}
          ref={messagesEnd}
        />

      </div>
      <div className="chatroom__footer">
        <IconButton onClick={event => setAnchorEl(anchorEl ? null : event.currentTarget)}>
          <SentimentVerySatisfiedIcon />
        </IconButton>
        <TextField
          ref={contentRef}
          autofocus
          value={input}
          style={{ margin: '2px 35px 1px 0' }}
          variant="outlined"
          onChange={(event) => setInput(event.target.value)}
          placeholder="Digite sua mensagem aqui"
          multiline
          rowsMax={3}
        />
        <SpeedDial
          ariaLabel="Enviar Mensagem"
          className={classes.speedDial}
          icon={<SendIcon />}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          FabProps={{ disabled: (!cardSelected?.telefones || cardSelected?.telefones.length == 0) }}
        >
          {cardSelected?.telefones?.map((phone) => {
            const actions = [{
              numero: phone.numero,
              icon: <PhoneIcon />,
              name: 'Telefone ' + phone.numero,
              canal: 1
            }]

            if (phone.whatsAppId) {
              actions.push({
                numero: phone.whatsAppId != 'y' ? phone.whatsAppId : phone.numero,
                icon: <WhatsAppIcon />,
                name: 'Whatsapp ' + phone.numero,
                canal: 2
              })
            }
            return actions?.map(action => {
              return (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => sendMessage({ id: action.numero, type: action.canal })}
                />
              )
            })
          })}
        </SpeedDial>

        <MicIcon />
      </div>

      <Popover id="popperEmoji"
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Picker onSelect={addEmoji} />
      </Popover>

      <Dialog open={modal.open} onClose={() => setModal({ open: false })} maxWidth="md" fullWidth>
        <DialogTitle>
          {modal?.modal == 'toEnd' &&
            <span>Você realmente deseja enviar <b>{cardSelected.nome}</b> para o final da fila?</span>
          }

          {modal?.modal == 'toSchedule' &&
            <span>Escolha a data e hora para agendar <b>{cardSelected.nome}</b>?</span>
          }
        </DialogTitle>
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {modal?.modal == 'toSchedule' &&
                <>
                  <DateTimePicker
                    fullWidth
                    label="Selecione a data de Agendamento"
                    value={modal.date}
                    maxDate={moment().add(1, 'month')}
                    minDate={moment()}
                    onChange={date => setModal({ ...modal, date })}
                  />
                </>
              }

              {modal?.modal != 'toSchedule' &&
                <b>Final da fila</b>
              }
            </Grid>

            <Grid item xs={6}>
              <ComboBoxData
                // error={showError && !modal.status}
                label="Selecione um Status"
                onChange={e => {
                  setModal({ ...modal, status: e.nome })
                }}
                selected={modal.status}
                list={statusList}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                value={modal.comment}
                variant="outlined"
                onChange={(event) => setModal({ ...modal, comment: event.target.value })}
                placeholder="Digite seu comentário aqui"
                multiline
                rowsMax={3}
                label="Deixe um comentário."
              />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setModal({ open: false })}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={() => {
            modal?.onClick(modal?.comment, modal?.date, modal?.status)
            setModal({ open: false })
          }}>Sim</Button>
        </DialogActions>
      </Dialog>

    </div >
  )
}

export default ChatRoom
