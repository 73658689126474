import Title from '@em/lib/src/util/Title'
import { Button, Dialog, DialogContent, Grid, Icon } from '@material-ui/core'
import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { show } from 'react-notification-system-redux'

import { axiosEMUtility } from '../../../../myAxios'
import { layoutFechamentoCaixa } from '../../impressaoLayouts'
import layoutReciboMotoboy from '../../impressaoLayouts/layoutReciboMotoboy'

const styleButton = {
  width: 100,
  height: 100
}

const styleDiv = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}

function PrintersModal ({
  onHide,
  data,
  list
}) {
  const [printers, setPrinters] = useState([])
  const loadPrinters = () => {
    Axios.get('/api/Impressora/Full')
      .then(response => {
        setPrinters(response)
        if (response?.length === 1) {
          onImprimir(response[0])
          onHide(true)
        }
      })
  }

  const onImprimir = async (impressora) => {
    let layout = []
    if (data?.tela === 2) {
      const caixa = JSON.parse(data.settings || '{}')
      layout = layoutFechamentoCaixa(caixa, list)
    }
    if (data?.tela === 4) {
      // const freteRepasse = JSON.parse(data.settings || '{}')
      layout = await layoutReciboMotoboy(data)
    }

    axiosEMUtility
      .post(`api/Printer/PrintOut/${impressora?.caminho}/${impressora?.modelo?.id}`, layout)
      .then(() => {
        show({ message: 'Impressão enviada!', title: 'Sucesso' }, 'success')
      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      }).finally(() => onHide(true))
  }

  useEffect(() => {
    loadPrinters()
  }, [])

  return (
    <Dialog open fullWidth>
      <Title
        title={'Opções de Impressão'}
        buttons={[
          { icon: 'close', color: 'secondary', onClick: onHide }
        ]}
      />
      <DialogContent>
        <Grid container spacing={1} justify="center">
          {printers?.map(p => {
            return (
              <Grid item key={p?.id}>
                <Button
                  variant="outlined"
                  style={styleButton}
                  onClick={() => onImprimir(p)}
                >
                  <div style={styleDiv}>
                    <Icon style={{ fontSize: 40 }}>print</Icon>
                    <label style={{ fontSize: 9 }} className='oneLine'>{p?.nome}</label>
                  </div>
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default PrintersModal
