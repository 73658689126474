import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Icon,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Popover
} from '@material-ui/core'
import { MdSportsMotorsports, MdStoreMallDirectory, MdOutlineLanguage } from 'react-icons/md'
import { SiAirtable } from 'react-icons/si'
import MontagemDialog from './montagemDialog'
import Axios from 'axios'
import TextFielddMoneyBR from '@em/lib/src/util/InputCustom/TextFieldMoneyBR'
import PedidoItensCard from './pedidoItensCard'
import { somarItem } from '@em/lib/src/Produtos/somasCaixa2'

import { TextField } from './../../../../_atoms'

const PedidoItens = ({
  pedido, onOpenMesas, onOpenSearchItens, search,
  handlerChange, mesas, openAdmsModal, allowEditByAdm, show,
  onSave, onRefreshPedido
}) => {
  const [montagemModal, setMontagemModal] = useState({ open: false })
  const [opcoesMenu, setOpcoesMenu] = useState({ anchorEl: null })
  const [itemAtual, setItemAtual] = useState({})
  const [idxAtual, setIdxAtual] = useState(null)
  const [valorPopover, setValorPopover] = useState({ open: false })

  useEffect(() => {
    if (pedido?.itens?.length > 0) {
      const itens = pedido.itens || []
      itens.forEach((itemAtual, idx) => {
        const itemCalculado = somarItem(itemAtual)
        if (itemAtual.showComposicao && itemAtual.montagens.length > 0) {
          setMontagemModal({ open: true })
          setItemAtual(itemAtual)
          setIdxAtual(idx)
        } else if (!itemCalculado._valid) {
          setMontagemModal({ open: true })
          setItemAtual(itemAtual)
          setIdxAtual(idx)
        }
      })
    }
  }, [pedido?.itens?.length])

  const handleChangeItem = (item, name, value) => {
    item[name] = value
    onRefreshPedido(pedido)
  }

  const applyNewAmount = async (pedidoItem, newValorComDesconto, descontoCliente, valorComponentes) => {
    const responseItem = await Axios.get(`api/Item/${pedidoItem?.id}`)

    let retorno = false
    if (responseItem && responseItem[0]) {
      const itemOriginal = responseItem[0]

      let permite = false
      let valorBlock = itemOriginal.valor

      if (!itemOriginal?.minAmount || itemOriginal?.minAmount === 0) {
        const desconto = ((itemOriginal.valor * descontoCliente) / 100)
        permite = newValorComDesconto >= (itemOriginal.valor - (desconto || 0))
        valorBlock = (itemOriginal.valor - (desconto || 0))
      } else if (itemOriginal?.minAmount && itemOriginal?.minAmount > 0) {
        permite = newValorComDesconto >= (itemOriginal?.minAmount || 0)
        valorBlock = itemOriginal?.minAmount
      }

      if (permite) {
        retorno = true
      } else {
        show({
          message: `Você não pode reduzir o valor do item para menos que R$ ${Number(valorBlock + valorComponentes || 0).toFixed(2).replace('.', ',')}!`,
          title: 'Alteração Inválida'
        }, 'error')
      }
    }

    return retorno
  }

  const handleChangeItemValor = async (item, value, valorComponentes = 0) => {
    const valorTotalSemComponentes = value - valorComponentes

    const allowChange = await applyNewAmount(item, parseFloat(valorTotalSemComponentes), pedido?.cliente?.entidade?.discount, valorComponentes)

    if (allowChange) {
      pedido.itens[item.idx].valorAux = parseFloat((parseFloat(valorTotalSemComponentes) - parseFloat(pedido.itens[item.idx].valor)).toFixed(2))
      setTimeout(() => {
        onRefreshPedido(pedido)
        setValorPopover({ open: false })
      }, 1)
    }
  }

  const handleChangeItem2 = item => {
    pedido.itens[idxAtual] = item
    onRefreshPedido(pedido)
    setMontagemModal({ open: false })
  }

  const onCloneItem = () => {
    const itens = pedido.itens || []
    onRefreshPedido({ ...pedido, itens: [...itens, _.cloneDeep(itemAtual)] })
  }

  const onRemoveConfirmation = () => {
    _.pullAt(pedido.itens, idxAtual)
    onRefreshPedido(pedido)
  }

  const setViagem = () => {
    const itens = pedido.itens || []
    itens[idxAtual].viagem = !itens[idxAtual].viagem
    onRefreshPedido({ ...pedido, itens })
  }

  const edicaoBloqueada = pedido?.statusEntregaJoin?.blockEdit && !allowEditByAdm

  return (
    <>
      <div style={{ padding: 5, backgroundColor: '#BABABA' }}>
        <Grid container spacing={1} alignItems="center">
          {pedido.canal != 2 && (
            <>
              <Grid item xs={6}>
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  variant={pedido.canal === 0 ? 'contained' : 'text'}
                  disabled={(edicaoBloqueada)}
                  endIcon={<MdStoreMallDirectory />}
                  onClick={() => {
                    pedido.canal = 0
                    onRefreshPedido(pedido)
                  }}
                >
                  {'Loja'}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  variant={pedido.canal === 1 ? 'contained' : 'text'}
                  disabled={(edicaoBloqueada)}
                  endIcon={<MdSportsMotorsports />}
                  onClick={() => {
                    pedido.canal = 1
                    onRefreshPedido(pedido)
                  }}
                >
                  {'Entrega'}
                </Button>
              </Grid>
              {mesas?.length > 0 && (
                <Grid item xs={3}>
                  <Button
                    size="large"
                    color="primary"
                    fullWidth
                    variant="text"
                    disabled={(edicaoBloqueada)}
                    onClick={() => onOpenMesas(pedido.mesas)}
                  >
                    {!(pedido?.mesas?.length > 0) ? 'Viagem' : `Mesa ${pedido?.mesas || '--'}`}
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <TextField
                  label="Cód. item / EAN"
                  name="search"
                  value={search}
                  onKeyPress={(e) => e.key === 'Enter' && onOpenSearchItens(search)}
                  onChange={handlerChange}
                  disabled={(edicaoBloqueada)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="outlined" position="end">
                        <IconButton
                          onClick={() => onOpenSearchItens(search)}
                          disabled={(edicaoBloqueada)}
                        >
                          <Icon>add</Icon>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              {(edicaoBloqueada) &&
                <Grid item>
                  <Tooltip title="Desbloquear Edição" placement="top">
                    <IconButton onClick={() => openAdmsModal()}>
                      <Icon color="primary">
                        lock
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              }
              {/* <Grid item>
                <Tooltip
                  title="Salvar Pedido"
                  placement="top"
                >
                  <IconButton
                    onClick={onSave}
                    disabled={pedido.canal == 2}
                  >
                    <Icon color="primary">
                      save
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </>
          )}
          {pedido.canal == 2 && (
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  variant={pedido?.tipo === 'TAKEOUT' ? 'contained' : 'text'}
                  endIcon={<MdStoreMallDirectory />}
                >
                  {'Retirada'}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  variant={pedido?.tipo === 'DELIVERY' ? 'contained' : 'text'}
                  endIcon={<MdSportsMotorsports />}
                >
                  {'Entrega'}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  variant={pedido?.tipo === 'INDOOR' ? 'contained' : 'text'}
                  endIcon={<SiAirtable />}
                >
                  {'Mesa'}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <PedidoItensCard
              pedido={pedido}
              edicaoBloqueada={edicaoBloqueada}
              handleChangeItem={handleChangeItem}
              setValorPopover={setValorPopover}
              setOpcoesMenu={setOpcoesMenu}
              setItemAtual={setItemAtual}
              setIdxAtual={setIdxAtual}
            />
          </Grid>
        </Grid>
      </div>

      {montagemModal.open && (
        <MontagemDialog
          hcItem={item => handleChangeItem2(item)}
          item={itemAtual}
          onHide={() => setMontagemModal({ open: false })}
        />
      )
      }

      <Popover
        id="Popover"
        open={valorPopover.open}
        anchorEl={valorPopover.anchorEl}
        onClose={() => setValorPopover({ open: false })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <TextFielddMoneyBR
          value={valorPopover?.valor}
          label={`Novo valor por ${valorPopover?.item?.unidadeMedida || 'un'}`}
          onKeyPress={(e) => e.key == 'Enter' && handleChangeItemValor(valorPopover.item, valorPopover.valor, valorPopover?.valorComponentes)}
          InputProps={{
            endAdornment: (
              <InputAdornment variant="outlined" position="end">
                <IconButton onClick={() => handleChangeItemValor(valorPopover.item, valorPopover.valor, valorPopover?.valorComponentes)}>
                  <Icon>check</Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
          onValueChange={(e) => setValorPopover({ ...valorPopover, valor: e.target.value })}
        />
      </Popover>

      <Menu
        id="long-menu"
        anchorEl={opcoesMenu.anchorEl}
        open={!!opcoesMenu.anchorEl}
        onClose={() => setOpcoesMenu({ anchorEl: null })}
      >
        <MenuItem
          disabled={!itemAtual.montagens}
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            setMontagemModal({ open: true })
          }}
        >
          Editar Composição
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            onCloneItem()
          }}
        >
          Clonar item
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            onRemoveConfirmation()
          }}
        >
          Deletar item
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            setViagem()
          }}
        >
          {!itemAtual.viagem ? 'Para viagem' : 'Remover viagem'}
        </MenuItem>
      </Menu>
    </>
  )
}

export default PedidoItens
