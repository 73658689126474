import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import {
  Button, Avatar, Icon, IconButton, Grid, TextField
} from '@material-ui/core'
import CriarEditarEntregadorDialog from '../../_organisms/pessoa/criarEditar/criarEditarEntregadorDialog'
import ReactTable from '../../_atoms/ReactTable'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import { entrarAction } from '../../actions/loginAction'
import Title from '@em/lib/src/util/Title'
import UploadFileModal from '../../_atoms/uploadFileModal'

const styles = theme => ({
  Paper: {
    padding: 10
  }
})

class ListaEntregadores extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalEditarSistema: { open: false },
      entidades: [],
      entregadores: {},
      loading: true,
      form: { busca: '' },
      filter: { busca: '' },
      uploadFileModal: { open: false }
    }
  }

  openModalEditarCriarSistema = data => {
    this.setState({ modalEditarSistema: { open: true, data } })
  };

  delete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {deleted.nome}
          </strong>
?
        </span>
      ),
      buttoes: [{ text: 'EXCLUIR', onClick: () => this.deleteConfirmed(deleted) }]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  deleteConfirmed = deleted => {
    axios.delete(`api/entregador/${deleted.id}`).then(() => {
      this.onBuscar()
    })
  };

  fetchData = (state, instance) => {
    this.setState({ loading: true, offset: state.page, pageSize: state.pageSize }, this.loadEntregadores)
  };

  loadEntregadores = () => {
    this.setState({ loading: true })
    const { form, offset, pageSize } = this.state
    const { busca } = form
    axios.get('api/entregador', { params: { busca, offset, pageSize } }).then(result => {
      this.setState({
        entregadores: result || {},
        loading: false,
        modalEditarSistema: { open: false }
      })
    })
  };

  handleChange = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value || '' } })
  };

  onBuscar = () => {
    const { form } = this.state
    this.setState({ filter: { ...form, key: Date() }, offset: 0 }, this.loadEntregadores)
  };

  podeEditar = () => {
    const { funcoes } = this.props
    return _.some(funcoes, f => f === 'editar')
  };

  podeDeletar = () => {
    const { funcoes } = this.props
    return _.some(funcoes, f => f === 'deletar')
  };

  render () {
    const { classes } = this.props
    const {
      form, entregadores, loading, modalEditarSistema,
      uploadFileModal
    } = this.state
    return (
      <div>
        <Title
          title="Lista de Entregadores"
          buttons={[
            {
              icon: 'cloud_upload',
              color: 'primary',
              onClick: () => this.setState({ uploadFileModal: { open: true } })
            },
            {
              icon: 'add',
              color: 'primary',
              onClick: this.openModalEditarCriarSistema
            }
          ]}
        />

        <Grid container spacing={1} justify="center" alignItems="flex-end">
          <Grid item>
            <TextField
              label="busca"
              className={classes.textField}
              type="text"
              onChange={e => this.handleChange('busca', e.target.value)}
              value={form.busca}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={this.onBuscar}>
              Buscar
            </Button>
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }}>
          <ReactTable
            loading={loading}
            onFetchData={this.fetchData}
            pages={entregadores.paginacao && entregadores.paginacao.qtdePaginas}
            data={entregadores.lista}
            columns={[
              {
                maxWidth: 60,
                accessor: 'foto',
                Cell: props => <Avatar
                  variant="rounded" style={{ textAlign: 'center' }} src={props.value} />
              },
              {
                Header: 'Nome do Entregador',
                accessor: 'nome'
              },
              {
                width: 165,
                Cell: props => (
                  <div>
                    <IconButton
                      disabled={!this.podeEditar()}
                      onClick={() => this.openModalEditarCriarSistema(props.original)}
                      color="primary"
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                    <IconButton disabled={!this.podeDeletar()} onClick={() => this.delete(props.original)} color="secondary">
                      <Icon>delete</Icon>
                    </IconButton>
                  </div>
                )
              }
            ]}
          />
        </div>

        {modalEditarSistema.open && (
          <CriarEditarEntregadorDialog
            onHide={refresh => this.setState({ modalEditarSistema: { open: false } }, () => refresh === true && this.loadEntregadores())}
            data={modalEditarSistema.data}
          />
        )}
        {uploadFileModal.open && (
          <UploadFileModal
            onHide={refresh => this.setState({ uploadFileModal: { open: false } }, () => refresh === true && this.loadEntregadores())}
            title="Importação de Entregadores"
            tipo={5}
          />
        )}
      </div>
    )
  }
}

ListaEntregadores.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showMensagemConfirmacaoAction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction,
      entrarAction
    },
    dispatch
  )
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListaEntregadores)
)
