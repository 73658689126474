import React, { useState } from 'react'
import _ from 'lodash'
import { EMTable } from '@em/lib/src'
import Axios from 'axios'
import { Chip, Avatar, IconButton, Icon, Tooltip, ClickAwayListener } from '@material-ui/core'

import IconButtonEdit from '../../../_atoms/button/IconButtonEdit'
import IconButtonDelete from '../../../_atoms/button/IconButtonDelete'
import NumberFormatMoneyBR from '../../../_atoms/numberFormat/NumberFormatMoneyBR'
import { isNullOrUndefined } from '@em/lib/src/util/fnHelper'

export default props => {
  const { funcoes, onDelete, onEdit, filter, tableRef, title, openSplitModal } = props

  return (
    <EMTable
      tableRef={tableRef}
      title={title}
      data={(query, a, b) => {
        const { page, pageSize } = query
        let params = {}
        _.keys(filter).forEach(key => {
          if (!isNullOrUndefined(filter[key])) {
            params = { ...params, [key]: filter[key] }
          }
        })

        return Axios.get('api/item', { params: { ...params, offset: page, limit: pageSize } }).then(response => {
          return {
            data: response.lista,
            page,
            totalCount: response.paginacao.qtdeRows || 0
          }
        })
      }}
      columns={
        [
          {
            type: 'image',
            maxWidth: 50,
            align: 'center',
            render: row => (
              <Avatar
                variant="rounded"
                alt="foto"
                src={`${global.bucket}${row?.fotos ? row?.fotos[0] : null}`}
              />
            )
          },
          {
            title: 'Código',
            field: 'codigo',
            align: 'center'
          },
          {
            title: 'Nome do item',
            field: 'nome'
          },
          {
            title: 'Unid. Medida',
            field: 'unidadeMedida'
          },
          {
            title: 'Tags',
            field: 'tags',
            align: 'center',
            render: row => _.map(row.tags, (tag, idx) => <Chip key={idx} label={tag} variant="outlined" />)
          },
          {
            title: 'Valor',
            field: 'valorApartir',
            align: 'center',
            render: row => (
              <div>
                <NumberFormatMoneyBR value={row.valor} />
                {row.valorApartir !== row.valor &&
                  <span>
                    <br />
                    apartir de:
                    <NumberFormatMoneyBR value={row.valorApartir} />
                  </span>
                }
              </div>
            )
          },
          {
            title: 'Disponivel',
            align: 'center',
            field: 'qtdeDisponivel'
          },
          {
            title: 'Ativo',
            field: 'ativo',
            align: 'center',
            lookup: {
              true: <Chip size="small" variant="outlined" label='Sim' />,
              false: <Chip size="small" variant="outlined" style={{ borderColor: 'red', color: 'red' }} label='Não' />
            }
          },
          {
            align: 'right',
            width: 110,
            render: row => {
              const tooltip = !row?.transformTo?.id ? 'Este item não tem uma tranformação configurada'
                : (row?.controlaEstoque && row?.qtdeDisponivel == 0) ? 'Este item não tem estoque'
                  : 'Transformação de Item'
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title={tooltip} placement="top">
                    <div>
                      <IconButton
                        color="primary"
                        disabled={!row?.transformTo?.id || (row.controlaEstoque && !row.qtdeDisponivel)}
                        onClick={() => openSplitModal(row)}
                      >
                        <Icon>transform</Icon>
                      </IconButton>
                    </div>
                  </Tooltip>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      onEdit({
                        ..._.omit(row, ['id', '_id', 'qtdeDisponivel']),
                        nome: row.nome + '(CLONE)'
                      })
                    }}>
                    <Icon>control_point_duplicate</Icon>
                  </IconButton>
                  <IconButtonEdit
                    funcoes={funcoes}
                    onClick={() => {
                      onEdit(row)
                    }}
                  />
                  <IconButtonDelete
                    funcoes={funcoes}
                    onClick={() => {
                      onDelete(row)
                    }}
                    perguntar
                    mensagem={(
                      <span>
                        Você realmente deseja excluir o item
                        <strong>
                          {' '}
                          {row.nome}
                        </strong>
                        ?
                      </span>
                    )}
                  />
                </div>
              )
            }
          }

        ]}
    />
  )
}
