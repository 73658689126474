import { EMTable } from '@em/lib/src'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Icon,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Paper
} from '@material-ui/core'
import { FormatColorReset } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { showMensagemConfirmacaoAction } from './../../../actions/mensagemAction'
import Title from '@em/lib/src/util/Title'
import NumberFormatMoneyBR from '../../../_atoms/numberFormat/NumberFormatMoneyBR'
import TextFieldPercent from '../../../_atoms/numberFormat/TextFieldPercent'

const RangeSimplesNacional = {
  1: 4,
  2: 7.3,
  3: 9.5,
  4: 10.70,
  5: 14.30,
  6: 19.00
}

function FeedBackListModal ({
  onHide,
  listItemsImport,
  validColums,
  invalidColums,
  setListItemsImport,
  importConfirmed,
  feedbackApi,
  fieldItem,
  ...props
}) {
  const [calcVenda, setCalcVenda] = useState({
    calc: false,
    changed: false,
    sumFaixa: true,
    sumFixedExpence: true
  })
  const [sistema, setSistema] = useState({})

  const confirm = () => {
    const model = {
      title: 'Importar',
      mensagem: 'Você realmente deseja realizar a importação desses itens?',
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => {
            onHide()
            importConfirmed()
          }
        }
      ]
    }
    props.showMensagemConfirmacaoAction(model)
  }

  useEffect(() => {
    const funcAsync = async () => {
      if (!sistema.id) {
        const responseSistema = await axios.get('api/sistema/me')
        setSistema(responseSistema)
        setCalcVenda({
          ...calcVenda,
          formula: responseSistema?.tax?.formula,
          margin: responseSistema?.tax?.marginDefault
        })
      }
    }
    funcAsync()
  }, [])

  useEffect(() => {
    if (calcVenda.calc) {
      onCalc()
    }
  }, [calcVenda.calc])

  const onCalc = async () => {
    if (calcVenda.calc && listItemsImport.length > 0) {
      const itens = listItemsImport.map(item => {
        let percents = calcVenda.margin
        if (calcVenda?.sumFaixa && sistema?.tax?.range) {
          percents += RangeSimplesNacional[sistema.tax.range]
        }

        if (calcVenda?.sumFixedExpence && sistema?.tax?.fixedExpense) {
          percents += sistema.tax.fixedExpense
        }

        let multiplicador = 1
        if (sistema?.tax?.formula == 'marckup') {
          multiplicador = 1 + (percents / 100)
        } else {
          multiplicador = 100 / (100 - percents)
        }
        const valor = parseFloat(item.costPrice) * (multiplicador || 1)

        return { ...item, valor }
      })
      setCalcVenda({ ...calcVenda, changed: false })
      setListItemsImport(itens)
    }
  }

  return (
    <Dialog open maxWidth={FormatColorReset} fullWidth>
      <Title
        title={`${feedbackApi ? 'Retorno' : 'Prévia'} da Importação`}
        buttons={[
          {
            icon: 'close',
            color: 'secondary',
            onClick: onHide
          }
        ]}
      />
      <DialogContent>
        <Grid item container spacing={1}>
          {invalidColums?.length > 0 &&
            <Grid item xs={12}>
              <div><b>Estas colunas são inválidas para a importação e serão ignoradas: </b></div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {invalidColums.map(key => (<Chip key={key} label={key} style={{ backgroundColor: '#c00000', color: '#fff', margin: 5 }} />))}
              </div>
            </Grid>
          }
          <Grid item xs={12}>
            <EMTable
              columns={validColums.map(key => {
                const field = fieldItem?.find(x => x.label === key)?.value
                return {
                  title: key,
                  field,
                  align: field === 'feedback' ? 'center' : '',
                  width: field === 'feedback' ? 50 : '',
                  render: row => {
                    if (field === 'feedback') {
                      return (
                        <Tooltip title={row?.feedback?.messageFeedback || ''}>
                          <div>
                            {row?.feedback?.status === 0 && <Icon style={{ color: '#c00000' }}>error</Icon>}
                            {row?.feedback?.status === 1 && <Icon style={{ color: '#009900' }}>check_circle</Icon>}
                            {row?.feedback?.status === 2 && <Icon style={{ color: '#009900' }}>check_circle</Icon>}
                            {row?.feedback?.status === 3 && <Icon style={{ color: '#f2ac11' }}>warning</Icon>}
                          </div>
                        </Tooltip>
                      )
                    }
                    return row[field]
                  }
                }
              })}
              data={listItemsImport}
              options={{
                pageSize: 10
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {!feedbackApi &&
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={confirm}
          >
            Concluir
          </Button>
        </DialogActions>}
    </Dialog>
  )
}
const mapDispatchToProps = dispatch => bindActionCreators({
  showMensagemConfirmacaoAction
},
dispatch)

export default connect(null, mapDispatchToProps)(FeedBackListModal)
