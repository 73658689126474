import React, { useEffect, useState } from 'react'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  useMediaQuery,
  IconButton,
  Fab,
  Slide,
  Fade
} from '@material-ui/core'
import Axios from 'axios'
import { withRouter } from 'react-router-dom'
import Title from '@em/lib/src/util/Title'
import { changeValorAuxItem } from '@em/lib/src/Produtos/somasCaixa2'

import { TextField } from './../../../../_atoms'
import ClienteSearchDialog from './clienteSearchDialog'
import { TextFieldTelefone, NumberFormatMoneyBR } from './../../../../_atoms/numberFormat'
import TextFieldCPFCNPJ from './../../../../_atoms/numberFormat/TextFieldCPFCNPJ'
import TextFieldCEP from './../../../../_atoms/numberFormat/TextFieldCEP'
import { ComboBoxAPI } from '../../../../_atoms/combobox'
import TextFielddMoneyBR from '../../../../_atoms/numberFormat/TextFieldMoneyBR'

const Transition = React.forwardRef(function Transition (props, ref) {
  const isMobile = useMediaQuery('(max-width:900px)')
  if (isMobile) {
    return <Slide direction="up" ref={ref} {...props} />
  }
  return <Fade ref={ref} {...props} />
})

const ClienteDialog = props => {
  const { onHide, onRefreshPedido, pedido, add } = props
  const isMobile = useMediaQuery('(max-width:900px)')
  const [cliente, setCliente] = useState({
    nome: '',
    cpf: '',
    telefone: '',
    email: '',
    ...pedido.cliente
  })
  const [endereco, setEndereco] = useState({
    rua: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    pais: '',
    referencia: '',
    observacao: '',
    loadApi: false,
    ...pedido.endereco
  })
  const [searchClientesModal, setSearchClientesModal] = useState({ open: false })
  const [showError, setShowError] = useState(false)
  const [frete, setFrete] = useState({ valor: pedido.valorFrete, repasse: pedido.valorFreteRepasse })

  const onOk = () => {
    if (endereco?.cep ||
      endereco?.rua ||
      endereco?.numero ||
      endereco?.bairro ||
      endereco?.cidade
    ) {
      const required = [
        endereco?.cep,
        endereco?.rua,
        endereco?.numero,
        endereco?.bairro,
        endereco?.cidade
      ]
      let error = false

      required.forEach(r => {
        if (!r) {
          error = true
        }
      })

      if (error) {
        setShowError(true)
        return
      }
    }

    pedido.itens = changeValorAuxItem(pedido.itens, cliente?.entidade?.discount)
    onRefreshPedido({ ...pedido, cliente, endereco, valorFrete: frete.valor, valorFreteRepasse: frete.repasse })
    onHide()
  }

  const handlerChangeCliente = e => {
    setCliente({ ...cliente, [e.target.name]: e.target.value })
  }

  const handlerChangeendereco = e => {
    const name = e.target.name
    setEndereco({ ...endereco, [name]: e.target.value })
  }

  const handlerChangeenderecoCEP = e => {
    setEndereco({
      ...endereco,
      cep: e.target.value,
      rua: '',
      bairro: '',
      cidade: '',
      complemento: '',
      loadApi: true
    })

    if (e.target.value.length == 9) {
      onSearchCEP(e.target.value)
    }
  }

  useEffect(() => {
    if (endereco?.cep?.replace('-', '')?.length == 8 && endereco.loadApi) {
      onSearchConector(endereco.cep)
    }
  }, [endereco?.cep])

  const onSearchCEP = (cep) => {
    Axios.get('/api/Localidade/' + cep).then(response => {
      setEndereco({ ...endereco, ...response, estado: response?.uf, loadApi: false })
    })
  }

  const onSearchConector = cep => {
    Axios.get(`/api/ConectorEnvio/cep/${cep}`)
      .then(resp => {
        const [shipping] = resp
        setFrete({ valor: shipping?.valor, repasse: shipping?.valorFreteRepasse })
      })
  }

  const onSelectCliente = (cli, end, tel) => {
    let endereco = {}
    const { nome, id, cpfCnpj, entidade, foto } = cli
    const clienteAux = { nome, id, cpf: cpfCnpj, entidade, foto }
    if (end) {
      endereco = {
        rua: end.endereco,
        numero: end.numero,
        bairro: end.bairro,
        cidade: end.cidade,
        cep: end.cep,
        cpf: end.cpf,
        referencia: end.referencia,
        complemento: end.complemento,
        estado: end.estado,
        loadApi: true
      }
    }
    if (tel) {
      clienteAux.telefone = tel.numero
    }
    setCliente(clienteAux)
    setEndereco(endereco)
    setSearchClientesModal({ open: false })
  }

  const buttons = []
  buttons.push({ icon: 'close', color: 'secondary', onClick: onHide })

  const {
    nome, cpf, telefone, id, email, entidade
  } = cliente

  const {
    rua, numero, bairro, cidade, cep, referencia, complemento, observacao
  } = endereco

  return (
    <>
      <Dialog
        open
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        TransitionComponent={Transition}
      >
        <Title
          component={
            <>
              {!add && !isMobile &&
                <Button
                  onClick={() => setSearchClientesModal({ open: true })}
                  color="primary"
                  variant="outlined"
                  startIcon={<Icon>search</Icon>}>
                  Buscar cliente
                </Button>
              }
              {!add && isMobile &&
                <Fab
                  variant="extended"
                  style={{ backgroundColor: '#FFFFFF', marginRight: 5 }}
                  onClick={() => setSearchClientesModal({ open: true })}
                >
                  <Icon>search</Icon>
                </Fab>
              }
            </>
          }
          title={id ? `${nome.split(' ')[0]}` : 'Novo Cliente'} buttons={buttons}
        />

        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6}>
              <TextField label="Nome do Cliente" name="nome" value={nome} onChange={handlerChangeCliente} />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldCPFCNPJ label="CPF do Cliente" name="cpf" value={cpf} onChange={handlerChangeCliente} />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldTelefone value={telefone} name="telefone" label="Telefone" onChange={handlerChangeCliente} />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField label="Email" name="email" value={email} onChange={handlerChangeCliente} />
            </Grid>
            <Grid item xs={12} md={4}>
              <ComboBoxAPI
                label="Grupo"
                onChange={e => handlerChangeCliente({ target: { name: 'entidade', value: e } })}
                default={entidade}
                value={entidade?.id}
                api="api/entidade"
              />
            </Grid>
            <Grid item xs={12}>
              <h3>Endereço para entrega do pedido</h3>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextFieldCEP
                label="CEP"
                name="cep"
                value={cep || ''}
                onChange={handlerChangeenderecoCEP}
                error={showError && !cep}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField label="Rua" name="rua" value={rua} onChange={handlerChangeendereco} error={showError && !rua} />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label="Numero" name="numero" value={numero} onChange={handlerChangeendereco} error={showError && !numero} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Complemento" name="complemento" value={complemento} onChange={handlerChangeendereco} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Bairro" name="bairro" value={bairro} onChange={handlerChangeendereco} error={showError && !bairro} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Cidade" name="cidade" value={cidade} onChange={handlerChangeendereco} error={showError && !cidade} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Ponto de Referencia" name="referencia" value={referencia} onChange={handlerChangeendereco} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Observação" name="observacao" value={observacao} onChange={handlerChangeendereco} />
            </Grid>
            <Grid item xs={12}>
              <h3>Opções de Frete</h3>
            </Grid>
            <Grid item xs={12}>
              <TextFielddMoneyBR
                label="Valor de Frete"
                name="referencia"
                value={frete.valor}
                onValueChange={e => {
                  setFrete({ ...frete, valor: e.target.value, repasse: e.target.value })
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{ color: '#aaa' }}>
            Valor de frete à ser aplicado: <NumberFormatMoneyBR value={frete.valor} />
            <br />
            Valor de repasse: <NumberFormatMoneyBR value={frete.repasse} />
          </div>
          <div style={{ flex: 1 }} />
          <Button color="primary" variant="contained" onClick={onOk}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {searchClientesModal.open && (
        <ClienteSearchDialog
          pedido={pedido}
          onRefreshPedido={onRefreshPedido}
          onSelect={onSelectCliente}
          onHide={() => setSearchClientesModal({ open: false })}
        />
      )}
    </>
  )
}

export default withRouter(ClienteDialog)
