import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'

const styles = () => ({})

class TabWhatsAppCriarEditarDialog extends Component {
  state = {
    form: { ...this.props.data }
  }

  handlerChange = e => {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    })
  };

  render() {
    const { form } = this.state
    const { data } = this.props
    const BUTTON_TEXT = (data && 'Editar') || 'Criar'
    return (
      <Dialog open maxWidth="md" fullWidth>
        <Title title={`${(data && 'Editar') || 'Criar'} contato`} />
        <DialogContent style={{ padding: 30 }}>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                fullWidth
                label="name"
                value={(form && form.name) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="host"
                fullWidth
                label="Host"
                value={(form && form.host) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="apiKey"
                fullWidth
                label="apiKey"
                value={(form && form.apiKey) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="whatsAppId"
                fullWidth
                label="whatsAppId"
                value={(form && form.whatsAppId) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onHide} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => this.props.onAdd(form)} color="primary" variant="contained">
            {BUTTON_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TabWhatsAppCriarEditarDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default withStyles(styles)(TabWhatsAppCriarEditarDialog)
