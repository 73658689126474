import { setCookie } from '@em/lib/src/util/fnHelper'
import { REHYDRATE } from 'redux-persist'
import { LOGIN, LOGOUT, SETSISTEMAS } from '../contstants/actionsContants'

const INITIAL_STATE = {
  token: '',
  pessoa: {
    id: '',
    nome: ''
  },
  sistema: {
    id: '',
    modulos: []
  },
  sistemas: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.key === LOGIN) {
        return { ...state, ...action.payload }
      }
      return state
    case LOGIN:
      setCookie("token", action.payload.token, 1)
      return { ...state, ...action.payload, logado: true }
    case SETSISTEMAS:
      return { ...state, sistemas: action.payload }
    case LOGOUT:
      return INITIAL_STATE
    default:
      break
  }

  return state
}
