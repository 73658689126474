import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  IconButton,
  Avatar,
  InputAdornment
} from '@material-ui/core'

import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import UploadImageDialog from '../../../_atoms/uploadImageDialog'
import AddOrEditPageModal from './addOrEditPageModal'
import TextFieldColor from '../../../_atoms/TextFieldColor'
import TextFieldJSON from '../../../_atoms/TextFieldJSON'
import Sessions from './sessions'

const INITIAL_FORM = {
  dominio: '',
  title: '',
  style: '',
  logo: '',
  favicon: '',
  menu: 1,
  ga: '',
  metaDescription: '',
  portal: '',
  pages: []
}

export { INITIAL_FORM }

const AddOrEditModal = (props) => {
  const [isJSON, setIsJSON] = useState(false)
  const [form, setForm] = useState({
    ...INITIAL_FORM,
    ...props.data,
    id: !props.duplicate ? props.data.id : ''
  })

  const [modalAddOrEdit, setModalAddOrEdit] = useState({ open: false })
  const [modalUpload, setModalUpload] = useState({ open: false })
  const [whatsApp, setWhatsapp] = useState(
    props.data && props.data.whatsApp && JSON.parse(props.data.whatsApp)
  )
  const [style, setStyle] = useState(
    props.data && props.data.style && JSON.parse(props.data.style)
  )
  // const [styleMenu, setStyleMenu] = useState(
  //   props.data && props.data.styleMenu && JSON.parse(props.data.styleMenu)
  // )

  const onSave = () => {
    axios
      .post('api/site', {
        ...form,
        style: JSON.stringify(style),
        // styleMenu: JSON.stringify(styleMenu),
        whatsApp: JSON.stringify(whatsApp)
      })
      .then((resp) => {
        props.onHide(true)
      })
  }

  const handlerChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handlerChangeStyle = (e) => {
    setStyle({ ...style, [e.target.name]: e.target.value })
  }

  const handlerChangeWhats = (e) => {
    setWhatsapp({ ...whatsApp, [e.target.name]: e.target.value })
  }

  const onHideSave = (page, idx) => {
    if (page) {
      if (idx == -1) {
        setForm({ ...form, pages: [...(form.pages || []), page] })
      } else {
        form.pages[idx] = page
        setForm({ ...form, pages: form.pages })
      }
    }
    setModalAddOrEdit({ open: false })
  }

  let title = 'Novo Pagina'
  if (form.id) {
    title = 'Edição da pagina ' + props.data.dominio
  } else if (props.duplicate) {
    title = 'Duplicando pagina: ' + props.data.dominio
  }

  const DialogContentInputs = () => {
    return (
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <center>
              <IconButton
                color="primary"
                onClick={() =>
                  setModalUpload({
                    open: true,
                    type: 'logo',
                    src: `${global.bucket}${form.logo}`
                  })
                }
              >
                <Avatar
                  variant="rounded"
                  style={{ width: 100, height: 100 }}
                  alt="Logo"
                  src={`${global.bucket}${form.logo}`}
                />
              </IconButton>
            </center>
          </Grid>

          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  label="Dominio do Site"
                  name="dominio"
                  value={form && form.dominio}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Title e Favicon"
                  name="title"
                  value={form && form.title}
                  onChange={handlerChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ padding: 0 }}
                          color="primary"
                          onClick={() =>
                            setModalUpload({
                              open: true,
                              type: 'favicon',
                              src: `${global.bucket}${form.favicon}`
                            })
                          }
                        >
                          <Avatar
                            variant="rounded"
                            alt="Favicon"
                            src={`${global.bucket}${form.favicon}`}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  label="Espaçamento Geral"
                  name="padding"
                  value={style && style.padding}
                  onChange={handlerChangeStyle}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldColor
                  label="Cor de fundo"
                  name="backgroundColor"
                  value={style && style.backgroundColor}
                  onChange={handlerChangeStyle}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldColor
                  label="Cor de texto"
                  name="color"
                  value={style && style.color}
                  onChange={handlerChangeStyle}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Fonte Familia"
                  name="fontFamily"
                  value={style && style.fontFamily}
                  onChange={handlerChangeStyle}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Whatsapp - Numero"
                  name="numero"
                  value={whatsApp && whatsApp.numero}
                  onChange={handlerChangeWhats}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Whatsapp - Mensagem de boas vindas"
                  name="texto"
                  value={whatsApp && whatsApp.texto}
                  onChange={handlerChangeWhats}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Código google analytics - SERÁ DESCONTINUADO 2021-02-23"
                  name="ga"
                  value={form.ga}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Facebook Pixel - SERÁ DESCONTINUADO 2021-02-23"
                  name="facebookPixel"
                  value={form.facebookPixel}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Facebook AppId"
                  name="facebookAppId"
                  value={form.facebookAppId}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Meta Descrição Google"
                  name="metaDescription"
                  value={form.metaDescription}
                  onChange={handlerChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldJSON
                  name={'tema'}
                  label={'Tema'}
                  onChange={handlerChange}
                  value={form?.tema}
                  defaultJSON={{
                    palette: {
                      primary: {
                        main: '#6b1b79'
                      },
                      secondary: {
                        light: '#0066ff',
                        main: '#0044ff',
                        contrastText: '#ffcc00'
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Title
          title="Onepage"
          buttons={[
            {
              icon: 'add',
              onClick: () => setModalAddOrEdit({ open: true, idx: -1 })
            }
          ]}
        />

        <Sessions
          form={form}
          setForm={setForm}
          setModalAddOrEdit={setModalAddOrEdit}
          onDelete={(row) => {
            form.pages.splice(row.tableData.id, 1)
            setForm({ ...form, pages: form.pages })
          }}
        />
      </DialogContent>
    )
  }

  return (
    <div>
      <Dialog maxWidth={false} fullWidth open onClose={props.onHide}>
        <Title
          title={title}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        {isJSON
          ? <TextField
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={20}
            onChange={e => setForm({ ...(JSON.parse(e.target.value)) })}
            value={JSON.stringify(_.omit(form, ['id', '_id', 'tokenPublico']))}
          />
          : DialogContentInputs()}
        <DialogActions>
          <Button color="primary" onClick={() => setIsJSON(!isJSON)}>
            Visualização em {isJSON ? 'INPUTS' : 'JSON'}
          </Button>
          <div style={{ flex: 1 }} />
          <Button color="primary" onClick={onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      {modalAddOrEdit.open && (
        <AddOrEditPageModal
          open={modalAddOrEdit.open}
          data={modalAddOrEdit.data}
          onHide={(p) => onHideSave(p, modalAddOrEdit.idx)}
          title="Session"
          okText={'OK'}
        />
      )}

      {modalUpload.open && (
        <UploadImageDialog
          title={`Alterar ${modalUpload.type}`}
          tag="Site"
          src={modalUpload.src}
          onHide={() => setModalUpload({ open: false })}
          onCapture={(img) => {
            setForm({ ...form, [modalUpload.type]: img })
            setModalUpload({ open: false })
          }}
        />
      )}
    </div>
  )
}

export default AddOrEditModal
