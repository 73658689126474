import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Icon,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Chip,
  Menu,
  MenuItem,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Popover
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { TextField } from './../../../../_atoms'
import { NumberFormatMoneyBR } from './../../../../_atoms/numberFormat'
import MontagemDialog from './montagemDialog'
import { EMImageZoom, EMTable } from '@em/lib/src'
import somas from '@em/lib/src/Produtos/somasCaixa2'
import Axios from 'axios'
import TextFielddMoneyBR from '@em/lib/src/util/InputCustom/TextFieldMoneyBR'
import { isNullOrUndefined } from '@em/lib/src/util/fnHelper'

const PedidoItens = ({
  pedido, onOpenMesas, onOpenSearchItens, search,
  handlerChange, mesas, openAdmsModal, allowEditByAdm, show,
  onSave, onRefreshPedido
}) => {
  const [montagemModal, setMontagemModal] = useState({ open: false })
  const [opcoesMenu, setOpcoesMenu] = useState({ anchorEl: null })
  const [itemAtual, setItemAtual] = useState({})
  const [idxAtual, setIdxAtual] = useState(null)
  const [valorPopover, setValorPopover] = useState({ open: false })

  useEffect(() => {
    if (pedido?.itens?.length > 0) {
      const itens = pedido.itens || []
      itens.forEach((itemAtual, idx) => {
        if (itemAtual.showComposicao) {
          setMontagemModal({ open: true })
          setItemAtual(itemAtual)
          setIdxAtual(idx)
        }
      })
    }
  }, [pedido?.itens?.length])

  const handleChangeItem = (item, name, value) => {
    item[name] = value
    onRefreshPedido(pedido)
  }

  const applyNewAmount = async (pedidoItem, newValorComDesconto, descontoCliente, valorComponentes) => {
    const responseItem = await Axios.get(`api/Item/${pedidoItem?.id}`)

    let retorno = false
    if (responseItem && responseItem[0]) {
      const itemOriginal = responseItem[0]

      let permite = false
      let valorBlock = itemOriginal.valor

      if (!itemOriginal?.minAmount || itemOriginal?.minAmount === 0) {
        const desconto = ((itemOriginal.valor * descontoCliente) / 100)
        permite = newValorComDesconto >= (itemOriginal.valor - (desconto || 0))
        valorBlock = (itemOriginal.valor - (desconto || 0))
      } else if (itemOriginal?.minAmount && itemOriginal?.minAmount > 0) {
        permite = newValorComDesconto >= (itemOriginal?.minAmount || 0)
        valorBlock = itemOriginal?.minAmount
      }

      if (permite) {
        retorno = true
      } else {
        show({
          message: `Você não pode reduzir o valor do item para menos que R$ ${Number(valorBlock + valorComponentes || 0).toFixed(2).replace('.', ',')}!`,
          title: 'Alteração Inválida'
        }, 'error')
      }
    }

    return retorno
    // console.log(pedidoItem)
  }

  const handleChangeItemValor = async (item, value, valorComponentes = 0) => {
    const valorTotalSemComponentes = value - valorComponentes

    const allowChange = await applyNewAmount(item, parseFloat(valorTotalSemComponentes), pedido?.cliente?.entidade?.discount, valorComponentes)

    if (allowChange) {
      pedido.itens[item.tableData?.id].valorAux = parseFloat((parseFloat(valorTotalSemComponentes) - parseFloat(pedido.itens[item.tableData?.id].valor)).toFixed(2))
      setTimeout(() => {
        onRefreshPedido(pedido)
        setValorPopover({ open: false })
      }, 1)
    }
  }

  const handleChangeItem2 = item => {
    pedido.itens[idxAtual] = item
    onRefreshPedido(pedido)
    setMontagemModal({ open: false })
  }

  const onCloneItem = () => {
    const itens = pedido.itens || []
    onRefreshPedido({ ...pedido, itens: [...itens, _.cloneDeep(itemAtual)] })
  }

  const onRemoveConfirmation = () => {
    _.pullAt(pedido.itens, idxAtual)
    onRefreshPedido(pedido)
  }

  const itens = pedido.itens || []

  somas(pedido)

  itens.forEach(item => {
    item._valor = (item.valor || 0) + (item.valorAux || 0)
    item.valor = parseFloat((item.valor || 0)?.toFixed(2))
  })

  const edicaoBloqueada = pedido?.statusEntregaJoin?.blockEdit && !allowEditByAdm
  return (
    <>
      <div style={{ padding: 10, backgroundColor: '#BABABA' }}>
        <Grid container spacing={1} alignItems="center">
          {pedido.canal != 2 && (
            <>
              {pedido.canal != 1 && mesas && mesas.length > 0 && (
                <Grid item>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    disabled={(edicaoBloqueada)}
                    onClick={() => onOpenMesas(pedido.mesas)}
                  >
                    {`Mesa: ${pedido?.mesas}`}
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <TextField
                  label="Código do item / EAN"
                  name="search"
                  value={search}
                  onKeyPress={(e) => e.key === 'Enter' && onOpenSearchItens(search)}
                  onChange={handlerChange}
                  disabled={(edicaoBloqueada)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="outlined" position="end">
                        <IconButton
                          onClick={() => onOpenSearchItens(search)}
                          disabled={(edicaoBloqueada)}
                        >
                          <Icon>add</Icon>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              {(edicaoBloqueada) &&
                <Grid item>
                  <Tooltip
                    title="Desbloquear Edição"
                    placement="top"
                  >
                    <IconButton
                      onClick={() => openAdmsModal()}
                    >
                      <Icon color="primary">
                        lock
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              }
              <Grid item>
                <Tooltip
                  title="Salvar Pedido"
                  placement="top"
                >
                  <IconButton
                    onClick={onSave}
                    disabled={pedido.canal == 2}
                  >
                    <Icon color="primary">
                      save
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <EMTable
              data={itens}
              columns={[
                // {
                //   width: 20,
                //   render: row => (
                //     <Checkbox
                //       onChange={() => handleChangeItem(row, 'viagem', !row.viagem)}
                //       checked={row.viagem}
                //       icon={<Icon>stop</Icon>}
                //       checkedIcon={<Icon>flight</Icon>}
                //       disabled={(edicaoBloqueada)}
                //     />
                //   )
                // },
                {
                  maxWidth: 75,
                  render: row => (
                    <EMImageZoom
                      width={50}
                      height={50}
                      scale={5}
                      zoomPosition="right"
                      src={row.foto && (global.bucket + row.foto)}
                      disabled={(edicaoBloqueada)}
                    />
                  )
                },
                {
                  maxWidth: '100%',
                  width: '100%',
                  title: 'Código',
                  render: row => {
                    const valorUnitarioSemDesconto = parseFloat((row._valorTotalItemSemDesconto / row.qtde).toFixed(2))
                    const valorItem = parseFloat(row.valor.toFixed(2))
                    const valorComponentes = parseFloat((valorUnitarioSemDesconto - valorItem).toFixed(2))
                    return (
                      <div>
                        {`${row.codigo} - ${row.nome}`}
                        <br />
                        {row.valorAux != 0 && !isNullOrUndefined(row.valorAux) &&
                          <Chip size="small" style={{ color: row.valorAux < 0 ? 'red' : 'green' }} label={
                            <NumberFormatMoneyBR value={row.valorAux * row.qtde} />
                          } />
                        }
                        {valorComponentes > 0 &&
                          <Chip size="small" style={{ color: 'blue' }} label={
                            <NumberFormatMoneyBR value={valorComponentes * row.qtde} />
                          } />
                        }
                      </div>
                    )
                  }
                },
                {
                  maxWidth: 150,
                  title: 'Qtde',
                  align: 'center',
                  render: row => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        size="small"
                        disabled={row.qtde <= 1 || pedido.canal == 2 || (edicaoBloqueada)}
                        onClick={() => row.qtde > 1 && handleChangeItem(row, 'qtde', row.qtde - 1)}
                      >
                        <Icon>remove</Icon>
                      </IconButton>
                      {row.qtde}
                      <IconButton
                        size="small"
                        disabled={pedido.canal == 2 || (edicaoBloqueada)}
                        onClick={() => handleChangeItem(row, 'qtde', row.qtde + 1)}>
                        <Icon>add</Icon>
                      </IconButton>

                    </div>
                  )
                },
                {
                  title: 'Total',
                  maxWidth: 150,
                  render: row => {
                    const valorUnitarioSemDesconto = parseFloat((row._valorTotalItemSemDesconto / row.qtde).toFixed(2))
                    const valorItem = parseFloat(row.valor.toFixed(2))
                    const valorComponentes = parseFloat((valorUnitarioSemDesconto - valorItem).toFixed(2))

                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Chip
                          size="small"
                          disabled={(edicaoBloqueada)}
                          onClick={(e) => setValorPopover({
                            open: true,
                            valor: row._valor + valorComponentes,
                            valorComponentes,
                            item: row,
                            anchorEl: e.currentTarget
                          })}
                          label={
                            <>
                              <NumberFormatMoneyBR value={row._valor + valorComponentes} /> por {row.unidadeMedida || 'un'}
                            </>
                          }
                        />
                        <br />
                        <span style={{ fontSize: 16, fontWeight: 900 }}>
                          <NumberFormatMoneyBR value={row._valorTotal} />
                        </span>
                      </div>
                    )
                  }
                },
                {
                  maxWidth: 75,
                  render: row => (
                    <IconButton
                      aria-label="More"
                      aria-owns={opcoesMenu.anchorEl ? 'long-menu' : undefined}
                      aria-haspopup="true"
                      onClick={e => {
                        setOpcoesMenu({ anchorEl: e.currentTarget })
                        setItemAtual(row)
                        setIdxAtual(row.tableData.id)
                      }}
                      disabled={pedido.canal == 2 || (edicaoBloqueada)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )
                }
              ]}
              detailPanel={[
                (item) => {
                  let qdeOpcoes = 0
                  item?.montagens?.forEach(monta => {
                    qdeOpcoes += monta?.opcoes?.length
                  })
                  return {
                    tooltip: `${qdeOpcoes} Componentes`,
                    icon: (item?.montagens?.length > 0) ? 'chevron_right' : '0',
                    disabled: !(item?.montagens?.length > 0),
                    render: row => {
                      if (row.montagens) {
                        return (
                          <>
                            {row.montagens.map((monta, idx) => {
                              if (monta._qtde > 0) {
                                return (
                                  <div
                                    key={idx}
                                    style={{
                                      padding: 20,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <strong>{monta.titulo}</strong>
                                    <div style={{ marginLeft: 10 }}>
                                      {monta.opcoes.map((opc, idx2) => {
                                        if (opc._qtde > 0) {
                                          return (
                                            <Chip
                                              size="small"
                                              key={`${idx}-${idx2}`}
                                              variant="outlined"
                                              label={`${opc._qtde}x ${opc.nome}: R$ ${Number(opc.valor).toFixed(2)}`}
                                              style={{ borderColor: 'blue', color: 'blue' }}
                                            />
                                          )
                                        }
                                        return null
                                      })}
                                    </div>
                                  </div>
                                )
                              }
                            })}
                          </>
                        )
                      }
                      return null
                    }
                  }
                }
              ]}
              options={{
                pageSize: 1000,
                paging: false,
                header: false
              }}
            />
          </Grid>

          {itens && itens.length > 0 && (
            <Grid item xs={12}>
              <Table style={{ borderCollapse: 'separate', borderSpacing: 2 }}>
                <TableBody>
                  {itens.map((item, idx) => {
                    const stBorder = `2px solid ${item._valid ? '#ccc' : '#f77'}`
                    const borderBottom = stBorder
                    const borderLeft = stBorder
                    const borderRight = stBorder
                    return (
                      <React.Fragment key={idx}>
                        {item.observacao && (
                          <TableRow>
                            <TableCell colSpan={6} style={{ borderBottom, borderLeft, borderRight }}>
                              <div key={idx} style={{ padding: '0 10px 3px 10px', display: 'flex', alignItems: 'center' }}>
                                <strong>Observação</strong>
                                <div style={{ marginLeft: 10 }}>{item.observacao}</div>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </Grid>
          )
          }
        </Grid>
      </div>

      {montagemModal.open && (
        <MontagemDialog
          hcItem={item => handleChangeItem2(item)}
          item={itemAtual}
          onHide={() => setMontagemModal({ open: false })}
        />
      )}

      <Popover
        id="Popover"
        open={valorPopover.open}
        anchorEl={valorPopover.anchorEl}
        onClose={() => setValorPopover({ open: false })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <TextFielddMoneyBR
          value={valorPopover?.valor}
          label={`Novo valor por ${valorPopover?.item?.unidadeMedida || 'un'}`}
          onKeyPress={(e) => e.key == 'Enter' && handleChangeItemValor(valorPopover.item, valorPopover.valor, valorPopover?.valorComponentes)}
          InputProps={{
            endAdornment: (
              <InputAdornment variant="outlined" position="end">
                <IconButton onClick={() => handleChangeItemValor(valorPopover.item, valorPopover.valor, valorPopover?.valorComponentes)}>
                  <Icon>check</Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
          onValueChange={(e) => setValorPopover({ ...valorPopover, valor: e.target.value })}
        />
      </Popover>

      <Menu
        id="long-menu"
        anchorEl={opcoesMenu.anchorEl}
        open={!!opcoesMenu.anchorEl}
        onClose={() => setOpcoesMenu({ anchorEl: null })}
      >
        <MenuItem
          disabled={!itemAtual.montagens}
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            setMontagemModal({ open: true })
          }}
        >
          Editar Composição
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            onCloneItem()
          }}
        >
          Clonar item
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpcoesMenu({ anchorEl: null })
            onRemoveConfirmation()
          }}
        >
          Deletar item
        </MenuItem>
      </Menu>
    </>
  )
}

// class PedidoItens extends React.Component {
//   state = {

//   };

//   // componentWillReceiveProps(nextProps) {
//   //   if (nextProps.pedido.itens != props.pedido.itens) {
//   //     const { pedido } = nextProps
//   //     const itens = pedido.itens || []
//   //     itens.forEach((itemAtual, idxAtual) => {
//   //       if (itemAtual.showComposicao) {
//   //         setState({ montagemModal: { open: true }, itemAtual, idxAtual })
//   //       }
//   //     })
//   //   }
//   // }

// }

export default PedidoItens
