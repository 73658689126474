export default [
  {
    id: 'UNID',
    nome: 'UNIDADE'
  },
  {
    id: 'LT',
    nome: 'LT (LITRO)'
  },
  {
    id: 'PC',
    nome: 'PC (PEÇA)'
  },
  {
    id: 'JOGO',
    nome: 'JOGO'
  },
  {
    id: 'KIT',
    nome: 'KIT'
  },
  {
    id: 'M',
    nome: 'M (METRO)'
  },
  {
    id: 'CX',
    nome: 'CX (CAIXA)'
  },
  {
    id: 'ROLO',
    nome: 'ROLO'
  },
  {
    id: 'KG',
    nome: 'KG (QUILOGRAMA)'
  },
  {
    id: 'GRAMAS',
    nome: 'GRAMAS'
  },
  {
    id: 'DUZIA',
    nome: 'DUZIA'
  },
  {
    id: 'PACOTE',
    nome: 'PACOTE'
  },
  {
    id: 'ML',
    nome: 'ML (MILILITRO)'
  }
]
