import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '..'

class TextSearch extends Component {
  constructor(props) {
    super(props)
    this.state = { searchText: '' }
  }

  render() {
    const { label, onBuscar } = this.props
    return (
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <TextField
            label={label}
            type="text"
            onChange={e => this.setState({ searchText: e.target.value })}
            value={this.state.searchText}
          />

        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={() => onBuscar(this.state.searchText)}>
                        Buscar
          </Button>
        </Grid>
      </Grid>
    )
  }
}

export default TextSearch
