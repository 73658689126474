import React, { Component, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent,
  Button,
  Grid,
  DialogActions
} from '@material-ui/core'
import TextFielddMoneyBR from '@em/lib/src/util/InputCustom/TextFieldMoneyBR'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import { ComboBoxData } from '../../../../_atoms/combobox'
import Title from '@em/lib/src/util/Title'
import { Fieldset } from '../../../../_atoms'

const FormasPagamentoDialog = ({ onHide, formasDePagamento, addPay, initialValue }) => {
  const [form, setForm] = useState({ valor: initialValue })
  const [showError, setShowError] = useState(false)
  const [formPgto, setFormPgto] = useState({})

  const validToPay = () => {
    if (!form.valor || !formPgto.id) {
      setShowError(true)
      return
    }
    addPay({ ...formPgto, ...form })
  }

  return (
    <Dialog open fullWidth maxWidth="md">
      <Title
        title={<span>Aplicar Pagamento - Falta <NumberFormatMoneyBR value={(initialValue || 0)} /></span>}
        buttons={[
          { icon: 'close', color: 'secondary', onClick: onHide }
        ]}
      />
      <DialogContent>

        <Fieldset label="Dividir a conta">
          <Grid container spacing={2} alignItems="center" justify="center">
            {[1, 2, 3, 4].map((personQty, idx) => {
              const valorCalc = Number((initialValue / personQty).toFixed(2))
              const selected = valorCalc == form.valor
              return (
                <Grid item>
                  <Button
                    key={idx}
                    variant="contained"
                    style={{
                      backgroundColor: selected ? '#228B22' : '#FFFFFF',
                      color: selected && '#FFFFFF',
                    }}
                    onClick={e => setForm({ ...form, valor: valorCalc })}
                  >
                    <span>
                      {personQty}
                      <br />
                      <NumberFormatMoneyBR value={valorCalc} />
                    </span>
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </Fieldset>


        <Grid container spacing={1} style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <ComboBoxData
              label="Escolha a forma de pagamento"
              error={showError && !formPgto.id}
              onChange={e => setFormPgto(e)}
              selected={formPgto.id}
              style={{ width: 400 }}
              list={formasDePagamento || []}
            />
          </Grid>
          <Grid item xs>
            <TextFielddMoneyBR
              fullWidth
              label="Valor"
              name="valor"
              error={showError && !form.valor}
              value={form.valor}
              onValueChange={e => setForm({ ...form, valor: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={validToPay}>Adicionar Pagamento</Button>
      </DialogActions>
    </Dialog>
  )

}


export default FormasPagamentoDialog
