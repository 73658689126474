import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogActions, Grid
} from '@material-ui/core'
import TabPessoa from './tabPessoa'
import TabTelefone from './tabTelefone'
import TabEmail from './tabEmail'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import TabLicenca from './tabLicença'

class CriarEditarEntregadorDialog extends React.Component {
  state = {
    form: this.props.data || {
      tipo: 'PF'
    },
    showErros: false,
    activeStep: 0
  };

  onSave = () => {
    const { contexto } = this.props
    const data = { ...this.state.form, contexto }
    axios.post('api/entregador', data).then(response => {
      this.props.onHide(true)
    })
  };

  handlerChange = e => {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    })
  };

  onAddTelefone = tel => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        telefones: fnHelper.addOrEditByIndex(form.telefones, tel)
      }
    })
  };

  onAddEmail = email => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        emails: fnHelper.addOrEditByIndex(form.emails, email)
      }
    })
  };

  handleBack = () => {
    const { activeStep } = this.state
    this.setState({ activeStep: activeStep - 1 })
  };

  handleNext = () => {
    const { activeStep } = this.state
    this.setState({ activeStep: activeStep + 1 })
  };

  render () {
    const { form, disable, activeStep } = this.state
    const { onHide, data, contexto } = this.props
    const steps = ['Dados gerais', 'Telefones', 'Emails', 'Licença']
    let title = 'Criar'
    if (data) title = `Editar ${data.nome && data.nome}`
    return (
      <Dialog open maxWidth={false} fullWidth>
        <Title
          title={title}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide
            }
          ]}
        />

        <DialogContent style={{ minHeight: 300 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TabPessoa
                handlerChange={this.handlerChange}
                form={form}
                contexto={contexto}
              />
            </Grid>
            <Grid item xs={6}>
              <TabTelefone
                telefones={form.telefones}
                onAdd={this.onAddTelefone}
                onRemoveTelefone={index => {
                  _.pullAt(form.telefones, index)
                  this.setState({
                    form: { ...form, telefones: form.telefones }
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TabEmail
                emails={form.emails}
                onAddEmail={this.onAddEmail}
                onRemoveEmail={index => {
                  _.pullAt(form.emails, index)
                  this.setState({ form: { ...form, emails: form.emails } })
                }}
              />
            </Grid>
          </Grid>

          {activeStep === 3 && <TabLicenca handlerChange={this.handlerChange} form={form} />}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" disabled={disable} onClick={this.onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

CriarEditarEntregadorDialog.propTypes = {
  form: PropTypes.object
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(CriarEditarEntregadorDialog)
