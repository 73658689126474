import { ONLINE } from '../contstants/actionsContants'

const INITIAL_STATE = true

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ONLINE:
      return action.payload
    default:
      break
  }

  return state
}
