import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import {
  Button,
  Grid,
  CardHeader,
  FormControlLabel,
  Checkbox,
  Paper,
} from '@material-ui/core'
import fundoDia from '../../assets/img/login/dia.jpg'
import fundoTarde from '../../assets/img/login/tarde.jpg'
import fundoManha from '../../assets/img/login/manha.jpg'
import fundoNoite from '../../assets/img/login/noite.jpg'
import logo from '../../assets/img/logo-emoutech.png'
import EsqueciMinhaSenhaDialog from './esqueciMinhaSenhaDialog'
import {
  entrarAction,
  sairAction,
  setSistemas,
} from '../../actions/loginAction'
import EscolherSistemaDialog from './escolherSistemaDialog'
import { TextField } from '../../_atoms'

class Entrar extends Component {
  state = {
    usuario: '',
    senha: '',
    open: false,
    disable: false,
    sistemas: [],
  }

  onChange = (key, event) => {
    this.setState({ [key]: event.target.value })
  };

  handlerClickEnter = () => {
    const { usuario, senha } = this.state
    const url = `/api/Login/Entrar?usuario=${usuario}&senha=${senha}`

    this.fazerLogin(url)
  };

  fazerLogin(url) {
    this.setState({ disable: true })
    return axios
      .post(url)
      .then(response => {
        this.setState({ disable: false })
        if (response?.sistemas) {
          this.props.setSistemas(response.sistemas)
        } else if (response) {
          this.props.entrarAction(response)
        }
      })
      .catch(error => {
        this.setState({ disable: false })
      })
  }

  onKeyPress = eventKeyPress => {
    if (eventKeyPress.key === 'Enter') {
      this.handlerClickEnter()
    }
  };

  fecharMenu = () => {
    this.props.sairAction()
  };

  selecionarSistema = sistema => {
    const { usuario, senha } = this.state
    const url = `/api/Login/Entrar?usuario=${usuario}&senha=${senha}&sistemaId=${sistema.id}`
    this.setState({ sistemas: [] }, () => this.fazerLogin(url))
  };

  render() {
    const { user } = this.props
    const horaAtual = new Date().getHours()
    const fundo = horaAtual >= 19 || horaAtual <= 4
      ? fundoNoite
      : horaAtual >= 17
        ? fundoTarde
        : horaAtual >= 12
          ? fundoDia
          : fundoManha
    const backgroundImage = {
      minHeight: '100vh',
      backgroundImage: `url(${fundo})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top center',
    }
    return (
      <div
        style={{
          ...backgroundImage,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper style={{ width: 400 }}>
          <Grid
            container
            spacing={1}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <img style={{ width: '100%' }} src={logo} alt="" />
            </Grid>
            <Grid item>
              <CardHeader
                title="Digite seu usuario e senha"
                subheader="Caso não tenha, comunique seu gestor"
              />
            </Grid>
            <Grid item style={{ width: 380 }}>
              <TextField
                label="Usuario"
                value={this.state.usuario}
                onChange={event => this.onChange('usuario', event)}
              />
            </Grid>
            <Grid item style={{ width: 380 }}>
              <TextField
                label="senha"
                type="password"
                onKeyPress={this.onKeyPress}
                onChange={event => this.onChange('senha', event)}
                value={this.state.senha}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.handlerClickEnter()}
                disabled={this.state.disable}
              >
                Entrar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={() => this.setState({ open: true })}
              >
                Esqueci minha senha
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {this.state.open && (
          <EsqueciMinhaSenhaDialog
            onHide={() => this.setState({ open: false })}
          />
        )}

        {!user.logado && user.sistemas.length > 0 && (
          <EscolherSistemaDialog
            sistemas={user.sistemas}
            onHide={() => this.fecharMenu()}
            onSelect={sistema => this.selecionarSistema(sistema)}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      entrarAction,
      sairAction,
      setSistemas,
    },
    dispatch,
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Entrar)
