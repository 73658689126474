import React, { Component } from 'react'
import axios from 'axios'
import {
  Dialog, DialogContent, DialogActions, Grid, Button,
} from '@material-ui/core'

import { CKEditor } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

class CifraDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: this.props.musica,
    }
  }

    handlerChange = e => {
      this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
    };

    save = () => {
      axios.post('/api/Musica', this.state.form).then(result => {
        this.props.onHide(true)
      })
    }

    render() {
      const { form } = this.state
      return (
        <div>
          <Dialog open maxWidth={false} fullWidth>
            <Title
              title={form.nome}
              buttons={[
                {
                  icon: 'close',
                  color: 'secondary',
                  onClick: this.props.onHide,
                },
              ]}
            />
            <DialogContent style={{ paddingTop: 10 }}>
              <Grid item xs={12}>
                <CKEditor
                  value={form && form.cifra}
                  handlerChange={e => this.handlerChange({ target: { name: 'cifra', value: e.editor.getData() } })}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <div style={{ flex: 1 }} />
              <Button color="primary" variant="contained" onClick={this.save}>
                            SALVAR
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default CifraDialog
