import React, { Component } from 'react'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent, DialogActions, Button, Grid
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import Mesa from './../../../../_atoms/Mesa'

class MesasDialog extends Component {
  constructor (props) {
    super(props)
    const mesasSelecionadas = props.mesasSelecionadas ? props.mesasSelecionadas.split(', ') : []
    this.state = { mesasSelecionadas }
  }

  onConfirm = () => {
    const { onSetMesas } = this.props
    const { mesasSelecionadas } = this.state
    onSetMesas(mesasSelecionadas.join(', '))
  };

  addMesa = numero => {
    const { mesasSelecionadas } = this.state
    this.setState({ mesasSelecionadas: [...mesasSelecionadas, numero] })
  };

  removeMesa = numero => {
    const { mesasSelecionadas } = this.state
    this.setState({ mesasSelecionadas: mesasSelecionadas.filter(x => x != numero) })
  };

  render () {
    const { onHide, mesas } = this.props
    const { mesasSelecionadas } = this.state

    return (
      <Dialog open fullWidth maxWidth={false}>
        <Title title="Escolha a mesa do cliente" buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1}>
            {mesas?.map((mesa, idx) => {
              const selected = _.some(mesasSelecionadas, x => x === mesa.numero)

              return (
                <Grid item key={idx}>
                  <Mesa
                    onClick={() => (selected ? this.removeMesa(mesa.numero) : this.addMesa(mesa.numero))}
                    selected={selected}
                    numero={mesa.numero}
                    lugares={mesa.lugares}
                  />
                </Grid>
              )
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onConfirm} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MesasDialog
