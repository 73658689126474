import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Tabs,
  Tab
} from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import { ComboBoxData } from '../../../_atoms/combobox'
import tipoEnvioJSON from './tipoEnvioJSON'
import FaixaDeCep from './faixaDeCep'
import InputGenerator from '../../../_atoms/InputGenerator'
import { TextFieldCEP } from '../../../_atoms/numberFormat'

const inputs = {
  1: [
    { type: 'outher', name: 'faixas', component: FaixaDeCep, xs: 12 }
  ],
  2: [

  ]
}

const AddOrEditModal = props => {
  const [form, setForm] = useState({
    tipo: 1,
    nome: '',
    ...props.data,
    data: {
      ...fnHelper.tryJsonParse(props.data && props.data.data),
      faixas: fnHelper.tryJsonParse(props?.data?.data)?.faixas?.map(x => ({ ...x, filter: true }))
    }
  })
  const [filter, setFilter] = useState({
    nome: '',
    cep: ''
  })
  const [tab, setTab] = useState(0)
  const [showError, setShowError] = useState(false)

  const onSave = () => {
    if (!!form.tipo || form.nome) {
      setShowError(true)
    }

    axios
      .post('api/conectorenvio', { ...form, data: JSON.stringify(form.data) })
      .then(resp => {
        props.onHide(true)
      })
  }

  const handlerChange = e => {
    if (e.target.name == 'tipo') {
      setForm({ ...form, [e.target.name]: e.target.value, data: '' })
    } else {
      setForm({ ...form, [e.target.name]: e.target.value })
    }
  }

  const handlerChangeData = e => {
    setForm({ ...form, data: { ...form.data, [e.target.name]: e.target.value } })
  }

  const handlerChangeDataImage = (name, value) => {
  }

  const handlerChangeDataOBJ = name => e => {
  }

  let ipts = []
  if (form.tipo > 0) {
    ipts = inputs[form.tipo]
  }

  const onFilter = () => {
    const faixasFiltered = form?.data?.faixas?.map(f => {
      if (f.nome.startsWith(filter?.nome)) {
        const searchCep = Number(filter.cep.replace('-', ''))
        const inicial = Number(f.inicial.replace('-', ''))
        const final = Number(f.final.replace('-', ''))
        if (!filter?.cep ||
          f.inicial.startsWith(filter?.cep) ||
          f.final.startsWith(filter?.cep) ||
          (searchCep >= inicial && searchCep <= final)
        ) {
          return { ...f, filter: true }
        }
      }
      return { ...f, filter: false }
    })

    setForm(state => ({ ...state, data: { ...state.data, faixas: faixasFiltered } }))
  }

  useEffect(() => {
    onFilter()
  }, [filter])

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={form && form.id ? 'Edição de Gateway' : 'Novo Gateway'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid
            container spacing={1}
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#fff',
              zIndex: 99
            }}
          >
            <Grid item xs={12}>
              <Tabs
                centered
                indicatorColor="primary"
                value={tab}
                onChange={(e, idx) => setTab(idx)}
              >
                <Tab label="Configuração" />
                <Tab label="Faixas de CEP" />
              </Tabs>
            </Grid>

            {tab == 0 &&
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Nome"
                    error={showError && !form.nome}
                    name="nome"
                    value={form && form.nome}
                    onChange={handlerChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ComboBoxData
                    label="Nome da Gateway"
                    onChange={e => handlerChange({ target: { name: 'tipo', value: e.id } })}
                    selected={form.tipo}
                    list={tipoEnvioJSON}
                  />
                </Grid>
              </>
            }
            {tab === 1 &&
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Nome"
                    name="nome"
                    value={filter?.nome}
                    onChange={e => setFilter({ ...filter, nome: e.target.value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldCEP
                    name="cep"
                    label="CEP"
                    value={filter?.cep}
                    onChange={e => setFilter({ ...filter, cep: e.target.value })}
                  />
                </Grid>
              </>
            }
          </Grid>
          <Grid container spacing={1}>
            {tab === 1 && ipts && ipts.map((f, idx) => (
              <Grid item xs={f.xs || 6} key={idx}>
                <InputGenerator
                  key={idx}
                  f={f}
                  handlerChangeDataImage={handlerChangeDataImage}
                  handlerChangeData={handlerChangeData}
                  handlerChangeDataOBJ={handlerChangeDataOBJ}
                  data={form && form.data}
                  setForm={setForm}
                  disabledReorder={!!(filter?.nome || filter?.cep)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(AddOrEditModal)
