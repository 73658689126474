import React from 'react'
import {
  Grid,
  CardActionArea,
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
  Badge
} from '@material-ui/core'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import './barraPedidos.css'
import { MdSportsMotorsports, MdStoreMallDirectory, MdOutlineLanguage, MdTimer } from 'react-icons/md'
import { SiAirtable } from 'react-icons/si'

const BarraPedidos = (props) => {
  const { pedidos, onSelectPedido, pedido } = props
  const isMobile = useMediaQuery('(max-width:900px)')

  return (
    <Grid container spacing={1}>
      {pedidos.map((p) => {
        const status = props.todosStatusDePedidos.find(x => x.id == p.statusEntregaId)
        const style = { border: `2px solid ${status?.color}` }

        if (p?.statusEntrega == 1) {
          if (!p.trancadoPara) {
            style.animation = 'atencao 2s infinite'
          }
        }

        if (pedido.id === p.id) {
          style.backgroundColor = 'black'
          style.color = 'white'
        }

        let nomeCliente = p.cliente?.nome
        if (nomeCliente) {
          nomeCliente = nomeCliente.split(' ')[0]
        }

        return (
          <Grid key={p.id} item xs={12} sm={isMobile ? 6 : 12} md={isMobile ? 4 : 12}>
            <Card style={{ ...style }}>
              <CardActionArea onClick={() => onSelectPedido(p)}>
                <CardHeader
                  style={{ padding: '4px 16px' }}
                  title={p.numero}
                />
                <CardContent
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    padding: '4px 16px'
                  }}
                >
                  <p>{nomeCliente || '--'}</p>
                  <p>{fnHelper.toDateTimeBR(p.dataCadastro)}</p>
                </CardContent>
                {p.canal === 2 &&
                  <>
                    <img
                      style={{ position: 'absolute', top: 10, right: 10, height: 40 }}
                      src={`/assets/img/${p.canal}.png`}
                    />
                    <div
                      style={{ position: 'absolute', bottom: 5, right: 20, fontSize: 20 }}
                    >
                      {p.tipo === 'TAKEOUT' && p.prioridade !== 'SCHEDULED' && <MdStoreMallDirectory />}
                      {p.tipo === 'INDOOR' && p.prioridade !== 'SCHEDULED' &&
                        <Badge
                          color="primary"
                          badgeContent={`mesa:${p.mesas.split(',')[0]}`}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                        >
                          <SiAirtable />
                        </Badge>
                      }
                      {p.tipo === 'DELIVERY' && p.prioridade !== 'SCHEDULED' && <MdSportsMotorsports />}
                      {p.prioridade === 'SCHEDULED' && <MdTimer />}
                    </div>
                  </>
                }
                {p.canal !== 2 &&
                  <div
                    style={{ position: 'absolute', top: 10, right: 10, fontSize: 40 }}
                  >
                    {p.canal === 0 && !p.mesas && <MdStoreMallDirectory />}
                    {p.canal === 0 && p.mesas &&
                      <Badge
                        color="primary"
                        badgeContent={`mesa:${p.mesas.split(',')[0]}`}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                      >
                        <SiAirtable />
                      </Badge>
                    }
                    {p.canal === 1 && <MdSportsMotorsports />}
                    {p.canal === 3 && <MdOutlineLanguage />}
                  </div>
                }

              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default BarraPedidos
