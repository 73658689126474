import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid, Divider, FormControlLabel, Checkbox
} from '@material-ui/core'

import { TextField, Fieldset, TextFieldColor } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxAPI, ComboBoxData } from '../../../../_atoms/combobox'
import STATUS_ENTREGA from './../../../../contstants/statusEntrega'

const AddOrEditModal = props => {
  const [pedidoStatus, setPedidoStatus] = useState({
    nome: '',
    document: null,
    statusType: '',
    parentId: '',
    printType: [],
    allowFinish: false,
    ...props.data
  })

  const [showError, setShowError] = useState(false)

  const onSave = () => {
    const { name, statusType } = pedidoStatus
    let error = false
    if (!name || !statusType) {
      error = true
    }

    if (error) {
      setShowError(true)
      return
    }

    axios.post('api/pedidoStatus', pedidoStatus)
      .then(resp => {
        props.onHide(true)
      })
  }

  let titulo = null
  if (props.data?.id) {
    titulo = <div>Editar Status de Pedido ➜&nbsp;
      <span style={{ textDecoration: 'underline' }}>{props.data?.nome}</span>
    </div>
  } else if (props.data?.parentName) {
    titulo = <div>Adicionar Status de Pedido em&nbsp;
      <span style={{ textDecoration: 'underline' }}>{props.data?.parentName}</span>
    </div>
  } else {
    titulo = <div>Adicionar Status de Pedido</div>
  }

  const hanhlerPrintType = (e, type) => {
    let printType = pedidoStatus.printType || []
    if (e.target.checked) {
      printType.push(type)
    } else {
      printType = printType.filter(x => x != type)
    }
    setPedidoStatus({ ...pedidoStatus, printType })
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open>
        <Title
          title={titulo}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <TextField
                error={showError && !pedidoStatus.name}
                name="name"
                label="Nome"
                onChange={(e) => setPedidoStatus({ ...pedidoStatus, [e.target.name]: e.target.value })}
                value={pedidoStatus.name}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxData
                error={showError && !pedidoStatus?.statusType}
                label="Status Type"
                onChange={e => setPedidoStatus({ ...pedidoStatus, statusType: e.id })}
                selected={pedidoStatus?.statusType}
                nameText="nome"
                nameValue="id"
                list={
                  STATUS_ENTREGA.filter(x =>
                    x.workflow &&
                    (props.data?.activeIds?.includes(x.id) ||
                      !props.data?.activeIds))}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxAPI
                loadStart
                label="Documento"
                api={'/api/DocumentoModelo/full'}
                onChange={e => setPedidoStatus({ ...pedidoStatus, document: { id: e.id, nome: e.nome } })}
                value={pedidoStatus?.document?.id}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxAPI
                loadStart
                label="Conector de Envio"
                api={'api/conector/full'}
                onChange={e => setPedidoStatus({ ...pedidoStatus, connectSend: { id: e.id, nome: e.nome } })}
                value={pedidoStatus?.connectSend?.id}
              />
            </Grid>
            <Grid item xs={6}>
              <Fieldset label="Tipo de Impressão">
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!pedidoStatus?.printType?.find(x => x === 1)}
                      onChange={(e) => hanhlerPrintType(e, 1)}
                    />
                  )}
                  label="Controle do caixa"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!pedidoStatus?.printType?.find(x => x === 2)}
                      onChange={(e) => hanhlerPrintType(e, 2)}
                    />
                  )}
                  label="Produtos nas Praças"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!pedidoStatus?.printType?.find(x => x === 3)}
                      onChange={(e) => hanhlerPrintType(e, 3)}
                    />
                  )}
                  label="Pedidão"
                />
              </Fieldset>
            </Grid>
            <Grid item xs={6} container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!pedidoStatus.blockEdit}
                      onChange={(e) => setPedidoStatus({ ...pedidoStatus, blockEdit: e.target.checked })}
                    />
                  )}
                  label="Bloqueia edição (Somente com senha)"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!pedidoStatus.activeTax}
                      onChange={(e) => setPedidoStatus({ ...pedidoStatus, activeTax: e.target.checked })}
                    />
                  )}
                  label="Habilitar Geração de nota fiscal"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={!!pedidoStatus.allowFinish}
                      onChange={(e) => setPedidoStatus({ ...pedidoStatus, allowFinish: e.target.checked })}
                    />
                  )}
                  label="Permite Faturar"
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <TextFieldColor
                name="color"
                label="Cor do status"
                value={pedidoStatus.color}
                onChange={(e) => setPedidoStatus({ ...pedidoStatus, color: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(AddOrEditModal)
