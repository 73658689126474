import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Grid, Chip, Button, Card, CardContent, CardHeader, Icon
} from '@material-ui/core'
import ListaDeConferentesDialog from './listaDeConferentesDialog'
import AutenticaConferenteDialog from './autenticaConferenteDialog'
import { TextFieldMoneyBR } from '../../../_atoms/numberFormat'
import { Dialog, TextField, DatePicker } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'

class DizimoDialog extends Component {
  state = {
    modalConferentes: false,
    conferente: {},
    modalSenha: false,
    form: {
      dataPagamento: moment(),
      valorLiquido: '',
      observacao: ''
    },
    saveDisabled: false
  };

  handlerChange = e => {
    this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
  };

  addConferente = conferente => {
    this.setState({ conferente, modalConferentes: false, modalSenha: true })
  };

  validaConferente = (id, senha) => {
    axios.post(`api/Login/VerificarLogin/?id=${id}&senha=${senha}`)
      .then(val => {
        if (val) {
          this.setState({
            modalSenha: false,
            id,
            conferente: { ...this.state.conferente, autenticado: true }
          })
        }
      })
  }

  onSave = () => {
    this.setState({ saveDisabled: true })
    const { form, conferente } = this.state
    const { valorLiquido } = form

    if (!conferente.autenticado || !valorLiquido) {
      return
    }

    const data = { ...form, conferentes: [conferente] }

    axios.post('api/financeiro/dizimo', data).then(response => {
      this.props.onHide(true)
    })
  };

  // handlerDateChange = dataPagamento => this.setState({ dataPagamento })

  // handlerChange = e => this.setState({ [e.target.name]: e.target.value })

  render () {
    const { usuario, onHide } = this.props
    const {
      modalConferentes, modalSenha, conferente, saveDisabled, form
    } = this.state
    const { dataPagamento, valorLiquido, observacao } = form
    const onClose = () => onHide(false)

    return (
      <div>
        <Dialog
          dialogProps={{ open: true, onClose }}
          title={(
            <Title
              title="Lançamento de Dízimos e Ofertas"
              buttons={[{ icon: 'close', color: 'secondary', onClick: onClose }]}
            />
          )}
          actions={(
            <Button
              color="primary"
              variant="contained"
              disabled={!(conferente.autenticado && valorLiquido) && saveDisabled}
              onClick={this.onSave}
            >
              Lançar
            </Button>
          )}
        >

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Data do Lançamento"
                value={dataPagamento}
                onChange={value => this.handlerChange({ target: { name: 'dataPagamento', value } })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldMoneyBR
                name="valorLiquido"
                error={saveDisabled && !valorLiquido}
                value={valorLiquido}
                label="Valor"
                onValueChange={this.handlerChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observações"
                value={observacao}
                name="observacao"
                onChange={this.handlerChange}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Card
                style={{
                  marginTop: 20,
                  border: !conferente.autenticado && saveDisabled && '1px solid red'
                }}
              >
                <CardHeader
                  avatar={(
                    <Button
                      variant="fab"
                      onClick={() => this.setState({ modalConferentes: true })}
                      color="primary"
                    >
                      <Icon>add</Icon>
                    </Button>
                  )}
                  title="Adicione os Conferentes"
                  subheader="Mínimo 2 conferentes"
                />
                <CardContent>
                  <Chip label={usuario.pessoa.nome} />
                  {conferente.autenticado && <Chip label={conferente.nome} />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Dialog>

        {modalConferentes &&
          (
            <ListaDeConferentesDialog
              addConferente={this.addConferente}
              onHide={() => this.setState({ modalConferentes: false })}
            />
          )}

        {modalSenha &&
          (
            <AutenticaConferenteDialog
              conferente={conferente}
              onHideSenha={() => this.setState({ modalSenha: false })}
              ValConf={this.validaConferente}
            />
          )}

      </div>

    )
  }
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}
export default connect(mapStateToProps)(DizimoDialog)
