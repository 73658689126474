import React, { Component } from 'react'
import axios from 'axios'
import {
  Dialog, DialogContent, DialogActions, Grid, Button,
} from '@material-ui/core'

import { TextField } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

class MusicaDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form: props.editar ? props.musica : {},
    }
  }

    handlerChange = e => {
      this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
    };

    inserir = () => {
      axios.post('/api/Musica', this.state.form).then(result => {
        this.props.onHide(true)
      })
    }

    render() {
      const { form } = this.state
      return (
        <div>
          <Dialog open maxWidth={false} fullWidth>
            <Title
              title={this.props.editar ? 'Editar Música' : 'Nova Música'}
              buttons={[
                {
                  icon: 'close',
                  color: 'secondary',
                  onClick: this.props.onHide,
                },
              ]}
            />

            <DialogContent style={{ paddingTop: 10 }}>
              <br />
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Número"
                    value={form.codigo}
                    name="codigo"
                    onChange={this.handlerChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Nome"
                    value={form.nome}
                    name="nome"
                    onChange={this.handlerChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Tom"
                    value={form.tom}
                    name="tom"
                    onChange={this.handlerChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Nome Original"
                    value={form.nomeOriginal}
                    name="nomeOriginal"
                    onChange={this.handlerChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Link do Youtube"
                    value={form.link}
                    name="link"
                    onChange={this.handlerChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div style={{ flex: 1 }} />
              <Button color="primary" variant="contained" onClick={this.inserir}>
                            SALVAR
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
}

export default MusicaDialog
