import React from 'react'
import { EMTable } from '@em/lib/src'
import axios from 'axios'
import { Chip } from '@material-ui/core'
import * as fnHelper from '@em/lib/src/util/fnHelper'

import STATUS_PAGAMENTO from './../../../contstants/statusPagamento'

export default function List ({
  openModal,
  tableRef
}) {
  return (
    <EMTable
      tableRef={tableRef}
      data={query => {
        const { page, pageSize } = query
        return axios.get('/api/coupon', { params: { offset: page, limit: pageSize } }).then(response => {
          return {
            data: response,
            page,
            totalCount: response?.paginacao?.qtdeRows || 0
          }
        })
      }}
      columns={
        [
          {
            title: 'Título',
            field: 'titulo'
          },
          {
            title: 'Prefixo',
            field: 'prefixo'
          },
          {
            title: 'Quantidade Incial',
            field: 'qtde'
          },
          {
            title: 'Gerados',
            field: 'qtdeGerado'
          },
          {
            title: 'Consumidos',
            field: 'qtyUsed'
          },
          {
            title: 'Desconto',
            render: row => {
              let desconto = ''
              if (row.percentualValor === 0) {
                desconto = `${row.desconto}%`
              } else {
                desconto = `R$ ${Number(row.desconto).toFixed(2)}`
              }
              return (
                <div>{desconto}</div>
              )
            }
          },
          {
            title: 'Data de Expiração',
            field: 'dataExpiracao',
            render: rowData => fnHelper.toDateTimeBR(rowData.dataExpiracao)
          },
          {
            title: 'Ativo',
            render: row => {
              return (
                <>
                  {row.ativo && <Chip color="primary" label="Sim" />}
                  {!row.ativo && <Chip color="secondary" label="Não" />}
                </>
              )
            }
          },
          {
            title: 'Status Pagamento',
            render: row => {
              return (
                <div>{STATUS_PAGAMENTO[row.pagamento.status]}</div>
              )
            }
          },
          {
            align: 'right',
            width: 180,
            render: row => {
              return (
                <div style={{ width: 180 }}>

                </div>
              )
            }
          }
        ]}
    />
  )
}
