import React, { useState } from 'react'
import {
  InputAdornment,
  IconButton
} from '@material-ui/core'
import _ from 'lodash'
import TextField from './../TextField'
import { AccountTreeOutlined } from '@material-ui/icons'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import AddOrEditModal from './addOrEditModal'

const TextFieldJSON = (props) => {
  const { value, defaultJSON, ...others } = props
  const valueJSON = fnHelper.tryJsonParse(value)
  const [modalAddOrEdit, setModalAddOrEdit] = useState({ open: false })

  return (
    <>
      <TextField
        multiline
        rowsMax={15}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{
                  marginRight: -5, padding: 10, marginTop: -20
                }}
                onClick={(e) => setModalAddOrEdit({ open: true, data: _.cloneDeep(valueJSON) })}
              >
                <AccountTreeOutlined />
              </IconButton>
            </InputAdornment>
          )
        }}
        value={value}
        {...others}
      />

      {modalAddOrEdit.open &&
        <AddOrEditModal
          data={{ raiz: modalAddOrEdit.data || null }}
          defaultJSON={{ raiz: defaultJSON || null }}
          label={others.label}
          onHide={() => setModalAddOrEdit({ open: false })}
          onChange={(text) => others.onChange({
            target: { name: props.name, value: text }
          })}
        />
      }

    </>
  )
}

export default TextFieldJSON
