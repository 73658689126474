import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon, IconButton, Chip } from '@material-ui/core'
import axios from 'axios'
import { showMensagemConfirmacaoAction } from '../../../actions/mensagemAction'
import { ReactTable } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import CriarEditarDialog from '../../../_organisms/pdv/caixas/criarEditarDialog'
import caixasJSON from '../../../contstants/canais'
import { FaCashRegister } from "react-icons/fa"
import { EMTable } from '@em/lib/src'

const CaixasConfig = props => {
  const [modalCriarEditarCaixa, setModalCriarEditarCaixa] = useState({ open: false })
  const [caixas, setCaixas] = useState([])
  const [loading, setLoading] = useState(false)

  const loadCaixas = filter => {
    setLoading(true)
    axios.get('api/caixa').then(resp => {
      setCaixas(resp)
    }).catch(error => {
      setCaixas([])
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    loadCaixas({})
  }, [])

  const onDelete = form => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {form.numero}
          </strong>
          ?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => deleteConfirmed(form) }
      ]
    }
    props.showMensagemConfirmacaoAction(model)
  }

  const deleteConfirmed = original => axios.delete(`api/caixa/${original.id}`).then(response => {
    loadCaixas({})
  })

  return (
    <div>
      <Title
        icon={<FaCashRegister style={{ fontSize: 24 }} />}
        title="Cadastro de caixas"
        buttons={[
          {
            icon: 'add',
            onClick: () => setModalCriarEditarCaixa({ open: true })
          }
        ]}
      />

      <EMTable
        // loading={loading}
        data={caixas}
        columns={[
          {
            title: 'Descrição',
            render: (dataRow) => `${dataRow.nome} ${dataRow.numero}`
          },
          {
            title: 'Canais',
            render: row => (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                {row && row.canais && row.canais.map((canal, idx) => (
                  <Chip
                    style={{ margin: 2 }}
                    variant="outlined"
                    label={caixasJSON.find(x => x.id == canal).nome || ''}
                  />
                ))}
              </div>
            )
          },
          {
            title: 'Impressora',
            field: 'impressora.nome'
          },
          {
            title: 'Operador',
            field: 'operador.nome'
          },
          {
            maxWidth: 120,
            render: dataRow => (
              <div>
                <IconButton onClick={() => setModalCriarEditarCaixa({
                  open: true,
                  data: dataRow
                })} color="primary">
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => onDelete(dataRow)} color="secondary">
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )
          }
        ]}
      />

      {modalCriarEditarCaixa.open && (
        <CriarEditarDialog
          onHide={refresh => {
            if (refresh === true) {
              loadCaixas()
            }
            setModalCriarEditarCaixa({ open: false })
          }}
          data={modalCriarEditarCaixa.data}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaixasConfig)
