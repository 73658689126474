import { EMTable } from '@em/lib/src'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import Title from '@em/lib/src/util/Title'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { TextField } from '../../../../_atoms'

const typesEntry = {
  0: 'Desmembramento',
  1: 'Entrada',
  2: 'Saída',
  3: 'Inventário'
}

export default ({ data, onHide }) => {
  return (
    <Dialog maxWidth="lg" fullWidth open onClose={onHide}>
      <Title
        title={'Resumo ' + (typesEntry[data?.tipo] || '')}
        buttons={[
          { icon: 'close', color: 'secondary', onClick: onHide }
        ]}
      />
      <DialogContent>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                disabled
                label="Razão Social"
                value={data?.fornecedor?.nome}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                label="Data Emissão"
                value={moment(data?.dataCadastro).format('DD/MM/YYYY HH:mm')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                label="Nota"
                value={`${data?.nNF}`}
              />
            </Grid>
          </Grid>
          <EMTable
            options={{
              toolbar: false,
              paging: false
            }}
            data={data?.itens}
            columns={[
              {
                title: '#',
                render: row => {
                  return row.tableData.id + 1
                }
              },
              {
                title: 'Codigo',
                field: 'externalCode'
              },
              {
                title: 'Descição',
                field: 'nome'
              },
              {
                title: 'Ean',
                field: 'ean'
              },
              {
                title: 'NCM',
                field: 'tax.NCM'
              },
              {
                title: 'CFOP',
                field: 'tax.CFOPEntrada'
              },
              {
                title: 'Qtde',
                field: 'qtde'
              },
              {
                title: 'Custo Un',
                field: 'costPrice',
                render: dataRow => (<NumberFormatMoneyBR value={dataRow.costPrice} />)
              }
            ]}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
