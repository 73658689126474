import React, { useRef, useState } from 'react'
import axios from 'axios'
import { DialogActions, DialogContent, Button, Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import moment from 'moment'
import Title from '@em/lib/src/util/Title'
import { TinyEditor } from '@em/lib/src'

import { TextField, DateTimePicker } from '../../../../_atoms'
import { CheckBox } from '@material-ui/icons'

const AddOrEditModal = ({ data, onHide }) => {
  const editorRef = useRef(null)
  const [form, setForm] = useState({
    content: '',
    name: '',
    scheduledAt: moment(),
    description: '',
    ...data
  })

  const onSave = () => {
    const content = editorRef.current.getContent()
    axios.post('api/schedule', { ...form, content })
      .then(() => onHide(true))
  }

  return (
    <div>
      <Title
        title={form && form.id ? 'Editar Boletim' : 'Novo Boletim'}
        buttons={[
          {
            icon: 'close',
            color: 'secondary',
            onClick: onHide
          }]}
      />

      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Titulo"
              value={form?.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              fullWidth
              label="Data"
              value={form.scheduledAt}
              onChange={scheduledAt => setForm({ ...form, scheduledAt })}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label="Descrição"
              value={form?.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              label="Publicado"
              control={
                <Checkbox
                  color={'primary'}
                  onChange={e => setForm({ ...form, status: e.target.checked ? 1 : 0 })}
                  checked={form.status === 1}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TinyEditor
              editorRef={editorRef}
              initialValue={data?.content}
              title={data?.name}
            />
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSave}>
          Salvar
        </Button>
      </DialogActions>
    </div>
  )
}

export default AddOrEditModal
