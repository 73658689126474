import { Button, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import Title from '@em/lib/src/util/Title'
import moment from 'moment'
import axios from 'axios'

import { DateTimePicker, Dialog, TextField } from '../../../../_atoms'

function CloseIfoodMerchantModal({
  data,
  onHide
}) {
  const [form, setForm] = useState({
    start: moment(),
    end: moment().add(12, 'h'),
    description: 'Excesso de demanda'
  })
  const [showError, setShowError] = useState(false)

  const save = () => {
    if (!form.start || !form.end || !form.description) {
      setShowError(true)
      return
    }
    axios.post('api/ifoodV2/merchants/interruptions', {
      ...form,
      start: form.start.format("YYYY-MM-DDTHH:mm"),
      end: form.end.format("YYYY-MM-DDTHH:mm")
    })
      .then(() => onHide(true))
  }

  return (
    <Dialog
      dialogProps={{
        open: true,
        maxWidth: 'sm',
        fullWidth: true
      }}
      title={
        <Title
          title={data?.available ? 'Fechar loja' : 'Abrir loja'}
          buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
        />
      }
      actions={(
        <Button
          variant='contained'
          color="primary"
          onClick={save}
        >
          Fechar Loja
        </Button>
      )}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            <h4>Você realmente deseja <b>FECHAR</b> sua loja no IFOOD?</h4>
            <Alert title="Informação" color="info">
              Esta ação pode demorar até 5 minutos para ser processada no IFOOD.
            </Alert>
          </div>
        </Grid>
        {data?.available &&
          <Grid item xs={12}>
            <div>
              <Alert title="Atenção" color="warning">
                Ao fechar a loja, o sistema deixará de receber notificaçães do IFOOD
              </Alert>
            </div>
          </Grid>
        }
        <Grid item xs={12} md={6}>
          <DateTimePicker
            error={showError && !form.start}
            label="Data/Hora incial"
            value={form.start || ''}
            onChange={value => setForm({ ...form, start: value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateTimePicker
            error={showError && !form.end}
            label="Data/Hora final"
            value={form.end || ''}
            onChange={value => setForm({ ...form, end: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={showError && !form.description}
            label="Motivo"
            value={form.description}
            onChange={e => setForm({ ...form, description: e.target.value })}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default CloseIfoodMerchantModal
