import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Drawer, AppBar, Toolbar, IconButton, Chip, Grid, Icon, useMediaQuery } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { showMensagemConfirmacaoAction } from './../actions/mensagemAction'

import Modulos from './Modulos'
import Routes from './routes'
import MenuPaginaConfig from './menuPaginaConfig'
import Axios from 'axios'
import envConstants from './../contstants/envConstants.json'
import { EMOUTECH_ID } from './../contstants/systemConstants'

const styles = theme => ({
  list: {
    width: 300
  },
  content: {
    marginTop: 64,
    padding: 2,
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0 0 0'
    }
  }
})

const MenuPagina = ({ classes, usuario, showMensagemConfirmacaoAction }) => {
  const [open, setOpen] = useState(false)
  const [ponto, setPonto] = useState({})
  const iniciado = !!ponto.dataEntrada && !ponto.dataSaida
  const isMobile = useMediaQuery('(max-width:770px)')
  useEffect(() => {
    const fn = async () => {
      const response = await Axios.get('api/ponto/last')
      setPonto(response)
    }
    fn()
  }, [])

  const tooglePonto = () => {
    const model = {
      title: `${iniciado ? 'Encerrar' : 'Iniciar'} atividades`,
      mensagem: `Tem certeza que deseja ${iniciado ? 'encerrar' : 'iniciar'} as atividades`,
      buttoes: [
        {
          text: iniciado ? 'Encerrar' : 'Iniciar',
          onClick: tooglePontoConfirmation
        }
      ]
    }

    showMensagemConfirmacaoAction(model)
  }

  const tooglePontoConfirmation = async () => {
    const response = await Axios.post('api/ponto')
    setPonto(response.dados)
  }

  const envLabel = envConstants[window.location.host].api
    ?.replace('.emoutech.com.br', '')
    ?.replace('https://', '')
    ?.replace('http://', '')

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <Grid container spacing={2} alignItems="center" justify="space-between">
            <Grid item>
              <IconButton color="inherit" onClick={() => setOpen(true)} aria-label="Menu" className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={isMobile}>
              <Link to="/">
                <img alt="" src={global.bucket + usuario.sistema.foto}
                  style={{ height: 40, minHeight: 40 }} />
              </Link>
            </Grid>
            {!isMobile &&
              <>
                <Grid item xs>
                  <label>{usuario.sistema.nome}</label>
                </Grid>
                {[usuario?.sistema?.id, usuario?.sistemaAnterior?.id].includes(EMOUTECH_ID) &&
                  <Grid item>
                    <Chip
                      icon={<Icon style={{ color: 'gray' }}>dns</Icon>}
                      style={{ border: '2px solid gray', backgroundColor: envLabel == 'apiprod' && 'red' }}
                      label={envLabel}
                    />
                  </Grid>
                }
                <Grid item>
                  <Chip
                    icon={<Icon
                      style={iniciado
                        ? { color: 'green' }
                        : { color: 'yellow' }
                      }
                    >person</Icon>}
                    style={iniciado
                      ? { border: '2px solid green' }
                      : { border: '2px solid yellow' }
                    }
                    label={iniciado ? 'Online' : 'Iniciar'}
                    onClick={tooglePonto}
                  />
                </Grid>
              </>
            }

            <Grid item>
              <MenuPaginaConfig usuario={usuario} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={() => setOpen(false)}>
        <div tabIndex={0} role="button" onKeyDown={() => setOpen(false)}>
          <Modulos usuario={usuario} onClose={() => setOpen(false)} />
        </div>
      </Drawer>

      <main className={classes.content}>
        <Routes usuario={usuario} />
      </main>

    </div>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  showMensagemConfirmacaoAction
},
dispatch)

export default withStyles(styles)(connect(null, mapDispatchToProps)(MenuPagina))
