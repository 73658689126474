export const loadApiEMTable = (tableRef, data) => {
  const query = tableRef?.current?.state?.query
  let newData = data
  if (data?.isDelete) {
    newData = {
      ...newData,
      page:
        query.page === 0 || query.totalCount % query.pageSize !== 1
          ? query.page
          : query.page - 1
    }
  }
  return tableRef.current && tableRef.current.onQueryChange(newData)
}
