import React, { useState } from 'react'
import moment from 'moment'
import { Icon, IconButton } from '@material-ui/core'

import statusEntregaList from './../../../../contstants/statusEntrega'
import { TextField } from '../../../../_atoms'

const PedidoInfo = props => {
  const { pedido, onRefreshPedido } = props
  const [changed, setChanged] = useState(false)
  const [observacao, setObservacao] = useState(pedido?.observacao || '')

  const sEntrega = statusEntregaList.find(x => x.id === pedido.statusEntrega)

  return (
    <>
      <div style={{ padding: 10, backgroundColor: '#EEE', border: '1px solid #BABABA' }}>
        <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
          <div style={{ flex: 1 }}>

            <h2><strong>Dados do Pedido: {pedido.numero}</strong></h2>

            <p style={{ margin: 5 }}>
              <strong>Status: </strong>
              {' '}
              {sEntrega.nome}
            </p>

            <p style={{ margin: 5 }}>
              <strong>Data Criação: </strong>
              {' '}
              {moment(pedido.dataCadastro).format('DD/MM/YYYY HH:mm')}
            </p>

            {pedido?.canal === 2 &&
              <>
                <p style={{ margin: 5 }}>
                  <strong>Prioridade: </strong>
                  {' '}
                  {pedido?.prioridade === 'SCHEDULED' ? 'AGENDAMENTO' : 'AGORA'}
                </p>
                <p style={{ margin: 5 }}>
                  <strong>Data de início do preparo: </strong>
                  {' '}
                  {moment(pedido?.dataIncioPreparo).format('DD/MM/YYYY HH:mm')}
                </p>
              </>
            }

            <TextField
              label="Observação"
              multiline
              rows={2}
              rowsMax={5}
              onChange={e => {
                setChanged(true)
                setObservacao(e.target.value)
              }}
              value={observacao}
              InputProps={{
                endAdornment: (
                  <>
                    {changed && observacao
                      ? <IconButton
                        size="small"
                        style={{ marginTop: -20 }}
                        onClick={() => {
                          onRefreshPedido({ ...pedido, observacao })
                          setChanged(false)
                        }}
                      >
                        <Icon color="primary">done</Icon>
                      </IconButton>
                      : null
                    }
                  </>
                )
              }}
            />
          </div>
          {/* {pedido.canal === 2 &&
            <div>
              <Button
                color="primary"
                disabled={disabledBtnStatus}
                onClick={atualizarStatusEntregaIfood}>
                Atualizar Status de entrega
              </Button>
            </div>
          } */}
        </div>
      </div>
    </>
  )
}

export default PedidoInfo
