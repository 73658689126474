import React, { Component } from 'react'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import ListExtrato from '../../_organisms/financeiro/extrato/listExtrato'
import DizimoDialog from '../../_organisms/financeiro/dizimo/dizimoDialog'

class DizimoContainer extends Component {
    state = {
      dataList: [],
      modalDizimo: { open: false }
    };

    componentDidMount () {
      this.loadExtrato()
    }

    loadExtrato = () => axios.get('api/financeiro/full?meusLancamentos=true&lstStatus=0').then(dataList => this.setState({ dataList }))

    render () {
      const { dataList, modalDizimo } = this.state

      return (
        <div>
          <Title
            title="Meus lançamento pendentes"
            buttons={[
              { icon: 'add', onClick: () => this.setState({ modalDizimo: { open: true } }) }
            ]}
          />
          <ListExtrato list={dataList} />

          {modalDizimo.open &&
                    (
                      <DizimoDialog
                        onHide={refresh => this.setState({ modalDizimo: { open: false } }, () => refresh && this.loadExtrato())}
                      />
                    )}
        </div>
      )
    }
}

export default DizimoContainer
