import {
  Breadcrumbs,
  Chip,
  Popover,
  TextField
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'

function CustomBreadCrumbCRUD ({
  referenceList,
  currentList,
  onChange,
  maxItems,
  label
}) {
  const [openPopover, setOpenPopover] = useState({ anchorEl: null, idx: null })
  // const [listFiltered, setListFiltered] = useState([])
  const [inputFree, setInputFree] = useState('')
  const handleDelete = idx => {
    const newList = currentList.filter((item, i) => i !== idx)
    onChange(newList)
  }

  const renderButtonMore = () => {
    // const lastSelected = currentList[currentList.length - 1]
    // const hasMore = !!referenceList.find(item => item.paiId === lastSelected?.id)

    // if (currentList?.length === 0 || hasMore) {
    return (
      <Chip
        color="primary"
        label={'Adicionar'}
        onClick={e => setOpenPopover({ anchorEl: e.currentTarget })}
        style={{ margin: 2 }}
      />
    )
    // }
  }

  const handlerChange = (value, idx) => {
    // if (!idx) {
    //   if (value?.paiId) {
    //     onChange([...currentList, value])
    //   } else {
    //     onChange([value])
    //   }
    // } else {
    //   onChange(currentList.map((item, i) => {
    //     if (i === idx) {
    //       return value
    //     }
    //     return item
    //   }).filter((x, i) => i <= idx))
    // }
    if (idx === '' || idx === undefined || idx === null) {
      onChange([...currentList, value])
    } else {
      onChange(currentList.map((item, i) => {
        if (i === idx) {
          return value
        }
        return item
      }))
    }
    setOpenPopover({ anchorEl: null })
  }

  const handleClick = (e, item, idx) => {
    setOpenPopover({ anchorEl: e.currentTarget, idx })
    // const paiId = referenceList.find(i => i.id === item.id)?.paiId
    // setListFiltered(referenceList.filter(i => i.paiId === paiId))
  }

  // useEffect(() => {
  //   const parentList = referenceList.filter(item => !item.paiId)
  //   const lastSelected = currentList[currentList.length - 1]
  //   const childrenList = referenceList.filter(item => item.paiId === lastSelected?.id)
  //   if (lastSelected) {
  //     setListFiltered(childrenList)
  //   } else {
  //     setListFiltered(parentList)
  //   }
  // }, [referenceList, currentList])

  return (
    <>
      <Breadcrumbs
        maxItems={maxItems || 5}
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {currentList?.map((item, idx) => {
          return (
            <Chip
              key={item.id}
              color="primary"
              variant="outlined"
              label={item.nome}
              onDelete={() => handleDelete(idx)}
              onClick={e => handleClick(e, item, idx)}
              style={{ margin: 2 }}
            />
          )
        })}
        {renderButtonMore()}
      </Breadcrumbs>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={openPopover?.anchorEl}
        open={Boolean(openPopover?.anchorEl)}
        onClose={() => setOpenPopover({ anchorEl: null })}
      >
        <div style={{ padding: 10, minWidth: 300 }}>
          <Autocomplete
            options={referenceList || []}
            getOptionLabel={option => option?.nome}
            onChange={(a, b) => {
              handlerChange(b, openPopover?.idx)
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder="Selecione"
                onChange={(e) => setInputFree(e.target.value)}
                value={inputFree}
                fullWidth
              />
            )}
          />
        </div>
      </Popover>
    </>
  )
}

export default CustomBreadCrumbCRUD
