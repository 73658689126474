import { Chip, Grid } from '@material-ui/core'
import React from 'react'
import Title from '@em/lib/src/util/Title'
import { ComboBoxDataTree } from '../../../../_atoms/combobox'

function TabApplication({ form, handlerChange, allApplications }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title
          title="Aplicação"
          buttons={[
            {
              icon: 'add',
              color: 'primary',
              onClick: () => handlerChange({
                target: {
                  name: 'applications',
                  value: [...(form?.applications || []), []]
                }
              })
            }
          ]}
        />
      </Grid>
      {form?.applications?.map((apps, idx) => {
        return (
          <Grid item xs={12}>
            <ComboBoxDataTree
              handlerChangeData={e => {
                const newApps = form.applications
                newApps[idx] = e
                handlerChange({
                  target: { name: 'applications', value: newApps }
                })
              }}
              label={`Aplicação ${idx + 1}`}
              listSelecteds={apps}
              listFull={allApplications}
            />

          </Grid>
        )
      })}

    </Grid>
  )
}

export default TabApplication
