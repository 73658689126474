import Title from '@em/lib/src/util/Title'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography
} from '@material-ui/core'
import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { NumberFormatMoneyBR } from '../../../../_atoms/numberFormat'
import PrinterModal from './printersModal'

const styles = {
  dialogContent: {
    paddingTop: '20px',
    width: '620px'

  },
  tabelaPagamentos: {
    border: 'solid .6px #cccccc',
    borderCollapse: 'collapse',
    width: '400px'
  },
  tabelaCaixa: {
    width: '400px'
  },
  tabelaCaixaCelulas: {
    padding: '10px'
  },
  tabelaPagamentosCelulas: {
    border: 'solid .6px #cccccc',
    borderCollapse: 'collapse',
    padding: '10px'
  }
}

const Table = ({ list }) => (
  <table style={styles.tabelaPagamentos}>
    <tbody>
      {
        _.map(list, p => (
          <tr key={p.id}>
            <td style={styles.tabelaPagamentosCelulas}>{p.nome}</td>
            <td style={styles.tabelaPagamentosCelulas}>
              <NumberFormatMoneyBR value={p.valor} />
            </td>
          </tr>
        ))
      }
      <tr>
        <td style={styles.tabelaPagamentosCelulas}>
          <strong>Total</strong>
        </td>
        <td style={styles.tabelaPagamentosCelulas}>
          <strong>
            <NumberFormatMoneyBR value={_.sumBy(list, 'valor')} />
          </strong>
        </td>
      </tr>
    </tbody>
  </table>
)

function PreviewModal ({
  onHide,
  data
}) {
  const [list, setList] = useState([])
  const [printersModal, setPrintersModal] = useState({ open: false })
  let title = ''

  useEffect(() => {
    if (data.tela === 2) {
      Axios.get(`/api/Pedido/FormaPagamento/${data.id}`).then(result => setList(result))
    }
    if (data.tela === 4) {
      const entregador = JSON.parse(data.settings || '{}')
      setList([
        {
          nome: entregador?.nome,
          valor: data.valorLiquido
        }
      ])
    }
  }, [])

  if (data.tela === 2) {
    title = 'Formas de Pagamentos'
  }
  if (data.tela === 4) {
    title = 'Frete Repasse'
  }

  return (
    <Dialog open fullWidth>
      <Title
        title="Imprimir fechamento"
        buttons={[
          { icon: 'close', color: 'secondary', onClick: onHide }
        ]}
      />
      <DialogContent>
        <Grid container justify="space-between" spacing={1}>
          <Grid item xs>
            <Typography variant="h6">{title}</Typography>
            <Table list={list} />
          </Grid>
        </Grid>
        {printersModal?.open &&
          <PrinterModal
            onHide={(refresh) => {
              setPrintersModal({ open: false })
              if (refresh === true) {
                onHide()
              }
            }}
            list={list}
            data={printersModal?.data}
          />
        }
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setPrintersModal({ open: true, data })}
        >
          Imprimir
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewModal
