import React, { useState, useEffect } from 'react'
import { pullAt } from 'lodash'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Paper,
  Tooltip,
  Chip,
  IconButton,
  Icon
} from '@material-ui/core'
import Axios from 'axios'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import { NumberFormatMoneyBR } from '@em/lib/src/util/InputCustom'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'

import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { EMTable } from '@em/lib/src'
import { TextFieldPercent } from '../../../../_atoms/numberFormat'
import TextFieldWithSearchItems from '../../../../_atoms/TextFieldWithSearchItems'

const INITAL_ITEM = {
  id: '',
  nome: ''
}

const RangeSimplesNacional = {
  1: 4,
  2: 7.3,
  3: 9.5,
  4: 10.70,
  5: 14.30,
  6: 19.00
}

const colorDB = '#64b5f6'

const AddOrEditModalManual = props => {
  const [calcVenda, setCalcVenda] = useState({ changed: false })
  const [item, setItem] = useState(INITAL_ITEM)
  const [screen, setScreen] = useState(0)
  const [sistema, setSistema] = useState({})
  const [estoqueEntrada, setEstoqueEntrada] = useState({
    observacao: '',
    fornecedor: {},
    itens: [],
    tipo: null,
    chave: new Date().getTime().toString()
  })
  const [allItems, setAllItems] = useState([])

  const [showErrorAdd, setShowErrorAdd] = useState(false)
  const [showErrorSave, setShowErrorSave] = useState(false)

  const onSave = () => {
    const { itens } = estoqueEntrada
    let error = false
    if (!itens || itens?.length == 0) {
      error = true
    }

    if (error) {
      setShowErrorSave(true)
      return
    }

    Axios.post(`api/estoqueentrada/${Boolean(calcVenda.calc)}`, estoqueEntrada)
      .then(resp => {
        props.onHide(true)
      })
  }

  const removeItem = dataRow => {
    const items = estoqueEntrada.itens
    pullAt(items, dataRow?.tableData?.id)
    setEstoqueEntrada({ ...estoqueEntrada, itens: items })
  }

  const add = () => {
    const { id, qtde } = item
    const requerids = [id, qtde]

    let error = false
    requerids.forEach(element => {
      if (!element) {
        error = true
      }
    })

    if (error) {
      setShowErrorAdd(true)
      return
    }

    setEstoqueEntrada({ ...estoqueEntrada, itens: [...estoqueEntrada.itens, { ...item, itemDB: item }] })
    setItem(INITAL_ITEM)
    setShowErrorAdd(false)
  }

  const onCalc = async () => {
    if (calcVenda.calc && estoqueEntrada.itens.length > 0) {
      const itens = estoqueEntrada.itens.map(item => {
        let percents = calcVenda.margin
        if (calcVenda.sumFaixa && sistema?.tax?.range) {
          percents += RangeSimplesNacional[sistema.tax.range]
        }

        if (calcVenda.sumFixedExpence && sistema?.tax?.fixedExpense) {
          percents += sistema.tax.fixedExpense
        }

        let multiplicador = 1
        if (sistema.tax.formula == 'marckup') {
          multiplicador = 1 + (percents / 100)
        } else {
          multiplicador = 100 / (100 - percents)
        }
        const valor = item.costPrice * multiplicador

        return { ...item, valor }
      })
      setCalcVenda({ ...calcVenda, changed: false })
      setEstoqueEntrada({ ...estoqueEntrada, itens })
    }
  }

  useEffect(() => {
    const funcAsync = async () => {
      if (!sistema.id) {
        const responseSistema = await Axios.get('api/sistema/me')
        setSistema(responseSistema)
        setCalcVenda({
          ...calcVenda,
          formula: responseSistema?.tax?.formula,
          margin: responseSistema?.tax?.marginDefault
        })
      }
      await Axios.get('api/Item/full?componente=false')
        .then(resp => setAllItems(resp))
    }
    funcAsync()
  }, [])

  useEffect(() => {
    if (calcVenda.calc) {
      onCalc()
    }
  }, [calcVenda.calc, estoqueEntrada?.itens?.length])

  return (
    <div>
      <Dialog maxWidth="lg" fullWidth open>
        <Title
          title={screen === 0 ? 'Entrada e Saída Manual de Estoque' : screen === 2 ? 'Entrada Manual de Estoque' : 'Saída Manual de Estoque'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent style={{ minHeight: 120 }}>
          {screen == 0 &&
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setScreen(2)
                    setEstoqueEntrada({
                      ...estoqueEntrada,
                      tipo: 1
                    })
                  }}
                  endIcon={<FaArrowCircleUp />}
                  style={{
                    minHeight: 100,
                    minWidth: 200,
                    color: '#64b5f6',
                    borderColor: '#64b5f6'
                  }}
                >
                  Entrada Manual
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setScreen(3)
                    setEstoqueEntrada({
                      ...estoqueEntrada,
                      tipo: 2
                    })
                  }}
                  endIcon={<FaArrowCircleDown />}
                  style={{
                    minHeight: 100,
                    minWidth: 200,
                    color: '#f00',
                    borderColor: '#f00'
                  }}
                >
                  Saída Manual
                </Button>
              </Grid>
            </Grid>
          }

          {screen > 0 &&
            <Grid container spacing={1} alignItems="center" justify="space-between">
              {screen === 2 &&
                <Grid item xs={4}>
                  <TextField
                    label="Nota"
                    default={estoqueEntrada.nNF}
                    onChange={e => {
                      setEstoqueEntrada({ ...estoqueEntrada, nNF: e.target.value })
                    }}
                    value={`${estoqueEntrada?.nNF || ''}`}
                  />
                </Grid>
              }
              <Grid item xs={screen === 2 ? 8 : 12}>
                <TextField
                  label="Observação"
                  onChange={e => {
                    setEstoqueEntrada({ ...estoqueEntrada, observacao: e.target.value })
                  }}
                  value={`${estoqueEntrada?.observacao || ''}`}
                />
              </Grid>
              {screen > 1 &&
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <TextFieldWithSearchItems
                        error={showErrorAdd && !item?.id}
                        label={'Item'}
                        itens={allItems}
                        value={item?.id}
                        nameValue="id"
                        onChange={(e, obj) => {
                          setItem({ ...item, ...obj })
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        error={showErrorAdd && !item.qtde}
                        name="qtde"
                        type="number"
                        label="Quantidade"
                        onChange={(e) => setItem({ ...item, [e.target.name]: e.target.value })}
                        value={item.qtde}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        fullWidth
                        variant="contained" color="primary"
                        onClick={add}
                        style={{ height: '100%' }}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
              {screen === 2 &&
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={estoqueEntrada?.itens?.length === 0}
                    control={
                      <Checkbox
                        color="primary"
                        checked={calcVenda.calc}
                        onChange={e => setCalcVenda({ ...calcVenda, changed: true, calc: e.target.checked })}
                      />
                    }
                    label={'Quero atualizar o preço'}
                  />
                </Grid>
              }
              {calcVenda.calc &&
                <Grid item xs={12}>
                  <Paper>
                    <Grid container alignItems="center" justify="space-between" spacing={1}>
                      <Grid item>
                        <TextFieldPercent
                          fullWidth
                          label={'Aplicar ' + (sistema.tax.formula || 'margem')}
                          onValueChange={e => setCalcVenda({ ...calcVenda, changed: true, margin: e.target.value })}
                          value={calcVenda.margin}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox color="primary"
                            checked={calcVenda.sumFaixa}
                            onChange={e => setCalcVenda({ ...calcVenda, changed: true, sumFaixa: e.target.checked })}
                          />}
                          label={`Adicionar DAS (${RangeSimplesNacional[sistema.tax.range]}%)`}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox color="primary"
                            checked={calcVenda.sumFixedExpence}
                            onChange={e => setCalcVenda({ ...calcVenda, changed: true, sumFixedExpence: e.target.checked })}
                          />}
                          label={`Adicionar Despesas fixas (${sistema.tax.fixedExpense}%)`}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          disabled={!calcVenda.changed || !estoqueEntrada.itens || estoqueEntrada.itens.length == 0}
                          variant={'contained'}
                          color="primary"
                          onClick={onCalc}>Calcular</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              }

              <Grid item xs={12}>
                <EMTable
                  options={{
                    toolbar: false,
                    paging: false
                  }}
                  data={estoqueEntrada.itens}
                  columns={[
                    {
                      title: 'Codigo',
                      field: 'externalCode',
                      width: 200,
                      editable: 'never',
                      render: dataRow => {
                        return (
                          <>
                            <p style={{ color: colorDB }}>
                              {dataRow?.itemDB?.codigo}
                            </p>
                          </>
                        )
                      }
                    },
                    {
                      title: 'Nome',
                      field: 'nome',
                      editable: 'never',
                      render: dataRow => {
                        return (
                          <div>
                            {dataRow?.itemDB?.nome &&
                              <p style={{ color: colorDB }}>
                                {dataRow?.itemDB?.nome}
                              </p>
                            }
                          </div>
                        )
                      }
                    },
                    {
                      width: 150,
                      title: 'Unid. Medida',
                      field: 'unidadeMedida',
                      editable: 'never',
                      render: dataRow => {
                        return (
                          <div style={{ color: colorDB }}>
                            {dataRow.itemDB?.unidadeMedida}
                          </div>
                        )
                      }
                    },
                    {
                      title: 'Qtde',
                      field: 'qtde',
                      editable: 'never',
                      width: 30
                    },
                    {
                      title: <Tooltip title='Custo Atual do Produto'><span>Custo Atual</span></Tooltip>,
                      field: 'itemDB.costPrice',
                      headerStyle: {
                        backgroundColor: colorDB,
                        color: '#FFF'
                      },
                      editable: 'never',
                      width: 90,
                      render: dataRow => (
                        <div style={{ color: colorDB }}>
                          <NumberFormatMoneyBR value={dataRow.itemDB?.costPrice} />
                        </div>
                      )
                    },
                    {
                      title: '',
                      editable: 'never',
                      hidden: !calcVenda.calc,
                      width: 25,
                      headerStyle: {
                        backgroundColor: colorDB,
                        color: '#FFF'
                      },
                      render: dataRow => {
                        if (!dataRow?.itemDB || !dataRow?.valor) {
                          return null
                        } else if (dataRow?.itemDB?.costPrice > dataRow?.costPrice) {
                          return (<ArrowDownward style={{ color: '#FF0000' }} />)
                        }
                        return (<ArrowUpward style={{ color: '#00FF00' }} />)
                      }
                    },
                    {
                      title: <Tooltip title='Preço atual de venda'><span>Preço Atual</span></Tooltip>,
                      editable: 'never',
                      width: 107,
                      field: 'itemDB.valor',
                      headerStyle: {
                        backgroundColor: colorDB,
                        color: '#FFF'
                      },
                      render: dataRow => (
                        <div style={{ color: colorDB }}>
                          {dataRow.id
                            ? <div>
                              <NumberFormatMoneyBR value={dataRow.itemDB?.valor} />
                            </div>
                            : <Chip label="Não encontrado" />
                          }
                        </div>
                      )
                    },
                    {
                      title: '',
                      editable: 'never',
                      hidden: !calcVenda.calc,
                      width: 25,
                      headerStyle: {
                        backgroundColor: '#fff176',
                        color: '#FFF'
                      },
                      render: dataRow => {
                        if (!dataRow?.itemDB || !dataRow?.valor) {
                          return null
                        } else if (dataRow?.itemDB?.valor > dataRow?.valor) {
                          return (<ArrowDownward style={{ color: '#FF0000' }} />)
                        }
                        return (<ArrowUpward style={{ color: '#00FF00' }} />)
                      }
                    },
                    {
                      title: <Tooltip title='Novo Preço de venda'><span>Novo Preço</span></Tooltip>,
                      editable: 'always',
                      headerStyle: {
                        backgroundColor: '#fff176'
                      },
                      width: 150,
                      hidden: !calcVenda.calc,
                      field: 'valor',
                      render: dataRow => dataRow.valor && <NumberFormatMoneyBR value={dataRow.valor} />
                    },
                    {
                      title: <Tooltip title='Novo custo do produto'><span>Novo Custo</span></Tooltip>,
                      editable: 'always',
                      headerStyle: {
                        backgroundColor: '#fff176'
                      },
                      width: 150,
                      hidden: !calcVenda.calc,
                      field: 'costPrice',
                      render: dataRow => dataRow.costPrice && <NumberFormatMoneyBR value={dataRow.costPrice} />
                    },
                    {
                      editable: 'never',
                      width: 50,
                      headerStyle: {
                        backgroundColor: calcVenda.calc ? '#fff176' : colorDB
                      },
                      render: dataRow => (
                        <IconButton onClick={() => removeItem(dataRow)}>
                          <Icon>delete</Icon>
                        </IconButton>
                      )
                    }
                  ]}
                  cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                      return new Promise((resolve, reject) => {
                        const itens = estoqueEntrada.itens.map((item, idx) => {
                          if (idx === rowData?.tableData?.id) {
                            return {
                              ...item,
                              [columnDef.field]: newValue
                            }
                          }
                          return item
                        })
                        setEstoqueEntrada({ ...estoqueEntrada, itens })
                        setCalcVenda({ ...calcVenda, changed: columnDef.field === 'costPrice' })
                        resolve()
                      })
                    }
                  }}
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
        {screen > 1 &&
          <DialogActions>
            <Button color="primary" onClick={onSave}>
              Salvar
            </Button>
          </DialogActions>
        }
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(AddOrEditModalManual)
