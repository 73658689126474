import React, { PureComponent } from 'react'
import { Grid } from '@material-ui/core'

import { Fieldset, TextField } from '../../../../_atoms'
import TextFieldWithSearchItems from '../../../../_atoms/TextFieldWithSearchItems'

class TabEmbalagens extends PureComponent {
  componentDidMount () {
    this.props.loadItensFull()
  }

  render () {
    const { form, handlerChangeDimensoes, allItems, handlerChange } = this.props
    const { dimensoesEmbalagem } = form
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Altura do item(metros)"
              name="altura"
              type="number"
              value={dimensoesEmbalagem && dimensoesEmbalagem.altura || ''}
              onChange={handlerChangeDimensoes}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Largura do item(metros)"
              name="largura"
              type="number"
              value={dimensoesEmbalagem && dimensoesEmbalagem.largura || ''}
              onChange={handlerChangeDimensoes}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Profundiade do item(metros)"
              name="profundidade"
              type="number"
              value={dimensoesEmbalagem && dimensoesEmbalagem.profundidade || ''}
              onChange={handlerChangeDimensoes}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Peso do item(kg)"
              name="peso"
              type="number"
              value={dimensoesEmbalagem && dimensoesEmbalagem.peso || ''}
              onChange={handlerChangeDimensoes}
            />
          </Grid>
          <Grid item xs={12}>
            <Fieldset label="Este produto pode se transformar em:">
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <TextFieldWithSearchItems
                    label={'Item'}
                    itens={allItems}
                    value={form?.transformTo?.id}
                    nameValue="id"
                    onChange={(e, obj) => {
                      handlerChange({
                        target: {
                          name: 'transformTo',
                          value: {
                            ...form.transformTo,
                            id: obj?.id,
                            code: obj?.codigo,
                            name: obj?.nome
                          }
                        }
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="number"
                    label="Unidades"
                    value={form?.transformTo?.qtdeUnity}
                    onChange={e => {
                      handlerChange({
                        target: {
                          name: 'transformTo',
                          value: {
                            ...form.transformTo,
                            qtdeUnity: Number(e.target.value)
                          }
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default TabEmbalagens
