import React, { useRef, useState } from 'react'
import { EMTable } from '@em/lib/src'
import Axios from 'axios'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'

import AddOrEditModal from './components/addOrEditModal'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { EMChoiceConectorModal } from '../../../_atoms'
import { IconButtonDelete } from '../../../_atoms/button'

export default ({ funcoes }) => {
  const tableRef = useRef(null)
  const [modalAddOrEdit, setMoodalAddOrEdit] = useState({ open: false })
  const [choiceConectorModal, setChoiceConectorModal] = useState({ open: false, data: null })

  const loadSchedule = (params) => tableRef?.current?.onQueryChange(params)

  const deleteBoletim = (boletim) => {
    Axios.delete(`/api/Schedule/${boletim?.id}`)
      .then(() => loadSchedule())
  }

  return (
    <div>
      {!modalAddOrEdit.open &&
        <div>
          <Title
            title="Boletins"
            buttons={[
              {
                icon: 'add',
                onClick: () => setMoodalAddOrEdit({ open: true })
              }
            ]}
          />
          <EMTable
            tableRef={tableRef}
            data={query => {
              const { page, pageSize } = query
              return Axios.get('/api/schedule', { params: { offset: page, limit: pageSize } }).then(response => {
                return {
                  data: response.lista,
                  page,
                  totalCount: response.paginacao.qtdeRows || 0
                }
              })
            }}
            columns={[
              {
                maxWidth: 40,
                render: dataRow => (
                  <>
                    {dataRow?.status === 1 &&
                      <Tooltip title="Publicado">
                        <Icon style={{ color: 'green' }}>publish</Icon>
                      </Tooltip>
                    }
                  </>
                )
              },
              {
                title: 'Data',
                field: 'scheduledAt',
                render: dataRow => {
                  return fnHelper.toDateTimeBR(dataRow.scheduledAt)
                }
              },
              {
                title: 'Nome',
                field: 'name'
              },
              {
                title: 'Descrição',
                field: 'description'
              },
              {
                maxWidth: 140,
                render: dataRow => (
                  <div>
                    <Tooltip title="Editar">
                      <IconButton
                        color="primary"
                        onClick={() => setMoodalAddOrEdit({ open: true, data: dataRow })}
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar Email">
                      <IconButton
                        color="primary"
                        onClick={() => setChoiceConectorModal({ open: true, data: dataRow })}
                      >
                        <Icon>send</Icon>
                      </IconButton>
                    </Tooltip>
                    <IconButtonDelete
                      onClick={() => deleteBoletim(dataRow)}
                      funcoes={funcoes}
                      perguntar
                      mensagem={'Você realmente deletar este boletim?'}
                    />
                  </div>
                )
              }
            ]}
          />
        </div>
      }

      {modalAddOrEdit.open &&
        <AddOrEditModal
          data={modalAddOrEdit.data}
          onHide={refresh => {
            setMoodalAddOrEdit({ open: false })
            if (refresh) {
              loadSchedule()
            }
          }}
        />
      }

      {choiceConectorModal?.open &&
        <EMChoiceConectorModal
          apiGet="api/conector/full?type=boletim"
          apiPost={`api/schedule/send_email/${choiceConectorModal?.data?.id}`}
          queryPost={'tags=boletim'}
          onHide={() => {
            setChoiceConectorModal({ open: false })
          }}
          label={'Escolha um Email de Sáida'}
          title={''}
          buttonLabel={'Enviar Email'}
          nameDefault={choiceConectorModal?.data?.name}
          inputLabel="Assunto do Email"
        />
      }

    </div>
  )
}
