import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Button
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { DatePicker, TextField } from '../../../../_atoms'
import { TextFieldMoneyBR, TextFieldPercent } from '../../../../_atoms/numberFormat'

function ContentConfig ({
  data,
  onClick
}) {
  const [cupomConfig, setCupomConfig] = useState(data)
  const [showError, setShowError] = useState(false)

  const nextStep = () => {
    let error = false
    const requireds = [
      cupomConfig.dataExpiracao,
      cupomConfig.descricao,
      cupomConfig.titulo,
      cupomConfig.desconto,
      cupomConfig.prefixo,
      cupomConfig.qtdeMaxUsuario,
      cupomConfig.regras
    ]
    requireds.forEach(r => {
      if (!r) {
        error = true
      }
    })
    if (error) {
      setShowError(true)
      return
    }
    onClick(cupomConfig)
  }

  useEffect(() => {
    setCupomConfig(current => ({ ...current, ...data }))
  }, [data])

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={4}
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <TextField
          label="Prefixo"
          onChange={e => setCupomConfig({
            ...cupomConfig,
            prefixo: e.target.value
          })}
          value={cupomConfig?.prefixo}
          error={!cupomConfig?.prefixo && showError}
        />
      </Grid>
      <Grid item xs={12} sm={4}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Tipo de desconto</FormLabel>
          <RadioGroup
            row
            fullWidth
            name="percentualValor"
            value={cupomConfig?.percentualValor}
            onChange={e => setCupomConfig({
              ...cupomConfig,
              percentualValor: Number(e.target.value)
            })}
          >
            <FormControlLabel
              value={0}
              control={<Radio color="primary" />}
              label="Percentual"
            />
            <FormControlLabel
              value={1}
              control={<Radio color="primary" />}
              label="Valor Fixo"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        {cupomConfig?.percentualValor === 1 &&
          <TextFieldMoneyBR
            label={'Valor do desconto'}
            onValueChange={e => setCupomConfig({
              ...cupomConfig,
              desconto: e.target.value
            })}
            value={cupomConfig?.desconto}
            error={!cupomConfig?.desconto && showError}
          />
        }
        {cupomConfig?.percentualValor === 0 &&
          <TextFieldPercent
            label={'Percentual do desconto'}
            onValueChange={e => setCupomConfig({
              ...cupomConfig,
              desconto: e.target.value
            })}
            value={cupomConfig?.desconto}
            error={!cupomConfig?.desconto && showError}
          />
        }
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label={'Quantidade de Cupons'}
          value={cupomConfig?.qtde}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label={'Limite de uso por usuário'}
          onChange={e => setCupomConfig({
            ...cupomConfig,
            qtdeMaxUsuario: e.target.value
          })}
          value={cupomConfig?.qtdeMaxUsuario}
          error={!cupomConfig?.qtdeMaxUsuario && showError}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePicker
          label="Data de Expiração"
          onChange={value => setCupomConfig({ ...cupomConfig, dataExpiracao: value })}
          value={cupomConfig.dataExpiracao}
          error={!cupomConfig?.dataExpiracao && showError}
          disablePast
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={'Título do Cupom'}
          onChange={e => setCupomConfig({
            ...cupomConfig,
            titulo: e.target.value
          })}
          value={cupomConfig?.titulo}
          error={!cupomConfig?.titulo && showError}
        />
      </Grid>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <TextField
          label={'Descrição do Cupom'}
          onChange={e => {
            if (e.target.value.length <= 200) {
              setCupomConfig({
                ...cupomConfig,
                descricao: e.target.value
              })
            }
          }}
          value={cupomConfig?.descricao}
          multiline
          rows={2}
          error={!cupomConfig?.descricao && showError}
        />
        <div
          style={{
            position: 'absolute',
            top: 15,
            right: 15,
            fontSize: 15,
            color: '#747473'
          }}
        >
          ({cupomConfig.descricao.length} de 200 caracteres)
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={'Descrição das regras'}
          onChange={e => setCupomConfig({
            ...cupomConfig,
            regras: e.target.value
          })}
          value={cupomConfig?.regras}
          multiline
          rows={2}
          error={!cupomConfig?.regras && showError}
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={nextStep}
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  )
}

export default ContentConfig
