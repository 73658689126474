import Title from '@em/lib/src/util/Title'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup
} from '@material-ui/core'
import _ from 'lodash'
import React, { useState } from 'react'

function FiltersModal ({
  onHide,
  userReducer,
  canaisContstants,
  todosStatusDePedidos,
  vendedores,
  filter,
  onFilter,
  pedidos
}) {
  const [form, setForm] = useState({
    open: false,
    canais: [],
    status: [],
    vendedores: [userReducer?.pessoa?.id],
    clientes: [],
    ...filter
  })
  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
    >
      <Title title="Filtrar Pedidos" buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
      <DialogContent>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Paper>
              <CardHeader title={'Visualização'} />
              <FormControl>
                <RadioGroup
                  row
                  value={form.view}
                  onChange={e => setForm({ ...form, view: e.target.value })}
                  name="view-group"
                >
                  <FormControlLabel value="me" control={<Radio />} label="Meus Pedidos" />
                  <FormControlLabel value="all" control={<Radio />} label="Todos Pedidos" />
                </RadioGroup>
              </FormControl>

              {/* <h4>Vendedor</h4>
              {vendedores.map(v => {
                return (
                  <FormControlLabel
                    key={v.id}
                    onChange={e => setForm({ ...form, vendedores: e.target.checked ? [...form.vendedores, v.id] : form.vendedores.filter(x => x != v.id) })}
                    control={(
                      <Checkbox
                        checked={form?.vendedores?.includes(v.id)}
                      />
                    )}
                    label={v.nome}
                  />
                )
              })} */}

            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <CardHeader title={'Canais'} />
              {canaisContstants.map(c => {
                return (
                  <FormControlLabel
                    key={c.id}
                    control={(
                      <Checkbox
                        onChange={e => setForm({ ...form, canais: e.target.checked ? [...form.canais, c.id] : form.canais.filter(x => x != c.id) })}
                        checked={form.canais.includes(c.id)}
                      />
                    )}
                    label={c.nome}
                  />
                )
              })}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <CardHeader title={'Status'} />
              {todosStatusDePedidos.map(s => {
                return (
                  <FormControlLabel
                    key={s.id}
                    onChange={e => setForm({ ...form, status: e.target.checked ? [...form.status, s.id] : form.status.filter(x => x != s.id) })}
                    control={(
                      <Checkbox
                        checked={form.status.includes(s.id)}
                      />
                    )}
                    label={s.name}
                  />
                )
              })}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <CardHeader title={'Clientes'} />
              {_.uniqBy(pedidos?.filter(p => p?.cliente)?.map(p => p.cliente), 'id')?.map(c => {
                return (
                  <FormControlLabel
                    key={c.id}
                    onChange={e => setForm({ ...form, clientes: e.target.checked ? [...form.clientes, c.id] : form.clientes.filter(x => x != c.id) })}
                    control={(
                      <Checkbox
                        checked={form?.clientes?.includes(c.id)}
                      />
                    )}
                    label={c.nome}
                  />
                )
              })}
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onFilter(form)}
          variant="contained"
          color="primary"
        >
          Aplicar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FiltersModal
