import React, { useEffect, useState } from 'react'
import {
  Grid, Dialog, DialogContent, DialogActions, Button, Typography
} from '@material-ui/core'
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { NumberFormatMoneyBR } from '../../../../_atoms/numberFormat'
import Title from '@em/lib/src/util/Title'
import ImprimirFechamentoModal from './imprimirFechamentoModal'

const styles = {
  dialogContent: {
    paddingTop: '20px',
    width: '620px'

  },
  tabelaPagamentos: {
    border: 'solid .6px #cccccc',
    borderCollapse: 'collapse',
    width: '400px'
  },
  tabelaCaixa: {
    width: '400px'
  },
  tabelaCaixaCelulas: {
    padding: '10px'
  },
  tabelaPagamentosCelulas: {
    border: 'solid .6px #cccccc',
    borderCollapse: 'collapse',
    padding: '10px'
  }
}

const DadosCaixa = ({ caixa }) => (
  <table style={styles.tabelaCaixa}>
    <tbody>
      <tr>
        <td style={styles.tabelaCaixaCelulas}>
          <strong>
            Caixa:
          </strong>
        </td>
        <td style={styles.tabelaCaixaCelulas}>
          {caixa.numero}
        </td>
      </tr>
      <tr>
        <td style={styles.tabelaCaixaCelulas}>
          <strong>
            Operador:
          </strong>
        </td>
        <td style={styles.tabelaCaixaCelulas}>
          {caixa.operador.nome}
        </td>
      </tr>
      <tr>
        <td style={styles.tabelaCaixaCelulas}>
          <strong>
            Aberto:
          </strong>
        </td>
        <td style={styles.tabelaCaixaCelulas}>
          {moment(caixa.dataAbertura).format('DD/MM/YYYY HH:mm')}
        </td>
      </tr>
    </tbody>
  </table>
)

const FormasPagamento = ({ paymentList }) => (
  <table style={styles.tabelaPagamentos}>
    <tbody>
      {
        _.map(paymentList, p => (
          <tr key={p.id}>
            <td style={styles.tabelaPagamentosCelulas}>{p.nome}</td>
            <td style={styles.tabelaPagamentosCelulas}>
              <NumberFormatMoneyBR value={p.valor} />
            </td>
          </tr>
        ))
      }
      <tr>
        <td style={styles.tabelaPagamentosCelulas}>
          <strong>Total</strong>
        </td>
        <td style={styles.tabelaPagamentosCelulas}>
          <strong>
            <NumberFormatMoneyBR value={_.sumBy(paymentList, 'valor')} />
          </strong>
        </td>
      </tr>
    </tbody>
  </table>
)

const FecharCaixaDialog = (props) => {
  const { onHide, caixa, emUtility } = props
  const [paymentList, setPaymentList] = useState([])
  const [freteRepassesList, setFreteRepassesList] = useState([])
  const [modalImprimir, setModalImprimir] = useState(false)

  useEffect(() => {
    axios.get(`/api/Pedido/FormaPagamento/${caixa.financeiroId}`).then(result => setPaymentList(result))
    axios.get(`/api/Pedido/FreteRepasse/${caixa.financeiroId}`).then(result => setFreteRepassesList(result))
  }, [])

  const fecharCaixa = () => {
    axios.post(`/api/Caixa/Fechar/${caixa.id}`).then(result => {
      setModalImprimir(true)
    })
  }

  return (
    <>
      <Dialog open={!modalImprimir} maxWidth="lg" fullWidth>
        <Title
          title="Fechamento de caixa"
          buttons={[
            { icon: 'close', color: 'secondary', onClick: onHide }
          ]}
        />
        <DialogContent>
          <Grid container justify="space-between" spacing={1}>
            <Grid item xs={freteRepassesList?.length > 0 ? 4 : 6}>
              <DadosCaixa caixa={caixa} />
            </Grid>
            <Grid item xs={freteRepassesList?.length > 0 ? 4 : 6}>
              <Typography variant="h6">Formas de Pagamento</Typography>
              <FormasPagamento paymentList={paymentList} />
            </Grid>
            {freteRepassesList?.length > 0 &&
              <Grid item xs={4}>
                <Typography variant="h6">Repasse Delivery</Typography>
                <FormasPagamento paymentList={freteRepassesList} />
              </Grid>
            }
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button onClick={onHide} color="secondary">
            fechar depois
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={fecharCaixa}
          >
            Fechar agora
          </Button>
        </DialogActions>

      </Dialog>

      {modalImprimir &&
        <ImprimirFechamentoModal
          title="Caixa fechado com sucesso!"
          caixa={caixa}
          emUtility={emUtility}
          paymentList={paymentList}
          freteRepassesList={freteRepassesList}
          printer={caixa.impressora}
        />
      }
    </>
  )
}

export default FecharCaixaDialog
