import React, { Component } from 'react'
import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  Icon,
  Chip
} from '@material-ui/core'
import { isNullOrUndefined } from 'util'
import axios from 'axios'
import { TextField, Fieldset } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { TextFieldMoneyBR } from '../../../../_atoms/numberFormat'
import SearchItemDialog from '../searchItemDialog'

class TabComposicaoOpcaoModal extends Component {
  state = {
    searchItemDialog: { open: false },
    showError: false,
    opcao: {
      minimo: 0, maximo: 1000, ativo: true, ...this.props.data
    },
    selecteds: []
  }

  handlerChange = e => {
    this.setState({ opcao: { ...this.state.opcao, [e.target.name]: e.target.value } })
  };

  onAddItem = selecteds => {
    this.setState({ selecteds, searchItemDialog: { open: false } })
  };

  formEstaValido = () => {
    const { minimo, maximo } = this.state.opcao
    const showError = isNullOrUndefined(minimo) || !maximo || Number(minimo) < 0 || Number(maximo) < 0 || Number(maximo) < Number(minimo)
    this.setState({ showError })
    return !showError
  };

  onOk = () => {
    if (this.formEstaValido()) {
      this.props.handlerChange(this.state.opcao, this.state.selecteds)
    }
  };

  onOpenSearchItens = search => {
    const { searchItemDialog } = this.state
    if (!searchItemDialog.loadItens) {
      axios.get('api/item/full').then(itens => {
        this.setState({
          searchItemDialog: {
            open: true, loadItens: true, itens, search
          }
        })
      })
    } else {
      this.setState({ searchItemDialog: { ...searchItemDialog, open: true, search } })
    }
  };

  render() {
    const { showError, opcao, searchItemDialog } = this.state
    const {
      onHide, montagem, tags, multi
    } = this.props

    return (
      <>
        <Dialog open>
          <Title
            title={this.props.data ? 'Editar opção' : 'Nova opção'}
            subTitle={`Em ${montagem.titulo}`}
            buttons={[
              {
                icon: 'close',
                color: 'secondary',
                onClick: onHide
              }
            ]}
          />

          <DialogContent style={{ paddingTop: 10 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Fieldset label="Opções">
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      {multi &&
                        this.state.selecteds?.map((sel, idx) => <Chip variant="outlined" key={idx} label={sel.nome} />)}

                      {!multi && <Chip variant="outlined" label={opcao.nome} />}
                    </div>
                    <div>
                      {multi && (
                        <IconButton onClick={this.onOpenSearchItens}>
                          <Icon>add</Icon>
                        </IconButton>
                      )}
                    </div>
                  </div>
                </Fieldset>
              </Grid>
              <Grid item xs={6} container style={{ minHeight: 58 }} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.handlerChange({ target: { name: "incluso", value: 1 } })
                        } else {
                          this.handlerChange({ target: { name: "incluso", value: 0 } })
                        }
                      }}
                      checked={opcao.incluso > 0}
                    />
                  }
                  label="É incluso?"
                />
              </Grid>
              <Grid item xs={6}>
                {opcao.incluso > 0 &&
                  <TextField
                    style={{ width: 100 }}
                    error={showError && isNullOrUndefined(opcao.incluso)}
                    label="Incluso"
                    name="incluso"
                    required
                    type="number"
                    value={opcao.incluso}
                    onChange={this.handlerChange}
                  />
                }
              </Grid>
              <Grid item xs={6} container style={{ minHeight: 58 }} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.handlerChange({ target: { name: "minimo", value: 1 } })
                        } else {
                          this.handlerChange({ target: { name: "minimo", value: 0 } })
                        }
                      }}
                      checked={opcao.minimo > 0}
                    />
                  }
                  label="É Obrigatório?"
                />
              </Grid>
              <Grid item xs={6}>
                {opcao.minimo > 0 &&
                  <TextField
                    style={{ width: 100 }}

                    error={showError && (isNullOrUndefined(opcao.minimo) || Number(opcao.maximo) < Number(opcao.minimo))}
                    label="Mínimo"
                    name="minimo"
                    required
                    type="number"
                    value={isNullOrUndefined(opcao.minimo) ? '' : opcao.minimo}
                    onChange={this.handlerChange}
                  />
                }
              </Grid>
              <Grid item xs={6} container style={{ minHeight: 58 }} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          this.handlerChange({ target: { name: "maximo", value: 10 } })
                        } else {
                          this.handlerChange({ target: { name: "maximo", value: 1000 } })
                        }
                      }
                      }
                      checked={opcao.maximo < 1000}
                    />
                  }
                  label="Tem maximo?"
                />
              </Grid>
              <Grid item xs={6}>
                {opcao.maximo < 1000 &&
                  <TextField
                    style={{ width: 100 }}
                    error={showError && (!Number(opcao.maximo) || Number(opcao.maximo) < Number(opcao.minimo))}
                    label="Máximo"
                    name="maximo"
                    required
                    type="number"
                    value={isNullOrUndefined(opcao.maximo) ? '' : opcao.maximo}
                    onChange={this.handlerChange}
                  />
                }
              </Grid>
              <Grid item xs={6}>
                <TextFieldMoneyBR
                  name="valor"
                  value={isNullOrUndefined(opcao.valor) ? '' : opcao.valor}
                  label="Valor por adicional R$"
                  onValueChange={this.handlerChange}
                />
              </Grid>


            </Grid>
          </DialogContent>

          <DialogActions>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={opcao.ativo}
                  onChange={e => this.handlerChange({
                    target: {
                      name: 'ativo',
                      value: e.target.checked
                    }
                  })}
                />
              )}
              label="Ativo"
            />
            <Button color="primary" variant="contained" onClick={this.onOk}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {searchItemDialog.open && (
          <SearchItemDialog
            onAddItem={this.onAddItem}
            itens={searchItemDialog.itens}
            title="Escolher itens para composição"
            search=""
            multi={multi}
            tags={tags}
            multi
            onHide={() => this.setState({
              searchItemDialog: { ...searchItemDialog, open: false }
            })}
          />
        )}
      </>
    )
  }
}

export default TabComposicaoOpcaoModal
