import React, { useState } from 'react'
import { EMTable } from '@em/lib/src'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import Axios from 'axios'
import _ from 'lodash'
import moment, { isMoment } from 'moment'
import { Icon, IconButton, Tooltip } from '@material-ui/core'

import { NumberFormatMoneyBR } from '../../../../_atoms/numberFormat'
import PreviewModal from './previewModal'

function List ({
  tableRef,
  filter,
  parent
}) {
  const [previewModal, setPreviewModal] = useState({ open: false, data: null })

  return (
    <>
      <EMTable
        tableRef={tableRef}
        data={async query => {
          const { page, pageSize, orderDirection, orderBy } = query

          let params = {
            offset: page,
            limit: pageSize
          }

          _.keys(filter).forEach(key => {
            if (!fnHelper.isNullOrUndefined(filter[key]) && !_.isEmpty(filter[key])) {
              if (isMoment(filter[key])) {
                params = { ...params, [key]: moment(filter[key]).format('YYYY-MM-DD') }
              } else {
                params = { ...params, [key]: filter[key] }
              }
            }
          })

          if (orderBy && orderDirection) {
            const field = orderBy.field.charAt(0).toUpperCase() + orderBy.field.substr(1)
            params = { ...params, sort: `${field}-${orderDirection}` }
          }

          const response = await Axios.get('api/financeiro', { params })

          return {
            data: response?.lista,
            page,
            totalCount: response?.paginacao?.qtdeRows || 0
          }
        }}
        options={{
          sorting: true
        }}
        columns={[
          {
            field: 'dataCadastro',
            title: 'Data de Lançamento',
            render: dataRow => fnHelper.toDateTimeBR(dataRow.dataCadastro)
          },
          {
            field: 'dataPagamento',
            title: 'Data de Pagamento',
            render: dataRow => fnHelper.toDateBR(dataRow.dataPagamento)
          },
          {
            hidden: parent === 'fechamentoCaixa',
            field: 'conta.nome',
            title: 'Conta'
          },
          {
            field: 'observacao',
            title: 'Observação'
          },
          {
            hidden: parent === 'fechamentoCaixa',
            maxWidth: 50,
            field: 'status',
            render: dataRow => dataRow.status === 0 && (
              <Tooltip title="Pendente de aprovação">
                <Icon style={{ color: 'yellow' }}>warning</Icon>
              </Tooltip>
            )
          },
          {
            hidden: parent === 'fechamentoCaixa',
            maxWidth: 50,
            field: 'tipo',
            render: dataRow => (dataRow.tipo === 1 ? (
              <Tooltip title="Despesa">
                <Icon style={{ color: 'red' }}>arrow_downward</Icon>
              </Tooltip>
            ) : dataRow.tipo === 2 ? (
              <Tooltip title="Receita">
                <Icon style={{ color: 'green' }}>arrow_upward</Icon>
              </Tooltip>
            ) : (<div />))
          },
          {
            maxWidth: 120,
            field: 'valorLiquido',
            title: 'Valor Líquido',
            render: dataRow => <NumberFormatMoneyBR value={dataRow.valorLiquido} />
          },
          {
            maxWidth: 60,
            render: dataRow => (
              <IconButton onClick={() => setPreviewModal({ open: true, data: dataRow })}>
                <Icon>print</Icon>
              </IconButton>
            )
          }
        ]}
      />
      {previewModal.open &&
        <PreviewModal
          onHide={() => setPreviewModal({ open: false })}
          data={previewModal.data}
        />
      }
    </>
  )
}

export default List
