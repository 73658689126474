export default [
  {
    id: 0,
    nome: 'Loja'
  },
  {
    id: 1,
    nome: 'Entrega'
  },
  {
    id: 2,
    nome: 'Ifood'
  },
  {
    id: 3,
    nome: 'Loja Virtual'
  }
]
