import React from 'react'
import {
  Chip, Icon, IconButton, Grid
} from '@material-ui/core'
import axios from 'axios'
import { Fieldset } from './../../../../_atoms'
import './barraPedidos.css'

class BarraPedidos extends React.Component {
  onAddPedido = canal => {
    axios.post('api/pedido', { canal }).then(response => {
      if (response.dados) {
        this.props.onAddPedido(response.dados, true)
      }
    })
  };


  getStyleStatusEntrega = pedido => {
    if (pedido.statusEntrega == 1) {
      if (!pedido.trancadoPara) {
        return { animation: 'atencao 2s infinite' }
      }
      return { border: '2px solid black' }
    }

    const status = this.props.todosStatusDePedidos.find(x => x.id == pedido.statusEntregaId)
    return { border: `2px solid ${status?.color}` }
  }

  render() {
    const { pedidos, onSelectPedido, pedido } = this.props

    const pedidosBalcao = pedidos.filter(x => !x.canal)
    const pedidosDelivery = pedidos.filter(x => x.canal == 1)
    const pedidosIfood = pedidos.filter(x => x.canal == 2)
    const pedidosEmoutech = pedidos.filter(x => x.canal == 3)

    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Fieldset label={
            <div>
              Balcão
            </div>
          }>
            <Grid container spacing={1}>
              <Grid item>
                <IconButton size="small" variant="outlined" onClick={() => this.onAddPedido(0)}>
                  <Icon>add</Icon>
                </IconButton>
              </Grid>
              {pedidosBalcao.map((p, idx) => {
                let style = this.getStyleStatusEntrega(p)
                const lbArray = []
                if (p.cliente && p.cliente.nome) {
                  lbArray.push(p.cliente.nome.split(' ')[0])
                  lbArray.push('-')
                }
                lbArray.push(p.numero)
                if (pedido.id === p.id) style = { ...style, backgroundColor: 'black', color: 'white' }
                return (
                  <Grid item>
                    <Chip
                      key={idx}
                      size="small"
                      style={style}
                      onClick={() => onSelectPedido(p)}
                      variant="outlined"
                      label={lbArray.join(' ')}
                    />
                  </Grid>
                )
              })}
            </Grid>

          </Fieldset>
        </Grid>
        <Grid item xs={6}>
          <Fieldset label="Delivery">
            <IconButton onClick={() => this.onAddPedido(1)}>
              <Icon>phone</Icon>
            </IconButton>

            {pedidosDelivery.map((p, idx) => {
              let style = this.getStyleStatusEntrega(p)
              const lbArray = []
              if (p.cliente && p.cliente.nome) {
                lbArray.push(p.cliente.nome.split(' ')[0])
                lbArray.push('-')
              }
              lbArray.push(p.numero)
              if (pedido.id === p.id) style = { ...style, backgroundColor: 'black', color: 'white' }
              return (
                <Chip
                  size="small"
                  style={style}
                  key={`${p.id}-${p.statusEntrega}`}
                  onClick={() => onSelectPedido(p)}
                  variant="outlined"
                  label={lbArray.join(' ')}
                />
              )
            })}
          </Fieldset>
        </Grid>

        {pedidosIfood && pedidosIfood.length > 0 && (
          <Grid item xs={12}>
            <Fieldset label="Ifood">
              <IconButton style={{ padding: 0 }} variant="outlined">
                <img style={{ width: 48, borderRadius: 24 }} src={require('./.././../../../assets/img/pdv/ifood.png')} />
              </IconButton>

              {pedidosIfood.map((p, idx) => {
                let style = this.getStyleStatusEntrega(p)
                if (pedido.id === p.id) style = { ...style, backgroundColor: 'black', color: 'white' }

                const lbArray = []
                if (p.cliente && p.cliente.nome) {
                  lbArray.push(p.cliente.nome.split(' ')[0])
                  lbArray.push('-')
                }
                lbArray.push(p.numero)
                return (
                  <Chip
                    size="small"
                    style={style}
                    key={`${p.id}-${p.statusEntrega}`}
                    onClick={() => onSelectPedido(p)}
                    variant="outlined"
                    label={lbArray.join(' ')}
                  />
                )
              })}
            </Fieldset>
          </Grid>
        )}

        {pedidosEmoutech && pedidosEmoutech.length > 0 && (
          <Grid item xs={12}>
            <Fieldset label="Emoutech">
              <IconButton style={{ padding: 0 }} variant="outlined">
                <img style={{ width: 48, borderRadius: 24 }} src={require('./.././../../../assets/img/pdv/emoutech.png')} />
              </IconButton>

              {pedidosEmoutech.map((p, idx) => {
                let style = this.getStyleStatusEntrega(p)

                const lbArray = []
                if (p.cliente && p.cliente.nome) {
                  lbArray.push(p.cliente.nome.split(' ')[0])
                  lbArray.push('-')
                }
                lbArray.push(p.numero)

                if (pedido.id === p.id) style = { ...style, backgroundColor: 'black', color: 'white' }
                return (
                  <Chip
                    size="small"
                    style={style}
                    key={`${p.id}-${p.statusEntrega}`}
                    onClick={() => onSelectPedido(p)}
                    variant="outlined"
                    label={lbArray.join(' ')}
                  />
                )
              })}
            </Fieldset>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default BarraPedidos
