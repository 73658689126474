import React, { useState } from 'react'
import { Button, Dialog, DialogContent, Grid, Icon } from '@material-ui/core'
import moment from 'moment'
import Title from '@em/lib/src/util/Title'

import { axiosEMUtility } from '../../../myAxios'
import { layoutFenix, layoutDefault, layoutPedidaoFenix } from '../impressaoLayouts'
import { ComboBoxAPI } from '../../../_atoms/combobox'
import layoutMDogao from '../impressaoLayouts/layoutMDogao'
import layoutFoguete from '../impressaoLayouts/layoutFoguete'

const styleButton = {
  width: 100,
  height: 100
}

const styleDiv = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}

function ImprimirModal ({
  onHide,
  show,
  data,
  usuario
}) {
  const [impressora, setImpressora] = useState(null)

  const onImprimirCfe = () => {
    axiosEMUtility
      .post(`api/Printer/PrintCfe/${impressora?.caminho}/${impressora?.modelo?.id}?chave=${data.cFe}`)
      .then(response => {
        show({ message: 'Impressão Enviada!', title: 'Sucesso' }, 'success')
      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  const onImprimirControle = () => {
    let layout = layoutDefault(data)
    if (usuario?.sistema?.sistemaBancoId == 1963) {
      layout = layoutFenix(data)
    } else if (usuario?.sistema?.sistemaBancoId == 3079) {
      layout = layoutMDogao(data)
    } else if (usuario?.sistema?.sistemaBancoId == 3649) {
      layout = layoutFoguete(data)
    }

    onImprimir(layout)
  }

  const onImprimirPedidao = () => {
    var lines = []

    lines.push(
      {
        value: 'Obrigado',
        align: 'center'
      },
      {
        value: `${data?.numero}`,
        fontSize: 30,
        align: 'center'
      },
      {
        value: 'Data da impressão ' + moment().format('DD/MM/YYYY HH:mm'),
        align: 'center'
      }
    )
    let layout = lines
    if (usuario?.sistema?.sistemaBancoId == 1963) {
      layout = layoutPedidaoFenix(data)
    }
    if (usuario?.sistema?.sistemaBancoId == 1963) {
      layout = layoutPedidaoFenix(data)
    }

    onImprimir(layout)
  }

  const onImprimirPracas = () => {
    var lines = []

    lines.push(
      {
        value: `${data.numero}`,
        fontSize: 30,
        align: 'center'
      },
      {
        value: `${data.numero}`,
        fontSize: 30,
        align: 'center'
      }
    )

    onImprimir(lines)
  }

  const onImprimir = (linhas) => {
    axiosEMUtility
      .post(`api/Printer/PrintOut/${impressora?.caminho}/${impressora?.modelo?.id}`, linhas)
      .then(response => {
        show({ message: 'Impressão enviada!', title: 'Sucesso' }, 'success')
      })
      .catch(error => {
        show({ message: error.message, title: 'Sem resposta' }, 'error')
      })
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Title
        title={'Opções de Impressão'}
        buttons={[
          { icon: 'close', color: 'secondary', onClick: onHide }
        ]}
      />
      <DialogContent>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12}>
            <ComboBoxAPI
              api="/api/Impressora/Full"
              label="Impressora"
              onChange={e => setImpressora(e)}
              default={impressora}
              value={impressora?.id}
            />
          </Grid>
          {data?.cFe &&
            <Grid item>
              <Button
                variant="outlined"
                style={styleButton}
                disabled={!impressora?.id}
                onClick={onImprimirCfe}
              >
                <div style={styleDiv}>
                  <Icon style={{ fontSize: 40 }}>print</Icon>
                  <label style={{ fontSize: 9 }}>Imprimir CFe</label>
                </div>
              </Button>
            </Grid>
          }
          <Grid item>
            <Button
              variant="outlined"
              style={styleButton}
              disabled={!impressora?.id}
              onClick={onImprimirControle}
            >
              <div style={styleDiv}>
                <Icon style={{ fontSize: 40 }}>print</Icon>
                <label style={{ fontSize: 9 }}>Imprimir Controle</label>
              </div>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              style={styleButton}
              disabled={!impressora?.id}
              onClick={onImprimirPedidao}
            >
              <div style={styleDiv}>
                <Icon style={{ fontSize: 40 }}>print</Icon>
                <label style={{ fontSize: 9 }}>Imprimir Pedidão</label>
              </div>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              style={styleButton}
              disabled={!impressora?.id}
              onClick={onImprimirPracas}
            >
              <div style={styleDiv}>
                <Icon style={{ fontSize: 40 }}>print</Icon>
                <label style={{ fontSize: 9 }}>Imprimir Praças</label>
              </div>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ImprimirModal
