import React, { Component } from 'react'
import {
  Dialog, Grid, DialogContent, DialogActions, Button,
  FormControlLabel, Checkbox
} from '@material-ui/core'
import { TextField } from '../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { isNullOrUndefined } from '@em/lib/src/util/fnHelper'

class TabComposicaoTituloModal extends Component {
  state = {
    showError: false,
    montagem: { minimo: 0, maximo: 1000, opcoes: [], ...this.props.data }
  }

  handlerChange = e => {
    this.setState({ montagem: { ...this.state.montagem, [e.target.name]: e.target.value } })
  };

  formEstaValido = () => {
    const { titulo, minimo, maximo } = this.state.montagem
    const showError = !titulo || isNullOrUndefined(minimo) || isNullOrUndefined(maximo) || minimo < 0 || maximo < 0 || maximo < minimo
    this.setState({ showError })
    return !showError
  };

  onOk = () => {
    if (this.formEstaValido()) {
      this.props.handlerChange(this.state.montagem)
    }
  };

  render() {
    const { showError, montagem } = this.state
    const { onHide } = this.props

    return (
      <Dialog open fullWidth maxWidth="xs">
        <Title
          title={this.props.data ? `Editar Grupo` : `Novo Grupo`}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide
            }
          ]}
        />

        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                error={showError && !montagem.titulo}
                label="Nome do Grupo"
                name="titulo"
                required
                value={montagem.titulo}
                onChange={this.handlerChange}
              />
            </Grid>
            <Grid item xs={5} container style={{ minHeight: 58 }} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.handlerChange({ target: { name: "minimo", value: "1" } })
                      } else {
                        this.handlerChange({ target: { name: "minimo", value: "0" } })
                      }
                    }
                    }
                    checked={montagem.minimo > 0}
                  />
                }
                label="É Obrigatório"
              />
            </Grid>
            <Grid item xs={7}>
              {montagem.minimo > 0 &&
                <TextField
                  style={{ width: 100 }}
                  error={showError && isNullOrUndefined(montagem.minimo)}
                  label="Minimo"
                  name="minimo"
                  required
                  type="number"
                  value={montagem.minimo}
                  onChange={this.handlerChange}
                />
              }
            </Grid>
            <Grid item container xs={5} style={{ minHeight: 58 }} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.handlerChange({ target: { name: "maximo", value: "10" } })
                      } else {
                        this.handlerChange({ target: { name: "maximo", value: "1000" } })
                      }
                    }
                    }
                    checked={montagem.maximo < 1000}
                  />
                }
                label="Tem maximo?"
              />
            </Grid>
            <Grid item xs={7}>
              {montagem.maximo < 1000 &&
                <TextField
                  style={{ width: 100 }}
                  error={showError && isNullOrUndefined(montagem.maximo)}
                  label="Maximo"
                  name="maximo"
                  required
                  type="number"
                  value={montagem.maximo}
                  onChange={this.handlerChange}
                />
              }
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="contained" onClick={this.onOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog >
    )
  }
}

export default TabComposicaoTituloModal
