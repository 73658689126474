import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Button,
  Grid,
  Chip,
} from '@material-ui/core'
import { Cropper } from 'react-image-cropper'
import axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DialogWebCam from './dialogWebCam'
import DialogComputador from './dialogComputador'
import GaleriaDialog from './dialogGaleria'
import Title from '@em/lib/src/util/Title'
import TextField from '../TextField'

const styles = {}

class TabImage extends Component {
  state = {
    indexTab: 0,
    base64Original: '',
    base64: '',
    titulo: '',
    descricao: '',
    fileName: '',
    tag:"",
    tags: [],
    ...this.props.data
  };

  handleTab = (event, indexTab) => {
    this.setState({ indexTab })
  };

  onCapture64 = base64 => {
    this.setState({ base64, base64Original: base64 })
  };

  handlerChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  };

  onCaptureFile = file => {
    this.setState({
      base64: file.base64,
      base64Original: file.base64,
      fileName: file.fileList && file.fileList[0].name,
      titulo: file.fileList && file.fileList[0].name,
    })
  };

  onSave = () => {
    const { base64, indexTab, fileName, titulo, descricao, tags, tag } = this.state
    const newTags = [...tags]
    if (tag) {
      newTags.push(tag)
    }
    let fn = `${this.props.title || 'cropper'}.png`
    if (indexTab == 0) {
      fn = fileName
    }
    const data = {
      fileName: fn,
      titulo,
      descricao,
      base64,
      tags: newTags,
    }
    axios.post('api/arquivo/UploadFileToS3', data).then(response => {
      this.props.onCapture(response.dados.key)
    })
  }

  render() {
    const { classes, title, onHide } = this.props
    const { indexTab, base64, base64Original } = this.state
    return (
      <Dialog open onClose={onHide} maxWidth="lg" fullWidth>

        <Title
          title={title}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide,
            },
          ]}
        />

        <DialogContent style={{ textAlign: 'center', minHeight: 350 }}>
          {base64 ? (
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  name="titulo"
                  label="Titulo"
                  onChange={this.handlerChange}
                  value={this.state.titulo}
                />
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  multiple
                  id="tags"
                  options={[]}
                  defaultValue={this.state.tags || []}
                  freeSolo
                  onChange={(a, b) => this.handlerChange({ target: { name: 'tags', value: b } })}
                  renderTags={(value, getTagProps) => value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Tags"
                      name="tag"
                      onChange={this.handlerChange}
                      value={this.state.tag}
                      placeholder="Nova tag"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  name="descricao"
                  label="Descricao"
                  onChange={this.handlerChange}
                  value={this.state.descricao}
                />
              </Grid>

              <Grid item xs={12}>
                <Cropper
                  radio="width"
                  fixedRatio={false}
                  src={base64}
                  ref={ref => { this.cropper = ref }}
                />
              </Grid>
            </Grid>
          ) : (
            <div className={classes.root}>
              <Tabs value={indexTab} onChange={this.handleTab}>
                <Tab label="Computador" />
                <Tab label="WebCam" />
                {!this.props.noShowGaleria && <Tab label="Galeria" />}
              </Tabs>
              {indexTab === 0 && (
                <DialogComputador
                  src={this.props.src}
                  fileTypes={this.props.fileTypes}
                  onCapture={this.onCaptureFile}
                  onRemove={() => this.props.onCapture('')}
                />
              )}
              {indexTab === 1 && <DialogWebCam onCapture={this.onCapture64} />}
              {indexTab === 2 && <GaleriaDialog tag={this.props.tag} onCapture={this.props.onCapture} />}
            </div>
          )}
        </DialogContent>
        {base64 && (
          <DialogActions>
            {base64 !== base64Original && (
              <Button
                onClick={() => {
                  this.setState({ base64: this.state.base64Original })
                }}
                color="primary"
              >
                Desfazer corte
              </Button>
            )}
            <div style={{ flex: 1 }} />
            <Button
              onClick={() => this.setState({ base64: this.cropper.crop() })}
              color="primary"
            >
              Cortar
            </Button>

            <Button onClick={this.onSave} color="primary" variant="contained">
              Concluir
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}

TabImage.defaultProps = {
  title: 'Carregar imagem',
  fileTypes: ['image'],
}

TabImage.propTypes = {
  classes: PropTypes.object.isRequired,
  fileTypes: PropTypes.array,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onCapture: PropTypes.func.isRequired,
}

export default withStyles(styles)(TabImage)
