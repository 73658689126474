import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Button, Typography } from '@material-ui/core'
import axios from 'axios'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import { EmptyList } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'

const Caixas = ({ usuario, history, caixaVersion }) => {
  const [caixas, setCaixas] = useState([])
  const [loading, setLoading] = useState(false)

  const loadCaixas = filter => {
    axios.get('api/caixa').then(caixas => {
      setCaixas(caixas)
    }).catch(error => {
      setCaixas([])
    })
  }

  useEffect(() => loadCaixas(), [])

  const onOpenCaixa = async caixa => {
    try {
      const response = await axios.post('api/caixa/abrir', caixa)
      if (response.dados && response.dados.id) {
        history.push(`/pdv/caixa${caixaVersion || ""}/${response.dados.id}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <Title title="Carregando Caixas..." />
    )
  }

  return (
    <div>
      <Title title="Caixas" />

      <Grid container spacing={1}>
        {caixas.length === 0 &&
          <EmptyList text="Não existem caixas cadastrados" />
        }

        {caixas.map((caixa, idx) => {
          let backgroundColor = '#66bb6a'
          if (caixa.dataAbertura) {
            if (caixa.operador.id == usuario.pessoa.id) {
              backgroundColor = '#ffee58'
            } else {
              backgroundColor = '#ef5350'
            }
          }

          return (
            <Grid item key={caixa.id}>
              <Button
                onClick={() => onOpenCaixa(caixa)}
                style={{ backgroundColor, height: 150, width: 300 }}>
                <Grid item>
                  <Typography variant="h4" style={{ color: 'white' }}>
                    {`${caixa.nome} ${caixa.numero}`}
                  </Typography>

                  {(caixa.dataAbertura && (
                    <div>
                      {caixa.operador && caixa.operador.nome && (
                        <Typography variant="subtitle1" style={{ color: 'white' }}>
                          {caixa.operador.nome}
                        </Typography>
                      )}
                      <Typography variant="subtitle2" style={{ color: 'white' }}>
                        {fnHelper.toDateTimeBR(caixa.dataAbertura)}
                      </Typography>
                    </div>
                  )) || (
                      <Typography variant="h3" style={{ color: 'white' }}>
                        Livre
                      </Typography>
                    )}
                </Grid>
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Caixas))
