import React, { PureComponent } from 'react'
import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'

import DetalheItens from './detalheItens'
import DetalhePagamentos from './detalheIPagamentos'
import StatusCaixaList from '../../../contstants/statusCaixa'
import StatusEntregaList from '../../../contstants/statusEntrega'
import CanaisList from '../../../contstants/canais'
import Axios from 'axios'

class DetalheModal extends PureComponent {
  onRefreshPedido = pedido => {
    Axios.post('api/pedido', pedido).then(response => {
      this.props.refresh()
    })
  }

  render () {
    const { onHide, data } = this.props
    const sCaixa = StatusCaixaList.find(x => x.id == data.statusCaixa)
    const sEntrega = StatusEntregaList.find(x => x.id == data.statusEntrega)
    const canal = CanaisList.find(x => x.id == data.canal)
    return (
      <Dialog open fullWidth maxWidth={false} onClose={onHide}>
        <Title
          title={`Detalhes do pedido: ${canal.nome} ${data.numero && data.numero}`}
          buttons={[{ icon: 'close', color: 'secondary', onClick: this.props.onHide }]}
        />

        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField label="Status Caixa" value={sCaixa?.nome} />
            </Grid>
            <Grid item xs={2}>
              <TextField label="Status Entrega" value={sEntrega?.nome} />
            </Grid>
            <Grid item xs={2}>
              <TextField label="Valor Itens" value={`R$ ${(data?.valor || 0).toFixed(2)}`} />
            </Grid>
            <Grid item xs={2}>
              <TextField label="Valor Desconto" value={`R$ ${(data?.discountAmount || 0).toFixed(2)}`} />
            </Grid>
            <Grid item xs={2}>
              <TextField label="Valor Frete" value={`R$ ${(data?.valorFrete || 0).toFixed(2)}`} />
            </Grid>
            <Grid item xs={2}>
              <TextField label="Valor Total" value={`R$ ${(data?.valorTotal || 0).toFixed(2)}`} />
            </Grid>
            <Grid item xs={3}>
              <TextField label={`Criado em: ${fnHelper.toDateTimeBR(data?.dataCadastro)}`} value={data?.operador.nome} />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={`Faturado em: ${fnHelper.toDateTimeBR(data.faturamento && data.faturamento?.data)}`}
                value={data.faturamento && data.faturamento.operadorNome}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField label="Cliente" value={data.cliente && data.cliente.nome} />
            </Grid>
            <Grid item xs={3}>
              <TextField label="CPF" value={data.cliente && data.cliente.cpf} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Observação do pedido:" value={data?.observacao || 'Sem observação'} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Entregador:" value={`${data?.entregador?.nome} - tel: ${data?.entregador?.telefone}` || 'Sem Entregador'} />
            </Grid>
            <Grid item xs={8}>
              <DetalheItens itens={data?.itens} />
            </Grid>
            <Grid item xs={4}>
              <DetalhePagamentos pagamentos={data?.pagamentos} pedido={data} onRefreshPedido={this.onRefreshPedido} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

export default DetalheModal
