import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'

import { TextField } from '../../../_atoms'
import { ComboBoxAPI, ComboBoxData } from '../../../_atoms/combobox'
import { listCanal, listTypes } from './'

const inputs = {
  1: [
    { name: 'host', label: 'Host' }
  ],
  2: [
    { name: 'to', label: 'Destino' }
  ],
  3: [
    { name: 'host', label: 'Host' },
    { name: 'port', label: 'Porta' },
    { name: 'enableSsl', label: 'Ssl' },
    { name: 'useDefaultCredentials', label: 'Use Default Credentials' },
    { name: 'targetName', label: 'targetName' },
    { name: 'enableTLS', label: 'enableTLS' },
    { name: 'userName', label: 'Usuário' },
    { name: 'password', label: 'Senha' },
    { name: 'email', label: 'Email' },
    { name: 'alias', label: 'Apelido do Email' },
  ]
}

const AddOrEditModal = props => {
  const [form, setForm] = useState({
    canalId: 0,
    host: '',
    nome: '',
    type: '',
    ...props.data,
    data: fnHelper.tryJsonParse(props.data && props.data.data)
  })

  const [showError, setShowError] = useState(false)

  const onSave = () => {
    if (!!form.canalId || form.nome) {
      setShowError(true)
    }

    axios
      .post('api/conector', { ...form, data: JSON.stringify(form.data) })
      .then(resp => {
        props.onHide(true)
      })
  }

  const handlerChange = e => {
    if (e.target.name == 'canalId') {
      setForm({ ...form, [e.target.name]: e.target.value, data: '' })
    } else {
      setForm({ ...form, [e.target.name]: e.target.value })
    }
  }

  const handlerChangeData = e => {
    setForm({ ...form, data: { ...form.data, [e.target.name]: e.target.value } })
  }

  let ipts = []
  if (form.canalId > 0) {
    ipts = inputs[form.canalId]
  }

  return (
    <div>
      <Dialog maxWidth={false} fullWidth open onClose={props.onHide}>
        <Title
          title={form && form.id ? 'Edição de Conector' : 'Novo Conector'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Nome"
                error={showError && !form.nome}
                name="nome"
                value={form && form.nome}
                onChange={handlerChange}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxAPI
                loadStart
                label="Documento"
                api={'/api/DocumentoModelo/full'}
                onChange={e => setForm({ ...form, document: { id: e.id, nome: e.nome } })}
                value={form?.document?.id}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxData
                label="Canal"
                onChange={e => handlerChange({ target: { name: 'canalId', value: e.id } })}
                selected={form.canalId}
                nameText="name"
                list={listCanal}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxData
                label="Tipo"
                onChange={e => handlerChange({ target: { name: 'type', value: e.id } })}
                selected={form.type}
                nameText="name"
                list={listTypes}
              />
            </Grid>
            {ipts && ipts.map((i, idx) => (
              <Grid item xs={i.xs || 6}>
                <TextField
                  key={idx}
                  label={i.label}
                  name={i.name}
                  value={form && form.data && form.data[i.name]}
                  onChange={handlerChangeData}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default AddOrEditModal
