import React, { Component } from 'react'
import axios from 'axios'
import { IconButton, Icon } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ReactTable } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import { AddOrEditModal } from './components'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import tipoEnvioJSON from './components/tipoEnvioJSON'

class GatewayEnvio extends Component {
  state = {
    modalAddOrEdit: { open: false },
    lista: []
  };

  componentDidMount () {
    this.loadGateways()
  }

  loadGateways = () => {
    axios.get('api/conectorenvio?offset=0&limit=20').then(res => {
      this.setState({ lista: res.lista })
    })
  }

  handleDelete = nofificador => {
    const model = {
      title: 'Excluir',
      mensagem: `Você realmente deseja excluir ${nofificador.nome}`,
      buttoes: [
        {
          text: 'Confirmar',
          onClick: () => this.handleDeleteConfirmation(nofificador)
        }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  };

  handleDeleteConfirmation = site => {
    const { id } = site

    axios.delete(`api/conectorenvio/${id}`).then(resp => {
      this.loadGateways()
    })
  }

  render () {
    const { lista, modalAddOrEdit } = this.state
    return (
      <div>
        <Title
          title="Lista de Gateway de Envio"
          buttons={[
            {
              icon: 'add',
              onClick: () => this.setState({ modalAddOrEdit: { open: true, data: { } } })
            }
          ]}
        />

        <ReactTable
          data={lista}
          columns={[
            {
              Header: 'Nome',
              accessor: 'nome'
            },
            {
              Header: 'Gateway',
              accessor: 'tipo',
              Cell: row => tipoEnvioJSON.find(x => x.id === row.value) && tipoEnvioJSON.find(x => x.id === row.value).nome || ''
            },
            {
              maxWidth: 110,
              Cell: props => (
                <div>
                  <IconButton color="primary" onClick={() => this.setState({ modalAddOrEdit: { open: true, data: props.original } })}>
                    <Icon>edit_icon</Icon>
                  </IconButton>
                  <IconButton color="secondary" onClick={() => this.handleDelete(props.original)}>
                    <Icon>delete_icon</Icon>
                  </IconButton>
                </div>
              )
            }
          ]}
        />

        {modalAddOrEdit.open && (
          <AddOrEditModal
            data={modalAddOrEdit.data}
            onHide={refresh => {
              this.setState({ modalAddOrEdit: { open: false } })
              if (refresh) {
                this.loadGateways()
              }
            }}
          />
        )}

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  showMensagemConfirmacaoAction
},
dispatch)

export default connect(null, mapDispatchToProps)(GatewayEnvio)
