import React, { Component, useEffect, useState } from 'react'
import axios from 'axios'
import { HashRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { bindActionCreators } from 'redux'
import Offline from 'react-offline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import {
  Snackbar, CssBaseline, Divider, Grid, Chip, Dialog, DialogContent, Container, useMediaQuery
} from '@material-ui/core'
import { show } from 'react-notification-system-redux'
import Entrar from './_organisms/login/entrar'
import TrocarSenha from './_organisms/login/trocarSenha'
import MenuPagina from './_templates/menuPagina'
import MsgConfirmacao from './_atoms/msgConfirmacao'
import { setOnlineAction } from './actions/onlineAction'
import Notificacoes from './Notificacoes'
import { sairAction } from './actions/loginAction'
// import interceptor from './interceptor'
import LoadingModal from './_atoms/loading/LoadingModal'
import envConstants from './contstants/envConstants.json'
import InterceptorEMUtility from './interceptorEMUtility'
import LogRocket from 'logrocket'
import { getCookie } from '@em/lib/src/util/fnHelper'

const App = props => {
  const [loading, setLoading] = useState(false)
  const [ping, setPing] = useState({})

  if (process.env.NODE_ENV == 'production') {
    ReactGA.initialize(envConstants[window.location.host].ga)
    LogRocket.init('8dfbiq/portal-khqez')
  }

  // interceptor.intercept(() => props)

  const isMobile = useMediaQuery('(max-width:1024px)')

  const renderRoutes = () => {
    const { usuario } = props
    if (window.location.pathname === '/TrocarSenha') {
      return (
        <TrocarSenha Token={window.location.search.replace('?Token=', '')} />
      )
    }
    if (usuario.token && usuario.token == getCookie("token")) {
      if (process.env.NODE_ENV == 'production') {
        LogRocket.identify(usuario?.pessoa?.id, {
          name: usuario?.pessoa?.nome,
          sistema: usuario?.sistema?.nome
        })
      }
      return (
        <HashRouter>
          <Container maxWidth={isMobile ? false : 'xl'} style={{ padding: isMobile && 0 }}>
            <MenuPagina usuario={usuario} />
          </Container>
        </HashRouter>
      )
    }
    return (
      <>
        <Entrar />
      </>
    )
  }

  useEffect(() => {
    axios
      .get('/api/Ferramentas/Ping')
      .then(response => {
        setPing(response)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(true)
        }, 2000)
      })
  }, [])

  if (!loading) {
    return <LoadingModal />
  }

  const { online, usuario } = props
  const jsonPadrao = {
    palette: {
      primary: {
        main: '#6b1b79',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00'
      },
      success: {
        light: '#00ff66',
        main: '#66ff00',
        contrastText: '#009900'
      },
      error: {
        light: '#ff0266',
        main: '#B00020',
        contrastText: '#ffffff'
      }
    }
  }
  let jsonTema = {}
  try {
    jsonTema = JSON.parse(usuario.sistema.tema)
  } catch (error) { }

  const theme = createMuiTheme({
    ...jsonPadrao,
    ...jsonTema,
    typography: { useNextVariants: true }
  })

  return (
    <React.Suspense fallback={<LoadingModal />}>
      <InterceptorEMUtility />
      <div className="fundodosistema">
        <Grid container justify="space-between" direction="column" style={{ minHeight: '100vh' }}>
          <Grid item>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              {renderRoutes()}
              <Notificacoes />
              <MsgConfirmacao />

              <Offline
                onChange={({ isOffline }) => props.setOnlineAction(!isOffline)}
              />

              <Snackbar
                open={!online}
                SnackbarContentProps={{ 'aria-describedby': 'message-id' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message="Estamos trabalhando offline"
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item>
            <Footer ping={ping} server={usuario.server} />
          </Grid>
        </Grid>
      </div>
    </React.Suspense>
  )
}

const Footer = ({ ping, server }) => (
  <footer
    style={{
      marginTop: 20,
      padding: 15,
      fontSize: 12,
      backgroundColor: '#444444',
      color: 'white'
    }}
  >
    <center>
      <strong>
        Versão do sistema: {server}
        {'.B'}
        {ping.version}
        {'.F'}
        {process.env.REACT_APP_BUILD_ID}
      </strong>
    </center>
  </footer>
)

function mapStateToProps (state) {
  return {
    usuario: state.userReducer,
    online: state.onlineReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      setOnlineAction,
      sairAction,
      show
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
