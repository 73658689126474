import React from 'react'
import axios from 'axios'
import { Button, Grid } from '@material-ui/core'
import { TextField } from '../../_atoms'
import Title from '@em/lib/src/util/Title'

class Criptocrafia extends React.Component {
    state = {
      text: '',
      textCrypt: '',
      textde: '',
      textDecrypt: '',
    }

    handleChangeCrypt = event => {
      this.setState({
        text: event.target.value,
        textCrypt: '',
      })
    }

    handleChangeDecrypt = event => {
      this.setState({
        textde: event.target.value, textDecrypt: '',
      })
    }

    onCrypt = () => {
      axios.post(`api/generico/crypt/${this.state.text}`).then(textCrypt => {
        this.setState({ textCrypt })
      })
    }

    onDecrypt = () => {
      const config = {
        params: {
          texto: this.state.textde,
        },
      }
      axios.post(`api/generico/decrypt`, null, config).then(textDecrypt => {
        this.setState({ textDecrypt })
      })
    }

    render() {
      return (
        <div>
          <Title
            title="Criptografia"
          />

          <Grid container spacing={1}>
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <TextField
                name="text"
                label="Texto"
                value={this.state.text}
                onChange={this.handleChangeCrypt}
                fullWidth
              />
              <p>{this.state.textCrypt}</p>
              <Button onClick={this.onCrypt} color="primary" variant="contained">
                            Criptografar
              </Button>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <TextField
                name="textde"
                label="Texto"
                value={this.state.textde}
                onChange={this.handleChangeDecrypt}
                fullWidth
              />
              <p>{this.state.textDecrypt}</p>
              <Button onClick={this.onDecrypt} color="primary" variant="contained">
                            Descriptografar
              </Button>
            </Grid>
          </Grid>
        </div>

      )
    }
}

export default Criptocrafia
