import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  Grid, Checkbox, FormControlLabel
} from '@material-ui/core'
import { show } from 'react-notification-system-redux'

import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import { DatePicker } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import { AdvancedSearch } from '../../_atoms/search'
import { ComboBoxAPI, ComboBoxData } from '../../_atoms/combobox'

import StatusCaixaList from '../../contstants/statusCaixa'
import CanaisList from '../../contstants/canais'
import DetalheDialog from '../../_organisms/pdv/pedidos/detalheDialog'
import List from './pedidos/list'
import ImprimirModal from './pedidos/imprimirModal'

class Pedidos extends React.Component {
  tableRef = React.createRef()

  constructor (props) {
    super(props)
    this.state = {
      modalPedidoDetalhe: { open: false },
      imprimirModal: { open: false },
      pedidos: {},
      loading: true,
      filter: {},
      sistema: null,
      emUtility: null
    }
  }

  componentDidMount () {
    Promise.all([
      axios.get('api/sistema/me'),
      axios.get('/api/Connect/Print')
    ]).then(resp => {
      this.setState({
        sistema: resp[0],
        emUtility: resp[1]
      })
    })
  }

  openModal = (name, data) => {
    this.setState({ [name]: { open: true, data } })
  };

  loadPedidos = (data) => this.tableRef.current && this.tableRef.current.onQueryChange(data)

  handlerChange = e => {
    this.setState({ filter: { ...this.state.filter, [e.target.name]: e.target.value } })
  };

  onBuscar = numero => {
    this.setState({ filter: { ...this.state.filter, numero }, offset: 0 }, () => this.loadPedidos({ page: 0 }))
  };

  onCheckCanal = (canal, checked) => {
    const { filter } = this.state
    const canais = (filter.canais && [...filter.canais]) || []
    if (checked) {
      canais.push(canal.id)
    } else {
      _.remove(canais, x => x === canal.id)
    }

    this.setState({ filter: { ...filter, canais } })
  };

  render () {
    const {
      filter,
      modalPedidoDetalhe,
      sistema,
      emUtility,
      imprimirModal
    } = this.state
    return (
      <div>
        <Title title="Pedidos" />

        <AdvancedSearch onBuscar={this.onBuscar} labelPrincipal="Pedido">
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <ComboBoxAPI
                noneText="Todos"
                label="Cliente"
                onChange={e => this.handlerChange({ target: { name: 'clienteId', value: e.id } })}
                value={filter?.clienteId}
                api="/api/Pessoa/cliente/full"
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label="Data de Criação Inicial"
                value={filter.dataCadastroInicial}
                onChange={e => this.handlerChange({ target: { name: 'dataCadastroInicial', value: e && e.format('YYYY-MM-DD') } })}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                label="Data de Criação Final"
                value={filter.dataCadastroFinal}
                onChange={e => this.handlerChange({ target: { name: 'dataCadastroFinal', value: e && e.format('YYYY-MM-DD') } })}
              />
            </Grid>
            <Grid item xs={3}>
              <ComboBoxData
                noneText="Todos"
                label="Status Caixa"
                onChange={e => this.handlerChange({ target: { name: 'statusCaixa', value: e.id } })}
                selected={filter.statusCaixa}
                list={StatusCaixaList}
              />
            </Grid>
            <Grid item xs={3}>
              <ComboBoxAPI
                noneText="Todos"
                label="Entregador"
                onChange={e => this.handlerChange({ target: { name: 'entregadorId', value: e.id } })}
                value={filter?.entregadorId}
                api="api/entregador/full"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container style={{ width: '100%' }} spacing={1} justify="flex-start">
                {_.map(CanaisList, (canal, index) => {
                  const checked = _.includes(filter.canais, canal.id)
                  return (
                    <Grid item key={index}>
                      <FormControlLabel
                        control={<Checkbox checked={checked} onChange={e => this.onCheckCanal(canal, e.target.checked)} />}
                        label={canal.nome}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </AdvancedSearch>
        <div>
          <List
            tableRef={this.tableRef}
            filter={this.state.filter}
            openModal={this.openModal}
            sistema={sistema}
            emUtility={emUtility}
            refresh={this.loadPedidos}
          />
        </div>

        {modalPedidoDetalhe.open && (
          <DetalheDialog
            onHide={() => this.setState({ modalPedidoDetalhe: { open: false } })}
            data={modalPedidoDetalhe.data}
            refresh={this.loadPedidos}
          />
        )}

        {imprimirModal.open && (
          <ImprimirModal
            onHide={() => this.setState({ imprimirModal: { open: false } })}
            data={imprimirModal.data}
            show={this.props.show}
            usuario={this.props.usuario}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction,
      show
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pedidos)
