import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Grow, Icon, Fab, IconButton } from '@material-ui/core'

class Title extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  renderMenu(btn) {
    const { anchorEl } = this.state
    return (
      <Grow in timeout={1000}>
        <>
          <Fab
            disabled={btn.disabled}
            variant="extended"
            style={{ backgroundColor: "#FFFFFF" }}
            onClick={event => this.setState({ anchorEl: event.currentTarget })}
          >
            <Icon>{btn.icon}</Icon>
          </Fab>
          <Menu
            id="simple-menu"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={event => this.setState({ anchorEl: null })}
          >
            {btn.buttons.map((btn2, idx) => (
              <MenuItem
                key={idx}
                onClick={() => {
                  this.setState({ anchorEl: null })
                  btn2.onClick && btn2.onClick()
                }}
              >
                {(btn2.icon && (
                  <>
                    <ListItemIcon>
                      <Icon>{btn2.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={btn2.label} />
                  </>
                ))
                  || btn2.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      </Grow>
    )
  }

  renderBotton(btn) {
    return (
      <Grow in timeout={1000}>
        <Fab
          disabled={btn.disabled}
          variant="extended"
          style={{ backgroundColor: "#FFFFFF" }}
          onClick={() => btn.onClick && btn.onClick()}>
          <Icon>{btn.icon}</Icon>
        </Fab>
      </Grow>
    )
  }

  render() {
    const { title, buttons, subTitle, component, icon } = this.props

    return (
      <Toolbar style={{ borderBottom: `3px solid #999`, marginBottom: 10, alignItems: "center" }} >
        <div style={{ flex: 1 }}>
          {title && (
            <Typography variant="h5" color="primary" style={{ display: "flex", alignItems: "center", fontWeight: 700 }}>
              {icon} <span style={{ marginLeft: 8 }}>{title}</span>
            </Typography>
          )}
          {subTitle && (
            <Typography variant="subtitle1" color="textSecondary">
              <span style={{ marginLeft: 8 }}>{subTitle}</span>
            </Typography>
          )}
        </div>

        {component && <>{component}</>}

        {buttons && _.map(buttons, (btn, idx) => (
          <>
            {(btn.type === 'menu' &&
              this.renderMenu(btn)) ||
              this.renderBotton(btn)}
          </>
        ))}
      </Toolbar>
    )
  }
}

Title.propTypes = {
  title: PropTypes.any,
  buttons: PropTypes.array,
  component: PropTypes.any,
}

export default Title
