import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  Icon,
  Typography
} from '@material-ui/core'
import axios from 'axios'

import Title from '@em/lib/src/util/Title'

import ContentCupons from './contentCupons'
import ContentConfig from './contentConfig'
import ContentPagamento from './contentPagamento'
import PayCheckModal from './payCheckModal'
import envConstants from './../../../../contstants/envConstants.json'

function BuyAndAddCuponsModal ({
  onHide
}) {
  const [cupom, setCupom] = useState({
    titulo: '',
    descricao: '',
    qtde: 0,
    qtdeMaxUsuario: 1,
    percentualValor: 0,
    desconto: 0,
    prefixo: '',
    dataExpiracao: '',
    pagamento: {
      nome: '',
      financeira: 0,
      status: 0,
      valor: 0
    },
    regras: ''
  })
  const [open, setOpen] = useState({ accordion: 'cupom' })
  const [conectoresFinanceira, setConectoresFinanceira] = useState([])
  const [payCheckModal, setPayCheckModal] = useState({ open: false })

  const verifyForm = () => {
    let retorno = false
    const requireds = [
      cupom.qtde,
      cupom.titulo,
      cupom.descricao,
      cupom.pagamento.valor,
      cupom.dataExpiracao,
      cupom.desconto,
      cupom.prefixo,
      cupom.qtdeMaxUsuario,
      cupom.regras
    ]
    requireds.forEach(r => {
      if (!r) {
        retorno = true
      }
    })
    return retorno
  }

  const loadConectoresFinanceira = () => {
    const tokenEmoutech = process.env.REACT_APP_CONECTOR_FINANCEIRA
    axios.create({
      baseURL: envConstants[window.location.host].api
    }).get('/api/ConectorFinanceira/full', {
      headers: {
        Authorization: tokenEmoutech
      }
    })
      .then(resp => {
        setConectoresFinanceira(resp?.data)
      })
  }

  const buy = confirmed => {
    if (!confirmed) {
      setPayCheckModal({ open: true })
      return
    }
    axios.post('/api/coupon', cupom).then(() => {
      onHide(true)
    })
  }

  useEffect(() => {
    loadConectoresFinanceira()
  }, [])

  const blockConfig = !cupom?.qtde
  const blockPagamento = verifyForm()
  return (
    <Dialog open maxWidth="md" fullWidth>
      <Title
        title={'Adicionar Cupons'}
        buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
      />
      <DialogContent>
        <Accordion
          expanded={open?.accordion === 'cupom'}
          onChange={() => setOpen({ accordion: 'cupom' })}
        >
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
          >

            {!blockConfig && <Icon color="success">check_circle_outline</Icon>}
            {blockConfig && <Icon color="primary">edit</Icon>}

            <Typography
              style={{ marginLeft: 10 }}
              color="primary"
            >
              Escolha seu pacote
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContentCupons
              onClick={pct => {
                setCupom({
                  ...cupom,
                  qtde: pct.quantidade,
                  pagamento: {
                    ...cupom.pagamento,
                    valor: pct?.valor
                  }
                })
                setOpen({ accordion: 'config' })
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={open?.accordion === 'config'}
          onChange={() => !blockConfig && setOpen({ accordion: 'config' })}
        >
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
          >
            {blockConfig && <Icon color="error">block</Icon>}
            {!blockConfig && blockPagamento && <Icon color="primary">edit</Icon>}
            {!blockPagamento && <Icon color="success">check_circle_outline</Icon>}
            <Typography
              style={{ marginLeft: 10 }}
              color="primary"
            >
              Configure seu cupom
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContentConfig
              data={cupom}
              onClick={config => {
                setCupom({
                  ...cupom,
                  ...config
                })
                setOpen({ accordion: 'pagamento' })
              }}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={open?.accordion === 'pagamento'}
          onChange={() => !blockPagamento && setOpen({ accordion: 'pagamento' })}
        >
          <AccordionSummary
            expandIcon={<Icon>expand_more</Icon>}
          >
            {blockPagamento && <Icon color="error">block</Icon>}
            {!blockPagamento && !cupom?.pagamento?.nome && <Icon color="primary">edit</Icon>}
            {!blockPagamento && cupom?.pagamento?.nome && <Icon color="success">check_circle_outline</Icon>}
            <Typography
              style={{ marginLeft: 10 }}
              color="primary"
            >
              Escolha como quer pagar
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContentPagamento
              conectoresFinanceira={conectoresFinanceira}
              onClick={payment => {
                setCupom({
                  ...cupom,
                  pagamento: {
                    ...cupom.pagamento,
                    nome: payment?.nome,
                    financeira: payment?.financeira
                  }
                })
                setPayCheckModal({ open: true })
              }}
            />
          </AccordionDetails>
        </Accordion>
        {payCheckModal.open &&
          <PayCheckModal
            onHide={() => setPayCheckModal({ open: false })}
            cupom={cupom}
            buy={buy}
          />
        }
      </DialogContent>
    </Dialog>
  )
}

export default BuyAndAddCuponsModal
