import React from 'react'
import { EMTable } from '@em/lib/src'
import {
  IconButtonEdit,
  IconButtonDelete,
  IconButtonAdd
} from '../../../_atoms/button'
import CustomTree from '../../../_atoms/customTree'


export default function List({
  segmentos,
  openModal,
  deleteSegmento,
  funcoes,
  openId
}) {
  return (
    <CustomTree
      lista={segmentos}
      keyParent={'paiId'}
      openId={openId}
      columns={[
        {
          field: 'nome',
          title: "Nome"
        },
        {
          field: 'destaque',
          title: "Destaque",
        },
      ]}
      actions={[{
        width: 180,
        render: row => {
          return (
            <>
              <IconButtonAdd onClick={() => openModal({ paiId: row.id, paiNome: row.nome })} funcoes={funcoes} />
              <IconButtonEdit onClick={() => openModal(row)} funcoes={funcoes} />
              <IconButtonDelete onClick={() => deleteSegmento(row)} funcoes={funcoes} />
            </>
          )
        }
      }]}
    />
  )
}
