import React, { Component } from 'react'
import * as NumberFormat from 'react-number-format'
import TextField from '../TextField'

class TextFielddEAN extends Component {
  render () {
    return (
      <NumberFormat
        customInput={TextField}
        format="#############"
        {...this.props}
        onValueChange={e => this.props.onValueChange({ target: { name: this.props.name, value: e.floatValue?.toString() } })}
      />
    )
  }
}

export default TextFielddEAN
