import React from 'react'
import { IconButtonEdit, IconButtonDelete, IconButtonAdd } from '../../../../_atoms/button'
import CustomTree from '../../../../_atoms/customTree'

export default function List ({
  enderecos,
  openModal,
  deleteEndereco,
  funcoes,
  openId
}) {
  return (
    <CustomTree
      lista={enderecos}
      keyParent={'parentId'}
      openId={openId}
      columns={[
        {
          title: 'Endereço',
          field: 'name'
        }
      ]}
      actions={[
        {
          width: 180,
          render: row => (
            <>
              <IconButtonAdd onClick={() => openModal({ parentId: row.id, parentName: row.name })} funcoes={funcoes} />
              <IconButtonEdit onClick={() => openModal(row)} funcoes={funcoes} />
              <IconButtonDelete
                onClick={() => deleteEndereco(row)}
                funcoes={funcoes}
                disabled={!!enderecos.find(x => x.parentId === row.id)}
                perguntar
                mensagem={'Você realmente deletar este endereço?'}
              />
            </>
          )
        }
      ]}
    />
  )
}
