import React, { Component } from 'react'
import { IconButton, Icon } from '@material-ui/core'
import ReactTable from '../../../_atoms/ReactTable'

class TabelaPerfil extends Component {
  render() {
    const { perfis, onEdit } = this.props

    return (
      <ReactTable
        data={perfis}
        columns={[
          {
            accessor: 'nome',
          },
          {
            width: 100,
            Cell: props => (
              <IconButton onClick={() => onEdit(props)}>
                <Icon>edit</Icon>
              </IconButton>
            ),
          },
        ]}
      />
    )
  }
}

export default TabelaPerfil
