import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid
} from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import * as fnHelper from '@em/lib/src/util/fnHelper'
import { ComboBoxData } from '../../../_atoms/combobox'
import financeirasJSON from './financeirasJSON'
import InputGenerator from '../../../_atoms/InputGenerator'
import envConstants from '../../../contstants/envConstants.json'

const inputs = {
  1: [
    { type: 'text', name: 'token', label: 'Token' },
    { type: 'text', name: 'email', label: 'Email' },
    { type: 'bool', name: 'shippingAddressRequired', label: 'Obrigar o cliente a colocar o endereço' },
    { type: 'bool', name: 'acceptCreditCard', label: 'Aceita Cartão de Crédito' },
    { type: 'bool', name: 'acceptBoleto', label: 'Aceita Boleto' }
  ]
}

const AddOrEditModal = props => {
  const [form, setForm] = useState({
    financeira: 0,
    nome: '',
    ...props.data,
    data: fnHelper.tryJsonParse(props.data && props.data.data)
  })

  const [showError, setShowError] = useState(false)

  const onSave = () => {
    if (!!form.financeira || form.nome) {
      setShowError(true)
    }

    axios
      .post('api/conectorfinanceira', { ...form, data: JSON.stringify(form.data) })
      .then(resp => {
        props.onHide(true)
      })
  }

  const handlerChange = e => {
    if (e.target.name == 'financeira') {
      setForm({ ...form, [e.target.name]: e.target.value, data: '' })
    } else {
      setForm({ ...form, [e.target.name]: e.target.value })
    }
  }

  const handlerChangeData = e => {
    setForm({ ...form, data: { ...form.data, [e.target.name]: e.target.value } })
  }

  const handlerChangeDataImage = (name, value) => {
  }

  const handlerChangeDataOBJ = name => e => {
  }

  let ipts = []
  if (form.financeira > 0) {
    ipts = inputs[form.financeira]
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open onClose={props.onHide}>
        <Title
          title={form && form.id ? 'Edição de Gateway' : 'Novo Gateway'}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: props.onHide }
          ]}
        />
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Nome"
                error={showError && !form.nome}
                name="nome"
                value={form && form.nome}
                onChange={handlerChange}
              />
            </Grid>
            <Grid item xs={6}>
              <ComboBoxData
                label="Nome da Gateway"
                onChange={e => handlerChange({ target: { name: 'financeira', value: e.id } })}
                selected={form.financeira}
                list={financeirasJSON}
              />
            </Grid>

            {ipts && ipts.map((f, idx) => (
              <Grid item xs={f.xs || 6} key={idx}>
                <InputGenerator
                  f={f}
                  handlerChangeDataImage={handlerChangeDataImage}
                  handlerChangeData={handlerChangeData}
                  handlerChangeDataOBJ={handlerChangeDataOBJ}
                  data={form && form.data}
                />
              </Grid>
            ))}
            {(props.usuario.sistema.sistemaBancoId && form.id) &&
              <Grid item xs={12}>
                <p>
                  Endereço para integração com pagseguro
                </p>
                <b>
                  {`${envConstants[window.location.host].api}/api/PagSeguro/RetornoPagSeguro/${props.usuario.sistema.sistemaBancoId}/${form.id}/true`}
                </b>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

export default connect(mapStateToProps)(AddOrEditModal)
