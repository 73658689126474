import React, { Component } from 'react'
import {
  TextField, Button, Dialog, DialogActions, DialogContent,
} from '@material-ui/core'

import Title from '@em/lib/src/util/Title'

class CriaPerfilDialog extends Component {
  state = {
    Nome: '',
  };

  handlerChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  };

  render() {
    const { Nome } = this.state
    return (
      <Dialog open>
        <Title title="Cadastro de Perfil" buttons={[{ icon: 'close', color: 'secondary', onClick: this.props.onHide }]} />
        <DialogContent style={{ paddingTop: 10 }}>
          <TextField fullWidth label="Nome do perfil" value={Nome} name="Nome" onChange={this.handlerChange} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => this.props.onSave({ Nome })}>
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default CriaPerfilDialog
