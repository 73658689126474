import React, { useState } from 'react'
import {
  TextField, InputAdornment, IconButton, Popover, Icon
} from '@material-ui/core'
import { isNullOrUndefined } from 'util'

const TextFieldIcon = props => {
  return (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ padding: 20, backgroundColor: isNullOrUndefined(props.value) ? 'white' : props.value }}
              >
                <Icon>{props.value}</Icon>
              </IconButton>
            </InputAdornment>
          )
        }}
        variant="filled"
        fullWidth
        {...props}
        value={isNullOrUndefined(props.value) ? '' : props.value}
      />

    </>
  )
}

export default TextFieldIcon
