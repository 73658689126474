import React, { Component } from 'react'
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent, DialogActions, Button, Grid,
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'
import { NumberFormatMoneyBR } from '../../../_atoms/numberFormat'
import { ComboBoxAPI } from '../../../_atoms/combobox'

class PendenteAprovacaoDialog extends Component {
  state = {
    financeiro: this.props.data,
  };

  handlerChange = e => {
    this.setState({
      financeiro: { ...this.state.financeiro, [e.target.name]: e.target.value },
    })
  };

  onConfirm = () => {
    axios.post('api/financeiro/aprovar', this.state.financeiro).then(response => {
      this.props.onConfirm(response)
    })
  };

  render() {
    const { onHide } = this.props
    const { financeiro } = this.state
    const tipoNome = financeiro.tipo === 1 ? 'Receita' : 'Despesa'
    return (
      <Dialog open maxWidth="sm">
        <Title
          title={`${tipoNome} Pendente de Aprovação`}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide,
            },
          ]}
        />
        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <span>
                {`Você confirma a ${tipoNome} de `}
                <NumberFormatMoneyBR style={{ color: 'green' }} value={financeiro.valorLiquido} />
?
              </span>
            </Grid>

            <Grid item xs={12}>
              <strong>Conferente(s)</strong>

              {financeiro.conferentes && (
                <>
                  {financeiro.conferentes.map((conferente, idx) => (
                    <span style={{ marginLeft: 10 }} key={idx}>
                      {conferente.nome}
                    </span>
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <ComboBoxAPI
                label="Seleciona a Conta Destino"
                onChange={e => this.handlerChange({ target: { name: 'conta', value: e } })}
                default={financeiro.conta}
                value={financeiro.conta && financeiro.conta.id}
                api="api/conta"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onConfirm} color="primary" variant="contained" disabled={!financeiro.conta}>
            Aprovar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default PendenteAprovacaoDialog
