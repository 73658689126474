import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import AddOrEditModal from './components/Categorias/AddOrEditModal'
import CustomTree from '../../_atoms/customTree'
import { IconButtonAdd, IconButtonDelete, IconButtonEdit } from '../../_atoms/button'
import * as fnHelper from '@em/lib/src/util/fnHelper'

export default ({ funcoes }) => {
  const [categorias, setCategorias] = useState([])
  const [addOrEditModal, setAddOrEditModal] = useState({ open: false, data: null })
  const [openId, setOpenId] = useState(null)

  async function loadCategorias () {
    axios.get('api/itemcategory/full?paiId=all').then(response => {
      setCategorias(response)
    })
  }

  async function addOrEditCategoria (categoria) {
    axios.post('api/itemcategory', categoria).then(response => {
      setOpenId(response.dados.id)
      loadCategorias()
      setAddOrEditModal({ open: false })
    })
  }

  async function deleteCategoria (categoria) {
    axios.delete(`api/itemcategory/${categoria.id}`).then(response => {
      loadCategorias()
      setOpenId(response.parentId)
    })
  }

  useEffect(() => {
    loadCategorias()
  }, [])

  return (
    <div>
      <Title
        title="Lista de categorias"
        buttons={[
          {
            icon: 'add',
            onClick: () => setAddOrEditModal({ open: true })
          }
        ]}
      />
      <CustomTree
        lista={fnHelper.orderBy(categorias)}
        openId={openId}
        keyParent="parentId"
        columns={[
          {
            title: 'Categoria',
            field: 'name'
          }
        ]}
        actions={[
          {
            width: 180,
            render: row => (
              <>
                <IconButtonAdd onClick={() => setAddOrEditModal({ open: true, data: { parentId: row.id, parentName: row.name } })} funcoes={funcoes} />
                <IconButtonEdit onClick={() => setAddOrEditModal({ open: true, data: row })} funcoes={funcoes} />
                <IconButtonDelete
                  onClick={() => deleteCategoria(row)}
                  funcoes={funcoes}
                  perguntar
                  mensagem={'Você realmente deletar esta categoria?'}
                  disabled={!!categorias.find(x => x.parentId === row.id)}
                />
              </>
            )
          }
        ]}
      />
      {addOrEditModal.open &&
        <AddOrEditModal
          onHide={() => setAddOrEditModal({ open: false })}
          data={addOrEditModal?.data ?? {}}
          addOrEditCategoria={addOrEditCategoria}
        />
      }
    </div>
  )
}
