import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Grid, Paper, Button, Collapse
} from '@material-ui/core'

import { TextField } from '..'
import { showMensagemConfirmacaoAction } from './../../actions/mensagemAction'

const AdvancedSearch = ({ labelPrincipal, children,
  onBuscar, searchSimple, onDownload, showMensagemConfirmacaoAction,
  hasSearch }) => {
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')

  const handleDownload = (text) => {
    const model = {
      title: 'Confirmação de Download',
      mensagem: (
        <span>
          Você realmente deseja fazer o download?
        </span>
      ),
      buttoes: [
        { text: 'Confirmar', onClick: () => onDownload(text) }
      ]
    }
    showMensagemConfirmacaoAction(model)
  }

  return (
    <Paper style={{ marginBottom: 10, padding: 10 }}>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1} >
            {labelPrincipal &&
              <Grid item>
                <TextField
                  label={labelPrincipal}
                  onChange={e => setSearchText(e.target.value)}
                  value={searchText}
                  onKeyPress={e => {
                    if (e.key === 'Enter') { onBuscar(searchText) }
                  }}
                />
              </Grid>
            }

            {searchSimple}

            <Grid item>
              <Button variant={hasSearch ? "contained" : "outlined"} color="primary" onClick={() => onBuscar(searchText)}>
                Buscar
              </Button>
            </Grid>
          </Grid>

        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            {onDownload && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={() => handleDownload(searchText)}>
                  Download
                </Button>
              </Grid>
            )}
            {children && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={() => setOpen(!open)}>
                  Busca avançada
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {children && (
        <Collapse style={{ marginTop: 10 }} in={open}>
          {children}
        </Collapse>
      )}
    </Paper>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    showMensagemConfirmacaoAction
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(AdvancedSearch)
