import React from 'react'
import {
  Grid, Dialog, DialogContent, DialogActions, Button, IconButton, Icon
} from '@material-ui/core'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { TextField } from './../../../../_atoms'
import Title from '@em/lib/src/util/Title'
import ClienteSearchDialog from './clienteSearchDialog'
import { TextFieldTelefone } from './../../../../_atoms/numberFormat'
import TextFieldCPFCNPJ from './../../../../_atoms/numberFormat/TextFieldCPFCNPJ'
import Axios from 'axios'
import TextFieldCEP from './../../../../_atoms/numberFormat/TextFieldCEP'
import { ComboBoxAPI } from '../../../../_atoms/combobox'
import { changeValorAuxItem } from '@em/lib/src/Produtos/somasCaixa2'

class ClienteDialog extends React.PureComponent {
  state = {
    cliente: {
      nome: '', cpf: '', telefone: '', email: '', ...this.props.pedido.cliente
    },
    endereco: {
      rua: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      pais: '',
      referencia: '',
      ...this.props.pedido.endereco
    },
    searchClientesModal: { open: false },
    showError: false
  };

  onOk = () => {
    const { onRefreshPedido, pedido, onHide } = this.props
    const { cliente, endereco } = this.state

    if (endereco?.cep ||
      endereco?.rua ||
      endereco?.numero ||
      endereco?.bairro ||
      endereco?.cidade
    ) {
      const required = [
        endereco?.cep,
        endereco?.rua,
        endereco?.numero,
        endereco?.bairro,
        endereco?.cidade
      ]
      let error = false

      required.forEach(r => {
        if (!r) {
          error = true
        }
      })

      if (error) {
        this.setState({ showError: true })
        return
      }
    }

    pedido.itens = changeValorAuxItem(pedido.itens, cliente?.entidade?.discount)
    onRefreshPedido({ ...pedido, cliente, endereco })
    onHide()
  };

  handlerChangeCliente = e => {
    this.setState({ cliente: { ...this.state.cliente, [e.target.name]: e.target.value } })
  };

  handlerChangeendereco = e => {
    const name = e.target.name
    this.setState({ endereco: { ...this.state.endereco, [name]: e.target.value } })
  };

  handlerChangeenderecoCEP = e => {
    const name = e.target.name
    this.setState({
      endereco: {
        ...this.state.endereco,
        cep: e.target.value,
        rua: '',
        bairro: '',
        cidade: '',
        complemento: ''
      }
    }, () => {
      if (this.state.endereco.cep.length == 9) {
        this.onSearchCEP()
      }
    })
  };

  onSearchCEP = () => {
    Axios.get('/api/Localidade/' + this.state.endereco.cep).then(response => {
      this.setState({ endereco: { ...this.state.endereco, ...response, estado: response?.uf } })
    })
  }

  onSelectCliente = (cli, end, tel) => {
    let endereco = {}
    const { nome, id, cpfCnpj, entidade } = cli
    const cliente = { nome, id, cpf: cpfCnpj, entidade }
    if (end) {
      endereco = {
        rua: end.endereco,
        numero: end.numero,
        bairro: end.bairro,
        cidade: end.cidade,
        cep: end.cep,
        cpf: end.cpf,
        referencia: end.referencia,
        complemento: end.complemento,
        estado: end.estado
      }
    }
    if (tel) {
      cliente.telefone = tel.numero
    }
    this.setState({ endereco, cliente, searchClientesModal: { open: false } })
  };

  render() {
    const {
      onHide, onRefreshPedido, pedido, add
    } = this.props
    const { cliente, endereco, searchClientesModal, showError } = this.state
    const {
      nome, cpf, telefone, id, email, entidade
    } = cliente
    const {
      rua, numero, bairro, cidade, cep, referencia, complemento
    } = endereco

    const buttons = []
    buttons.push({ icon: 'close', color: 'secondary', onClick: onHide })

    return (
      <>
        <Dialog open maxWidth="md" fullWidth>
          <Title
            component={!add && (
              <Button
                onClick={() => this.setState({ searchClientesModal: { open: true } })}
                color="primary"
                variant="outlined"
                startIcon={<Icon>search</Icon>}>
                Buscar cliente
              </Button>)
            }
            title={id ? `Editar Cliente: ${nome}` : 'Novo Cliente'} buttons={buttons} />

          <DialogContent style={{ paddingTop: 10 }}>
            <Grid container spacing={1} style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <TextField label="Nome do Cliente" name="nome" value={nome} onChange={this.handlerChangeCliente} />
              </Grid>
              <Grid item xs={3}>
                <TextFieldCPFCNPJ label="CPF do Cliente" name="cpf" value={cpf} onChange={this.handlerChangeCliente} />
              </Grid>
              <Grid item xs={3}>
                <TextFieldTelefone value={telefone} name="telefone" label="Telefone" onChange={this.handlerChangeCliente} />
              </Grid>
              <Grid item xs={8}>
                <TextField label="Email" name="email" value={email} onChange={this.handlerChangeCliente} />
              </Grid>
              <Grid item xs={4}>
                <ComboBoxAPI
                  label="Grupo"
                  onChange={e => this.handlerChangeCliente({ target: { name: 'entidade', value: e } })}
                  default={entidade}
                  value={entidade?.id}
                  api="api/entidade"
                />
              </Grid>
              <Grid item xs={12}>
                <h3>Endereço para entrega do pedido</h3>
              </Grid>
              <Grid item xs={3}>
                <TextFieldCEP
                  label="CEP"
                  name="cep"
                  value={cep || ''}
                  onChange={this.handlerChangeenderecoCEP}
                  error={showError && !cep}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField label="Rua" name="rua" value={rua} onChange={this.handlerChangeendereco} error={showError && !rua} />
              </Grid>
              <Grid item xs={2}>
                <TextField label="Numero" name="numero" value={numero} onChange={this.handlerChangeendereco} error={showError && !numero} />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Complemento" name="complemento" value={complemento} onChange={this.handlerChangeendereco} />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Bairro" name="bairro" value={bairro} onChange={this.handlerChangeendereco} error={showError && !bairro} />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Cidade" name="cidade" value={cidade} onChange={this.handlerChangeendereco} error={showError && !cidade} />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Ponto de Referencia" name="referencia" value={referencia} onChange={this.handlerChangeendereco} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onHide} color="secondary">
              Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={this.onOk}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {searchClientesModal.open && (
          <ClienteSearchDialog
            pedido={pedido}
            onRefreshPedido={onRefreshPedido}
            onSelect={this.onSelectCliente}
            onHide={() => this.setState({ searchClientesModal: { open: false } })}
          />
        )}
      </>
    )
  }
}

export default withRouter(ClienteDialog)
