import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { Icon, IconButton } from '@material-ui/core'
import ReactTable from '../../_atoms/ReactTable'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import Title from '@em/lib/src/util/Title'
import CriarEditarDialog from '../../_organisms/pessoa/entidade/criarEditarDialog'

class Entidades extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalCriarEditarEntidade: { open: false },
      entidades: [],
      loading: true
    }
  }

  componentDidMount () {
    this.loadEntidades()
  }

  openModalCriarEditarEntidade = data => {
    this.setState({ modalCriarEditarEntidade: { open: true, data } })
  }

  delete = deleted => {
    const model = {
      title: 'Excluir',
      mensagem: (
        <span>
          Você realmente deseja excluir
          {' '}
          <strong>
            {' '}
            {deleted.nome}
          </strong>
?
        </span>
      ),
      buttoes: [
        { text: 'EXCLUIR', onClick: () => this.deleteConfirmed(deleted) }
      ]
    }
    this.props.showMensagemConfirmacaoAction(model)
  }

  deleteConfirmed = deleted => {
    axios.delete(`api/entidade/${deleted.id}`).then(() => {
      this.loadEntidades()
    })
  }

  loadEntidades = () => {
    this.setState({ loading: true })
    axios.get('api/entidade')
      .then(result => {
        this.setState({
          entidades: result || [],
          loading: false,
          modalCriarEditarEntidade: { open: false }
        })
      })
  }

  podeEditar = () => {
    const { funcoes } = this.props
    return _.some(funcoes, f => f === 'editar')
  };

  podeDeletar = () => {
    const { funcoes } = this.props
    return _.some(funcoes, f => f === 'deletar')
  };

  render () {
    const { entidades, loading, modalCriarEditarEntidade } = this.state

    return (
      <div>
        <Title
          title="Grupo de Pessoas"
          buttons={[
            {
              icon: 'add',
              color: 'primary',
              onClick: this.openModalCriarEditarEntidade
            }
          ]}
        />
        <ReactTable
          loading={loading}
          data={entidades}
          header={false}
          columns={[
            {
              className: 'padding20',
              Cell: props => (
                <div>
                  <span>{props.original.nome}</span>
                  {props.original.EmpresaNome && (
                    <label style={{ color: '#555', fontSize: 10 }}>
                      <br />
                      {props.original.EmpresaNome}
                    </label>
                  )}
                </div>
              )
            },
            {
              width: 110,
              Cell: props => (
                <div>
                  <IconButton
                    disabled={!this.podeEditar()}
                    onClick={() => this.openModalCriarEditarEntidade(props.original)}
                    color="primary"
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                  <IconButton
                    disabled={!this.podeDeletar()}
                    onClick={() => this.delete(props.original)}
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              )
            }
          ]}
        />

        {modalCriarEditarEntidade.open && (
          <CriarEditarDialog
            onReload={this.loadEntidades}
            onHide={() => this.setState({ modalCriarEditarEntidade: { open: false } })}
            data={modalCriarEditarEntidade.data}
          />
        )}
      </div>
    )
  }
}

Entidades.propTypes = {
  history: PropTypes.object.isRequired,
  showMensagemConfirmacaoAction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    usuario: state.userReducer
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Entidades)
