import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Title from '@em/lib/src/util/Title'
import List from './components/list'
import AddOrEditModal from './components/addOrEditModal'

export default function PedidoStatusList ({ funcoes }) {
  const [addOrEditModal, setAddOrEditModal] = useState({ open: false, data: null })
  const [pedidoStatuses, setPedidoStatuses] = useState([])

  const loadStatuses = () => {
    axios.get('/api/pedidostatus/full').then(response => {
      setPedidoStatuses(response)
    })
  }

  async function addOrEditStatuses (status) {
    axios.post('api/pedidostatus', status).then(response => {
      loadStatuses()
      setAddOrEditModal({ open: false })
    })
  }

  async function deleteStatus (status) {
    axios.delete(`api/pedidostatus/${status.id}`).then(response => {
      loadStatuses()
    })
  }

  useEffect(() => {
    loadStatuses()
  }, [])

  return (
    <div>
      <Title
        title="Cadastro de Status"
        buttons={[
          {
            icon: 'add',
            onClick: () => setAddOrEditModal({ open: true })
          }
        ]}
      />

      <List
        openModal={data => setAddOrEditModal({ open: true, data })}
        deleteStatus={deleteStatus}
        funcoes={funcoes}
        pedidoStatuses={pedidoStatuses}
      />

      {addOrEditModal.open &&
        <AddOrEditModal
          onHide={refresh => {
            setAddOrEditModal({ open: false })
            if (refresh) {
              loadStatuses()
            }
          }}
          data={addOrEditModal?.data ?? {}}
          addOrEditStatuses={addOrEditStatuses}
        />
      }
    </div>
  )
}
