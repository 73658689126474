import moment from 'moment'
import { Liquid } from 'liquidjs'

export const getColumnsByFont = (fontSize = 8) => {
  let ttCols = 0
  switch (fontSize) {
    case 6:
      ttCols = 55
      break
    case 7:
      ttCols = 46
      break
    case 8:
      ttCols = 41
      break
    case 9:
      ttCols = 36
      break
    case 10:
      ttCols = 32
      break
    case 11:
      ttCols = 30
      break
    case 12:
      ttCols = 26
      break
    case 13:
      ttCols = 24
      break
    case 18:
      ttCols = 18
      break
    case 25:
      ttCols = 12
      break
    default:
      break
  }
  return ttCols
}

export const getCenter = (text, fontSize) => {
  // centraliza texto
  const ttCols = getColumnsByFont(fontSize)

  const ttText = (`${text}`).length
  const ttWhite = ttCols - ttText
  const ttWhiteLeft = ttWhite / 2
  let ret = ''

  for (let i = 0; i < ttWhiteLeft; i++) {
    ret += ' '
  }

  ret += text

  return ret
}

export const getAddSpacesRightSide = (text, ttCols, align = 'left') => {
  // adiciona espaços em branco à direita
  text = text.substr(0, ttCols)

  const ttText = text.length
  const ttWhite = ttCols - ttText
  let ret = ''

  if (align == 'left') {
    ret += text
    for (let i = 0; i < ttWhite; i++) {
      ret += ' '
    }
  } else {
    for (let i = 0; i < ttWhite; i++) {
      ret += ' '
    }
    ret += text
  }

  return ret
}

export const getSpaceBetween = (text1, text2, fontSize) => {
  // coloca espaço entre dois textos

  const ttCols = getColumnsByFont(fontSize)
  var ttText = text1.length + text2.length
  var ttWhite = ttCols - ttText
  let ret = text1
  for (let i = 0; i < ttWhite; i++) {
    ret += ' '
  }
  ret += text2
  return ret
}

export const getAlignTextPerPosition = (arrayTexts) => {
  let newLine = ''
  arrayTexts.forEach(partLine => {
    newLine += partLine.value
  })

  return newLine
}

export const getBreakLines = (line, fontSize) => {
  // retorna uma string com a quantidade de posições mesmo que em branco e quebra em linhas
  const length = getColumnsByFont(fontSize)
  if (line?.value?.length <= length) {
    return [line]
  } else {
    const lines = line?.value?.match(new RegExp('.{1,' + length + '}', 'g'))
    return lines?.map(l => {
      const newLine = l.trim()
      return { ...line, value: newLine }
    })
  }
}

export const parseVariablesFromPrintLayout = async (pedido, lines) => {
  const engine = new Liquid()
  const newLines = []
  for (let index = 0; index < lines.length; index++) {
    const line = lines[index]
    let newValue = line.value
    try {
      const tpl = await engine.parse(newValue)
      newValue = await engine.render(tpl, pedido)
    } catch (error) {

    }

    newLines.push({ ...line, value: newValue })
  }

  return newLines
}

export const tranformProductsToLines = pedido => {
  const lines = []
  const itens = pedido?.itens?.map(item => {
    const newMontagens = item?.montagens?.filter(m => !!(m.opcoes?.find(opt => opt?.qtde && opt?.qtde !== 0)))
    return { ...item, montagens: newMontagens }
  })

  lines.push(
    {
      value: 'COD | DESC | QDE | UN | Valor Un | Total',
      fontSize: 8,
      fontFamily: 'Lucida Console',
      fontStyle: 1,
      type: 0
    },
    {
      value: '',
      fontSize: 8
    }
  )

  itens?.forEach(item => {
    const valorUnit = Number(item?._valorTotal || item?.valorTotal || 0) / item?.qtde

    lines.push(
      ...getBreakLines(
        {
          value: `#${item.codigo} ${item.nome}`,
          fontSize: 10,
          fontFamily: 'Lucida Console',
          type: 0
        }, 10),
      {
        value: getAddSpacesRightSide(`${Number(item?.qtde || 0)} ${item?.unidadeMedida} x ${valorUnit?.toFixed(2).replace('.', ',')} ${Number(item?._valorTotal || item?.valorTotal || 0).toFixed(2).replace('.', ',')}`, getColumnsByFont(10), 'right'),
        fontSize: 10,
        fontFamily: 'Lucida Console',
        type: 0
      }
    )
    debugger
    if (item.viagem || item.observacao) {
      lines.push(
        {
          value: ' ',
          type: 0
        },
        {
          value: ' *Observação:' + (item.viagem ? ' Para Viagem' : ''),
          fontStyle: 1,
          fontSize: 11,
          fontFamily: 'Lucida Console',
          type: 0
        }
      )
      if (item.observacao) {
        lines.push(
          ...getBreakLines(
            {
              value: `  ${item.observacao}`,
              fontSize: 10,
              fontFamily: 'Lucida Console',
              type: 0
            }, 10)
        )
      }
    }
    item?.montagens?.forEach(m => {
      if (m?.opcoes?.length > 0) {
        lines.push(
          {
            value: ' ',
            type: 0
          },
          {
            value: ` *${m.titulo}:`,
            fontStyle: 1,
            fontSize: 11,
            fontFamily: 'Lucida Console',
            type: 0
          }
        )
        m?.opcoes?.forEach(opt => {
          if (opt?.qtde < 0) {
            lines.push(
              {
                value: `  ${opt?.qtde}x ${opt.nome?.substr(0, 10)} ${opt?.valor ? `(- ${Number(opt?.valor || 0).toFixed(2).replace('.', ',')})` : ''}`,
                fontSize: 10,
                fontFamily: 'Lucida Console',
                type: 0
              }
            )
          } else if (opt?.qtde > 0 && opt?.qtde > opt?.incluso) {
            lines.push(
              {
                value: `   ${opt.qtde + opt?.incluso}x ${opt.nome?.substr(0, 18)} ${opt?.valor ? `(+ ${Number(opt?.valor || 0).toFixed(2).replace('.', ',')})` : ''}`,
                fontSize: 10,
                fontFamily: 'Lucida Console',
                type: 0
              }
            )
          } else if (opt?.qtde > 0) {
            lines.push(
              {
                value: `   ${opt.qtde}x ${opt.nome} ${opt?.valor ? `(${Number(opt?.valor || 0).toFixed(2).replace('.', ',')})` : ''}`,
                fontSize: 10,
                fontFamily: 'Lucida Console',
                type: 0
              }
            )
          }
        })
      }
    })
    lines.push(
      {
        value: '=========================================',
        fontSize: 12,
        fontFamily: 'Lucida Console',
        type: 0
      }
    )
  })
  return lines
}

export const formatLines = async (pedido, lines) => {
  let newLines = await parseVariablesFromPrintLayout(pedido, lines)
  // alinhamento
  newLines = newLines?.map(line => {
    let newValue = line?.value
    const ttcols = getColumnsByFont(Number(line?.fontSize || 0))
    if (line?.align === 'center') {
      newValue = getCenter(line?.value, Number(line?.fontSize || 0))
    } else if (line?.align === 'right') {
      newValue = getAddSpacesRightSide(line?.value, ttcols, line?.align)
    } else {
      newValue = line?.value?.trim()
    }

    return {
      ...line,
      value: newValue
    }
  })

  let newLines2 = []
  newLines.forEach(line => {
    if (line?.type === 'products') {
      newLines2 = [...newLines2, ...tranformProductsToLines(pedido)]
    }

    newLines2 = [...newLines2, line]
  })

  return newLines2
}

export const getLinesByWords = (phrase, maxLength = 33) => {
  const words = phrase.split(' ')
  const listLines = ['']
  words.forEach(word => {
    const lastLineIndex = listLines.length - 1
    const lastLine = listLines[lastLineIndex]
    if ((lastLine.length + word.length) < maxLength) {
      listLines[lastLineIndex] += `${word} `
    } else {
      listLines.push(word)
    }
  })

  return listLines
}
