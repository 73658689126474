import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent,
  Button,
  Grid,
  DialogActions,
  Typography,
  IconButton,
  Icon,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Title from '@em/lib/src/util/Title'
import { NumberFormatMoneyBR } from './../../../../_atoms/numberFormat'
import { somarItem } from '@em/lib/src/Produtos/somasCaixa2'

const OptionSelectOne = ({ opcoes, onSelectOne }) => (
  <Grid container spacing={1}>
    {opcoes && opcoes.map((opc, idx) => {
      const qtde2 = (opc.qtde || 0) + (opc.incluso || 0)
      let borderColor = '#ccc'
      if (qtde2 > 0) borderColor = '#66bb6a'

      return (
        <Grid item key={idx}>
          <Button
            style={{ borderRadius: 15, border: `2px solid ${borderColor}` }}
            onClick={() => onSelectOne(opcoes, opc)}
          >
            <div>
              <Typography variant="subtitle1">{opc.nome}</Typography>
              <Typography variant="caption">
                <NumberFormatMoneyBR value={opc.valor} />
              </Typography>
            </div>
          </Button>
        </Grid>
      )
    })}
  </Grid>
)

const OptionMultiple = ({ opcoes, onAddOrLess }) => (
  <Grid container spacing={1}>
    {opcoes && opcoes.map((opc, idx) => {
      let borderColor = '#ccc'
      if (opc.minimo > (opc._qtde)) {
        borderColor = '#ef5350'
      } else if (opc.maximo < (opc._qtde)) {
        borderColor = '#ef5350'
      } else if (opc.qtde < 0) {
        borderColor = '#ef5350'
      } else if (opc.qtde > 0) {
        borderColor = '#66bb6a'
      }

      return (
        <Grid item key={idx}>
          <table style={{ borderRadius: 20, border: `2px solid ${borderColor}` }}>
            <tbody>
              <tr>
                <td style={{ position: 'relative' }}>
                  <span style={{
                    fontSize: 10, position: 'absolute', top: 5, left: 5
                  }}
                  >
min:
                    {opc.minimo}
                  </span>
                  <IconButton
                    disabled={(opc._qtde || 0) <= (opc.minimo || 0)}
                    onClick={() => onAddOrLess(opc, -1)}
                  >
                    <Icon>remove</Icon>
                  </IconButton>
                </td>
                <td>
                  <center>
                    <Typography variant="subtitle1">{opc.nome}</Typography>

                    <Typography variant="caption">
                      {(opc._qtde || 0)}
x
                      <NumberFormatMoneyBR value={opc.valor} />
                    </Typography>
                  </center>
                </td>
                <td style={{ position: 'relative' }}>
                  <span style={{
                    fontSize: 10, position: 'absolute', top: 5, right: 5
                  }}
                  >
max:
                    {opc.maximo}
                  </span>
                  <IconButton
                    disabled={(opc._qtde || 0) >= (opc.maximo || 0)}
                    onClick={() => onAddOrLess(opc, 1)}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      )
    })}
  </Grid>
)

class MontagemDialog extends Component {
  state = {
    expandedPanel: 0,
    item: { ...this.props.item, showComposicao: false }
  }

  onSave = () => {
    const { hcItem } = this.props
    const { item } = this.state
    hcItem(item)
  }

  onAddOrLess = (opt, qtde) => {
    opt.qtde = (opt.qtde || 0) + qtde
    const { item } = this.state
    somarItem(item)
    this.setState({ item })
  }

  onSelectOne = (opcoes, opc) => {
    opcoes.forEach(o => {
      if (opc.nome == o.nome) {
        if (o._qtde > 0) o.qtde = 0
        else o.qtde = 1
      } else {
        o.qtde = 0
      }
      o.incluso = 0
    })
    const { item } = this.state
    somarItem(item)
    this.setState({ item })
  }

  getValor (montagem) {
    let valor = 0
    let qtde = 0
    montagem.opcoes.forEach(opc => {
      const qtde2 = ((opc.qtde || 0) + (opc.incluso || 0))
      valor += opc.valor * qtde2
      qtde += qtde2
    })
    return { valor, qtde }
  }

  onHide = () => {
    this.props.hcItem({ ...this.props.item, showComposicao: false })
    this.props.onHide()
  }

  render () {
    const { onHide } = this.props
    const { item } = this.state
    const { expandedPanel } = this.state
    return (
      <Dialog open fullWidth maxWidth={false}>
        <Title
          title={item.nome}
          buttons={[
            { icon: 'close', color: 'secondary', onClick: this.onHide }
          ]}
        />
        <DialogContent style={{ paddingTop: 10 }}>
          {item.montagens && item.montagens.map((montagem, idx) => {
            const expanded = expandedPanel == idx
            return (
              <ExpansionPanel
                key={idx}
                expanded={expanded}
                onChange={(a, b) => this.setState({ expandedPanel: b ? idx : null })}
              >
                <ExpansionPanelSummary style={{ margin: 0 }} expandIcon={<ExpandMoreIcon />}>
                  <Icon fontSize="large" color={!montagem._valid ? 'error' : 'primary'}>
                    {!montagem._valid ? 'list' : 'playlist_add_check'}
                  </Icon>
                  <div style={{
                    marginLeft: 10, display: 'flex', width: '100%', alignItems: 'center'
                  }}
                  >
                    <div style={{ flex: 1 }}>
                      <Typography variant="h6">{montagem.titulo}</Typography>
                      <Typography variant="caption">
                        {montagem.maximo > 0 && `Escolha até ${montagem.maximo} opções`}
                        {montagem.minimo > 0 && ` (${montagem.minimo} opções são obrigatórias)`}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h6">
                        {`${montagem._qtde}x `}
                        {' '}
                        <NumberFormatMoneyBR value={montagem._valorTotal} />
                      </Typography>
                    </div>
                  </div>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  {montagem.maximo == 1 &&
                    <OptionSelectOne opcoes={montagem.opcoes} onSelectOne={this.onSelectOne} requered={montagem.maximo == 1} /> ||
                    <OptionMultiple opcoes={montagem.opcoes} onAddOrLess={this.onAddOrLess} />}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Typography variant="h6" style={{ color: '#bbb', marginRight: 10 }}>
Valor Unitário:
            <NumberFormatMoneyBR value={item.valor} />
          </Typography>
          <Typography variant="h6">
Total:
            <NumberFormatMoneyBR value={item._valorTotalItemSemDesconto} />
          </Typography>
          <Button color="primary" variant="contained" onClick={this.onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default MontagemDialog
