import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent, DialogActions, Button, Grid, Typography, Icon,
} from '@material-ui/core'
import Title from '@em/lib/src/util/Title'

class SearchImpressoraDialog extends Component {
  state = {
    impressoras: [],
    impressorasSelecionadas: this.props.impressorasSelecionadas,
  }

  componentDidMount() {
    axios.get('api/Impressora/Full').then(impressoras => {
      this.setState({ impressoras })
    })
  }


  onConfirm = () => {
    const { impressorasSelecionadas } = this.state
    const { handlerSelectedImpressora } = this.props
    handlerSelectedImpressora(impressorasSelecionadas)
  }

  addImpressora = imp => {
    const { impressorasSelecionadas } = this.state
    this.setState({ impressorasSelecionadas: [...impressorasSelecionadas, imp] })
  }

  removeImpressora = imp => {
    const { impressorasSelecionadas } = this.state
    this.setState({ impressorasSelecionadas: impressorasSelecionadas.filter(x => x.id != imp.id) })
  }

  render() {
    const { onHide, title } = this.props
    const { impressorasSelecionadas, impressoras } = this.state

    return (
      <Dialog
        open
        fullWidth
        maxWidth="md"
      >
        <Title title={title || 'Selecione as impressoras'} buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]} />
        <DialogContent style={{ paddingTop: 10 }}>
          <Grid container spacing={1}>
            {impressoras && impressoras.map((imp, idx) => {
              const selected = _.some(impressorasSelecionadas, x => x.id === imp.id)

              const backgroundColor = selected ? '#333' : 'white'
              const color = selected ? 'white' : '#333'

              return (
                <Grid item key={idx}>

                  <Button
                    onClick={() => (selected ? this.removeImpressora(imp) : this.addImpressora(imp))}
                    variant="outlined"
                    style={{ backgroundColor, minWidth: 120 }}
                  >
                    <Grid item>
                      <Icon fontSize="large" style={{ color }}>print</Icon>
                      <Typography style={{ color }} variant="subtitle1">{imp.nome}</Typography>
                    </Grid>
                  </Button>

                </Grid>
              )
            })}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onConfirm} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default SearchImpressoraDialog
