import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'

const styles = () => ({})

class TabContatoCriarEditarDialog extends Component {
  state = {
    form: { tipo: 'whatsapp', ...this.props.data }
  }

  handlerChange = e => {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    })
  };

  render () {
    const { form } = this.state
    const { data } = this.props
    const BUTTON_TEXT = (data && 'Editar') || 'Criar'
    return (
      <Dialog open maxWidth="md" fullWidth>
        <Title title={`${(data && 'Editar') || 'Criar'} contato`} />
        <DialogContent style={{ padding: 30 }}>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={12} md={6}>
              <ComboBoxData
                label="Tipo"
                onChange={e => this.handlerChange({ target: { name: 'tipo', value: e.id } })}
                selected={form.tipo}
                list={[
                  { id: 'whatsapp', nome: 'Whatsapp' },
                  { id: 'twitter', nome: 'Twitter' },
                  { id: 'instagram', nome: 'Instagram' },
                  { id: 'facebook', nome: 'Facebook' },
                  { id: 'email', nome: 'Email' },
                  { id: 'telefone', nome: 'Telefone' },
                  { id: 'site', nome: 'Site' },
                  { id: 'ifood', nome: 'Ifood' },
                  { id: 'rappi', nome: 'Rappi' },
                  { id: '99food', nome: '99 Food' },
                  { id: 'uberEats', nome: 'Uber eats' },
                  { id: 'linkedin', nome: 'Linkedin' },
                  { id: 'behance', nome: 'Behance' },
                  { id: 'pinterest', nome: 'Pinterest' },
                  { id: 'youtube', nome: 'Youtube' },
                  { id: 'zeDelivery', nome: 'Zé Delivery' }
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="valor"
                fullWidth
                label="Valor"
                multiline
                value={(form && form.valor) || ''}
                onChange={this.handlerChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onHide} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => this.props.onAdd(form)} color="primary" variant="contained">
            {BUTTON_TEXT}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TabContatoCriarEditarDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default withStyles(styles)(TabContatoCriarEditarDialog)
