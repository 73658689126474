import Axios from 'axios'
import React, { useState } from 'react'

import AutenticaAdmDialog from './autenticaAdmDialog'
import ListaDeAdmsDialog from './listaDeAdmsDialog'

export const authorizationAdmin = (callback) => {
  const [autenticaAdmModal, setAutenticaAdmModal] = useState({ open: false, data: null })
  const [admsModal, setAdmsModal] = useState({ open: false })
  const [showError, setShowError] = useState(false)

  const validateAdm = (userId, senha) => {
    Axios.post(`api/Login/VerificarLogin/?id=${userId}&senha=${senha}`)
      .then(val => {
        if (val) {
          setAutenticaAdmModal({ open: false, data: null })
          callback('success')
        } else {
          callback('fail')
          setShowError(true)
        }
      }).catch(() => {
        callback('fail')
        setShowError(true)
      })
  }

  const open = () => {
    setAdmsModal({ open: true })
    setShowError(false)
  }

  const renderElement = () => {
    return (
      <>
        {admsModal.open &&
          <ListaDeAdmsDialog
            onHide={() => setAdmsModal({ open: false })}
            confirmAdm={adm => {
              setAutenticaAdmModal({ open: true, data: adm })
              setAdmsModal({ open: false })
            }}
          />
        }

        {autenticaAdmModal.open &&
          <AutenticaAdmDialog
            onHide={() => setAutenticaAdmModal({ open: false, data: null })}
            adm={autenticaAdmModal.data}
            validateAdm={validateAdm}
            showError={showError}
          />
        }
      </>
    )
  }

  return {
    renderElement,
    open
  }
}
