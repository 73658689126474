import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import {
  Paper, Icon, Button, IconButton, Typography, Grid,
  InputAdornment, Fab, Tooltip, Grow
} from '@material-ui/core'
import { LocalShippingOutlined } from '@material-ui/icons'
import { SiCashapp } from 'react-icons/si'

import statusPagamentoJSON from './../../../../contstants/statusPagamento'
import PedidoPagamentoWorkFlowIfood from './pedidoPagamentoWorkFlowIfood'
import WorkflowStatusButtons from './workflowStatusButtons'
import ApplyShippingDialog from './applyShippingDialog'
import ApplyDiscountDialog from './applyDiscountDialog'
import FormasPagamentoDialog from './formasPagamentoDialog'
import { NumberFormatMoneyBR, TextFieldMoneyBR } from '@em/lib/src/util/InputCustom'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { BsListCheck } from 'react-icons/bs'

const PedidoPagamento = ({
  onFinalizar, onCancelar, onImprimir, onImprimirPedidao,
  onImprimirControle, onImprimirPracas, onImprimirCfe, onSave, pedido,
  onRefreshPedido, hostIfood, formasDePagamento, todosStatusDePedidos, emUtility, sistema, changedPedido
}) => {
  const [valor, setValor] = useState('')
  const [autoValor, setAutoValor] = useState(true)
  const [applyShippingDialog, setApplyShippingDialog] = useState({ open: false })
  const [applyDiscountDialog, setApplyDiscountDialog] = useState({ open: false })
  const [payDialog, setPayDialog] = useState({ open: false })

  const getValorPagamento = () => {
    let valorPago = 0
    pedido.pagamentos &&
      pedido.pagamentos.forEach(item => {
        valorPago += Number(item.valor || 0)
      })
    return valorPago
  }

  const addPagamento = pgto => {
    if (pgto.valor > 0) {
      let pagamentos = []
      if (pedido.pagamentos) pagamentos = [...pedido.pagamentos]
      onRefreshPedido({
        ...pedido,
        pagamentos: [...pagamentos, pgto]
      })
      setValor('')
      setAutoValor(true)
      setPayDialog(false)
    }
  }

  const removePagamento = index => {
    _.pullAt(pedido.pagamentos, index)
    onRefreshPedido(pedido)
  }

  const valorPago = getValorPagamento()

  let valorField = ''
  if (!autoValor) {
    valorField = valor
  } else if (pedido._valorTotal > valorPago) {
    valorField = pedido._valorTotal - valorPago
  }

  const canalInterno = [0, 1, 3].includes(pedido.canal)

  return (
    <div style={{ padding: 10, backgroundColor: '#EEE', border: '1px solid #BABABA' }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={8}>
              <span>
                Valor dos produtos
              </span>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <span>
                <NumberFormatMoneyBR value={(pedido._valor || 0)} />
              </span>
            </Grid>
            <Grid item xs={8}>
              <span>
                Valor de desconto
              </span>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <span>
                <NumberFormatMoneyBR value={(pedido.discountAmount || 0)} />
              </span>
            </Grid>
            <Grid item xs={8}>
              <span>
                Taxa de entrega
              </span>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <span>
                <NumberFormatMoneyBR value={(pedido.valorFrete || 0)} />
              </span>
            </Grid>
            {pedido.valorTaxa > 0 &&
              <>
                <Grid item xs={8}>
                  <span>
                    Taxa de serviço do site
                  </span>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <span>
                    <NumberFormatMoneyBR value={(pedido.valorTaxa || 0) - (pedido.valorFrete || 0)} />
                  </span>
                </Grid>
              </>
            }

            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={8}>
              <b style={{ fontSize: 20 }}>
                Valor total
              </b>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <b style={{ fontSize: 20 }}>
                <NumberFormatMoneyBR value={pedido._valorTotal} />
              </b>
            </Grid>
            <Grid item xs={8}>
              <b style={{ fontSize: 20 }}>
                Valor pago
              </b>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <b style={{ fontSize: 20 }}>
                <NumberFormatMoneyBR value={valorPago} />
              </b>
            </Grid>
            <Grid item xs={8}>
              <b style={{ fontSize: 20 }}>
                {valorPago >= pedido._valorTotal ? 'Troco ' : 'Falta '}
              </b>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <b style={{ fontSize: 20, color: valorPago < pedido._valorTotal && 'red' }}>
                <NumberFormatMoneyBR value={valorPago.toFixed(2) - pedido._valorTotal?.toFixed(2)} />
              </b>
            </Grid>
          </Grid>
        </Grid>
        {pedido?.pagamentos?.length > 0 && (
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                {pedido.pagamentos.map((pag, idx) => (
                  <Paper key={idx} style={{ padding: 10, marginTop: 5, backgroundColor: '#CCC' }}>
                    <Grid container justify="space-between" alignItems="center" spacing={1}>
                      <Grid item>
                        {pag.nome}
                      </Grid>
                      <Grid item>
                        <span>
                          {statusPagamentoJSON[pag.status]}
                        </span>
                      </Grid>
                      <Grid item style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                        <NumberFormatMoneyBR value={pag.valor} />

                        {(canalInterno && pag.financeira !== 1) && (
                          <IconButton size="small" color="secondary" onClick={() => removePagamento(idx)}>
                            <Icon>delete</Icon>
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs style={{ textAlign: 'right' }}>
          <Tooltip title="Aplicar Frete" placement="top">
            <Fab
              style={{
                backgroundColor: '#fff',
                color: '#FFD700',
                marginRight: 10
              }}
              onClick={() => setApplyShippingDialog({ open: true })}
            >
              <LocalShippingOutlined />
            </Fab>
          </Tooltip>
          <Tooltip title="Aplicar Desconto" placement="top">
            <Fab
              style={{
                backgroundColor: '#fff',
                color: '#32CD32',
                marginRight: 10
              }}
              onClick={() => setApplyDiscountDialog({ open: true })}
            >
              <Icon>local_atm</Icon>
            </Fab>
          </Tooltip>
          {canalInterno &&
            <Tooltip title="Aplicar Pagamento" placement="top">
              <Fab
                disabled={valorField <= 0}
                style={{
                  backgroundColor: valorField <= 0 ? '#CCC' : '#32CD32',
                  color: '#ffffff',
                  marginRight: 10
                }}
                onClick={() => setPayDialog({ open: true, initialValue: valorField })}
              >
                <Icon>
                  <SiCashapp />
                </Icon>
              </Fab>
            </Tooltip>
          }
        </Grid>

        <Grid item xs={12}>
          {[0, 1, 3].includes(pedido.canal) && (
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <WorkflowStatusButtons
                  key={`${pedido.id}-${pedido.statusEntrega}-${pedido.cFe}`}
                  onRefreshPedido={onRefreshPedido}
                  pedido={pedido}
                  emUtility={emUtility}
                  sistema={sistema}
                  todosStatusDePedidos={todosStatusDePedidos}
                  changedPedido={changedPedido}
                />
              </Grid>
            </Grid>
          )}
          {pedido.canal != 0 && (
            <>
              {pedido.canal == 2 &&
                (pedido.statusEntrega !== 3 && pedido.statusEntrega !== null && (
                  <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                      <PedidoPagamentoWorkFlowIfood
                        onImprimir={onImprimir}
                        onRefreshPedido={onRefreshPedido}
                        pedido={pedido}
                        hostIfood={hostIfood}
                      />
                    </Grid>
                  </Grid>
                ))}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          {((pedido.statusEntrega === 3) && (
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <Button fullWidth style={{ backgroundColor: 'red', color: 'white' }} onClick={onCancelar}>
                  Cancelar Faturamento
                </Button>
              </Grid>
            </Grid>
          )) || (
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                {[0, 1, 3].includes(pedido.canal) && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onFinalizar}
                    disabled={pedido._valorTotal > valorPago || !pedido._valid}
                  >
                      Faturar
                  </Button>
                )}

                {([2].includes(pedido.canal)) && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onFinalizar}
                    disabled={![4, 5, 6].includes(pedido.statusEntrega)}
                  >
                      Faturar
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grow in={valorPago > 0} timeout={1000}>
            <Grid container justify="flex-end" alignItems="center" spacing={1}>
              <Grow in={!!pedido.cFe}>
                <Grid item>
                  <Tooltip title="Imprimir Cfe" placement="top">
                    <Fab style={{ backgroundColor: '#fff' }}
                      onClick={onImprimirCfe}
                    >
                      <Icon style={{ color: '#03c303' }}>receipt_long</Icon>
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grow>
              <Grid item>
                <Tooltip title="Imprimir Controle" placement="top">
                  <Fab
                    style={{ backgroundColor: '#fff' }}
                    onClick={onImprimirControle}
                  >
                    <Icon><BsListCheck /></Icon>
                  </Fab>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Imprimir Numero do Pedido" placement="top">
                  <Fab style={{ backgroundColor: '#fff' }}
                    onClick={onImprimirPedidao}
                  >
                    <Icon><AiOutlineFieldNumber /></Icon>
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </Grid>
      {applyShippingDialog.open &&
        <ApplyShippingDialog
          onHide={() => setApplyShippingDialog({ open: false })}
          pedido={pedido}
          onRefreshPedido={onRefreshPedido}
        />
      }
      {applyDiscountDialog.open &&
        <ApplyDiscountDialog
          onHide={() => setApplyDiscountDialog({ open: false })}
          pedido={pedido}
          onRefreshPedido={onRefreshPedido}
        />
      }

      {payDialog.open && (
        <FormasPagamentoDialog
          addPay={addPagamento}
          fPgto={payDialog.fPgto}
          initialValue={payDialog.initialValue}
          formasDePagamento={formasDePagamento}
          onHide={() => setPayDialog({ ...payDialog, open: false })}
        />
      )}
    </div>
  )
}

export default PedidoPagamento
