import React from 'react'
import _ from 'lodash'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  Grid, Button, Collapse, Dialog, DialogContent, DialogActions, Checkbox, FormControlLabel,
} from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'

class TabLogin extends React.Component {
  state = {
    loading: true,
    trocarSenha: false,
    login: {},
    perfis: [],
  };

  componentDidMount() {
    this.loadLogin()
    this.loadPerfis()
  }

  loadLogin = () => {
    const { data } = this.props
    axios.get(`api/login/${data.id}`).then(login => {
      this.setState({ login })
    })
  };

  loadPerfis = () => {
    axios.get(`api/perfil`).then(perfis => {
      this.setState({ perfis })
    })
  };

  handlerChange = e => {
    this.setState({
      login: { ...this.state.login, [e.target.name]: e.target.value },
    })
  };

  handlerCheck = (check, id) => {
    const { login } = this.state
    const perfis = login.perfis || []
    if (check) {
      perfis.push(id)
    } else {
      _.remove(perfis, x => x === id)
    }

    this.setState({ login: { ...login, perfis } })
  };

  onSave = () => {
    const { login } = this.state
    const { data } = this.props
    const { id } = data
    axios.post(`api/login`, { ...login, id }).then(login => {
      this.props.onHide()
    })
  };

  render() {
    const { login, trocarSenha, perfis } = this.state
    const { onHide } = this.props
    const superAdminsId = '5b5f7eaa3bd0ae4b82737764'
    const superAdminsCheck = _.some(login.perfis, x => x === superAdminsId)

    return (
      <Dialog open>
        <Title
          title={`${(login.id && 'Editar') || 'Criar'} login`}
          buttons={[
            {
              icon: 'close',
              color: 'secondary',
              onClick: onHide,
            },
          ]}
        />
        <DialogContent style={{ padding: 30 }}>
          <Grid container justify="space-between" alignItems="center" spacing={1}>
            <Grid item xs={8}>
              <TextField
                onChange={this.handlerChange}
                name="usuario"
                value={(login && login.usuario) || ''}
                label="Usuario"
                fullWidth
              />
            </Grid>
            <Grid item>
              {!trocarSenha && (
                <Button variant="contained" onClick={() => this.setState({ trocarSenha: true })}>
                  Trocar Senha
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Collapse in={trocarSenha}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={this.handlerChange}
                      type="password"
                      name="senha"
                      value={(login && login.senha) || ''}
                      label="Digite uma senha"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={this.handlerChange}
                      type="password"
                      name="resenha"
                      error={login && login.senha != login.resenha}
                      value={(login && login.resenha) || ''}
                      label="Repita a senha"
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={superAdminsCheck}
                    onChange={() => this.handlerCheck(!superAdminsCheck, superAdminsId)}
                  />
                )}
                label="Super Admin"
              />
              {perfis.map((perfil, idx) => {
                const check = _.some(login.perfis, x => x === perfil.id)
                return (
                  <FormControlLabel
                    key={idx}
                    control={<Checkbox checked={check} onChange={() => this.handlerCheck(!check, perfil.id)} />}
                    label={perfil.nome}
                  />
                )
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSave}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TabLogin.propTypes = {
  login: PropTypes.object,
}

export default TabLogin
