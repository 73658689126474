import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import Highcharts from 'highcharts'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import Chart from '../../_atoms/Chart'
import NumberFormatMoneyBR from '../../_atoms/numberFormat/NumberFormatMoneyBR'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../_atoms/combobox'

export default class GraficoVendasMes extends Component {
  state = {
    serie: [],
    categories: [],
    faturamentoAtual: 0,
    ano: `${new Date().getFullYear()}`,
    mes: `${new Date().getMonth()}`,
  }

  getOptions = (serie, categories) => ({
    chart: {
      defaultSeriesType: 'column',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          // rotation: -90,
          // y: 10, // 10 pixels down from the top
          format: 'R$ {point.y:.2f}', // one decimal
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
    },
    title: {
      text: 'Venda por dia',
    },

    legend: {
      enabled: true,
    },
    exporting: {
      enabled: false,
    },
    xAxis: [
      {
        categories,
        title: {
          text: '',
        },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Faturamento',
        },
      },
    ],
    tooltip: {
      formatter() {
        const valor = Highcharts.numberFormat(this.y, 2)
        const serieName = this.x
        return `Vendas - ${serieName}:<br/>`
          + `<b> R$${valor}</b><br/><br/>`
          + `<br/>`
      },
    },
    series: [
      {
        data: serie,
        // events: {
        //     click: mostrarFechamento
        // },
        name: 'Faturamento',
        color: '#2b908f',
      },
    ],
  })

  getData = () => {
    const { ano, mes } = this.state
    axios
      .get(`/api/Pedido/Diario/${ano}/${mes}`)
      .then(data => this.atualizaGraficos(data))
  }

  atualizaGraficos = data => {
    const categories = []; const
      serie = []

    _.forEach(data, d => {
      const property = _.head(_.keys(d))
      categories.push(this.toData(property))
      serie.push(d[property])
    })

    this.setState({
      serie, categories,
    }, () => {
      this.atualizaLabels()
    })
  }

  toData(dia) {
    return moment().set({
      date: dia,
      month: this.state.mes - 1,
      year: this.state.ano,
    }).format('DD/MM/YYYY')
  }

  atualizaLabels() {
    const { serie } = this.state
    const faturamentoAtual = _.sum(serie)
    this.setState({ faturamentoAtual })
  }

  componentDidMount() {
    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
    })
    const hj = new Date()
    this.setDataAtual(hj.getFullYear(), hj.getMonth() + 1)
  }

  setDataAtual = (ano, mes) => {
    this.setState({
      ano, mes,
    }, () => {
      this.getData()
    })
  }

  handlerChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      this.getData()
    })
  }


  listaAno = () => {
    let anoInicio = new Date().getFullYear() - 5
    const anoFim = new Date().getFullYear()
    const ret = []
    while (anoInicio <= anoFim) {
      ret.push({
        id: `${anoInicio}`,
        nome: `${anoInicio}`,
      })
      anoInicio++
    }
    return ret
  }

  listaMes = () => {
    const mInicio = moment().set({
      month: 0,
    })
    const mFim = moment().set({
      month: 0,
      year: mInicio.get('year') + 1,
    })

    const ret = []
    while (mInicio.isBefore(mFim)) {
      ret.push({
        id: Number(mInicio.format('MM')),
        nome: _.capitalize(mInicio.format('MMMM')),
      })

      mInicio.add(1, 'month')
    }
    return ret
  }

  render() {
    const { serie, categories, ano, mes } = this.state
    const options = this.getOptions(serie, categories)

    return (
      <>
        <Title title="Gráfico de vendas diário" />
        <br />
        <Grid container justify="space-between">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <ComboBoxData
                  label="Ano"
                  onChange={e => this.handlerChange({ target: { name: 'ano', value: e.id } })}
                  selected={ano}
                  list={this.listaAno()}
                />
              </Grid>
              <Grid item>
                <ComboBoxData
                  label="Mes"
                  onChange={e => this.handlerChange({ target: { name: 'mes', value: e.id } })}
                  selected={`${mes}`}
                  list={this.listaMes()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <span style={{

              color: '#ccc',
              fontSize: '.8em',
            }}
            >
              Faturamento:&nbsp;
              <span style={{
                fontSize: '2em',
              }}
              >
                <NumberFormatMoneyBR value={this.state.faturamentoAtual} />
              </span>
            </span>
          </Grid>
        </Grid>
        <br />
        <Chart options={options} />
      </>
    )
  }
}
