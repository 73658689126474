import React from 'react'
import { EMImageZoom } from '@em/lib/src'

import AddOrEditEntregadorDialog from './addOrEditEntregadorDialog'
import { Avatar, IconButton } from '@material-ui/core'

class PedidoEntregador extends React.Component {
  state = {
    anchorEl: null,
    addOrEditEntregadorDialog: { open: false }
  }

  render () {
    const { pedido, onRefreshPedido } = this.props
    const { entregador } = pedido
    return (
      <div style={{ padding: 10, backgroundColor: '#EEE', border: '1px solid #BABABA' }}>
        <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
          <div style={{ flex: 1 }}>

            <strong>Dados do Entregador</strong>

            {entregador?.nome && (
              <p style={{ margin: 0 }}>
                <strong>Nome: </strong>
                {' '}
                {entregador.nome}
              </p>
            )}
            {entregador?.tipo && (
              <p style={{ margin: 0 }}>
                <strong>Veículo: </strong>
                {' '}
                {entregador.tipo}
              </p>
            )}
            {entregador?.telefone && (
              <p style={{ margin: 0 }}>
                <strong>Telefone: </strong>
                {' '}
                {entregador && entregador.telefone}
              </p>
            )}

          </div>
          <IconButton
            onClick={() => this.setState({ addOrEditEntregadorDialog: { open: true } })}
          >
            <Avatar
              src={!entregador?.foto?.startsWith('http') && entregador?.foto ? global.bucket + entregador?.foto : entregador?.foto}
            />
          </IconButton>
        </div>
        {this.state.addOrEditEntregadorDialog.open &&
          <AddOrEditEntregadorDialog
            onHide={() => this.setState({ addOrEditEntregadorDialog: { open: false } })}
            entregador={entregador}
            saveEntregador={data => {
              this.setState({ addOrEditEntregadorDialog: { open: false } }, () => {
                pedido.entregador = data
                onRefreshPedido(pedido)
              })
            }}
          />
        }
      </div>
    )
  }
}

export default PedidoEntregador
