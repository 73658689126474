import React, { useState } from 'react'
import _ from 'lodash'
import {
  IconButton, Icon, List, ListItem, ListItemText
  , ListItemSecondaryAction
} from '@material-ui/core'
import AddOrEditModal from './addOrEditModal'
import { NumberFormatMoneyBR } from '../../../../_atoms/numberFormat'
import EMTable from '@em/lib/src/EMTable'

export default ({ data, onChange, setForm, disabledReorder }) => {
  const [modal, setModal] = useState({ open: false })

  const handlerChange = (item, idx) => {
    if (modal.action == 'add') {
      data = [...(data || []), { ...item }]
    } else {
      data[idx] = item
    }
    onChange(data)
    setModal({ open: false })
  }

  return (
    <div>

      <List component="nav" aria-labelledby="nested-list-subtitle">
        <ListItem >
          <ListItemText primary="Faixa de Cep" />
          <ListItemSecondaryAction>
            <IconButton onClick={() => setModal({ open: true, dataValue: data, action: 'add' })} edge="end" aria-label="add">
              <Icon>add</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

      </List>

      <EMTable
        data={data?.filter(x => x.filter)}
        options={{
          paging: false
        }}
        columns={[
          {
            field: 'nome',
            title: 'Nome'
          },
          {
            field: 'inicial',
            title: 'Inicial'
          },
          {
            field: 'final',
            title: 'Final'
          },
          {
            field: 'valor',
            title: 'Valor do Frete',
            render: dataRow => <NumberFormatMoneyBR value={dataRow.valor} />
          },
          {
            field: 'valorFreteRepasse',
            title: 'Valor de Repasse',
            render: dataRow => <NumberFormatMoneyBR value={dataRow.valorFreteRepasse} />
          },
          {
            cellStyle: {
              width: 220
            },
            render: row => (
              <div>
                <IconButton
                  color="primary"
                  disabled={row.tableData.id === 0 || disabledReorder}
                  onClick={() => {
                    data.splice(
                      row.tableData.id - 1,
                      0,
                      _.cloneDeep(row)
                    )
                    data.splice(row.tableData.id + 1, 1)
                    setForm(state => ({ ...state, data: { ...state.data, faixas: data } }))
                  }}
                >
                  <Icon>arrow_drop_up</Icon>
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={row.tableData.id + 1 === data.length || disabledReorder}
                  onClick={() => {
                    data.splice(
                      row.tableData.id + 2,
                      0,
                      _.cloneDeep(row)
                    )
                    data.splice(row.tableData.id, 1)
                    setForm(state => ({ ...state, data: { ...state.data, faixas: data } }))
                  }}
                >
                  <Icon>arrow_drop_down</Icon>
                </IconButton>
                <IconButton onClick={() => setModal({ open: true, dataValue: row, idx: row.tableData.id })} edge="end" aria-label="edit">
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton onClick={() => {
                  data.splice(row.index, 1)
                  onChange(data || [])
                }} edge="end" aria-label="delete">
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )
          }
        ]}
      />

      {modal.open &&
        <AddOrEditModal
          action={modal.action}
          onChange={(item) => { handlerChange(item, modal.idx) }}
          data={modal.dataValue}
          onHide={() => setModal({ open: false })}
        />
      }
    </div>
  )
}
