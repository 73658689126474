import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import {
  Dialog, DialogActions, DialogContent, Button, Grid,
} from '@material-ui/core'
import { CKEditor, TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxData } from '../../../_atoms/combobox'

const pisCofinsGrupos = [
  {
    id: '01',
    nome: '01 – Operação Tributável (base de cálculo = valor da operação alíquota normal (cumulativo/não cumulativo))',
  },
  { id: '02', nome: '02 - Operação Tributável (base de cálculo = valor da operação (alíquota diferenciada))' },
  { id: '03', nome: '03 - Operação Tributável (base de cálculo = quantidade vendida x alíquota por unidade de produto)' },
  { id: '04', nome: '04 - Operação Tributável (tributação monofásica (alíquota zero))' },
  { id: '06', nome: '06 - Operação Tributável (alíquota zero)' },
  { id: '07', nome: '07 - Operação Isenta da Contribuição' },
  { id: '08', nome: '08 - Operação Sem Incidência da Contribuição' },
  { id: '09', nome: '09 - Operação com Suspensão da Contribuição' },
  { id: '49', nome: '49 - Outras Operações de saída' },
  { id: '99', nome: '99 - Outras Operações' },
]

const icmsOrigs = [
  { id: '0', nome: '0 - Nacional, exceto as indicadas nos códigos 3 a 5' },
  { id: '1', nome: '1 - Estrangeira - Importação direta, exceto a indicada no código 6' },
  { id: '2', nome: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7' },
  { id: '3', nome: '3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%' },
  {
    id: '4',
    nome:
      '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes',
  },
  { id: '5', nome: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%' },
  { id: '6', nome: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX' },
  { id: '7', nome: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX' },
]

const icmsGrupos = [
  { id: '00', nome: '00 - Tributada integralmente' },
  { id: '20', nome: '20 - Com redução de base de cálculo' },
  { id: '90', nome: '90 - Outros' },
  { id: '40', nome: '40 - Isenta' },
  { id: '41', nome: '41 - Não tributada' },
  { id: '50', nome: '50 - Suspensão' },
  { id: '60', nome: '60 - ICMS cobrado anteriormente por substituição tributária' },
  { id: '102', nome: '102- Tributada pelo Simples Nacional sem permissão de crédito' },
  { id: '300', nome: '300 – Imune' },
  {
    id: '500',
    nome: '500 – ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação',
  },
  { id: '900', nome: '900 - Outros' },
]

class AddOrEditModal extends Component {
  state = {
    form: {
      icms: { orig: '0', cst: '102' },
      pis: { cst: '49' },
      cofins: { cst: '49' },
      ...this.props.data,
    },
  };

  onSave = () => {
    const { form } = this.state
    axios.post('api/grupoFiscal', form).then(resp => {
      this.props.onHide(true)
    })
  };

  handlerChange = e => {
    this.setState({ form: { ...this.state.form, [e.target.name]: e.target.value } })
  };

  handlerChangeSub = (sub, e) => {
    const _sub = { ...this.state.form[sub], [e.target.name]: e.target.value }
    this.setState({ form: { ...this.state.form, [sub]: _sub } })
  };

  renderSubTitle = title => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{
        height: 1, backgroundColor: '#ccc', width: '100%', marginRight: 5,
      }}
      />
      <div>{title}</div>
      <div style={{
        height: 1, backgroundColor: '#ccc', width: '100%', marginLeft: 5,
      }}
      />
    </div>
  );

  render() {
    const { onHide } = this.props
    const { form } = this.state
    return (
      <div>
        <Dialog maxWidth={false} fullWidth open onClose={onHide}>
          <Title
            title={form && form.id ? 'Edição de NCM' : 'Novo NCM'}
            buttons={[{ icon: 'close', color: 'secondary', onClick: onHide }]}
          />
          <DialogContent style={{ paddingTop: 10 }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField label="NCM" name="ncm" value={form && form.ncm} onChange={this.handlerChange} />
              </Grid>
              <Grid item xs={8}>
                <TextField label="Nome" name="nome" value={form && form.nome} onChange={this.handlerChange} />
              </Grid>
              <Grid item xs={4}>
                <TextField label="CEST" name="cest" value={form && form.cest} onChange={this.handlerChange} />
              </Grid>
              <Grid item xs={4}>
                <ComboBoxData
                  label="Origem"
                  onChange={e => this.handlerChangeSub('icms', { target: { name: 'orig', value: e.id } })}
                  selected={form.icms?.orig}
                  list={icmsOrigs}
                />
              </Grid>
              <Grid item xs={4}>
                <ComboBoxData
                  label="ICMS CST"
                  onChange={e => this.handlerChangeSub('icms', { target: { name: 'cst', value: e.id } })}
                  selected={form.icms?.cst}
                  list={icmsGrupos}
                />
              </Grid>
              <Grid item xs={12} style={{ margin: 10 }}>
                {this.renderSubTitle('PIS')}
              </Grid>
              <Grid item xs={6}>
                <ComboBoxData
                  label="PIS Código de Situação Tributária"
                  onChange={e => this.handlerChangeSub('pis', { target: { name: 'cst', value: e.id } })}
                  selected={form.pis?.cst}
                  list={pisCofinsGrupos}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Alíquota do PIS (em percentual) (pPis)"
                  name="pPIS"
                  value={form.pis && form.pis.pPIS}
                  onChange={e => this.handlerChangeSub('pis', e)}
                />
              </Grid>
              <Grid item xs={12} style={{ margin: 10 }}>
                {this.renderSubTitle('COFINS')}
              </Grid>
              <Grid item xs={6}>
                <ComboBoxData
                  label="COFINS Código de Situação Tributária"
                  onChange={e => this.handlerChangeSub('cofins', { target: { name: 'cst', value: e.id } })}
                  selected={form.cofins && form.cofins.cst}
                  list={pisCofinsGrupos}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Alíquota do COFINS (em percentual) (pCOFINS)"
                  name="pCOFINS"
                  value={form.cofins && form.cofins.pCOFINS}
                  onChange={e => this.handlerChangeSub('cofins', e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.onSave}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AddOrEditModal
