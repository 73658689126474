import React, { Component } from 'react'
import * as NumberFormat from 'react-number-format'
import TextField from '../TextField'

class TextFielddMoneyBR extends Component {
  render () {
    return (
      <NumberFormat
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        decimalScale={2}
        prefix="R$ "
        customInput={TextField}
        {...this.props}
        onValueChange={e => this.props.onValueChange({ target: { name: this.props.name, value: e.floatValue } })}
      />
    )
  }
}

export default TextFielddMoneyBR
