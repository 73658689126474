import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'

import { ReactTable } from '../../_atoms'
import Title from '@em/lib/src/util/Title'
import IconButtonEdit from '../../_atoms/button/IconButtonEdit'
import IconButtonDelete from '../../_atoms/button/IconButtonDelete'
import MusicaDialog from '../../_organisms/musica/musicaDialog'
import { showMensagemConfirmacaoAction } from '../../actions/mensagemAction'
import CifraDialog from '../../_organisms/musica/cifraDialog'

class ListaMusicas extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      loading: false,
      offset: 0,
      limit: 10,
      modal: false,
      editar: false,
      musica: {},
      modalCifra: false
    }
  }

    fetchData = (state, instance) => {
      this.setState(
        { loading: true, offset: state.page, limit: state.limit },
        this.getMusicas
      )
    }

    getMusicas () {
      const { offset, limit } = this.state

      this.setState({ loading: true }, () => {
        axios.get('/api/Musica', {
          params: {
            offset,
            limit
          }
        }).then(result => {
          this.setState({ data: result, loading: false })
        })
      })
    }

    onEdit = ({ original }) => {
      this.setState({
        modal: true,
        editar: true,
        musica: original
      })
    }

    onCifra = ({ original }) => {
      this.setState({
        modalCifra: true,
        musica: original
      })
    }

    handleDeleteConfirmation = musicaId => {
      axios.delete(`api/Musica/${musicaId}`).then(resp => {
        this.getMusicas()
      })
    }

    handleDelete = ({ original }) => {
      const model = {
        title: 'Excluir',
        mensagem: (
          <span>
                    Você realmente deseja excluir
            {' '}
            <strong>
              {' '}
              {original.nome}
            </strong>
?
          </span>
        ),
        buttoes: [
          { text: 'EXCLUIR', onClick: () => this.handleDeleteConfirmation(original.id) }
        ]
      }
      this.props.showMensagemConfirmacaoAction(model)
    };

    render () {
      const { funcoes } = this.props
      const {
        data, loading, modal, editar, musica, modalCifra
      } = this.state
      return (
        <div>
          <Title
            title="Música"
            buttons={[
              {
                icon: 'add',
                onClick: () => this.setState({
                  modal: true,
                  editar: false
                })
              }
            ]}
          />

          <ReactTable
            loading={loading}
            onFetchData={this.fetchData}
            pages={data.paginacao && data.paginacao.qtdePaginas}
            data={data.lista || []}
            columns={[
              {
                Header: 'Nº',
                accessor: 'codigo',
                width: 60
              },
              {
                Header: 'Nome',
                accessor: 'nome',
                Cell: props => (
                  <div>
                    <p>{props.original.nome}</p>
                    <p>
(
                      {props.original.nomeOriginal}
)
                    </p>
                  </div>
                )
              },
              {
                Header: 'Tom',
                accessor: 'tom'
              },
              {
                Cell: props => (
                  <div>
                    <Button onClick={() => this.onCifra(props)}><Icon>library_music</Icon></Button>
                    <Button><a href={props.original.link} target="_blank"><Icon>play_circle_filled</Icon></a></Button>
                    <IconButtonEdit funcoes={funcoes} onClick={() => this.onEdit(props)} />
                    <IconButtonDelete funcoes={funcoes} onClick={() => this.handleDelete(props)} />

                  </div>
                ),
                width: 250
              }

            ]}
          />

          {modal && (
            <MusicaDialog
              onHide={
                refresh => {
                  this.setState({
                    modal: false
                  })
                  if (refresh === true) {
                    this.getMusicas()
                  }
                }
              }
              editar={editar}
              musica={musica}
            />
          )}
          {modalCifra && (
            <CifraDialog
              musica={musica}
              onHide={
                refresh => {
                  this.setState({
                    modalCifra: false
                  })
                  if (refresh === true) {
                    this.getMusicas()
                  }
                }
              }
            />
          )}
        </div>
      )
    }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      showMensagemConfirmacaoAction
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(ListaMusicas)
