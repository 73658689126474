import React from 'react'
import { InlineDateTimePicker } from 'material-ui-pickers'
import moment from 'moment'
import TextField from './TextField'

export default props => {
  const newProps = { ...props, value: props.value || null }
  return (
    <InlineDateTimePicker
      variant="filled"
      label="Selecione a data hora"
      format="DD/MM/YYYY HH:mm"
      mask={value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/] : [])}
      invalidDateMessage="Data invalida"
      keyboard
      minDate={moment().add(-1, 'year')}
      ampm={false}
      animateYearScrolling
      fullWidth
      clearable
      TextFieldComponent={TextField}
      {...newProps}
    />
  )
}
