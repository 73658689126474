import React, { useState } from 'react'
import MaterialTable from 'material-table'

export default (props) => {
  const { options, localization, ...others } = props
  const [pageSize, setPageSize] = useState(5)
  const pagination = localization?.pagination
  const body = localization?.body
  const toolbar = localization?.toolbar

  return (
    <MaterialTable
      title="Lista"
      localization={
        {
          body: {
            emptyDataSourceMessage: <h3>Lista vazia.</h3>,
            ...body
          },
          toolbar: {
            exportTitle: 'Baixar',
            exportAriaLabel: 'Baixar',
            ...toolbar
          },
          pagination: {
            labelRowsPerPage: 'Registros por pagina',
            previousAriaLabel: 'Pagina anterior',
            previousTooltip: 'Pagina anterior',
            nextTooltip: 'Proxima pagina',
            nextAriaLabel: 'Proxima pagina',
            firstTooltip: 'Primeira pagina',
            firstAriaLabel: 'Primeira pagina',
            lastAriaLabel: 'Ultima pagina',
            lastTooltip: 'Ultima pagina',
            labelRowsSelect: 'Registros',
            labelDisplayedRows: '{from}-{to} de {count}',
            ...pagination
          }
        }
      }
      onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
      options={{
        pageSize,
        toolbar: false,
        exportButton: true,
        exportAllData: true,
        search: false,
        filtering: false,
        sorting: false,
        draggable: false,
        paging: true,
        showTitle: true,
        header: true,
        grouping: false,
        emptyRowsWhenPaging: false,
        exportDelimiter: ';',
        rowStyle: rowData => {
          if (rowData.tableData.id % 2 === 0) {
            return { backgroundColor: '#f1f1f1' }
          }
          return {}
        },
        ...options
      }}
      {...others}
    />
  )
}
