import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Grid, Button, Dialog, DialogContent, DialogActions, Checkbox, FormControlLabel, FormControl, FormLabel, FormHelperText, FormGroup, Chip } from '@material-ui/core'
import { TextField } from '../../../_atoms'
import Title from '@em/lib/src/util/Title'
import { ComboBoxAPI } from '../../../_atoms/combobox'
import canaisJSON from '../../../contstants/canais'
import { Autocomplete } from '@material-ui/lab'

const CriarEditarDialog = ({ data, onHide }) => {
  const [todosStatusDePedidos, setTodosStatusDePedidos] = useState([])
  const [form, setForm] = useState({
    nome: 'Caixa',
    flags: [],
    canais: [],
    statuses: [],
    ...data
  })


  // useEffect(() => {
  //   axios.get('/api/pedidostatus/full')
  //     .then(resp => {
  //       setTodosStatusDePedidos(resp)
  //     })
  // }, [])


  const handlerChangeCanais = e => {
    let { canais } = form

    if (e.target.checked && !(canais || []).includes(e.target.value)) {
      canais = [...(canais || []), parseInt(e.target.value)]
    } else {
      canais = canais.filter(value => value != e.target.value)
    }

    setForm({ ...form, canais })
  }

  const handlerChangeFlags = e => {
    let { flags } = form

    if (e.target.checked && !(flags || []).includes(e.target.value)) {
      flags = [...(flags || []), e.target.value]
    } else {
      flags = flags.filter(value => value != e.target.value)
    }

    setForm({ ...form, flags })
  }

  const onSave = async () => {
    await axios.post('api/caixa', form).then(response => response)
    onHide(true)
  }

  return (
    <Dialog open>
      <Title
        title={`${form.id ? 'Editar' : 'Novo'} caixa`}
        buttons={[{ icon: 'close', onClick: onHide }]}
      />

      <DialogContent style={{ paddingTop: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Nome"
              name="nome"
              required
              value={form.nome || ''}
              onChange={e => setForm({ ...form, nome: e.target.value })}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Numero"
              name="numero"
              required
              value={form.numero || ''}
              onChange={e => setForm({ ...form, numero: e.target.value })}
            />
          </Grid>
          {/* <Grid xs={12}>

            <Autocomplete
              multiple
              id="Status"
              options={todosStatusDePedidos}
              defaultValue={form.statuses || []}
              freeSolo
              onChange={(a, b) => setForm({ target: { name: 'tags', value: b } })}
              renderTags={(value, getTagProps) => value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))}
              renderInput={params => (
                <TextField
                  {...params}
                  // variant="filled"
                  label="Tags"
                  name="tag"
                  placeholder="Nova tag"
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <ComboBoxAPI
              label="Impressora"
              onChange={e => setForm({ ...form, impressora: e })}
              default={form && form.impressora}
              value={form && form.impressora && form.impressora.id}
              api="api/impressora/full"
            />
          </Grid>
          <Grid item xs={6}>

            <FormControl component="fieldset">
              <FormLabel component="legend">Canais Ativos</FormLabel>
              <FormGroup>
                {canaisJSON.map(x => {
                  return (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={form?.canais?.includes(x.id)}
                          color="default"
                          value={x.id}
                          onChange={handlerChangeCanais}
                        />
                      )}
                      label={x.nome}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>

          </Grid>
          <Grid item xs={6}>

            <FormControl component="fieldset">
              <FormLabel component="legend">Campos Obrigatórios</FormLabel>
              <FormGroup>
                {[
                  { id: "clienteNome", name: "Nome do Cliente" },
                  { id: "clienteTelefone", name: "Telefone do Cliente" }
                ].map(x => {
                  return (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={form?.flags?.includes(x.id)}
                          color="default"
                          value={x.id}
                          onChange={handlerChangeFlags}
                        />
                      )}
                      label={x.name}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>

          </Grid>

          <Grid item xs={6}>

            <FormControl component="fieldset">
              <FormLabel component="legend">Notificações</FormLabel>
              <FormGroup>
                {[
                  {
                    id: "notifyPrinter",
                    name: "Noficação de impressoras"
                  }
                ].map(x => {
                  return (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={form?.flags?.includes(x.id)}
                          color="default"
                          value={x.id}
                          onChange={handlerChangeFlags}
                        />
                      )}
                      label={x.name}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSave} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CriarEditarDialog
