import { LOGIN, LOGOUT, SETSISTEMAS } from '../contstants/actionsContants'

export const setSistemas = sistemas => ({
  type: SETSISTEMAS,
  payload: sistemas,
})

export const entrarAction = usuario => ({
  type: LOGIN,
  payload: usuario,
})

export const sairAction = () => ({
  type: LOGOUT,
})
